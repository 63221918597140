import { CHF_VISIT_SECTIONS } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/metadata';
import type {
  DailyLivingFormFields,
  DietFormFields,
  ExerciseFormFields,
  TobaccoUseFormFields,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/generalAssessment/formConfig';
import type { SectionStepsState } from 'shared/common/Wizard/state';
import { getWizardStepValues } from 'shared/common/Wizard/state';

import { cnGeneralAssessment } from '../../shared';

export function chfVisitGeneralAssessment(data: SectionStepsState) {
  const tobaccoData = getWizardStepValues(
    data,
    CHF_VISIT_SECTIONS,
    '/general-assessment',
    '/tobacco-use',
  )<TobaccoUseFormFields>();
  const dietData = getWizardStepValues(
    data,
    CHF_VISIT_SECTIONS,
    '/general-assessment',
    '/diet',
  )<DietFormFields>();
  const exerciseData = getWizardStepValues(
    data,
    CHF_VISIT_SECTIONS,
    '/general-assessment',
    '/exercise',
  )<ExerciseFormFields>();
  const dailyLivingData = getWizardStepValues(
    data,
    CHF_VISIT_SECTIONS,
    '/general-assessment',
    '/daily-living',
  )<DailyLivingFormFields>();

  return cnGeneralAssessment({
    tobaccoData,
    dietData,
    exerciseData,
    dailyLivingData,
  });
}
