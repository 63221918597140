import last from 'lodash/last';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ChevronUp from 'shared/assets/svgs/chevron-up.svg?react';
import ChevronDown from 'shared/assets/svgs/chevron.svg?react';
import { UserAvatar } from 'shared/common/UserAvatar';
import type { Comment as TComment, Task } from 'shared/tasking/types';
import { Avatar } from 'shared/tempo/atom/Avatar';
import { Button } from 'shared/tempo/atom/Button';

import { CardSection } from '../CardSection';
import { Comment } from '../Comment';
import { isResolved, taskParticipants } from '../utils';
import {
  collapseButton,
  comment,
  commentCardSection,
  expandButton,
  expandComments,
} from './CommentList.css';

type Props = {
  task: Task;
  splitMostRecent: boolean;
  collapsible?: boolean;
};

export function CommentList({
  task,
  splitMostRecent,
  collapsible = true,
}: Props) {
  const [fullCommentsHidden, setFullCommentsHidden] = useState(collapsible);
  const { comments } = task;
  const listComments = splitMostRecent ? comments.slice(0, -1) : comments;
  const lastComment = last(task.comments);

  return (
    <>
      {!fullCommentsHidden && (
        <CardSection className={commentCardSection}>
          <CardSection.LeftColumn />
          <CardSection.RightColumn>
            {collapsible && (
              <Button
                variant="tertiary"
                size="small"
                className={collapseButton}
                onPress={() => setFullCommentsHidden(true)}
              >
                <Button.Icon>
                  <ChevronUp />
                </Button.Icon>
                <FormattedMessage
                  defaultMessage="Hide {count} {count, plural, one {comment} other {comments}}"
                  values={{ count: listComments.length }}
                />
              </Button>
            )}
            {listComments.map((c) => (
              <div key={c.name} className={comment}>
                <Comment taskOrComment={c} canEdit={!isResolved(task)} />
              </div>
            ))}
          </CardSection.RightColumn>
        </CardSection>
      )}
      {!splitMostRecent &&
        fullCommentsHidden &&
        Boolean(listComments.length) && (
          <CardSection unstyled>
            <CardSection.LeftColumn />
            <CardSection.RightColumn>
              <ExpandCommentControl
                comments={listComments}
                onExpand={() => setFullCommentsHidden(false)}
              />
            </CardSection.RightColumn>
          </CardSection>
        )}
      {splitMostRecent && lastComment && (
        <CardSection className={commentCardSection}>
          <CardSection.LeftColumn />
          <CardSection.RightColumn>
            {fullCommentsHidden && Boolean(listComments.length) && (
              <ExpandCommentControl
                comments={listComments}
                onExpand={() => setFullCommentsHidden(false)}
              />
            )}
            <Comment
              alternate
              taskOrComment={lastComment}
              canEdit={!isResolved(task)}
            />
          </CardSection.RightColumn>
        </CardSection>
      )}
    </>
  );
}

function ExpandCommentControl({
  comments,
  onExpand,
}: {
  comments: TComment[];
  onExpand: () => void;
}) {
  return (
    <div className={expandComments}>
      <Button
        variant="tertiary"
        size="small"
        onPress={onExpand}
        className={expandButton}
      >
        <Button.Icon>
          <ChevronDown />
        </Button.Icon>
        <FormattedMessage
          defaultMessage="{count} more {count, plural, one {comment} other {comments}}"
          values={{ count: comments.length }}
        />
      </Button>
      <Avatar.Stack>
        {taskParticipants(comments).map(({ givenName, familyName, uid }) => (
          <UserAvatar
            key={uid}
            size="small"
            firstName={givenName}
            lastName={familyName}
          />
        ))}
      </Avatar.Stack>
    </div>
  );
}
