import { TypeOfEncounter } from 'pages/patients/patientDetails/ui/Notes/Notes.types';
import { Wizard } from 'shared/common/Wizard';
import { Condition } from 'shared/types/clinicalprofile.types';

import { useCNFormContext } from '../../../CNFormContext';
import { useGoToRegActionPlan } from '../useGoToActionPlan';
import { VitalAverageLevel } from '../vitalEducation/types';
import { useAdditionalScriptContext } from '../vitalEducation/useAdditionalScriptContext';
import { useVitalBasedScript } from '../vitalEducation/useVitalBasedScript';
import { ScriptWrapper } from './scripts/ScriptWrapper';
import { RECOMMENDATION_SCRIPTS } from './scripts/recommendationScripts';

type Props = {
  onNext?: () => void;
};

export function SharedVitalRecommendation({ onNext: onNextOverride }: Props) {
  const {
    hasT2dWithScaleOnly,
    isLoading: isLoadingHasT2d,
    conditions,
  } = useAdditionalScriptContext();
  const hasNoVitalControlLevel =
    hasT2dWithScaleOnly || conditions.includes(Condition.CHF);
  const [recommendationScript, isLoadingScript] = useVitalBasedScript(
    RECOMMENDATION_SCRIPTS,
    hasNoVitalControlLevel ? VitalAverageLevel.Unknown : undefined,
  );
  const [goToRegActionPlan] = useGoToRegActionPlan();
  const { cnVisitType } = useCNFormContext();
  let onNext;

  if (onNextOverride) {
    onNext = onNextOverride;
  } else if (cnVisitType === TypeOfEncounter.CN_VISIT) {
    onNext = goToRegActionPlan;
  }

  return (
    <Wizard.Step
      sections={[]}
      isLoading={isLoadingScript || isLoadingHasT2d}
      onNext={onNext}
    >
      <ScriptWrapper>{recommendationScript}</ScriptWrapper>
    </Wizard.Step>
  );
}
