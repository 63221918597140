import { Fragment } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useCcmConditionOptions } from 'pages/adminPanel/patient-profile/tabs/ClinicalProfileTab/clinicalprofile.utils';
import ArrowUpRight from 'shared/assets/svgs/arrow-up-right.svg?react';
import { Divider } from 'shared/common/Divider';
import { Form } from 'shared/common/Form';
import { RatingScaleField } from 'shared/common/RatingScaleField';
import { Button } from 'shared/tempo/atom/Button';
import { Link } from 'shared/tempo/atom/Link';
import { color } from 'shared/tempo/theme';

import { RemovableRow } from '../RemovableRow';
import { CCM_CONDITION_RESOURCES, useCcmConditionI18n } from '../conditions';
import { EMPTY_GOALS_SECTION, type FormFields } from '../formConfig';
import { CCM_PROGNOSIS_OPTIONS, useCcmPrognosisI18n } from '../prognosis';
import {
  addItemButton,
  careGuide,
  divider,
  ratingField,
  sectionTitle,
  selfManagementGoalsTitle,
} from './carePlanSections.css';

type Props = {
  form: UseFormReturn<FormFields>;
};

export function GoalsAndInterventions({ form }: Props) {
  const intl = useIntl();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'goalsAndInterventions',
  });

  return (
    <Form.Section
      title={intl.formatMessage({
        defaultMessage: 'Goals and Interventions',
      })}
      classes={{ title: sectionTitle }}
    >
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <ConditionSection
            numOfConditions={fields.length}
            index={index}
            form={form}
            onRemove={() => remove(index)}
            isRemovable={fields.length > 2}
          />
          {index < fields.length - 1 && <Divider className={divider} />}
        </Fragment>
      ))}
      <div
        className={
          !fields.length ? addItemButton.emptyList : addItemButton.default
        }
      >
        <Button
          variant="secondary"
          size="small"
          onPress={() => append({ ...EMPTY_GOALS_SECTION })}
        >
          <FormattedMessage defaultMessage="Add Condition" />
        </Button>
      </div>
    </Form.Section>
  );
}

type ConditionSectionProps = {
  index: number;
  onRemove: () => void;
  form: UseFormReturn<FormFields>;
  isRemovable: boolean;
  numOfConditions: number;
};

function ConditionSection({
  index,
  form,
  isRemovable,
  onRemove,
  numOfConditions,
}: ConditionSectionProps) {
  const intl = useIntl();
  const ccmConditionI18n = useCcmConditionI18n();
  const prognosisI18n = useCcmPrognosisI18n();
  const conditionOptions = useCcmConditionOptions();
  const selectedCondition = form.watch(
    `goalsAndInterventions.${index}.condition`,
  );

  function triggerPopulatedConditionValidations() {
    for (let i = 0; i < numOfConditions; i++) {
      if (form.getValues(`goalsAndInterventions.${i}.condition`)) {
        form.trigger(`goalsAndInterventions.${i}.condition`);
      }
    }
  }

  return (
    <>
      <RemovableRow
        onRemove={() => {
          onRemove();
          // Trigger validation for uniqueness check
          triggerPopulatedConditionValidations();
        }}
        hasError={
          !!form.formState.errors.goalsAndInterventions?.[index]?.condition
        }
        enabled={isRemovable}
      >
        <Form.DeprecatedSelect
          required
          size={11}
          onChange={() => {
            // Trigger validation for uniqueness check
            triggerPopulatedConditionValidations();
          }}
          label={intl.formatMessage(
            {
              defaultMessage: 'Condition {count}',
            },
            { count: index + 1 },
          )}
          name={`goalsAndInterventions.${index}.condition`}
        >
          <option disabled selected value={undefined}>
            {intl.formatMessage({ defaultMessage: 'Select one' })}
          </option>
          {conditionOptions.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Form.DeprecatedSelect>
      </RemovableRow>
      {selectedCondition && (
        <Form.GridItem size={11} className={careGuide.container}>
          <Link.Native
            className={careGuide.link}
            href={CCM_CONDITION_RESOURCES[selectedCondition]}
            target="_blank"
          >
            {intl.formatMessage(
              { defaultMessage: '{condition} Care Guide' },
              { condition: ccmConditionI18n[selectedCondition] },
            )}
            <ArrowUpRight fill={color.Theme.Light.Primary} />
          </Link.Native>
        </Form.GridItem>
      )}
      <Form.Row>
        <Form.DeprecatedSelect
          required
          size={11}
          label={intl.formatMessage({
            defaultMessage: 'Prognosis',
          })}
          name={`goalsAndInterventions.${index}.prognosis`}
        >
          <option disabled selected value={undefined}>
            {intl.formatMessage({ defaultMessage: 'Select one' })}
          </option>
          {CCM_PROGNOSIS_OPTIONS.map((prognosis) => (
            <option key={prognosis} value={prognosis}>
              {prognosisI18n[prognosis]}
            </option>
          ))}
        </Form.DeprecatedSelect>
      </Form.Row>
      <Form.Row>
        <Form.TextField
          required
          size={11}
          label={intl.formatMessage({
            defaultMessage: 'Treatment Goal',
          })}
          name={`goalsAndInterventions.${index}.treatmentGoal`}
        />
      </Form.Row>
      <div className={selfManagementGoalsTitle}>
        <FormattedMessage defaultMessage="Self-Management Goals" />
      </div>
      <Form.Row>
        <Form.TextField
          required
          size={11}
          label={intl.formatMessage({
            defaultMessage: 'Patient self-defined health goal',
          })}
          name={`goalsAndInterventions.${index}.healthGoal`}
        />
      </Form.Row>
      <Form.Row>
        <Form.TextArea
          required
          size={11}
          rows={3}
          label={intl.formatMessage({
            defaultMessage: 'Patient self-defined action steps',
          })}
          name={`goalsAndInterventions.${index}.actionSteps`}
        />
      </Form.Row>
      <Form.Row>
        <RatingScaleField
          classes={ratingField}
          size={11}
          label={intl.formatMessage({
            defaultMessage:
              'On a scale of 1 to 10, how sure are you that you can follow this action plan?',
          })}
          help={intl.formatMessage({
            defaultMessage:
              'If confidence is less than 7, explore ways to revise the plan so the patient feels more confident.',
          })}
          name={`goalsAndInterventions.${index}.confidence`}
        />
      </Form.Row>
      <Form.Row>
        <Form.TextArea
          required
          size={11}
          rows={3}
          label={intl.formatMessage({
            defaultMessage: 'Coordination of Care/Resources',
          })}
          name={`goalsAndInterventions.${index}.coordination`}
        />
      </Form.Row>
    </>
  );
}
