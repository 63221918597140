import type { SvgIconProps } from 'deprecated/mui';
import { SvgIcon } from 'deprecated/mui';

export const EllipseIcon = ({ fill, ...rest }: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...rest} viewBox="0 0 16 16">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="6" fillRule="evenodd" />
    </svg>
  </SvgIcon>
);
