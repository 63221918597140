import { useAssignedDevices } from 'shared/hooks/queries/devices.queries';
import type { Device } from 'shared/types/device.types';
import { DeviceTypes } from 'shared/types/device.types';
import { VitalType } from 'shared/types/vitals.types';

import { usePatientConditions } from './usePatientConditions';

export type VitalTypes = Record<VitalType, { isIncluded: boolean }>;

export function useRelevantVitalTypes(patientId: string) {
  const {
    isLoading: isLoadingConditions,
    hasHtn,
    hasChf,
    hasT2d,
  } = usePatientConditions(patientId);
  const { data: devices, isLoading: isLoadingDevices } = useAssignedDevices(
    patientId,
    {},
  );

  if (isLoadingConditions || isLoadingDevices) {
    return { isLoading: true, vitalTypes: undefined };
  }

  const hasBpMeter = hasDeviceOfType(
    devices?.data,
    DeviceTypes.BLOOD_PRESSURE_METER,
  );
  const hasBgMeter = hasDeviceOfType(
    devices?.data,
    DeviceTypes.BLOOD_GLUCOSE_METER,
  );
  const hasWeightScale = hasDeviceOfType(
    devices?.data,
    DeviceTypes.WEIGHT_SCALE,
  );

  const vitalTypes: VitalTypes = {
    [VitalType.BloodPressure]: {
      isIncluded: hasBpMeter && (hasHtn || hasChf),
    },
    [VitalType.BloodGlucose]: {
      isIncluded: hasBgMeter && hasT2d,
    },
    [VitalType.HeartRate]: { isIncluded: hasBpMeter },
    [VitalType.Weight]: { isIncluded: hasWeightScale },
  };

  return { isLoading: false, vitalTypes };
}

function hasDeviceOfType(
  devices: Device[] = [],
  deviceType: (typeof DeviceTypes)[keyof typeof DeviceTypes],
) {
  return devices.some((device) => device.device_type === deviceType) ?? false;
}
