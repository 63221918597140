import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  FieldGroup,
  Script,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';

type Props = {
  hasData: boolean;
  children: ReactNode;
};

export function LastDiscussed({ hasData, children }: Props) {
  if (!hasData) {
    return <FieldGroup>{children}</FieldGroup>;
  }

  return (
    <FieldGroup>
      <Script>
        <FormattedMessage defaultMessage="Last we discussed, you were:" />
      </Script>
      {children}
      <Script>
        <FormattedMessage defaultMessage="Has anything changed since we last spoke about this?" />
      </Script>
    </FieldGroup>
  );
}
