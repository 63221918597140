import { conditionsToProgram } from 'pages/patients/patientDetails/ui/Notes/NoteEditor/templates/hooks';
import type { NextAppointmentRecommendation } from 'shared/generated/grpcGateway/scheduling.pb';
import { getRpmConditionsFromProgramAndStatus } from 'shared/patient/conditions.utils';
import { ConditionProgram } from 'shared/types/condition.types';
import { type Patient, PatientStatus } from 'shared/types/patient.types';

export function isRtePatientWithRecommendedStartDate(
  patient: Patient,
  recommendedAppt: NextAppointmentRecommendation,
): recommendedAppt is NextAppointmentRecommendation & { startDate: string } {
  return (
    patient.status === PatientStatus.ReadyToEnroll &&
    !!recommendedAppt.startDate
  );
}

export function shouldBeScheduledAsap(
  patient: Patient,
  recommendedAppt: NextAppointmentRecommendation,
) {
  if (isRtePatientWithRecommendedStartDate(patient, recommendedAppt)) {
    return false;
  }
  const isInitAppointment = isInitialAppointment(
    recommendedAppt.appointmentTypeName,
  );
  const isUnstableChfCnVisit = checkUnstableChfCnVisit(
    patient,
    recommendedAppt,
  );
  return Boolean(isInitAppointment || isUnstableChfCnVisit);
}

export function isInitialAppointment(apptTypeName: string = '') {
  return apptTypeName.startsWith('Initial');
}

export function isInitialCNAppointment(apptTypeName: string = '') {
  return apptTypeName.startsWith('Initial CN');
}

export function isRegularAppointment(apptTypeName: string = '') {
  return apptTypeName.startsWith('Regular');
}

export function isRegularCNAppointment(apptTypeName: string = '') {
  return apptTypeName.startsWith('Regular CN');
}

function checkUnstableChfCnVisit(
  patient: Patient,
  recommendedAppt: NextAppointmentRecommendation,
) {
  if (!patient || !recommendedAppt.appointmentTypeName) {
    return false;
  }

  const { sourceAppointmentTypeName, appointmentTypeName, startDate } =
    recommendedAppt;
  const rpmConditions = getRpmConditionsFromProgramAndStatus(
    patient?.programs,
    patient?.status,
  );
  const hasChf = conditionsToProgram(rpmConditions) === ConditionProgram.CHF;
  return (
    sourceAppointmentTypeName?.includes('CN Visit') &&
    appointmentTypeName.includes('NP Visit') &&
    !startDate &&
    hasChf
  );
}
