import cx from 'classnames';
import type { ReactNode } from 'react';
import React, { Component, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { logger } from 'logger';
import { header as noteHeader } from 'pages/patients/PatientProfile/NoteDrawer/NoteDrawer.css';
import { useNoteEditorContext } from 'pages/patients/patientDetails/ui/Notes/NoteEditorContext';
import CloseIcon from 'shared/assets/svgs/close.svg?react';
import { IconButton } from 'shared/common/IconButton';
import { LoadingPlaceholder } from 'shared/common/LoadingPlaceholder';
import { usePatientAutosavedNote } from 'shared/hooks/queries/autosave-notes.queries';
import { fullWidth } from 'shared/jsStyle/utils.css';
import { useToaster } from 'shared/tempo/molecule/Toast';
import type { RequiredNoteType } from 'shared/types/note.types';
import type { RouteParam } from 'shared/types/route.types';

import { useSetNoteEditorContentFromNote } from '../utils/useSetNoteEditorContent.hook';
import { errorViewContainer } from './NoteEditorErrorBoundary.css';
import { useCloseAndClearNoteEditor } from './hooks/noteEditorVisibility.hooks';

interface Props {
  onException: (err: Error) => Promise<unknown>;
  onRecoveryFail: () => void;
  onReset: () => void;
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class NoteEditorErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };

    this.reset = this.reset.bind(this);
  }

  public async componentDidCatch(error: Error) {
    const { onException } = this.props;
    logger.error(error.message, error);
    await onException(error);
    // Only set the error state after the exception callback has completed
    this.setState({ hasError: true });
  }

  reset() {
    const { onReset } = this.props;
    onReset();
    this.setState({ hasError: false });
  }

  public render(): React.ReactNode {
    const { children, onRecoveryFail } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <AutoRecoverView onReset={this.reset} onRecoveryFail={onRecoveryFail} />
      );
    }
    return children;
  }
}

type AutoRecoverViewProps = {
  onReset: () => void;
  onRecoveryFail: () => void;
};

// eslint-disable-next-line react-refresh/only-export-components
function AutoRecoverView({ onReset, onRecoveryFail }: AutoRecoverViewProps) {
  const { patientId }: RouteParam = useParams();
  const { openEditor } = useNoteEditorContext();
  const closeAndClearNoteEditor = useCloseAndClearNoteEditor();
  const intl = useIntl();
  const { toaster } = useToaster();
  const setNoteEditorContentFromNote = useSetNoteEditorContentFromNote();

  function recoverNoteFromCrash(note: RequiredNoteType) {
    toaster.alert(
      intl.formatMessage({
        defaultMessage:
          'The note was auto-recovered, please verify its accuracy',
      }),
    );
    logger.warn('Note was auto-recovered');
    onReset();
    setNoteEditorContentFromNote(note);
    openEditor();
  }

  const { data: autosavedNote, isFetching } = usePatientAutosavedNote(
    patientId,
    {
      onSettled(note, error) {
        if (!note || error) {
          onRecoveryFail();
        }
      },
    },
  );

  useEffect(() => {
    if (!isFetching && autosavedNote) {
      // Calls onReset to reset error state in error boundary which
      // unmounts this component
      recoverNoteFromCrash(autosavedNote);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autosavedNote, isFetching]);

  return (
    <div className={errorViewContainer}>
      <div className={cx(noteHeader, fullWidth)}>
        <IconButton
          onClick={() => {
            closeAndClearNoteEditor({ shouldClearNoteContent: false });
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <LoadingPlaceholder isLoading={isFetching} />
    </div>
  );
}
