import { useIdleTimer } from 'react-idle-timer';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { settings } from 'config';
import { logout } from 'pages/auth/actionCreators';
import { useToaster } from 'shared/tempo/molecule/Toast';
import type { RootState } from 'store/redux.types';

import { useOnMount } from './useOnMount';

const ONE_HOURS_IN_MILLI_SEC = 1000 * 60 * 60;

export function useIdleForceLogout() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { toaster } = useToaster();
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );

  // Ensure we logout on first mount if last activity time is out of range
  useOnMount(() => {
    if (
      Number(localStorage.getItem('last_activity_at')) +
        ONE_HOURS_IN_MILLI_SEC <
      new Date().getTime()
    ) {
      dispatch(logout());
    }
  });

  useIdleTimer({
    timeout: ONE_HOURS_IN_MILLI_SEC,
    disabled: !isAuthenticated,
    crossTab: true,
    // Throttle the cross-tab synchronization by 200 milliseconds
    syncTimers: 200,
    name: `logout-timer-${settings.VITE_ENVIRONMENT}`,
    onIdle: () => {
      toaster.info(
        intl.formatMessage({
          defaultMessage: 'You have been logged out due to inactivity',
        }),
      );
      dispatch(logout());
    },
    onAction: () =>
      localStorage.setItem('last_activity_at', String(new Date().getTime())),
  });
}
