import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from 'shared/common/Wizard/state';

import { INIT_VISIT_SECTIONS } from '../../metadata';
import type { IntroFormFields } from '../../shared/IntroPatientAttendance';
import {
  SharedIntroPatientAttendance,
  getIntroductionFormConfig,
} from '../../shared/IntroPatientAttendance';
import { initVisitPath } from '../paths';

export function IntroPatientAttendance() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<IntroFormFields>(
    initVisitPath('/intro', '/patient-attendance'),
    getIntroductionFormConfig(intl),
  );

  return (
    <SharedIntroPatientAttendance
      form={form}
      sections={INIT_VISIT_SECTIONS}
      nextPath={initVisitPath('/intro', '/contact-info')}
      timeTrackingPath={initVisitPath('/time-tracking', '/index')}
    />
  );
}
