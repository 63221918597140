import type { CSSProperties } from 'react';

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from 'deprecated/mui';
import { typography } from 'shared/tempo/theme';

export type Props = {
  checked: boolean;
  label: JSX.Element;
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabled?: boolean;
  fontSize?: string;
  customStyles?: CSSProperties;
  customLabelStyles?: CSSProperties;
  customClassName?: string;
  customLabelClassName?: string;
};
export const LabelledCheckbox = ({
  checked,
  label,
  id,
  onChange,
  required,
  disabled,
  fontSize = '12px',
  customStyles = {},
  customLabelStyles = {},
  customClassName = '',
  customLabelClassName = '',
}: Props) => (
  <FormGroup>
    <FormControlLabel
      className={customClassName}
      sx={{ marginRight: '5px', ...customStyles }}
      control={
        <Checkbox
          id={id}
          checked={checked}
          onChange={onChange}
          required={required}
          disabled={disabled}
          sx={{ padding: '4px' }}
        />
      }
      label={
        <Typography
          className={customLabelClassName}
          sx={{
            ...typography.Body.Lead,
            fontSize,
            fontWeight: 600,
            fontFamily: 'Inter',
            ...customLabelStyles,
          }}
        >
          {label}
        </Typography>
      }
    />
  </FormGroup>
);
