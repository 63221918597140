import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import CallIcon from 'shared/assets/svgs/calling.svg?react';
import PlusIcon from 'shared/assets/svgs/plus.svg?react';
import { Form } from 'shared/common/Form';
import { useFormFromConfig } from 'shared/common/Form/FormContainer';
import { validators } from 'shared/common/Form/validations';
import { Modal } from 'shared/common/Modal';
import { useTwilioFlexContext } from 'shared/common/TwilioFlex';
import type { PatientSchema } from 'shared/generated/api/pms';
import { Menu } from 'shared/tempo/@labs/molecule/Menu';
import { Button } from 'shared/tempo/atom/Button';
import { IconButton } from 'shared/tempo/atom/IconButton';
import type { StandaloneCaregiver } from 'shared/types/caregiver.types';

import { icon, menuItem, textButton } from './TwilioFlexNoteButton.css';

const LANGUAGE_LINE_NUMBER = '+18668743972';

type Props = {
  patientId: Nullable<string>;
  noteId?: Nullable<number>;
  variant: 'icon' | 'text';
  contacts: Exclude<Required<PatientSchema>['contacts'], undefined>;
  caregivers: StandaloneCaregiver[];
};

type ContactMenuOption = {
  key: string;
  contactName: Nullable<string>;
  isPrimary: boolean;
  contactNumber: string;
};

export function TwilioFlexNoteButton({
  patientId,
  noteId,
  contacts,
  caregivers,
  variant,
}: Props) {
  const intl = useIntl();
  // TODO: Disable if there is no note ID, note should be saved before
  const hasNoteId = Boolean(noteId) || noteId === 0;
  const [isPhoneNumberModalOpen, setIsPhoneNumberModalOpen] = useState(false);
  const { iframeRef, setIsFlexOpen } = useTwilioFlexContext();

  const initiateCall = (phoneNumber: string) => {
    if (phoneNumber) {
      setIsFlexOpen(true);
      // TODO: pass in note ID, patient cadence ID, clinician ID as attributes
      iframeRef?.current?.contentWindow?.postMessage(
        { phoneNumber, patientId, noteId },
        'https://flex.twilio.com',
      );
    }
  };

  const patientContacts: ContactMenuOption[] = contacts
    .sort((a, b) => Number(b.primary) - Number(a.primary))
    .map((contact) => ({
      key: contact.contact?.phone_number ?? '',
      isPrimary: Boolean(contact.primary),
      contactName: null,
      contactNumber: contact.contact?.phone_number ?? '',
    }));

  const patientContactMenu =
    patientContacts.length > 0 ? (
      <Menu.Section title={<FormattedMessage defaultMessage="Patient" />}>
        {patientContacts.map((contactOption) => {
          const phoneNumber = contactOption.contactNumber;
          const message =
            (contactOption.isPrimary
              ? intl.formatMessage({ defaultMessage: 'Primary: ' })
              : intl.formatMessage({ defaultMessage: 'Alternative: ' })) +
            phoneNumber;
          return (
            <Menu.Item key={contactOption.contactNumber}>
              <div className={menuItem}>
                <CallIcon className={icon} />
                {message}
              </div>
            </Menu.Item>
          );
        })}
      </Menu.Section>
    ) : null;

  const caregiverContacts: ContactMenuOption[] = [];

  caregivers
    .sort((a, b) => Number(b.isPrimary) - Number(a.isPrimary))
    .forEach((caregiver) => {
      caregiver.caregiver.contacts
        ?.sort((a, b) => Number(b.isPreferred) - Number(a.isPreferred))
        .forEach((contactDetail) => {
          caregiverContacts.push({
            key: contactDetail.contact.phoneNumber ?? '',
            isPrimary: contactDetail.isPreferred,
            contactName: caregiver.caregiver.firstName,
            contactNumber: contactDetail.contact.phoneNumber,
          });
        });
    });

  const caregiverContactMenu =
    caregiverContacts.length > 0 ? (
      <Menu.Section title={<FormattedMessage defaultMessage="Caregiver" />}>
        {caregiverContacts.map((contactOption) => {
          const message = contactOption.isPrimary
            ? `${intl.formatMessage({ defaultMessage: 'Primary' })} (${
                contactOption.contactName
              }): ${contactOption.contactNumber}`
            : `${intl.formatMessage({ defaultMessage: 'Alternative' })} (${
                contactOption.contactName
              }): ${contactOption.contactNumber}`;
          return (
            <Menu.Item key={contactOption.contactNumber}>
              <div className={menuItem}>
                <CallIcon className={icon} />
                {message}
              </div>
            </Menu.Item>
          );
        })}
      </Menu.Section>
    ) : null;

  const otherNumbersSection = (
    <Menu.Section title={<FormattedMessage defaultMessage="Other numbers" />}>
      <Menu.Item key="manual">
        <div className={menuItem}>
          <PlusIcon className={icon} />
          {intl.formatMessage({ defaultMessage: 'Enter manual number' })}
        </div>
      </Menu.Item>
      <Menu.Item key="language_line">
        <div className={menuItem}>
          <PlusIcon className={icon} />
          {intl.formatMessage({ defaultMessage: 'Language line' })}
        </div>
      </Menu.Item>
    </Menu.Section>
  );

  const allMenuSections = patientContactMenu ? [patientContactMenu] : [];
  if (caregiverContactMenu) {
    allMenuSections.push(caregiverContactMenu);
  }
  allMenuSections.push(otherNumbersSection);

  return (
    <>
      <Menu.Trigger
        onAction={(key) => {
          const caregiver = caregiverContacts.find((cc) => cc.key === key);
          const contact = patientContacts.find((pc) => pc.key === key);

          if (key === 'manual') {
            setIsPhoneNumberModalOpen(true);
            return;
          }
          if (caregiver) {
            initiateCall(caregiver.contactNumber);
            return;
          }
          if (contact) {
            initiateCall(contact.contactNumber);
          }
          if (key === 'language_line') {
            initiateCall(LANGUAGE_LINE_NUMBER);
          }
        }}
        menu={<Menu>{allMenuSections}</Menu>}
      >
        {variant === 'text' ? (
          <Button isDisabled={!hasNoteId} className={textButton}>
            <Button.Icon>
              <CallIcon />
            </Button.Icon>
            <FormattedMessage defaultMessage="Call Patient" />
          </Button>
        ) : (
          <IconButton size="small" isDisabled={!hasNoteId}>
            <CallIcon />
          </IconButton>
        )}
      </Menu.Trigger>
      <ManualPhoneNumberModal
        isOpen={isPhoneNumberModalOpen}
        onClose={() => setIsPhoneNumberModalOpen(false)}
        onConfirm={(phoneNumber: string) => {
          setIsPhoneNumberModalOpen(false);
          initiateCall(phoneNumber);
        }}
      />
    </>
  );
}

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (phoneNumber: string) => void;
};

function ManualPhoneNumberModal({ isOpen, onClose, onConfirm }: ModalProps) {
  const intl = useIntl();
  const { required, phoneNumber } = validators(intl);

  type Values = {
    phone: string;
  };

  const form = useFormFromConfig<Values>({
    fields: {
      phone: {
        validation: required(phoneNumber()),
      },
    },
  });

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header
        title={<FormattedMessage defaultMessage="Enter manual phone number" />}
      />
      <Form form={form} onSubmit={(vals) => onConfirm(vals.phone)}>
        {({ canSubmit }) => (
          <>
            <Modal.Body>
              <Form.PhoneNumber
                name="phone"
                label={<FormattedMessage defaultMessage="Phone number" />}
                size={12}
              />
            </Modal.Body>
            <Modal.Footer>
              <Form.Actions>
                <Button variant="secondary" onPress={onClose}>
                  <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <Button type="submit" isDisabled={!canSubmit}>
                  <FormattedMessage defaultMessage="Call Patient" />
                </Button>
              </Form.Actions>
            </Modal.Footer>
          </>
        )}
      </Form>
    </Modal>
  );
}
