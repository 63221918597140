import { useHistory } from 'react-router-dom';

import { useWizardStateContext } from 'shared/common/Wizard/state';

import { useCNFormContext } from '../../CNFormContext';
import { useFindPreviousNoteWithPatientGoal } from '../regularVisit/generalAssessment/useFindPreviousNoteWithPatientGoal';
import { regVisitPath } from '../regularVisit/paths';

export function useGoToRegActionPlan(): [() => void, boolean] {
  const history = useHistory();
  const { skipSectionStep } = useWizardStateContext();
  const { patientId } = useCNFormContext();
  const { note: noteWithPatientGoal, isLoading: isLoadingNoteWithPrevGoal } =
    useFindPreviousNoteWithPatientGoal(patientId);

  function goToRegActionPlan() {
    if (noteWithPatientGoal) {
      // if our patient did have a previous goal, we're skipping the step
      // related to no previous goals to trigger the progress bar.
      skipSectionStep(regVisitPath('/action-plan', '/no-prev-goal'));

      history.push(regVisitPath('/action-plan', '/prev-goal-progress'));
    } else {
      // if our patient did not have a previous goal, we're skipping the
      // steps related to previous goals to trigger the progress bar.
      skipSectionStep(regVisitPath('/action-plan', '/prev-goal-progress'));
      skipSectionStep(
        regVisitPath('/action-plan', '/prev-goal-progress/discuss'),
      );

      history.push(regVisitPath('/action-plan', '/no-prev-goal'));
    }
  }

  return [goToRegActionPlan, isLoadingNoteWithPrevGoal];
}
