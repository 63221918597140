import type { UseFormReturn } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from 'shared/common/Form';
import { Button } from 'shared/tempo/atom/Button';

import { RemovableRow } from '../RemovableRow';
import type { FormFields } from '../formConfig';
import { addItemButton, sectionTitle } from './carePlanSections.css';

type Props = {
  form: UseFormReturn<FormFields>;
};

export function EmergencyVisits({ form }: Props) {
  const intl = useIntl();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'emergencyVisits',
  });

  return (
    <Form.Section
      title={intl.formatMessage({
        defaultMessage:
          'Emergency Visits and Hospitalizations in the last 12 months',
      })}
      classes={{ title: sectionTitle }}
    >
      {fields.map((field, index) => (
        <RemovableRow
          key={field.id}
          onRemove={() => remove(index)}
          hasError={!!form.formState.errors.emergencyVisits?.[index]}
        >
          <Form.TextField
            required
            size={4}
            label={intl.formatMessage({
              defaultMessage: 'Hospital',
            })}
            name={`emergencyVisits.${index}.hospital`}
          />
          <Form.DatePicker
            required
            size={3}
            label={intl.formatMessage({
              defaultMessage: 'Date',
            })}
            name={`emergencyVisits.${index}.date`}
          />
          <Form.TextField
            required
            size={4}
            label={intl.formatMessage({
              defaultMessage: 'Related Diagnosis',
            })}
            name={`emergencyVisits.${index}.relatedDiagnosis`}
          />
        </RemovableRow>
      ))}
      <div
        className={
          !fields.length ? addItemButton.emptyList : addItemButton.default
        }
      >
        <Button
          variant="secondary"
          size="small"
          onPress={() =>
            append({ hospital: '', date: '', relatedDiagnosis: '' })
          }
        >
          <FormattedMessage defaultMessage="Add Emergency Visit" />
        </Button>
      </div>
    </Form.Section>
  );
}
