import uniq from 'lodash/uniq';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import { ConditionProgram } from 'shared/types/condition.types';

import { useCNFormContext } from '../../../CNFormContext';

export enum Symptom {
  Bloating = 'abdominalFullnessOrBloating',
  ChangesInVision = 'changesInVision',
  ChestPain = 'chestPain',
  DecreasedAppetite = 'decreasedAppetite',
  Dizziness = 'dizziness',
  ExcessiveHunger = 'excessiveHunger',
  FootOrAnkleSwelling = 'footOrAnkleSwelling',
  FrequentUrination = 'frequentUrination',
  Headache = 'headache',
  IncreasedThirst = 'increasedThirst',
  Nausea = 'nausea',
  ShortnessOfBreath = 'shortnessOfBreath',
  Sweating = 'sweating',
}

const HTN_SYMPTOMS = [
  Symptom.ChangesInVision,
  Symptom.ChestPain,
  Symptom.Headache,
  Symptom.ShortnessOfBreath,
  Symptom.Dizziness,
  Symptom.FootOrAnkleSwelling,
] as const;

const T2D_SYMPTOMS = [
  Symptom.Dizziness,
  Symptom.ExcessiveHunger,
  Symptom.Headache,
  Symptom.Nausea,
  Symptom.IncreasedThirst,
  Symptom.FrequentUrination,
  Symptom.Sweating,
] as const;

export const ALL_SYMPTOMS = uniq([...Object.values(Symptom)]);

export const SYMPTOMS_BY_PROGRAM: Record<ConditionProgram, readonly Symptom[]> =
  {
    [ConditionProgram.Hypertension]: [...HTN_SYMPTOMS],
    [ConditionProgram.TypeTwoDiabetes]: [...T2D_SYMPTOMS],
    [ConditionProgram.T2DAndHTN]: uniq([
      ...HTN_SYMPTOMS,
      ...T2D_SYMPTOMS,
    ]) as Symptom[],
    [ConditionProgram.CHF]: [
      Symptom.Bloating,
      Symptom.DecreasedAppetite,
      Symptom.FootOrAnkleSwelling,
      Symptom.Nausea,
      Symptom.ShortnessOfBreath,
    ],

    // unsupported
    [ConditionProgram.COPD]: [],
    [ConditionProgram.CCM]: [],
  };

export function getSymptomI18nMapping(
  intl: IntlShape,
  program: Nullable<ConditionProgram>,
): Record<Symptom, string> {
  return {
    [Symptom.Bloating]: intl.formatMessage({
      defaultMessage: 'Abdominal fullness or bloating',
    }),
    [Symptom.ChangesInVision]: intl.formatMessage({
      defaultMessage: 'Changes in vision',
    }),
    [Symptom.ChestPain]: intl.formatMessage({ defaultMessage: 'Chest pains' }),
    [Symptom.DecreasedAppetite]: intl.formatMessage({
      defaultMessage: 'Decreased appetite',
    }),
    [Symptom.Dizziness]: intl.formatMessage({ defaultMessage: 'Dizziness' }),
    [Symptom.ExcessiveHunger]: intl.formatMessage({
      defaultMessage: 'Excessive hunger',
    }),
    [Symptom.FootOrAnkleSwelling]: intl.formatMessage({
      defaultMessage: 'Foot or ankle swelling (edema)',
    }),
    [Symptom.FrequentUrination]: intl.formatMessage({
      defaultMessage: 'Frequent urination',
    }),
    [Symptom.Headache]: intl.formatMessage({ defaultMessage: 'Headache' }),
    [Symptom.IncreasedThirst]: intl.formatMessage({
      defaultMessage: 'Increased thirst',
    }),
    [Symptom.Nausea]: intl.formatMessage({ defaultMessage: 'Nausea' }),
    [Symptom.ShortnessOfBreath]:
      // CHF uses a more specific description of shortness of breath
      program === ConditionProgram.CHF
        ? intl.formatMessage({
            defaultMessage:
              'Shortness of breath, with activity, at rest, lying down, or during the night',
          })
        : intl.formatMessage({
            defaultMessage: 'Shortness of breath',
          }),
    [Symptom.Sweating]: intl.formatMessage({ defaultMessage: 'Sweating' }),
  };
}

export function useSymptomI18nMapping(): Record<Symptom, string> {
  const intl = useIntl();
  const { program } = useCNFormContext();

  return getSymptomI18nMapping(intl, program);
}
