import { FormattedMessage } from 'react-intl';

import { emptyState } from './CarePlanTab.css';

type Props = {
  hasLegacyApprovedCarePlan: boolean;
};

export function CarePlanEmptyState({ hasLegacyApprovedCarePlan }: Props) {
  return (
    <div className={emptyState.container}>
      <div className={emptyState.content}>
        {hasLegacyApprovedCarePlan ? (
          <>
            <p className={emptyState.text}>
              <FormattedMessage defaultMessage="This patient is enrolled in CCM and may have an existing Care Plan in Google Drive." />
            </p>
            <p className={emptyState.text}>
              <FormattedMessage defaultMessage="To create a new Care Plan in Cadence, start a CCM Care Plan encounter." />
            </p>
          </>
        ) : (
          <>
            <p className={emptyState.text}>
              <FormattedMessage defaultMessage="This patient is eligible for CCM but does not yet have a care plan." />
            </p>
            <p className={emptyState.text}>
              <FormattedMessage defaultMessage="Start a Care Plan encounter to start building a care plan." />
            </p>
          </>
        )}
      </div>
    </div>
  );
}
