import { useIntl } from 'react-intl';

import { useRecentNoteFormData } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useRecentNoteFormData';
import {
  useGetWizardStepValues,
  useWizardFormFromConfig,
} from 'shared/common/Wizard/state';

import { initVisitPath } from '../../initialVisit/paths';
import { REGULAR_VISIT_SECTIONS } from '../../metadata';
import type { EducationModulesReviewFormFields } from '../../shared/EducationModulesReview';
import {
  SharedEducationModulesReview,
  getEducationModulesReviewFormConfig,
} from '../../shared/EducationModulesReview';
import type { PatientGoalFormFields } from '../../shared/setPatientGoal';
import { regVisitPath } from '../paths';

export function EducationModulesReview() {
  const intl = useIntl();

  const path = regVisitPath('/action-plan', '/education-modules-review');
  const { isLoading, data } =
    useRecentNoteFormData<EducationModulesReviewFormFields>([
      path,
      initVisitPath('/action-plan', '/education-modules-review'),
    ]);
  const form = useWizardFormFromConfig<EducationModulesReviewFormFields>(path, {
    ...getEducationModulesReviewFormConfig(intl, data),
    triggerReset: !isLoading,
  });

  const patientGoal = useGetWizardStepValues(
    REGULAR_VISIT_SECTIONS,
    '/action-plan',
    '/set-goal',
  )<PatientGoalFormFields>()?.patientGoal;

  return (
    <SharedEducationModulesReview
      form={form}
      isLoading={isLoading}
      sections={REGULAR_VISIT_SECTIONS}
      nextPath={regVisitPath('/action-plan', '/set-action-steps')}
      patientGoal={patientGoal}
    />
  );
}
