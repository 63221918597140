import type {
  AllTagType,
  TagType,
  Tags,
} from 'shared/types/tagsAndThreshold.types';
import {
  BloodGlucoseMonthlyAverageTagType,
  BloodGlucoseTagType,
  BloodGlucoseWeeklyPercentageOutOfRangeTagType,
  BloodPressureTagType,
  DryWeightTagType,
  HeartRateTagType,
  WeightGainTagType,
} from 'shared/types/tagsAndThreshold.types';

export const getTagsInfo = (tags: Tags) => {
  const tagTypes = Object.keys(tags) as TagType[];
  return {
    hasHighTags: !!tagTypes.find((t) => isHighTagType(t)),
    hasLowTags: !!tagTypes.find((t) => isLowTagType(t)),
  };
};

const HighTagTypes = [
  WeightGainTagType.RapidWeightGainP0,
  WeightGainTagType.WeeklyWeightGainP0,
  DryWeightTagType.DryWeightGainP0,
  BloodPressureTagType.SystolicHighP1,
  BloodPressureTagType.SystolicHighP0,
  BloodPressureTagType.DiastolicHighP0,
  BloodPressureTagType.DiastolicHighP1,
  BloodPressureTagType.MonthlyPercentageHighP1,
  BloodPressureTagType.MonthlyAverageHighP0,
  BloodGlucoseTagType.BloodGlucoseHighP0,
  BloodGlucoseTagType.BloodGlucoseHighP1,
  BloodGlucoseMonthlyAverageTagType.BloodGlucoseMonthlyAverageHighP0,
  BloodGlucoseMonthlyAverageTagType.BloodGlucoseMonthlyAverageHighP1,
  BloodGlucoseMonthlyAverageTagType.BloodGlucoseMonthlyAverageHighP2,
  BloodGlucoseWeeklyPercentageOutOfRangeTagType.BloodGlucoseWeeklyPercentageOutOfRangeHighP0,
  BloodGlucoseWeeklyPercentageOutOfRangeTagType.BloodGlucoseWeeklyPercentageOutOfRangeHighP1,
  BloodGlucoseWeeklyPercentageOutOfRangeTagType.BloodGlucoseWeeklyPercentageOutOfRangeHighP2,
  HeartRateTagType.PulseHighP0,
];

const LowTagTypes = [
  DryWeightTagType.DryWeightLossP1,
  BloodPressureTagType.SystolicLowP0,
  BloodPressureTagType.DiastolicLowP0,
  BloodPressureTagType.SystolicLowP1,
  BloodPressureTagType.DiastolicLowP1,
  BloodPressureTagType.MeanArterialPressureLowP0,
  BloodGlucoseTagType.BloodGlucoseLowP0,
  BloodGlucoseTagType.BloodGlucoseLowP1,
  HeartRateTagType.PulseLowP0,
];

const isHighTagType = (tagType: AllTagType) => HighTagTypes.includes(tagType);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isLowTagType = (tagType: TagType) => LowTagTypes.includes(tagType as any);
