import type { AxiosError } from 'axios';
import type { InfiniteData } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { Device, DevicesResponse } from 'shared/types/device.types';
import type { PaginatedData } from 'shared/types/pagination.types';
import type { SortDirection } from 'shared/types/sorting.types';
import Session from 'shared/utils/session';

type Params = Partial<{
  page: number;
  pageSize: number;
  sortBy: string;
  orderBy: SortDirection;
  imeiserial: string;
}>;

const getDeviceAssignmentParams = (params: Params) => ({
  ...(params.page && { page: params.page }),
  ...(params.pageSize && { page_size: params.pageSize }),
  ...(params.sortBy && { sort_by: params.sortBy }),
  ...(params.orderBy && { order_by: params.orderBy }),
  ...(params.imeiserial && {
    imeiserial: params.imeiserial,
  }),
});

const DEVICE_ASSIGNMENTS_QUERY_KEY_BASE = [
  'pms',
  'api',
  'v1',
  'device_assignments',
];

export function useAssignedDevices(
  patientId: string,
  params: Params,
  enabled: boolean = true,
) {
  return useQuery<DevicesResponse>(
    [
      ...DEVICE_ASSIGNMENTS_QUERY_KEY_BASE,
      patientId,
      getDeviceAssignmentParams(params),
    ],
    { enabled: Boolean(patientId) && enabled },
  );
}

export function useUnassignedDevices(params: Params) {
  return useQuery<DevicesResponse>([
    ...DEVICE_ASSIGNMENTS_QUERY_KEY_BASE,
    'unassigned',
    getDeviceAssignmentParams(params),
  ]);
}

export function useBulkAssignDevices(patientId: string) {
  const queryClient = useQueryClient();
  return useMutation(
    (deviceIds: string[]) =>
      Promise.all(
        deviceIds.map((id) =>
          Session.Api.post<Device>('/pms/api/v1/device_assignments', {
            patient_id: patientId,
            device_id: id,
          }),
        ),
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(DEVICE_ASSIGNMENTS_QUERY_KEY_BASE);
      },
    },
  );
}

export function useBulkUnassignDevices() {
  const queryClient = useQueryClient();
  return useMutation(
    (deviceIds: string[]) =>
      Promise.all(
        deviceIds.map((id) =>
          Session.Api.delete<Device>(`/pms/api/v1/device_assignments/${id}`),
        ),
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(DEVICE_ASSIGNMENTS_QUERY_KEY_BASE);
      },
    },
  );
}

export const DEVICES_LIST_INFINITE_QUERY_KEY = [
  '/pms/api/v1/devices?sort_by=updated_at&order_by=desc',
  'infinite',
];

// This can be used to retry provisioning a device if provisioning failed on the initial upload
export function useProvisionDevice() {
  const queryClient = useQueryClient();
  return useMutation(
    (device_id: number) =>
      Session.Api.put<Device>(`/pms/api/v1/devices/${device_id}/provision`),
    {
      onSettled: (response, err) => {
        const deviceFromError = (err as AxiosError<{ data?: Device }>)?.response
          ?.data?.data;
        const fetchedDevice = response?.data || deviceFromError;
        if (!fetchedDevice) {
          queryClient.resetQueries(DEVICES_LIST_INFINITE_QUERY_KEY);
          return;
        }

        const infiniteQueryData = queryClient.getQueryData<
          InfiniteData<PaginatedData<Device>>
        >(DEVICES_LIST_INFINITE_QUERY_KEY);

        if (infiniteQueryData) {
          queryClient.setQueryData(DEVICES_LIST_INFINITE_QUERY_KEY, {
            ...infiniteQueryData,
            pages: infiniteQueryData.pages.map(({ data, ...rest }) => ({
              ...rest,
              data: data.map((device) => {
                if (device.id !== fetchedDevice.id) return device;

                return fetchedDevice;
              }),
            })),
          });
        }
      },
    },
  );
}
