import identity from 'lodash/identity';
import { useIntl } from 'react-intl';

import { MedInfo } from 'pages/patients/PatientMedications/MedInfo';
import { lastMedReviewChange } from 'pages/patients/PatientMedications/utils/medChangeUtils';
import { getMedGroups } from 'pages/patients/PatientMedications/utils/sortMeds';
import type {
  MedicationChange,
  PatientMedications,
} from 'shared/generated/grpcGateway/medication.pb';

import type { TypeOfEncounter } from '../../Notes.types';
import { isCCMVisit } from '../../utils/encounterTypeUtils';
import { previewList } from '../NotePreview.css';

export function MedicationsNotePreview({
  data,
  noteId,
  encounterType,
  hideOtherMeds = false,
}: {
  data: PatientMedications;
  noteId: number;
  encounterType?: TypeOfEncounter;
  hideOtherMeds?: boolean;
}) {
  const intl = useIntl();
  const { diseaseSpecificMeds, otherMeds } = getMedGroups(data);

  // Filter for filtering out medications that were removed, unless they were removed
  // in the current note that's in progress
  function showMedChange(medChange: MedicationChange) {
    return (
      medChange.status !== 'INACTIVE' || Number(medChange.noteId) === noteId
    );
  }

  const dsmRows = diseaseSpecificMeds
    .map((m) => {
      const medChange = lastMedReviewChange(noteId, m.medChanges);
      if (!medChange || !showMedChange(medChange)) {
        return null;
      }
      return (
        <MedInfo
          key={medChange.name}
          medChange={medChange}
          isPreview
          noteId={noteId}
        />
      );
    })
    .filter(identity);

  const omRows = hideOtherMeds
    ? []
    : otherMeds
        .filter((mOrMc) => showMedChange(mOrMc.medChange))
        .map(({ medChange }) => (
          <MedInfo
            key={medChange.name}
            medChange={medChange}
            isPreview
            noteId={noteId}
          />
        ));

  if (isCCMVisit(encounterType)) {
    return Boolean(dsmRows.length) || Boolean(omRows.length) ? (
      <>
        <div>
          {intl.formatMessage({
            defaultMessage: 'Medication Review:',
          })}
        </div>
        <ul className={previewList}>
          {dsmRows}
          {omRows}
        </ul>
      </>
    ) : null;
  }
  return (
    <>
      {Boolean(dsmRows.length) && (
        <>
          <div>
            {intl.formatMessage({
              defaultMessage: 'Disease-specific medications:',
            })}
          </div>
          <ul className={previewList}>{dsmRows}</ul>
        </>
      )}
      {Boolean(omRows.length) && (
        <>
          <div>
            {intl.formatMessage({
              defaultMessage: 'Other medications:',
            })}
          </div>
          <ul className={previewList}>{omRows}</ul>
        </>
      )}
    </>
  );
}
