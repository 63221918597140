import cx from 'classnames';
import { type ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useReferenceMedication } from 'pages/patients/PatientMedications/referenceMedications.queries';
import type {
  MedicationChange,
  MedicationChangeFrequency,
} from 'shared/generated/grpcGateway/medication.pb';
import { Tag } from 'shared/tempo/atom/Tag';

import { Actions } from './Actions';
import { NotTitratingReasonSelect } from './NotTitratingReasonSelect';
import { Protocols } from './Protocols';
import {
  container,
  notTitrating,
  protocols,
  tag,
} from './SuggestedTitration.css';
import { SuggestedTitrationMode } from './types';
import type { TitrationRecommendation } from './useTitrationRecommendation';

type Props = {
  children: ReactNode;
  referenceMedicationId: string;
  titrationRecommendation: TitrationRecommendation;
  readOnly: boolean;
} & (
  | {
      readOnly: true;
      onEdit?: never;
      onCancelEdit?: never;
      isEditing?: never;
    }
  | {
      readOnly: false;
      onEdit: (proposedChange: MedicationChange) => void;
      onCancelEdit: () => void;
      isEditing: boolean;
    }
);

export function SuggestedTitration({
  children,
  referenceMedicationId,
  titrationRecommendation,
  onEdit,
  onCancelEdit,
  isEditing,
  readOnly,
}: Props) {
  const [mode, setMode] = useState(SuggestedTitrationMode.Default);
  const { data: referenceMed, isLoading } = useReferenceMedication(
    referenceMedicationId,
    true,
  );

  const recommendedRxNorm = referenceMed?.rxNorms?.find(
    (rxNorm) => rxNorm.rxcui === titrationRecommendation.rxcui,
  );

  if (isLoading || !recommendedRxNorm) {
    return <>{children}</>;
  }

  const toggleMode = (newMode: SuggestedTitrationMode) => {
    if (readOnly) {
      return;
    }

    setMode((currentMode) =>
      currentMode === newMode ? SuggestedTitrationMode.Default : newMode,
    );
    onCancelEdit();
  };

  return (
    <div className={container}>
      <div>
        <Tag variant="info" className={tag.decision}>
          {readOnly ? (
            <FormattedMessage defaultMessage="Titration suggestion" />
          ) : (
            <FormattedMessage defaultMessage="Titration decision" />
          )}
        </Tag>
        {children}
      </div>
      {!isEditing && (
        <>
          <div
            className={cx(protocols.wrapper, {
              [protocols.wrapperBorder]: !readOnly,
            })}
          >
            <Protocols
              titrationRecommendation={titrationRecommendation}
              recommendedRxNorm={recommendedRxNorm}
            />
          </div>
          {!readOnly && (
            <Actions
              mode={mode}
              onEdit={() => {
                setMode(SuggestedTitrationMode.Default);
                onEdit?.({
                  rxnorm: recommendedRxNorm,
                  // if we make it this far, these values will be defined. should be safe to cast
                  doseQuantities: [
                    titrationRecommendation.doseQuantity as number,
                  ],
                  frequencies: [
                    titrationRecommendation.frequency as MedicationChangeFrequency,
                  ],
                });
              }}
              onAccept={() => toggleMode(SuggestedTitrationMode.Accepted)}
              onReject={() => toggleMode(SuggestedTitrationMode.NotTitrating)}
            />
          )}
        </>
      )}
      {mode === SuggestedTitrationMode.NotTitrating && (
        <div className={notTitrating.container}>
          <NotTitratingReasonSelect />
        </div>
      )}
    </div>
  );
}
