import cx from 'classnames';

import { useNumberOfPatientInfoColumns } from 'pages/patients/PatientInfoSummary/numberOfPatientInfoColumns';
import { useNoteEditorContext } from 'pages/patients/patientDetails/ui/Notes/NoteEditorContext';
import { CalendarIcon } from 'shared/assets/icons/CalendarIcon';
import { LoadingPlaceholder } from 'shared/common/LoadingPlaceholder';
import { Skeleton } from 'shared/tempo/atom/Skeleton';
import { color } from 'shared/tempo/theme';

import { NextScheduledVisit } from './NextScheduledVisit';
import {
  lastNpVisitContainerV2,
  lastNpVisitIconContainer,
  schedulingContainerV2,
} from './PatientScheduling.css';
import { useGetNextScheduledAppointment } from './appointments.queries';

export const PatientScheduling = ({ patientId }: { patientId: string }) => {
  const { isEditorOpen } = useNoteEditorContext();
  const numberOfPatientInfoColumns = useNumberOfPatientInfoColumns();

  const { data: nextScheduledAppointment, isLoading: isLoadingAppointment } =
    useGetNextScheduledAppointment(patientId, true);
  // We want to always show the next appointment if there is one, even if it's in the past
  const nextAppointment = nextScheduledAppointment?.nextAppointment;

  return (
    <div
      className={cx(schedulingContainerV2.primary, {
        [schedulingContainerV2.error]: nextAppointment?.patientNoShow,
      })}
    >
      <LoadingPlaceholder
        isLoading={isLoadingAppointment}
        placeholder={<Skeleton variant="text" width="100%" />}
      >
        <div
          className={cx({
            [lastNpVisitContainerV2.editorOpen]: isEditorOpen,
            [lastNpVisitContainerV2.fourCols]:
              !isEditorOpen && numberOfPatientInfoColumns === 4,
            [lastNpVisitContainerV2.lessThan4cols]:
              !isEditorOpen && numberOfPatientInfoColumns < 4,
          })}
        >
          <div className={lastNpVisitIconContainer}>
            <CalendarIcon
              fill={color.Theme.Light['Base Font']}
              fontSize="small"
            />
          </div>
          <NextScheduledVisit
            appointment={nextAppointment}
            patientId={patientId}
          />
        </div>
      </LoadingPlaceholder>
    </div>
  );
};
