import cx from 'classnames';
import type { ReactNode } from 'react';
import { useRef } from 'react';
import type { AriaSelectProps } from 'react-aria';
import { HiddenSelect, useSelect } from 'react-aria';
import { useSelectState } from 'react-stately';

import Chevron from 'shared/assets/svgs/chevron.svg?react';
import { ListBox } from 'shared/tempo/@labs/atom/ListBox';
import { Popover } from 'shared/tempo/@labs/molecule/Popover';
import { Button } from 'shared/tempo/atom/Button';
import type { Variant } from 'shared/tempo/atom/Button/Button';

import { button, chevron, listBox, popover, subtleChevron } from './Filter.css';

type Props<T> = {
  emptyMessage: ReactNode;
  buttonVariant: Variant;
} & AriaSelectProps<T>;

export function Filter<T extends object>({
  emptyMessage,
  buttonVariant,
  ...props
}: Props<T>) {
  const state = useSelectState(props);
  const ref = useRef(null);
  const { triggerProps, valueProps, menuProps } = useSelect(props, state, ref);

  return (
    <>
      <HiddenSelect state={state} triggerRef={ref} {...props} />
      <Button
        {...triggerProps}
        ref={ref}
        size="small"
        variant={buttonVariant}
        className={button[buttonVariant]}
      >
        <span {...valueProps}>
          {state.selectedItem?.rendered ?? emptyMessage}
        </span>
        <Button.Icon>
          <Chevron
            className={cx({
              [chevron.open]: state.isOpen,
              [chevron.default]: !state.isOpen,
              [subtleChevron]: buttonVariant === 'tertiary',
            })}
          />
        </Button.Icon>
      </Button>
      {state.isOpen && (
        <Popover
          state={state}
          triggerRef={ref}
          placement="bottom end"
          className={popover}
        >
          <ListBox.Controlled
            {...menuProps}
            state={state}
            className={listBox}
          />
        </Popover>
      )}
    </>
  );
}
