import type { WidgetProps } from '@rjsf/core';

import { Checkbox, FormControlLabel } from 'deprecated/mui';

import { getLabel } from '../SchemaDrivenForm';
import { formLabel } from '../SchemaDrivenForm.css';
import { getOmitExtraDataOnChange } from '../getOmitExtraDataOnChange';

export function CheckboxWidget({
  onChange: rjsfOnChange,
  value,
  id,
  label: rjsfLabel,
  required,
  uiSchema: { 'ui:label': uiLabel, 'ui:options': uiOptions },
}: WidgetProps) {
  const onChange = getOmitExtraDataOnChange(rjsfOnChange);
  const label = getLabel(
    'boolean',
    id,
    uiLabel,
    rjsfLabel,
    required,
    uiOptions,
  );
  return (
    <FormControlLabel
      classes={{
        label: formLabel,
      }}
      control={<Checkbox />}
      label={label || ''}
      onClick={() => onChange(!value)}
      checked={value || false}
    />
  );
}
