import cx from 'classnames';
import type { HTMLAttributes } from 'react';

import AlertTriangle from 'shared/assets/svgs/alertTriangle.svg?react';

import { error, errorIcon } from './ValidationError.css';

export function ValidationError({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props} className={cx(error, className)}>
      <AlertTriangle className={errorIcon} /> {children}
    </div>
  );
}
