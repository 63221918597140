import { createCtx } from 'shared/hooks/createCtx';

type TaskCardContext = {
  isHovering: boolean;
  isEditingComment: boolean;
  setIsEditingComment: (val: boolean) => void;
};

export const [useTaskCardContext, TaskCardContextProvider] =
  createCtx<TaskCardContext>('TaskCardContext');
