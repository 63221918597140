import { TypeOfEncounter } from 'pages/patients/patientDetails/ui/Notes/Notes.types';

import { htn } from './htn';
import { t2d } from './t2d';
import { t2dAndHtn } from './t2dAndHtn';

export const initialRnVisit = {
  [TypeOfEncounter.INITIAL_RN_VISIT_DEPRECATED]: {
    ...htn,
    ...t2d,
    ...t2dAndHtn,
  },
};
