import { FormattedMessage, useIntl } from 'react-intl';

import { Modal } from 'shared/common/Modal';
import { Button } from 'shared/tempo/atom/Button';

type Props = {
  open: boolean;
  onClose: () => void;
  onReopen: () => void;
};

export function ReopenTaskModal({ open, onClose, onReopen }: Props) {
  const intl = useIntl();
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header
        title={intl.formatMessage({
          defaultMessage: 'Task Details',
        })}
      />
      <Modal.Body>
        <FormattedMessage defaultMessage="The task will be assigned to the last person who was assigned." />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onPress={onClose}>
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button
          variant="primary"
          onPress={() => {
            onReopen();
            onClose();
          }}
        >
          <FormattedMessage defaultMessage="Reopen Task" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
