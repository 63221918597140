import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Script } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { formatProviderForScript } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/script.utils';
import { Wizard } from 'shared/common/Wizard';
import { usePatientContext } from 'shared/hooks/queries';
import { flexContainer } from 'shared/jsStyle/flex.css';
import { gap } from 'shared/jsStyle/gap.css';

import { useCNFormContext } from '../../../CNFormContext';
import { CYCLE_17_INIT_VISIT_SECTIONS } from '../metadata';

export function IntroProgramDescription() {
  const intl = useIntl();
  const { patientId } = useCNFormContext();
  const { data: context, isLoading: isLoadingContext } =
    usePatientContext(patientId);
  const provider = formatProviderForScript(
    context?.primaryPhysicianGivenName,
    context?.primaryPhysicianFamilyName,
    context?.primaryPhysicianRole,
  );

  return (
    <Wizard.Step
      isLoading={!context || isLoadingContext}
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      title={intl.formatMessage({ defaultMessage: 'Introduction' })}
    >
      <div className={cx(flexContainer.column, gap.M)}>
        <Script>
          <FormattedMessage
            defaultMessage="You can think of Cadence as an extension of {provider}'s office. We act as a second pair of eyes on your health, and will be in close contact with {provider} throughout your time with us. Our Care Team will review each of your readings, day or night, so if something is not right, we will be there to check on you. You are also welcome to call or text us anytime if you have any questions about your health."
            values={{ provider }}
          />
        </Script>
        <Script>
          <FormattedMessage
            defaultMessage="{provider} might not discuss your Cadence data during every visit. This is because they know we are managing your day-to-day readings, and if things are looking good, there might not be a need to discuss it in detail. However, you are always welcome to bring up your Cadence results during your appointments to discuss any concerns or insights. This allows your provider to make the most of your visits and focus on your needs during your visits."
            values={{ provider }}
          />
        </Script>
      </div>
    </Wizard.Step>
  );
}
