import type {
  ChangeEvent,
  ReactElement,
  MouseEvent as ReactMouseEvent,
} from 'react';
import { cloneElement } from 'react';

import type { RadioGroupProps } from 'deprecated/mui';
import { RadioGroup as MUIRadioGroup } from 'deprecated/mui';

type ChangeHandler = (
  event:
    | ChangeEvent<HTMLInputElement>
    | ReactMouseEvent<HTMLDivElement, MouseEvent>,
  value: string,
) => void;

type Props = {
  children: ReactElement[];
  onChange?: ChangeHandler;
} & RadioGroupProps;

const RADIO_IDENTIFIER_ATTR = 'data-radio-item';

export function RadioGroup({
  children,
  onChange,
  onMouseDown,
  ...baseProps
}: Props) {
  return (
    <MUIRadioGroup
      {...baseProps}
      onMouseDown={(e) =>
        handleMouseDownChange(e, (value) => {
          onChange?.(e, value);
        })
      }
    >
      {children.map((child) =>
        cloneElement(child, {
          [RADIO_IDENTIFIER_ATTR]: true,
        }),
      )}
    </MUIRadioGroup>
  );
}

/*
 * Enables the radio group to be handled via
 * mouse down events
 */
function handleMouseDownChange(
  e: ReactMouseEvent<HTMLDivElement, MouseEvent>,
  onChange: (val: string) => void,
) {
  const { target } = e;
  if (target instanceof HTMLElement) {
    const radioBtn = target
      .closest(`[${RADIO_IDENTIFIER_ATTR}]`)
      ?.querySelector<HTMLInputElement>('input[type="radio"]');

    if (radioBtn?.value) {
      onChange(radioBtn.value);
    }
  }
}
