import isEmpty from 'lodash/isEmpty';
import type { FieldValues, Path, PathValue } from 'react-hook-form';

import type { FormConfig } from 'shared/common/Form';
import type { ConfiguredForm } from 'shared/common/Form/FormContainer';
import { useFormFromConfig } from 'shared/common/Form/FormContainer';
import { useOnMount } from 'shared/hooks/useOnMount';

import { useWizardStateContext } from './WizardStateContext';

/**
 * Gets the form from a config and injects wizard form state data into the defaults
 */
export function useWizardFormFromConfig<TFieldValues extends FieldValues>(
  fullStepPath: string,
  config: FormConfig,
): ConfiguredForm<TFieldValues> {
  const {
    wizardFormData: { data: stepData },
  } = useWizardStateContext();
  const storeValues = stepData[fullStepPath]?.values || {};
  const hasStoreData = !isEmpty(storeValues);
  const form = useFormFromConfig<TFieldValues>({
    ...config,
    // If data already exists in wizard state, don't trigger reset form
    // so we preserve data on back navigation / autosave load
    triggerReset: hasStoreData ? false : config.triggerReset,
  });

  // On mount, load and set form values from wizard state
  useOnMount(() => {
    Object.entries(storeValues).forEach(([fieldName, value]) => {
      form.setValue(
        fieldName as Path<TFieldValues>,
        value as PathValue<TFieldValues, Path<TFieldValues>>,
        {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        },
      );
    });
  });
  return form;
}
