import type { MedicationChange } from 'shared/generated/grpcGateway/medication.pb';
import { createCtx } from 'shared/hooks/createCtx';

export type OriginalMedChanges = Record<string, MedicationChange>;

type PatientMedicationsCtx = {
  patientId: string;
  noteId: Maybe<number>;
  showRequiredActions: boolean;

  // Store med changes on first load so that meds are-resorted after actions are taken that would change the sort order
  setOriginalMedChange: (mc: MedicationChange, refId: Maybe<string>) => void;
};

export const [usePatientMedicationsContext, PatientMedicationsContextProvider] =
  createCtx<PatientMedicationsCtx>('PatientMedicationsCtx');
