import type { ReactElement } from 'react';

import { RadioGroup as SharedRadioGroup } from 'shared/common/RadioGroup';

import type { BaseFieldProps } from '../../BaseField';
import { BaseField } from '../../BaseField';
import { radioGroup } from './RadioGroup.css';

type Props = {
  children: ReactElement[];
  compact?: boolean;
  parseValue?: (val: string) => void;
} & BaseFieldProps;

export function RadioGroup({
  compact,
  children,
  parseValue,
  ...baseProps
}: Props) {
  return (
    <BaseField {...baseProps}>
      {({ controller: { field } }) => (
        <SharedRadioGroup
          row={compact || false}
          classes={{ root: radioGroup }}
          value={field.value}
          onChange={(_, value) => {
            field.onChange(parseValue ? parseValue(value) : value);
          }}
        >
          {children}
        </SharedRadioGroup>
      )}
    </BaseField>
  );
}
