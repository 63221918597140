import type { UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { SMSHistorySchema } from 'shared/generated/api/notification';
import {
  OutboundSmsService,
  SmsHistoryService,
} from 'shared/generated/api/notification';

import Session from '../../utils/session';

const POLLING_INTERVAL = 10000;

const NOTIFICATIONS_COMMUNICATIONS_KEY_BASE = [
  'notification',
  'api',
  'v1',
  'communications',
] as const;

const communicationsKeys = {
  smsHistory: (patientId: string) =>
    [
      ...NOTIFICATIONS_COMMUNICATIONS_KEY_BASE,
      'sms_history',
      patientId,
    ] as const,
  recording: (recordingId: string) =>
    [
      ...NOTIFICATIONS_COMMUNICATIONS_KEY_BASE,
      'recording_stream',
      recordingId,
    ] as const,
};

export function useSmsHistory(
  patientId: string,
  config: UseQueryOptions<SMSHistorySchema> = {},
) {
  return useQuery({
    queryKey: communicationsKeys.smsHistory(patientId),
    queryFn: () =>
      SmsHistoryService.getNotificationApiV1CommunicationsSmsHistory(patientId),
    ...config,
    refetchInterval: POLLING_INTERVAL,
  });
}

export function useSendSms(
  patientId: Nullable<string>,
  caregiverId: Nullable<string>,
) {
  const queryClient = useQueryClient();
  return useMutation(
    (smsBody: string) =>
      OutboundSmsService.postNotificationApiV1CommunicationsSendSms({
        message_body: smsBody,
        patient_id: patientId,
        caregiver_id: caregiverId,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          communicationsKeys.smsHistory(patientId ?? ''),
        );
      },
    },
  );
}

export function useRecordingStream(recordingId: string) {
  return useQuery({
    queryKey: communicationsKeys.recording(recordingId),
    queryFn: async () => {
      const { data } = await Session.Api.get<Blob>(
        `/messaging/recordings?recordingSid=${recordingId}`,
        {
          responseType: 'blob',
        },
      );

      const blob = new Blob([data], { type: 'audio/mpeg' });
      const stream = blob.stream();
      const streamReader = stream.getReader();
      const chunks = [];
      let receivedLength = 0;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        // eslint-disable-next-line no-await-in-loop
        const { done, value } = await streamReader.read();
        if (done) {
          break;
        }
        chunks.push(value);
        receivedLength += value.length;
      }

      const chunksAll = new Uint8Array(receivedLength);
      let position = 0;
      for (let i = 0; i < chunks.length; i++) {
        const chunk = chunks[i];
        chunksAll.set(chunk, position);
        position += chunk.length;
      }

      return URL.createObjectURL(new Blob([chunksAll]));
    },
  });
}
