import uniq from 'lodash/uniq';
import { useIntl } from 'react-intl';

import type { Condition, Program } from 'shared/generated/grpcGateway/pms.pb';
import {
  PatientStatusEnum,
  ProgramProgramStatus,
  ProgramProgramType,
} from 'shared/generated/grpcGateway/pms.pb';

export function useProgramTypeMapping() {
  const intl = useIntl();
  return {
    [ProgramProgramType.RPM]: intl.formatMessage({
      defaultMessage: 'RPM',
    }),
    [ProgramProgramType.CCM]: intl.formatMessage({
      defaultMessage: 'CCM',
    }),
    [ProgramProgramType.PROGRAM_TYPE_UNSPECIFIED]: intl.formatMessage({
      defaultMessage: 'Unspecified',
    }),
  };
}

export function useProgramStatusMapping() {
  const intl = useIntl();
  return {
    [ProgramProgramStatus.ELIGIBLE]: intl.formatMessage({
      defaultMessage: 'Eligible',
    }),
    [ProgramProgramStatus.ORDERED]: intl.formatMessage({
      defaultMessage: 'Ordered',
    }),
    [ProgramProgramStatus.SELECTED]: intl.formatMessage({
      defaultMessage: 'Selected',
    }),
    [ProgramProgramStatus.ENROLLED]: intl.formatMessage({
      defaultMessage: 'Enrolled',
    }),
    [ProgramProgramStatus.DISENROLLED]: intl.formatMessage({
      defaultMessage: 'Disenrolled',
    }),
    [ProgramProgramStatus.SUGGESTED]: intl.formatMessage({
      defaultMessage: 'Suggested',
    }),
    [ProgramProgramStatus.PROGRAM_STATUS_UNSPECIFIED]: intl.formatMessage({
      defaultMessage: 'Unspecified',
    }),
  };
}

export function getRpmConditions(
  programs: Maybe<Program[]>,
  status: Maybe<PatientStatusEnum>,
): Condition[] {
  if (!programs?.length || !status) {
    return [];
  }
  let programStatus: ProgramProgramStatus;
  if ([PatientStatusEnum.ENROLLED].includes(status)) {
    programStatus = ProgramProgramStatus.ENROLLED;
  } else {
    programStatus = ProgramProgramStatus.SELECTED;
  }
  const foundProgram = programs.find(
    (program) =>
      program.programType === ProgramProgramType.RPM &&
      program.programStatus === programStatus,
  );

  const conditions =
    foundProgram?.conditions
      ?.filter((condition) => Boolean(condition.conditionType))
      .map((condition) => condition.conditionType as Condition) || [];
  return uniq(conditions);
}
