import { FormattedMessage } from 'react-intl';

import { logger } from 'logger';

import { ConditionProgram } from './types';

export function vitalType(conditionProgram: ConditionProgram) {
  switch (conditionProgram) {
    case ConditionProgram.HEART_FAILURE:
    case ConditionProgram.HYPERTENSION:
      return <FormattedMessage defaultMessage="blood pressure" />;
    case ConditionProgram.TYPE_2_DIABETES:
      return <FormattedMessage defaultMessage="blood glucose" />;
    case ConditionProgram.T2D_AND_HYPERTENSION:
      return (
        <FormattedMessage defaultMessage="blood sugar and blood pressure" />
      );
    default:
      logger.error(`Unexpected condition program: ${conditionProgram}`);
      return null;
  }
}

export function vitalTypeCapitalized(conditionProgram: ConditionProgram) {
  switch (conditionProgram) {
    case ConditionProgram.HEART_FAILURE:
    case ConditionProgram.HYPERTENSION:
      return <FormattedMessage defaultMessage="Blood pressure" />;
    case ConditionProgram.TYPE_2_DIABETES:
      return <FormattedMessage defaultMessage="Blood glucose" />;
    case ConditionProgram.T2D_AND_HYPERTENSION:
      return (
        <FormattedMessage defaultMessage="Blood sugar and blood pressure" />
      );
    default:
      logger.error(`Unexpected condition program: ${conditionProgram}`);
      return null;
  }
}

export function programShortLabel(conditionProgram: ConditionProgram) {
  switch (conditionProgram) {
    case ConditionProgram.HEART_FAILURE:
      return <FormattedMessage defaultMessage="heart failure" />;
    case ConditionProgram.HYPERTENSION:
      return <FormattedMessage defaultMessage="hypertension" />;
    case ConditionProgram.TYPE_2_DIABETES:
      return <FormattedMessage defaultMessage="diabetes" />;
    case ConditionProgram.T2D_AND_HYPERTENSION:
      return <FormattedMessage defaultMessage="diabetes and hypertension" />;
    default:
      logger.error(`Unexpected condition program: ${conditionProgram}`);
      return null;
  }
}
