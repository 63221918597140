import { format, parseISO } from 'date-fns';
import { useIntl } from 'react-intl';

import HistoryIcon from 'shared/assets/svgs/history.svg?react';
import { IconButton } from 'shared/tempo/atom/IconButton';

import { actionIcon, updatedDateText } from './styles.css';

export function HistoryIconButton({
  updatedDate,
  onShowHistory,
}: {
  updatedDate?: string;
  onShowHistory: () => void;
}) {
  const intl = useIntl();

  return (
    <IconButton.Tooltip
      content={
        <div>
          {intl.formatMessage({ defaultMessage: 'History' })}
          {updatedDate && <LastUpdatedDate date={updatedDate} />}
        </div>
      }
    >
      <IconButton variant="tertiary" size="small" onPress={onShowHistory}>
        <HistoryIcon className={actionIcon} />
      </IconButton>
    </IconButton.Tooltip>
  );
}

function LastUpdatedDate({ date }: { date: string }) {
  const intl = useIntl();
  const updatedDate = parseISO(date);
  return (
    <div className={updatedDateText}>
      <div>{intl.formatMessage({ defaultMessage: 'Last update' })}</div>
      {format(updatedDate, 'MM/dd/yyyy')}
    </div>
  );
}
