import { FormattedMessage } from 'react-intl';

import type { SelectChangeEvent } from 'shared/common/Select';
import { Option, Select } from 'shared/common/Select';
import { VitalType } from 'shared/types/vitals.types';

type VitalsDropdownProps = {
  onVitalSelect: (vitals: VitalType) => void;
  selectedVital: VitalType;
};

export function VitalsDropdown({
  onVitalSelect,
  selectedVital,
}: VitalsDropdownProps) {
  const handleVitalSelectChange = (event: SelectChangeEvent<VitalType>) => {
    const { value } = event.target;
    onVitalSelect(value as VitalType);
  };

  return (
    <Select
      id="vitalsDropdown"
      customStyle={{ width: 170 }}
      value={selectedVital}
      onChange={handleVitalSelectChange}
    >
      <Option value={VitalType.BloodPressure}>
        <FormattedMessage defaultMessage="Blood Pressure" />
      </Option>
      <Option value={VitalType.HeartRate}>
        <FormattedMessage defaultMessage="Heart Rate" />
      </Option>
      <Option value={VitalType.Weight}>
        <FormattedMessage defaultMessage="Weight" />
      </Option>
      <Option value={VitalType.BloodGlucose}>
        <FormattedMessage defaultMessage="Blood Glucose" />
      </Option>
    </Select>
  );
}
