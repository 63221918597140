import { conditionsToProgram } from 'pages/patients/patientDetails/ui/Notes/NoteEditor/templates/hooks';
import { usePatientDetails } from 'shared/hooks/queries';
import { getRpmConditionsFromProgramAndStatus } from 'shared/patient/conditions.utils';

export function usePatientProgram(patientId: string) {
  const { data, isLoading } = usePatientDetails(patientId, false);
  const rpmConditions = getRpmConditionsFromProgramAndStatus(
    data?.programs,
    data?.status,
  );

  return { program: conditionsToProgram(rpmConditions), isLoading };
}
