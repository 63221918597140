import cx from 'classnames';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { settings } from 'config';
import { logger } from 'logger';
import { bigButton } from 'pages/auth/ui/shared/auth.css';
import { ErrorScene, body } from 'shared/common/ErrorScene';
import { Button } from 'shared/tempo/atom/Button';

import { button } from './ErrorBoundary.css';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error: Error) {
    const appVersion = settings.VITE_VERSION;

    // For stale asset errors, only attempt one refresh per app-version to
    // avoid an infinite loop
    if (isStaleAssetsError(error) && !didRefreshAppVersion(appVersion)) {
      // Perform a hard refresh of the page in order to pull in the latest
      // index file with updated asset references
      refreshAppAssets(appVersion);
    } else {
      // Log unhandled errors
      logger.error(error.message, error);
      this.setState({ hasError: true });
    }
  }

  public render(): React.ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <ErrorScene fullscreen>
          {(openReport) => (
            <>
              <p className={body}>
                <FormattedMessage defaultMessage="Please return to the last page and try again. If the problem persists, report the error below so we can address it. We apologize for the inconvenience." />
              </p>
              <Button
                id="error-boundary--report-error"
                variant="primary"
                className={cx(button, bigButton)}
                onPress={openReport}
              >
                <FormattedMessage defaultMessage="Report error" />
              </Button>
            </>
          )}
        </ErrorScene>
      );
    }
    return children;
  }
}

/*
 * Helpers
 */

function isStaleAssetsError(error: Error): boolean {
  const { message } = error;
  return (
    message.startsWith('Unable to preload CSS for') ||
    message.startsWith('Failed to fetch dynamically imported module')
  );
}

const REFRESH_ATTEMPTED_KEY = 'refresh_attempted';

function didRefreshAppVersion(appVersion: string = '') {
  return sessionStorage.getItem(REFRESH_ATTEMPTED_KEY) === appVersion;
}

function refreshAppAssets(appVersion: string = '') {
  sessionStorage.setItem(REFRESH_ATTEMPTED_KEY, appVersion);
  window.location.reload();
}
