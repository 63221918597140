import get from 'lodash/get';
import type { FieldError } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Block } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Block/Block';
import TrashIcon from 'shared/assets/svgs/trash.svg?react';
import { flexContainer, flexGrow } from 'shared/jsStyle/flex.css';
import { Button } from 'shared/tempo/atom/Button';
import { Tag } from 'shared/tempo/atom/Tag';
import { Tooltip } from 'shared/tempo/atom/Tooltip';
import { TextField } from 'shared/tempo/molecule/TextField';

import {
  bottomRowFields,
  container,
  dependenciesTag,
  removeButtonContainer,
} from './ActionStep.css';
import type { Field } from './ActionStepsField';
import type { TActionStep } from './types';

type Props = {
  actionStep: TActionStep;
  onChange: (name: Field, value: string) => void;
  onRemove: () => void;
  stepNumber: number;
  validation?: Record<Field, FieldError>;
};

export function ActionStep({
  actionStep,
  onRemove,
  onChange,
  stepNumber,
  validation,
}: Props) {
  const intl = useIntl();
  const { step, frequency, dependencies } = actionStep;
  const anyFieldFilledOut = [step, frequency, dependencies].some(Boolean);
  const canRemove = !anyFieldFilledOut;

  return (
    <Block className={container}>
      <div>
        <TextField
          value={step}
          errorMessage={get(validation, ['step', 'message'])}
          onChange={(val) => onChange('step', val)}
          placeholder={intl.formatMessage({
            defaultMessage: 'Go for a walk',
          })}
          label={
            <FormattedMessage
              defaultMessage="Action Step {stepNumber}"
              values={{ stepNumber: stepNumber + 1 }}
            />
          }
        />
      </div>
      <div className={bottomRowFields}>
        <TextField
          className={flexGrow[1]}
          value={frequency}
          errorMessage={get(validation, ['frequency', 'message'])}
          onChange={(val) => onChange('frequency', val)}
          placeholder={intl.formatMessage({
            defaultMessage: 'daily',
          })}
          label={<FormattedMessage defaultMessage="How often" />}
        />
        <div className={flexContainer.center}>
          <Tag className={dependenciesTag}>
            <FormattedMessage defaultMessage="if" />
          </Tag>
        </div>
        <TextField
          className={flexGrow[1]}
          value={dependencies}
          errorMessage={get(validation, ['dependencies', 'message'])}
          onChange={(val) => onChange('dependencies', val)}
          placeholder={intl.formatMessage({
            defaultMessage: 'the weather is nice',
          })}
          label={<FormattedMessage defaultMessage="Dependencies (Optional)" />}
        />
      </div>
      <div className={removeButtonContainer}>
        {stepNumber !== 0 && (
          <Tooltip
            isDisabled={canRemove}
            content={intl.formatMessage({
              defaultMessage: 'Clear fields to enable removing',
            })}
          >
            <Button
              variant="tertiary"
              size="small"
              isDisabled={!canRemove}
              onPress={onRemove}
            >
              <Button.Icon>
                <TrashIcon />
              </Button.Icon>
              <FormattedMessage defaultMessage="Remove" />
            </Button>
          </Tooltip>
        )}
      </div>
    </Block>
  );
}
