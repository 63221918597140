import cx from 'classnames';
import type { ReactNode } from 'react';

import InfoCircle from 'shared/assets/svgs/info-circle.svg?react';
import { flexContainer } from 'shared/jsStyle/flex.css';
import { color, typography } from 'shared/tempo/theme';

import { infoIcon } from './InlineMessage.css';

type Props = {
  className?: string;
  children: ReactNode;
};
export function InlineMessage({ children, className }: Props) {
  return (
    <div className={cx(flexContainer.row, className)}>
      <InfoCircle
        className={infoIcon}
        stroke={color.Theme.Light.Info}
        width={32}
        height={19}
      />
      <span style={{ ...typography.Body.DefaultShort }}>{children}</span>
    </div>
  );
}
