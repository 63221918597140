import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { useIntl } from 'react-intl';

import { Select } from 'shared/tempo/@labs/molecule/Select';
import type { Patient } from 'shared/types/patient.types';

import { conversationSelect, optionContainer } from './ConversationsSelect.css';
import PhoneIcon from './phone.svg?react';

type SelectOption = {
  key: string;
  patientId: Maybe<string>;
  caregiverName: Maybe<string>;
  phoneNumber: Maybe<string>;
};

type Props = {
  selected?: SelectOption;
  options: SelectOption[];
  onChange: (option: SelectOption) => void;
  patientWithNoConversation?: Patient | null;
};

export function ConversationsSelect({
  selected,
  options,
  onChange,
  patientWithNoConversation,
}: Props) {
  const intl = useIntl();
  const optionsGroupedByType = map(
    groupBy(
      sortBy(
        [
          ...options,
          ...(patientWithNoConversation
            ? [getPatientWithNoConversationOption(patientWithNoConversation)]
            : []),
        ],
        // Ensure's patient section comes first
        (opt) => opt.patientId,
      ),
      (opt) =>
        opt.patientId
          ? intl.formatMessage({ defaultMessage: 'Patient' })
          : intl.formatMessage({ defaultMessage: 'Caregivers' }),
    ),
    (opts, type) => ({ type, children: opts }),
  );

  return (
    <Select
      popover={{ width: 'min-select', placement: 'top start' }}
      className={conversationSelect}
      selectionLabel={(selectedItem) =>
        (selectedItem.value as unknown as SelectOption)?.patientId
          ? intl.formatMessage({ defaultMessage: 'Patient' })
          : intl.formatMessage({ defaultMessage: 'Caregiver' })
      }
      selectedKey={!selected ? options[0].key : selected.key}
      onSelectionChange={(key) => {
        if (patientWithNoConversation && key === patientWithNoConversation.id) {
          onChange(
            getPatientWithNoConversationOption(patientWithNoConversation),
          );
          return;
        }

        const option = getOptionByKey(key as string, options);
        if (!option) {
          return;
        }
        onChange(option);
      }}
      items={optionsGroupedByType}
      aria-label={intl.formatMessage({ defaultMessage: 'Contacts' })}
    >
      {(section) => (
        <Select.Section
          key={section.type}
          items={section.children}
          title={section.type}
        >
          {(item) => {
            const label =
              item?.patientId !== null
                ? item.phoneNumber
                : `${
                    item.caregiverName ||
                    intl.formatMessage({ defaultMessage: 'Caregiver' })
                  } - ${item.phoneNumber}`;
            return (
              <Select.Option key={item.key}>
                <div className={optionContainer}>
                  <PhoneIcon width={16} height={16} />
                  {label}
                </div>
              </Select.Option>
            );
          }}
        </Select.Section>
      )}
    </Select>
  );
}

function getOptionByKey(key: string, options: SelectOption[]) {
  return options.find((option) => option.key === key);
}

function getPatientWithNoConversationOption(patient: Patient) {
  return {
    key: patient.id,
    phoneNumber: patient.contacts.find(
      (contact) => contact.relationship === null,
    )?.contact?.phone_number,
    patientId: patient.id,
    caregiverName: null,
  };
}
