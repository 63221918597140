import { useEffect } from 'react';

import { useToaster } from 'shared/tempo/molecule/Toast';
import type { Variant } from 'shared/tempo/molecule/Toast/types';
import { getErrorMsg } from 'shared/utils/helpers';

type SnackbarProps = {
  message: unknown;
  variant: Variant;
};

/** @deprecated This compnonent is legacy from when we use notistack. It has been refactored to work the Tempo toast, but should be replaced with useToaster */
export function Snackbar({ message, variant }: SnackbarProps) {
  const { toaster } = useToaster();

  let displayMessage = message;

  if (typeof message !== 'string' && variant === 'error') {
    displayMessage = getErrorMsg(message);
  }

  useEffect(() => {
    toaster.add({ variant, content: displayMessage });
  }, [displayMessage, variant, toaster]);

  return null;
}
