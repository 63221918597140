import classnames from 'classnames/bind';

import { Button } from 'deprecated/mui';

import styles from './emptySection.module.scss';

const cx = classnames.bind(styles);

type EmptySectionProps = {
  message: string;
  action?: string;
  href?: string;
  onAction?: () => void;
};

export function EmptySection({
  action,
  message,
  href,
  onAction,
}: EmptySectionProps) {
  return (
    <div className={cx('container')}>
      <span>{message}</span>
      {action && href && (
        <Button
          className={cx('action-button')}
          variant="outlined"
          href={href}
          target="_blank"
        >
          {action}
        </Button>
      )}
      {action && onAction && (
        <Button
          className={cx('action-button')}
          variant="outlined"
          type="button"
          onClick={onAction}
        >
          {action}
        </Button>
      )}
    </div>
  );
}
