import classnames from 'classnames/bind';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { CircularProgress, Link, TextField } from 'deprecated/mui';

import styles from './styles.module.scss';

const cx = classnames.bind(styles);

const MAX_NOTE_LENGTH = 5000;

type Props = {
  isUpdatingReminderNote: boolean;
  currentNoteText: string;
  cancelNoteEdit: () => void;
  saveNote: (newNoteText: string) => void;
};

export const ReminderNoteEditor = ({
  isUpdatingReminderNote,
  currentNoteText,
  cancelNoteEdit,
  saveNote,
}: Props) => {
  const [newNoteText, setNewNoteText] = useState(currentNoteText);

  return (
    <>
      <div className={cx('reminder-note-editor-title')}>
        <FormattedMessage defaultMessage="Patient Reminder" />
      </div>
      <TextField
        value={newNoteText}
        helperText={`${newNoteText.length}/${MAX_NOTE_LENGTH}`}
        rows={4}
        multiline
        type="text"
        disabled={isUpdatingReminderNote}
        FormHelperTextProps={{ style: { marginLeft: 0 } }}
        InputProps={{ classes: { input: cx('reminder-note-input') } }}
        sx={{ flex: 1, pb: '24px' }}
        onChange={({ target: { value } }) =>
          setNewNoteText(value.slice(0, MAX_NOTE_LENGTH))
        }
      />
      <div className={cx('reminder-note-actions')}>
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <Link
          component="button"
          variant="body2"
          disabled={isUpdatingReminderNote}
          className={cx('link-button')}
          onClick={cancelNoteEdit}
        >
          <FormattedMessage defaultMessage="Cancel" />
        </Link>
        <div className={cx('loading-button')}>
          {isUpdatingReminderNote && <CircularProgress size={12} />}
          <Link
            component="button"
            variant="body2"
            disabled={!newNoteText || isUpdatingReminderNote}
            className={cx('link-button')}
            onClick={() => saveNote(newNoteText)}
          >
            <FormattedMessage defaultMessage="Save" />
          </Link>
        </div>
      </div>
    </>
  );
};
