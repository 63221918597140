import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Link } from 'shared/tempo/atom/Link';

import { container } from './SchedulingInfo.css';
import { divider, noTimezoneText } from './SmartScheduler.css';

type Props = {
  children: ReactNode;
  patientId: string;
  onNavigate: () => void;
};

export function NoTimezoneView({ children, patientId, onNavigate }: Props) {
  return (
    <div className={container}>
      {children}
      <div className={divider} />
      <div className={noTimezoneText}>
        <FormattedMessage
          defaultMessage="In order to use the Smart Scheduler, please ensure a timezone is set for this <link>patient's profile.</link>"
          values={{
            link: (text: string) => (
              <Link.Routed
                to={`/admin/patient/${patientId}/profile`}
                onClick={onNavigate}
              >
                {text}
              </Link.Routed>
            ),
          }}
        />
      </div>
    </div>
  );
}
