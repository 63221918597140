import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Script } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { formatProviderForScript } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/script.utils';
import { useTimeEstimate } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { LoadingPlaceholder } from 'shared/common/LoadingPlaceholder';
import { Wizard } from 'shared/common/Wizard';
import { useWizardStateContext } from 'shared/common/Wizard/state';
import { usePatientContext } from 'shared/hooks/queries';
import { useVitalsEngagement } from 'shared/hooks/useVitalsEngagement';
import { Tag } from 'shared/tempo/atom/Tag';

import { isHighDeviceUsage, usageContainer } from '.';
import { useCNFormContext } from '../../../CNFormContext';
import type { CNSectionMap } from '../../metadata';

type Props = {
  sections: CNSectionMap;
  highUsagePath?: string;
  lowUsagePath: string;
  highUsageOnNext?: () => void;
  isLoading?: boolean;
};

export function SharedDeviceUsage({
  sections,
  highUsagePath,
  lowUsagePath,
  highUsageOnNext,
  isLoading,
}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const { skipSectionStep } = useWizardStateContext();
  const { patientId } = useCNFormContext();
  const { data } = useVitalsEngagement(patientId);
  const isHighUsage = isHighDeviceUsage(data);
  const { data: patientContext, isLoading: isLoadingPatientContext } =
    usePatientContext(patientId);
  const providerName = formatProviderForScript(
    patientContext?.primaryPhysicianGivenName,
    patientContext?.primaryPhysicianFamilyName,
    patientContext?.primaryPhysicianRole,
  );

  return (
    <Wizard.Step
      isLoading={isLoading}
      sections={sections}
      title={intl.formatMessage({ defaultMessage: 'Product Support' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => {
        if (isHighUsage) {
          // if our patient has high device usage, we're skipping the low device usage section
          // to trigger the progress bar.
          skipSectionStep(lowUsagePath);

          if (highUsageOnNext) {
            highUsageOnNext();
          } else if (highUsagePath) {
            history.push(highUsagePath);
          }
        } else {
          history.push(lowUsagePath);
        }
      }}
    >
      <LoadingPlaceholder
        isLoading={data?.usageRatio === undefined || isLoadingPatientContext}
      >
        {isHighUsage ? (
          <HighUsage providerName={providerName} />
        ) : (
          <LowUsage providerName={providerName} />
        )}
      </LoadingPlaceholder>
    </Wizard.Step>
  );
}

function HighUsage({ providerName }: { providerName: string }) {
  return (
    <div className={usageContainer}>
      <div>
        <Tag variant="success">
          <FormattedMessage defaultMessage="High device usage" />
        </Tag>
      </div>
      <Script>
        <FormattedMessage defaultMessage="It looks like you are taking your vitals often." />
      </Script>
      <Script>
        <FormattedMessage
          defaultMessage="This is amazing! Great work! We want every Cadence patient to be taking vitals as frequently as you are. This provides us with important information we need to help you achieve your health goals in collaboration with {providerName}."
          values={{
            providerName,
          }}
        />
      </Script>
    </div>
  );
}

function LowUsage({ providerName }: { providerName: string }) {
  return (
    <div className={usageContainer}>
      <div>
        <Tag variant="danger">
          <FormattedMessage defaultMessage="Low device usage" />
        </Tag>
      </div>
      <Script>
        <FormattedMessage defaultMessage="I noticed you haven't taken readings very often over the past 30 days." />
      </Script>
      <Script>
        <FormattedMessage
          defaultMessage="Many people say that Cadence is like a second set of eyes. Your readings send to us automatically and we have a team of nurses monitoring them to make sure they are in a normal range. We want to help you find ways to incorporate taking your readings into your daily routine so that you can have peace of mind knowing we are looking out for you."
          values={{
            providerName,
          }}
        />
      </Script>
    </div>
  );
}
