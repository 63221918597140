import type { ReactNode } from 'react';

import { MenuItem as MuiMenuItem } from 'deprecated/mui';

export function MenuItem({
  onClick,
  children,
}: {
  onClick: () => void;
  children: ReactNode;
}) {
  return <MuiMenuItem onClick={onClick}>{children}</MuiMenuItem>;
}
