import { INIT_VISIT_SECTIONS } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/initialVisit';
import type { IntroFormFields } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/IntroPatientAttendance';
import type { SectionStepsState } from 'shared/common/Wizard/state';
import { getWizardStepValues } from 'shared/common/Wizard/state';

export function isInitialVisitPatientNoShow(data: SectionStepsState) {
  const introData = getWizardStepValues(
    data,
    INIT_VISIT_SECTIONS,
    '/intro',
    '/patient-attendance',
  )<IntroFormFields>();
  return introData?.patientAttendance === 'false';
}
