import { MedPermissions } from 'pages/patients/PatientMedications/PatientMedicationsList';
import { AsyncTitrationAsyncTitrationStatus } from 'shared/generated/grpcGateway/medication.pb';
import { useFlags } from 'shared/hooks';

import type { TitrationRecommendation } from './useTitrationRecommendation';

// these indicate that there is no action to take on the suggested titration,
// so we can safely hide it.
const HIDE_STATUSES = [
  AsyncTitrationAsyncTitrationStatus.APPLIED,
  AsyncTitrationAsyncTitrationStatus.PATIENT_REJECTED,
  AsyncTitrationAsyncTitrationStatus.REJECTED_ON_FINAL_REVIEW,
  AsyncTitrationAsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW,
  AsyncTitrationAsyncTitrationStatus.ASYNC_TITRATION_STATUS_UNSPECIFIED,
];

// typically we don't want to display a suggested titration if the
// meds module is not being loaded with "Titrate" permissions, but
// these statuses are view-only and should always be displayed if present
const DISPLAY_ALWAYS_STATUSES = [
  AsyncTitrationAsyncTitrationStatus.INITIALLY_REVIEWED,
  AsyncTitrationAsyncTitrationStatus.PATIENT_CONSENTED,
];

export function useShouldDisplayAsyncTitration(
  asyncTitration: TitrationRecommendation,
  permissions?: MedPermissions,
) {
  const { asyncTitrations: enableAsyncTitrations } = useFlags();
  const { status } = asyncTitration;

  if (!status) {
    return false;
  }

  if (HIDE_STATUSES.includes(status)) {
    return false;
  }

  if (DISPLAY_ALWAYS_STATUSES.includes(status)) {
    return enableAsyncTitrations;
  }

  return permissions === MedPermissions.Titrate && enableAsyncTitrations;
}
