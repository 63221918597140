import { decode } from 'js-base64';
import queryString from 'query-string';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import { compose } from 'deprecated/recompose';
import BackButtonIcon from 'shared/assets/icons/BackButtonIcon';
import GrayCheckIcon from 'shared/assets/icons/GrayCheckIcon';
import {
  lowercaseCharacter,
  numberCharacter,
  passwordMinLength,
  required,
  specialCharacter,
  uppercaseCharacter,
} from 'shared/common/FormValidation';
import PasswordTextField from 'shared/common/PasswordTextField';
import { withToaster } from 'shared/common/withToaster';
import { Button } from 'shared/tempo/atom/Button';
import { getErrorMsg } from 'shared/utils/helpers';
import type { RootState } from 'store/redux.types';

import { forceChangePassword } from '../../actionCreators';
import { AuthPageWrapper } from '../shared';
import { bigButton } from '../shared/auth.css';
import './ResetPasswordPage.scss';
import type { TResetPasswordPage } from './TResetPasswordPage';

export class ResetPasswordPageComponent extends Component<TResetPasswordPage> {
  constructor(props: TResetPasswordPage) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidUpdate(prevProps: TResetPasswordPage) {
    const {
      location,
      history,
      isNewPasswordCreated,
      resetPasswordError,
      toaster,
    } = this.props;
    const { from } = location.state || {
      from: { pathname: '/reset-password-success' },
    };

    if (
      isNewPasswordCreated &&
      isNewPasswordCreated !== prevProps.isNewPasswordCreated
    ) {
      history.replace(from.pathname);
    }

    if (
      resetPasswordError !== prevProps.resetPasswordError &&
      resetPasswordError !== null
    ) {
      toaster?.error(getErrorMsg(resetPasswordError));
    }
  }

  submit(values: any) {
    const { dispatchForceChangePassword, location } = this.props;
    const queryParameters: any = queryString.parse(location.search);

    const data = queryString.parse(decode(queryParameters.token));
    const { username, code } = data;

    dispatchForceChangePassword(username, code, values.password);
  }

  render() {
    const { pristine, submitting, handleSubmit } = this.props;

    return (
      <AuthPageWrapper>
        <div>
          <Link className="login-form-link" to="/login">
            <BackButtonIcon />
          </Link>
        </div>
        <div className="reset-password-title">
          <FormattedMessage defaultMessage="Create a new passsword" />
        </div>
        <form id="reset-password--form" onSubmit={handleSubmit(this.submit)}>
          <div>
            <Field
              name="password"
              component={PasswordTextField}
              placeholder="Create a new password"
              customStyle={{
                width: '400px',
              }}
              fullWidth
              validate={[
                required,
                passwordMinLength,
                lowercaseCharacter,
                uppercaseCharacter,
                numberCharacter,
                specialCharacter,
              ]}
            />
          </div>
          <div className="reset-password-sections reset-password-hint">
            <GrayCheckIcon />
            <span className="reset-password-hint-text">
              <FormattedMessage defaultMessage="At least 8 characters" />
            </span>
          </div>
          <div className="reset-password-hint">
            <GrayCheckIcon />
            <span className="reset-password-hint-text">
              <FormattedMessage defaultMessage="1 number" />
            </span>
          </div>
          <div className="reset-password-hint">
            <GrayCheckIcon />
            <span className="reset-password-hint-text">
              <FormattedMessage defaultMessage="1 special character" />
            </span>
          </div>
          <div className="reset-password-hint">
            <GrayCheckIcon />
            <span className="reset-password-hint-text">
              <FormattedMessage defaultMessage="1 Lowercase letter" />
            </span>
          </div>
          <div className="reset-password-hint">
            <GrayCheckIcon />
            <span className="reset-password-hint-text">
              <FormattedMessage defaultMessage="1 Uppercase letter" />
            </span>
          </div>
          <div className="reset-password-sections">
            <Button
              id="reset-password--form-submit"
              variant="primary"
              type="submit"
              isDisabled={pristine || submitting}
              className={bigButton}
            >
              <FormattedMessage defaultMessage="Reset password" />
            </Button>
          </div>
        </form>
      </AuthPageWrapper>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isNewPasswordCreated: state.auth.isNewPasswordCreated,
  resetPasswordError: state.auth.error,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchForceChangePassword: (
    email: string,
    confirmationCode: string,
    proposedPassword: string,
  ) => {
    dispatch(forceChangePassword(email, confirmationCode, proposedPassword));
  },
});

const enhance = compose(
  withToaster,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'reset-password--form',
    enableReinitialize: true,
  }),
);

export const ResetPasswordPage = enhance(ResetPasswordPageComponent);
