import { FormattedMessage } from 'react-intl';

import { logger } from 'logger';

import { NoteLabelTag } from '../NoteEditor/NoteLabelTag';
import { useFetchNoteLabels } from '../NoteEditor/hooks/useFetchNoteLabels.hook';

export const NoteLabelsPreview = ({ labelIds }: { labelIds?: number[] }) => {
  const { noteLabels, isFetchingNoteLabels } = useFetchNoteLabels();

  if (!labelIds || labelIds.length === 0) {
    return <FormattedMessage defaultMessage="Preview labels" />;
  }
  return (
    <>
      {labelIds.map((labelId) => {
        const noteLabel = noteLabels.find(({ id }) => id === labelId);
        if (!noteLabel && !isFetchingNoteLabels) {
          logger.error(`Note label with id ${labelId} does not exist`);
        }
        return noteLabel ? (
          <NoteLabelTag key={labelId} noteLabel={noteLabel} />
        ) : null;
      })}
    </>
  );
};
