import { useGetWizardStepValues } from 'shared/common/Wizard/state';

import {
  type PatientGoalFormFields,
  usePatientGoalI18n,
} from '../../shared/setPatientGoal';
import { REGULAR_VISIT_SECTIONS } from '../metadata';

export function usePatientGoalData() {
  return useGetWizardStepValues(
    REGULAR_VISIT_SECTIONS,
    '/action-plan',
    '/set-goal',
  )<PatientGoalFormFields>();
}

export function usePatientGoalText() {
  const patientGoal = usePatientGoalData()?.patientGoal;
  const patientGoalI18n = usePatientGoalI18n();
  return patientGoal ? patientGoalI18n[patientGoal] : null;
}
