import type { ReactElement } from 'react';
import { cloneElement, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import type { HeaderColumn } from 'shared/common/Table';

import { SkeletonTable } from '../../SkeletonTable';

type Props<T> = {
  children: ReactElement[];
  onPageRequested: () => unknown;
  isFetching: boolean;
  hasNextPage: boolean;
  columns: number | HeaderColumn<T>[];
  renderLoader?: () => ReactElement;
};

export function DataTableRows<T>({
  children,
  onPageRequested,
  isFetching,
  columns,
  hasNextPage,
  renderLoader,
}: Props<T>) {
  const [lastRowRef, lastRowInView] = useInView();

  useEffect(() => {
    if (
      !isFetching &&
      hasNextPage &&
      (lastRowInView || children.length === 0)
    ) {
      onPageRequested();
    }
    // children.length is not in deps as we are interested of it's value
    // on changes of other things, but not interested in its changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastRowInView, onPageRequested, isFetching, hasNextPage]);

  return (
    <>
      {children.map((child, i) => {
        const props = {
          key: i,
          ...(i === children.length - 1 && { ref: lastRowRef }),
        };
        return cloneElement(child, props);
      })}
      {isFetching && renderLoader && renderLoader()}
      {isFetching && !renderLoader && (
        <SkeletonTable columns={columns} rows={3} />
      )}
    </>
  );
}
