import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from 'shared/common/Wizard/state';

import { INIT_VISIT_SECTIONS } from '../../../metadata';
import type { EducationModulesReviewFormFields } from '../../../shared/EducationModulesReview';
import {
  SharedEducationModulesReview,
  getEducationModulesReviewFormConfig,
} from '../../../shared/EducationModulesReview';
import { initVisitPath } from '../../paths';
import { usePatientGoal } from '../usePatientGoal';

export function EducationModulesReview() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<EducationModulesReviewFormFields>(
    initVisitPath('/action-plan', '/education-modules-review'),
    getEducationModulesReviewFormConfig(intl),
  );
  const patientGoal = usePatientGoal();

  return (
    <SharedEducationModulesReview
      form={form}
      sections={INIT_VISIT_SECTIONS}
      nextPath={initVisitPath('/action-plan', '/set-action-steps')}
      patientGoal={patientGoal}
    />
  );
}
