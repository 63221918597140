import cx from 'classnames';
import type { ReactNode } from 'react';

import { Grid as MUIGrid } from 'deprecated/mui';

import { field } from '../fields/BaseField/styles.css';

type Props = {
  children: ReactNode;
  className?: string;
  size?: number;
  rowSpacing?: number;
};

export function GridItem({
  children,
  className,
  rowSpacing,
  size = 12,
}: Props) {
  return (
    <MUIGrid
      item
      rowSpacing={rowSpacing}
      xs={size}
      className={cx(field, className)}
      display="flex"
      flexDirection="column"
    >
      {children}
    </MUIGrid>
  );
}
