import { FormattedMessage, useIntl } from 'react-intl';

import { TimeTrackerTasksCheckboxGroup } from 'pages/patients/patientDetails/ui/tabs/TimeTracking/ManualTimeTrackingForm/shared';

import type { NoteFieldValidationResult } from '../../validation';
import { TimeTrackerLabel } from '../TimeTrackerLabel';
import { ValidationMessage } from '../ValidationMessage';
import { errorContainer } from './TimeTrackerTasks.css';

type Props = {
  onChange: (value: string[] | undefined) => void;
  value?: string[];
  fieldValidationResult?: NoteFieldValidationResult;
  required?: boolean;
};

export function TimeTrackerTasks({
  value,
  onChange,
  fieldValidationResult,
  required = true,
}: Props) {
  const intl = useIntl();
  const messageOverride = useValidationMessageOverride(fieldValidationResult);
  return (
    <div>
      <TimeTrackerLabel
        message={intl.formatMessage({
          defaultMessage: 'Select tasks you accomplished',
        })}
        error={Boolean(fieldValidationResult)}
        required={required}
      />
      <TimeTrackerTasksCheckboxGroup value={value || []} onChange={onChange} />
      <div className={errorContainer}>
        <ValidationMessage
          validationMessage={fieldValidationResult?.message}
          messageOverride={messageOverride}
        />
      </div>
    </div>
  );
}

function useValidationMessageOverride(
  fieldValidationResult?: NoteFieldValidationResult,
) {
  switch (fieldValidationResult?.type) {
    case 'required':
      return <FormattedMessage defaultMessage="Required" />;
    case 'min':
      return (
        <FormattedMessage
          defaultMessage="Select at least {min}"
          values={{ min: fieldValidationResult?.params?.min }}
        />
      );
    default:
      return null;
  }
}
