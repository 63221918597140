import type { AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { CANCEL_PENDING_REQUEST } from 'shared/actionCreators';
import Session from 'shared/utils/session';

import { CREATE_NPI, CREATE_NPI_FAILURE, npiCreated } from '../actionCreators';

const createNpiEpic = (action$: any) =>
  action$.pipe(
    ofType(CREATE_NPI),
    switchMap((action: any) =>
      from(
        Session.Api.post('/pms/api/v1/npis', {
          npi: action.npi,
        }),
      ).pipe(takeUntil(action$.pipe(ofType(CANCEL_PENDING_REQUEST)))),
    ),
    map((response: AxiosResponse<TNpiCreatedUpdatedPayload>) =>
      npiCreated(response.data),
    ),
    catchError((error, caught) =>
      merge(
        of({
          type: CREATE_NPI_FAILURE,
          payload: error,
          error: true,
        }),
        caught,
      ),
    ),
  );

export default createNpiEpic;
