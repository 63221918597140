import cx from 'classnames';

import { InputAdornment, TextField as MUITextField } from 'deprecated/mui';
import LockIcon from 'shared/assets/icons/LockIcon';
import {
  addInputClassname,
  addInputWrapperClassname,
} from 'shared/common/Input';

import type { BaseFieldProps } from '../BaseField';
import { BaseField } from '../BaseField';
import type { MaskWithDefinitions } from '../shared/InputMask';
import { InputMask } from '../shared/InputMask';
import { baseInput, endAdornment } from './styles.css';

type Props = {
  placeholder?: string;
  mask?: string | MaskWithDefinitions;
  inputType?: string;
  maxLength?: number;
  inputClass?: string;
  endAdornmentText?: string;
} & BaseFieldProps;

export function TextField({
  mask,
  placeholder,
  inputType = 'text',
  maxLength,
  inputClass,
  endAdornmentText,
  ...baseProps
}: Props) {
  const inputProps = {
    ...addInputClassname(),
    'aria-label': baseProps.name,
    maxLength,
  };

  return (
    <BaseField {...baseProps}>
      {({ controller }) => (
        <MUITextField
          disabled={baseProps.isDisabled || baseProps.isReadOnly}
          error={!!controller.fieldState.error}
          InputProps={{
            inputProps,
            ...(mask && {
              inputComponent: InputMask,
              inputProps: {
                ...inputProps,
                mask,
                name: baseProps.name,
              },
            }),
            ...(baseProps.isReadOnly && {
              endAdornment: (
                <InputAdornment position="end">
                  <LockIcon width="16" height="15" />
                </InputAdornment>
              ),
            }),
            ...(endAdornmentText && {
              endAdornment: (
                <InputAdornment position="end">
                  <div className={endAdornment}>{endAdornmentText}</div>
                </InputAdornment>
              ),
            }),
          }}
          {...controller.field}
          id={baseProps.name}
          className={cx(
            baseInput,
            addInputWrapperClassname(
              baseProps.isDisabled,
              baseProps.isReadOnly,
              !!controller.fieldState.error,
            ),
            inputClass,
          )}
          type={inputType}
          placeholder={placeholder}
        />
      )}
    </BaseField>
  );
}
