import { createCtx } from 'shared/hooks/createCtx';
import type { PatientDetails } from 'shared/types/patient.types';

export type PatientDetailsCtx = {
  patientDetails: PatientDetails;
  setPatientDetails: React.Dispatch<React.SetStateAction<PatientDetails>>;
};

const [useCtx, Provider] = createCtx<PatientDetailsCtx>('PatientDetailsCtx');

/** @deprecated Use useRouteParamPatientDetails instead */
export const usePatientDetailsCtx = useCtx;
/** @deprecated Use useRouteParamPatientDetails instead */
export const PatientDetailsProvider = Provider;
