import { useGetWizardStepValues } from 'shared/common/Wizard/state';

import { INIT_VISIT_SECTIONS } from '../../metadata';
import {
  type PatientGoalFormFields,
  usePatientGoalI18n,
} from '../../shared/setPatientGoal';

export function usePatientGoal() {
  const patientGoalsVals = useGetWizardStepValues(
    INIT_VISIT_SECTIONS,
    '/action-plan',
    '/set-goal',
  )<PatientGoalFormFields>();
  return patientGoalsVals?.patientGoal;
}

export function usePatientGoalText() {
  const patientGoal = usePatientGoal();
  const patientGoalI18n = usePatientGoalI18n();
  return patientGoal ? patientGoalI18n[patientGoal] : null;
}
