import { FormattedMessage } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { CarePlanAlertBanner } from 'pages/patients/patientDetails/ui/tabs/CarePlan/CarePlanAlertBanner';
import { useOnSave } from 'shared/common/CarePlanForm/persistence';
import { LoadingPlaceholder } from 'shared/common/LoadingPlaceholder';
import { PatientStatusEnum } from 'shared/generated/grpcGateway/pms.pb';
import { useCarePlanDraft } from 'shared/hooks/queries/carePlan.queries';
import { useIsCcmParticipating } from 'shared/patient/ccm.utils';
import { Button } from 'shared/tempo/atom/Button';
import { Link } from 'shared/tempo/atom/Link';
import { Tooltip } from 'shared/tempo/atom/Tooltip';
import { grpcNameToId } from 'shared/utils/grpc';

type Props = {
  noteId: Maybe<number>;
};

export function CarePlanButton({ noteId }: Props) {
  const history = useHistory();
  const { url, params } = useRouteMatch<{ patientId: string }>();
  const { data, isLoading } = useCarePlanDraft(params.patientId);
  const { onSave, isSaving } = useOnSave(params.patientId, noteId);
  const hasDraft = !!data?.carePlan;
  const { isLoading: isLoadingCcmParticipating, isCcmParticipating } =
    useIsCcmParticipating(params.patientId, [PatientStatusEnum.ENROLLED]);

  function onPress() {
    const base = `${url}/care-plan`;

    if (hasDraft) {
      history.push(`${base}/${grpcNameToId(data?.name ?? '')}`);
    } else {
      // Create a new care plan and redirect to draft
      onSave(
        {},
        {
          onSuccess() {
            history.push(`${base}/draft`);
          },
        },
      );
    }
  }

  if (isLoading || isLoadingCcmParticipating) {
    return <LoadingPlaceholder isLoading />;
  }

  // In this case, there is a draft Care Plan associated with a previously published
  // encounter and so we want to warn the user
  if (hasDraft && data.noteId !== noteId) {
    return (
      <CarePlanAlertBanner
        title={
          <FormattedMessage defaultMessage="Please fix the following errors" />
        }
      >
        <FormattedMessage
          defaultMessage="The <link>Care Plan draft</link> is associated with another care plan encounter. Complete the current care plan draft to create a new care plan."
          values={{
            link: (text: string) => (
              <Link.Routed
                to={`${url}/care-plan/${grpcNameToId(data?.name ?? '')}`}
              >
                {text}
              </Link.Routed>
            ),
          }}
        />
      </CarePlanAlertBanner>
    );
  }

  const canCreateCarePlan = !hasDraft && isCcmParticipating;

  return (
    <div>
      <Tooltip
        isDisabled={canCreateCarePlan}
        content={
          <FormattedMessage defaultMessage="Patient must be enrolled, have CCM conditions, and have provided program consent." />
        }
      >
        <Button
          onPress={onPress}
          isProcessing={isSaving}
          isDisabled={!canCreateCarePlan}
        >
          {hasDraft ? (
            <FormattedMessage defaultMessage="View Care Plan" />
          ) : (
            <FormattedMessage defaultMessage="Create Care Plan" />
          )}
        </Button>
      </Tooltip>
    </div>
  );
}
