import { format, isToday, isYesterday, parseISO } from 'date-fns';
import last from 'lodash/last';
import max from 'lodash/max';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import type { IntlShape } from 'react-intl';

import { useFlatPages } from 'reactQuery';
import {
  GRPC_RESOURCE_NAME,
  useTaskTypesInfinite,
} from 'shared/hooks/queries/tasks.queries';
import type { Provider, ProviderTitle } from 'shared/types/provider.types';

import type { CareProvider, Comment, ConvertedProvider, Task } from '../types';
import { TaskPriority, TaskState } from '../types';

export function isUrgent(task: Task) {
  return task.priority === TaskPriority.Urgent;
}

export function formatTimestamp(intl: IntlShape, timestamp: string): string {
  const date = parseISO(timestamp);

  if (isToday(date)) {
    return intl.formatMessage(
      { defaultMessage: 'Today, {date}' },
      { date: format(date, 'hh:mm a') },
    );
  }
  if (isYesterday(date)) {
    return intl.formatMessage(
      { defaultMessage: 'Yesterday, {date}' },
      { date: format(date, 'hh:mm a') },
    );
  }
  return format(date, 'MM/dd/yy, hh:mm a');
}

export function formatProviderName(provider: CareProvider) {
  return [`${provider.givenName} ${provider.familyName}`, provider.role]
    .filter(Boolean)
    .join(', ');
}

export function isResolved(task: Task) {
  return task.state === TaskState.CLOSED;
}

export function taskParticipants(
  comments: Comment[],
  author?: CareProvider,
  assignee?: CareProvider,
) {
  return uniqBy(
    [author, assignee, ...comments.map((c) => c.author)].filter(
      Boolean,
    ) as CareProvider[],
    ({ uid }) => uid,
  );
}

export function toTaskProvider(provider: Provider): CareProvider {
  return {
    givenName: provider.first_name,
    familyName: provider.last_name,
    role: provider.role,
    name: GRPC_RESOURCE_NAME.careProvider(provider.id),
    uid: provider.id,
  };
}

export function toPartialProvider(provider: CareProvider): ConvertedProvider {
  return {
    first_name: provider.givenName,
    last_name: provider.familyName,
    role: provider.role as ProviderTitle,
    id: provider.uid,
  };
}

export function urgencyToPriority(urgent: boolean) {
  return urgent ? TaskPriority.Urgent : TaskPriority.Normal;
}

export function lastActivityAt(task: Task) {
  const updatedAt = task.updateTime;
  const lastCommentAt = last(sortBy(task.comments, (c) => c.createTime))
    ?.createTime;
  return max([updatedAt, lastCommentAt]) as string;
}

// as of milestone 1, we auto-resolve tasks that are assigned to
// the patient success queue. these are all tasks that create zd
// tickets, so we need special handling on the display
export function isZendeskTask(task: Task) {
  return isResolved(task) && isUnassigned(task);
}

export function isUnassigned(task: Task) {
  return !task.assignee?.uid;
}

export function useCanAssignTaskType(task: Task) {
  const taskTypes = useFlatPages(useTaskTypesInfinite({}));
  return taskTypes.find(({ name }) => name === task.taskType.name)?.isVisible;
}
