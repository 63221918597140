import { FormattedMessage, useIntl } from 'react-intl';

import { Link } from 'shared/tempo/atom/Link';

import { SubHeader } from '../../SubHeader';
import { BASE_ROUTE } from '../../useCategories';

export function CommunicationsResources() {
  const intl = useIntl();
  return (
    <>
      <SubHeader>
        <FormattedMessage defaultMessage="Resources" />
      </SubHeader>
      <p>
        <FormattedMessage defaultMessage="If a patient reports that they have not received expected communications from Cadence (i.e. SMS or Email) the following steps can help determine the reason for this." />
      </p>
      <ul>
        <li>
          <Link.Native
            target="_blank"
            href="https://www.notion.so/cadencesolutions/Troubleshooting-Cadence-Messaging-f089b179c8b547139d0fb62e83d9bad7?pvs=4"
          >
            {intl.formatMessage({
              defaultMessage:
                'Check this cheat sheet for common troubleshooting steps and solutions',
            })}
          </Link.Native>
        </li>
        <li>
          <FormattedMessage
            defaultMessage="If the expected communication is a vitals confirmation text, and the communication preferences are correctly set, please reference the <link>Vitals Support Page</link> to verify the vital is being properly received by Cadence"
            values={{
              link: (children: string) => (
                <Link.Routed to={`${BASE_ROUTE}/vitals`}>
                  {children}
                </Link.Routed>
              ),
            }}
          />
        </li>
      </ul>
    </>
  );
}
