import type { MapLeafNodes } from '@vanilla-extract/private';
import isEmpty from 'lodash/isEmpty';
import type { CSSProperties, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import type {
  SelectChangeEvent as MuiSelectChangeEvent,
  SelectClasses,
} from 'deprecated/mui';
import { MenuItem, Select as MuiSelect } from 'deprecated/mui';
import ChevronIcon from 'shared/assets/svgs/chevron.svg?react';
import { color } from 'shared/tempo/theme';

export type SelectProps<T> = {
  id?: string;
  classes?: Partial<SelectClasses>;
  className?: string;
  customStyle?: CSSProperties | MapLeafNodes<unknown, unknown>;
  defaultValue?: T;
  displayEmpty?: boolean;
  children: ReactNode;
  multiple?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  native?: boolean;
  onChange: (e: MuiSelectChangeEvent<T>) => void;
  renderValue?: (value: T) => ReactNode;
  placeholder?: string;
  value?: T;
  variant?: 'unstyled' | 'outlined';
  iconStyle?: CSSProperties;
  label?: ReactNode;
  hasError?: boolean;
  onOpen?: (event: React.SyntheticEvent<Element, Event>) => void;
  onClose?: (event: React.SyntheticEvent<Element, Event>) => void;
};

export const Select = <T,>({
  children,
  customStyle,
  iconStyle,
  isLoading,
  isDisabled,
  placeholder,
  renderValue,
  variant,
  hasError,
  ...rest
}: SelectProps<T>) => (
  <MuiSelect
    // See renderValue comment
    displayEmpty={!!placeholder}
    IconComponent={(props) => (
      <ChevronIcon {...props} fill={color.Palette.Neutral[700]} />
    )}
    // If no variant is passed in, MUI defaults to 'outlined'
    variant={variant === 'unstyled' ? 'standard' : variant}
    disabled={isDisabled}
    {...(variant === 'unstyled' && { disableUnderline: true })}
    sx={{
      '.MuiSelect-select': {
        // Increase padding right from 32px because the chevron icon we are
        // displaying is wider than the arrow icon MUI displays by default.
        // !important is used because MUI adds this as a very specific style
        // through emotion
        paddingRight: '38px !important',
      },
      '.MuiSelect-icon': {
        width: '15.5px',
        top: 'inherit',
        ...(variant !== 'unstyled' && { marginRight: '7px' }),
        ...iconStyle,
      },
      ...(hasError && {
        fieldset: {
          border: 'none',
        },
      }),
      ...(variant === 'unstyled' && {
        '.MuiSelect-select:focus': {
          backgroundColor: 'transparent',
        },
      }),
      ...customStyle,
    }}
    renderValue={
      // MUI does not have a placeholder prop so we have to implement it like this
      // See https://mui.com/components/selects/#placeholder
      placeholder && isEmpty(rest.value)
        ? () => (
            <span style={{ color: color.Palette['Grey Neutral'][500] }}>
              {placeholder}
            </span>
          )
        : renderValue
    }
    {...rest}
  >
    {isLoading ? (
      <MenuItem disabled value={undefined}>
        <FormattedMessage defaultMessage="Loading" />
        ...
      </MenuItem>
    ) : (
      children
    )}
  </MuiSelect>
);

export type SelectChangeEvent<T> = MuiSelectChangeEvent<T>;

export const Option = MenuItem;
