import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';

import type { CommunicationPreferencesFormFields } from 'pages/adminPanel/patient-profile/tabs/PatientForm/formConfig';
import { getCommunicationPreferencesFields } from 'pages/adminPanel/patient-profile/tabs/PatientForm/formConfig';
import { parseCommunicationPreferencesFields } from 'pages/adminPanel/patient-profile/tabs/PatientForm/parseFormValueUtil';
import { CommunicationPreferences } from 'pages/adminPanel/patient-profile/tabs/PatientForm/sections/CommunicationPreferences';
import { Form } from 'shared/common/Form';
import { useFormFromConfig } from 'shared/common/Form/FormContainer';
import { LoadingPlaceholder } from 'shared/common/LoadingPlaceholder';
import {
  patientDetailsQueryKeys,
  patientKeys,
  usePatchPatient,
  usePatientDetails,
} from 'shared/hooks/queries';
import { Button } from 'shared/tempo/atom/Button';
import { useToaster } from 'shared/tempo/molecule/Toast';

import { formActions } from './CommunicationsForm.css';

type Props = {
  patientId: string;
};

export function CommunicationsForm({ patientId }: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const client = useQueryClient();

  // we only care about loading the patient and the notification settings if we
  // are in Update mode - just disable the queries when we aren't working with
  // an already existing patient
  const { data: patient, isLoading } = usePatientDetails(patientId, false);
  const patchMutation = usePatchPatient(patientId);
  const communicationPrefsFormFields =
    getCommunicationPreferencesFields(patient);

  const form = useFormFromConfig<CommunicationPreferencesFormFields>({
    fields: communicationPrefsFormFields,
    triggerReset: !isLoading,
  });

  const handleFormSubmit = useCallback(
    (values: CommunicationPreferencesFormFields) => {
      const payload = parseCommunicationPreferencesFields(values);

      patchMutation.mutate(payload, {
        onSuccess: async () => {
          toaster.success(
            intl.formatMessage({
              defaultMessage: 'Successfully updated communication preferences',
            }),
          );
          form.reset(values);
          await client.invalidateQueries(patientKeys.detail(patientId));
          await client.invalidateQueries(
            patientDetailsQueryKeys.detail(patientId),
          );
        },
      });
    },
    [client, form, intl, patchMutation, patientId, toaster],
  );

  return (
    <LoadingPlaceholder isLoading={isLoading}>
      <Form form={form} onSubmit={handleFormSubmit}>
        <CommunicationPreferences />
        <Form.Actions className={formActions}>
          <Button
            id="form-submit"
            type="submit"
            variant="primary"
            isDisabled={!form.canSubmit}
            isProcessing={patchMutation.isLoading}
          >
            <FormattedMessage defaultMessage="Save" />
          </Button>
        </Form.Actions>
      </Form>
    </LoadingPlaceholder>
  );
}
