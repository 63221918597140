import { ScheduleTrayBody } from 'components/ScheduleTray/ScheduleTrayBody';
import { useFlexAwareApptNotifications } from 'components/ScheduleTray/useFlexAwareApptNotifications';
import type { NotificationInfo } from 'components/ScheduleTray/utils';
import { endOfDay, formatISO, startOfDay } from 'date-fns';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useInterval } from 'usehooks-ts';

import { useListAppointments } from 'pages/patients/PatientProfile/PatientScheduling/appointments.queries';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';

import { areaTitle } from './HomePage.css';

export function HomeScheduleArea() {
  const [dateUsed, setDateUsed] = useState(new Date());
  const { currentUserId: providerId } = useCurrentUser();
  const [notification, setNotification] = useState<NotificationInfo>({
    startingSoon: false,
    isLate: false,
    minutes: null,
    appointmentId: null,
    patientId: null,
  });

  const { data: apptsData, isLoading } = useListAppointments(
    {
      apptStartTimeFrom: formatISO(startOfDay(dateUsed)),
      apptStartTimeTo: formatISO(endOfDay(dateUsed)),
      careProviderId: providerId,
    },
    Boolean(providerId),
  );

  const getFlexAwareNotifDetails = useFlexAwareApptNotifications(providerId);

  useInterval(() => {
    const notifDetails = getFlexAwareNotifDetails();
    setNotification(notifDetails);
  }, 1 * 1000);

  return (
    <>
      <div className={areaTitle}>
        <FormattedMessage defaultMessage="My appointments" />
      </div>
      <ScheduleTrayBody
        isLoading={isLoading}
        dateUsed={dateUsed}
        onUpdateDateUsed={(date: Date) => {
          setDateUsed(date);
        }}
        appointments={apptsData?.appointments ?? []}
        notification={notification}
      />
    </>
  );
}
