import type {
  Grams,
  Lbs,
  MgDls,
  MmHgs,
  MmolLs,
  Pascals,
} from 'shared/types/units';

export const convertToLbs = (grams: Grams): Lbs =>
  parseFloat(Number((grams / 1000) * 2.2046226218).toFixed(1));

export const convertToGrams = (lbs: Lbs): Grams =>
  parseFloat(Number((lbs / 2.2046226218) * 1000).toFixed(1));

export const convertToMmHg = (pascal: Pascals): MmHgs =>
  parseFloat(Number(pascal * 0.00750062).toFixed(0));

// Converts from mmol/L to mg/dL
export const convertToMgDl = (mmolL: MmolLs): MgDls =>
  Math.round(Number(mmolL * 18));

// Converts from mg/dL to mmol/L
export const convertToMmolL = (mgdL: MgDls): MmolLs =>
  parseFloat(Number(mgdL / 18).toFixed(2));

export const convertToPascal = (mmHg: MmHgs): Pascals =>
  parseFloat(Number(mmHg / 0.00750062).toFixed(1));
