import {
  type DataObject,
  SchemaDrivenForm,
  type Props as SchemaDrivenFormProps,
} from 'shared/common/@deprecated/SchemaDrivenForm/SchemaDrivenForm';

import {
  getEncounterType,
  getEncounterTypeInstance,
} from '../../utils/encounterTypeUtils';
import { useEncounterModuleInstances } from '../hooks/useEncounterModuleInstances.hook';

/** @deprecated Use our `Form` components instead */
export function NotesSchemaDrivenForm<T extends DataObject>(
  props: SchemaDrivenFormProps<T>,
) {
  // We need to re-mount the schema driven form whenever the encounter type
  // changes, or the RichTextEditorWidgets will have stale internal state
  const { encounterModuleInstances } = useEncounterModuleInstances();
  const key =
    getEncounterType(encounterModuleInstances) +
    String(
      getEncounterTypeInstance(encounterModuleInstances)?.inputs.visit_layout,
    );

  return <SchemaDrivenForm {...props} key={key} />;
}
