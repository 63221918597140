import { TypeOfEncounter } from 'pages/patients/patientDetails/ui/Notes/Notes.types';

import { chf } from './chf';
import { htn } from './htn';
import { t2d } from './t2d';
import { t2dAndHtn } from './t2dAndHtn';

export const regularNpVisit = {
  [TypeOfEncounter.NP_VISIT]: {
    ...htn,
    ...t2d,
    ...t2dAndHtn,
    ...chf,
  },
};
