import type { IntlShape } from 'react-intl';

import { REGULAR_VISIT_SECTIONS } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/regularVisit';
import type { EndCallFormFields } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/EndCall/formConfig';
import type { SectionStepsState } from 'shared/common/Wizard/state';
import { getWizardStepValues } from 'shared/common/Wizard/state';

import { cnEarlyEndCall } from '../../shared/cnEarlyEndCall';

export function regularVisitEarlyEndCall(
  data: SectionStepsState,
  intl: IntlShape,
) {
  const endCallData = getWizardStepValues(
    data,
    REGULAR_VISIT_SECTIONS,
    '/end-call',
    '/index',
  )<EndCallFormFields>();

  return cnEarlyEndCall({
    intl,
    endCallData,
    sectionData: data,
    sections: REGULAR_VISIT_SECTIONS,
  });
}
