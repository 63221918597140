/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as GoogleProtobufField_mask from "./google/protobuf/field_mask.pb"
import * as GoogleProtobufTimestamp from "./google/protobuf/timestamp.pb"
import * as fm from "./fetch.pb"
export type Market = {
  name?: string
  displayName?: string
  healthSystemId?: number
  healthSystemName?: string
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  createUser?: string
  updateUser?: string
  providerRelationsManagerName?: string
  providerRelationsManagerPhone?: string
}

export type CreateMarketRequest = {
  market?: Market
}

export type UpdateMarketRequest = {
  market?: Market
  updateMask?: GoogleProtobufField_mask.FieldMask
}

export type GetMarketRequest = {
  name?: string
  readMask?: GoogleProtobufField_mask.FieldMask
}

export type ListMarketsRequest = {
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  filter?: string
  orderBy?: string
}

export type ListMarketsResponse = {
  markets?: Market[]
  nextPageToken?: string
  totalSize?: number
}

export type CareProvider = {
  name?: string
}

export type ListCareProviderMarketsRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  filter?: string
  orderBy?: string
}

export type GetAllPatientMarketRequest = {
  name?: string
}

export class MarketService {
  static CreateMarket(req: CreateMarketRequest, initReq?: fm.InitReq): Promise<Market> {
    return fm.fetchReq<CreateMarketRequest, Market>(`/rpm/v1/markets`, {...initReq, method: "POST", body: JSON.stringify(req["market"], fm.replacer)})
  }
  static UpdateMarket(req: UpdateMarketRequest, initReq?: fm.InitReq): Promise<Market> {
    return fm.fetchReq<UpdateMarketRequest, Market>(`/rpm/v1/${req["market"]?.["name"]}`, {...initReq, method: "PATCH", body: JSON.stringify(req["market"], fm.replacer)})
  }
  static GetMarket(req: GetMarketRequest, initReq?: fm.InitReq): Promise<Market> {
    return fm.fetchReq<GetMarketRequest, Market>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static ListMarkets(req: ListMarketsRequest, initReq?: fm.InitReq): Promise<ListMarketsResponse> {
    return fm.fetchReq<ListMarketsRequest, ListMarketsResponse>(`/rpm/v1/markets?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ListCareProviderMarkets(req: ListCareProviderMarketsRequest, initReq?: fm.InitReq): Promise<ListMarketsResponse> {
    return fm.fetchReq<ListCareProviderMarketsRequest, ListMarketsResponse>(`/rpm/v1/${req["parent"]}/markets?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static GetAllPatientMarket(req: GetAllPatientMarketRequest, initReq?: fm.InitReq): Promise<Market> {
    return fm.fetchReq<GetAllPatientMarketRequest, Market>(`/rpm/v1/patients/${req["name"]}/market?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
}