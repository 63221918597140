import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import type { FormConfig } from 'shared/common/Form';
import { validators } from 'shared/common/Form/validations';
import type { Provider } from 'shared/types/provider.types';

export type FormFields = {
  assignee: Provider;
};

export function getFormConfig(intl: IntlShape): FormConfig {
  const { required } = validators(intl);
  return {
    fields: {
      assignee: {
        validation: required(yup.mixed()),
      },
    },
  };
}
