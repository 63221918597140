import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { logger } from 'logger';
import { Condition } from 'shared/generated/grpcGateway/pms.pb';
import { usePatientDetails } from 'shared/hooks/queries';
import { usePatientVitalAvgAndGoals } from 'shared/hooks/queries/vitalsContext.queries';
import { getRpmConditions } from 'shared/patient/programUtils';

import { useCNFormContext } from '../../../CNFormContext';
import {
  CADENCE_BG_GOAL,
  CADENCE_DIASTOLIC_GOAL,
  CADENCE_SYSTOLIC_GOAL,
} from './constants';
import type {
  ConditionScripts,
  ScriptContext,
  ScriptFn,
  VitalAvgLevelScriptMap,
} from './types';
import { ConditionProgram, VitalAverageLevel } from './types';
import { getBgVitalLevel, getBpVitalLevel, getBpVitalStr } from './vitalUtils';

type Script = ConditionScripts | VitalAvgLevelScriptMap | ScriptFn;

type VitalData = ReturnType<
  typeof usePatientVitalAvgAndGoals
>['vitalValuesAndGoals'];

export function useVitalBasedScript(
  scripts: Script,
  levelOverride?: VitalAverageLevel,
): [ReactElement | ReactElement[], boolean] {
  const { patientId } = useCNFormContext();
  const { data: patientData, isLoading: isLoadingPatient } = usePatientDetails(
    patientId,
    true,
  );

  const { vitalValuesAndGoals, isLoading: isLoadingVitalData } =
    usePatientVitalAvgAndGoals(patientId);

  const programConditions = getRpmConditions(
    patientData?.programs,
    patientData?.patient?.status,
  );

  const conditionProgram = getConditionProgram(programConditions);

  const script = getScript(
    conditionProgram,
    vitalValuesAndGoals,
    scripts,
    levelOverride,
  );

  return [script, isLoadingPatient || isLoadingVitalData];
}

export function getVitalLevel(
  conditionProgram: ConditionProgram,
  vitalData: Maybe<VitalData>,
): [VitalAverageLevel, string | null] {
  const { systolicAvg30d, diastolicAvg30d, bgAvg30d } = vitalData || {};

  const noVitalsResponse: [VitalAverageLevel, null] = [
    VitalAverageLevel.Unknown,
    null,
  ];

  switch (conditionProgram) {
    case ConditionProgram.HEART_FAILURE:
    case ConditionProgram.HYPERTENSION:
      if (!systolicAvg30d || !diastolicAvg30d) {
        return noVitalsResponse;
      }
      return [
        getBpVitalLevel(systolicAvg30d, diastolicAvg30d),
        getBpVitalStr(systolicAvg30d, diastolicAvg30d),
      ];
    case ConditionProgram.TYPE_2_DIABETES:
      if (!bgAvg30d) {
        return noVitalsResponse;
      }
      return [getBgVitalLevel(bgAvg30d), bgAvg30d.toString()];
    case ConditionProgram.T2D_AND_HYPERTENSION:
      return [
        VitalAverageLevel.Unknown,
        systolicAvg30d && diastolicAvg30d
          ? getBpVitalStr(systolicAvg30d, diastolicAvg30d)
          : null,
      ];
    default:
      logger.error(`Unexpected condition program: ${conditionProgram}`);
      return noVitalsResponse;
  }
}

export function getClinicalGoal(
  conditionProgram: ConditionProgram,
  vitalData: Maybe<VitalData>,
) {
  switch (conditionProgram) {
    case ConditionProgram.HYPERTENSION:
    case ConditionProgram.HEART_FAILURE:
    case ConditionProgram.T2D_AND_HYPERTENSION:
      return `${vitalData?.systolicGoal || CADENCE_SYSTOLIC_GOAL}/${
        vitalData?.diastolicGoal || CADENCE_DIASTOLIC_GOAL
      }`;
    case ConditionProgram.TYPE_2_DIABETES:
      return (vitalData?.bgGoal || CADENCE_BG_GOAL)?.toString();
    default:
      logger.error(`Unexpected condition program: ${conditionProgram}`);
      return '';
  }
}

export function getConditionProgram(conditions: Condition[]) {
  if (
    conditions.includes(Condition.HYPERTENSION) &&
    conditions.includes(Condition.TYPE_2_DIABETES)
  ) {
    return ConditionProgram.T2D_AND_HYPERTENSION;
  }

  if (conditions.length > 1) {
    logger.error(
      `Unexpected multiple conditions: ${conditions
        .map((c) => c.toString())
        .join(', ')}`,
    );
  }

  const condition = conditions[0];
  switch (condition) {
    case Condition.HYPERTENSION:
      return ConditionProgram.HYPERTENSION;
    case Condition.TYPE_2_DIABETES:
      return ConditionProgram.TYPE_2_DIABETES;
    case Condition.HEART_FAILURE:
      return ConditionProgram.HEART_FAILURE;
    default:
      logger.error(`Unexpected condition: ${conditions[0]}`);
      return null;
  }
}

function getScript(
  conditionProgram: Maybe<ConditionProgram>,
  vitalData: Maybe<VitalData>,
  conditionScripts: Script,
  levelOverride?: VitalAverageLevel,
) {
  if (!conditionProgram) {
    logger.error('No condition program');
    return (
      <FormattedMessage defaultMessage="There is a problem with this patient. Please contact support." />
    );
  }

  const [vitalLevel, vitalAvg] = getVitalLevel(conditionProgram, vitalData);

  const scriptContext: ScriptContext = {
    vitalAvg,
    conditionProgram,
    clinicalGoal: getClinicalGoal(conditionProgram, vitalData),
    ...(conditionProgram === ConditionProgram.T2D_AND_HYPERTENSION
      ? {
          secondaryClinicalGoal: getClinicalGoal(
            ConditionProgram.TYPE_2_DIABETES,
            vitalData,
          ),
        }
      : {}),
  };

  const vitalAverageLevel = levelOverride || vitalLevel;

  if (typeof conditionScripts === 'function') {
    return conditionScripts(scriptContext);
  }

  if (isVitalAvgLevelScriptMap(conditionScripts)) {
    return conditionScripts[vitalAverageLevel](scriptContext);
  }

  const conditionScript = conditionScripts[conditionProgram];
  if (typeof conditionScript === 'function') {
    return conditionScript(scriptContext);
  }

  return conditionScript[vitalAverageLevel](scriptContext);
}

function isVitalAvgLevelScriptMap(
  scripts: ConditionScripts | VitalAvgLevelScriptMap,
): scripts is VitalAvgLevelScriptMap {
  return VitalAverageLevel.One in scripts;
}
