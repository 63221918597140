import { useIntl } from 'react-intl';

import { useFlatPages } from 'reactQuery';
import { DataTableRows } from 'shared/common/@deprecated/Table';
import { SkeletonTable } from 'shared/common/SkeletonTable';
import type { HeaderColumn } from 'shared/common/Table';
import { Table, useTableSort } from 'shared/common/Table';
import { getSortParams } from 'shared/common/Table/sort/sort.utils';
import type { MonitoringSessionsSortField } from 'shared/hooks/queries';
import { useMonitoringSessionsInfiniteQuery } from 'shared/hooks/queries';

import { TimeEntryRow } from './TimeEntryRow';
import { headerCell } from './TimeEntryTable.css';

function useColumnHeaders(): HeaderColumn<MonitoringSessionsSortField>[] {
  const intl = useIntl();
  return [
    {
      title: intl.formatMessage({ defaultMessage: 'Date' }),
      sortKey: ['start_datetime'],
    },
    { title: intl.formatMessage({ defaultMessage: 'Start Time' }) },
    { title: intl.formatMessage({ defaultMessage: 'Entry Type' }) },
    { title: intl.formatMessage({ defaultMessage: 'Duration' }) },
    {
      title: intl.formatMessage({ defaultMessage: 'Interaction' }),
      sortKey: ['communication_type'],
    },
    {
      title: intl.formatMessage({ defaultMessage: 'Added By' }),
      sortKey: ['first_name', 'last_name'],
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'Action',
      }),
    },
  ];
}

type Props = {
  patientId: string;
  onEdit: (entryId: string) => void;
  onView: (entryId: string) => void;
};

export function TimeEntryTable({ patientId, onEdit, onView }: Props) {
  const intl = useIntl();
  const columnHeaders = useColumnHeaders();
  const { sortState, handleSort } = useTableSort<MonitoringSessionsSortField>({
    sortKey: ['start_datetime'],
    sortDir: ['desc'],
  });

  const { sortKey, sortDir } = getSortParams(sortState);

  const sessionsInfiniteQuery = useMonitoringSessionsInfiniteQuery(patientId, {
    sort_by: sortKey,
    order_by: sortDir,
  });
  const timeEntries = useFlatPages(sessionsInfiniteQuery);
  const { isFetching, isLoading, hasNextPage, fetchNextPage } =
    sessionsInfiniteQuery;

  return (
    <Table.Container>
      <Table>
        <Table.Header
          columns={columnHeaders}
          onSort={handleSort}
          sortValue={sortState}
          headerCellClassName={headerCell}
        />
        <Table.Body>
          {!timeEntries.length && !isFetching && (
            <Table.Row>
              <Table.TextCell colSpan={columnHeaders.length}>
                {intl.formatMessage({
                  defaultMessage: 'No time tracking records found',
                })}
              </Table.TextCell>
            </Table.Row>
          )}
          {isLoading ? (
            <SkeletonTable columns={columnHeaders} />
          ) : (
            <DataTableRows
              onPageRequested={fetchNextPage}
              isFetching={isFetching}
              hasNextPage={Boolean(hasNextPage)}
              columns={columnHeaders}
            >
              {timeEntries.map((timeEntry) => (
                <TimeEntryRow
                  key={timeEntry.id}
                  timeEntry={timeEntry}
                  onEdit={onEdit}
                  onView={onView}
                />
              ))}
            </DataTableRows>
          )}
        </Table.Body>
      </Table>
    </Table.Container>
  );
}
