import { type Key, useCallback } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { joinPaths } from 'shared/utils/route-helpers';

export function useCurrentRoutedTab<T extends string>(
  tabs: T[],
  defaultTab: T,
): T;
export function useCurrentRoutedTab<T extends string>(
  tabs: T[],
  defaultTab?: T,
) {
  const { pathname } = useLocation();
  return tabs.find((tab) => pathname.includes(tab)) || defaultTab;
}

export function useRoutedTabHandler() {
  const { url: matchedUrl } = useRouteMatch();
  const history = useHistory();
  return useCallback(
    (key: Key) => {
      const newLocation = `/${joinPaths(matchedUrl, key as string)}`;
      if (history.location.pathname !== newLocation) {
        history.replace(newLocation);
      }
    },
    [history, matchedUrl],
  );
}
