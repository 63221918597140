import { useIntl } from 'react-intl';

import { TEMPLATE_HELPERS } from 'pages/patients/patientDetails/ui/Notes/NoteEditor/templates/engine';
import {
  usePatientVitalAvgAndGoals,
  usePatientVitalsContext,
} from 'shared/hooks/queries/vitalsContext.queries';
import { VitalType } from 'shared/types/vitals.types';

type VitalData = {
  avg: string;
  goal?: string;
  atGoal?: boolean;
};

export type PatientActivityVitals = {
  [VitalType.BloodPressure]?: VitalData;
  [VitalType.HeartRate]?: VitalData;
  [VitalType.Weight]?: VitalData;
  [VitalType.BloodGlucose]?: VitalData;
};

export function useVitalsAndGoals(patientId: string): {
  isLoading: boolean;
  data: Maybe<PatientActivityVitals>;
} {
  const intl = useIntl();
  const { isLoading: isLoadingGoals, vitalValuesAndGoals: goals } =
    usePatientVitalAvgAndGoals(patientId);
  const { data: vitalsContext, isLoading: isLoadingVitals } =
    usePatientVitalsContext(patientId);

  if (isLoadingVitals || isLoadingGoals) {
    return { data: undefined, isLoading: true };
  }

  return {
    isLoading: false,
    data: {
      [VitalType.BloodPressure]: {
        avg: intl.formatMessage(
          { defaultMessage: '{bloodPressure} mmHg' },
          {
            bloodPressure: TEMPLATE_HELPERS.bp(
              vitalsContext?.contextVitals?.bloodPressure?.systolic?.avg30d,
              vitalsContext?.contextVitals?.bloodPressure?.diastolic?.avg30d,
            ),
          },
        ),
        goal: intl.formatMessage(
          { defaultMessage: '{goalSystolic}/{goalDiastolic} mmHg goal' },
          {
            goalSystolic: goals?.systolicGoal,
            goalDiastolic: goals?.diastolicGoal,
          },
        ),
        atGoal:
          (goals?.systolicAvg30d ?? Infinity) <= (goals?.systolicGoal ?? 0) &&
          (goals?.diastolicAvg30d ?? Infinity) <= (goals?.diastolicGoal ?? 0),
      },
      [VitalType.HeartRate]: {
        avg: TEMPLATE_HELPERS.hr(
          vitalsContext?.contextVitals?.heartRate?.avg30d,
        ),
      },
      [VitalType.Weight]: {
        avg: TEMPLATE_HELPERS.weight(
          vitalsContext?.contextVitals?.weight?.avg30d,
        ),
      },
      [VitalType.BloodGlucose]: {
        avg: TEMPLATE_HELPERS.bg(
          vitalsContext?.contextVitals?.bloodGlucose?.avg30d,
        ),
        goal: intl.formatMessage(
          { defaultMessage: '{goalBloodGlucose} mg/dL goal' },
          { goalBloodGlucose: goals?.bgGoal },
        ),
        atGoal: (goals?.bgAvg30d ?? Infinity) <= (goals?.bgGoal ?? 0),
      },
    },
  };
}
