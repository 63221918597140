import merge from 'lodash/merge';
import { FormattedMessage } from 'react-intl';

import { getUiSchemaFromLabels } from 'shared/common/@deprecated/SchemaDrivenForm/';

import type { HospitalizationInputs } from '../NotePreview/formatHospitalizationInputs.util';
import type { EncounterModuleInstance } from '../Notes.types';
import { EncounterModuleId } from '../Notes.types';
import { useEncounterModule } from '../note.queries';
import { NotesSchemaDrivenForm } from './@deprecated/NotesSchemaDrivenForm';

export function RecentHospitalizationForm({
  initialValues,
  onChange,
  shouldShowValidation,
}: {
  initialValues?: HospitalizationInputs;
  onChange: (encounterModuleInstance: EncounterModuleInstance) => void;
  shouldShowValidation: boolean;
}) {
  const { encounterModule, isLoading } = useEncounterModule(
    EncounterModuleId.Hospitalization,
  );

  return (
    <>
      {!isLoading && encounterModule && (
        <NotesSchemaDrivenForm
          shouldShowValidation={shouldShowValidation}
          shouldLiveValidate={
            // See TypeOfEncounterForm.tsx for why we are setting this to false
            false
          }
          initialFormData={initialValues}
          schema={encounterModule.schema}
          onChange={(inputs: HospitalizationInputs) => {
            onChange({
              encounter_module_id: encounterModule.id,
              inputs,
            });
          }}
          uiSchema={merge(
            {
              'ui:order': [
                'visit_types',
                'admission_start_date',
                'admission_end_date',
                'location',
                'admission_reasons',
                'additional_information',
              ],
              visit_types: {
                'ui:childOptions': {
                  inline: true,
                },
                ...getUiSchemaFromLabels(VISIT_TYPE_FORMATTED_MESSAGES),
              },
              admission_reasons: {
                'ui:childOptions': {
                  inline: true,
                },
                'ui:order': ['chf', 'hypertension', 'type_2_diabetes', 'other'],
                ...getUiSchemaFromLabels(ADMISSION_REASON_FORMATTED_MESSAGES),
              },
              admission_start_date: {
                'ui:options': { inline: true },
              },
              admission_end_date: {
                'ui:options': { inline: true },
              },
            },
            getUiSchemaFromLabels(HOSPITALIZATION_FORMATTED_MESSAGES),
          )}
        />
      )}
    </>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export const ADMISSION_REASON_FORMATTED_MESSAGES = {
  chf: <FormattedMessage defaultMessage="CHF" />,
  hypertension: <FormattedMessage defaultMessage="Hypertension" />,
  type_2_diabetes: <FormattedMessage defaultMessage="Type 2 Diabetes" />,
  other: <FormattedMessage defaultMessage="Other" />,
};

// by default the input previews use ADMISSION_REASON_FORMATTED_MESSAGES. you can use
// this object with the same key to override what we show in the preview.
// eslint-disable-next-line react-refresh/only-export-components
export const ADMISSION_REASON_PREVIEW_MESSAGES = {
  other: <FormattedMessage defaultMessage="other reasons" />,
};

// eslint-disable-next-line react-refresh/only-export-components
export const VISIT_TYPE_FORMATTED_MESSAGES = {
  urgent_care: <FormattedMessage defaultMessage="Urgent care visit" />,
  emergency_dept: (
    <FormattedMessage defaultMessage="Emergency department visit" />
  ),
  hospital_admission: <FormattedMessage defaultMessage="Hospital admission" />,
};

// eslint-disable-next-line react-refresh/only-export-components
export const HOSPITALIZATION_FORMATTED_MESSAGES = {
  visit_types: (
    <FormattedMessage defaultMessage="Type of Outpatient Clinical Encounter" />
  ),
  admission_reasons: <FormattedMessage defaultMessage="Admitted for" />,
  location: <FormattedMessage defaultMessage="Location" />,
  admission_end_date: <FormattedMessage defaultMessage="End date" />,
  admission_start_date: <FormattedMessage defaultMessage="Start date" />,
  additional_information: (
    <FormattedMessage defaultMessage="Additional information" />
  ),
};
