import type { AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { CANCEL_PENDING_REQUEST } from 'shared/actionCreators';
import Session from 'shared/utils/session';

import {
  FETCH_NPI,
  FETCH_NPI_FAILURE,
  npiListFetched,
} from '../actionCreators';

const fetchNpiEpic = (action$: any) =>
  action$.pipe(
    ofType(FETCH_NPI),
    switchMap((action: any) =>
      from(
        Session.Api.get(
          `/pms/api/v1/npis?page=${action.page}&page_size=${action.pageSize}${
            action.searchTerm ? `&npi=${action.searchTerm}` : ''
          }`,
        ),
      ).pipe(takeUntil(action$.pipe(ofType(CANCEL_PENDING_REQUEST)))),
    ),
    map((response: AxiosResponse) => npiListFetched(response.data)),
    catchError((error, caught) =>
      merge(
        of({
          type: FETCH_NPI_FAILURE,
          payload: error,
          error: true,
        }),
        caught,
      ),
    ),
  );

export default fetchNpiEpic;
