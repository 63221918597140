import { useIntl } from 'react-intl';

import { ConditionProgram } from 'shared/types/condition.types';

import { usePatientProgram } from './usePatientProgram';

export function usePatientDiseaseStateI18n(patientId: string) {
  const intl = useIntl();
  const { program, isLoading } = usePatientProgram(patientId);
  const i18nMap = {
    [ConditionProgram.CHF]: intl.formatMessage({
      defaultMessage: 'heart condition',
    }),
    [ConditionProgram.Hypertension]: intl.formatMessage({
      defaultMessage: 'high blood pressure',
    }),
    [ConditionProgram.TypeTwoDiabetes]: intl.formatMessage({
      defaultMessage: 'blood sugar',
    }),
  };
  let diseaseState;

  if (program) {
    diseaseState = i18nMap[program as keyof typeof i18nMap] ?? program;
  }

  return {
    isLoading,
    diseaseState,
  };
}
