import PatientsIcon from 'shared/assets/svgs/patients.svg?react';
import type { Task } from 'shared/tasking/types';
import { Avatar } from 'shared/tempo/atom/Avatar';

import { isResolved, useCanAssignTaskType } from '../utils';
import { teamIcon, unassignedAvatar } from './UnassignedAvatar.css';

type Props = {
  task: Task;
};

export function UnassignedAvatar({ task }: Props) {
  const canAssignTask = useCanAssignTaskType(task);
  const isAssignableByUser = canAssignTask && !isResolved(task);

  if (!isAssignableByUser) {
    return <TeamAvatar />;
  }

  return <Avatar className={unassignedAvatar} />;
}

function TeamAvatar() {
  return (
    <div className={teamIcon.container}>
      <PatientsIcon className={teamIcon.icon} />
    </div>
  );
}
