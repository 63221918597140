import cx from 'classnames';
import type { ReactNode } from 'react';

import StopWatchIcon from 'shared/assets/svgs/stopWatch.svg?react';
import { color } from 'shared/tempo/theme';

import {
  stopWatchIcon,
  timeTrackerContainer,
  timeTrackerWrapper,
  title,
} from './TimeTracking.css';

type Props = {
  children?: ReactNode;
  className?: string;
};
export const TimeTracker = ({ children, className }: Props) => (
  <div className={cx(timeTrackerWrapper.baseStyle, className)}>
    <div className={timeTrackerContainer}>
      <StopWatchIcon
        fill={color.Theme.Light['Base Font']}
        className={stopWatchIcon}
      />
      <div className={title}>{children}</div>
    </div>
  </div>
);
