import type { IntlShape } from 'react-intl';

import { CHF_VISIT_SECTIONS } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/chfVisit';
import type { EmergencyVisitsFormFields } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/emergencyVisits';
import type { SectionStepsState } from 'shared/common/Wizard/state';
import { getWizardStepValues } from 'shared/common/Wizard/state';

import { cnEmergencyVisits } from '../../shared/cnEmergencyVisits';
import { sectionHasData } from '../../util';

export function chfVisitEmergencyVisits(
  data: SectionStepsState,
  intl: IntlShape,
) {
  const edVisitData = getWizardStepValues(
    data,
    CHF_VISIT_SECTIONS,
    '/emergency-visits',
    '/index',
  )<EmergencyVisitsFormFields>();

  if (!sectionHasData(edVisitData)) {
    return '';
  }

  return cnEmergencyVisits({ intl, edVisitData });
}
