import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { logger } from 'logger';
import { EllipseIcon } from 'shared/assets/icons/Ellipse';
import type { NotificationAudit } from 'shared/hooks/queries';
import type { TagVariant } from 'shared/tempo/atom/Tag';
import { Tag } from 'shared/tempo/atom/Tag';
import { Tooltip } from 'shared/tempo/atom/Tooltip';
import { color } from 'shared/tempo/theme';

import {
  inline,
  statusTag,
  statusTagIcon,
} from './NotificationAuditStatusTag.css';
import { useCommunicationStatusi18nMap } from './useCommunicationStatusi18nMap';

type Props = {
  error?: Nullable<string>;
  status: OmitUndefined<NotificationAudit>['notification_status'];
};

export function NotificationAuditStatusTag({ status, error }: Props) {
  return <BaseTag {...useStatusTagProps(status, error)} />;
}

type BaseTagProps = {
  variant: TagVariant;
  iconColor: string;
  label: ReactNode;
  tooltip?: string;
};

function BaseTag({ variant, iconColor, label, tooltip }: BaseTagProps) {
  return (
    <Tooltip content={tooltip} classes={{ triggerContainer: inline }}>
      <Tag variant={variant} className={statusTag}>
        <EllipseIcon fill={iconColor} className={statusTagIcon} />
        {label}
      </Tag>
    </Tooltip>
  );
}

function useStatusTagProps(
  status: OmitUndefined<NotificationAudit>['notification_status'],
  error?: Nullable<string>,
): BaseTagProps {
  const commTypeMap = useCommunicationStatusi18nMap();
  switch (status) {
    case 'RECEIVED':
    case 'DELIVERED':
      return {
        variant: 'success',
        iconColor: color.Theme.Light.Success,
        label: commTypeMap[status],
      };
    case 'UNDELIVERED':
    case 'FAILED':
    case 'BOUNCE':
    case 'DROPPED':
      return {
        variant: 'danger',
        iconColor: color.Palette.Danger[700],
        label: <FormattedMessage defaultMessage="Failed" />,
        tooltip: error || commTypeMap[status],
      };
    case 'DEFERRED':
    case 'UNSUBSCRIBE':
    case 'GROUP_UNSUBSCRIBE':
    case 'SPAM_REPORT':
      return {
        variant: 'warning',
        iconColor: color.Palette.Warning[600],
        label: commTypeMap[status],
      };
    case 'READ':
    case 'ACCEPTED':
    case 'CLICK':
    case 'GROUP_RESUBSCRIBE':
    case 'OPEN':
      return {
        variant: 'info',
        iconColor: color.Brand.Primary['Bright Blue'],
        label: commTypeMap[status],
      };
    case 'PROCESSED':
    case 'QUEUED':
    case 'SCHEDULED':
    case 'SENDING':
    case 'SENT':
    case 'RECEIVING':
      return {
        variant: 'default',
        iconColor: color.Theme.Light['Base Font'],
        label: commTypeMap[status],
      };
    default:
      logger.warn(`Unhandled notifiation audit status: '${status}'`);
      return {
        variant: 'default',
        iconColor: color.Theme.Light['Base Font'],
        label: <FormattedMessage defaultMessage="Unspecified" />,
      };
  }
}
