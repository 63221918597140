import type { RadioGroupProps } from '@react-stately/radio';
import { useRadioGroupState } from '@react-stately/radio';
import cx from 'classnames';
import type { ReactNode } from 'react';
import { useRadioGroup } from 'react-aria';

import { Label } from 'shared/tempo/atom/Label';
import { ValidationError } from 'shared/tempo/atom/ValidationError';
import type { EnforcedA11yLabel } from 'shared/tempo/shared/types';
import { RadioGroupContext } from 'shared/tempo/util/RadioGroupContext';

import {
  label as labelCss,
  radioContainer,
  validationError,
} from './RadioGroup.css';

type Props = {
  children: ReactNode;
  classes?: {
    label?: string;
    root?: string;
  };
} & RadioGroupProps &
  EnforcedA11yLabel;

export function RadioGroup({
  children,
  classes,
  orientation = 'vertical',
  ...props
}: Props) {
  const { description, label, isRequired, errorMessage, isDisabled } = props;
  const hasError = !!errorMessage;
  const state = useRadioGroupState({
    ...props,
    isDisabled,
    validationState: hasError ? 'invalid' : 'valid',
  });
  const { radioGroupProps, labelProps, descriptionProps, errorMessageProps } =
    useRadioGroup({ orientation, ...props }, state);

  return (
    <div {...radioGroupProps}>
      <Label
        className={cx(labelCss, classes?.label)}
        description={description}
        descriptionProps={descriptionProps}
        hasError={hasError}
        isRequired={isRequired}
        label={label}
        labelProps={labelProps}
      />
      <div className={cx(radioContainer[orientation], classes?.root)}>
        <RadioGroupContext.Provider value={state}>
          {children}
        </RadioGroupContext.Provider>
      </div>
      {errorMessage && (
        <ValidationError {...errorMessageProps} className={validationError}>
          {errorMessage}
        </ValidationError>
      )}
    </div>
  );
}
