import { REGULAR_VISIT_SECTIONS } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/metadata';
import type { DeviceUsageGoalsFormFields } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/LowDeviceUsage';
import type { ProductFeedbackFormFields } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/ProductSupport/feedbackFormConfig';
import type { SectionStepsState } from 'shared/common/Wizard/state';
import { getWizardStepValues } from 'shared/common/Wizard/state';
import type { VitalsEngagement } from 'shared/hooks/useVitalsEngagement';

import { cnProductSupport } from '../../shared/cnProductSupport';

export function regularVisitProductSupport(
  data: SectionStepsState,
  vitalsEngagement: VitalsEngagement,
) {
  const deviceUsageData = getWizardStepValues(
    data,
    REGULAR_VISIT_SECTIONS,
    '/product-support',
    '/low-device-usage',
  )<DeviceUsageGoalsFormFields>();
  const feedbackData = getWizardStepValues(
    data,
    REGULAR_VISIT_SECTIONS,
    '/product-support',
    '/feedback',
  )<ProductFeedbackFormFields>();

  return cnProductSupport({
    deviceUsageData,
    vitalsEngagement,
    feedbackData,
  });
}
