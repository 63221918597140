import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import PencilIcon from 'shared/assets/svgs/pencil.svg?react';
import CheckIcon from 'shared/assets/svgs/smallCheck.svg?react';
import SmallXIcon from 'shared/assets/svgs/smallX.svg?react';
import { Button } from 'shared/tempo/atom/Button';

import {
  acceptButton,
  acceptIconSizeFix,
  actions,
  cancelButton,
  editButton,
} from './SuggestedTitration.css';
import { SuggestedTitrationMode } from './types';

type Props = {
  mode: SuggestedTitrationMode;
  onReject: () => void;
  onEdit: () => void;
  onAccept: () => void;
};

export function Actions({ mode, onReject, onEdit, onAccept }: Props) {
  return (
    <div className={actions.container}>
      <Button
        variant="secondary"
        className={
          mode === SuggestedTitrationMode.NotTitrating
            ? cancelButton.button.selected
            : cancelButton.button.default
        }
        onPress={onReject}
      >
        <Button.Icon className={cancelButton.iconWrapper}>
          <SmallXIcon className={cancelButton.icon} />
        </Button.Icon>
        <FormattedMessage defaultMessage="Do not titrate" />
      </Button>
      <Button
        variant="secondary"
        className={editButton.button.default}
        onPress={onEdit}
      >
        <Button.Icon>
          <PencilIcon />
        </Button.Icon>
        <FormattedMessage defaultMessage="Edit" />
      </Button>
      <Button
        variant="secondary"
        className={
          mode === SuggestedTitrationMode.Accepted
            ? acceptButton.button.selected
            : acceptButton.button.default
        }
        onPress={onAccept}
      >
        <Button.Icon className={acceptButton.iconWrapper}>
          <CheckIcon className={cx(acceptButton.icon, acceptIconSizeFix)} />
        </Button.Icon>
        <FormattedMessage defaultMessage="Accept" />
      </Button>
    </div>
  );
}
