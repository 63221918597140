import * as yup from 'yup';

import type { FileWithBase64 } from 'shared/utils/file';
import {
  getImageDimensions,
  getImageSrc,
  isFileWithBase64,
} from 'shared/utils/file';

import type { FileValue } from '../fields/File';
import { validator } from './factory';

export type RequiredParams = { schema: yup.AnySchema; errorMessage?: string };

export const requiredFile = validator(
  (intl, schemaOrParams: yup.AnySchema | RequiredParams) => {
    const schema =
      'schema' in schemaOrParams ? schemaOrParams.schema : schemaOrParams;
    const errorMessage =
      'errorMessage' in schemaOrParams
        ? schemaOrParams.errorMessage
        : undefined;

    return schema.test(
      'required',
      errorMessage ?? intl.formatMessage({ defaultMessage: 'Required' }),
      (value?: FileValue | FileWithBase64) => {
        if (isFileWithBase64(value)) {
          return !!value.base64;
        }

        return !!value?.files?.length;
      },
    );
  },
);

type ImageDimensionsParams = {
  width: number;
  height: number;
  errorMessage?: string;
};

export const imageDimensions = validator(
  (intl, params: ImageDimensionsParams) =>
    yup.object().test(
      'image-dimensions',
      params.errorMessage ??
        intl.formatMessage(
          {
            defaultMessage: 'Image must be {width}px X {height}px',
          },
          {
            width: params.width,
            height: params.height,
          },
        ),
      async (value?: FileWithBase64) => {
        const src = getImageSrc(value);

        if (!src) {
          return true;
        }

        let width;
        let height;

        try {
          const dimensions = await getImageDimensions(src);
          width = dimensions.width;
          height = dimensions.height;
        } catch (err) {
          return false;
        }

        return width === params.width && height === params.height;
      },
    ),
);
