import type { ReactNode } from 'react';

import { Menu as MuiMenu } from 'deprecated/mui';

export function Menu({
  anchorEl,
  isOpen,
  close,
  children,
}: {
  anchorEl: Element | null;
  isOpen: boolean;
  close: () => void;
  children: ReactNode;
}) {
  return (
    <MuiMenu anchorEl={anchorEl} open={isOpen} onClose={close}>
      {children}
    </MuiMenu>
  );
}
