import { FormattedMessage } from 'react-intl';

import { Modal } from 'shared/common/Modal';
import { ReportIssueRequestCategory } from 'shared/generated/grpcGateway/issue_reporter.pb';
import { Tabs } from 'shared/tempo/@labs/atom/Tabs';

import { useSupportModalContext } from '../../SupportModalContext';
import { TicketCta } from '../../TicketCta';
import { tabs } from '../page.css';
import { tabPadding } from './Orders.css';
import { OrdersProgramsConditions } from './OrdersProgramsConditions';
import { OrdersReceivedOrders } from './OrdersReceivedOrders';
import { OrdersResources } from './OrdersResources';

export function Orders() {
  const { patientId } = useSupportModalContext();
  if (!patientId) {
    return (
      <Modal.Body>
        <OrdersResources />
      </Modal.Body>
    );
  }

  return (
    <Modal.Body>
      <Tabs className={tabs}>
        <Tabs.Tab
          key="programs-conditions"
          className={tabPadding}
          title={<FormattedMessage defaultMessage="Programs & Conditions" />}
        >
          <OrdersProgramsConditions patientId={patientId} />
        </Tabs.Tab>
        <Tabs.Tab
          key="received-orders"
          className={tabPadding}
          title={<FormattedMessage defaultMessage="Received Orders" />}
        >
          <OrdersReceivedOrders patientId={patientId} />
        </Tabs.Tab>
        <Tabs.Tab
          key="resources"
          className={tabPadding}
          title={<FormattedMessage defaultMessage="Resources" />}
        >
          <OrdersResources />
        </Tabs.Tab>
      </Tabs>
      <TicketCta category={ReportIssueRequestCategory.CATEGORY_ORDERS} />
    </Modal.Body>
  );
}
