import { parseISO } from 'date-fns';
import flatten from 'lodash/flatten';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ProtocolsIcon from 'shared/assets/svgs/protocols.svg?react';
import { LastSyncDate } from 'shared/common/LastSyncDate';
import { LoadingPlaceholder } from 'shared/common/LoadingPlaceholder';
import type { ReferenceLab } from 'shared/generated/grpcGateway/labs.pb';
import { usePatientDetails } from 'shared/hooks/queries';
import { usePatientLabsAndGroups } from 'shared/hooks/queries/labs.queries';

import { EmptyState } from './EmptyState';
import { LabFilters } from './LabFilters';
import {
  container,
  filters,
  header,
  syncDateContainer,
  tableContainer,
  title,
} from './Labs.css';
import { LabsTable } from './LabsTable';
import {
  areLabsEmpty,
  filterLabGroups,
  getDefaultLabGroup,
  getLatestLabGroupsWithDates,
} from './labs.utils';
import type { FilterState } from './types';

type Props = {
  patientId: string;
};

export function Labs({ patientId }: Props) {
  const defaultFilter = useRef({ isSet: false, value: '' });
  const [filterState, setFilterState] = useState<FilterState>({
    analytes: [],
    labGroup: '',
  });
  const { data: patient } = usePatientDetails(patientId, true);
  const { data: patientLabsAndGroups, isLoading: isLoadingLabsAndGroups } =
    usePatientLabsAndGroups(patientId);

  const labGroups = patientLabsAndGroups?.labGroups;
  const visibleLabs = filterLabGroups(labGroups ?? [], filterState);
  const emptyLabs = areLabsEmpty(visibleLabs || []);

  const latestLabGroupsWithDates = getLatestLabGroupsWithDates(labGroups ?? []);

  const referenceLabs = flatten(
    labGroups?.map(
      (group) => group.referenceLabs?.map((ref) => ref.referenceLab),
    ),
  ) as ReferenceLab[];

  const filterHash = useMemo(() => JSON.stringify(filterState), [filterState]);
  const lastSyncDate = patient?.patient?.lastEhrSyncDate
    ? parseISO(patient?.patient?.lastEhrSyncDate)
    : null;

  useEffect(() => {
    // we only want to set this once, and only if we have all the data we need.
    if (
      defaultFilter.current.isSet ||
      !latestLabGroupsWithDates.length || // filter by lab group
      !referenceLabs.length // filter by analyte
    ) {
      return;
    }
    // Use the most recent lab group as the default filter
    const defaultLabGroup = getDefaultLabGroup(latestLabGroupsWithDates);

    setFilterState({
      analytes: [],
      labGroup: defaultLabGroup,
    });
    defaultFilter.current = { isSet: true, value: defaultLabGroup };
  }, [referenceLabs, defaultFilter, latestLabGroupsWithDates]);

  return (
    <div className={container}>
      <div className={header}>
        <span className={title}>
          <ProtocolsIcon fill="currentColor" />
          <FormattedMessage defaultMessage="Lab Results" />
        </span>
        {!isLoadingLabsAndGroups && (
          <div className={filters}>
            <LabFilters
              referenceLabs={referenceLabs}
              analytes={filterState.analytes}
              labGroup={filterState.labGroup}
              latestLabGroups={latestLabGroupsWithDates}
              onAnalytesChange={(analytes) =>
                setFilterState({
                  analytes,
                  // fall back to our default filter if we're clearing the analytes
                  labGroup: analytes.length ? '' : defaultFilter.current.value,
                })
              }
              onLabGroupChange={(labGroup) =>
                setFilterState({ analytes: [], labGroup })
              }
            />
          </div>
        )}
      </div>
      <div className={tableContainer}>
        <LoadingPlaceholder isLoading={isLoadingLabsAndGroups}>
          {!emptyLabs ? (
            <LabsTable
              // because this table is column based rather than row based, we need
              // to invalidate it at the table level when the filter changes. without
              // this, column headers get out of sync with the data
              key={filterHash}
              visibleLabs={visibleLabs}
            />
          ) : (
            <EmptyState />
          )}
        </LoadingPlaceholder>
      </div>
      <div className={syncDateContainer}>
        <LastSyncDate syncDate={lastSyncDate} />
      </div>
    </div>
  );
}
