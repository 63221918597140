import React from 'react';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import {
  getCurrentDxCodesFromProgram,
  getPreviousDxCodesFromProgram,
} from 'pages/adminPanel/patient-profile/tabs/ClinicalProfileTab/clinicalprofile.utils';
import { isManualConditionSelectionAllowed } from 'shared/common/ConditionSelect';
import type { PatientDetails } from 'shared/generated/grpcGateway/pms.pb';
import { useFlags } from 'shared/hooks';
import { convertPtConditionPerProgramToPrograms } from 'shared/hooks/queries/patients-grpc/converters';
import { isDateValid, isOneMonthAgo } from 'shared/patient/datetimeUtils';
import { PatientStatus } from 'shared/types/patient.types';

import { bannerStyle } from '../PatientOverview.css';

function isActiveDxInfoDate(date?: GoogleDate, isSuggested = false) {
  const isValidDate = date && isDateValid(date);
  if (isSuggested) {
    return isValidDate;
  }
  return isValidDate && !isOneMonthAgo(date);
}

function getBannerContent({
  patient,
  intl,
  hideSuggestedConditionBanner,
}: {
  patient: PatientDetails;
  intl: IntlShape;
  hideSuggestedConditionBanner: boolean;
}) {
  const { rpmDxCorrectedDate, rpmDxSuggestedDate } = patient.dxInfo || {};
  if (
    isActiveDxInfoDate(rpmDxSuggestedDate, true) &&
    !hideSuggestedConditionBanner
  ) {
    return intl.formatMessage({
      defaultMessage:
        'Patient has the wrong diagnosis on their order. Do NOT adjust any medications for this patient or make medication change recommendations to their PCP.',
    });
  }

  if (rpmDxCorrectedDate && isActiveDxInfoDate(rpmDxCorrectedDate)) {
    const programs = convertPtConditionPerProgramToPrograms({
      programs: patient.programs,
    });
    const newDxCodes = getCurrentDxCodesFromProgram(
      programs,
      PatientStatus.Enrolled,
    ).join(', ');
    const { isValid, prevConditions } = getPreviousDxCodesFromProgram(
      programs,
      rpmDxCorrectedDate,
    );

    const orderId = patient?.rpmOrders?.find((o) => !!o.applied)?.id;
    const idString = orderId ? `(Id: ${orderId})` : '';

    if (!isValid) {
      return intl.formatMessage(
        {
          defaultMessage:
            'Patient has been moved to {newDxCodes} due to a new order {idString} from the PCP without a valid previous dx. Please contact the PCP for a new order with the old dx if you believe this was an error.',
        },
        { newDxCodes, idString },
      );
    }

    const previousDxCodes = prevConditions?.join(', ') || newDxCodes; // this happens when new order has the same icd-10 as the old order
    const isSameCode = newDxCodes === previousDxCodes;
    if (isSameCode) {
      return null;
    }
    return intl.formatMessage(
      {
        defaultMessage:
          'Patient has been moved from {previousDxCodes} to {newDxCodes} due to a new order {idString} from the PCP. Please contact the PCP for a new order with the old dx if you believe this was an error.',
      },
      { previousDxCodes, newDxCodes, idString },
    );
  }
  return null;
}

export const PatientProfileBanner = ({
  patient,
  healthSystemId,
}: {
  patient: PatientDetails;
  healthSystemId?: string;
}) => {
  const intl = useIntl();
  const { allowManualPatientRpmConditionSelection } = useFlags();
  const hideSuggestedConditionBanner = isManualConditionSelectionAllowed(
    allowManualPatientRpmConditionSelection,
    healthSystemId,
  );
  const content = getBannerContent({
    patient,
    intl,
    hideSuggestedConditionBanner,
  });

  return <>{content && <div className={bannerStyle}>{content}</div>}</>;
};
