import cx from 'classnames';
import reduce from 'lodash/reduce';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { TypeOfEncounter } from 'pages/patients/patientDetails/ui/Notes/Notes.types';
import { RecentNotesButton } from 'pages/patients/patientDetails/ui/Notes/RecentNotes/RecentNotesButton';
import CloseIcon from 'shared/assets/svgs/close.svg?react';
import { getFullStepPath } from 'shared/common/Wizard/path.utils';
import type { SectionStepsState } from 'shared/common/Wizard/state';
import { useFlags } from 'shared/hooks';
import { flexContainer } from 'shared/jsStyle/flex.css';
import { gap } from 'shared/jsStyle/gap.css';
import type { TimerProps } from 'shared/notes/Timer';
import { Timer, getTimerUrgency } from 'shared/notes/Timer';
import { Button } from 'shared/tempo/atom/Button';
import { IconButton } from 'shared/tempo/atom/IconButton';

import { useGetAppointment } from '../../PatientScheduling/appointments.queries';
import { useCNFormContext } from '../CNWizardForm/CNFormContext';
import { CN_SECTIONS_MAP } from '../CNWizardForm/sections/metadata';
import type { CNVisitType } from '../CNWizardForm/types';
import {
  buttonsContainer,
  deleteButton,
  headerContainer,
} from './CNNotesPanelHeader.css';

type Props = {
  stepData: SectionStepsState;
  cnVisitType: CNVisitType;
  timer: Omit<TimerProps, 'totalTime'>;
  onClose: () => void;
  onDelete: () => void;
};

export function CNNotesPanelHeader({
  stepData,
  cnVisitType,
  timer: { timerRunning, onToggle, isPauseable },
  onClose,
  onDelete,
}: Props) {
  const { appointmentReminders } = useFlags();
  const totalTime = reduce(
    stepData,
    (acc, step) => (step?.elapsed || 0) + acc,
    0,
  );

  const { patientId, appointmentId } = useCNFormContext();
  const { data: appointment } = useGetAppointment(appointmentId, {
    enabled: appointmentReminders,
  });
  const history = useHistory();

  return (
    <div className={headerContainer}>
      <div className={cx(flexContainer.row, gap.L)}>
        <IconButton variant="tertiary" size="small" onPress={onClose}>
          <CloseIcon width="16" height="16" />
        </IconButton>
        <Timer
          urgency={
            appointmentReminders
              ? getTimerUrgency(totalTime, appointment?.duration)
              : 'none'
          }
          timerRunning={timerRunning}
          onToggle={onToggle}
          totalTime={totalTime}
          isPauseable={isPauseable}
        />
      </div>
      <div className={buttonsContainer}>
        <Button
          size="small"
          variant="tertiary"
          className={deleteButton}
          onPress={onDelete}
        >
          <FormattedMessage defaultMessage="Delete" />
        </Button>
        {cnVisitType !== TypeOfEncounter.PATIENT_FOLLOW_UP && (
          <Button
            size="small"
            variant="tertiary"
            onPress={() => {
              const endCallPath = getFullStepPath(
                CN_SECTIONS_MAP[cnVisitType],
                '/end-call',
                '/index',
              );
              if (!history.location.pathname.endsWith(endCallPath)) {
                history.push(endCallPath);
              }
            }}
          >
            <FormattedMessage defaultMessage="End Early" />
          </Button>
        )}
        <RecentNotesButton patientId={patientId} />
      </div>
    </div>
  );
}
