import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Input } from 'shared/tempo/atom/Input';

import type { NoteFieldValidationResult } from '../../validation';
import { TimeTrackerLabel } from '../TimeTrackerLabel';
import { ValidationMessage } from '../ValidationMessage';
import { container, label as labelClass } from './TimeTrackingTextInput.css';

type Props = {
  label: ReactNode;
  onChange: (value: string) => void;
  value?: string;
  placeholder?: string;
  fieldValidationResult?: NoteFieldValidationResult;
};

export function TimeTrackingTextInput({
  label,
  onChange,
  placeholder,
  value = '',
  fieldValidationResult,
}: Props) {
  const messageOverride = useValidationMessageOverride(fieldValidationResult);
  return (
    <div className={container}>
      <TimeTrackerLabel
        message={label}
        className={labelClass}
        error={Boolean(fieldValidationResult)}
      />
      <Input
        value={value}
        placeholder={placeholder}
        hasError={Boolean(fieldValidationResult)}
        onChange={(event) => onChange(event.target.value)}
        maxLength={400}
      />
      <ValidationMessage validationMessage={messageOverride} />
    </div>
  );
}

function useValidationMessageOverride(
  fieldValidationResult?: NoteFieldValidationResult,
) {
  switch (fieldValidationResult?.type) {
    case 'required':
      return <FormattedMessage defaultMessage="Required" />;
    case 'len':
      return (
        <FormattedMessage defaultMessage="May not exceed 400 characters" />
      );
    default:
      return null;
  }
}
