import { FocusRing } from '@react-aria/focus';
import { useMenuItem } from '@react-aria/menu';
import { filterDOMProps, useObjectRef } from '@react-aria/utils';
import type { TreeState } from '@react-stately/tree';
import type { Node } from '@react-types/shared';
import cx from 'classnames';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import type { AriaMenuItemProps } from 'react-aria';
import { mergeProps } from 'react-aria';

import {
  option,
  optionFocusOutline,
} from 'shared/tempo/shared/styles/listStyles.css';
import { focusRingCss } from 'shared/tempo/util/FocusRing/FocusRing.css';

type Props<T> = {
  item: Node<T>;
  state: TreeState<T>;
} & Omit<AriaMenuItemProps, 'isVirtualized'>;

function MenuItem<T>(
  { state, item }: Props<T>,
  forwardedRef: ForwardedRef<HTMLLIElement>,
) {
  const ref = useObjectRef(forwardedRef);
  const {
    isFocused: isHovered,
    isSelected,
    isPressed,
    menuItemProps,
    labelProps,
    descriptionProps,
    keyboardShortcutProps,
  } = useMenuItem({ key: item.key }, state, ref);

  const domProps = filterDOMProps(item.props);

  return (
    <FocusRing focusRingClass={cx(focusRingCss.keyboard, optionFocusOutline)}>
      <li
        {...mergeProps(menuItemProps, domProps)}
        ref={ref}
        className={cx({
          [option.default]: !isSelected && !isHovered && !isPressed,
          [option.selected]: isSelected || isPressed,
          [option.hover]: isHovered,
        })}
      >
        <span {...descriptionProps} {...labelProps} {...keyboardShortcutProps}>
          {item.rendered}
        </span>
      </li>
    </FocusRing>
  );
}

const ForwardedMenuItem = forwardRef(MenuItem);

export { ForwardedMenuItem as MenuItem };
