import { useIntl } from 'react-intl';

import { useTimeEstimate } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from 'shared/common/Wizard';
import { useWizardFormFromConfig } from 'shared/common/Wizard/state';

import { CYCLE_17_INIT_VISIT_SECTIONS } from '../../metadata';
import {
  PatientGoalRadioSection,
  getPatientGoalFormConfig,
} from '../../shared/setPatientGoal';
import { cycle17InitVisitPath } from '../paths';

export function SetGoal() {
  const intl = useIntl();
  const form = useWizardFormFromConfig(
    cycle17InitVisitPath('/action-plan', '/set-goal'),
    getPatientGoalFormConfig(intl),
  );

  return (
    <Wizard.Step
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      form={form}
      title={intl.formatMessage({ defaultMessage: 'Patient Goal' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
    >
      <PatientGoalRadioSection />
    </Wizard.Step>
  );
}
