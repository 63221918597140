import omit from 'lodash/omit';
import type { QueryFunctionContext } from 'react-query/types/core/types';

import { getQueryParamsFromQueryKey } from 'reactQuery';

import type { QueryParamsFilterLookup } from '../patients-grpc';
import { GrpcFilterBuilder } from '../patients-grpc';

export type GrpcRequest = {
  pageSize?: number;
  pageToken?: string;
  filter?: string;
  orderBy?: string;
};

export type GrpcResponse = {
  nextPageToken?: string;
  totalSize?: number;
};

export const buildConferenceRPCRequest = (
  ctx: QueryFunctionContext,
  qpFilterLookup: QueryParamsFilterLookup,
): GrpcRequest => {
  const queryParams = getQueryParamsFromQueryKey(ctx.queryKey);

  let filter = '';

  if (queryParams) {
    filter = new GrpcFilterBuilder().fromQueryParams(
      omit(queryParams, ['sort_by', 'order_by']),
      qpFilterLookup,
    ).filter;
  }
  return {
    pageToken: ctx.pageParam,
    filter,
  };
};
