import type { IntlShape } from 'react-intl';
import * as yup from 'yup';
import type { TestContext } from 'yup';

import { validators } from 'shared/common/Form/validations';
import { ConditionProgram } from 'shared/types/condition.types';

import type { Symptom } from './symptomList';
import { ALL_SYMPTOMS, SYMPTOMS_BY_PROGRAM } from './symptomList';

type SymptomChecked = {
  [P in `${Symptom}-checked`]: boolean;
};

type SymptomNewSymptom = {
  [P in `${Symptom}-newSymptom`]?: 'true' | 'false';
};

type SymptomNewSeverity = {
  [P in `${Symptom}-newSeverity`]?: 'true' | 'false';
};

type SymptomFields = SymptomChecked & SymptomNewSymptom & SymptomNewSeverity;

export type SecondarySymptomsFormFields = {
  newSecondarySymptoms: 'true' | 'false';
} & SymptomFields;

export function getSecondarySymptomsFormConfig(
  intl: IntlShape,
  condition?: Nullable<ConditionProgram>,
) {
  const { required, boolean } = validators(intl);
  const symptomList = condition ? SYMPTOMS_BY_PROGRAM[condition] : ALL_SYMPTOMS;
  const symptomFields = symptomList.reduce(
    (acc, symptom) => ({
      ...acc,
      [`${symptom}-checked`]: {
        defaultValue: undefined,
        validation: boolean(),
      },
      [`${symptom}-newSymptom`]: {
        defaultValue: undefined,
        validation: yup.string().when(`${symptom}-checked`, {
          is: true,
          then: (schema) => required(schema.oneOf(['true', 'false'])),
        }),
      },
      [`${symptom}-newSeverity`]: {
        defaultValue: undefined,
        validation: yup.string().when(`${symptom}-newSymptom`, {
          is: 'false',
          then: (schema) => required(schema.oneOf(['true', 'false'])),
        }),
      },
    }),
    {},
  );

  return {
    fields: {
      // we don't capture this information for CHF patients, just a list of conditions
      ...(condition !== ConditionProgram.CHF && {
        newSecondarySymptoms: {
          defaultValue: undefined,
          validation: required(yup.string().oneOf(['true', 'false'])).test(
            'at-least-one-checked-symptom-when-true',
            intl.formatMessage({
              defaultMessage: 'At least one symptom must be selected',
            }),
            (value: 'true' | 'false', context: TestContext) => {
              if (value === 'true') {
                // Require at least one symptom to be checked
                const values = context.parent as SecondarySymptomsFormFields;
                return symptomList.some((s) => values[`${s}-checked`]);
              }
              return true;
            },
          ),
        },
      }),
      ...symptomFields,
    },
  };
}
