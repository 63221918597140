import { FormattedMessage } from 'react-intl';

import {
  GRPC_RESOURCE_NAME,
  useUpdateTask,
} from 'shared/hooks/queries/tasks.queries';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import type { Task } from 'shared/tasking/types';
import { Button } from 'shared/tempo/atom/Button';

type Props = {
  task: Task;
  isDisabled?: boolean;
  onSelfAssignTask?: () => void;
};

export function UnassignedActions({
  task,
  isDisabled,
  onSelfAssignTask,
}: Props) {
  const { isLoading: isSaving, mutate: updateTask } = useUpdateTask(task, {
    onSuccess: onSelfAssignTask,
  });
  const { currentUserId } = useCurrentUser();

  const selfAssignTask = () => {
    updateTask({
      task: {
        assignee: {
          name: GRPC_RESOURCE_NAME.careProvider(currentUserId),
        },
      },
    });
  };

  return (
    <Button
      size="small"
      isDisabled={isDisabled}
      isProcessing={isSaving}
      onPress={selfAssignTask}
    >
      <FormattedMessage defaultMessage="Take it" />
    </Button>
  );
}
