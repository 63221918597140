import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import { validators } from 'shared/common/Form/validations';
import { ConditionProgram } from 'shared/types/condition.types';

export type SymptomsFormFields = {
  newConcerns: 'true' | 'false';
  patientReportedSymptoms?: string;

  // HTN
  newWeakness?: 'true' | 'false';
  fainting?: 'true' | 'false';
  slurredSpeech?: 'true' | 'false';

  // T2D
  confusion?: 'true' | 'false';

  // CHF
  chestPain?: 'true' | 'false';
  dizziness?: 'true' | 'false';

  clinicalEscalationConfirmed?: 'true' | 'false';
  escalationDetails?: string;
};

const PROGRAM_SYMPTOMS: Record<
  ConditionProgram,
  Array<keyof SymptomsFormFields>
> = {
  [ConditionProgram.Hypertension]: ['newWeakness', 'fainting', 'slurredSpeech'],
  [ConditionProgram.TypeTwoDiabetes]: ['confusion'],
  [ConditionProgram.T2DAndHTN]: [
    'confusion',
    'newWeakness',
    'fainting',
    'slurredSpeech',
  ],
  [ConditionProgram.CHF]: ['chestPain', 'dizziness'],

  // unsupported
  [ConditionProgram.COPD]: [],
  [ConditionProgram.CCM]: [],
};

export function getSymptomsFormConfig(
  intl: IntlShape,
  program: Nullable<ConditionProgram>,
) {
  const { required } = validators(intl);
  const requiredWhenRelevant = (field: keyof SymptomsFormFields) =>
    yup.string().when('newConcerns', {
      is: (value: string) =>
        !!program &&
        PROGRAM_SYMPTOMS[program].includes(field) &&
        value === 'true',
      then: (schema) => required(schema.oneOf(['true', 'false'])),
    });

  return {
    fields: {
      newConcerns: {
        defaultValue: undefined,
        validation: required(yup.string().oneOf(['true', 'false'])),
      },
      patientReportedSymptoms: {
        defaultValue: undefined,
        validation: yup.string().when('newConcerns', {
          is: 'true',
          then: required,
        }),
      },
      newWeakness: {
        defaultValue: undefined,
        validation: requiredWhenRelevant('newWeakness'),
      },
      fainting: {
        defaultValue: undefined,
        validation: requiredWhenRelevant('fainting'),
      },
      slurredSpeech: {
        defaultValue: undefined,
        validation: requiredWhenRelevant('slurredSpeech'),
      },
      confusion: {
        defaultValue: undefined,
        validation: requiredWhenRelevant('confusion'),
      },
      chestPain: {
        defaultValue: undefined,
        validation: requiredWhenRelevant('chestPain'),
      },
      dizziness: {
        defaultValue: undefined,
        validation: requiredWhenRelevant('dizziness'),
      },
      clinicalEscalationConfirmed: {
        defaultValue: undefined,
        validation: yup
          .string()
          .when(
            [
              'newWeakness',
              'fainting',
              'slurredSpeech',
              'confusion',
              'chestPain',
              'dizziness',
            ],
            {
              is: (...values: ('true' | 'false')[]) =>
                values.some((value) => value === 'true'),
              then: (schema) => required(schema.oneOf(['true', 'false'])),
            },
          ),
      },
      escalationDetails: {
        defaultValue: undefined,
        validation: yup.string().when('clinicalEscalationConfirmed', {
          is: 'true',
          then: required,
        }),
      },
    },
  };
}
