import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';

import TrashIcon from 'shared/assets/svgs/trash.svg?react';
import {
  useCarePlanDraft,
  useCarePlanNoteInfo,
  useDeleteCarePlan,
} from 'shared/hooks/queries/carePlan.queries';
import { Button } from 'shared/tempo/atom/Button';
import { useToaster } from 'shared/tempo/molecule/Toast';
import { color } from 'shared/tempo/theme';
import { NoteStatus } from 'shared/types/note.types';
import { grpcNameToId } from 'shared/utils/grpc';
import { parentPath } from 'shared/utils/route-helpers';

import { Form } from '../Form';
import { useFormFromConfig } from '../Form/FormContainer';
import { LoadingPlaceholder } from '../LoadingPlaceholder';
import { actions, formWrapper } from './CarePlanForm.css';
import { marshalCarePlanToFormData } from './dataTransformation';
import { getFormConfig } from './formConfig';
import { useAutosaveCarePlan, useOnSave } from './persistence';
import { EmergencyVisits } from './sections/EmergencyVisits';
import { GoalsAndInterventions } from './sections/GoalsAndInterventions';
import { MedicationAllergies } from './sections/MedicationAllergies';
import { Medications } from './sections/Medications';
import { PastSurgicalHistory } from './sections/PastSurgicalHistory';
import { PreventativeCare } from './sections/PreventativeCare';
import { PreventativeCareGaps } from './sections/PreventativeCareGaps';
import { ProblemList } from './sections/ProblemList';
import { PsychosocialAssessment } from './sections/PsychosocialAssessment';
import { Specialists } from './sections/Specialists';
import { sectionTitle } from './sections/carePlanSections.css';

export function CarePlanForm() {
  const intl = useIntl();
  const history = useHistory();
  const { url, params } = useRouteMatch<{ patientId: string }>();
  const { toaster } = useToaster();

  function navigateUp() {
    history.push(parentPath(url));
  }

  const {
    data: existingDraft,
    isLoading: isLoadingDraft,
    isFetching: isFetchingDraft,
  } = useCarePlanDraft(params.patientId, {
    onSuccess(data) {
      if (!data.ccmCarePlans?.length) {
        // No draft so navigate up
        navigateUp();
      }
    },
  });
  const draftPlan = existingDraft?.carePlan;
  const hasDraftPlan = !!draftPlan;
  const noteId = hasDraftPlan ? (existingDraft.noteId as number) : undefined;

  const form = useFormFromConfig({
    ...getFormConfig(
      intl,
      hasDraftPlan ? marshalCarePlanToFormData(draftPlan) : {},
    ),
    triggerReset: !isLoadingDraft && hasDraftPlan,
  });

  const { onSave, isSaving } = useOnSave(params.patientId, noteId);
  useAutosaveCarePlan(form, onSave, { enabled: !isFetchingDraft });

  const draftCarePlanId = existingDraft?.name
    ? grpcNameToId(existingDraft.name)
    : undefined;
  const {
    data: noteInfo,
    isLoading: isLoadingNoteInfo,
    isFetching: isFetchingNoteInfo,
  } = useCarePlanNoteInfo(draftCarePlanId || '', {
    enabled: Boolean(draftCarePlanId),
  });

  const associatedNoteIsPublished =
    noteInfo?.noteStatus === NoteStatus.Published;

  const { mutate: deleteCarePlan } = useDeleteCarePlan({
    onSuccess: () => navigateUp(),
    onError: () =>
      toaster.error(
        intl.formatMessage({ defaultMessage: 'Failed to delete care plan' }),
      ),
  });

  if (isLoadingDraft || !noteId) {
    return <LoadingPlaceholder isLoading />;
  }

  return (
    <div className={formWrapper}>
      <Form
        form={form}
        onSubmit={(data) => {
          onSave(data, {
            onSuccess: () => navigateUp(),
          });
        }}
      >
        {({ canSubmit }) => (
          <>
            <ProblemList />
            <PastSurgicalHistory form={form} />
            <Specialists form={form} />
            <EmergencyVisits form={form} />
            <Medications noteId={noteId} />
            <MedicationAllergies form={form} />
            <PreventativeCare form={form} />
            <Form.Section
              title={intl.formatMessage({ defaultMessage: 'Social history' })}
              classes={{ title: sectionTitle }}
            >
              <Form.Row>
                <Form.TextField
                  size={11}
                  label={
                    <FormattedMessage defaultMessage="History of tobacco use?" />
                  }
                  name="tobaccoUse"
                />
              </Form.Row>
            </Form.Section>
            <Form.Section
              title={intl.formatMessage({ defaultMessage: 'Diet' })}
              classes={{ title: sectionTitle }}
            >
              <Form.Row>
                <Form.TextField
                  size={11}
                  label={
                    <FormattedMessage defaultMessage="How many times per week do you eat fast food meals?" />
                  }
                  name="fastFood"
                />
              </Form.Row>
              <Form.Row>
                <Form.TextField
                  size={11}
                  label={
                    <FormattedMessage defaultMessage="Do you snack often?" />
                  }
                  name="snacks"
                />
              </Form.Row>
              <Form.Row>
                <Form.TextField
                  size={11}
                  label={
                    <FormattedMessage defaultMessage="How many times per week do you eat desserts or other sweets?" />
                  }
                  name="desserts"
                />
              </Form.Row>
              <Form.Row>
                <Form.TextField
                  size={11}
                  label={
                    <FormattedMessage defaultMessage="Do you drink regular soda?" />
                  }
                  name="regularSoda"
                />
              </Form.Row>
              <Form.Row>
                <Form.TextField
                  size={11}
                  label={
                    <FormattedMessage defaultMessage="Do you drink sweet tea?" />
                  }
                  name="sweetTea"
                />
              </Form.Row>
              <Form.Row>
                <Form.TextField
                  size={11}
                  label={
                    <FormattedMessage defaultMessage="How much water do you drink per day?" />
                  }
                  name="waterIntake"
                />
              </Form.Row>
              <Form.Row>
                <Form.TextField
                  size={11}
                  label={
                    <FormattedMessage defaultMessage="Do you add salt to meals regularly?" />
                  }
                  name="saltIntake"
                />
              </Form.Row>
            </Form.Section>
            <Form.Section
              title={intl.formatMessage({ defaultMessage: 'Exercise' })}
              classes={{ title: sectionTitle }}
            >
              <Form.Row>
                <Form.TextArea
                  size={11}
                  rows={3}
                  label={
                    <FormattedMessage defaultMessage="Do you exercise regularly? If so, what type of exercise? How long and how often?" />
                  }
                  name="exercise"
                />
              </Form.Row>
            </Form.Section>
            <PsychosocialAssessment form={form} />
            <GoalsAndInterventions form={form} />
            <PreventativeCareGaps form={form} />
            <Form.Section
              title={intl.formatMessage({ defaultMessage: 'Follow up' })}
              classes={{ title: sectionTitle }}
            >
              <Form.Row>
                <Form.TextField
                  size={11}
                  label={
                    <FormattedMessage defaultMessage="Date and time for next follow-up with my chronic care manager" />
                  }
                  name="ccmAppointment"
                />
              </Form.Row>
              <Form.Row>
                <Form.TextField
                  size={11}
                  label={
                    <FormattedMessage defaultMessage="Date and time for next appointment with my doctor" />
                  }
                  name="providerAppointment"
                />
              </Form.Row>
            </Form.Section>
            <Form.GridItem size={11} className={actions.container}>
              {!associatedNoteIsPublished ? (
                <Button
                  variant="tertiary"
                  isDisabled={isLoadingNoteInfo || isFetchingNoteInfo}
                  className={actions.deleteButton}
                  onPress={() => {
                    if (existingDraft?.name) {
                      deleteCarePlan(existingDraft?.name);
                    } else {
                      // if we don't have a name we haven't saved yet so we can just navigate out
                      navigateUp();
                    }
                  }}
                >
                  <Button.Icon>
                    <TrashIcon fill={color.Theme.Light.Danger} />
                  </Button.Icon>
                  <FormattedMessage defaultMessage="Delete" />
                </Button>
              ) : (
                // Empty Div to keep Save button on the right
                <div />
              )}
              <Button
                type="submit"
                isDisabled={!canSubmit}
                isProcessing={isSaving}
              >
                <FormattedMessage defaultMessage="Save Draft" />
              </Button>
            </Form.GridItem>
          </>
        )}
      </Form>
    </div>
  );
}
