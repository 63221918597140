import { useMemo } from 'react';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import type { TableHeaderProps } from 'shared/common/@deprecated/Table';
import { TableHeader } from 'shared/common/@deprecated/Table';

function getColumnHeaders(intl: IntlShape): TableHeaderProps['columnHeaders'] {
  return [
    {
      message: intl.formatMessage({ defaultMessage: 'Last Update' }),
      style: { textAlign: 'center' },
    },
    {
      message: intl.formatMessage({
        defaultMessage: 'BP',
        description: 'Abbreviation for blood pressure',
      }),
      style: { textAlign: 'center' },
    },
    {
      message: intl.formatMessage({
        defaultMessage: 'HR',
        description: 'Abbreviation for heart rate',
      }),
      style: { textAlign: 'center' },
    },
    {
      message: intl.formatMessage({ defaultMessage: 'Weight' }),
      style: { textAlign: 'center' },
    },
    {
      message: intl.formatMessage({
        defaultMessage: 'BG',
        description: 'Abbreviation for blood glucose',
      }),
      style: { textAlign: 'center' },
    },
  ];
}

export const VitalsTableHeader = () => {
  const intl = useIntl();
  const headers = useMemo(() => getColumnHeaders(intl), [intl]);

  return <TableHeader columnHeaders={headers} />;
};
