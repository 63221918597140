import { FormattedMessage } from 'react-intl';

import { FormLabel } from 'deprecated/mui';
import AlertTriangle from 'shared/assets/svgs/alertTriangle.svg?react';

import { errorIcon, errorMessage, errorMessageHidden } from './NoteEditor.css';

export const ErrorMessage = ({ hasError }: { hasError: boolean }) => (
  <FormLabel className={hasError ? errorMessage : errorMessageHidden}>
    <AlertTriangle className={errorIcon} />
    <FormattedMessage defaultMessage="This field is required" />
  </FormLabel>
);
