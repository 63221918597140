import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import type { UseInfiniteQueryResult } from 'react-query';

import {
  PatientProfileModal,
  usePatientProfileCtx,
} from 'pages/patients/PatientProfile/context/PatientProfileCtx';
import { useFlatPages } from 'reactQuery';
import CreateTaskIcon from 'shared/assets/svgs/arrowUpRight.svg?react';
import { InfiniteScrollLoader } from 'shared/common/InfiniteScrollLoader';
import { useFlags } from 'shared/hooks';
import type { PaginatedTasks } from 'shared/hooks/queries/tasks.queries';
import {
  PlaceholderImage,
  EmptyTasks as SharedEmptyTasks,
} from 'shared/tasking/EmptyTasks';
import { TaskCardSkeleton } from 'shared/tasking/TaskCard/TaskCardSkeleton';
import { TaskCountHeader } from 'shared/tasking/TaskCountHeader';
import {
  ParentView,
  TaskList as SharedTaskList,
} from 'shared/tasking/TaskList';
import { Button } from 'shared/tempo/atom/Button';
import { color } from 'shared/tempo/theme';

import { collapsedContainer, emptyState, header } from './ProfileTaskList.css';

type Props = {
  query: UseInfiniteQueryResult<PaginatedTasks>;
};

export function ProfileTaskList({ query }: Props) {
  const { cycle17TaskHubUpdates } = useFlags();
  const { setCurrentModal } = usePatientProfileCtx();
  const tasks = useFlatPages(query);

  if (!tasks.length) {
    return cycle17TaskHubUpdates ? (
      <SharedEmptyTasks
        placeHolderImage={PlaceholderImage.Tray}
        message={
          <FormattedMessage defaultMessage="No tasks here at the moment" />
        }
      />
    ) : (
      <EmptyTasks />
    );
  }

  return (
    <>
      {!cycle17TaskHubUpdates && (
        <div className={header}>
          <TaskCountHeader query={query} />
          <Button
            variant="tertiary"
            onPress={() => setCurrentModal(PatientProfileModal.CreateTask)}
          >
            <Button.Icon>
              <CreateTaskIcon />
            </Button.Icon>
            <FormattedMessage defaultMessage="Create Task" />
          </Button>
        </div>
      )}
      <SharedTaskList
        tasks={tasks}
        parentView={ParentView.PatientProfile}
        taskHubOverlayState={null}
        classes={{
          container: cx({ [collapsedContainer]: cycle17TaskHubUpdates }),
        }}
      />
      <InfiniteScrollLoader
        query={query}
        loadingPlaceholder={<TaskCardSkeleton />}
      />
    </>
  );
}

function EmptyTasks() {
  const { setCurrentModal } = usePatientProfileCtx();

  return (
    <SharedEmptyTasks>
      <Button
        className={emptyState.createTaskButton}
        size="small"
        variant="secondary"
        onPress={() => setCurrentModal(PatientProfileModal.CreateTask)}
      >
        <Button.Icon>
          <CreateTaskIcon fill={color.Theme.Light.Primary} />
        </Button.Icon>
        <FormattedMessage defaultMessage="Create task" />
      </Button>
    </SharedEmptyTasks>
  );
}
