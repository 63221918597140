import { useIntl } from 'react-intl';

import type { DeviceProviderType } from 'shared/types/device.types';
import { DeviceProvider } from 'shared/types/device.types';

export function useDeviceProviderTypeI18nMap(): Record<
  DeviceProviderType,
  string
> {
  const intl = useIntl();

  return {
    [DeviceProvider.BIOTEL]: intl.formatMessage({
      defaultMessage: 'BioTel',
    }),
    [DeviceProvider.BODYTRACE]: intl.formatMessage({
      defaultMessage: 'BodyTrace',
    }),
    [DeviceProvider.SMART_METER]: intl.formatMessage({
      defaultMessage: 'Smart Meter',
    }),
    [DeviceProvider.WITHINGS]: intl.formatMessage({
      defaultMessage: 'Withings',
    }),
    [DeviceProvider.TRANSTEK]: intl.formatMessage({
      defaultMessage: 'Transtek',
    }),
  };
}
