import type { IntlShape } from 'react-intl';

import { INIT_VISIT_SECTIONS } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/initialVisit';
import type { IntroFormFields } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/IntroPatientAttendance';
import type { SectionStepsState } from 'shared/common/Wizard/state';
import { getWizardStepValues } from 'shared/common/Wizard/state';

import { cnPatientNoShow } from '../../shared/cnPatientNoShow';

export function initialVisitPatientNoShow(
  data: SectionStepsState,
  intl: IntlShape,
) {
  const introData = getWizardStepValues(
    data,
    INIT_VISIT_SECTIONS,
    '/intro',
    '/patient-attendance',
  )<IntroFormFields>();

  return cnPatientNoShow({ intl, introData });
}
