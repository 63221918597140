import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from 'shared/common/Wizard/state';

import { useCNFormContext } from '../../../CNFormContext';
import { CHF_VISIT_SECTIONS } from '../../metadata';
import type { SymptomsFormFields } from '../../shared/Symptoms';
import { SharedSymptoms, getSymptomsFormConfig } from '../../shared/Symptoms';
import { chfVisitPath } from '../paths';

export function Symptoms() {
  const intl = useIntl();
  const { program } = useCNFormContext();
  const form = useWizardFormFromConfig<SymptomsFormFields>(
    chfVisitPath('/symptoms', '/index'),
    getSymptomsFormConfig(intl, program),
  );

  return <SharedSymptoms form={form} sections={CHF_VISIT_SECTIONS} />;
}
