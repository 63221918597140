import type { BaseFieldProps } from 'shared/common/Form/fields/BaseField';
import { BaseField } from 'shared/common/Form/fields/BaseField';
import { TimePicker } from 'shared/common/TimePicker';

type Props = {} & BaseFieldProps;

export function TimeTrackerTimeField({ ...props }: Props) {
  return (
    <BaseField {...props}>
      {({ controller }) => (
        <TimePicker
          error={!!controller.fieldState.error}
          {...controller.field}
        />
      )}
    </BaseField>
  );
}
