import { useIntl } from 'react-intl';

import { Option, Select } from 'shared/common/Select';
import type { TimePeriod } from 'shared/utils/time-helpers';
import { TWELVE_HOURS_RANGE } from 'shared/utils/time-helpers';

import { getTimePeriodsOptions } from './vitalReminders.utils';

export type Props = {
  initHour: string;
  initAMPM: TimePeriod;
  disabled: boolean;
  onHourChange: (hour: string) => void;
  onAMPMChange: (ampm: TimePeriod) => void;
};
export const TimeInputs = ({
  initHour,
  initAMPM,
  disabled,
  onHourChange,
  onAMPMChange,
}: Props) => {
  const intl = useIntl();
  return (
    <>
      <Select
        isDisabled={disabled}
        customStyle={{ width: '100px' }}
        variant="outlined"
        value={initHour}
        onChange={(e) => onHourChange(e.target.value)}
      >
        {TWELVE_HOURS_RANGE.map((hour) => (
          <Option key={hour} value={String(hour)}>
            {hour}
          </Option>
        ))}
      </Select>
      <Select
        isDisabled={disabled}
        customStyle={{ width: '100px' }}
        variant="outlined"
        value={initAMPM}
        onChange={(e) => onAMPMChange(e.target.value as TimePeriod)}
      >
        {getTimePeriodsOptions(intl).map(({ value, text }) => (
          <Option key={value} value={value}>
            {text}
          </Option>
        ))}
      </Select>
    </>
  );
};
