import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { TaskChangeNotificationRequest } from 'shared/generated/grpcWeb/task_pb';
import { TaskServiceClient } from 'shared/generated/grpcWeb/task_pb.client';
import { ClientFactory, retry } from 'shared/grpcWeb';
import { TASKS_QUERY_KEY_BASE } from 'shared/hooks/queries/tasks.queries';

/**
 * Listens to changes to tasks and invalidates the client query cache
 */
export function useListenToTaskNotifications() {
  const client = useQueryClient();
  useEffect(() => {
    const abortController = new AbortController();
    const taskClient = ClientFactory(TaskServiceClient);

    // TODO: If connection is lost, and then re-gained, we should probably
    // re-fetch the tasks
    retry(
      () => {
        const call = taskClient.changeNotifications(
          TaskChangeNotificationRequest.create(),
          { abort: abortController.signal },
        );

        call.responses.onMessage(() => {
          // Invalidate queries for tasks
          client.invalidateQueries(TASKS_QUERY_KEY_BASE);
        });
        return call;
      },
      { exponential: true },
    );

    // Close the request on unmount
    return () => abortController.abort();
  }, [client]);
}
