import type { ReactNode } from 'react';
import { MemoryRouter } from 'react-router-dom';

import type { WizardStateAndHandlers } from './WizardStateContext';
import { WizardStateProvider } from './WizardStateContext';
import type { WizardFormData } from './useWizardState';
import { useWizardState } from './useWizardState';

type Props = {
  initPath: string;
  children: ReactNode | ((props: WizardStateAndHandlers) => ReactNode);
  initialState?: WizardFormData;
  onStateChange?: (newState: WizardFormData) => void;
};

export function ConfiguredWizardProvider({
  children,
  initialState,
  initPath,
  onStateChange,
}: Props) {
  const wizardState = useWizardState(initialState, onStateChange);
  const {
    wizardFormData: { navigationHistory },
  } = wizardState;
  const historyEntries = !navigationHistory.length
    ? [initPath]
    : navigationHistory;

  return (
    <MemoryRouter
      initialIndex={historyEntries.length}
      initialEntries={historyEntries}
    >
      <WizardStateProvider value={wizardState}>
        {typeof children === 'function' ? children(wizardState) : children}
      </WizardStateProvider>
    </MemoryRouter>
  );
}
