import { Link } from 'react-router-dom';

import { Modal } from 'shared/common/Modal';

import { useCategories } from '../useCategories';
import { categoryList, itemContainer } from './page.css';

export function Categories() {
  const categories = useCategories();

  return (
    <Modal.Body>
      <div className={categoryList.list}>
        {categories.map(({ description, to, label, icon }) => (
          <Link to={to} key={to} className={itemContainer}>
            <div className={categoryList.item}>
              {icon}
              <div>
                <div className={categoryList.title}>{label}</div>
                <div className={categoryList.description}>{description}</div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </Modal.Body>
  );
}
