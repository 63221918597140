import type { TabListProps, TabProps } from 'deprecated/mui';
import { Tab, TabList } from 'deprecated/mui';

import {
  segmentedControl,
  segmentedControlTab,
} from './SegmentecControlTabList.css';

/**
 * A TabList styled like a segmented control (See https://ux.stackexchange.com/questions/85552/using-segmented-controls-vs-tab-bar for the difference between segmented controls vs. tabs)
 */
export const SegmentedControlTabList = (props: TabListProps) => (
  <TabList classes={segmentedControl} {...props} />
);

export const SegmentedControlTab = ({
  position = 'base',
  ...props
}: TabProps & { position: 'left' | 'right' | 'base' }) => (
  <Tab disableRipple className={segmentedControlTab[position]} {...props} />
);
