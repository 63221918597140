import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from 'shared/common/Wizard/state';
import { ConditionProgram } from 'shared/types/condition.types';

import { CHF_VISIT_SECTIONS } from '../../../metadata';
import type { SecondarySymptomsFormFields } from '../../../shared/SecondarySymptoms';
import {
  SharedSecondarySymptoms,
  getSecondarySymptomsFormConfig,
} from '../../../shared/SecondarySymptoms';
import { chfVisitPath } from '../../paths';

export function SecondarySymptoms() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<SecondarySymptomsFormFields>(
    chfVisitPath('/symptoms', '/secondary'),
    getSecondarySymptomsFormConfig(intl, ConditionProgram.CHF),
  );

  return (
    <SharedSecondarySymptoms
      form={form}
      sections={CHF_VISIT_SECTIONS}
      nextPath={chfVisitPath('/emergency-visits', '/index')}
    />
  );
}
