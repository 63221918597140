import { createCtx } from 'shared/hooks/createCtx';

export enum PatientProfileModal {
  CreateTask = 'create_task',
  Debug = 'debug',
}

// Global state for the PatientProfile page
export type PatientProfileCtx = {
  vitalsStartDate: Date;
  vitalsEndDate: Date;
  setVitalsStartDate: (m: Date) => void;
  setVitalsEndDate: (m: Date) => void;
  currentModal: Nullable<PatientProfileModal>;
  setCurrentModal: React.Dispatch<
    React.SetStateAction<Nullable<PatientProfileModal>>
  >;
};

export const [usePatientProfileCtx, PatientProfileProvider] =
  createCtx<PatientProfileCtx>('PatientProfileCtx');
