import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  FieldGroup,
  Script,
  YesNoRadioGroup,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { InlineMessage } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/InlineMessage';
import { scriptLabel } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';
import { infoMessage } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/shared.css';
import { useTimeEstimate } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Form } from 'shared/common/Form';
import type { ConfiguredForm } from 'shared/common/Form/FormContainer';
import { Wizard } from 'shared/common/Wizard';
import { flexContainer } from 'shared/jsStyle/flex.css';
import { gap } from 'shared/jsStyle/gap.css';
import { Label } from 'shared/tempo/atom/Label';
import { TimePeriod } from 'shared/utils/time-helpers';

import type { CNSectionMap } from '../../metadata';
import { scheduleLabel } from './SharedLowDeviceUsage.css';
import type { DeviceUsageGoalsFormFields } from './formConfig';

type Props = {
  form: ConfiguredForm<DeviceUsageGoalsFormFields>;
  sections: CNSectionMap;
  onNext: () => void;
  isLoading?: boolean;
};

export function SharedLowDeviceUsage({
  form,
  sections,
  onNext,
  isLoading,
}: Props) {
  const intl = useIntl();
  const timeOptions = useTimeOptions();
  const declinedToSetGoal = form.watch('declinedToSetGoal');
  const visibility = useSectionVisibility(form);

  return (
    <Wizard.Step
      sections={sections}
      title={intl.formatMessage({ defaultMessage: 'Product Support' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      form={form}
      isLoading={isLoading}
      onNext={onNext}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage defaultMessage="We hear from a lot of people in the beginning that they simply forget to take their readings. Is that what is happening for you?" />
        </Script>
        <Script>
          <FormattedMessage defaultMessage="The best time to take your measurements is anytime you remember to do so. So, if you forget to take them in the morning, that's okay! You should go ahead and take them whenever you remember." />
        </Script>
        <Script>
          <FormattedMessage defaultMessage="I have heard from other patients that it can be helpful to put your devices near your favorite chair, next to your bed, or anywhere that you would notice during your normal morning or evening routine." />
        </Script>
        <YesNoRadioGroup
          name="devicesAccessible"
          classes={{ label: scriptLabel }}
          label={
            <Script>
              <FormattedMessage defaultMessage="Where are you storing your devices? Do you keep your devices somewhere accessible to remind you to take them every day?" />
            </Script>
          }
          required={!declinedToSetGoal}
        />
        {visibility.deviceLocation && (
          <>
            <Script>
              <FormattedMessage defaultMessage="Where's an easy place for you to keep your devices so you can remember to use them every day?" />
            </Script>
            <InlineMessage className={infoMessage}>
              <FormattedMessage defaultMessage="If setting a goal for device readings is not relevant for patient, mark declined to set goal toggle." />
            </InlineMessage>
            <Form.TextField
              size={12}
              name="deviceLocation"
              label={
                <FormattedMessage defaultMessage="Select a location goal with the patient" />
              }
              required={!declinedToSetGoal}
            />
          </>
        )}
        {visibility.hasSchedule && (
          <YesNoRadioGroup
            name="hasSchedule"
            classes={{ label: scriptLabel }}
            label={
              <Script>
                <FormattedMessage defaultMessage="Is there a specific time of day you strive to take your readings every day?" />
              </Script>
            }
            required={!declinedToSetGoal}
          />
        )}
        {visibility.goalSchedule && (
          <>
            <Script>
              <FormattedMessage defaultMessage="What time of day do you plan to take your measurements?" />
            </Script>
            <div>
              <Label
                className={scheduleLabel}
                label={
                  <FormattedMessage defaultMessage="Set a goal with the patient and share timing reminders" />
                }
                isRequired={!declinedToSetGoal}
              />
              <div className={cx(flexContainer.row, gap.S)}>
                <Form.DeprecatedSelect
                  label=""
                  ariaLabel={intl.formatMessage({
                    defaultMessage: 'Select an hour',
                  })}
                  name="goalScheduleHour"
                  size={3}
                >
                  <option value="" selected disabled>
                    {intl.formatMessage({ defaultMessage: 'Select' })}
                  </option>
                  {timeOptions.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Form.DeprecatedSelect>
                <Form.DeprecatedSelect
                  label=""
                  ariaLabel={intl.formatMessage({
                    defaultMessage: 'Select a period',
                  })}
                  name="goalSchedulePeriod"
                  size={3}
                >
                  <option value={TimePeriod.AnteMeridiem}>
                    {intl.formatMessage({ defaultMessage: 'AM' })}
                  </option>
                  <option value={TimePeriod.PostMeridiem}>
                    {intl.formatMessage({ defaultMessage: 'PM' })}
                  </option>
                </Form.DeprecatedSelect>
              </div>
            </div>
          </>
        )}
        <Form.Toggle
          size={12}
          name="declinedToSetGoal"
          onChange={(val) => {
            if (val) {
              // Re-trigger validations since this should disable all of them
              form.trigger();
            }
          }}
          label={
            <FormattedMessage defaultMessage="Patient declined to set a goal" />
          }
        />
      </FieldGroup>
    </Wizard.Step>
  );
}

function useTimeOptions() {
  const intl = useIntl();

  return [
    { value: '12:00', label: intl.formatMessage({ defaultMessage: '12:00' }) },
    { value: '11:00', label: intl.formatMessage({ defaultMessage: '11:00' }) },
    { value: '10:00', label: intl.formatMessage({ defaultMessage: '10:00' }) },
    { value: '09:00', label: intl.formatMessage({ defaultMessage: '09:00' }) },
    { value: '08:00', label: intl.formatMessage({ defaultMessage: '08:00' }) },
    { value: '07:00', label: intl.formatMessage({ defaultMessage: '07:00' }) },
    { value: '06:00', label: intl.formatMessage({ defaultMessage: '06:00' }) },
    { value: '05:00', label: intl.formatMessage({ defaultMessage: '05:00' }) },
    { value: '04:00', label: intl.formatMessage({ defaultMessage: '04:00' }) },
    { value: '03:00', label: intl.formatMessage({ defaultMessage: '03:00' }) },
    { value: '02:00', label: intl.formatMessage({ defaultMessage: '02:00' }) },
    { value: '01:00', label: intl.formatMessage({ defaultMessage: '01:00' }) },
  ];
}

function useSectionVisibility(
  form: ConfiguredForm<DeviceUsageGoalsFormFields>,
) {
  const [devicesAccessible, deviceLocation, hasSchedule] = form.watch([
    'devicesAccessible',
    'deviceLocation',
    'hasSchedule',
  ]);

  return {
    deviceLocation: devicesAccessible === 'false',
    hasSchedule: !!deviceLocation,
    goalSchedule: hasSchedule === 'false',
  };
}
