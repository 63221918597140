import type { VitalsAlert } from 'shared/types/alert.types';
import type { AllTagType } from 'shared/types/tagsAndThreshold.types';

export enum AlertType {
  Custom = 'Custom',
  Multiple = 'Multiple',
  Trend = 'Trend',
  Spot = 'Spot',
}

export function getAlertType(alert: VitalsAlert): AlertType;
export function getAlertType(alerts: VitalsAlert[]): AlertType;
export function getAlertType(alertOrAlerts: VitalsAlert | VitalsAlert[]) {
  const alerts = Array.isArray(alertOrAlerts) ? alertOrAlerts : [alertOrAlerts];
  if (containsCustomThreshold(alerts)) {
    return AlertType.Custom;
  }
  if (containsMultipleAlertTags(alerts)) {
    return AlertType.Multiple;
  }
  if (containsTrendAlert(alerts)) {
    return AlertType.Trend;
  }
  return AlertType.Spot;
}

const containsCustomThreshold = (alerts: VitalsAlert[]) =>
  alerts.some((alert) => alert.has_custom_threshold);

const containsMultipleAlertTags = (alerts: VitalsAlert[]) =>
  alerts.some((alert) => alert.alert_tags.length > 1);

const containsTrendAlert = (alerts: VitalsAlert[]) =>
  alerts.some((alertList) => containsTrendAlertTag(alertList.alert_tags));

const containsTrendAlertTag = (alertTags: AllTagType[]) =>
  alertTags.some(
    (alertTag) =>
      alertTag.includes('WEEKLY') ||
      alertTag.includes('MONTHLY') ||
      alertTag.includes('RAPID'),
  );
