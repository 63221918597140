import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import type { MedicationChange } from 'shared/generated/grpcGateway/medication.pb';
import {
  MedicationChangeChangeType,
  MedicationChangeStatus,
} from 'shared/generated/grpcGateway/medication.pb';

import { medRowSubtext } from './MedicationRow.css';

export function MedicationSubtext({
  medChange,
  status,
}: {
  medChange: MedicationChange;
  status?: MedicationChangeStatus;
}) {
  const intl = useIntl();
  const { reason, changeType } = medChange;

  const subtext = getSubtext(intl, reason, changeType, status);

  if (!subtext) {
    return null;
  }

  return <div className={medRowSubtext}>{subtext}</div>;
}

function getSubtext(
  intl: IntlShape,
  reason?: string,
  changeType?: MedicationChangeChangeType,
  status?: MedicationChangeStatus,
) {
  switch (changeType) {
    case MedicationChangeChangeType.REVIEW:
      return reason;
      break;

    case MedicationChangeChangeType.STOP:
      return reason
        ? intl.formatMessage(
            { defaultMessage: 'Stopped- {reason}' },
            { reason },
          )
        : intl.formatMessage({ defaultMessage: 'Stopped' });

    case MedicationChangeChangeType.START:
      if (status === MedicationChangeStatus.INACTIVE) {
        return null;
      }
      return reason
        ? intl.formatMessage(
            { defaultMessage: 'Started- {reason}' },
            { reason },
          )
        : intl.formatMessage({ defaultMessage: 'Started' });

    case MedicationChangeChangeType.TITRATION:
      if (status === MedicationChangeStatus.INACTIVE) {
        return null;
      }
      return reason
        ? intl.formatMessage(
            { defaultMessage: 'Titrated- {reason}' },
            { reason },
          )
        : intl.formatMessage({ defaultMessage: 'Titrated' });
    default:
      return null;
  }

  return null;
}
