import { format, parseISO } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  FieldGroup,
  Script,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { formatProviderForScript } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/script.utils';
import {
  verificationValue,
  verificationsList,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/shared.css';
import { usePatientProgram } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/usePatientProgram';
import { LoadingPlaceholder } from 'shared/common/LoadingPlaceholder';
import { Wizard } from 'shared/common/Wizard';
import { usePatientContext, usePatientDetails } from 'shared/hooks/queries';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { getRpmConditionsFromProgramAndStatus } from 'shared/patient/conditions.utils';
import { displayHomeState } from 'shared/patient/patientInfoUtils';

import { useCNFormContext } from '../../../CNFormContext';
import type { CNSectionMap } from '../../metadata';

type Props = {
  sections: CNSectionMap;
  nextPath: string;
};

export function SharedIntroConfirmData({ sections, nextPath }: Props) {
  const intl = useIntl();
  const history = useHistory();
  const { currentUserFullName } = useCurrentUser();
  const { patientId } = useCNFormContext();
  const { data: context, isLoading: isLoadingContext } =
    usePatientContext(patientId);
  const { data: patient, isLoading: isLoadingPatient } = usePatientDetails(
    patientId,
    false,
    Boolean(patientId),
  );
  const { program, isLoading: isLoadingProgram } = usePatientProgram(patientId);
  const rpmPrograms = getRpmConditionsFromProgramAndStatus(
    patient?.programs,
    patient?.status,
  );

  if (
    !patient ||
    !context ||
    !program ||
    isLoadingPatient ||
    isLoadingContext ||
    isLoadingProgram
  ) {
    return <LoadingPlaceholder isLoading />;
  }

  const patientName = `${patient?.first_name} ${patient?.last_name}`;

  return (
    <Wizard.Step
      sections={sections}
      title={intl.formatMessage({ defaultMessage: 'Introduction' })}
      onNext={() => history.push(nextPath)}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage
            defaultMessage="Hi, is this {patientName}? This is {username} calling from Cadence and {provider} on a recorded line."
            values={{
              username: currentUserFullName,
              provider: formatProviderForScript(
                context.primaryPhysicianGivenName,
                context.primaryPhysicianFamilyName,
                context.primaryPhysicianRole,
              ),
              patientName,
            }}
          />
        </Script>
        <ol className={verificationsList}>
          <li>
            <FormattedMessage
              defaultMessage="Confirm patient date of birth: <verification>{dob}</verification>"
              values={{
                dob: format(parseISO(patient.dob), 'MMMM d, yyyy'),
                verification,
              }}
            />
          </li>
          <li>
            <FormattedMessage
              defaultMessage="Confirm patient is in their home state: <verification>{homeState}</verification>"
              values={{
                homeState: displayHomeState(patient),
                verification,
              }}
            />
          </li>
        </ol>
        <Script>
          <FormattedMessage defaultMessage="Is now still a good time to talk? We need about 20 minutes together." />
        </Script>
        <Script>
          <FormattedMessage
            defaultMessage="I am excited to talk today. As a reminder, I am your Cadence Clinical Navigator and my job is to work with you on your {conditions} control. The purpose of today's call is to get you to the clinical goals set by {provider}, and work on lifestyle and diet improvements to get you healthier."
            values={{
              conditions: rpmPrograms
                .sort()
                .join(` ${intl.formatMessage({ defaultMessage: 'and' })} `),
              provider: formatProviderForScript(
                context.primaryPhysicianGivenName,
                context.primaryPhysicianFamilyName,
                context.primaryPhysicianRole,
              ),
            }}
          />
        </Script>
        <Script>
          <FormattedMessage defaultMessage="Do you have any questions about the phone appointments you will have with Cadence?" />
        </Script>
      </FieldGroup>
    </Wizard.Step>
  );
}

function verification(children: string) {
  return <span className={verificationValue}>{children}</span>;
}
