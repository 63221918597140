import useResizeObserver from '@react-hook/resize-observer';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import type { RefObject } from 'react';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import ChevronLeft from 'shared/assets/svgs/chevronLeft.svg?react';
import ChevronRight from 'shared/assets/svgs/chevronRight.svg?react';
import type { TaskType } from 'shared/generated/grpcGateway/task.pb';
import { useOnMount } from 'shared/hooks/useOnMount';
import { display, visibility } from 'shared/jsStyle/utils.css';
import { Choice } from 'shared/tasking/TaskCard/Choice';
import { cardChoices } from 'shared/tasking/TaskCard/Choice.css';
import { IconButton } from 'shared/tempo/atom/IconButton';

import { controls, filterContainer, scrollContainer } from './FilterCards.css';

enum ScrollDirection {
  Left = -1,
  Right = 1,
}

type Props = {
  types: TaskType[];
  selectedType: TaskType[];
  onChangeSelected: (taskTypes: TaskType[]) => void;
  typeCounts: Record<string, number>;
};

export function FilterCards({
  types,
  selectedType,
  onChangeSelected,
  typeCounts,
}: Props) {
  const intl = useIntl();
  const [visibleWidth, setVisibleWidth] = useState<number>(0);
  const [totalWidth, setTotalWidth] = useState<number>(0);
  const [controlsVisible, setControlsVisible] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const innerScrollRef = useRef<HTMLDivElement>(null);
  const filterCardRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const { onScroll, scrollable } = useIsScrollable(scrollRef);
  const needsControls = totalWidth > visibleWidth;

  const scroll = useCallback(
    (direction: ScrollDirection) => {
      if (!scrollRef.current) {
        return;
      }

      const scrollAmount = direction * visibleWidth;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    },
    [scrollRef, visibleWidth],
  );

  useResizeObserver(scrollRef, (entry) => {
    setVisibleWidth(entry.target.clientWidth);
  });

  useResizeObserver(innerScrollRef, (entry) => {
    setTotalWidth(entry.target.scrollWidth);
  });

  const onWheel = useCallback(
    (e) => {
      // no need to capture the scroll event if there's
      // not any scrolling to be done
      if (!needsControls || !scrollRef.current) {
        return;
      }

      e.stopPropagation();
      e.preventDefault();

      // we can't differentiate between a mousewheel and a track pad, so
      // we need to look at both the X and Y scroll distance to determine
      // which one we actually want to "apply" to the horizontal scroll.
      // most trackpad users will naturally scroll horizontally with this UI
      // so we can pass that delta along untouched. a mouse wheel, however,
      // will typically only scroll vertically, but we can assume that the
      // user actually wants a horizontal scroll.
      const scrollDistance = e.deltaX === 0 ? e.deltaY : e.deltaX;
      scrollRef.current.scrollBy({ left: scrollDistance });
    },
    [needsControls],
  );

  useLayoutEffect(() => {
    filterCardRefs.current[selectedType[0]?.name as string]?.scrollIntoView();
  }, [selectedType]);

  useEffect(() => {
    const scroller = scrollRef.current;
    scroller?.addEventListener('wheel', onWheel, { passive: false });

    return () => {
      scroller?.removeEventListener('wheel', onWheel);
    };
  }, [scrollRef, onWheel]);

  return (
    <div
      className={filterContainer}
      onMouseEnter={() => {
        if (needsControls) {
          setControlsVisible(true);
        }
      }}
      onMouseLeave={() => setControlsVisible(false)}
    >
      <div className={scrollContainer} ref={scrollRef} onScroll={onScroll}>
        <div className={cardChoices} ref={innerScrollRef}>
          <Choice
            key="all"
            title={intl.formatMessage({ defaultMessage: 'All' })}
            isSelected={selectedType.length === 0}
            onPress={() => onChangeSelected([])}
            size="small"
          />
          {types
            .filter(
              (type) =>
                typeCounts[type.name as string] ||
                type.name === selectedType[0]?.name,
            )
            .map((type) => (
              <Choice
                ref={(el) => {
                  if (el) {
                    filterCardRefs.current[type.name as string] = el;
                  }
                }}
                key={type.name}
                title={type.typeName ?? ''}
                isSelected={
                  selectedType.length === 1 &&
                  type.name === selectedType[0]?.name
                }
                onPress={() => onChangeSelected([type])}
                size="small"
                count={typeCounts[type.name as string] ?? 0}
              />
            ))}
        </div>
        <div
          className={cx(controls, {
            [display.none]: !needsControls,
            [display.flex]: needsControls,
            [visibility.hidden]: !controlsVisible,
          })}
        >
          <IconButton
            size="small"
            variant="secondary"
            isDisabled={!scrollable.left}
            onPress={() => scroll(ScrollDirection.Left)}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            size="small"
            variant="secondary"
            isDisabled={!scrollable.right}
            onPress={() => scroll(ScrollDirection.Right)}
          >
            <ChevronRight />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

function useIsScrollable(scrollRef: RefObject<HTMLDivElement>) {
  const [scrollable, setScrollable] = useState<{
    left: boolean;
    right: boolean;
  }>({ left: false, right: true });
  const handleScroll = useCallback(() => {
    const el = scrollRef.current;

    if (!el) {
      return;
    }

    setScrollable({
      left: el.scrollLeft > 0,
      right: el.scrollLeft + el.clientWidth < el.scrollWidth,
    });
  }, [scrollRef]);

  // 50ms is arbitrary, feel free to adjust. just trying to maintain some
  // performance while scrolling, so i'm sure it can be tweaked.
  const debouncedOnScroll = useMemo(
    () => debounce(handleScroll, 50),
    [handleScroll],
  );

  useOnMount(() => () => debouncedOnScroll.cancel());

  return { onScroll: debouncedOnScroll, scrollable };
}
