import type { IntlShape } from 'react-intl';
import type { TestContext } from 'yup';

import { validators } from 'shared/common/Form/validations';

import { EducationModuleSection } from './types';

export type EducationModulesReviewFormFields = {
  reviewedModules: EducationModuleSection[];
  previousReviewedModules?: EducationModuleSection[];
};

export function getEducationModulesReviewFormConfig(
  intl: IntlShape,
  values?: EducationModulesReviewFormFields,
) {
  const { enumType, array } = validators(intl);
  const prevReviewedModules = values?.reviewedModules || [];

  return {
    fields: {
      reviewedModules: {
        defaultValue: values?.reviewedModules || [],
        validation: array({
          ofType: enumType({ source: EducationModuleSection, pluck: 'values' }),
        }).test(
          'new-reviewed-module',
          intl.formatMessage({
            defaultMessage: 'Must review at least one new module',
          }),
          (reviewedMods, context: TestContext) => {
            const { previousReviewedModules } =
              context.parent as EducationModulesReviewFormFields;
            return !!reviewedMods?.some(
              (el) => !previousReviewedModules?.includes(el),
            );
          },
        ),
      },
      // Hidden field, use to keep track of prev. reviewed modules
      previousReviewedModules: {
        defaultValue: prevReviewedModules,
        validation: array({
          ofType: enumType({ source: EducationModuleSection, pluck: 'values' }),
        }),
      },
    },
  };
}
