import cx from 'classnames';
import { addDays, differenceInDays, format, subDays } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { ChevronLeft, ChevronRight } from 'shared/assets/svgs';
import { IconButton } from 'shared/tempo/atom/IconButton';

import { dateSelector } from './styles.css';
import { useDateValidators } from './useDateValidators';

type Props = {
  value: Date;
  onChange: (date: Date) => void;
};

export function DateSelector({ value, onChange }: Props) {
  const { today, canAddDay, canSubDay } = useDateValidators();
  const daysFromNow = differenceInDays(value, today);

  return (
    <div className={dateSelector.container}>
      <div className={dateSelector.controls}>
        <IconButton
          size="small"
          variant="tertiary"
          className={dateSelector.chevronButton}
          onPress={() => onChange(subDays(value, 1))}
          isDisabled={!canSubDay(value)}
        >
          <ChevronLeft
            className={cx(dateSelector.chevronIcon.default, {
              [dateSelector.chevronIcon.disabled]: !canSubDay,
            })}
          />
        </IconButton>
        <div className={dateSelector.date}>{format(value, 'eeee, MMM d')}</div>
        <IconButton
          size="small"
          variant="tertiary"
          className={dateSelector.chevronButton}
          onPress={() => onChange(addDays(value, 1))}
          isDisabled={!canAddDay(value)}
        >
          <ChevronRight
            className={cx(dateSelector.chevronIcon.default, {
              [dateSelector.chevronIcon.disabled]: !canAddDay,
            })}
          />
        </IconButton>
      </div>
      <div className={dateSelector.timeframe}>
        {daysFromNow === 0 ? (
          <FormattedMessage defaultMessage="Today" />
        ) : (
          <FormattedMessage
            defaultMessage="In {days} {days, plural, =0 {days} one {day} other {days}}"
            values={{ days: daysFromNow }}
          />
        )}
      </div>
    </div>
  );
}
