import type { AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { CANCEL_PENDING_REQUEST } from 'shared/actionCreators';
import Session from 'shared/utils/session';

import { UPDATE_NPI, UPDATE_NPI_FAILURE, npiUpdated } from '../actionCreators';

const updateNpiEpic = (action$: any) =>
  action$.pipe(
    ofType(UPDATE_NPI),
    switchMap((action: any) =>
      from(
        Session.Api.put(`/pms/api/v1/npis/${action.npiId}`, {
          npi: action.npi,
        }),
      ).pipe(takeUntil(action$.pipe(ofType(CANCEL_PENDING_REQUEST)))),
    ),
    map((response: AxiosResponse<TNpiCreatedUpdatedPayload>) =>
      npiUpdated(response.data),
    ),
    catchError((error, caught) =>
      merge(
        of({
          type: UPDATE_NPI_FAILURE,
          payload: error,
          error: true,
        }),
        caught,
      ),
    ),
  );

export default updateNpiEpic;
