import classnames from 'classnames/bind';
import { format, parseISO } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { CircularProgress, Link, Typography } from 'deprecated/mui';
import type { PatientReminderNoteData } from 'shared/types/patient.types';

import styles from './styles.module.scss';

const cx = classnames.bind(styles);

type Props = {
  note: PatientReminderNoteData;
  isUpdatingReminderNote: boolean;
  editReminderNote: () => void;
  deleteReminderNote: () => void;
};

export const ReminderNote = ({
  note,
  isUpdatingReminderNote,
  editReminderNote,
  deleteReminderNote,
}: Props) => (
  <>
    <Typography className={cx('reminder-note-text-in-bold')}>
      {note.text}
    </Typography>
    <Typography className={cx('reminder-note-metadata')}>
      {note.author}{' '}
      {note.updated_at ? format(parseISO(note.updated_at), 'MM/dd/yyyy') : null}
    </Typography>
    <div className={cx('reminder-note-actions')}>
      <div className={cx('loading-button')}>
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <Link
          component="button"
          variant="body2"
          className={cx('delete-button')}
          onClick={deleteReminderNote}
        >
          <FormattedMessage defaultMessage="Delete" />
        </Link>
        {isUpdatingReminderNote && <CircularProgress size={12} />}
      </div>
      <Link
        component="button"
        variant="body2"
        disabled={isUpdatingReminderNote}
        className={cx('link-button')}
        onClick={editReminderNote}
      >
        <FormattedMessage defaultMessage="Edit" />
      </Link>
    </div>
  </>
);
