import { conditionToAbbreviation } from 'pages/adminPanel/patient-profile/tabs/ClinicalProfileTab/clinicalprofile.utils';
import type { Condition } from 'shared/types/clinicalprofile.types';

export const formatList = (items: string[], maxDisplayable: number) => {
  if (items.length <= maxDisplayable) {
    return items.join(', ');
  }

  return `${items.slice(0, maxDisplayable).join(', ')} ...`;
};

export const formatConditions = (conditions?: Condition[]): string => {
  const length = conditions?.length;
  if (!length) {
    return '-';
  }
  return conditions.map(conditionToAbbreviation).join(' + ');
};
