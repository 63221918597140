import cx from 'classnames';
import { format, parseISO } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';

import { CommonInfoField, PLACEHOLDER } from 'shared/common/CommonInfoField';
import type { Hospital } from 'shared/generated/grpcGateway/hospital.pb';
import type { PatientDetails } from 'shared/generated/grpcGateway/pms.pb';
import { getRpmConditionsFromProgramAndStatus } from 'shared/patient/conditions.utils';
import {
  displayAge,
  displayGender,
  displayGenderV2,
  displayGoogleDateAge,
  displayGoogleDateDOB,
} from 'shared/patient/patientInfoUtils';
import type { Condition } from 'shared/types/clinicalprofile.types';
import type { Patient } from 'shared/types/patient.types';
import { Gender } from 'shared/types/patient.types';

import { useNoteEditorContext } from '../patientDetails/ui/Notes/NoteEditorContext';
import {
  hospitalNameLabel,
  patientInfoFieldsContainer,
  patientInfoFieldsContainerV2,
  patientInfoSection,
} from './BasicPatientInfoFields.css';
import { ClinicalPatientInfoFieldsWrapper } from './ClinicalPatientInfoFields';
import { PatientMedsInfoTag } from './PatientMedsInfoTag';
import { formatConditions, formatList } from './formatting.utils';
import {
  useNumberOfPatientInfoColumns,
  useSetNumberOfPatientInfoColumnsFromRef,
} from './numberOfPatientInfoColumns';

type PatientInfoFields = {
  patientDetails: PatientDetails;
  patientId: string;
};

export const BasicPatientInfoFieldsV2 = ({
  patientDetails,
  patientId,
}: PatientInfoFields) => {
  const { patient, allEhrInformation } = patientDetails;

  const ehrInformation = allEhrInformation?.sort(
    (ehrInfo1, ehrInfo2) =>
      (ehrInfo1.primaryProfile ? -1 : 1) - (ehrInfo2.primaryProfile ? -1 : 1),
  )?.[0];

  const { isEditorOpen } = useNoteEditorContext();
  const divElement = useSetNumberOfPatientInfoColumnsFromRef();
  const numberOfPatientInfoColumns = useNumberOfPatientInfoColumns();
  return (
    <div
      ref={divElement}
      className={cx({
        [patientInfoFieldsContainerV2.editorOpen]: isEditorOpen,
        [patientInfoFieldsContainerV2.fourCols]:
          !isEditorOpen && numberOfPatientInfoColumns === 4,
        [patientInfoFieldsContainerV2.lessThan4cols]:
          !isEditorOpen && numberOfPatientInfoColumns < 4,
      })}
    >
      <CommonInfoField
        useSubtleLabelStyle
        label={<FormattedMessage defaultMessage="Age" />}
        text={patient?.dob ? displayGoogleDateAge(patient.dob) : PLACEHOLDER}
      />
      <CommonInfoField
        useSubtleLabelStyle
        label={<FormattedMessage defaultMessage="DoB" />}
        text={patient?.dob ? displayGoogleDateDOB(patient.dob) : PLACEHOLDER}
      />
      <CommonInfoField
        useSubtleLabelStyle
        label={<FormattedMessage defaultMessage="Gender" />}
        text={patient?.gender ? displayGenderV2(patient.gender) : PLACEHOLDER}
      />
      {ehrInformation?.mrn && (
        <MrnPatientInfoField
          useSubtleLabelStyle
          mrns={allEhrInformation
            ?.sort(
              (a, b) => (b.primaryProfile ? 1 : 0) - (a.primaryProfile ? 1 : 0),
            )
            .filter((ehrInfo) => ehrInfo.mrn)
            .map((ehrInfo) => ehrInfo.mrn as string)}
        />
      )}
      <ClinicalPatientInfoFieldsWrapper patientId={patientId} />
      <LocationPatientInfoFieldV2 hospital={ehrInformation?.hospital} />
    </div>
  );
};

export const BasicPatientInfoFieldsV1 = ({
  patientDetails,
  hospitals,
  showMedManagement,
}: {
  patientDetails: Patient;
  showMedManagement?: boolean;
  hospitals?: Hospital[];
}) => {
  const { dob, gender, mrns, programs, status } = patientDetails;

  const conditions = getRpmConditionsFromProgramAndStatus(programs, status);

  return showMedManagement ? (
    <div className={patientInfoSection}>
      <div className={patientInfoFieldsContainer}>
        <CommonInfoField
          label={<FormattedMessage defaultMessage="Age" />}
          text={dob ? displayAge(dob) : PLACEHOLDER}
        />
        <CommonInfoField
          label={<FormattedMessage defaultMessage="Gender" />}
          text={displayGender(gender) ?? PLACEHOLDER}
        />
        <ConditionPatientInfoField conditions={conditions} />
        <LocationPatientInfoFieldV1 hospitals={hospitals} />
        <MrnPatientInfoField mrns={mrns} />
        <CommonInfoField
          label={<FormattedMessage defaultMessage="DoB" />}
          text={format(parseISO(dob), 'M/d/yy') || PLACEHOLDER}
        />
      </div>
      <PatientMedsInfoTag patient={patientDetails} />
    </div>
  ) : (
    <div className={patientInfoFieldsContainer}>
      <GenderAgeDobCompoundPatientInfoField patientDetails={patientDetails} />
      <MrnPatientInfoField mrns={mrns} />
      <LocationPatientInfoFieldV1 hospitals={hospitals} />
      <ConditionPatientInfoField conditions={conditions} />
    </div>
  );
};

function GenderAgeDobCompoundPatientInfoField({
  patientDetails: { gender, dob },
}: {
  patientDetails: Patient;
}) {
  const intl = useIntl();
  const genderText =
    {
      [Gender.Male]: intl.formatMessage({ defaultMessage: 'Male' }),
      [Gender.Female]: intl.formatMessage({ defaultMessage: 'Female' }),
      [Gender.Other]: intl.formatMessage({ defaultMessage: 'Other' }),
      [Gender.X]: intl.formatMessage({ defaultMessage: 'X' }),
    }[gender as Gender] ?? PLACEHOLDER;
  const displayDob = format(parseISO(dob), 'M/d/yyyy') || PLACEHOLDER;
  return (
    <CommonInfoField
      text={`${genderText}, ${displayAge(dob)} y.o., ${displayDob}`}
    />
  );
}

function ConditionPatientInfoField({
  conditions,
}: {
  conditions?: Condition[];
}) {
  return (
    <CommonInfoField
      label={<FormattedMessage defaultMessage="Condition" />}
      text={formatConditions(conditions)}
    />
  );
}

const MAX_DISPLAYABLE_MRNS = 3;
const MrnPatientInfoField = ({
  mrns,
  useSubtleLabelStyle,
}: {
  mrns?: string[];
  useSubtleLabelStyle?: boolean;
}) => (
  <CommonInfoField
    useSubtleLabelStyle={useSubtleLabelStyle}
    label={
      <FormattedMessage
        defaultMessage="{count, plural, =0 {MRN} one {MRN} other {MRNs}}"
        values={{ count: mrns?.length }}
      />
    }
    text={mrns?.length ? formatList(mrns, MAX_DISPLAYABLE_MRNS) : PLACEHOLDER}
  />
);

const MAX_DISPLAYABLE_HOSPITALS = 3;
const LocationPatientInfoFieldV1 = ({
  hospitals,
  useSubtleLabelStyle,
}: {
  hospitals?: Hospital[];
  useSubtleLabelStyle?: boolean;
}) => {
  const hospitalNames = (hospitals || []).map(
    (hospital) => hospital.name || '',
  );

  return (
    <CommonInfoField
      useSubtleLabelStyle={useSubtleLabelStyle}
      label={<FormattedMessage defaultMessage="Loc" />}
      text={
        hospitalNames?.length
          ? formatList(hospitalNames, MAX_DISPLAYABLE_HOSPITALS)
          : PLACEHOLDER
      }
      toolTipMsg={
        hospitalNames?.length > MAX_DISPLAYABLE_HOSPITALS
          ? hospitalNames?.join(', ')
          : undefined
      }
    />
  );
};

const MAX_DISPLAYABLE_HOSPITAL_NAME_CHAR = 46;
const shortenedHospitalNames = (hospitalName: string) => {
  if (hospitalName.length > MAX_DISPLAYABLE_HOSPITAL_NAME_CHAR) {
    return `${hospitalName.slice(0, MAX_DISPLAYABLE_HOSPITAL_NAME_CHAR)}...`;
  }
  return hospitalName;
};

const LocationPatientInfoFieldV2 = ({ hospital }: { hospital?: Hospital }) => {
  const locationLabel = <FormattedMessage defaultMessage="Loc" />;

  const hospitalNameString = hospital?.displayName || '';

  return (
    <CommonInfoField
      useSubtleLabelStyle
      className={hospitalNameLabel}
      label={locationLabel}
      text={shortenedHospitalNames(hospitalNameString)}
      toolTipMsg={hospitalNameString}
    />
  );
};
