import type { TypeaheadSelectProps } from 'shared/common/TypeaheadSelect';
import { TypeaheadSelect as BaseTypeaheadSelect } from 'shared/common/TypeaheadSelect';

import type { BaseFieldProps } from '../BaseField';
import { BaseField } from '../BaseField';

type Props<T, D extends string = 'data'> = {
  disabled?: boolean;
} & BaseFieldProps &
  Omit<TypeaheadSelectProps<T, D>, 'onChange' | 'value'>;

export function TypeaheadSelect<T, D extends string = 'data'>({
  disabled,
  setSearchTerm,
  query,
  queryDataField,
  placeholder,
  getOptionLabel,
  getOptionValue,
  OptionComponent,
  ResultContainer,
  PopperComponent,
  ...baseProps
}: Props<T, D>) {
  return (
    <BaseField {...baseProps}>
      {({ controller: { field, fieldState } }) => (
        <BaseTypeaheadSelect
          onChange={(value) => {
            field.onChange(value ?? '');
          }}
          value={field.value}
          setSearchTerm={setSearchTerm}
          query={query}
          queryDataField={queryDataField}
          placeholder={placeholder}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          OptionComponent={OptionComponent}
          ResultContainer={ResultContainer}
          disabled={disabled}
          hasError={Boolean(fieldState.error)}
          PopperComponent={PopperComponent}
        />
      )}
    </BaseField>
  );
}
