import { MedPermissions } from 'pages/patients/PatientMedications/PatientMedicationsList';
import { TypeOfEncounter } from 'pages/patients/patientDetails/ui/Notes/Notes.types';
import { useFlags } from 'shared/hooks';

const ALLOWED_ENCOUNTER_TYPES = [
  TypeOfEncounter.INITIAL_NP_VISIT,
  TypeOfEncounter.NP_VISIT,
  TypeOfEncounter.RESULTS_FOLLOW_UP,
  TypeOfEncounter.PATIENT_INBOUND,
];

export function useShouldDisplayReadOnlySuggestedTitration(
  permissions?: MedPermissions,
  typeOfEncounter?: TypeOfEncounter,
) {
  const { asyncTitrations, suggestedTitration } = useFlags();

  if (permissions !== MedPermissions.Titrate) {
    return false;
  }

  if (!typeOfEncounter || !ALLOWED_ENCOUNTER_TYPES.includes(typeOfEncounter)) {
    return false;
  }

  return !asyncTitrations && suggestedTitration;
}
