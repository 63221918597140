import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  MedPermissions,
  PatientMedicationsList,
} from 'pages/patients/PatientMedications/PatientMedicationsList';
import {
  MedCategory,
  useHasRequiredMedActions,
} from 'pages/patients/PatientMedications/utils/requiredActions';
import {
  FieldGroup,
  Script,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { useTimeEstimate } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { ChevronRight } from 'shared/assets/svgs';
import { Form } from 'shared/common/Form';
import type { ConfiguredForm } from 'shared/common/Form/FormContainer';
import { Wizard } from 'shared/common/Wizard';
import { Button } from 'shared/tempo/atom/Button';

import { useCNFormContext } from '../../../CNFormContext';
import type { CNSectionMap } from '../../metadata';
import {
  medReviewStepInfo,
  patientDeclinedToggle,
} from './SharedMedReview.css';
import type { MedReviewFormFields } from './formConfig';

type Props = {
  form: ConfiguredForm<MedReviewFormFields>;
  sections: CNSectionMap;
  nextPath: string;
};

export function SharedMedReview({ form, sections, nextPath }: Props) {
  const intl = useIntl();
  const history = useHistory();
  const [attemptedNextNavigation, setAttemptedNextNavigation] = useState(false);
  const { patientId, noteId } = useCNFormContext();
  const patientDeclineMedReview = form.watch('patientDeclineMedReview');
  const hasRequiredMedActions = useHasRequiredMedActions(
    patientId,
    !patientDeclineMedReview,
    MedCategory.DiseaseSpecific,
  );

  const blockNextNavigation = !patientDeclineMedReview && hasRequiredMedActions;
  const displayValidations = blockNextNavigation && attemptedNextNavigation;

  return (
    <Wizard.Step
      form={form}
      sections={sections}
      title={intl.formatMessage({ defaultMessage: 'Medication Review' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => {
        history.push(nextPath);
      }}
      submitButton={({ form: wizardForm, onNext, submitWithTiming }) => (
        <Button
          size="small"
          type="submit"
          onPress={() => {
            if (blockNextNavigation) {
              // Will display validation errors
              setAttemptedNextNavigation(true);
            } else {
              setAttemptedNextNavigation(false);
              const storeValues = submitWithTiming(true);
              if (wizardForm) {
                onNext(storeValues as MedReviewFormFields);
              } else {
                onNext();
              }
            }
          }}
        >
          <FormattedMessage defaultMessage="Next" />
          <Button.Icon>
            <ChevronRight />
          </Button.Icon>
        </Button>
      )}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage defaultMessage="Another important item for us to discuss is the medications that you take on a regular basis. I have a list that was provided by your doctor. Were you reminded to bring your medication list with you for our call today? No problem if not!" />
        </Script>
        <div className={medReviewStepInfo}>
          <FormattedMessage defaultMessage="Read the name, dosage, and frequency of each medication and verify if the patient is taking the medication." />
        </div>
        <PatientMedicationsList
          showRequiredActions={displayValidations}
          noteId={noteId}
          patientId={patientId}
          medPermissions={MedPermissions.Review}
          hideOtherMeds
        />
        <Form.Toggle
          className={patientDeclinedToggle}
          name="patientDeclineMedReview"
          label={intl.formatMessage({
            defaultMessage: 'Patient declined to answer',
          })}
        />
      </FieldGroup>
    </Wizard.Step>
  );
}
