import type { SvgIconProps } from 'deprecated/mui';
import { SvgIcon } from 'deprecated/mui';

const StrikethroughIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props} viewBox="0 0 24 24">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7443 8.18182H16.2784C16.2081 6.20668 14.3864 4.73011 11.8551 4.73011C9.34943 4.73011 7.38068 6.1875 7.38068 8.38636C7.38068 10.1506 8.65909 11.1989 10.7045 11.7869L12.3153 12.2472C13.696 12.6307 14.9233 13.1165 14.9233 14.4205C14.9233 15.8523 13.5426 16.7983 11.7273 16.7983C10.1676 16.7983 8.78693 16.108 8.65909 14.625H7.02273C7.17614 16.7727 8.91477 18.2301 11.7273 18.2301C14.7443 18.2301 16.4574 16.5682 16.4574 14.446C16.4574 11.9915 14.1307 11.1989 12.7756 10.8409L11.446 10.483C10.4744 10.2273 8.91477 9.71591 8.91477 8.30966C8.91477 7.05682 10.0653 6.13636 11.804 6.13636C13.3892 6.13636 14.5909 6.89062 14.7443 8.18182Z"
        fill="#3B3A45"
      />
      <path d="M6 12.1065H17.4801V13.3338H6V12.1065Z" fill="#3B3A45" />
    </svg>
  </SvgIcon>
);

export default StrikethroughIcon;
