import { useObjectRef } from '@react-aria/utils';
import cx from 'classnames';
import type { ReactText } from 'react';
import { forwardRef } from 'react';
import type { AriaLinkOptions } from 'react-aria';
import { FocusRing, useLink } from 'react-aria';
import type { LinkProps as ReactRouterLinkProps } from 'react-router-dom';
import { Link as ReactRouterLink } from 'react-router-dom';

import { focusRingCss } from 'shared/tempo/util/FocusRing/FocusRing.css';

import { link } from './styles.css';

type Props = {
  className?: string;
  children: ReactText;
} & ReactRouterLinkProps &
  AriaLinkOptions;

export const RoutedLink = forwardRef<HTMLAnchorElement, Props>(
  ({ className, children, ...props }, forwardedRef) => {
    const ref = useObjectRef(forwardedRef);
    const { linkProps, isPressed } = useLink(props, ref);

    return (
      <FocusRing focusRingClass={cx(focusRingCss.keyboard)}>
        <ReactRouterLink
          {...props}
          {...linkProps}
          innerRef={ref}
          className={cx(
            { [link.default]: !isPressed, [link.active]: isPressed },
            className,
          )}
        >
          {children}
        </ReactRouterLink>
      </FocusRing>
    );
  },
);
