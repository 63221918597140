import type { AxiosError } from 'axios';
import { format, isBefore, isSameDay, parseISO, startOfDay } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import shuffle from 'lodash/shuffle';
import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { logger } from 'logger';
import { useCheckPatientAppointmentAvailability } from 'pages/patients/PatientProfile/PatientScheduling/appointments.queries';
import ChevronLeft from 'shared/assets/svgs/chevronLeft.svg?react';
import InfoIcon from 'shared/assets/svgs/info-circle.svg?react';
import type {
  AppointmentAvailability,
  NextAppointmentRecommendation,
} from 'shared/generated/grpcGateway/scheduling.pb';
import { useOnMount } from 'shared/hooks/useOnMount';
import { Button } from 'shared/tempo/atom/Button';
import { Skeleton } from 'shared/tempo/atom/Skeleton';
import { useToaster } from 'shared/tempo/molecule/Toast';
import { color } from 'shared/tempo/theme';
import type { Patient } from 'shared/types/patient.types';

import { EmbeddedAcuityView } from '../EmbeddedAcuityView';
import { shouldBeScheduledAsap } from '../appointment.utils';
import { type AcuityIframeUrlParams, ApptsFilter } from '../types';
import { AvailabilitySelector } from './AvailabilitySelector';
import { NoTimezoneView } from './NoTimezoneView';
import { SchedulingInfo } from './SchedulingInfo';
import {
  availability,
  container,
  divider,
  info,
  infoContainer,
  timeContainer,
} from './SmartScheduler.css';
import { CalendarSelect } from './dateSelectors/CalendarSelect';
import { getPatientStateInfo } from './patient.utils';

type Props = {
  patient: Patient;
  filterType: ApptsFilter;
  iframeParams: AcuityIframeUrlParams;
  recommendedAppt: NextAppointmentRecommendation;
  onCancel?: () => void;
};

export function SmartScheduler({
  patient,
  filterType,
  iframeParams,
  recommendedAppt,
  onCancel,
}: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const [chosenSlotInfo, setChosenSlotInfo] =
    useState<AppointmentAvailability>();

  const { id: patientId } = patient;

  const apptType = recommendedAppt?.appointmentTypeAcuityId;
  const asapScheduling = shouldBeScheduledAsap(patient, recommendedAppt);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [searchDate, _setSearchDate] = useState(
    getInitialSearchDay(recommendedAppt?.startDate, asapScheduling),
  );
  // Check initial availability
  useOnMount(() => {
    if (apptType) {
      checkAvailability({
        acuityAppointmentTypeId: apptType,
        apptDate: searchDate,
      });
    }
  });
  // Changing the search date should also check for availability on that date
  const setSearchDate = (date: Date) => {
    _setSearchDate(date);
    if (apptType) {
      checkAvailability({
        acuityAppointmentTypeId: apptType,
        apptDate: date,
      });
    }
  };
  const formattedSearchDate = format(searchDate, 'MMM d, yyyy');
  const patientState = getPatientStateInfo(patient);

  const {
    mutate: checkAvailability,
    isLoading: checkingAvailabilities,
    data,
    error,
  } = useCheckPatientAppointmentAvailability(patientId, {
    onError: () => {
      toaster.error(
        intl.formatMessage(
          {
            defaultMessage:
              'Failed to retrieve appointment availabilities for date: {date}',
          },
          { date: formattedSearchDate },
        ),
      );
    },
  });

  const scheduleInfo = (
    <SchedulingInfo patient={patient} recommendedAppt={recommendedAppt} />
  );

  const timezone = iframeParams.timezone || '';
  const availabilities = useMemo(
    () => getUniformUniqAvailabilities(data?.availabilities || []),
    [data?.availabilities],
  );
  // Filter out the times that aren't in the patient's day
  const availabilitiesInPatientDay = useMemo(
    () =>
      availabilities.filter(({ datetime }) => {
        const date = datetime ? utcToZonedTime(datetime, timezone) : null;
        return date && isSameDay(date, searchDate);
      }),
    [availabilities, searchDate, timezone],
  );

  if (!timezone && filterType !== ApptsFilter.SHOW_ALL) {
    return (
      <NoTimezoneView patientId={patient.id} onNavigate={() => onCancel?.()}>
        {scheduleInfo}
      </NoTimezoneView>
    );
  }

  function formatInTz(date: Maybe<string | Date>, fmt: string) {
    if (date instanceof Date) {
      return formatInTimeZone(date, timezone, fmt);
    }
    return date ? formatInTimeZone(new Date(date), timezone, fmt) : null;
  }

  const fullIframeParams: AcuityIframeUrlParams = {
    ...iframeParams,
    ...(chosenSlotInfo?.acuityCalendarId && {
      calendarID: `${chosenSlotInfo.acuityCalendarId}`,
    }),
    // Even though we pass timezone to Acuity, Acuity
    // still defaults to scheduled Clinician's Timezone.
    // https://help.acuityscheduling.com/hc/en-us/articles/16676881708941-Setting-time-zones-in-Acuity-Scheduling#01FWCHW3BYDTQBXWQWPV8VRCN6
    // So we must convert the time to the scheduled clinician's timezone in order
    // for acuity to use the correct date
    datetime: chosenSlotInfo?.datetime
      ? formatInTimeZone(
          new Date(chosenSlotInfo.datetime),
          // Convert the time to the scheduled clincian's timezone
          // or default to EST
          chosenSlotInfo.timezone || 'America/New_York',
          "yyyy-MM-dd'T'HH:mm",
        )
      : '',
  };

  if (!chosenSlotInfo && filterType === ApptsFilter.SHOW_SUGGESTED) {
    return (
      <div className={container}>
        {scheduleInfo}
        <div className={divider} />
        <div className={availability.container}>
          <div className={availability.heading}>
            <Skeleton isLoading={checkingAvailabilities}>
              <FormattedMessage
                defaultMessage="{timeSlots, plural, =0 {No} one {{timeSlots}} other {{timeSlots}}} available {timeSlots, plural, =0 {times} one {time} other {times}}{state, select, NOT_FOUND {} other { for {state}}} on {date}"
                values={{
                  timeSlots: availabilitiesInPatientDay.length,
                  state: patientState?.name || 'NOT_FOUND',
                  date: formattedSearchDate,
                }}
              />
            </Skeleton>
          </div>
          <CalendarSelect value={searchDate} onChange={setSearchDate} />
        </div>
        <AvailabilitySelector
          // HACK: Attempting to fix issue where search date and selector date are out of sync
          key={searchDate.toDateString()}
          isLoading={checkingAvailabilities}
          fetchError={error as AxiosError}
          searchDate={searchDate}
          setSearchDate={setSearchDate}
          availabilities={availabilitiesInPatientDay}
          onSelect={(slot) => {
            logger.debug('SmartScheduler availability time slot selected', {
              searchDate,
              formattedSearchDate,
              selectedTimeInPatientTz: formatInTz(
                slot.datetime,
                'eeee, MMM d h:mm a',
              ),
              selectedAvailabilitySlot: slot,
            });
            setChosenSlotInfo(slot);
          }}
          formatInPatientTimezone={formatInTz}
        />
        {patientState && (
          <div className={infoContainer}>
            <InfoIcon stroke={color.Theme.Light.Info} />
            <div className={info}>
              <FormattedMessage
                defaultMessage="Make sure the patient will be in {state} at the time of their appointment"
                values={{ state: patientState.name }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <EmbeddedAcuityView
      key={`${filterType}-${JSON.stringify(fullIframeParams)}`}
      iframeParams={fullIframeParams}
    >
      {scheduleInfo}
      {chosenSlotInfo && (
        <div className={timeContainer}>
          <Button
            size="small"
            variant="tertiary"
            onPress={() => setChosenSlotInfo(undefined)}
          >
            <Button.Icon>
              <ChevronLeft />
            </Button.Icon>
            <FormattedMessage defaultMessage="Previous" />
          </Button>
          {formatInTz(chosenSlotInfo.datetime, "MMM d, YYY 'at' h:mm a z")}
        </div>
      )}
      <div className={divider} />
    </EmbeddedAcuityView>
  );
}

function getInitialSearchDay(
  recommendedApptDay: string | undefined,
  asapScheduling: boolean,
) {
  const apptDay = recommendedApptDay
    ? startOfDay(parseISO(recommendedApptDay))
    : null;
  const today = startOfDay(new Date());

  if (asapScheduling || !apptDay) {
    return today;
  }
  if (isBefore(apptDay, today)) {
    return today;
  }

  return apptDay;
}

/**
 * This function aims to get the availabilities for the day, ensuring that the selection of appointment slots
 * is balanced across different acuityCalendarIds. It groups the availabilities by their datetime and
 * selects the least chosen acuityCalendarId from each group to maintain an even distribution.
 */
function getUniformUniqAvailabilities(
  availabilities: AppointmentAvailability[],
): AppointmentAvailability[] {
  const countMap = new Map();

  const getLeastSelected = (group: AppointmentAvailability[]) =>
    shuffle(group).reduce((leastSelected, current) => {
      const currentCount = countMap.get(current.acuityCalendarId) || 0;
      const leastSelectedCount =
        countMap.get(leastSelected.acuityCalendarId) || 0;
      return currentCount < leastSelectedCount ? current : leastSelected;
    });

  const groupedByDatetime = groupBy(availabilities, 'datetime');

  // Select the least selected element from each group
  return map(groupedByDatetime, (group) => {
    const selected = getLeastSelected(group);
    countMap.set(
      selected.acuityCalendarId,
      (countMap.get(selected.acuityCalendarId) || 0) + 1,
    );
    return selected;
  }) as AppointmentAvailability[];
}
