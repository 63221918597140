import { differenceInMilliseconds, parseISO } from 'date-fns';
import minBy from 'lodash/minBy';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useGetSuggestedEncounterAppointments } from 'pages/patients/PatientProfile/PatientScheduling/appointments.queries';
import type { TimeEntry } from 'pages/patients/patientDetails/ui/Notes/Notes.types';
import { getEhrName } from 'shared/common/Ehr';
import type { EHR } from 'shared/types/ehr.types';

import { getDateTimeFromTimeEntry } from '../../hooks/useMarshaledTimeTrackingPayload.hook';
import { ConfirmationDialog } from '../ConfirmationDialog';
import type { PublishConfirmations } from './confirmation.utils';
import {
  getAssociatedAppointmentConfirmation,
  getTimeTrackingConfirmations,
} from './confirmation.utils';

type Props = {
  patientId: string;
  timeEntry: Partial<TimeEntry>;
  shouldEmrSync: boolean;
  ehr?: EHR;
  isOpen: boolean;
  publishConfirmations: PublishConfirmations;
  onConfirm: (apptId?: Maybe<string>) => void;
  onCancel: () => void;
};

export function PublishConfirmationDialog({
  ehr,
  isOpen,
  onCancel,
  patientId,
  onConfirm,
  timeEntry,
  shouldEmrSync,
  publishConfirmations,
}: Props) {
  const intl = useIntl();
  const { isAppointmentAssociationNeeded } = publishConfirmations;

  const [associatedAppointmentId, setAssociatedAppointmentId] =
    useState<Maybe<string>>();

  // Use time tracking date or date of publish
  const encounterDate = getDateTimeFromTimeEntry(timeEntry) || new Date();
  const { suggestedAppointments, isLoading } =
    useGetSuggestedEncounterAppointments(patientId, encounterDate, {
      enabled: isAppointmentAssociationNeeded && isOpen,
      onSuccess: (data) => {
        const closestApptToEncounter = minBy(data.appointments, (appt) =>
          Math.abs(
            differenceInMilliseconds(
              parseISO(appt.startTime || ''),
              encounterDate,
            ),
          ),
        );
        setAssociatedAppointmentId(closestApptToEncounter?.name);
      },
    });

  const confirmationElems = [
    getTimeTrackingConfirmations(publishConfirmations, timeEntry),
    getAssociatedAppointmentConfirmation(
      publishConfirmations,
      suggestedAppointments,
      associatedAppointmentId,
      setAssociatedAppointmentId,
    ),
  ].filter(Boolean);

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      isLoading={isLoading}
      onCancel={onCancel}
      onConfirm={() => onConfirm(associatedAppointmentId)}
      cancelButtonText={intl.formatMessage({ defaultMessage: 'Close' })}
      confirmButtonText={
        shouldEmrSync
          ? intl.formatMessage(
              { defaultMessage: 'Confirm & Publish to {ehr}' },
              { ehr: getEhrName(ehr) },
            )
          : intl.formatMessage({ defaultMessage: 'Confirm & Publish' })
      }
      dialogTitle={
        <FormattedMessage defaultMessage="Please confirm before publishing" />
      }
      dialogDescription={<>{confirmationElems}</>}
    />
  );
}
