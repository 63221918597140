import { settings } from 'config';
import type { Hospital } from 'shared/types/hospital.types';

const EXTENSION_ID = 'iooakpbaojkmhgceojmapoafaahblkhf';

type RPAConfig = {
  username: string;
  password: string;
  mrn?: string;
  athenaDepartmentId?: string;
  athenaDepartmentName?: string;
  athenaPracticeId?: string;
  healthSystemId?: string;
  env: string;
  type: string;
};

export type EhrCredentials = {
  username: string;
  password: string;
};

export function isRpaChromeExtensionEnabled(): boolean {
  // In Chrome, chrome.runtime.connect and chrome.runtime.sendMessage are used for message sending.
  // These methods are only available when there is at least one extension listening for messages,
  // meaning the extension registered the website as an externally connectable host in it's manifest.
  // chrome.runtime is not defined unconditionally.
  return !!window.chrome && !!window.chrome.runtime;
}

export function goToPatientChart(
  mrn: string | undefined,
  hospital: Hospital,
  credentials: EhrCredentials,
) {
  const rpaConfig = composeRpaConfig(mrn, hospital, credentials);
  return window.chrome.runtime.sendMessage(
    EXTENSION_ID,
    JSON.stringify(rpaConfig),
  );
}

function composeRpaConfig(
  mrn: string | undefined,
  hospital: Hospital,
  credentials: EhrCredentials,
): RPAConfig {
  const { VITE_ENVIRONMENT } = settings;

  return {
    username: credentials.username,
    password: credentials.password,
    mrn,
    athenaDepartmentId: hospital.ehr_information?.athena_department_id,
    athenaPracticeId: hospital.ehr_information?.athena_practice_id,
    athenaDepartmentName: hospital.ehr_information?.athena_department_name,
    type: 'patientChart',
    healthSystemId: hospital.health_system_id.toString(),
    env: VITE_ENVIRONMENT || 'development',
  };
}
