import type { IntlShape } from 'react-intl';

import { DeviceProvider, DeviceTypes } from 'shared/types/device.types';

export function getFriendlyDeviceTypes(intl: IntlShape) {
  return {
    [DeviceTypes.BLOOD_PRESSURE_METER]: intl.formatMessage({
      defaultMessage: 'Blood pressure monitor',
    }),
    [DeviceTypes.WEIGHT_SCALE]: intl.formatMessage({
      defaultMessage: 'Weight scale',
    }),
    [DeviceTypes.BLOOD_GLUCOSE_METER]: intl.formatMessage({
      defaultMessage: 'Blood glucose meter',
    }),
  };
}

export function getFriendlyDeviceProviders(intl: IntlShape) {
  return {
    [DeviceProvider.BODYTRACE]: intl.formatMessage({
      defaultMessage: 'BodyTrace',
    }),
    [DeviceProvider.BIOTEL]: intl.formatMessage({
      defaultMessage: 'BioTel',
    }),
    [DeviceProvider.SMART_METER]: intl.formatMessage({
      defaultMessage: 'Smart Meter',
    }),
    [DeviceProvider.WITHINGS]: intl.formatMessage({
      defaultMessage: 'Withings',
    }),
    [DeviceProvider.TRANSTEK]: intl.formatMessage({
      defaultMessage: 'Transtek',
    }),
  };
}
