import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  FieldGroup,
  Script,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { scriptText } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';
import { Wizard } from 'shared/common/Wizard';
import { useWizardStateContext } from 'shared/common/Wizard/state';
import { ConditionProgram } from 'shared/types/condition.types';

import { useCNFormContext } from '../../../CNFormContext';
import { CYCLE_17_INIT_VISIT_SECTIONS } from '../../metadata';
import { cycle17InitVisitPath } from '../paths';
import { list } from './ProfileCompletion.css';

export function ProfileCompletionInactive() {
  const intl = useIntl();
  const history = useHistory();
  const { program } = useCNFormContext();
  const { skipSectionStep } = useWizardStateContext();

  return (
    <Wizard.Step
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      title={intl.formatMessage({ defaultMessage: 'Activation' })}
      onNext={() => {
        skipSectionStep(cycle17InitVisitPath('/device-tips', '/index'));

        if (program === ConditionProgram.CHF) {
          history.push(cycle17InitVisitPath('/vital-progress', '/intro'));
        } else {
          skipSectionStep(cycle17InitVisitPath('/vital-progress', '/intro'));
          skipSectionStep(
            cycle17InitVisitPath('/vital-progress', '/recommendation'),
          );
          history.push(cycle17InitVisitPath('/action-plan', '/script'));
        }
      }}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage defaultMessage="I see that you have received your devices but have not been able to take any readings yet. After our conversation today, if you have time, I would like to have you talk to one of my colleagues who can help us get you connected and ready to check your readings daily. We can call you back if needed." />
        </Script>
        <Script>
          <FormattedMessage defaultMessage="A few more tips about your devices:" />
        </Script>
        <ul className={list}>
          <li className={scriptText}>
            <FormattedMessage defaultMessage="If you are traveling, we do recommend taking your devices with you when possible. However, if you will be gone for an extended amount of time and will not be taking readings, please let us know ahead of time by calling or texting us." />
          </li>
          <li className={scriptText}>
            <FormattedMessage defaultMessage="Be sure that you are the only one using the devices. Each reading will transmit and may cause confusion if it does not match your usual readings." />
          </li>
        </ul>
      </FieldGroup>
    </Wizard.Step>
  );
}
