import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from 'shared/common/Form';
import { useFormFromConfig } from 'shared/common/Form/FormContainer';
import { Modal } from 'shared/common/Modal';
import type { ReportIssueRequestCategory } from 'shared/generated/grpcGateway/issue_reporter.pb';
import { Button } from 'shared/tempo/atom/Button';

import { useReportIssueToSlack } from '../reports.queries';
import { errorMessage, successMessage } from './ReportIssueForm.css';
import type { FormFields } from './formConfig';
import { useFormConfig } from './formConfig';

type Props = {
  patientId?: string;
  category?: ReportIssueRequestCategory;
};

export function ReportIssueForm({ patientId, category }: Props) {
  const intl = useIntl();
  const {
    mutate: reportToSlack,
    error,
    isSuccess,
    isLoading,
  } = useReportIssueToSlack();

  const config = useFormConfig();
  const form = useFormFromConfig<FormFields>(config);

  useEffect(() => {
    form.setValue('patientId', patientId || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  return (
    <Form
      form={form}
      onSubmit={({ patientId: formPatientId, description }) =>
        reportToSlack({ description, patientId: formPatientId, category })
      }
    >
      <Modal.Body>
        {!isSuccess ? (
          <>
            <Form.Row>
              <Form.TextField
                size={12}
                name="patientId"
                mask={{
                  mask: '########{-}####{-}####{-}####{-}############',
                  definitions: { '#': /[A-Za-z0-9]/ },
                }}
                label={<FormattedMessage defaultMessage="Patient ID" />}
              />
            </Form.Row>
            <Form.Row>
              <Form.TextArea
                size={12}
                rows={4}
                name="description"
                placeholder={intl.formatMessage({
                  defaultMessage:
                    'Be as descriptive as possible, but do not include EPHI data.',
                })}
                label={<FormattedMessage defaultMessage="Issue Description" />}
              />
            </Form.Row>
          </>
        ) : (
          <p className={successMessage}>
            <FormattedMessage defaultMessage="Thank you for your feedback. The issue has been reported successfully." />
          </p>
        )}
        {error && (
          <p className={errorMessage}>
            <FormattedMessage defaultMessage="Failed to submit issue report" />
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!isSuccess && (
          <Button
            id="issue-report-submit"
            isDisabled={!form.canSubmit}
            isProcessing={isLoading}
            type="submit"
            variant="primary"
          >
            <FormattedMessage defaultMessage="Submit" />
          </Button>
        )}
      </Modal.Footer>
    </Form>
  );
}
