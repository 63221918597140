import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import type { OverlayTriggerState } from 'react-stately';

import { useFlags } from 'shared/hooks';
import { useTaskCountByType } from 'shared/hooks/queries/tasks.queries';
import { ParentView } from 'shared/tasking/TaskList';
import type { Task } from 'shared/tasking/types';
import { Tabs } from 'shared/tempo/@labs/atom/Tabs';
import type { NotificationBadgeProps } from 'shared/tempo/atom/NotificationBadge';
import { NotificationBadge } from 'shared/tempo/atom/NotificationBadge';

import { TabKey } from './TabKey';
import { tabLine, tabTitle, tabTitleContainer } from './TaskHub.css';
import { tabPanel } from './TaskHubBody.css';
import {
  AssignedTasksList,
  FollowedTasksList,
  ResolvedTasksList,
  TeamTasksList,
} from './TaskList';

type Props = {
  parentView: ParentView;
  state?: OverlayTriggerState;
  activeTaskId?: string;
  onOpenTaskDetail?: (task: Task) => void;
  selectedTab?: TabKey;
  onSelectedTabChange?: (tab: TabKey) => void;
};

export function TaskHubBody({
  state,
  parentView,
  activeTaskId,
  onOpenTaskDetail,
  selectedTab,
  onSelectedTabChange,
}: Props) {
  const { cycle17TaskHubUpdates } = useFlags();
  const { data: countsByType } = useTaskCountByType();
  const openTaskCount = getTotalTaskCount(countsByType?.assigned);
  const teamTasksCount = getTotalTaskCount(countsByType?.team);
  const followingTaskCount = getTotalTaskCount(countsByType?.following);

  const tabs = [
    <Tabs.Tab
      keepMounted
      className={cycle17TaskHubUpdates ? tabPanel.compact : tabPanel.legacy}
      key={TabKey.Open}
      title={
        <TabTitle badgeVariant="default" taskCount={openTaskCount}>
          <FormattedMessage defaultMessage="My tasks" />
        </TabTitle>
      }
    >
      <AssignedTasksList
        taskHubOverlayState={state}
        parentView={parentView}
        activeTaskId={activeTaskId}
        onOpenTaskDetail={onOpenTaskDetail}
      />
    </Tabs.Tab>,
  ];

  if (parentView === ParentView.HomePage || cycle17TaskHubUpdates) {
    tabs.push(
      <Tabs.Tab
        keepMounted
        className={cycle17TaskHubUpdates ? tabPanel.compact : tabPanel.legacy}
        key={TabKey.Team}
        title={
          <TabTitle badgeVariant="muted" taskCount={teamTasksCount}>
            <FormattedMessage defaultMessage="Team tasks" />
          </TabTitle>
        }
      >
        <TeamTasksList
          taskHubOverlayState={state}
          parentView={parentView}
          activeTaskId={activeTaskId}
          onOpenTaskDetail={onOpenTaskDetail}
        />
      </Tabs.Tab>,
    );
  }

  tabs.push(
    <Tabs.Tab
      keepMounted
      className={cycle17TaskHubUpdates ? tabPanel.compact : tabPanel.legacy}
      key={TabKey.Following}
      title={
        <TabTitle badgeVariant="muted" taskCount={followingTaskCount}>
          <FormattedMessage defaultMessage="Following" />
        </TabTitle>
      }
    >
      <FollowedTasksList
        taskHubOverlayState={state}
        parentView={parentView}
        activeTaskId={activeTaskId}
        onOpenTaskDetail={onOpenTaskDetail}
      />
    </Tabs.Tab>,
  );
  tabs.push(
    <Tabs.Tab
      keepMounted
      className={cycle17TaskHubUpdates ? tabPanel.compact : tabPanel.legacy}
      key={TabKey.Resolved}
      title={
        <TabTitle>
          <FormattedMessage defaultMessage="Resolved" />
        </TabTitle>
      }
    >
      <ResolvedTasksList
        taskHubOverlayState={state}
        parentView={parentView}
        activeTaskId={activeTaskId}
        onOpenTaskDetail={onOpenTaskDetail}
      />
    </Tabs.Tab>,
  );

  return (
    <Tabs
      className={tabLine}
      items={tabs}
      selectedKey={selectedTab}
      onSelectionChange={(key) => onSelectedTabChange?.(key as TabKey)}
    >
      {(tab) => tab}
    </Tabs>
  );
}

type TabTitleProps = {
  badgeVariant?: NotificationBadgeProps['variant'];
  children: ReactNode;
  taskCount?: number;
};

function TabTitle({ badgeVariant, children, taskCount = 0 }: TabTitleProps) {
  return (
    <div className={tabTitleContainer}>
      <span className={tabTitle}>{children}</span>
      {badgeVariant && taskCount > 0 && (
        <NotificationBadge count={taskCount} variant={badgeVariant} />
      )}
    </div>
  );
}

function getTotalTaskCount(countByType: Record<string, string> = {}) {
  return Object.values(countByType).reduce(
    (acc, count) => acc + parseInt(count, 10),
    0,
  );
}
