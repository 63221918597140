import { useIntl } from 'react-intl';

import {
  Condition,
  type RpmCondition,
} from 'shared/types/clinicalprofile.types';

export function useConditionStrings(conditions: RpmCondition[]) {
  const intl = useIntl();
  const hasHtn = conditions.includes(Condition.Hypertension);
  const hasT2d = conditions.includes(Condition.TypeTwoDiabetes);

  if (hasHtn && hasT2d) {
    return {
      condition: intl.formatMessage({
        defaultMessage: 'Hypertension and Diabetes',
      }),
      vitals: intl.formatMessage({
        defaultMessage: 'blood pressure and blood glucose',
      }),
    };
  }

  if (hasHtn) {
    return {
      condition: intl.formatMessage({ defaultMessage: 'Hypertension' }),
      vitals: intl.formatMessage({ defaultMessage: 'blood pressure' }),
    };
  }

  if (hasT2d) {
    return {
      condition: intl.formatMessage({ defaultMessage: 'Diabetes' }),
      vitals: intl.formatMessage({ defaultMessage: 'blood glucose' }),
    };
  }

  if (conditions.includes(Condition.CHF)) {
    return {
      condition: intl.formatMessage({ defaultMessage: 'CHF' }),
      vitals: intl.formatMessage({
        defaultMessage: 'blood pressure and weight',
      }),
    };
  }

  return {
    condition: intl.formatMessage({ defaultMessage: 'UNKNOWN CONDITION' }),
    vitals: intl.formatMessage({ defaultMessage: 'UNKNOWN CONDITION' }),
  };
}
