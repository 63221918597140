import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { scriptText } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';

import { ConditionProgram } from '../../vitalEducation/types';

type Props = {
  conditionProgram: ConditionProgram;
};

const DIET_RECOMMENDATION: Record<ConditionProgram, ReactNode> = {
  [ConditionProgram.HEART_FAILURE]: (
    <FormattedMessage defaultMessage="Eat a heart-healthy diet that's low in salt, sugar, and has limited saturated fats" />
  ),
  [ConditionProgram.HYPERTENSION]: (
    <FormattedMessage defaultMessage="Eat a well-balanced diet that's low in salt" />
  ),
  [ConditionProgram.TYPE_2_DIABETES]: (
    <FormattedMessage defaultMessage="Eat a well-balanced diet and watch sugar and carbohydrates intake" />
  ),
  [ConditionProgram.T2D_AND_HYPERTENSION]: [
    <FormattedMessage defaultMessage="Eat a well-balanced diet that's low in salt" />,
    <FormattedMessage defaultMessage="Watch your sugar and carbohydrates intake " />,
  ],
};

const MEDS_RECOMMENDATION: Record<ConditionProgram, ReactNode> = {
  [ConditionProgram.HEART_FAILURE]: (
    <FormattedMessage defaultMessage="If you take medications, make sure to take them properly" />
  ),
  [ConditionProgram.HYPERTENSION]: (
    <FormattedMessage defaultMessage="If you take blood pressure medications, make sure to take them properly" />
  ),
  [ConditionProgram.TYPE_2_DIABETES]: (
    <FormattedMessage defaultMessage="If you take diabetes medications, make sure to take them properly" />
  ),
  [ConditionProgram.T2D_AND_HYPERTENSION]: (
    <FormattedMessage defaultMessage="If you take medications, make sure to take them properly" />
  ),
};

export function RecommendationsList({ conditionProgram }: Props) {
  const hasChf = conditionProgram === ConditionProgram.HEART_FAILURE;
  const dietRec = DIET_RECOMMENDATION[conditionProgram];
  const medsRec = MEDS_RECOMMENDATION[conditionProgram];

  if (hasChf) {
    return (
      <ul>
        <ListItem>{dietRec}</ListItem>
        <ListItem>
          <FormattedMessage defaultMessage="Keep your stress levels low and get enough rest" />
        </ListItem>
        <ListItem>
          <FormattedMessage defaultMessage="Limit or avoid alcohol" />
        </ListItem>
        <ListItem>
          <FormattedMessage defaultMessage="Enjoy regular physical activity" />
        </ListItem>
        <ListItem>
          <FormattedMessage defaultMessage="Maintain a healthy weight" />
        </ListItem>
        <ListItem>
          <FormattedMessage defaultMessage="If you smoke, quit." />
        </ListItem>
        <ListItem>{medsRec}</ListItem>
      </ul>
    );
  }
  return (
    <ul>
      {Array.isArray(dietRec) ? (
        dietRec.map((item, index) => <ListItem key={index}>{item}</ListItem>)
      ) : (
        <ListItem>{dietRec}</ListItem>
      )}

      <ListItem>
        <FormattedMessage defaultMessage="Limit the alcohol that you drink" />
      </ListItem>
      <ListItem>
        <FormattedMessage defaultMessage="Enjoy regular physical activity" />
      </ListItem>
      <ListItem>
        <FormattedMessage defaultMessage="Keep your stress levels low" />
      </ListItem>
      <ListItem>
        <FormattedMessage defaultMessage="Maintain a healthy weight" />
      </ListItem>
      <ListItem>
        <FormattedMessage defaultMessage="If you smoke, quit." />
      </ListItem>
      <ListItem>{medsRec}</ListItem>
    </ul>
  );
}

function ListItem({ children }: { children: ReactNode }) {
  return <li className={scriptText}>{children}</li>;
}
