import type { IntlShape } from 'react-intl';

import { validators } from 'shared/common/Form/validations';

import { PatientGoal } from '../../PatientGoal';

export type PatientGoalFormFields = {
  patientGoal: PatientGoal;
  previousPatientGoal?: PatientGoal;
  newGoalReason?: string;
};

export function hasNewPatientGoal(
  patientGoalData: Maybe<PatientGoalFormFields>,
) {
  return patientGoalData?.patientGoal !== patientGoalData?.previousPatientGoal;
}

export function getPatientGoalFormConfig(
  intl: IntlShape,
  showVitalsEducation?: boolean,
  values?: PatientGoalFormFields,
) {
  const { required, enumType, maxLengthString } = validators(intl);
  const prevPatientGoal = values?.patientGoal;

  return {
    fields: {
      patientGoal: {
        defaultValue: prevPatientGoal,
        validation: required(
          enumType({ source: PatientGoal, pluck: 'values' }),
        ),
      },
      // Hidden field, use to keep track of prev. goal
      previousPatientGoal: {
        defaultValue: prevPatientGoal,
        validation: enumType({ source: PatientGoal, pluck: 'values' }),
      },
      ...(!showVitalsEducation && {
        newGoalReason: {
          // Always default this to undefined, we don't want to carry this forward
          // from past notes
          defaultValue: undefined,
          validation: maxLengthString({ maxLength: 255 }).when('patientGoal', {
            is: (currPatientGoal: Maybe<string>) => {
              if (
                currPatientGoal &&
                prevPatientGoal &&
                prevPatientGoal !== currPatientGoal
              ) {
                return true;
              }
              return false;
            },
            then: required,
          }),
        },
      }),
    },
  };
}
