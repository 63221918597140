import type { Schema } from 'shared/common/@deprecated/SchemaDrivenForm/';
import type { RpmCondition } from 'shared/types/clinicalprofile.types';

import type { SymptomsInputs } from '../../NotePreview/formatSymptomsInputs.util';
import {
  getConditionInputs,
  getConditionKeys,
} from '../encounterModuleSchema.utils';

// TODO: Change this method to only add condition inputs, and update
// `useInitializeFormData` to be able to render default symptoms inputs by
// understanding the `dependencies` keyword
// Ticket: https://cadencerpm.atlassian.net/browse/PLAT-3121
/**
 * Populates initial values for SymptomsForm to fit the schema used for the
 * react-json-schema-form
 * @param {Object} schema - Symptoms schema as stored in the backend
 * @param {Array.<Condition>} conditions - An array of conditions that the patient has
 * @param {Object} initialValues - Existing Symptoms data to load initial values from (e.g. from a draft or from a copied note)
 * @returns {Object} Returns data for SymptomsForm that follows the schema
 */
export function getInitialSymptomsData(
  schema: Schema,
  conditions: RpmCondition[] = [],
  initialValues?: SymptomsInputs,
) {
  const patientSymptoms = Object.entries(schema.dependentSchemas ?? {})
    .filter(([conditionKey]) =>
      getConditionKeys(conditions).includes(conditionKey),
    )
    .flatMap(([, { properties = {} }]) => Object.keys(properties));

  // Create map of properties that the SymptomsForm expects and populate
  // any existing initial values
  const symptomsInputs = Object.fromEntries(
    patientSymptoms.map((symptomKey) => [
      symptomKey,
      initialValues?.[symptomKey] || {},
    ]),
  );

  return {
    ...(conditions ? getConditionInputs(conditions) : {}),
    ...symptomsInputs,
  };
}
