import { useQueryClient } from 'react-query';

import { useUpdateOrBlockAlertRequest } from 'pages/patients/patientDetails/utils';
import { invalidatePatientMonitoringSessionQueries } from 'shared/hooks/queries';
import type { VitalsAlert } from 'shared/types/alert.types';
import { AlertStatus } from 'shared/types/alert.types';

import { useNoteEditorContext } from '../../NoteEditorContext';
import { EditableNoteType } from '../../Notes.types';
import type { ResolveAlertNoteParams } from '../../note.queries';

export function useResolveAlert() {
  const updateOrBlockAlertRequest = useUpdateOrBlockAlertRequest();
  const { editingNote, setEditingNote } = useNoteEditorContext();
  const queryClient = useQueryClient();

  return async (notePayload: ResolveAlertNoteParams) => {
    if (editingNote?.type !== EditableNoteType.Alert) {
      return;
    }

    const { error } = await updateOrBlockAlertRequest({
      alert: editingNote.alert,
      setAlertWaitingForNotes: (alert: VitalsAlert) =>
        setEditingNote({
          type: EditableNoteType.Alert,
          alert,
          // note is set once we perform the first autosave
          note: null,
        }),
      status: AlertStatus.Resolved,
      note: {
        title: notePayload.title,
        body: notePayload.body,
        body_html: notePayload.bodyHtml,
        rtf_body: notePayload.rtfBody,
        author: notePayload.author,
        labels: notePayload.labels,
        action_required: notePayload.actionRequired,
        urgent: notePayload.urgent,
        encounter_instances: notePayload.encounterModuleInstances,
        external_provider_id: notePayload.externalProviderId,
        should_emr_sync: notePayload.shouldEMRSync,
        time_tracking: notePayload.timeTracking,
        zendesk_ticket_id: notePayload.zendeskTicket,
      },
    });
    if (error) {
      throw new Error('updateOrBlockAlertRequest failed');
    }
    await invalidatePatientMonitoringSessionQueries(
      notePayload.patientId,
      queryClient,
    );
  };
}
