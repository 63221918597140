import authInitialState from 'pages/auth/reducer/initialState';

const locale = localStorage.getItem('locale') || 'en';

export default {
  intl: {
    defaultLocale: 'en',
    locale,
    messages: {},
  },
  auth: authInitialState,
};
