import cx from 'classnames';
import type { HTMLAttributes } from 'react';

import { color as colors } from 'shared/tempo/theme';

import { label } from './Label.css';
import { useAvatarContext } from './avatarContext';

type Props = {
  color?: string;
} & HTMLAttributes<HTMLDivElement>;

export function Label({
  className,
  children,
  color = colors.Palette.Neutral.White,
  ...props
}: Props) {
  const { size } = useAvatarContext();
  return (
    <div {...props} style={{ color }} className={cx(label[size], className)}>
      {children}
    </div>
  );
}
