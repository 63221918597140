import type { ComponentType } from 'react';
import { Component as ReactComponent } from 'react';

import { Toast } from 'shared/tempo/molecule/Toast';
import type { Toaster } from 'shared/tempo/molecule/Toast/types';

type WithToasterProp = {
  toaster?: Maybe<Toaster>;
};

/** @deprecated Use Tempo useToaster hook instead */
export function withToaster<T extends object>(Component: ComponentType<T>) {
  class WithToaster extends ReactComponent<T & WithToasterProp> {
    render() {
      return (
        <Toast.Consumer>
          {(context) => (
            <Component {...this.props} toaster={context?.toaster} />
          )}
        </Toast.Consumer>
      );
    }
  }

  return WithToaster;
}
