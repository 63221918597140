import { useIntl } from 'react-intl';

import { logger } from 'logger';
import type { PatientClinicalProfile } from 'shared/generated/grpcGateway/pms.pb';
import { useFlags } from 'shared/hooks';
import type { VitalsAlert } from 'shared/types/alert.types';
import { ReadingDataType } from 'shared/types/patientSummary.types';
import type { AllTagType } from 'shared/types/tagsAndThreshold.types';
import type { PatientVitals } from 'shared/types/vitals.types';

import type { AlertMessageFormatterProps } from '../AlertDescription.types';
import type { AlertFormatter } from '../utils/alertDescriptionFormatters';
import {
  BloodGlucoseAlertStrategy,
  BloodPressureAlertStrategy,
  HeartRateAlertStrategy,
  WeightAlertStrategy,
} from '../utils/alertDescriptionStrategies';
import type { AlertDescriptionStrategy } from '../utils/baseAlertDescriptionStrategy';

export type AlertDescriptionMeta = {
  tag: AllTagType;
  readings: AlertMessageFormatterProps;
  timestamp: string;
  formatter: AlertFormatter<AllTagType, AlertMessageFormatterProps>;
};

export function useAlertDescription(
  alert: VitalsAlert,
  vitals: PatientVitals,
  patientProfile: Maybe<PatientClinicalProfile>,
) {
  const intl = useIntl();
  const flags = useFlags();

  const alertLoggerInfo = {
    patientId: alert.patient_id,
    alertId: alert.id,
  };

  const descriptionFactories = new Map<
    ReadingDataType,
    AlertDescriptionStrategy
  >([
    [ReadingDataType.Weight, new WeightAlertStrategy(patientProfile, intl)],
    [ReadingDataType.BloodGlucose, new BloodGlucoseAlertStrategy(intl, flags)],
    [ReadingDataType.HeartRate, new HeartRateAlertStrategy(intl)],
    [ReadingDataType.BloodPressure, new BloodPressureAlertStrategy(intl)],
  ]);

  return Object.entries(alert.relations).reduce((acc, curr) => {
    const [dataType, readingIds] = curr;

    const factory = descriptionFactories.get(dataType as ReadingDataType);
    if (!factory) {
      logger.error(
        `Failed to generate alert description for reading data type: ${dataType}`,
      );
      return acc;
    }
    return acc.concat(
      factory.getAlertDescription(
        readingIds,
        vitals,
        alert.alert_tags,
        alertLoggerInfo,
        alert.relation_tags_unsuppressed,
      ),
    );
  }, [] as AlertDescriptionMeta[]);
}
