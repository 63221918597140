import classnames from 'classnames/bind';
import { useIntl } from 'react-intl';

import type {
  AccommodationKey,
  PatientDetails,
  ResidenceKey,
} from 'shared/types/patient.types';
import { Accommodation, Residence } from 'shared/types/patient.types';
import type { PhoneType } from 'shared/types/shared.types';
import { formatPhoneNumber, getPhoneType } from 'shared/utils/phone';
import { timezoneToFriendlyName } from 'shared/utils/time-helpers';

import { InfoRow } from '../InfoRow';
import styles from './personalInfo.module.scss';

const cx = classnames.bind(styles);

// if the patient has this in their accommodations list,
// there should also be a value populated in the patient's
// `accommodations_other` field.
const OTHER_ACCOMMODATION = 'OTHER';

type PersonalInfoProps = {
  patient: PatientDetails;
};

export function PersonalInfo({ patient }: PersonalInfoProps) {
  const intl = useIntl();
  const contacts =
    patient.contacts?.filter(({ emergency }) => !emergency) ?? [];
  const accommodations =
    patient.accommodations?.filter((acc) => acc !== OTHER_ACCOMMODATION) ?? [];

  return (
    <>
      {patient.email && (
        <InfoRow
          left={intl.formatMessage({ defaultMessage: 'Email' })}
          right={patient.email}
        />
      )}
      {contacts
        .sort((contact) => (contact.primary ? -1 : 1))
        .map(({ contact_id, contact, primary }) => (
          <InfoRow
            key={`contact-${contact_id}`}
            left={getPhoneType(contact.phone_type as PhoneType)}
            right={formatPhoneNumber(contact.phone_number)}
            rightSubtext={
              primary && intl.formatMessage({ defaultMessage: 'Preferred' })
            }
          />
        ))}
      <InfoRow
        left={intl.formatMessage({ defaultMessage: 'Time Zone' })}
        right={timezoneToFriendlyName(patient.timezone)}
      />
      {patient.addresses?.map(({ address, address_id }) => (
        <div key={`address-${address_id}`}>
          {address.street_address} {address.premise}
          <br />
          {address.locality}, {address.region} {address.postal_code}
        </div>
      ))}
      <ul className={cx('info-list')}>
        {patient.residence && (
          <li>{Residence[patient.residence as ResidenceKey]}</li>
        )}
        {accommodations.map((accomodation) => (
          <li key={accomodation}>
            {Accommodation[accomodation as AccommodationKey]}
          </li>
        ))}
        {patient.accommodations_other && (
          <li>{patient.accommodations_other}</li>
        )}
      </ul>
    </>
  );
}
