import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { FieldGroup } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { InlineMessage } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/InlineMessage';
import { useTimeEstimate } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import type { ConfiguredForm } from 'shared/common/Form/FormContainer';
import { BaseField } from 'shared/common/Form/fields/BaseField';
import { Wizard } from 'shared/common/Wizard';
import { usePatientDetails } from 'shared/hooks/queries';
import { display } from 'shared/jsStyle/utils.css';
import { getRpmConditionsFromProgramAndStatus } from 'shared/patient/conditions.utils';

import { useCNFormContext } from '../../../CNFormContext';
import type { CNSectionMap } from '../../metadata';
import type { PatientGoal } from '../PatientGoal';
import { ReviewedModules } from './ReviewedModules';
import type { EducationModulesReviewFormFields } from './formConfig';

type Props = {
  form: ConfiguredForm<EducationModulesReviewFormFields>;
  sections: CNSectionMap;
  patientGoal?: PatientGoal;
  isLoading?: boolean;
} & (
  | { nextPath: string; onNext?: never }
  | { nextPath?: never; onNext: () => void }
);

export function SharedEducationModulesReview({
  form,
  sections,
  nextPath,
  onNext,
  patientGoal,
  isLoading,
}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const { patientId } = useCNFormContext();
  const { data: patientDetails } = usePatientDetails(
    patientId,
    false,
    Boolean(patientId),
  );
  const [reviewedModules, previousReviewedModules] = form.watch([
    'reviewedModules',
    'previousReviewedModules',
  ]);

  const conditions = getRpmConditionsFromProgramAndStatus(
    patientDetails?.programs,
    patientDetails?.status,
  );

  return (
    <Wizard.Step
      isLoading={isLoading}
      sections={sections}
      form={form}
      title={intl.formatMessage({ defaultMessage: 'Education' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => {
        if (onNext) {
          onNext();
          return;
        }

        history.push(nextPath);
      }}
    >
      <FieldGroup>
        <InlineMessage>
          <FormattedMessage defaultMessage="Select the appropriate education module(s) and review with the patient. Afterwards, return to this screen and mark which modules were reviewed." />
        </InlineMessage>
        <ReviewedModules
          name="reviewedModules"
          conditions={conditions}
          patientGoal={patientGoal}
          reviewedModules={reviewedModules}
          previouslyReviewedModules={previousReviewedModules || []}
        />
        <BaseField
          name="previousReviewedModules"
          className={display.none}
          aria-label={intl.formatMessage({
            defaultMessage: 'Previously Reviewed Modules',
          })}
        />
      </FieldGroup>
    </Wizard.Step>
  );
}
