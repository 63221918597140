import { useIntl } from 'react-intl';

import { CCMPrognosis } from 'shared/generated/grpcGateway/ccm_care_plan.pb';

export const CCM_PROGNOSIS_OPTIONS = [
  CCMPrognosis.GOOD,
  CCMPrognosis.FAIR,
  CCMPrognosis.POOR,
];

export function useCcmPrognosisI18n(): Record<CCMPrognosis, string> {
  const intl = useIntl();

  return {
    [CCMPrognosis.GOOD]: intl.formatMessage({ defaultMessage: 'Good' }),
    [CCMPrognosis.FAIR]: intl.formatMessage({ defaultMessage: 'Fair' }),
    [CCMPrognosis.POOR]: intl.formatMessage({ defaultMessage: 'Poor' }),
    [CCMPrognosis.CCM_PROGNOSIS_UNSPECIFIED]: intl.formatMessage({
      defaultMessage: 'Unspecified',
    }),
  };
}
