import { useIntl } from 'react-intl';
import { MemoryRouter, Redirect, Route, Switch } from 'react-router-dom';

import { Modal } from 'shared/common/Modal';
import { useUniqueRenderKey } from 'shared/hooks';
import { useCopyToClipboard } from 'shared/hooks/useCopyToClipboard';
import { Button } from 'shared/tempo/atom/Button';

import { Header } from './Header';
import { Categories } from './Pages/Categories';
import { ReportIssue } from './Pages/ReportIssue';
import { version as versionClass } from './SupportModal.css';
import { SupportModalContextProvider } from './SupportModalContext';
import { useLocationPatientId } from './hooks';
import { useDebugMetadata } from './reports.queries';
import { BASE_ROUTE, useCategories } from './useCategories';

type Props = {
  open: boolean;
  onClose: () => void;
};

export function SupportModal({ open, onClose }: Props) {
  const intl = useIntl();
  const categories = useCategories();
  const patientId = useLocationPatientId();
  const { key, refresh } = useUniqueRenderKey('support-modal');
  const copyToClipboard = useCopyToClipboard();
  const {
    currentPageUri,
    environment,
    rumSessionUrl,
    userDisplayName,
    version,
    sentryUrl,
  } = useDebugMetadata();

  return (
    <SupportModalContextProvider value={{ patientId }}>
      <MemoryRouter key={key}>
        <Modal
          size="large"
          open={open}
          onClose={() => {
            refresh();
            onClose();
          }}
        >
          <Header />
          <Switch>
            <Route path={BASE_ROUTE} exact component={Categories} />
            {categories.map(({ to, component }) => (
              <Route path={to} key={to} component={component} />
            ))}
            <Route path={`${BASE_ROUTE}/report`} component={ReportIssue} />
            <Redirect to={BASE_ROUTE} />
          </Switch>
          <div className={versionClass.container}>
            <Button
              className={versionClass.button}
              size="small"
              variant="tertiary"
              onPress={() => {
                copyToClipboard(
                  JSON.stringify(
                    {
                      userDisplayName,
                      currentPageUri,
                      environment,
                      rumSessionUrl,
                      sentryUrl,
                    },
                    null,
                    2,
                  ),
                  intl.formatMessage({
                    defaultMessage: 'Copied debug information to clipboard',
                  }),
                  intl.formatMessage({
                    defaultMessage:
                      'Failed to copy debug information to clipboard',
                  }),
                );
              }}
            >
              {intl.formatMessage(
                { defaultMessage: 'version {versionText}' },
                {
                  versionText: `${environment}:${version}`,
                },
              )}
            </Button>
          </div>
        </Modal>
      </MemoryRouter>
    </SupportModalContextProvider>
  );
}
