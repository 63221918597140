import uniq from 'lodash/uniq';
import { useMemo } from 'react';

import { getProblemList } from 'pages/adminPanel/patient-enrollment-tracker/ui/shared/icd10CodeUtils';
import { useFlags } from 'shared/hooks';
import { usePatientDetails } from 'shared/hooks/queries';
import { useAssignedDevices } from 'shared/hooks/queries/devices.queries';
import { usePatientContext } from 'shared/hooks/queries/patientContext.queries';
import { usePatientVitalsContext } from 'shared/hooks/queries/vitalsContext.queries';
import { getRpmConditionsFromProgramAndStatus } from 'shared/patient/conditions.utils';

import type { TemplateContext } from '../types';
import { usePatientLatestLabsContext } from './usePatientLatestLabsContext';
import { usePatientEhrMedicationsContext } from './usePatientMedicationsContext';

export function useTemplateContext(patientId: string) {
  const flags = useFlags();
  const { data: context, isLoading: isContextLoading } = usePatientContext(
    patientId,
    { enabled: !!patientId },
  );
  const { data: patientVitalsContext, isLoading: isContextVitalsLoading } =
    usePatientVitalsContext(patientId);

  const { data: devices, isLoading: isDevicesLoading } = useAssignedDevices(
    patientId,
    {},
  );
  const { data: latestLabsContext, isLoading: isLabsLoading } =
    usePatientLatestLabsContext(patientId);
  const { data: patientDetailsContext, isLoading: isPatientDetailsLoading } =
    usePatientDetails(patientId, false);
  const { data: ehrMedicationsContext, isLoading: isMedicationsLoading } =
    usePatientEhrMedicationsContext(patientId);
  const deviceTypes = useMemo(
    () => uniq(devices?.data?.map((d) => d.device_type) || []),
    [devices?.data],
  );
  const contextVitals = patientVitalsContext?.contextVitals;

  const templateContext: TemplateContext = {
    ...(patientDetailsContext && {
      problemList: getProblemList(patientDetailsContext.diagnosis_codes),
      rpmConditions: getRpmConditionsFromProgramAndStatus(
        patientDetailsContext.programs,
        patientDetailsContext.status,
      ),
    }),
    ...context,
    ...contextVitals,
    latestLabs: latestLabsContext,
    ehrMedications: ehrMedicationsContext,
    flags,
    deviceTypes,
  };
  const isLoading =
    isContextLoading ||
    isContextVitalsLoading ||
    isDevicesLoading ||
    isPatientDetailsLoading ||
    isLabsLoading ||
    isMedicationsLoading;

  return {
    isLoading,
    context: templateContext,
  };
}
