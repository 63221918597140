import type { WidgetProps } from '@rjsf/core';
import { useEffect, useState } from 'react';

import { TextField } from 'deprecated/mui';

import { textWidget } from '../SchemaDrivenForm.css';
import { getOmitExtraDataOnChange } from '../getOmitExtraDataOnChange';
import { hasValidationErrorFormContext } from '../validationErrorUtils';

export function TextWidget({
  onChange: rjsfOnChange,
  value,
  formContext,
  label,
  id,
}: WidgetProps) {
  const onChange = getOmitExtraDataOnChange(rjsfOnChange);
  const [inputValue, setInputValue] = useState('');
  const hasError = hasValidationErrorFormContext(formContext, label, id);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <TextField
      error={hasError}
      value={inputValue || ''}
      onBlur={() => {
        if (inputValue === value) return;

        // If empty string then pass undefined so rjsf treats it as no value.
        // It's required for proper validation of required texts fields.
        onChange(inputValue === '' ? undefined : inputValue);
      }}
      onChange={(event) => setInputValue(event.target.value)}
      className={textWidget}
    />
  );
}
