import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { settings } from 'config';
import type { RootState } from 'store/redux.types';

/**
 * Datadog and Sentry monitoring utils
 */

export function getRumSessionId() {
  return datadogRum.getInternalContext()?.session_id;
}

export function getRumSessionUrl() {
  const id = getRumSessionId();
  return (
    id && `https://app.datadoghq.com/rum/explorer?query=%40session.id%3A${id}`
  );
}

export function registerMonitoredUser(email: string) {
  // Register user information with sentry, datadog, and heap
  datadogRum.setUser({ email });
  Sentry.setUser({ email });
  window.heap?.identify(email);
  window.heap?.addUserProperties({ email });
}

export function unregisterMonitoredUser() {
  // Unregister user information from sentry, datadog, and heap
  datadogRum.removeUser();
  Sentry.setUser(null);
  window.heap?.resetIdentity();
}

/**
 * Unique falcon version per environment / commit SHA
 */
export function getFalconVersion() {
  return `${settings.VITE_ENVIRONMENT}@${settings.VITE_VERSION}`;
}

export function useRegisterMonitoredUser() {
  const { isAuthenticated, email } = useSelector(
    (state: RootState) => state.auth,
  );

  useEffect(() => {
    if (isAuthenticated && email) {
      registerMonitoredUser(email);
    }
  }, [isAuthenticated, email]);
}
