import uniq from 'lodash/uniq';
import without from 'lodash/without';

import { Checkbox } from 'shared/tempo/atom/Checkbox';

import { grid } from './TimeTrackerTasksCheckboxGroup.css';
import { TASK_OPTIONS } from './constants';

type Props = {
  value: string[];
  onChange: (val: string[]) => void;
};

export function TimeTrackerTasksCheckboxGroup({
  onChange,
  value: fieldValue,
}: Props) {
  return (
    <div className={grid}>
      {TASK_OPTIONS.map(({ label, value }) => (
        <Checkbox
          key={value}
          isSelected={fieldValue.includes(value)}
          onChange={(isSelected) => {
            if (isSelected) {
              onChange(uniq([...fieldValue, value]));
            } else {
              onChange(without(fieldValue, value));
            }
          }}
        >
          {label}
        </Checkbox>
      ))}
    </div>
  );
}
