import type { TemplateDelegate } from 'pages/patients/patientDetails/ui/Notes/NoteEditor/templates/types';
import type { WizardFormData } from 'shared/common/Wizard/state';

import { engine } from './engine';
import type { CnTemplateContext } from './types';

type TemplateCtx = WizardFormData & CnTemplateContext;

export function markdownFieldTemplate(
  template: TemplateSpecification,
): TemplateDelegate<string, TemplateCtx> {
  return (context: TemplateCtx) =>
    postprocessMarkdown(engine.template(template)(context));
}

function postprocessMarkdown(markdown: string) {
  // In Athena, if there are multiple newlines separating bullet lists,
  // then it will break formatting, need to join them back together
  return markdown.replace(/(\n\s*[-*]\s+)+/g, '\n- ');
}
