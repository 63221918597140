import { addDays, subDays, subMonths, subWeeks, subYears } from 'date-fns';
import { useMemo, useState } from 'react';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import type { SelectChangeEvent } from 'shared/common/Select';
import { Option, Select } from 'shared/common/Select';

type TimeRangeDropdownProps = {
  onStartDateSelect: (startDate: Date) => void;
};

function getDropdownOptions(intl: IntlShape) {
  return {
    WEEK: {
      name: intl.formatMessage({ defaultMessage: '1 WEEK' }),
      value: subWeeks(new Date(), 1),
    },
    '2WEEKS': {
      name: intl.formatMessage({ defaultMessage: '2 WEEKS' }),
      value: subWeeks(new Date(), 2),
    },
    MONTH: {
      name: intl.formatMessage({ defaultMessage: '30 DAYS' }),
      value: subDays(new Date(), 30),
    },
    '3MONTHS': {
      name: intl.formatMessage({ defaultMessage: '3 MONTHS' }),
      value: subMonths(new Date(), 3),
    },
    '6MONTHS': {
      name: intl.formatMessage({ defaultMessage: '6 MONTHS' }),
      value: subMonths(new Date(), 6),
    },
    YEAR: {
      name: intl.formatMessage({ defaultMessage: '1 YEAR' }),
      value: subYears(new Date(), 1),
    },
  };
}

type DDTimeRangeOptionKey =
  | 'WEEK'
  | '2WEEKS'
  | 'MONTH'
  | '3MONTHS'
  | '6MONTHS'
  | 'YEAR';

export function TimeRangeDropdown({
  onStartDateSelect,
}: TimeRangeDropdownProps) {
  const intl = useIntl();
  const dropdownOptions = useMemo(() => getDropdownOptions(intl), [intl]);
  const timeRangeOptionKeys = Object.keys(
    dropdownOptions,
  ) as Array<DDTimeRangeOptionKey>;

  const [selectedRange, setSelectedRange] = useState<DDTimeRangeOptionKey>(
    timeRangeOptionKeys[0],
  );

  const handleTimeRangeSelectChange = (
    event: SelectChangeEvent<DDTimeRangeOptionKey>,
  ) => {
    const timeRange = event.target.value as DDTimeRangeOptionKey;
    setSelectedRange(timeRange);
    // Adding one day to get a proper range of e.g. 7 days including today
    // instead of 8 day range.
    onStartDateSelect(addDays(dropdownOptions[timeRange].value, 1));
  };

  return (
    <Select
      id="timeRangeDropdown"
      customStyle={{ flex: 1, textAlign: 'center' }}
      onChange={handleTimeRangeSelectChange}
      value={selectedRange}
    >
      {timeRangeOptionKeys.map((value) => (
        <Option key={value} value={value}>
          {dropdownOptions[value].name}
        </Option>
      ))}
    </Select>
  );
}
