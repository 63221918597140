import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { FieldGroup } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { useRecentNoteFormData } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useRecentNoteFormData';
import { useTimeEstimate } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Form } from 'shared/common/Form';
import { Wizard } from 'shared/common/Wizard';
import { useWizardFormFromConfig } from 'shared/common/Wizard/state';
import { useShowVitalsEducation } from 'shared/common/Wizard/state/sectionUtils';
import { display } from 'shared/jsStyle/utils.css';

import { initVisitPath } from '../../initialVisit/paths';
import { REGULAR_VISIT_SECTIONS } from '../../metadata';
import {
  type PatientGoalFormFields,
  getPatientGoalFormConfig,
} from '../../shared/setPatientGoal';
import { PatientGoalRadioSection } from '../../shared/setPatientGoal/PatientGoalRadioSection';
import { regVisitPath } from '../paths';

export function SetGoal() {
  const intl = useIntl();
  const history = useHistory();

  const showVitalsEducation = useShowVitalsEducation();

  const path = regVisitPath('/action-plan', '/set-goal');
  const { isLoading, data } = useRecentNoteFormData<PatientGoalFormFields>([
    path,
    initVisitPath('/action-plan', '/set-goal'),
  ]);
  const form = useWizardFormFromConfig<PatientGoalFormFields>(path, {
    ...getPatientGoalFormConfig(intl, showVitalsEducation, data),
    triggerReset: !isLoading,
  });

  const patientGoal = form.watch('patientGoal');
  const isNewPatientGoal =
    data?.patientGoal && data.patientGoal !== patientGoal;

  return (
    <Wizard.Step
      sections={REGULAR_VISIT_SECTIONS}
      form={form}
      title={intl.formatMessage({ defaultMessage: 'Patient Goal' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => {
        history.push(regVisitPath('/action-plan', '/education-modules-review'));
      }}
    >
      <FieldGroup>
        <PatientGoalRadioSection hasPreviousGoal={!!data?.patientGoal} />
        {/* Note: Remove in cnActionPlan template helper when showVitalsEducation is fully rolled out */}
        {(!showVitalsEducation || form.getValues('newGoalReason')) &&
          isNewPatientGoal && (
            <Form.TextField
              size={12}
              name="newGoalReason"
              label={<FormattedMessage defaultMessage="Reason for new goal" />}
              required
            />
          )}

        <Form.TextField
          label=""
          name="previousPatientGoal"
          className={display.none}
        />
      </FieldGroup>
    </Wizard.Step>
  );
}
