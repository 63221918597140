import cx from 'classnames';
import set from 'lodash/set';
import type { FieldError } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import PlusIcon from 'shared/assets/svgs/plus.svg?react';
import type { ConfiguredForm } from 'shared/common/Form/FormContainer';
import {
  BaseField,
  type BaseFieldProps,
} from 'shared/common/Form/fields/BaseField';
import { flexContainer } from 'shared/jsStyle/flex.css';
import { gap } from 'shared/jsStyle/gap.css';
import { Button } from 'shared/tempo/atom/Button';

import type { CNSectionMap } from '../../metadata';
import { ActionStep } from './ActionStep';
import { addStepButton } from './ActionStepsField.css';
import type { TActionStep } from './SharedSetActionSteps';
import type { SetActionStepsFields } from './formConfig';

type Props = {
  form: ConfiguredForm<SetActionStepsFields>;
  sections: CNSectionMap;
} & Omit<BaseFieldProps, 'size'>;

export type Field = 'step' | 'frequency' | 'dependencies';

export function ActionStepsField({ form, sections, ...baseProps }: Props) {
  return (
    <BaseField {...baseProps} size={12}>
      {({ renderError, controller: { field, fieldState } }) => {
        // Trigger error rendering override
        renderError();
        const validation = Array.isArray(fieldState.error)
          ? (fieldState.error as Record<Field, FieldError>[])
          : null;

        const actionSteps = field.value as TActionStep[];
        return (
          <>
            <div className={cx(flexContainer.column, gap.M)}>
              {field.value?.map((actionStep: TActionStep, idx: number) => (
                <ActionStep
                  key={idx}
                  stepNumber={idx}
                  actionStep={actionStep}
                  validation={validation?.[idx]}
                  onChange={(name, val) => {
                    const updatedSteps = [...actionSteps];
                    set(updatedSteps, [idx, name], val);
                    field.onChange(updatedSteps);
                  }}
                  onRemove={() => {
                    field.onChange([
                      ...actionSteps.slice(0, idx),
                      ...actionSteps.slice(idx + 1),
                    ]);
                  }}
                />
              ))}
            </div>
            <div className={addStepButton}>
              {actionSteps.length < 3 && (
                <Button
                  variant="tertiary"
                  onPress={() =>
                    field.onChange([
                      ...actionSteps,
                      {
                        step: undefined,
                        frequency: undefined,
                        dependencies: undefined,
                      },
                    ])
                  }
                >
                  <Button.Icon>
                    <PlusIcon />
                  </Button.Icon>
                  <FormattedMessage defaultMessage="Add Action Step" />
                </Button>
              )}
            </div>
          </>
        );
      }}
    </BaseField>
  );
}
