import type { ReactElement } from 'react';

import { Condition } from 'shared/generated/grpcGateway/pms.pb';

export enum VitalAverageLevel {
  One = 'Level_1',
  Two = 'Level_2',
  Three = 'Level_3',
  Four = 'Level_4',
  Five = 'Level_5', // May not be needed because patients at this level should be elevated to an NP
  Unknown = 'Unknown',
}

export enum ConditionProgram {
  T2D_AND_HYPERTENSION = 'T2D_AND_HYPERTENSION',
  HYPERTENSION = Condition.HYPERTENSION,
  TYPE_2_DIABETES = Condition.TYPE_2_DIABETES,
  HEART_FAILURE = Condition.HEART_FAILURE,
}

export type VitalAvgLevelScriptMap = Record<
  VitalAverageLevel,
  (ctx: ScriptContext) => JSX.Element | JSX.Element[]
>;

export type ScriptContext = {
  vitalAvg: string | null;
  conditionProgram: ConditionProgram;
  clinicalGoal: string;
  secondaryClinicalGoal?: string;
};

export type ConditionScripts = Record<
  ConditionProgram,
  VitalAvgLevelScriptMap | ScriptFn
>;

export type ScriptFn = (ctx: ScriptContext) => ReactElement | ReactElement[];
