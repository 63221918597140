import {
  useShouldMoveUpActionPlan,
  useShowVitalsEducation,
} from 'shared/common/Wizard/state/sectionUtils';

import { initVisitPath } from '../paths';

export function useDeviceUsageNextPath() {
  const shouldMoveUpActionPlan = useShouldMoveUpActionPlan();
  const showVitalsEducation = useShowVitalsEducation();

  if (shouldMoveUpActionPlan) {
    return showVitalsEducation
      ? initVisitPath('/vital-progress', '/intro')
      : initVisitPath('/action-plan', '/script');
  }
  return initVisitPath('/med-review', '/index');
}
