import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { MultiselectDropdown } from 'shared/common/MultiselectDropdown';

import type { BaseFieldProps } from '../BaseField';
import { BaseField } from '../BaseField';

type Props = {
  allOptionsLabel?: ReactNode;
  items: Array<{ value: string; label: string }>;
  disabled?: boolean;
} & BaseFieldProps;

export function MultiSelect({
  allOptionsLabel,
  disabled,
  items,
  ...baseProps
}: Props) {
  return (
    <BaseField {...baseProps}>
      {({ controller: { field, fieldState } }) => (
        <MultiselectDropdown
          hasError={Boolean(fieldState.error)}
          isDisabled={disabled}
          filter={field.value}
          setFilter={field.onChange}
          items={items}
          allLabel={
            allOptionsLabel ?? <FormattedMessage defaultMessage="All options" />
          }
        />
      )}
    </BaseField>
  );
}
