import isEmpty from 'lodash/isEmpty';

import { type StepFormValues } from 'shared/common/Wizard/state';

import { useCNFormContext } from '../CNWizardForm/CNFormContext';
import { useMostRecentPublishedNote } from './query.hooks';

export function useRecentNoteFormData<T extends StepFormValues>(
  path: string | string[],
): { isLoading: boolean; data: T | undefined } {
  const paths = Array.isArray(path) ? path : [path];
  let foundPath: Maybe<string>;
  const { patientId } = useCNFormContext();
  const { note, isLoading } = useMostRecentPublishedNote(
    patientId,
    true,
    (currNote) => {
      const cnNote = currNote.clinical_navigator_note?.[0];
      const stepData = cnNote?.form?.data;

      if (!stepData) {
        return false;
      }

      for (let i = 0; i < paths.length; i++) {
        // If step has any partial data
        if (!isEmpty(stepData[paths[i]]?.values)) {
          foundPath = paths[i];
          return true;
        }
      }

      return false;
    },
  );

  return {
    isLoading,
    data: foundPath
      ? (note?.clinical_navigator_note?.[0]?.form?.data[foundPath]?.values as T)
      : undefined,
  };
}
