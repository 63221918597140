import type { FC } from 'react';

import type { SxProps } from 'deprecated/mui';
import { color, typography } from 'shared/tempo/theme';

import { TableCell, TableHead, TableRow } from './index';

export type TableHeaderProps = {
  columnHeaders: {
    message: string;
    style?: SxProps;
  }[];
  tableCellStyle?: SxProps;
};

export const TableHeader: FC<TableHeaderProps> = ({
  columnHeaders,
  tableCellStyle,
}) => (
  <TableHead>
    <TableRow>
      {columnHeaders.map(({ message, style }) => (
        <TableCell
          key={message}
          sx={
            {
              ...(tableCellStyle ?? defaultTableCellStyle),
              ...style,
            } as SxProps
          }
        >
          {message}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const defaultTableCellStyle = {
  textAlign: 'left',
  borderBottom: 'none',
  paddingBottom: '2px',
  textTransform: 'uppercase',
  ...typography.Action.Small,
  color: color.Theme.Light['Base Font Subtle'],
};
