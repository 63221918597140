import cx from 'classnames';
import type { ForwardedRef } from 'react';
import { forwardRef, useCallback } from 'react';

import CheckIcon from 'shared/assets/svgs/checkMark.svg?react';
import { TableCell, TableRow } from 'shared/common/@deprecated/Table';
import { useFlags } from 'shared/hooks';
import {
  AlertPriority,
  containsVerifiedAlert,
  getHighestAlertPriority,
} from 'shared/patient/alerts';
import { getRpmConditionsFromProgramAndStatus } from 'shared/patient/conditions.utils';
import { hasAlertForReading } from 'shared/patient/hasAlert.util';
import { displayPatientLabel } from 'shared/patient/patientInfoUtils';
import type { Patient } from 'shared/types/patient.types';
import type { PatientSummaryReading } from 'shared/types/patientSummary.types';

import {
  BGReading,
  BPReading,
  HRReading,
  WeightReading,
} from '../VitalReading';
import {
  getBgReadings,
  getBpReadings,
  getDate,
  getHeartRateReadings,
  getWeightReadings,
} from '../summaryUtils';
import { ConditionTags } from './ConditionTags';
import { PatientAlertsStatusIcon } from './PatientAlertsStatusIcon';
import {
  checkIcon,
  readingCell,
  statusCell,
  tableCell,
} from './PatientsTableRow.css';

type PatientTableRowProps = {
  patient: Patient;
  hasActiveAlert: boolean;
  onClick: () => void;
};

export const PatientTableRow = forwardRef(
  (
    { patient, hasActiveAlert, onClick }: PatientTableRowProps,
    ref?: ForwardedRef<HTMLTableRowElement> | null,
  ) => {
    const { validatedAlerts } = useFlags();

    const tableCellStyle = tableCell({
      validated:
        hasActiveAlert &&
        validatedAlerts &&
        containsVerifiedAlert(patient.active_vitals_alerts),
      priority: hasActiveAlert
        ? getHighestAlertPriority(patient.active_vitals_alerts)
        : AlertPriority.None,
    });

    const hasAlert = useCallback(
      (reading?: PatientSummaryReading) =>
        !!reading &&
        hasAlertForReading(
          reading.reading_id || '',
          reading.data_type,
          patient.active_vitals_alerts,
        ),
      [patient.active_vitals_alerts],
    );

    const bpReading = getBpReadings(patient.patient_summary);
    const hrReading = getHeartRateReadings(patient.patient_summary);
    const weightReading = getWeightReadings(patient.patient_summary);
    const bgReading = getBgReadings(patient.patient_summary);

    return (
      <TableRow hover onClick={onClick} sx={{ cursor: 'pointer' }} ref={ref}>
        <TableCell className={cx(tableCellStyle, statusCell)}>
          {hasActiveAlert ? (
            <PatientAlertsStatusIcon alerts={patient.active_vitals_alerts} />
          ) : (
            <CheckIcon className={checkIcon} />
          )}
        </TableCell>
        <TableCell className={tableCellStyle}>
          {displayPatientLabel(patient)}
          <ConditionTags
            conditions={getRpmConditionsFromProgramAndStatus(
              patient.programs,
              patient.status,
            )}
          />
        </TableCell>
        <TableCell className={tableCellStyle}>
          {patient.reading_timestamp && getDate(patient.reading_timestamp)}
        </TableCell>
        <TableCell className={cx(tableCellStyle, readingCell)}>
          {bpReading ? (
            <BPReading
              systolic={bpReading.last_reading.systolic}
              diastolic={bpReading.last_reading.diastolic}
              tags={bpReading.tags}
              hasAlert={hasAlert(bpReading as PatientSummaryReading)}
              isReadingSuppressed={!!bpReading.is_suppressed}
            />
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell className={cx(tableCellStyle, readingCell)}>
          {hrReading ? (
            <HRReading
              pulse={hrReading.last_reading.pulse}
              tags={hrReading.tags}
              hasAlert={hasAlert(hrReading as PatientSummaryReading)}
              isReadingSuppressed={!!hrReading.is_suppressed}
            />
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell className={cx(tableCellStyle, readingCell)}>
          {weightReading ? (
            <WeightReading
              weight={weightReading.last_reading.weight}
              tags={weightReading.tags}
              hasAlert={hasAlert(weightReading as PatientSummaryReading)}
              isReadingSuppressed={!!weightReading.is_suppressed}
            />
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell className={cx(tableCellStyle, readingCell)}>
          {bgReading ? (
            <BGReading
              glucoseLevel={bgReading.last_reading.glucose_level}
              tags={bgReading.tags}
              hasAlert={hasAlert(bgReading as PatientSummaryReading)}
              isReadingSuppressed={!!bgReading.is_suppressed}
            />
          ) : (
            '-'
          )}
        </TableCell>
      </TableRow>
    );
  },
);
