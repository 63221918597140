import { FormattedMessage } from 'react-intl';

import SettingsIcon from 'shared/assets/svgs/settings.svg?react';
import { Button } from 'shared/tempo/atom/Button';

import { useCNFormContext } from '../../../CNFormContext';
import { adminProfileButton } from './AdminProfileButton.css';

export function AdminProfileButton() {
  const { patientId } = useCNFormContext();

  return (
    <Button
      size="small"
      variant="secondary"
      className={adminProfileButton}
      onPress={() =>
        window.open(`/admin/patient/${patientId}/profile`, '_blank')
      }
    >
      <Button.Icon>
        <SettingsIcon />
      </Button.Icon>
      <FormattedMessage defaultMessage="Patient Admin" />
    </Button>
  );
}
