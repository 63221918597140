import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { compose } from 'deprecated/recompose';
import BackButtonIcon from 'shared/assets/icons/BackButtonIcon';
import TextField from 'shared/common/@deprecated/TextField';
import { emailValidation, required } from 'shared/common/FormValidation';
import { withToaster } from 'shared/common/withToaster';
import { Button } from 'shared/tempo/atom/Button';
import { getErrorMsg } from 'shared/utils/helpers';
import type { RootState } from 'store/redux.types';

import { forgotPassword } from '../../actionCreators';
import { AuthPageWrapper } from '../shared';
import { bigButton } from '../shared/auth.css';
import './ForgotPasswordPage.scss';
import type { TForgotPasswordPage } from './TForgotPasswordPage';

export class ForgotPasswordPageComponent extends Component<TForgotPasswordPage> {
  constructor(props: TForgotPasswordPage) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidUpdate(prevProps: TForgotPasswordPage) {
    const { location, history, isEmailSent, forgotPasswordError, toaster } =
      this.props;
    const { from } = location.state || {
      from: { pathname: '/forgot-password-success' },
    };

    if (isEmailSent && isEmailSent !== prevProps.isEmailSent) {
      history.replace(from.pathname);
    }

    if (
      forgotPasswordError !== prevProps.forgotPasswordError &&
      forgotPasswordError !== null
    ) {
      toaster?.error(getErrorMsg(forgotPasswordError));
    }
  }

  submit(values: any) {
    const { dispatchForgotPassword } = this.props;

    dispatchForgotPassword(values.email);
  }

  render() {
    const { pristine, submitting, handleSubmit } = this.props;

    return (
      <AuthPageWrapper>
        <div>
          <Link className="login-form-link" to="/login/password">
            <BackButtonIcon />
          </Link>
        </div>
        <div className="forgot-password-title">
          <FormattedMessage defaultMessage="Forgot your password?" />
          <div className="forgot-password-form-title-content">
            <FormattedMessage defaultMessage="Enter your email and we'll send you a link to get back into your account." />
          </div>
        </div>
        <form
          data-testid="forgot-password--form"
          onSubmit={handleSubmit(this.submit)}
        >
          <div>
            <Field
              name="email"
              component={TextField}
              placeholder="Company email"
              type="email"
              customStyle={{
                width: '400px',
              }}
              fullWidth
              validate={[required, emailValidation]}
            />
          </div>
          <div className="forgot-password-sections">
            <Button
              id="forgot-password--form-submit"
              variant="primary"
              type="submit"
              isDisabled={pristine || submitting}
              className={bigButton}
            >
              <FormattedMessage defaultMessage="Send reset password link" />
            </Button>
          </div>
        </form>
      </AuthPageWrapper>
    );
  }
}

const selector = formValueSelector('forgot-password--form');

const mapStateToProps = (state: RootState) => ({
  isEmailSent: state.auth.isEmailSent,
  forgotPasswordError: state.auth.error,
  email: selector(state, 'email'),
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchForgotPassword: (email: string) => {
    dispatch(forgotPassword(email));
  },
});

const enhance = compose(
  withToaster,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'forgot-password--form',
    enableReinitialize: true,
  }),
);

export const ForgotPasswordPage = enhance(ForgotPasswordPageComponent);
