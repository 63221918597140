import { FormattedMessage, useIntl } from 'react-intl';

import type { ReportIssueRequestCategory } from 'shared/generated/grpcGateway/issue_reporter.pb';
import { Link } from 'shared/tempo/atom/Link';

import { ticketCtaContainer } from './SupportModal.css';
import { BASE_ROUTE } from './useCategories';

type Props = {
  category: ReportIssueRequestCategory;
};

export function TicketCta({ category }: Props) {
  const intl = useIntl();

  return (
    <div className={ticketCtaContainer}>
      <FormattedMessage defaultMessage="Can't find what you're looking for? " />
      <Link.Routed to={`${BASE_ROUTE}/report?category=${category}`}>
        {intl.formatMessage({ defaultMessage: 'Submit a support request' })}
      </Link.Routed>
    </div>
  );
}
