import { endOfDay, formatISO, isValid, parse, startOfDay } from 'date-fns';
import type { QueryKey, UseInfiniteQueryOptions } from 'react-query';
import { useInfiniteQuery, useQuery } from 'react-query';

import { PatientService } from 'shared/generated/api/pms';
import { TelemetryService } from 'shared/generated/grpcGateway/telemetry.pb';
import type {
  DerivePatientVitalsEngagementRequest,
  DerivePatientVitalsEngagementResponse,
  ListPatientVitalsResponseVital,
} from 'shared/generated/grpcGateway/telemetry.pb';
import type { AipPaginatedData } from 'shared/types/pagination.types';
import { formatStringToGoogleDate } from 'shared/utils/grpc';

import { patientKeys } from './patients.queries';

const PATIENT_VITALS_QUERY_BASE = ['telemetry', 'v1'] as const;
const vitalsKeys = {
  patient: (patientId: string) =>
    [...PATIENT_VITALS_QUERY_BASE, patientId, 'vitals'] as const,
};
export type PaginatedVitals = AipPaginatedData<
  ListPatientVitalsResponseVital,
  'data'
>;

type PatientVitalsParams = {
  patientId: Maybe<string>;
  dateFrom: string;
  dateTo: string;
};

export const PATIENTS_VITALS_QUERY_KEY_BASE = [
  'data',
  'api',
  'v1',
  'summary',
  'vitals',
];

export function useGetAlertsSummary({
  patientId,
  dateFrom,
  dateTo,
}: PatientVitalsParams) {
  return useQuery({
    queryKey: patientKeys.vitalsAlerts({
      patient_id: patientId || '',
      date_from: dateFrom,
      date_to: dateTo,
    }),
    queryFn: () =>
      PatientService.getPmsApiV1PatientsVitalsAlerts(
        patientId || '',
        dateTo,
        dateFrom,
      ),
    refetchOnWindowFocus: true,
    enabled: !!patientId,
  });
}

export function useListPatientVitals(
  { patientId, dateFrom, dateTo }: PatientVitalsParams,
  config?: UseInfiniteQueryOptions<PaginatedVitals>,
) {
  const start = tryParseDate(dateFrom);
  const end = tryParseDate(dateTo);
  const startOfDateTo = start ? formatISO(startOfDay(start)).trim() : '';
  const endOfDateTo = end ? formatISO(endOfDay(end)).trim() : '';

  const hasDateRanges = startOfDateTo && endOfDateTo;
  const filter = hasDateRanges
    ? `timestamp > '${startOfDateTo}' AND timestamp <= '${endOfDateTo}'`
    : '';

  return useInfiniteQuery<PaginatedVitals>(
    [...vitalsKeys.patient(patientId || ''), dateFrom, dateTo] as QueryKey,
    {
      ...config,
      enabled: Boolean(patientId),
      queryFn: async (ctx) => {
        const response = await TelemetryService.ListPatientVitals({
          parent: `patients/${patientId}`,
          filter,
          orderBy: 'timestamp desc',
          pageToken: ctx.pageParam,
        });

        return {
          data:
            (response.patientVitals as ListPatientVitalsResponseVital[]) ?? [],
          nextPageToken: response.nextPageToken ?? '',
          totalSize: response.totalSize,
        };
      },
    },
  );
}

const tryParseDate = (dateStr: string, defaultDate = new Date()) => {
  if (!dateStr) return undefined;
  const parsedDate = parse(dateStr, 'yyyy-MM-dd', defaultDate);
  return isValid(parsedDate) ? parsedDate : undefined;
};

export function useDeriveVitalsEngagement({
  patientId,
  dateFrom,
  dateTo,
}: PatientVitalsParams) {
  const requestPayload: DerivePatientVitalsEngagementRequest = {
    name: `patients/${patientId}`,
    startDate: formatStringToGoogleDate(dateFrom),
    endDate: formatStringToGoogleDate(dateTo),
  };

  return useQuery<DerivePatientVitalsEngagementResponse, Error>(
    ['deriveVitalsEngagement', patientId, dateFrom, dateTo],
    () => TelemetryService.DerivePatientVitalsEngagement(requestPayload),
    {
      enabled: !!patientId,
    },
  );
}
