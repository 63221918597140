import { FormattedMessage } from 'react-intl';

import { RichTextEditorWidget } from 'shared/common/@deprecated/SchemaDrivenForm/widgets';

import type {
  EncounterModuleInstance,
  GeneralAssessmentInputs,
} from '../../Notes.types';
import { EncounterModuleId } from '../../Notes.types';
import { useEncounterModule } from '../../note.queries';
import { NotesSchemaDrivenForm } from '../@deprecated/NotesSchemaDrivenForm';

type Props = {
  initialValues?: GeneralAssessmentInputs;
  onChange: (inputs: EncounterModuleInstance) => void;
  shouldShowValidation: boolean;
};

export function GeneralAssessmentAndPlanForm({
  initialValues,
  onChange,
  shouldShowValidation,
}: Props) {
  const { encounterModule, isLoading } = useEncounterModule(
    EncounterModuleId.GeneralAssessmentAndPlan,
  );

  if (!encounterModule || isLoading) {
    return null;
  }

  return (
    <NotesSchemaDrivenForm
      initialFormData={initialValues}
      schema={encounterModule.schema}
      onChange={(inputs: GeneralAssessmentInputs) => {
        onChange({
          encounter_module_id: encounterModule.id,
          inputs,
        });
      }}
      shouldShowValidation={shouldShowValidation}
      uiSchema={{
        assessment_body: {
          'ui:label': <FormattedMessage defaultMessage="Notes" />,
          // Using ui:field here because ui:widget doesn't get applied since property has type: "object"
          'ui:field': RichTextEditorWidget,
        },
      }}
    />
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export const ASSESSMENT_BODY_LABEL = (
  <FormattedMessage defaultMessage="Assessment and Plan notes" />
);
