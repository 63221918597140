import { format, set } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { logger } from 'logger';

type Prefixed<I extends string, P extends string> = `${P}/${I}`;
type Suffixed<I extends string, S extends string> = `${I}/${S}`;

export function idToGrpcName<P extends string, I extends string>(
  prefix: P,
  id: I,
): `${P}/${I}`;
export function idToGrpcName<
  P extends string,
  I extends string,
  S extends string,
>(prefix: P, id: I, suffix: S): Suffixed<Prefixed<I, P>, S>;
export function idToGrpcName<
  P extends string,
  I extends string,
  S extends string,
>(prefix: P, id: I, suffix?: S): Prefixed<I, P> | Suffixed<Prefixed<I, P>, S> {
  let name = '';
  if (!id.startsWith(prefix)) {
    name = `${prefix}/${id}`;
  }
  if (suffix && !id.endsWith(suffix)) {
    name = `${name}/${suffix}`;
  }
  return name as Prefixed<I, P> | Suffixed<Prefixed<I, P>, S>;
}

export function grpcNameToId(name: string) {
  const id = name.split('/').pop();
  if (!id) {
    logger.error(`Failed to parse id from gRPC name: '${name}'`);
  }
  return id;
}

export function parseGrpcDate({ year, month, day }: GoogleDate) {
  return new Date(year, month - 1, day);
}

/*
 * Parses a GoogleDateTime date with timezone into a JS Date and ensures the time is in UTC.
 */
export function parseGrpcDateTimeToUtc(googleDateTime: GoogleDateTime) {
  const { year, month, day, hours, minutes, seconds, nanos } = googleDateTime;

  let timeZoneId: TimeZone | string = 'UTC';
  if (googleDateTime.timeZone) {
    timeZoneId = googleDateTime.timeZone.id;
  } else if (googleDateTime.utcOffset) {
    const { seconds: offsetSeconds } = googleDateTime.utcOffset;
    const offsetMinutes = parseInt(offsetSeconds, 10) / 60;
    // The UTC offset is applied by converting the total offset seconds into a timezone
    // string like Etc/GMT+5 or Etc/GMT-3, which is then used to adjust the date to UTC.
    timeZoneId = `Etc/GMT${offsetMinutes < 0 ? '' : '+'}${-offsetMinutes}`;
  }

  const date = set(new Date(0), {
    year,
    month: month - 1,
    date: day,
    hours,
    minutes,
    seconds,
    milliseconds: nanos / 1e6,
  });

  return zonedTimeToUtc(date, timeZoneId);
}

export function dateToGrpcDate(date: Date): GoogleDate {
  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
}

export function formatGoogleDateToString(googleDate: GoogleDate): string {
  const date = parseGrpcDate(googleDate);
  return format(date, 'yyyy-MM-dd');
}

export function formatStringToGoogleDate(date: string): GoogleDate {
  if (!date) {
    return { day: 0, month: 0, year: 0 };
  }
  const parsed = new Date(date);
  return dateToGrpcDate(parsed);
}
