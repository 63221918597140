import { type InputMask as IMaskInputMask } from 'imask';
import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

import type { InputBaseComponentProps } from 'deprecated/mui';

type MaskDefinitions = Record<string, string | RegExp>;
export type MaskWithDefinitions = {
  mask: string;
  definitions?: MaskDefinitions;
};

export type InputMaskEvent = {
  target: { name: string; value: string };
  maskRef: IMaskInputMask;
};

type InputMaskProps = {
  onChange?: (event: InputMaskEvent) => void;
  name: string;
  mask: string | MaskWithDefinitions;
};

export const InputMask = forwardRef<
  HTMLInputElement,
  InputBaseComponentProps & InputMaskProps
>(({ onChange, mask, ...rest }, ref) => {
  const inputMask = typeof mask === 'string' ? mask : mask.mask;
  const maskDefinitions = typeof mask === 'string' ? {} : mask.definitions;

  return (
    <IMaskInput
      mask={inputMask}
      definitions={maskDefinitions}
      lazy={false}
      inputRef={ref}
      onAccept={(value, maskRef) => {
        onChange?.({
          target: { name: rest.name, value: value as string },
          maskRef,
        });
      }}
      unmask
      overwrite
      {...rest}
    />
  );
});
