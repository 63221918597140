import type { ReactNode } from 'react';

import { Divider } from 'shared/common/Divider';

import {
  content,
  iconContainer,
  sectionContainer,
  sectionTitle,
  titleContainer,
  titleDivider,
} from './VisitLayoutSection.css';

type Props = {
  children: ReactNode;
  title: ReactNode;
  icon?: ReactNode;
};

export const VisitLayoutSection = ({ children, title, icon }: Props) => (
  <div className={sectionContainer}>
    <div className={titleContainer}>
      {icon && <div className={iconContainer}>{icon}</div>}
      <div className={sectionTitle}>{title}</div>
    </div>
    <Divider className={titleDivider} />
    <div className={content}>{children}</div>
  </div>
);
