import classnames from 'classnames/bind';

import Cross from 'shared/assets/svgs/cross.svg?react';
import Expand from 'shared/assets/svgs/expand.svg?react';
import Fullscreen from 'shared/assets/svgs/maximize.svg?react';
import DismissFullScreen from 'shared/assets/svgs/minimize.svg?react';
import RightChevron from 'shared/assets/svgs/rightChevron.svg?react';
import Shrink from 'shared/assets/svgs/shrink.svg?react';

import styles from '../styles.module.scss';
import { useSidePanelCtx } from './SidePanelContext';
import { SidePanelType } from './sidePanel.types';

const cx = classnames.bind(styles);

type Props = {
  subContent?: JSX.Element;
  right?: JSX.Element;
  allowFullscreen?: boolean;
  allowExpansion?: boolean;
  title: string;
  onHideSidePanel?: () => boolean;
};

export function Header({
  title,
  right,
  subContent,
  allowFullscreen = false,
  allowExpansion = true,
  onHideSidePanel,
}: Props) {
  const { state, handlers } = useSidePanelCtx();
  const { isExpanded, isFullscreen } = state;

  return (
    <div className={cx('container-header')}>
      <div className={cx('main-content')}>
        <div className={cx('header-left')}>
          {state.type === SidePanelType.Dockable ? (
            <button
              type="button"
              id="side-panel-collapse-button"
              onClick={() => {
                if (onHideSidePanel) {
                  const shouldHide = onHideSidePanel();
                  if (!shouldHide) {
                    return;
                  }
                }
                handlers.setIsVisible(false);
              }}
            >
              <RightChevron />
            </button>
          ) : (
            <button
              type="button"
              id="side-panel-dismiss-button"
              onClick={() => handlers.setIsVisible(false)}
            >
              <Cross />
            </button>
          )}
          <div className={cx('header-title')}>{title}</div>
        </div>
        <div className={cx('header-right')}>
          {allowFullscreen && (
            <button
              type="button"
              id="side-panel-fullscreen-button"
              className={cx('sm-button')}
              onClick={() => handlers.setIsFullscreen(!isFullscreen)}
            >
              {isFullscreen ? <DismissFullScreen /> : <Fullscreen />}
            </button>
          )}
          {allowExpansion && !isFullscreen && (
            <button
              type="button"
              id="side-panel-expand-button"
              onClick={() => handlers.setIsExpanded(!isExpanded)}
            >
              {isExpanded ? <Shrink /> : <Expand />}
            </button>
          )}
          {right}
        </div>
      </div>
      {subContent && <div className={cx('sub-content')}>{subContent}</div>}
    </div>
  );
}
