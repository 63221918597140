import type { TaskState, TaskType } from 'shared/generated/grpcGateway/task.pb';
import { createCtx } from 'shared/hooks/createCtx';
import type { TaskQuery } from 'shared/hooks/queries/tasks.queries';

import type { TabKey } from '../TabKey';

export type TeamTabAssignee = 'assigned' | 'unassigned';

type TaskFilterContext = {
  orderBy: string;
  onOrderByChange: (orderBy: string) => void;
  taskTypes: TaskType[];
  onTaskTypesChange: (taskTypes: TaskType[]) => void;
  teamTabAssignee: TeamTabAssignee;
  onTeamTabAssigneeChange: (teamTabAssignee: TeamTabAssignee) => void;
  getFilter: (tab: TabKey, state: TaskState) => TaskQuery;
};

export const [useTaskFilterContext, TaskFilterContextProvider] =
  createCtx<TaskFilterContext>('TaskFilterContext');
