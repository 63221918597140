import omit from 'lodash/omit';
import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import { useController } from 'react-hook-form';

import { Button } from 'shared/tempo/atom/Button';

import type { BaseFieldProps } from '../BaseField';
import { BaseField } from '../BaseField';
import { button, nativeInput } from './File.css';

type Props = {
  accept?: string;
  buttonLabel: ReactNode | string;
} & BaseFieldProps;

export type FileValue = {
  files?: FileList;
  path?: string;
};

export function File({ accept, buttonLabel, ...baseProps }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { field } = useController({ name: baseProps.name });

  // react doesn't expose onCancel so we have to subscribe manually
  useEffect(() => {
    const el = inputRef.current;
    const onCancel = () => {
      field.onBlur();
    };

    el?.addEventListener('cancel', onCancel);

    return () => el?.removeEventListener('cancel', onCancel);
  }, [field, inputRef]);

  return (
    <BaseField {...baseProps}>
      {({ controller }) => (
        <>
          <input
            type="file"
            // programmatically setting value on a file input
            // makes stuff blow up, so leave it out of the spread.
            // setting the value doesn't matter here as we're
            // tracking it separately from the input itself.
            {...omit(controller.field, 'value')}
            accept={accept}
            ref={inputRef}
            onChange={(e) => {
              controller.field.onChange({
                target: {
                  value: { files: e.target.files, path: e.target.value },
                },
              });
            }}
            className={nativeInput}
          />
          <Button
            size="small"
            variant="secondary"
            className={button}
            onPress={() => inputRef.current?.click()}
          >
            {buttonLabel}
          </Button>
        </>
      )}
    </BaseField>
  );
}
