import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import CircleSuccessIcon from 'shared/assets/svgs/circle-success.svg?react';
import {
  ResourceTableContainer,
  ResourceTableTitle,
} from 'shared/common/ResourceTableContainer';
import type { Patient } from 'shared/types/patient.types';

import { usePatientListCtx } from '../PatientListContext';
import { PatientListEmptyResults } from '../PatientListEmptyResults';
import { PatientsTable } from '../PatientsTable';
import { usePatientsInfiniteQuery } from '../hooks/queries.hooks';
import {
  PatientSortSelect,
  usePriorityPatientsSortOptions,
} from './PatientsSortSelect';
import { tableContainer } from './tableContainer.css';

type Props = {
  onClickPatient: (patient: Patient) => void;
};

export function PatientsWithActiveAlerts({ onClickPatient }: Props) {
  const intl = useIntl();
  const sortOptions = usePriorityPatientsSortOptions();
  const defaultSortOption = sortOptions[0];
  const [sortOption, setSortOption] = useState(defaultSortOption);

  const {
    patientsInfiniteQuery,
    patients: fetchedPatients,
    isEmptyResult,
  } = usePatientsInfiniteQuery(
    true,
    { sortBy: sortOption.sortBy, orderBy: sortOption.orderBy },
    { refetchOnWindowFocus: true },
  );

  const { patients, setPatients } = usePatientListCtx();
  useEffect(() => {
    setPatients(fetchedPatients);
  }, [fetchedPatients, setPatients]);

  return (
    <ResourceTableContainer
      className={tableContainer}
      header={
        <>
          <ResourceTableTitle>
            <FormattedMessage defaultMessage="Active alerts" />
          </ResourceTableTitle>
          {!isEmptyResult && (
            <PatientSortSelect
              onChange={setSortOption}
              options={sortOptions}
              selected={sortOption}
            />
          )}
        </>
      }
    >
      {isEmptyResult ? (
        <PatientListEmptyResults
          icon={<CircleSuccessIcon />}
          message={intl.formatMessage({
            defaultMessage: "There aren't any patients with active alerts",
          })}
        />
      ) : (
        <PatientsTable
          hasActiveAlert
          patients={patients}
          onClickPatient={onClickPatient}
          onPageRequested={patientsInfiniteQuery.fetchNextPage}
          isFetching={patientsInfiniteQuery.isFetching}
          hasNextPage={patientsInfiniteQuery.hasNextPage ?? false}
        />
      )}
    </ResourceTableContainer>
  );
}
