import type { IntlShape } from 'react-intl';

import { getEmergencyContactRelationshipI18n } from 'shared/i18n';
import type { Patient } from 'shared/types/patient.types';
import { PhoneType } from 'shared/types/shared.types';

export function getI18nPhoneType(
  intl: IntlShape,
  type: Maybe<PhoneType | 'LANDLINE' | 'CELLULAR'>,
  isCaregiver: boolean = false,
) {
  if (!type) {
    return null;
  }
  let i18nPhone = null;
  switch (type) {
    case PhoneType.Landline:
    case 'LANDLINE':
      i18nPhone = intl.formatMessage({ defaultMessage: 'home' });
      break;
    case PhoneType.Cellular:
    case 'CELLULAR':
      i18nPhone = intl.formatMessage({ defaultMessage: 'mobile' });
      break;
    default:
      i18nPhone = null;
  }
  const caregiverText = isCaregiver
    ? intl.formatMessage({ defaultMessage: 'caregiver' })
    : null;
  const joined = [caregiverText, i18nPhone].filter(Boolean).join(' ');
  return joined ? `(${joined})` : null;
}

export function getEmergencyContactText(patient: Patient, intl: IntlShape) {
  const emergencyContactRelationshipI18n =
    getEmergencyContactRelationshipI18n(intl);
  const emergencyContact = patient?.contacts?.find(
    ({ emergency }) => emergency,
  );
  if (!emergencyContact) {
    return null;
  }
  const { relationship } = emergencyContact;
  const name = emergencyContact.contact?.name;
  if (!relationship || relationship === 'OTHER') {
    return name || null;
  }

  return (
    [name, emergencyContactRelationshipI18n[relationship]]
      .filter(Boolean)
      .join(', ') || null
  );
}
