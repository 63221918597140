import type { PropsWithChildren, ReactNode } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { settings } from 'config';
import { logger } from 'logger';
import { useOnMount } from 'shared/hooks/useOnMount';

import { acuityIframeContainer, content } from './SchedulePatientModal.css';
import { getEmbeddedAcuityIframeUrl } from './acuityIframeUrl.utils';
import type { AcuityIframeUrlParams } from './types';

interface Props {
  children: ReactNode;
  iframeParams: AcuityIframeUrlParams;
}

export function EmbeddedAcuityView({
  children,
  iframeParams,
}: PropsWithChildren<Props>) {
  const baseUrl = settings.VITE_ACUITY_SCHEDULING_URL;

  useEffect(() => {
    if (!baseUrl) {
      logger.error(
        'Environment variable VITE_ACUITY_SCHEDULING_URL was expected but not found',
      );
    }
  }, [baseUrl]);

  const iframeUrl = baseUrl
    ? getEmbeddedAcuityIframeUrl(baseUrl, iframeParams)
    : '';

  useOnMount(() => {
    logger.debug('Acuity Iframe is being loaded.', {
      params: iframeParams,
      iframeUrl,
    });
  });

  if (!iframeUrl) {
    return (
      <div>
        <div>
          <FormattedMessage defaultMessage="Sorry, the scheduling service is currently unavailable." />
        </div>
        <div>
          <FormattedMessage defaultMessage="Please call the Cadence Care Team at 855-613-0778 between 8AM and 6PM to book an appointment." />
        </div>
      </div>
    );
  }

  return (
    <div className={content}>
      <div>{children}</div>
      <IframeContainer srcUrl={iframeUrl} />
    </div>
  );
}

function IframeContainer({ srcUrl }: { srcUrl: string }) {
  const intl = useIntl();
  return (
    <div className={acuityIframeContainer}>
      <iframe
        src={srcUrl}
        title={intl.formatMessage({ defaultMessage: 'Schedule Appointment' })}
        width="100%"
        height="100%"
        frameBorder="0"
      />
    </div>
  );
}
