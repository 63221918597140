import groupBy from 'lodash/groupBy';
import type { AnySchema } from 'yup';
import { ValidationError as YupValidationError, object } from 'yup';

import type { FormConfig } from 'shared/common/Form';

import type { EncounterModuleId } from '../../Notes.types';
import type { NoteFormValues } from '../noteFormState';
import type { NoteFormSubmissionType } from './formConfig';

export type NoteFormValidationResult = Record<
  keyof NoteFormValues,
  NoteFieldValidationResult[]
>;
export type NoteFieldValidationResult = {
  message: string;
  path?: string;
  type?: string;
  params?: Record<string, unknown>;
  encounterModuleId?: EncounterModuleId;
};

export function hasValidationErrors(
  validationResult: NoteFormValidationResult,
) {
  return Object.values(validationResult).some((fieldValidationResults) =>
    fieldValidationResults.some(Boolean),
  );
}

/**
 * A "bridge" between our standard Form/formConfig approach and current needs
 * of NoteEditor. Hopefully we'll remove most of this once we use our
 * useFormFromConfig in NoteEditor.
 */
export function useNoteEditorValidation(
  noteFormValues: NoteFormValues,
  formConfig: FormConfig,
  enableValidation: (submissionType: NoteFormSubmissionType) => FormConfig,
) {
  return {
    noteFormValidationResult: validateNoteForm(formConfig, noteFormValues),
    validate: (submissionType: NoteFormSubmissionType) => {
      const freshFormConfig = enableValidation(submissionType);
      return validateNoteForm(freshFormConfig, noteFormValues);
    },
  };
}

function validateNoteForm(
  formConfig: FormConfig,
  noteFormValues: NoteFormValues,
) {
  return validateNoteFormValues(
    noteFormValues,
    object(
      Object.fromEntries(
        Object.entries(formConfig.fields).map(([fieldName, fieldConfig]) => [
          fieldName,
          fieldConfig.validation as NonNullable<typeof fieldConfig.validation>,
        ]),
      ),
    ),
  );
}

function validateNoteFormValues(
  formValue: NoteFormValues,
  yupSchema: AnySchema,
) {
  let yupValidationError: Nullable<YupValidationError> = null;
  try {
    yupSchema.validateSync(formValue, { abortEarly: false });
  } catch (error) {
    if (error instanceof YupValidationError) {
      yupValidationError = error;
    } else {
      throw error;
    }
  }
  return getNoteFormValidationResult(yupValidationError);
}

function getNoteFormValidationResult(
  validationError: Nullable<YupValidationError>,
) {
  const validationResult: Partial<NoteFormValidationResult> =
    validationError === null
      ? {}
      : groupBy(getNoteFieldValidationResults(validationError), (result) =>
          result.encounterModuleId
            ? 'encounterModuleInstances'
            : result.path?.split('.')[0] ?? 'unknown_path',
        );
  return new Proxy(validationResult as NoteFormValidationResult, {
    get: (target, property: string) =>
      (target as Record<string, unknown>)[property] ?? [],
  });
}

function getNoteFieldValidationResults(
  validationError: YupValidationError,
): NoteFieldValidationResult[] {
  if (validationError.inner.length) {
    return validationError.inner.flatMap((innerValidationError) =>
      getNoteFieldValidationResults(innerValidationError),
    );
  }
  return validationError.errors.map((message) => ({
    message,
    path: validationError.path,
    type: validationError.type,
    params: validationError.params,
    encounterModuleId: validationError.params?.encounterModuleId as
      | EncounterModuleId
      | undefined,
  }));
}
