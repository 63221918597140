import cx from 'classnames';
import type { ReactNode } from 'react';
import { cloneElement, isValidElement } from 'react';

import { TableCell } from 'deprecated/mui';

import { tableCell } from './cells.css';

const EMPTY_PLACEHOLDER = '---';

type CellType = 'default' | 'actions';

export type BaseCellProps = {
  children?: ReactNode;
  type?: CellType;
  fallback?: string;
  className?: string;
  align?: 'left' | 'center' | 'right';
  colSpan?: number;
};

export function BaseCell({
  children,
  className,
  colSpan,
  type = 'default',
  align = 'left',
  fallback = EMPTY_PLACEHOLDER,
  ...rest
}: BaseCellProps) {
  return (
    <TableCell
      colSpan={colSpan}
      className={cx(
        {
          [tableCell.left]: align === 'left',
          [tableCell.center]: align === 'center',
          [tableCell.right]: align === 'right',
          [tableCell.actions]: type === 'actions',
        },
        className,
      )}
    >
      {(isValidElement(children) ? cloneElement(children, rest) : children) ||
        fallback}
    </TableCell>
  );
}
