import { FormattedMessage } from 'react-intl';

import { Modal } from 'shared/common/Modal';
import { ReportIssueRequestCategory } from 'shared/generated/grpcGateway/issue_reporter.pb';

import { SubHeader } from '../SubHeader';
import { TicketCta } from '../TicketCta';

export function PatientInfo() {
  return (
    <Modal.Body>
      <SubHeader>
        <FormattedMessage defaultMessage="Resources" />
      </SubHeader>
      <p>
        <FormattedMessage defaultMessage="You may edit patient information directly in the admin section of Cadence. If the save button is grayed out, please ensure that all required fields are populated." />
      </p>
      <TicketCta category={ReportIssueRequestCategory.CATEGORY_PATIENT_INFO} />
    </Modal.Body>
  );
}
