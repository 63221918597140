import { FormattedMessage } from 'react-intl';

import type { SelectChangeEvent } from 'deprecated/mui';
import { FormLabel } from 'deprecated/mui';

import { ErrorMessage } from '../ErrorMessage';
import { errorLabel, sectionLabel } from '../NoteEditor.css';
import { NoteLabelsDropdown } from '../NoteLabelsDropdown';
import { labelsContainer } from './VisitLayoutSection.css';

type Props = {
  hasLabelsError: boolean;
  labels: number[];
  onChange: (labels: number[]) => void;
};
export function LabelsFormField({ hasLabelsError, labels, onChange }: Props) {
  return (
    <div className={labelsContainer}>
      <FormLabel className={hasLabelsError ? errorLabel : sectionLabel}>
        <FormattedMessage defaultMessage="Labels" />
      </FormLabel>
      <NoteLabelsDropdown
        selectedLabels={labels}
        handleChangeMultiple={(event: SelectChangeEvent<number[]>) =>
          onChange(event.target.value as number[])
        }
        hasError={hasLabelsError}
      />
      <ErrorMessage hasError={hasLabelsError} />
    </div>
  );
}
