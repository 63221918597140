import cx from 'classnames';
import type { ReactNode } from 'react';

import { ButtonIcon } from 'shared/tempo/atom/Button/ButtonIcon';

import { segmentIconRef } from './SegmentIcon.css';

type Props = {
  className?: string;
  children: ReactNode;
};

export function SegmentIcon({ children, className }: Props) {
  return (
    <ButtonIcon className={cx(segmentIconRef, className)}>
      {children}
    </ButtonIcon>
  );
}
