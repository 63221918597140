import type { HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';

import { TypeaheadSearch } from 'shared/common/TypeaheadSearch';

import { withEhrWidth } from './patientSearch.css';

export const SearchResultsContainer = ({
  children,
}: HTMLAttributes<HTMLElement>) => (
  <>
    <TypeaheadSearch.ResultHeaderRow>
      <TypeaheadSearch.ResultHeaderCell className={withEhrWidth}>
        <FormattedMessage defaultMessage="Patient" />
      </TypeaheadSearch.ResultHeaderCell>
      <TypeaheadSearch.ResultHeaderCell className={withEhrWidth}>
        <FormattedMessage defaultMessage="Date of Birth" />
      </TypeaheadSearch.ResultHeaderCell>
      <TypeaheadSearch.ResultHeaderCell className={withEhrWidth}>
        <FormattedMessage
          defaultMessage="MRN"
          description="Abbreviation for Medical Record Number"
        />
      </TypeaheadSearch.ResultHeaderCell>
      <TypeaheadSearch.ResultHeaderCell className={withEhrWidth}>
        <FormattedMessage defaultMessage="Status" />
      </TypeaheadSearch.ResultHeaderCell>
      <>
        <TypeaheadSearch.ResultHeaderCell className={withEhrWidth}>
          <FormattedMessage defaultMessage="Hospital" />
        </TypeaheadSearch.ResultHeaderCell>
        <TypeaheadSearch.ResultHeaderCell className={withEhrWidth}>
          <FormattedMessage
            defaultMessage="EMR"
            description="Abbreviation for Electronic Medical Record"
          />
        </TypeaheadSearch.ResultHeaderCell>
      </>
    </TypeaheadSearch.ResultHeaderRow>
    <TypeaheadSearch.ResultBody>{children}</TypeaheadSearch.ResultBody>
  </>
);
