import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import CallingIcon from 'shared/assets/svgs/calling.svg?react';
import { usePatientDetails } from 'shared/hooks/queries';
import { Button } from 'shared/tempo/atom/Button';
import { IconButton } from 'shared/tempo/atom/IconButton';
import { Tooltip } from 'shared/tempo/atom/Tooltip';
import { useToaster } from 'shared/tempo/molecule/Toast';
import { getPatientZendeskUrl } from 'shared/utils/zendesk.utils';

import { useCreateNoteZendeskTicket } from '../note.queries';

type Props = {
  patientId: string;
  zendeskTicket: Maybe<number>;
  noteId?: Nullable<number>;
  variant: 'icon' | 'text';
  isDisabled?: boolean;
  disabledTooltipMessage?: ReactNode;
};

export function ZendeskNoteButton({
  patientId,
  zendeskTicket,
  noteId,
  isDisabled = false,
  disabledTooltipMessage,
  variant,
}: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const { isLoading, data: patientDetails } = usePatientDetails(
    patientId,
    true,
  );

  const showZendeskNoteSyncFailure = () =>
    toaster.error(
      intl.formatMessage({
        defaultMessage:
          'Could not create Zendesk ticket. Please create manually in Zendesk.',
      }),
    );

  const zendeskId = patientDetails?.patient?.zendeskId || '';

  const { mutate: createNoteZendeskTicket, isLoading: isProcessing } =
    useCreateNoteZendeskTicket(patientId, noteId ?? 0, {
      onSuccess(data) {
        if (data.zendesk_ticket_id) {
          window.open(getPatientZendeskUrl(zendeskId));
        } else {
          showZendeskNoteSyncFailure();
        }
      },
      onError() {
        showZendeskNoteSyncFailure();
      },
    });

  const hasNoteId = Boolean(noteId) || noteId === 0;
  const hasPatientZendeskId = Boolean(zendeskId);
  const ButtonCmp = variant === 'icon' ? IconButtonVariant : TextButtonVariant;

  return (
    <ButtonCmp
      tooltipMessage={getTooltipMessage(
        hasNoteId,
        hasPatientZendeskId,
        isDisabled,
        disabledTooltipMessage,
      )}
      isProcessing={isProcessing || isLoading}
      isDisabled={isDisabled || !hasNoteId || !hasPatientZendeskId}
      onPress={() => {
        if (zendeskTicket) {
          window.open(getPatientZendeskUrl(zendeskId));
        } else if (noteId) {
          createNoteZendeskTicket();
        } else {
          showZendeskNoteSyncFailure();
        }
      }}
    />
  );
}

type VariantProps = {
  tooltipMessage: Nullable<ReactNode>;
  isProcessing: boolean;
  isDisabled: boolean;
  onPress: () => void;
};

function IconButtonVariant({
  tooltipMessage,
  isProcessing,
  isDisabled,
  onPress,
}: VariantProps) {
  return (
    <IconButton.Tooltip content={tooltipMessage} placement="bottom-start">
      <IconButton
        size="small"
        isProcessing={isProcessing}
        isDisabled={isDisabled}
        onPress={onPress}
      >
        <CallingIcon />
      </IconButton>
    </IconButton.Tooltip>
  );
}

function TextButtonVariant({
  tooltipMessage,
  isProcessing,
  isDisabled,
  onPress,
}: VariantProps) {
  return (
    <Tooltip content={tooltipMessage} placement="bottom-start">
      <Button
        isProcessing={isProcessing}
        isDisabled={isDisabled}
        onPress={onPress}
      >
        <Button.Icon>
          <CallingIcon />
        </Button.Icon>
        <FormattedMessage defaultMessage="Call Patient" />
      </Button>
    </Tooltip>
  );
}

function getTooltipMessage(
  hasNoteId: boolean,
  hasPatientZendeskId: boolean,
  isDisabled: boolean,
  disabledTooltipMessage?: ReactNode,
) {
  if (isDisabled) {
    return disabledTooltipMessage;
  }

  if (!hasNoteId) {
    return <FormattedMessage defaultMessage="Save note to enable" />;
  }

  if (!hasPatientZendeskId) {
    return (
      <FormattedMessage defaultMessage="Patient missing Zendesk profile" />
    );
  }

  return null;
}
