import type { UseInfiniteQueryOptions, UseQueryOptions } from 'react-query';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import { STALE_TIME } from 'reactQuery';
import type {
  CountTasksByTypeResponse,
  CreateTaskQueueRequest,
  CreateTaskTypeRequest,
  ListTaskQueuesRequest,
  ListTaskTypesRequest,
  ListTasksRequest,
  ListTeamsRequest,
  TaskQueue,
  TaskType as TaskTypePb,
  Team,
  UpdateCommentRequest,
  UpdateTaskQueueRequest,
  UpdateTaskRequest,
  UpdateTaskTypeRequest,
} from 'shared/generated/grpcGateway/task.pb';
import { TaskService, TaskState } from 'shared/generated/grpcGateway/task.pb';
import type { Comment, CreateTaskPayload, Task } from 'shared/tasking/types';
import type { AipPaginatedData } from 'shared/types/pagination.types';
import { idToGrpcName } from 'shared/utils/grpc';

import { useCurrentUser } from '../useCurrentUser';

export const TASKS_QUERY_KEY_BASE = ['rpm', 'v1', 'task'] as const;
export const TEAMS_QUERY_KEY_BASE = ['rpm', 'v1', 'team'] as const;
export const DEFAULT_ORDER_BY =
  'state asc, priority asc, updateTime desc, uid desc';

const taskQueryKey = {
  infinite: (
    filter: string = '',
    follower: string = '',
    orderBy: string = '',
  ) =>
    [...TASKS_QUERY_KEY_BASE, 'infinite', filter, follower, orderBy] as const,
  task: (taskId: string) => [...TASKS_QUERY_KEY_BASE, taskId] as const,

  queuesInfinite: () => [...TASKS_QUERY_KEY_BASE, 'queue', 'infinite'] as const,
  taskQueue: (taskQueueId: string) =>
    [...TASKS_QUERY_KEY_BASE, 'queue', taskQueueId] as const,
  typesInfinite: () => [...TASKS_QUERY_KEY_BASE, 'type', 'infinite'] as const,
  taskType: (taskTypeId: string) =>
    [...TASKS_QUERY_KEY_BASE, 'type', taskTypeId] as const,
  teamInfinite: () => [...TEAMS_QUERY_KEY_BASE, 'infinite'] as const,
  team: (teamId: string) => [...TEAMS_QUERY_KEY_BASE, teamId] as const,
  countByType: () => [...TASKS_QUERY_KEY_BASE, 'countByType'] as const,
};

export type PaginatedTasks = AipPaginatedData<Task, 'data'>;
export type PaginatedTaskQueues = AipPaginatedData<TaskQueue, 'data'>;
export type PaginatedTaskTypes = AipPaginatedData<TaskTypePb, 'data'>;
export type PaginatedTeams = AipPaginatedData<Team, 'data'>;

export function useTasksByPatientInfinite(
  patientId: string,
  orderBy: string = DEFAULT_ORDER_BY,
  config?: UseInfiniteQueryOptions<PaginatedTasks>,
) {
  return useTasksInfinite(
    { filter: `patientId = '${patientId}'` },
    orderBy,
    config,
  );
}

export function useAssignedTasksInfinite(
  query: TaskQuery,
  orderBy: string = DEFAULT_ORDER_BY,
  config?: UseInfiniteQueryOptions<PaginatedTasks>,
) {
  const { currentUserId } = useCurrentUser();

  return useTasksInfinite(
    {
      filter: `assignee.name = '${currentUserId}' AND ${parseTaskQuery(query)}`,
    },
    orderBy,
    config,
  );
}

export function useTeamTasksInfinite(
  query: TaskQuery,
  orderBy: string = DEFAULT_ORDER_BY,
  config?: UseInfiniteQueryOptions<PaginatedTasks>,
) {
  return useTasksInfinite(
    {
      filter: `${parseTaskQuery(query)}`,
    },
    orderBy,
    config,
  );
}

export function useFollowedTasksInfinite(
  query: TaskQuery,
  orderBy: string = DEFAULT_ORDER_BY,
  config?: UseInfiniteQueryOptions<PaginatedTasks>,
) {
  const { currentUserId } = useCurrentUser();

  return useTasksInfinite(
    {
      filter: `${parseTaskQuery(
        query,
      )} AND (assignee.name != '${currentUserId}' OR assignee.name = null)`,
      follower: currentUserId,
    },
    orderBy,
    config,
  );
}

export function useResolvedTasksInfinite(
  query: TaskQuery,
  orderBy: string = DEFAULT_ORDER_BY,
  config?: UseInfiniteQueryOptions<PaginatedTasks>,
) {
  const { currentUserId } = useCurrentUser();

  return useTasksInfinite(
    {
      filter: parseTaskQuery(query),
      follower: currentUserId,
    },
    orderBy,
    config,
  );
}

function useTasksInfinite(
  query: ListTasksRequest,
  orderBy: string = DEFAULT_ORDER_BY,
  config?: UseInfiniteQueryOptions<PaginatedTasks>,
) {
  return useInfiniteQuery<PaginatedTasks>(
    taskQueryKey.infinite(query.filter, query.follower, orderBy),
    {
      staleTime: STALE_TIME.ONE_MINUTE,
      ...config,
      queryFn: async (ctx) => {
        const taskResponse = await TaskService.ListTasks({
          ...query,
          pageToken: ctx.pageParam,
          orderBy,
        });

        return {
          data: (taskResponse.tasks as Task[]) ?? [],
          nextPageToken: taskResponse.nextPageToken ?? '',
          totalSize: taskResponse.totalSize,
        };
      },
    },
  );
}

export function useTask(taskId: string, config?: UseQueryOptions<Task>) {
  const name = idToGrpcName('task', taskId);

  return useQuery({
    queryKey: taskQueryKey.task(name),
    queryFn: () => TaskService.GetTask({ name }) as Promise<Task>,
    ...config,
  });
}

type Callbacks<D = unknown, E = unknown> = {
  onSuccess?: (data?: D) => void;
  onError?: (err?: E) => void;
};

export function useCreateTask(callbacks: Callbacks) {
  const client = useQueryClient();

  return useMutation(
    (task: CreateTaskPayload) => TaskService.CreateTask({ task }),
    {
      onSuccess: async () => {
        await client.invalidateQueries(TASKS_QUERY_KEY_BASE);
        callbacks.onSuccess?.();
      },
      onError: callbacks.onError,
    },
  );
}

export function useCreateComment(
  taskId: string,
  callbacks?: Callbacks<Comment>,
) {
  const client = useQueryClient();
  return useMutation(
    ({ body }: { body: string }) =>
      TaskService.CreateComment({ parent: taskId, comment: { body } }),
    {
      onSuccess: async (comment) => {
        await client.invalidateQueries(TASKS_QUERY_KEY_BASE);
        callbacks?.onSuccess?.(comment as Comment);
      },
      onError: callbacks?.onError,
    },
  );
}

export function useUpdateComment(
  { name, etag }: Pick<Comment, 'name' | 'etag'>,
  callbacks?: Callbacks,
) {
  const client = useQueryClient();

  return useMutation(
    (req: UpdateCommentRequest) =>
      TaskService.UpdateComment({
        ...req,
        comment: { ...req.comment, name, etag },
      }),
    {
      onSuccess: async () => {
        await client.invalidateQueries(TASKS_QUERY_KEY_BASE);
        callbacks?.onSuccess?.();
      },
      onError: callbacks?.onError,
    },
  );
}

export function useUpdateTask(
  { name, etag }: Pick<Task, 'name' | 'etag'>,
  callbacks?: Callbacks,
) {
  const client = useQueryClient();

  return useMutation(
    (req: UpdateTaskRequest) =>
      TaskService.UpdateTask({
        ...req,
        task: { ...req.task, name, etag },
      }),
    {
      onSuccess: async () => {
        await client.invalidateQueries(TASKS_QUERY_KEY_BASE);
        callbacks?.onSuccess?.();
      },
      onError: callbacks?.onError,
    },
  );
}

type SetStateCallbacks = Pick<Callbacks, 'onError'> & {
  onSuccess: (newState: TaskState) => void;
};

export function useSetTaskState(task: Task, callbacks?: SetStateCallbacks) {
  const client = useQueryClient();
  return useMutation(
    (state: TaskState) =>
      state === TaskState.CLOSED
        ? TaskService.CloseTask({ name: task.name, etag: task.etag })
        : TaskService.OpenTask({ name: task.name, etag: task.etag }),
    {
      onSuccess: async (_, newState) => {
        callbacks?.onSuccess?.(newState);
        await client.invalidateQueries(TASKS_QUERY_KEY_BASE);
      },
      onError: callbacks?.onError,
    },
  );
}

export function useDeleteTask(
  { name, etag }: Pick<Task, 'name' | 'etag'>,
  callbacks?: Callbacks,
) {
  const client = useQueryClient();

  return useMutation(
    () =>
      TaskService.DeleteTask({
        name,
        etag,
      }),
    {
      onSuccess: async () => {
        await client.invalidateQueries(TASKS_QUERY_KEY_BASE);
        callbacks?.onSuccess?.();
      },
      onError: callbacks?.onError,
    },
  );
}

export type TaskQuery = {
  state?: TaskState;
  types?: TaskTypePb[];
  hasAssignee?: boolean;
};

function parseTaskQuery(query: TaskQuery) {
  const filter = [];

  if (query.state) {
    filter.push(`state = '${query.state}'`);
  }

  if (query.types && query.types.length > 0) {
    filter.push(
      `(${query.types
        ?.map((type) => `taskTypeId = '${type.name}'`)
        .join(' OR ')})`,
    );
  }

  if (query.hasAssignee !== undefined) {
    filter.push(`assignee.name ${query.hasAssignee ? '!=' : '='} null`);
  }

  return filter.join(' AND ');
}

export const GRPC_RESOURCE_NAME = {
  task: (id: string) => resourcePrefix('task', id),
  careProvider: (id: string) => resourcePrefix('careProvider', id),
};

function resourcePrefix(prefix: string, id: string) {
  if (!id.startsWith(prefix)) {
    return `${prefix}/${id}`;
  }
  return id;
}

export function useTaskQueuesInfinite(
  query: ListTaskQueuesRequest,
  config?: UseInfiniteQueryOptions<PaginatedTaskQueues>,
) {
  return useInfiniteQuery<PaginatedTaskQueues>(taskQueryKey.queuesInfinite(), {
    ...config,
    queryFn: async (ctx) => {
      const response = await TaskService.ListTaskQueues({
        ...query,
        pageToken: ctx.pageParam,
      });
      return {
        data: response.taskQueues ?? [],
        nextPageToken: response.nextPageToken ?? '',
      };
    },
  });
}

export function useCreateTaskQueue(callbacks: Callbacks) {
  const client = useQueryClient();

  return useMutation(
    (task_queue: TaskQueue) =>
      TaskService.CreateTaskQueue({ task_queue } as CreateTaskQueueRequest),
    {
      onSuccess: async () => {
        await client.invalidateQueries(taskQueryKey.queuesInfinite());
        callbacks.onSuccess?.();
      },
      onError: callbacks.onError,
    },
  );
}

export function useUpdateTaskQueue(callbacks: Callbacks) {
  const client = useQueryClient();

  return useMutation(
    // casting required due to weird casing issues
    (taskQueue: TaskQueue) =>
      TaskService.UpdateTaskQueue({
        task_queue: taskQueue,
        taskQueue,
      } as UpdateTaskQueueRequest),
    {
      onSuccess: async () => {
        await client.invalidateQueries(taskQueryKey.queuesInfinite());
        callbacks.onSuccess?.();
      },
      onError: callbacks.onError,
    },
  );
}

export function useDeleteTaskQueue(callbacks: Callbacks) {
  const client = useQueryClient();

  return useMutation(
    (id: string) =>
      TaskService.DeleteTaskQueue({ name: idToGrpcName('task/queue', id) }),
    {
      onSuccess: async () => {
        await client.invalidateQueries(taskQueryKey.queuesInfinite());
        callbacks.onSuccess?.();
      },
      onError: callbacks.onError,
    },
  );
}

export function useTaskTypesInfinite(
  query: ListTaskTypesRequest,
  config?: UseInfiniteQueryOptions<PaginatedTaskTypes>,
) {
  return useInfiniteQuery<PaginatedTaskTypes>(taskQueryKey.typesInfinite(), {
    ...config,
    queryFn: async (ctx) => {
      const response = await TaskService.ListTaskTypes({
        ...query,
        pageToken: ctx.pageParam,
      });
      return {
        data: response.taskTypes ?? [],
        nextPageToken: response.nextPageToken ?? '',
      };
    },
  });
}

export function useCreateTaskType(callbacks: Callbacks) {
  const client = useQueryClient();

  return useMutation(
    (task_type: TaskTypePb) =>
      TaskService.CreateTaskType({ task_type } as CreateTaskTypeRequest),
    {
      onSuccess: async () => {
        await client.invalidateQueries(taskQueryKey.typesInfinite());
        callbacks.onSuccess?.();
      },
      onError: callbacks.onError,
    },
  );
}

export function useUpdateTaskType(callbacks: Callbacks) {
  const client = useQueryClient();

  return useMutation(
    (taskType: TaskTypePb) =>
      // casting required due to weird casing issues
      TaskService.UpdateTaskType({
        task_type: taskType,
        taskType,
      } as UpdateTaskTypeRequest),
    {
      onSuccess: async () => {
        await client.invalidateQueries(taskQueryKey.typesInfinite());
        callbacks.onSuccess?.();
      },
      onError: callbacks.onError,
    },
  );
}

export function useDeleteTaskType(callbacks: Callbacks) {
  const client = useQueryClient();

  return useMutation(
    (id: string) =>
      TaskService.DeleteTaskType({ name: idToGrpcName('task/type', id) }),
    {
      onSuccess: async () => {
        await client.invalidateQueries(taskQueryKey.typesInfinite());
        callbacks.onSuccess?.();
      },
      onError: callbacks.onError,
    },
  );
}

export function useTeamsInfinite(
  query: ListTeamsRequest,
  config?: UseInfiniteQueryOptions<PaginatedTeams>,
) {
  return useInfiniteQuery<PaginatedTeams>(taskQueryKey.teamInfinite(), {
    ...config,
    queryFn: async (ctx) => {
      const response = await TaskService.ListTeams({
        ...query,
        pageToken: ctx.pageParam,
      });
      return {
        data: response.teams ?? [],
        nextPageToken: response.nextPageToken ?? '',
      };
    },
  });
}

export function useCreateTeam(callbacks: Callbacks) {
  const client = useQueryClient();

  return useMutation((team: Team) => TaskService.CreateTeam({ team }), {
    onSuccess: async () => {
      await client.invalidateQueries(TEAMS_QUERY_KEY_BASE);
      callbacks.onSuccess?.();
    },
    onError: callbacks.onError,
  });
}

export function useUpdateTeam(callbacks: Callbacks) {
  const client = useQueryClient();

  return useMutation(
    (team: Team) =>
      TaskService.UpdateTeam({
        team: { ...team, name: idToGrpcName('team', team.name ?? '') },
      }),
    {
      onSuccess: async () => {
        await client.invalidateQueries(TEAMS_QUERY_KEY_BASE);
        callbacks.onSuccess?.();
      },
      onError: callbacks.onError,
    },
  );
}

export function useDeleteTeam(callbacks: Callbacks) {
  const client = useQueryClient();

  return useMutation(
    (id: string) => TaskService.DeleteTeam({ name: idToGrpcName('team', id) }),
    {
      onSuccess: async () => {
        await client.invalidateQueries(TEAMS_QUERY_KEY_BASE);
        callbacks.onSuccess?.();
      },
      onError: callbacks.onError,
    },
  );
}

export function useTaskCountByType(
  config?: UseQueryOptions<CountTasksByTypeResponse>,
) {
  return useQuery({
    queryKey: taskQueryKey.countByType(),
    queryFn: () => TaskService.CountTasksByType({}),
    ...config,
  });
}
