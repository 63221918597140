/* eslint-disable no-nested-ternary */
import { makeStyles } from 'deprecated/mui';
import { color } from 'shared/tempo/theme';
import type { PatientReminderNoteData } from 'shared/types/patient.types';

type Props = {
  note: Nullable<PatientReminderNoteData>;
  active: boolean;
};

export const useStyles = makeStyles<{}, Props>({
  infoIcon: ({ note, active }) => ({
    '& #info-icon-background': {
      fill: note
        ? color.Theme.Light.Warning
        : color.Theme.Light['Base Background'],
    },
    '& #info-icon-inner-icon-1, #info-icon-inner-icon-2': {
      fill: active
        ? color.Theme.Light.Primary
        : note
          ? color.Theme.Light['Base Font']
          : color.Theme.Light.Primary,
    },

    '& #info-icon-border': {
      stroke: active
        ? color.Theme.Light.Primary
        : note
          ? color.Theme.Light['Base Font']
          : color.Theme.Light.Primary,
    },
  }),
});
