import cx from 'classnames';
import omit from 'lodash/omit';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { BillableTimeGuidanceLink } from 'pages/patients/patientDetails/ui/shared';
import {
  Divider,
  TasksContainer,
} from 'pages/patients/patientDetails/ui/tabs/TimeTracking/ManualTimeTrackingForm/shared';
import CallIcon from 'shared/assets/svgs/calling.svg?react';
import FileIcon from 'shared/assets/svgs/file.svg?react';
import { useFlags } from 'shared/hooks';

import { CallTracking } from '../../../tabs/TimeTracking/CallTracking/CallTracking';
import {
  type EncounterTypeInputs,
  type TimeEntry,
  TimeTrackedTaskType,
} from '../../Notes.types';
import { useIsTimeTrackedTypeOfEncounter } from '../hooks/useIsTimeTrackedTypeOfEncounter';
import type { NoteFormValidationResult } from '../validation';
import { DateOfEncounter } from './DateOfEncounter';
import {
  contentsContainer,
  startDateAndTimeContainer,
} from './NoteTimeTracker.css';
import { StartOfEncounter } from './StartOfEncounter';
import { TimeTrackerMinutesPicker } from './TimeTrackerMinutesPicker';
import { TimeTrackerTasks } from './TimeTrackerTasks';
import { TimeTrackingTextInput } from './TimeTrackingTextInput';
import { TimeTrackingTotalTimeDisplay } from './TimeTrackingTotalTimeDisplay';

type Props = {
  onChange: (timeEntry: Partial<TimeEntry>) => void;
  timeEntry: Partial<TimeEntry>;
  className?: string;
  validationResults: NoteFormValidationResult['timeEntry'];
  encounterTypeInputs?: EncounterTypeInputs;
  patientId?: Maybe<string>;
  noteId?: Maybe<number>;
  captureInteractiveTime?: boolean;
};

export function NoteTimeTracker({
  onChange,
  timeEntry,
  className,
  validationResults,
  encounterTypeInputs,
  patientId,
  noteId,
  captureInteractiveTime = true,
}: Props) {
  const intl = useIntl();
  const { addCallsToEncounter } = useFlags();

  const onFieldChange = (field: keyof TimeEntry) => (value: unknown) => {
    let updatedTimeEntry = { ...timeEntry, [field]: value };
    // Remove the old fields if present in the TT retrieved from BE.
    // These should be inferred by BE using up-to-date TT fields.
    updatedTimeEntry = omit(updatedTimeEntry, [
      'communication_type',
      'note',
      'duration',
    ]);
    onChange({
      ...updatedTimeEntry,
      ...(!captureInteractiveTime && {
        interactive_duration: 0,
      }),
    });
  };

  useEffect(() => {
    if (
      timeEntry.other_task_description !== undefined &&
      !timeEntry.tasks_accomplished?.includes(TimeTrackedTaskType.Other)
    ) {
      onFieldChange('other_task_description')(undefined);
    }
    // Don't trigger on onFieldChange changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeEntry.tasks_accomplished]);

  const isPatientNoShow = !!encounterTypeInputs?.patient_no_show;

  useEffect(() => {
    if (isPatientNoShow) {
      onFieldChange('interactive_duration')(0);
    }
    // Run effect on isPatientNoShow changes with up to date onFieldChange
    // but don't run on onFieldChange changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPatientNoShow /* onFieldChange */]);

  if (!useIsTimeTrackedTypeOfEncounter(encounterTypeInputs)) {
    return null;
  }

  const totalTimeInMinutes =
    (timeEntry.interactive_duration || 0) +
    (timeEntry.non_interactive_duration || 0);

  return (
    <div className={cx(className)}>
      {captureInteractiveTime && (
        <>
          <TimeTrackerMinutesPicker
            tooltip={
              isPatientNoShow
                ? intl.formatMessage({
                    defaultMessage:
                      'Interactive time cannot be entered for a patient no-show ',
                  })
                : undefined
            }
            isDisabled={isPatientNoShow}
            icon={<CallIcon width={16} height={16} />}
            label={<FormattedMessage defaultMessage="Interactive time" />}
            description={
              <FormattedMessage defaultMessage="Live phone conversation" />
            }
            onChange={onFieldChange('interactive_duration')}
            value={timeEntry.interactive_duration}
            fieldValidationResult={getFieldValidationResult(
              validationResults,
              'interactive_duration',
            )}
          />
          {addCallsToEncounter && noteId && patientId && (
            <CallTracking noteId={noteId} patientId={patientId} />
          )}

          <Divider />
        </>
      )}
      <TimeTrackerMinutesPicker
        icon={<FileIcon width={16} height={16} />}
        label={<FormattedMessage defaultMessage="Non-interactive time" />}
        description={
          <FormattedMessage defaultMessage="Chart prep, documentation and other tasks" />
        }
        onChange={onFieldChange('non_interactive_duration')}
        value={timeEntry.non_interactive_duration}
        fieldValidationResult={getFieldValidationResult(
          validationResults,
          'non_interactive_duration',
        )}
      />
      <TasksContainer>
        <TimeTrackerTasks
          onChange={onFieldChange('tasks_accomplished')}
          value={timeEntry.tasks_accomplished}
          fieldValidationResult={getFieldValidationResult(
            validationResults,
            'tasks_accomplished',
          )}
          required={(timeEntry?.non_interactive_duration ?? 0) > 0}
        />
        {timeEntry.tasks_accomplished?.includes(TimeTrackedTaskType.Other) && (
          <TimeTrackingTextInput
            onChange={onFieldChange('other_task_description')}
            value={timeEntry.other_task_description}
            label={intl.formatMessage({
              defaultMessage: 'Other task description',
            })}
            placeholder={intl.formatMessage({
              defaultMessage: 'Enter the task description',
            })}
            fieldValidationResult={getFieldValidationResult(
              validationResults,
              'other_task_description',
            )}
          />
        )}
      </TasksContainer>
      <TimeTrackingTotalTimeDisplay
        minutes={totalTimeInMinutes}
        fieldValidationResult={getFieldValidationResult(
          validationResults,
          'totalTimeDisplay',
        )}
      />
      <Divider />
      <div className={contentsContainer.default}>
        <div className={startDateAndTimeContainer}>
          <DateOfEncounter
            onChange={onFieldChange('start_date')}
            value={timeEntry.start_date}
            validationResult={getFieldValidationResult(
              validationResults,
              'start_date',
            )}
          />
          <StartOfEncounter
            onChange={onFieldChange('start_time')}
            value={timeEntry.start_time}
            validationResult={getFieldValidationResult(
              validationResults,
              'start_time',
            )}
          />
        </div>
        <BillableTimeGuidanceLink />
      </div>
    </div>
  );
}

function getFieldValidationResult(
  validationResults: NoteFormValidationResult['timeEntry'],
  field: keyof TimeEntry,
) {
  return validationResults.find(
    (result) => result.path === `timeEntry.${field}`,
  );
}
