import type { ReactNode } from 'react';
import type { IntlShape } from 'react-intl';

import Devices from 'shared/assets/svgs/admin-devices.svg?react';
import EPL from 'shared/assets/svgs/admin-enrollment-tracker.svg?react';
import HealthSystem from 'shared/assets/svgs/admin-health-systems.svg?react';
import Hospitals from 'shared/assets/svgs/admin-hospitals.svg?react';
import Markets from 'shared/assets/svgs/admin-markets.svg?react';
import NPI from 'shared/assets/svgs/admin-npi.svg?react';
import Patients from 'shared/assets/svgs/admin-patients.svg?react';
import Providers from 'shared/assets/svgs/admin-providers.svg?react';
import Instances from 'shared/assets/svgs/admin.svg?react';
import Home from 'shared/assets/svgs/home.svg?react';
import Toolbox from 'shared/assets/svgs/wrench-light.svg?react';
import type { FeatureFlagSet } from 'shared/types/featureFlags.types';

import { sidebarIcon } from './Sidebar.css';

type MenuLink = {
  to: string;
  icon: ReactNode;
  label: string;
};

export function getMenuLinks(
  intl: IntlShape,
  isAdminPage: boolean,
  flags: FeatureFlagSet,
): MenuLink[] {
  const { marketsAndOrders, showInstanceConfigsPage, showAdminToolbox } = flags;
  if (isAdminPage) {
    return [
      {
        to: '/admin/patient',
        icon: <Patients className={sidebarIcon} />,
        label: intl.formatMessage({ defaultMessage: 'Patient' }),
      },
      {
        to: '/admin/provider',
        icon: <Providers className={sidebarIcon} />,
        label: intl.formatMessage({ defaultMessage: 'Provider' }),
      },
      {
        to: '/admin/hospital',
        icon: <Hospitals className={sidebarIcon} />,
        label: intl.formatMessage({ defaultMessage: 'Hospital' }),
      },
      marketsAndOrders && {
        to: '/admin/markets',
        icon: <Markets className={sidebarIcon} />,
        label: intl.formatMessage({ defaultMessage: 'Markets' }),
      },
      {
        to: '/admin/health-system',
        icon: <HealthSystem className={sidebarIcon} />,
        label: intl.formatMessage({
          defaultMessage: 'Health System',
        }),
      },
      {
        to: '/admin/npi',
        icon: <NPI className={sidebarIcon} />,
        label: intl.formatMessage({ defaultMessage: 'NPI' }),
      },
      {
        to: '/admin/devices',
        icon: <Devices className={sidebarIcon} />,
        label: intl.formatMessage({ defaultMessage: 'Devices' }),
      },
      {
        to: '/admin/patient-enrollment-tracker',
        icon: <EPL className={sidebarIcon} />,
        label: intl.formatMessage({
          defaultMessage: 'Enrollment Tracker',
        }),
      },
      showInstanceConfigsPage && {
        to: '/admin/instance',
        icon: <Instances className={sidebarIcon} />,
        label: intl.formatMessage({ defaultMessage: 'Instance Configs' }),
      },
      showAdminToolbox && {
        to: '/admin/toolbox',
        icon: <Toolbox className={sidebarIcon} />,
        label: intl.formatMessage({ defaultMessage: 'Toolbox' }),
      },
    ].filter(Boolean) as MenuLink[];
  }

  // Non-admin menu links

  return [
    flags.showHomePage && {
      to: '/home',
      icon: <Home className={sidebarIcon} />,
      label: intl.formatMessage({ defaultMessage: 'Home Page' }),
    },
    {
      to: '/patients',
      icon: <Patients className={sidebarIcon} />,
      label: intl.formatMessage({ defaultMessage: 'Patients' }),
    },
  ].filter(Boolean) as MenuLink[];
}
