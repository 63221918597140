import { FormattedMessage } from 'react-intl';

import { Button } from 'deprecated/mui';

export function TruncateButton({
  onTruncateClick,
  isTruncated,
}: {
  onTruncateClick: () => void;
  isTruncated: boolean;
}) {
  return (
    <Button disableRipple className="truncate-button" onClick={onTruncateClick}>
      {isTruncated ? (
        <FormattedMessage defaultMessage="Read more" />
      ) : (
        <FormattedMessage defaultMessage="Read less" />
      )}
    </Button>
  );
}
