import {
  CareModelVersion,
  type FeatureFlagSet,
} from 'shared/types/featureFlags.types';

import { disenrollment } from '../__shared__/disenrollment';
import { patientEnrollment } from '../__shared__/patientEnrollment';
import { virtualEnrollment } from '../__shared__/virtualEnrollment';
import { welcomeCall } from '../__shared__/welcomeCall';
import { ccmVisit } from './3.0.0/ccmVisit';
import { initialCnVisit } from './3.0.0/initialCnVisit';
import { initialNpVisit as initialNpVisitDotZero } from './3.0.0/initialNpVisit';
import { regularCnVisit } from './3.0.0/regularCnVisit';
import { regularNpVisit as regularNpVisitDotZero } from './3.0.0/regularNpVisit';
import { initialNpVisit as initialNpVisitDotOne } from './3.0.1/initialNpVisit';
import { regularNpVisit as regularNpVisitDotOne } from './3.0.1/regularNpVisit';

const dotZero = {
  [CareModelVersion.V3]: {
    ...initialCnVisit,
    ...regularCnVisit,
    ...patientEnrollment,
    ...virtualEnrollment,
    ...initialNpVisitDotZero,
    ...regularNpVisitDotZero,
    ...welcomeCall,
    ...disenrollment,
    ...ccmVisit,
  },
};

const dotOne = {
  [CareModelVersion.V3]: {
    ...dotZero[CareModelVersion.V3],
    ...initialNpVisitDotOne,
    ...regularNpVisitDotOne,
  },
};

export function getThreeDotOhTemplates(flags: FeatureFlagSet) {
  // Add conditional statements (e.g. LD flagging) for newer versions above all existing returns,
  // so they take precedence over the older ones.
  if (flags.structuredClinicalGoalReached) {
    return dotOne;
  }
  return dotZero;
}
