import { FormattedMessage } from 'react-intl';

import {
  Script,
  YesNoRadioGroup,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { scriptLabel } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';
import { Form } from 'shared/common/Form';
import type { ConfiguredForm } from 'shared/common/Form/FormContainer';

import type { DietFormFields } from './formConfig';

type Props = {
  form: ConfiguredForm<DietFormFields>;
};

export function DietForm({ form }: Props) {
  const sugaryDrinks = form.watch('sugaryDrinks');

  return (
    <>
      <Form.TextArea
        size={12}
        rows={3}
        name="currentDiet"
        label={
          <FormattedMessage defaultMessage="Current fast food/processed food habits" />
        }
      />
      <YesNoRadioGroup
        name="sugaryDrinks"
        classes={{ label: scriptLabel }}
        label={
          <Script>
            <FormattedMessage defaultMessage="Do you drink regular sodas, fruit juices, or sweet tea?" />
          </Script>
        }
      />
      {sugaryDrinks === 'true' && (
        <Form.TextField
          size={12}
          name="sugaryDrinksDescription"
          label={
            <FormattedMessage defaultMessage="How many servings do you drink per day?" />
          }
          required
        />
      )}
      <Script>
        <FormattedMessage defaultMessage="How much water do you drink per day?" />
      </Script>
      <Form.TextField
        size={12}
        name="waterPerDay"
        label={<FormattedMessage defaultMessage="Daily water consumption" />}
        required
      />
      <YesNoRadioGroup
        name="addSalt"
        classes={{ label: scriptLabel }}
        label={
          <Script>
            <FormattedMessage defaultMessage="Do you add salt to meals regularly?" />
          </Script>
        }
      />
    </>
  );
}
