import isEqual from 'lodash/isEqual';

import type {
  CompositeSortDirection,
  CompositeSortKey,
  SortDirection,
  SortKey,
} from 'shared/types/sorting.types';
import { Sort } from 'shared/types/sorting.types';

import type { SortState } from './useTableSort';

function ensureCompositeSortKey<K>(sortKey: SortKey<K> | CompositeSortKey<K>) {
  return !Array.isArray(sortKey) ? new Array(sortKey) : sortKey;
}

export function areSortKeysEqual<K>(
  sortKeyA: SortKey<K> | CompositeSortKey<K> | undefined,
  sortKeyB: SortKey<K> | CompositeSortKey<K> | undefined,
) {
  return isEqual(
    ensureCompositeSortKey(sortKeyA),
    ensureCompositeSortKey(sortKeyB),
  );
}

/*
 * Gets the sort direction array corresponding to the sortKey
 * i.e. ('key', 'asc') => ['asc']
 *      (['key1', 'key2'], 'desc') => ['desc', 'desc']
 */
export function getCompositeSortDir<K>(
  sortKey: CompositeSortKey<K>,
  sortDir: SortDirection,
) {
  const arrKey = ensureCompositeSortKey(sortKey);
  return new Array(arrKey.length).fill(sortDir) as CompositeSortDirection;
}

export function getSortParams<K>(
  sortState: SortState<K>,
  defaultSort?: SortState<K>, // only used if sort key is not in UI (ex: 'enrollment_score' in EPL)
) {
  if (areSortKeysEqual(sortState.sortDir?.[0], Sort.None)) {
    if (defaultSort) {
      return { sortKey: defaultSort.sortKey, sortDir: defaultSort.sortDir };
    }
    return {};
  }
  return { sortKey: sortState.sortKey, sortDir: sortState.sortDir };
}
