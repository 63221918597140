import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { logger } from 'logger';
import {
  FieldGroup,
  Script,
  YesNoRadioGroup,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { scriptLabel } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';
import { usePatientProgram } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/usePatientProgram';
import { useTimeEstimate } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import type { ConfiguredForm } from 'shared/common/Form/FormContainer';
import { Wizard } from 'shared/common/Wizard';
import { ConditionProgram } from 'shared/types/condition.types';

import { useCNFormContext } from '../../../CNFormContext';
import type { CNSectionMap } from '../../metadata';
import { symptomCheckboxesWrapper } from './SharedSecondarySymptoms.css';
import { SymptomFields } from './SymptomFields';
import type { SecondarySymptomsFormFields } from './formConfig';
import { SYMPTOMS_BY_PROGRAM, useSymptomI18nMapping } from './symptomList';

type Props = {
  form: ConfiguredForm<SecondarySymptomsFormFields>;
  sections: CNSectionMap;
  nextPath: string;
};

export function SharedSecondarySymptoms({ form, sections, nextPath }: Props) {
  const intl = useIntl();
  const history = useHistory();
  const symptomI18nMap = useSymptomI18nMapping();
  const { patientId } = useCNFormContext();
  const { program, symptoms } = useProgramSpecificSymptoms(patientId);
  const introLabel = useIntroQuestion(program);
  const newSecondarySymptoms = form.watch('newSecondarySymptoms');
  const isChf = program === ConditionProgram.CHF;

  return (
    <Wizard.Step
      sections={sections}
      form={form}
      title={intl.formatMessage({ defaultMessage: 'Symptoms' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => {
        history.push(nextPath);
      }}
    >
      <FieldGroup>
        {isChf ? (
          <Script>{introLabel}</Script>
        ) : (
          <YesNoRadioGroup
            name="newSecondarySymptoms"
            classes={{ label: scriptLabel }}
            label={<Script>{introLabel}</Script>}
          />
        )}
        {(isChf || newSecondarySymptoms === 'true') && symptoms.length > 0 && (
          <div className={symptomCheckboxesWrapper}>
            {symptoms.map((symptom) => (
              <SymptomFields
                key={symptom}
                symptom={symptom}
                label={symptomI18nMap[symptom]}
                form={form}
              />
            ))}
          </div>
        )}
      </FieldGroup>
    </Wizard.Step>
  );
}

function useProgramSpecificSymptoms(patientId: string) {
  const loggedError = useRef(false);
  const { program, isLoading } = usePatientProgram(patientId);

  if (isLoading || !program) {
    return { program, symptoms: [] };
  }

  const symptoms = SYMPTOMS_BY_PROGRAM[program];

  if (!symptoms.length && !loggedError.current) {
    logger.error(
      `Patient with unsupported program made it into the CN experience wizard. This shouldn't happen.`,
      undefined,
      {
        patientId,
        program,
      },
    );

    loggedError.current = true;
  }

  return { program, symptoms };
}

function useIntroQuestion(program: Nullable<ConditionProgram>) {
  const intl = useIntl();

  switch (program) {
    case ConditionProgram.Hypertension:
      return intl.formatMessage({
        defaultMessage:
          'Have you experienced any changes in vision, chest pain, headaches, shortness of breath, dizziness, or foot or ankle swelling (edema)?',
      });
    case ConditionProgram.TypeTwoDiabetes:
      return intl.formatMessage({
        defaultMessage:
          'Have you experienced any dizziness, excessive hunger, headaches, nausea, increased thirst, frequent urination, or sweating?',
      });
    case ConditionProgram.T2DAndHTN:
      return intl.formatMessage({
        defaultMessage:
          'Have you experienced any dizziness, excessive hunger, headaches, nausea, increased thirst, frequent urination, sweating, changes in vision, chest pain, headaches, shortness of breath, or foot or ankle swelling (edema)?',
      });
    case ConditionProgram.CHF:
      return intl.formatMessage({
        defaultMessage: 'Have you experienced any of the following?',
      });
    default:
      return intl.formatMessage({
        defaultMessage: 'TODO: Unsupported condition',
      });
  }
}
