import type { UseQueryOptions } from 'react-query';

import type { Note } from 'shared/types/note.types';
import type { PaginatedData } from 'shared/types/pagination.types';

import { useQueryNoRefetch } from '../useQueryNoRefetch';

const getMonitoringSessionNotesQueryKeyBase = (sessionId: string) => [
  'pms',
  'api',
  'v1',
  'notes',
  'monitoring_sessions',
  sessionId,
];

export const MONITORING_SESSION_NOTES_QUERY_KEYS = {
  list: (sessionId: string) =>
    [...getMonitoringSessionNotesQueryKeyBase(sessionId)] as const,
};

export function useMonitoringSessionNotesNoRefetch(
  sessionId: string,
  params?: UseQueryOptions<PaginatedData<Note>>,
) {
  return useQueryNoRefetch<PaginatedData<Note>>(
    MONITORING_SESSION_NOTES_QUERY_KEYS.list(sessionId),
    params,
  );
}
