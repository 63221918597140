import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { LabelledCheckbox } from 'shared/common/LabelledCheckbox';
import { usePrevious } from 'shared/hooks';
import { usePatchPatient } from 'shared/hooks/queries';
import type { Patient } from 'shared/types/patient.types';
import type { PatientNotificationSettings } from 'shared/types/shared.types';

import { label, sendoutInfo } from './WeeklyEmailSummary.css';

export type Props = {
  patient: Patient;
  patientNotifications: PatientNotificationSettings;
  customClass?: string;
};

export function WeeklyEmailSummary({
  patient,
  patientNotifications,
  customClass,
}: Props) {
  const patchPatient = usePatchPatient(patient.id || '');
  const [isChecked, setIsChecked] = useState(
    patientNotifications.weekly_summary,
  );

  useEffect(() => {
    setIsChecked(Boolean(patientNotifications?.weekly_summary));
  }, [patientNotifications]);

  const previousIsChecked = usePrevious(isChecked);
  const handleWeeklySummaryToggle = (checked: boolean) => {
    // optimistic update
    setIsChecked(checked);
    if (checked !== previousIsChecked) {
      patchPatient.mutate(
        {
          notification_settings: {
            ...patientNotifications,
            weekly_summary: checked,
          },
        },
        {
          onError: () => {
            // roll back the optimistic update
            setIsChecked(!checked);
          },
        },
      );
    }
  };

  return (
    <>
      <LabelledCheckbox
        label={<FormattedMessage defaultMessage="Weekly email summary" />}
        id="weekly-email-summary-checkbox"
        checked={isChecked ?? false}
        disabled={patchPatient.isLoading}
        onChange={(e) => handleWeeklySummaryToggle(e.target.checked)}
        fontSize="16px"
        customClassName={customClass}
        customLabelClassName={label}
      />
      <div className={sendoutInfo}>
        <FormattedMessage defaultMessage="Sent weekly on Mondays at 9am" />
      </div>
    </>
  );
}
