import cx from 'classnames';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { MenuItem } from 'deprecated/mui';
import Admin from 'shared/assets/svgs/admin.svg?react';
import Logout from 'shared/assets/svgs/logout.svg?react';
import ReportIssue from 'shared/assets/svgs/report-issue.svg?react';
import { UserAvatar } from 'shared/common/UserAvatar';
import { isAdmin } from 'shared/utils/userRoles.utils';

import { MenuTooltip } from '../MenuTooltip';
import {
  activeMenuItem,
  menuItem,
  menuList,
  sidebarBackBtn,
  sidebarIcon,
  sidebarIconContainer,
} from '../Sidebar.css';
import { SupportModal } from './SupportModal';
import type { TSidebarMenu } from './TSidebarMenu';

export function SidebarMenu({
  userInfo,
  logout,
  role,
  isAdminPage,
  onReportIssueOpenChange,
}: TSidebarMenu) {
  if (isAdminPage) {
    return (
      <div className={menuList}>
        <ReportIssueButton onOpenChange={onReportIssueOpenChange} />
        <NavLink exact to="/patient">
          <button type="button" className={cx(sidebarBackBtn, menuItem)}>
            <FormattedMessage defaultMessage="Back" />
          </button>
        </NavLink>
      </div>
    );
  }

  const adminToolsLabel = <FormattedMessage defaultMessage="Admin Tools" />;
  const logoutLabel = <FormattedMessage defaultMessage="Logout" />;

  return (
    <div className={menuList}>
      <MenuItem className={menuItem} disabled>
        <div className={sidebarIconContainer}>
          <UserAvatar
            firstName={userInfo.given_name}
            lastName={userInfo.family_name}
          />
        </div>
      </MenuItem>

      {isAdmin(role) && (
        <MenuTooltip label={adminToolsLabel}>
          <NavLink exact to="/admin/patient">
            <MenuItem className={menuItem}>
              <div className={sidebarIconContainer}>
                <Admin className={sidebarIcon} />
              </div>
            </MenuItem>
          </NavLink>
        </MenuTooltip>
      )}

      <ReportIssueButton onOpenChange={onReportIssueOpenChange} />
      <MenuTooltip label={logoutLabel}>
        <div>
          <MenuItem className={menuItem} onClick={logout}>
            <div className={sidebarIconContainer}>
              <Logout className={sidebarIcon} data-testid="logout-btn" />
            </div>
          </MenuItem>
        </div>
      </MenuTooltip>
    </div>
  );
}

function ReportIssueButton({
  onOpenChange,
}: {
  onOpenChange?: (isOpen: boolean) => void;
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const label = <FormattedMessage defaultMessage="Report Issue" />;

  const setOpenState = (isOpen: boolean) => {
    setModalVisible(isOpen);
    onOpenChange?.(isOpen);
  };

  return (
    <>
      <MenuTooltip label={label} blockOpen={modalVisible}>
        <div className={cx({ [activeMenuItem]: modalVisible })}>
          <MenuItem className={menuItem} onClick={() => setOpenState(true)}>
            <div className={sidebarIconContainer}>
              <ReportIssue className={sidebarIcon} />
            </div>
          </MenuItem>
        </div>
      </MenuTooltip>
      <SupportModal open={modalVisible} onClose={() => setOpenState(false)} />
    </>
  );
}
