import { type ReactNode, useEffect } from 'react';
import { useIntl } from 'react-intl';
import type { OverlayTriggerState } from 'react-stately';
import { TransitionGroup } from 'react-transition-group';

import { LoadingPlaceholder } from 'shared/common/LoadingPlaceholder';
import { useCareProvider } from 'shared/hooks/queries';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useToaster } from 'shared/tempo/molecule/Toast';

import { TaskCard } from '../TaskCard';
import type { Task } from '../types';
import { TaskCardTransition } from './TaskCardTransition';
import { container } from './TaskList.css';
import { TaskListContextProvider } from './taskListContext';
import type { ParentView } from './types';

type Props = {
  tasks: Task[];
  parentView: ParentView;
  taskHubOverlayState?: Nullable<OverlayTriggerState>;
  classes?: {
    container?: string;
  };
  TaskCardComponent?: (props: {
    task: Task;
    parentView: ParentView;
    isActive?: boolean;
    onOpenTaskDetail?: (task: Task) => void;
  }) => JSX.Element;
  isCompact?: boolean;
  activeTaskId?: string;
  onOpenTaskDetail?: (task: Task) => void;
  children?: ReactNode;
};

export function TaskList({
  tasks,
  parentView,
  taskHubOverlayState,
  classes,
  TaskCardComponent = TaskCard,
  isCompact = false,
  activeTaskId,
  onOpenTaskDetail,
  children,
}: Props) {
  const intl = useIntl();
  const { currentUserId } = useCurrentUser();
  const { toaster } = useToaster();
  const {
    isLoading,
    error,
    data: loggedInProvider,
  } = useCareProvider(currentUserId);
  useEffect(() => {
    if (error) {
      toaster.error(
        intl.formatMessage({
          defaultMessage: 'Failed to fetch logged in provider information',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (isLoading || !loggedInProvider) {
    return <LoadingPlaceholder isLoading />;
  }

  return (
    <TaskListContextProvider
      value={{
        loggedInProvider,
        parentView,
        taskHubOverlayState: taskHubOverlayState || null,
      }}
    >
      <div className={classes?.container || container}>
        <TransitionGroup component={null}>
          {tasks.map((task) => (
            <TaskCardTransition key={task.uid} compact={isCompact}>
              <TaskCardComponent
                task={task}
                parentView={parentView}
                onOpenTaskDetail={onOpenTaskDetail}
                isActive={task.uid === activeTaskId}
              />
            </TaskCardTransition>
          ))}
        </TransitionGroup>
        {children}
      </div>
    </TaskListContextProvider>
  );
}
