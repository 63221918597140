import cx from 'classnames';
import omit from 'lodash/omit';
import type { ReactNode } from 'react';
import { useRef, useState } from 'react';
import type { TooltipTriggerProps } from 'react-aria';
import { useTooltipTrigger } from 'react-aria';
import { createPortal } from 'react-dom';
import { useTooltipTriggerState } from 'react-stately';
import { Transition } from 'react-transition-group';

import { useOnMount } from 'shared/hooks/useOnMount';

import type { PassthroughProps } from './BaseTooltip';
import { BaseTooltip } from './BaseTooltip';
import { ANIMATION_DURATION_MS, transitions, trigger } from './styles.css';

type RenderTrigger = (props: { isOpen: boolean }) => ReactNode;

export type Props = {
  children: ReactNode | RenderTrigger;
  content: ReactNode;
  // Node to portal tooltips to
  portalNode?: HTMLElement;
  className?: string;
  classes?: {
    triggerContainer?: string;
  };
} & Omit<TooltipTriggerProps, 'delay' | 'closeDelay'> &
  PassthroughProps;

export function Tooltip({
  className,
  classes = {},
  children,
  content,
  portalNode,
  ...props
}: Props) {
  const [mountedInModal, setMountedInModal] = useState<Nullable<Element>>(null);
  useOnMount(() => {
    // TODO: We should be able to remove this once we've replaced MUI Dialogs with react-aria/tempo
    const muiModalContainer = document.querySelector(`.MuiDialog-container`);
    setMountedInModal(muiModalContainer);
  });
  // Make sure the anchor ref is stored in state, not in a ref.
  // This makes them reactive to changes, and avoids reading refs in render (forbidden by React).
  const [anchorEl, setAnchorEl] = useState<Nullable<Element>>(null);
  const state = useTooltipTriggerState({
    ...props,
    delay: ANIMATION_DURATION_MS,
    closeDelay: ANIMATION_DURATION_MS,
  });
  const triggerRef = useRef(null);
  const { triggerProps, tooltipProps } = useTooltipTrigger(
    props,
    state,
    triggerRef,
  );
  const { isOpen } = state;
  const portal = portalNode || mountedInModal || document.body;

  const renderedTrigger =
    typeof children === 'function' ? children({ isOpen }) : children;

  const ref = useRef<HTMLDivElement>(null);

  const tooltip = (
    <Transition
      in={isOpen}
      appear
      mountOnEnter
      unmountOnExit
      nodeRef={ref}
      timeout={ANIMATION_DURATION_MS}
    >
      {(transitionState) => (
        <div ref={ref}>
          <BaseTooltip
            {...props}
            {...tooltipProps}
            state={state}
            className={cx(className, transitions[transitionState])}
            anchor={anchorEl as Element}
          >
            {content}
          </BaseTooltip>
        </div>
      )}
    </Transition>
  );

  return (
    <>
      {portal ? createPortal(tooltip, portal) : tooltip}
      <div
        {...omit(triggerProps, 'onClick')}
        ref={setAnchorEl}
        className={cx(trigger, classes.triggerContainer)}
      >
        {renderedTrigger}
      </div>
    </>
  );
}
