import cx from 'classnames';
import first from 'lodash/first';

import { TableCell } from 'deprecated/mui';
import SortDefault from 'shared/assets/svgs/sort-default.svg?react';
import SortDown from 'shared/assets/svgs/sort-down.svg?react';
import SortUp from 'shared/assets/svgs/sort-up.svg?react';
import type {
  CompositeSortDirection,
  SortDirection,
} from 'shared/types/sorting.types';
import { Sort } from 'shared/types/sorting.types';

import {
  cellContents,
  headerCell,
  sortArrows,
  sortIcon,
  sortable,
} from './HeaderCell.css';

export type ColumnType = 'default' | 'actions';

export type Props = {
  type?: ColumnType;
  children: string | React.ReactNode;
  className?: string;
  sortDir?: CompositeSortDirection;
  onSort?: Nullable<(sortDir?: CompositeSortDirection) => void>;
  colSpan?: number;
};

export function HeaderCell({
  children,
  className,
  sortDir,
  onSort,
  type = 'default',
  colSpan = 1,
}: Props) {
  const isSortable = Boolean(onSort);
  return (
    <TableCell
      colSpan={colSpan}
      className={cx(headerCell.left, className, {
        [sortable]: isSortable,
        [headerCell.actions]: type === 'actions',
      })}
      onClick={() => onSort?.(sortDir)}
    >
      <div className={cellContents}>
        {children}
        {isSortable && <SortArrows sort={first(sortDir)} />}
      </div>
    </TableCell>
  );
}

function SortArrows({ sort }: { sort?: Nullable<SortDirection> }) {
  let icon;
  switch (sort) {
    case Sort.Ascending:
      icon = <SortUp className={sortIcon} />;
      break;
    case Sort.Descending:
      icon = <SortDown className={sortIcon} />;
      break;
    default:
      icon = <SortDefault className={sortIcon} />;
  }
  return (
    <div className={sortArrows} role="button" tabIndex={0}>
      {icon}
    </div>
  );
}
