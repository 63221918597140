import cx from 'classnames';
import type { ForwardedRef, InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { FocusRing } from 'react-aria';

import type { MaskWithDefinitions } from 'shared/common/Form/fields/shared/InputMask';
import { InputMask } from 'shared/common/Form/fields/shared/InputMask';
import { focusRingCss } from 'shared/tempo/util/FocusRing/FocusRing.css';

import { input } from './Input.css';

export type InputProps = {
  hasError?: boolean;
  mask?: string | MaskWithDefinitions;
} & InputHTMLAttributes<HTMLInputElement>;

function InputBase(
  { className, hasError, ...props }: InputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const InputCmp = props.mask ? InputMask : 'input';

  return (
    <FocusRing
      isTextInput
      focusRingClass={
        hasError ? focusRingCss.keyboardWithError : focusRingCss.keyboard
      }
    >
      <InputCmp
        {...props}
        ref={ref}
        className={cx(
          {
            [input.default]: !hasError,
            [input.error]: hasError,
          },
          className,
        )}
      />
    </FocusRing>
  );
}

export const Input = forwardRef<HTMLInputElement, InputProps>(InputBase);
