import { isValid, set } from 'date-fns';
import omit from 'lodash/omit';
import { useMemo } from 'react';

import { getUserTimezone } from 'shared/utils/time-helpers';

import type {
  EncounterModuleInstance,
  TimeEntry,
  TimeTracking,
} from '../../Notes.types';
import { useIsTimeTrackedTypeOfEncounterFromInstances } from './useIsTimeTrackedTypeOfEncounter';

export function useMarshaledTimeTrackingPayload(timeEntry: Partial<TimeEntry>) {
  return useMemo(() => {
    const timeEntryDateTime = getDateTimeFromTimeEntry(timeEntry);
    return {
      ...omit(timeEntry, ['start_date', 'start_time']),
      ...(timeEntryDateTime && {
        start_datetime: timeEntryDateTime.toISOString(),
      }),
      timezone: getUserTimezone(),
    };
  }, [timeEntry]) as TimeTracking;
}

export function useMarshaledTimeTrackingPayloadByEncounter(
  timeEntry: Partial<TimeEntry>,
  encounterModuleInstances: EncounterModuleInstance[],
) {
  const marshalled = useMarshaledTimeTrackingPayload(timeEntry);
  const isTimeTrackedTypeOfEncounterFromInstances =
    useIsTimeTrackedTypeOfEncounterFromInstances(encounterModuleInstances);
  return useMemo(() => {
    if (isTimeTrackedTypeOfEncounterFromInstances) {
      return marshalled;
    }
    return {};
  }, [isTimeTrackedTypeOfEncounterFromInstances, marshalled]) as
    | TimeTracking
    | Record<string, never>;
}

export function getDateTimeFromTimeEntry(timeEntry: Partial<TimeEntry>) {
  if (
    timeEntry.start_date &&
    timeEntry.start_time &&
    isValid(timeEntry.start_date)
  ) {
    return set(timeEntry.start_date, {
      hours: Number(timeEntry.start_time.split(':')[0]),
      minutes: Number(timeEntry.start_time.split(':')[1]),
      seconds: 0,
    });
  }

  return null;
}
