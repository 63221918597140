import type { QueryKey, UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import {
  type PatientContextVitals,
  TelemetryService,
} from 'shared/generated/grpcGateway/telemetry.pb';
import { usePatientDetails } from 'shared/hooks/queries';
import { useHealthSystemById } from 'shared/hooks/queries/health-system.queries';
import type { MmolLs, Pascals } from 'shared/types/units';
import { idToGrpcName } from 'shared/utils/grpc';
import { convertToMgDl, convertToMmHg } from 'shared/utils/unit-helpers';

const PATIENT_CONTEXT_VITALS_QUERY_KEY_BASE = ['telemetry', 'v1'] as const;

const patientContextVitalsKeys = {
  context: (patientId: string) =>
    [
      ...PATIENT_CONTEXT_VITALS_QUERY_KEY_BASE,
      patientId,
      'contextVitals',
    ] as const,
};

export function usePatientVitalsContext(
  patientId: string,
  params: UseQueryOptions<PatientContextVitals> = {},
) {
  return useQuery(
    patientContextVitalsKeys.context(patientId) as QueryKey,
    () =>
      TelemetryService.GetPatientContextVitals({
        name: idToGrpcName('patients', patientId),
      }),
    { ...params, enabled: Boolean(patientId) },
  );
}

export type PatientVitalAvgAndGoals = {
  bgAvg30d?: number;
  a1c?: number;
  systolicAvg30d?: number;
  diastolicAvg30d?: number;
  bgGoal?: number;
  a1cGoal?: number;
  systolicGoal?: number;
  diastolicGoal?: number;
};

export function usePatientVitalAvgAndGoals(patientId: Maybe<string>): {
  vitalValuesAndGoals: PatientVitalAvgAndGoals | null;
  isLoading: boolean;
  healthSystemId: Maybe<string>;
} {
  const { data: patientDetails, isLoading: isPatientDetailsLoading } =
    usePatientDetails(patientId || '', false, Boolean(patientId));

  const {
    data: patientVitalsContext,
    isLoading: isPatientVitalsContextLoading,
  } = usePatientVitalsContext(patientDetails?.id || '', {
    enabled: Boolean(patientDetails),
  });

  const contextVitals = patientVitalsContext?.contextVitals;

  const { data: pbPatientDetails, isLoading: isPbPatientDetailsLoading } =
    usePatientDetails(patientDetails?.id || '', true, Boolean(patientDetails));
  const healthSystemId =
    pbPatientDetails?.ehrInformation?.hospital?.healthSystem?.name;
  const { data: healthSystem, isLoading: isHealthSystemLoading } =
    useHealthSystemById(healthSystemId ?? '', {
      enabled: Boolean(healthSystemId),
    });

  const isMissingData =
    !patientDetails || !contextVitals || !pbPatientDetails || !healthSystem;

  return {
    healthSystemId,
    isLoading:
      isPatientDetailsLoading ||
      isPatientVitalsContextLoading ||
      !contextVitals ||
      isPbPatientDetailsLoading ||
      isHealthSystemLoading,
    vitalValuesAndGoals: isMissingData
      ? null
      : // Only get positive numbers and undefined otherwise (when null or 0) to keep things simple
        {
          bgAvg30d: convertToMgDlOrUndefined(
            contextVitals.bloodGlucose?.avg30d,
          ),
          a1c:
            patientDetails.patient_profile?.t2d_profile?.t2d_data?.a1c ||
            undefined,
          systolicAvg30d: convertToMmHgOrUndefined(
            contextVitals.bloodPressure?.systolic?.avg30d,
          ),
          diastolicAvg30d: convertToMmHgOrUndefined(
            contextVitals.bloodPressure?.diastolic?.avg30d,
          ),
          bgGoal: convertToMgDlOrUndefined(healthSystem.bg_goal),
          a1cGoal: healthSystem.a1c_goal || undefined,
          systolicGoal: convertToMmHgOrUndefined(healthSystem.systolic_goal),
          diastolicGoal: convertToMmHgOrUndefined(healthSystem.diastolic_goal),
        },
  };
}

function convertToMgDlOrUndefined(mmolLs?: MmolLs) {
  return mmolLs ? convertToMgDl(mmolLs) : undefined;
}

function convertToMmHgOrUndefined(pascal?: Pascals) {
  return pascal ? convertToMmHg(pascal) : undefined;
}
