import { FormattedMessage } from 'react-intl';

import {
  Script,
  YesNoRadioGroup,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { scriptLabel } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';
import { Form } from 'shared/common/Form';
import type { ConfiguredForm } from 'shared/common/Form/FormContainer';

import type { TobaccoUseFormFields } from './formConfig';

type Props = {
  form: ConfiguredForm<TobaccoUseFormFields>;
};

export function TobaccoUseForm({ form }: Props) {
  const sectionVisibility = useSectionVisibility(form);

  return (
    <>
      <YesNoRadioGroup
        name="currentTobaccoUse"
        classes={{ label: scriptLabel }}
        label={
          <Script>
            <FormattedMessage defaultMessage="Do you currently smoke or chew tobacco? This includes cigarettes, cigars, e-cigarettes and chewing tobacco." />
          </Script>
        }
      />
      {sectionVisibility.currentTobaccoUseDescription && (
        <>
          <Script>
            <FormattedMessage defaultMessage="What kind and how much?" />
          </Script>
          <Form.TextField
            size={12}
            name="currentTobaccoUseDescription"
            label={<FormattedMessage defaultMessage="Current tobacco use" />}
            required
          />
        </>
      )}
      {sectionVisibility.pastTobaccoUse && (
        <>
          <YesNoRadioGroup
            name="pastTobaccoUse"
            classes={{ label: scriptLabel }}
            label={
              <Script>
                <FormattedMessage defaultMessage="Have you used tobacco in the past?" />
              </Script>
            }
          />
        </>
      )}
      {sectionVisibility.pastTobaccoUseDescription && (
        <>
          <Script>
            <FormattedMessage defaultMessage="When did you quit?" />
          </Script>
          <Form.TextField
            size={12}
            name="pastTobaccoUseDescription"
            label={
              <FormattedMessage defaultMessage="Last date of tobacco use" />
            }
            required
          />
        </>
      )}
    </>
  );
}

// form.watch(...) instead of form.getValues(...) is important here because
// react-hook-form doesn't cause a component re-render when values are changed.
// if we watch the values, we'll re-render when we need to.
function useSectionVisibility(form: ConfiguredForm<TobaccoUseFormFields>) {
  const [currentTobaccoUse, pastTobaccoUse] = form.watch([
    'currentTobaccoUse',
    'pastTobaccoUse',
  ]);

  return {
    currentTobaccoUseDescription: currentTobaccoUse === 'true',
    pastTobaccoUse:
      typeof currentTobaccoUse !== 'undefined' && currentTobaccoUse === 'false',
    pastTobaccoUseDescription: pastTobaccoUse === 'true',
  };
}
