import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import {
  FieldGroup,
  Script,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { formatProviderForScript } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/script.utils';
import { usePatientDiseaseStateI18n } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/usePatientDiseaseStateI18n';
import { Form } from 'shared/common/Form';
import { Wizard } from 'shared/common/Wizard';
import { useWizardFormFromConfig } from 'shared/common/Wizard/state';
import { usePatientContext } from 'shared/hooks/queries';

import { useCNFormContext } from '../../../CNFormContext';
import { CYCLE_17_INIT_VISIT_SECTIONS } from '../metadata';
import { cycle17InitVisitPath } from '../paths';

export type FormFields = {
  additionalNotes?: string;
};

export function IntroQuestions() {
  const intl = useIntl();
  const { patientId } = useCNFormContext();
  const { data: context, isLoading: isLoadingContext } =
    usePatientContext(patientId);
  const { diseaseState, isLoading: isLoadingDiseaseState } =
    usePatientDiseaseStateI18n(patientId);

  const form = useWizardFormFromConfig<FormFields>(
    cycle17InitVisitPath('/intro', '/questions'),
    {
      fields: {
        additionalNotes: {
          defaultValue: undefined,
          validation: yup.string(),
        },
      },
    },
  );

  return (
    <Wizard.Step
      isLoading={!context || isLoadingContext || isLoadingDiseaseState}
      form={form}
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      title={intl.formatMessage({ defaultMessage: 'Introduction' })}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage
            defaultMessage="The goal of this program is to support your care between appointments with {provider} and avoid complications that can result in having to go to the hospital or ER. To maintain an accurate picture of your health and provide you with the best support, we recommend that you check readings every day. You will have telephone appointments with us every 2-3 months or sooner if your {diseaseState} seems to be off track."
            values={{
              diseaseState,
              provider: formatProviderForScript(
                context?.primaryPhysicianGivenName,
                context?.primaryPhysicianFamilyName,
                context?.primaryPhysicianRole,
              ),
            }}
          />
        </Script>
        <Script>
          <FormattedMessage defaultMessage="We will set a health goal together to work on and I will provide some tips for helping you to meet your goal and stay healthy. The length of the program is ultimately up to you and your provider, but we would love to work with you as long as possible. Staying in good health is a lifelong process." />
        </Script>
        <Script>
          <FormattedMessage defaultMessage="Before we move forward, are there any other questions that I can answer about what we will accomplish together?" />
        </Script>
        <Form.TextArea
          size={12}
          rows={3}
          name="additionalNotes"
          label={<FormattedMessage defaultMessage="Additional notes" />}
        />
      </FieldGroup>
    </Wizard.Step>
  );
}
