import type { History, Action as HistoryAction, Location } from 'history';
import { useReducer, useState } from 'react';

import { useOnMount } from 'shared/hooks/useOnMount';

export const EMPTY_WIZARD_STATE: WizardFormData = {
  navigationHistory: [],
  data: {},
};

// Form values are null when no form is present for that section
export type StepFormValues = Nullable<Record<string, unknown>>;
// True or false directly modifies completion, preserve keeps current value
export type StepCompletion = boolean | 'preserve';
type StepState = {
  elapsed: number;
  isComplete: boolean | 'preserve';
  values: StepFormValues;
};
export type SectionStepsState = {
  [fullStepPath: string]: StepState | undefined;
};

export type WizardFormData = {
  data: SectionStepsState;
  navigationHistory: History.Path[];
};

enum ActionType {
  Reset = 'reset',
  StepSubmit = 'step-submit',
  StepNavigate = 'step-navigate',
  AssociateZendeskTicket = 'associate-zendesk-ticket',
}

type ActionCallback = {
  onSuccess?: (newState: WizardFormData) => void;
};

type Action =
  | ({ type: ActionType.Reset } & ActionCallback)
  | ({
      type: ActionType.StepSubmit;
      fullStepPath: string;
      values: StepFormValues;
      isComplete: StepCompletion;
      elapsed: number;
    } & ActionCallback)
  | ({
      type: ActionType.StepNavigate;
      historyAction: HistoryAction;
      fullStepPath: string;
    } & ActionCallback);

function storeReducer(state: WizardFormData, action: Action): WizardFormData {
  const { onSuccess } = action;

  if (action.type === ActionType.Reset) {
    onSuccess?.(EMPTY_WIZARD_STATE);
    return EMPTY_WIZARD_STATE;
  }

  const preUpdateStep = state.data[action.fullStepPath];
  if (action.type === ActionType.StepSubmit) {
    const newState = {
      ...state,
      data: {
        ...state.data,
        [action.fullStepPath]: {
          values: action.values,
          isComplete:
            action.isComplete === 'preserve'
              ? Boolean(state.data[action.fullStepPath]?.isComplete)
              : action.isComplete,
          elapsed: (preUpdateStep?.elapsed ?? 0) + action.elapsed,
        },
      },
    };
    onSuccess?.(newState);
    return newState;
  }
  if (action.type === ActionType.StepNavigate) {
    const { fullStepPath, historyAction } = action;

    let history = [...state.navigationHistory];
    if (historyAction === 'PUSH') {
      history.push(fullStepPath);
    } else if (historyAction === 'POP') {
      history = history.slice(0, -1);
    } else if (historyAction === 'REPLACE') {
      history = [...history.slice(0, -1), fullStepPath];
    }

    const newState = {
      ...state,
      navigationHistory: history,
    };
    onSuccess?.(newState);
    return newState;
  }
  return state;
}

export function useWizardState(
  initialState: WizardFormData = EMPTY_WIZARD_STATE,
  onStateChange?: (newState: WizardFormData) => void,
) {
  const [wizardFormData, dispatch] = useReducer(storeReducer, initialState);
  const [timerRunning, setTimerRunning] = useState(true);
  const [isPauseable, setIsPauseable] = useState(false);

  useOnMount(() => {
    onStateChange?.(wizardFormData);
  });

  return {
    wizardFormData,
    resetWizard: () => dispatch({ type: ActionType.Reset }),
    onNavigate: (location: Location, action: HistoryAction) => {
      dispatch({
        type: ActionType.StepNavigate,
        historyAction: action,
        fullStepPath: location.pathname,
        onSuccess: (newState) => onStateChange?.(newState),
      });
    },
    submitSectionStep(fullStepPath: string, state: StepState) {
      dispatch({
        type: ActionType.StepSubmit,
        fullStepPath,
        onSuccess: (newState) => onStateChange?.(newState),
        ...state,
      });
    },
    skipSectionStep(fullStepPath: string) {
      dispatch({
        type: ActionType.StepSubmit,
        fullStepPath,
        onSuccess: (newState) => onStateChange?.(newState),
        // Values for skipped steps
        elapsed: 0,
        isComplete: true,
        values: {},
      });
    },
    timerRunning,
    setTimerRunning,
    isPauseable,
    setIsPauseable,
  };
}
