import { CareProviderRole } from 'shared/generated/grpcGateway/pms.pb';
import { ProviderTitle } from 'shared/types/provider.types';

export function formatProviderForScript(
  firstName: string = '',
  lastName: string = '',
  role: Maybe<CareProviderRole | ProviderTitle>,
) {
  const fullName = [firstName, lastName].filter(Boolean).join(' ');
  if (!role || [CareProviderRole.OTHER, ProviderTitle.Other].includes(role)) {
    return fullName;
  }
  if (
    [
      CareProviderRole.DO,
      CareProviderRole.MD,
      ProviderTitle.DO,
      ProviderTitle.MD,
    ].includes(role)
  ) {
    return `Dr. ${fullName}`;
  }
  return `${fullName}, ${role}`;
}
