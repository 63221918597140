<p>Patient of {{primaryPhysicianGivenName}} {{primaryPhysicianFamilyName}}, successfully enrolled in Cadence RPM on {{date rpmConsentDateTime timeZone}} in-person, for {{fmtConditions rpmConditions}}. Patient was educated on and verbalized understanding of RPM program / program requirements.</p>

<p>Successfully provided FDA-cleared devices and demonstrated use of <i>[BP cuff ([BodyTrace BT105 / BodyTrace BT106 / Withings Body Connect Pro / Device Model]) and scale ([BodyTrace BT005 / BodyTrace BT006 / Withings Body Pro / Device Model]) and glucose meter ([iGlucose GM291 / Device Model])</i>.</p>
{{> free_text_space }}

<p><i>[Consent forms signed OR At the direction of {{givenName}} {{familyName}}, I provided technical assistance with filling out the consent form.]</i></p>
{{> free_text_space }}

<p>{{givenName}} {{familyName}} provided verbal consent on {{date rpmConsentDateTime timeZone}}, for Cadence to discuss their health and treatment with <i>[Caregivers name]</i>; no objection noted. Reviewed Cadence satisfaction survey with patient and <i>[confirmed willingness to receive surveys OR objected to receive surveys]</i>. Cadence contacts have been entered in the assigned mobile phone and readings were successfully received. Initial intake phone call scheduled.</p>

<p>Vitals at time of enrollment BP <i>[]</i>, HR <i>[]</i>, weight <i>[]</i> lbs, blood glucose <i>[]</i>. <i>[Escalation not required / Escalation required &amp; note on action taken]</i>.</p>
