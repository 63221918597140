import { useEffect } from 'react';

import { usePaginatedQuery } from 'reactQuery';
import type { NoteLabel } from 'shared/types/noteLabels.types';

export function useFetchNoteLabels() {
  const {
    infiniteQuery: { fetchNextPage },
    items,
    hasMoreToFetch,
  } = usePaginatedQuery<NoteLabel>(
    '/pms/api/v1/labels?sort_by=name&order_by=asc',
  );

  // Fetch all labels upfront. This is needed to render all the note labels
  // belonging to a note. This is not too expensive because the payload for
  // labels is small and is something that won't scale linearly over time
  useEffect(() => {
    if (hasMoreToFetch) {
      // Wait for the next render loop so that hasMoreToFetch can update with
      // every new load
      setTimeout(fetchNextPage, 0);
    }
  }, [hasMoreToFetch, fetchNextPage]);

  return {
    noteLabels: items,
    isFetchingNoteLabels: hasMoreToFetch,
  };
}
