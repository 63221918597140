import type { ReactChild, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { VitalType } from 'shared/types/vitals.types';

import {
  legendContainer,
  legendDot,
  legendText,
} from './VitalsGraphLegend.css';

type Props = {
  vitalType: VitalType;
};

export function VitalsGraphLegend({ vitalType }: Props) {
  let legend: ReactChild[];
  switch (vitalType) {
    case VitalType.BloodPressure:
      legend = [
        <LegendItem
          key={`${VitalType.BloodPressure}-secondary`}
          variant="secondary"
          text={<FormattedMessage defaultMessage="BP (Sys) in mmHg" />}
        />,
        <LegendItem
          key={`${VitalType.BloodPressure}-primary`}
          variant="primary"
          text={<FormattedMessage defaultMessage="BP (Dia) in mmHg" />}
        />,
      ];
      break;
    case VitalType.HeartRate:
      legend = [
        <LegendItem
          variant="primary"
          key={VitalType.HeartRate}
          text={
            <FormattedMessage
              defaultMessage="HR"
              description="Abbreviation for Heart Rate"
            />
          }
        />,
      ];
      break;
    case VitalType.Weight:
      legend = [
        <LegendItem
          key={VitalType.Weight}
          variant="primary"
          text={<FormattedMessage defaultMessage="Weight" />}
        />,
      ];
      break;
    case VitalType.BloodGlucose:
      legend = [
        <LegendItem
          key={VitalType.BloodGlucose}
          variant="primary"
          text={<FormattedMessage defaultMessage="Blood glucose in mg/dL" />}
        />,
      ];
      break;
    default:
      legend = [];
  }

  return <div className={legendContainer}>{[...legend]}</div>;
}

type LegendItemProps = {
  text: ReactElement;
  variant: 'primary' | 'secondary';
};

function LegendItem({ variant, text }: LegendItemProps) {
  return (
    <div className={legendText}>
      <div className={legendDot[variant]} />
      <span>{text}</span>
    </div>
  );
}
