import isEmpty from 'lodash/isEmpty';

import { PatientStatusEnum } from 'shared/generated/grpcGateway/pms.pb';
import { usePatientDetails } from 'shared/hooks/queries';

import { getCcmConditionsFromProgramAndStatus } from './conditions.utils';

export function useIsCcmParticipating(
  patientId: string,
  allowedStatuses: PatientStatusEnum[] = [
    PatientStatusEnum.ENROLLED,
    PatientStatusEnum.DISENROLLED,
  ],
) {
  const { data: patientDetails, isLoading } = usePatientDetails(
    patientId,
    false,
  );
  const { data: patientDetailsGrpc, isLoading: isLoadingGrpc } =
    usePatientDetails(patientId, true);
  const validPatientStatus =
    patientDetailsGrpc?.patient?.status &&
    allowedStatuses.includes(patientDetailsGrpc.patient.status);
  const ccmConditions = getCcmConditionsFromProgramAndStatus(
    patientDetails?.programs,
    patientDetails?.status,
  );
  const hasCcmConditions = !isEmpty(ccmConditions);
  const isCcmParticipating =
    hasCcmConditions && validPatientStatus && patientDetails?.ccm_consent_date;

  return {
    isLoading: isLoading || isLoadingGrpc,
    isCcmParticipating: !isLoading && !!isCcmParticipating,
  };
}
