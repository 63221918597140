import { ConditionProgram } from 'shared/types/condition.types';

import { chf as chfDotZero } from '../../../3.0.0/initialNpVisit';
import { generalAssessmentAndPlanModule as generalAssessmentAndPlanModuleDotOne } from './assessmentAndPlanModule';

export const chf = {
  [ConditionProgram.CHF]: {
    ...chfDotZero[ConditionProgram.CHF],
    ...generalAssessmentAndPlanModuleDotOne,
  },
};
