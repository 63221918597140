import { EditorState, convertFromRaw } from 'draft-js';

import { convertRtfBodyToRawDraftContentState } from 'pages/patients/patientDetails/ui/Notes/utils/rtfBodyUtil';
import type { TNoteBodyRTF } from 'shared/types/note.types';

import { getRtfContentDecorator } from './RtfLink/utils';

export const convertToEditorState = (editorContent: TNoteBodyRTF) => {
  const content = convertFromRaw(
    convertRtfBodyToRawDraftContentState(editorContent),
  );
  return EditorState.createWithContent(content, getRtfContentDecorator());
};
