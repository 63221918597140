import { useState } from 'react';
import type { IntlShape } from 'react-intl';
import { FormattedMessage, useIntl } from 'react-intl';
import type { UseInfiniteQueryResult } from 'react-query';

import { useFlatPages } from 'reactQuery';
import { DataTableRows } from 'shared/common/@deprecated/Table';
import { SkeletonTable } from 'shared/common/SkeletonTable';
import type { HeaderColumn } from 'shared/common/Table';
import { Table, useTableSort } from 'shared/common/Table';
import { getSortParams } from 'shared/common/Table/sort/sort.utils';
import type {
  NotificationAudit,
  NotificationsAuditQueryParams,
  SortableNotificationsAuditOrderFields,
} from 'shared/hooks/queries';
import {
  useInfinitePatientNotificationsAudit,
  usePatientDetails,
} from 'shared/hooks/queries';
import {
  Segment,
  SegmentedButtonGroup,
} from 'shared/tempo/@labs/molecule/SegmentedButtonGroup';
import { Checkbox } from 'shared/tempo/atom/Checkbox';
import type { PaginatedData } from 'shared/types/pagination.types';

import { CommunicationRow } from './CommunicationRow';
import { filtersContainer, table } from './CommunicationsTable.css';

function getColumnHeaders(
  intl: IntlShape,
): HeaderColumn<SortableNotificationsAuditOrderFields>[] {
  return [
    {
      title: intl.formatMessage({ defaultMessage: 'Type' }),
      sortKey: ['notification_type'],
    },
    {
      title: intl.formatMessage({ defaultMessage: 'Date Sent' }),
      sortKey: ['timestamp'],
    },
    {
      title: intl.formatMessage({ defaultMessage: 'Status' }),
      sortKey: ['notification_status'],
    },
    { title: intl.formatMessage({ defaultMessage: 'Title / Content' }) },
  ];
}

type Props = {
  patientId: string;
};

const ALL_FILTER_TYPE = '__all_filter_type__';

export function CommunicationsTable({ patientId }: Props) {
  const intl = useIntl();
  const columnHeaders = getColumnHeaders(intl);
  const [onlyShowDelivered, setOnlyShowDelivered] = useState(false);

  const { isLoading: isLoadingPatient, data: patient } = usePatientDetails(
    patientId || '',
    true,
    Boolean(patientId),
  );

  const [filterType, setFilterType] =
    useState<NotificationsAuditQueryParams['notificationType']>();
  const { sortState, handleSort } =
    useTableSort<SortableNotificationsAuditOrderFields>({
      sortKey: ['timestamp'],
      sortDir: ['desc'],
    });

  const { sortKey, sortDir } = getSortParams(sortState);
  const infiniteQuery = useInfinitePatientNotificationsAudit(patientId, {
    ...(sortKey?.[0] && { sortBy: sortKey[0] }),
    ...(sortDir?.[0] && { orderBy: sortDir[0] }),
    ...(onlyShowDelivered && { notificationStatus: 'DELIVERED' }),
    notificationType: filterType,
  });

  const { isLoading: isLoadingComms } = infiniteQuery;
  const notifications = useFlatPages(infiniteQuery);

  const isLoading = isLoadingComms || isLoadingPatient;

  return (
    <>
      <div className={filtersContainer}>
        <SegmentedButtonGroup
          size="small"
          isDisabled={isLoading}
          value={!filterType ? ALL_FILTER_TYPE : filterType}
          onChange={(val) =>
            val === ALL_FILTER_TYPE
              ? setFilterType(undefined)
              : setFilterType(
                  val as NotificationsAuditQueryParams['notificationType'],
                )
          }
        >
          <Segment value={ALL_FILTER_TYPE}>
            <FormattedMessage defaultMessage="All" />
          </Segment>
          <Segment value="SMS">
            <FormattedMessage defaultMessage="SMS" />
          </Segment>
          <Segment value="EMAIL">
            <FormattedMessage defaultMessage="EMAIL" />
          </Segment>
        </SegmentedButtonGroup>
        <Checkbox
          isSelected={onlyShowDelivered}
          isDisabled={isLoading}
          onChange={(isSelected) => setOnlyShowDelivered(isSelected)}
        >
          <FormattedMessage defaultMessage="Show Delivered Only" />
        </Checkbox>
      </div>
      <Table.Container className={table.wrapper}>
        <Table stickyHeader>
          <Table.Header
            columns={columnHeaders}
            onSort={handleSort}
            sortValue={sortState}
          />
          <TableBody
            patientTimezone={patient?.patient?.timeZone}
            infiniteQuery={infiniteQuery}
            columns={columnHeaders}
            notifications={notifications}
          />
        </Table>
      </Table.Container>
    </>
  );
}

function TableBody({
  patientTimezone,
  notifications,
  columns,
  infiniteQuery,
}: {
  patientTimezone?: string;
  notifications: Maybe<NotificationAudit[]>;
  columns: HeaderColumn<string>[];
  infiniteQuery: UseInfiniteQueryResult<PaginatedData<NotificationAudit>>;
}) {
  const { isLoading, hasNextPage, fetchNextPage, isFetching } = infiniteQuery;
  if (isLoading) {
    return (
      <Table.Body>
        <SkeletonTable columns={columns} />
      </Table.Body>
    );
  }

  return (
    <Table.Body>
      {!notifications?.length ? (
        <Table.Row>
          <Table.NodeCell colSpan={columns.length}>
            <FormattedMessage defaultMessage="No communications have been found that were sent to this patient" />
          </Table.NodeCell>
        </Table.Row>
      ) : (
        <DataTableRows
          onPageRequested={fetchNextPage}
          isFetching={isFetching}
          hasNextPage={Boolean(hasNextPage)}
          columns={columns}
        >
          {notifications.map((entry) => (
            <CommunicationRow
              key={entry.id}
              entry={entry}
              patientTimezone={patientTimezone}
            />
          ))}
        </DataTableRows>
      )}
    </Table.Body>
  );
}
