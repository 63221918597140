import type { FieldValues } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Session from 'shared/utils/session';

import { bulkUpdateGoals, updateGoal } from './patientGoals.requests';
import type { Goal } from './patientGoals.types';
import { getGoalIdFromFormName, sortGoals } from './patientGoals.utils';

type GoalToggledContext = {
  previousGoals?: Goal[];
};

type GoalsResponse = {
  data: Goal[];
};

function getGoalsListQueryKey(patientId: string) {
  return ['goals', patientId];
}

export function useFetchPatientGoals(patientId: string) {
  return useQuery(getGoalsListQueryKey(patientId), async () => {
    const { data } = await Session.Api.get<GoalsResponse>(
      `/pms/api/v1/patients/${patientId}/goals`,
    );

    return sortGoals(data.data);
  });
}

export function useBulkUpdateGoals(patientId: string, onSuccess: () => void) {
  const queryClient = useQueryClient();
  const queryKey = getGoalsListQueryKey(patientId);

  return useMutation(
    (newList: FieldValues) => {
      const toUpdate = Object.entries(newList)
        .map(([name, value]) => {
          const id = getGoalIdFromFormName(name);

          return {
            text: value,
            ...(typeof id !== 'undefined' && { id }),
          };
        })
        .filter((goal) => goal.text || goal.id);

      return bulkUpdateGoals(patientId, toUpdate);
    },
    {
      onSuccess: (newGoals) => {
        queryClient.setQueryData(queryKey, newGoals);
        onSuccess();
      },
    },
  );
}

export function useToggleGoal(patientId: string) {
  const queryClient = useQueryClient();
  const queryKey = getGoalsListQueryKey(patientId);

  return useMutation<Goal, unknown, number, GoalToggledContext>(
    // somewhat annoyingly, `onMutate` is called _before_ the actual mutation function. since we're
    // doing an optimistic update here, our goal is actually already updated in queryClient's state,
    // so we just pull out the data and make our PUT request. feels weird.
    (goalId: number) => {
      const goals = queryClient.getQueryData<Goal[]>(queryKey);

      if (!goals) {
        throw new Error(
          `Attempting to update goal ${goalId} when no goals exist.`,
        );
      }

      const toUpdate = goals.find((goal) => goalId === goal.id);

      if (!toUpdate) {
        throw new Error(
          `Attempting to update goal ${goalId} when it does not exist in the goals list.`,
        );
      }

      return updateGoal(
        patientId,
        toUpdate.id,
        toUpdate.is_completed,
        toUpdate.text,
      );
    },
    {
      onMutate: (goalId) => {
        const previousGoals = queryClient.getQueryData<Goal[]>(queryKey);

        if (!previousGoals) {
          return {};
        }

        const idx = previousGoals.findIndex((goal) => goal.id === goalId);
        const toUpdate = previousGoals[idx];
        const newGoals = [...previousGoals];

        newGoals[idx] = {
          ...toUpdate,
          is_completed: !toUpdate.is_completed,
        };

        queryClient.setQueryData(queryKey, newGoals);

        return { previousGoals };
      },
      onError: (err, goal, context) => {
        queryClient.setQueryData(queryKey, context?.previousGoals);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    },
  );
}
