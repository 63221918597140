import cx from 'classnames';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import { FormattedMessage } from 'react-intl';

import AlertTriangle from 'shared/assets/svgs/alertTriangle.svg?react';
import { bodyDefault, bodyStrong } from 'shared/jsStyle/typography.css';

import type { TypeOfEncounter } from '../../Notes.types';
import {
  getEncounterType,
  isRegularVisitClinicianType,
} from '../../utils/encounterTypeUtils';
import type { ReportingField } from '../VisitLayout/EncounterModuleSections';
import { ReportingFields } from '../VisitLayout/EncounterModuleSections';
import { useEncounterModuleInstances } from '../hooks/useEncounterModuleInstances.hook';
import {
  errorIcon,
  errorsContainer,
  errorsList,
  errorsTitle,
} from './ValidationErrors.css';
import type {
  NoteFieldValidationResult,
  NoteFormValidationResult,
} from './noteEditorValidation';
import { hasValidationErrors } from './noteEditorValidation';

export const ValidationErrors = ({
  validationResult,
  className,
  hasRequiredMedActions,
}: {
  validationResult: NoteFormValidationResult;
  className?: string;
  hasRequiredMedActions: boolean;
}) => {
  if (!hasValidationErrors(validationResult) && !hasRequiredMedActions) {
    return null;
  }
  const results = flatten(Object.values(validationResult));

  return (
    <div className={cx(errorsContainer, className)}>
      <div className={cx(errorsTitle, bodyStrong)}>
        <AlertTriangle className={errorIcon} />
        <FormattedMessage defaultMessage="Please fix the following errors:" />
      </div>
      <ul
        className={cx(errorsList.default, bodyDefault, {
          [errorsList.twoCol]: results.length >= 3,
        })}
      >
        <ValidationErrorMessages validationResults={results} />
        {hasRequiredMedActions && (
          <li>
            <FormattedMessage defaultMessage="Medications module requires action" />
          </li>
        )}
      </ul>
    </div>
  );
};

const ValidationErrorMessages = ({
  validationResults,
}: {
  validationResults: NoteFieldValidationResult[];
}) => {
  const { encounterModuleInstances } = useEncounterModuleInstances();

  const encounterType = getEncounterType(encounterModuleInstances);

  const results = encounterType
    ? validationResults
    : // Filter reporting field error messages if no encounter type has been selected
      validationResults.filter(
        (result) =>
          !Object.values(ReportingFields).includes(
            result.params?.argument as ReportingField,
          ),
      );

  return (
    <>
      {uniqBy(results, 'message').map((fieldValidationResult, i) => (
        <li key={`${i}-${fieldValidationResult.message}`}>
          {getSectionName(
            fieldValidationResult.params?.argument as string,
            encounterType,
          )}
          {fieldValidationResult.message}
        </li>
      ))}
    </>
  );
};

function getSectionName(fieldName?: string, typeOfEncounter?: TypeOfEncounter) {
  if (fieldName === 'notes_body') {
    return isRegularVisitClinicianType(typeOfEncounter) ? (
      <FormattedMessage defaultMessage="Clinical Overview " />
    ) : (
      <FormattedMessage defaultMessage="Encounter " />
    );
  }
  return '';
}
