import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from 'shared/common/Wizard/state';

import { useCNFormContext } from '../../../CNFormContext';
import { CYCLE_17_INIT_VISIT_SECTIONS } from '../../metadata';
import type { SymptomsFormFields } from '../../shared/Symptoms';
import { SharedSymptoms, getSymptomsFormConfig } from '../../shared/Symptoms';
import { cycle17InitVisitPath } from '../paths';

export function Symptoms() {
  const intl = useIntl();
  const { program } = useCNFormContext();
  const form = useWizardFormFromConfig<SymptomsFormFields>(
    cycle17InitVisitPath('/symptoms', '/index'),
    getSymptomsFormConfig(intl, program),
  );

  return <SharedSymptoms form={form} sections={CYCLE_17_INIT_VISIT_SECTIONS} />;
}
