import type { WidgetProps } from '@rjsf/core';

import { FormControlLabel } from 'deprecated/mui';
import { Toggle } from 'shared/tempo/atom/Toggle';
import { Tooltip } from 'shared/tempo/atom/Tooltip';

import { getLabel } from '../SchemaDrivenForm';
import { formLabel } from '../SchemaDrivenForm.css';
import { getOmitExtraDataOnChange } from '../getOmitExtraDataOnChange';

export function ToggleWidget({
  onChange: rjsfOnChange,
  value,
  id,
  label: rjsfLabel,
  required,
  uiSchema: { 'ui:label': uiLabel, 'ui:options': uiOptions },
}: WidgetProps) {
  const onChange = getOmitExtraDataOnChange(rjsfOnChange);
  const label = getLabel(
    'boolean',
    id,
    uiLabel,
    rjsfLabel,
    required,
    uiOptions,
  );
  const tooltip = uiOptions?.tooltip;
  const isDisabled = !!uiOptions?.isDisabled;
  return (
    <FormControlLabel
      classes={{ label: formLabel }}
      control={
        <Tooltip content={tooltip}>
          <Toggle
            aria-label={formLabel}
            isSelected={value === true}
            isDisabled={isDisabled}
          />
        </Tooltip>
      }
      label={label ?? ''}
      onClick={() => {
        if (!isDisabled) {
          onChange(!value);
        }
      }}
      checked={value ?? false}
    />
  );
}
