import { format, isValid, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import type { HelperDeclareSpec, HelperOptions } from 'handlebars';
import isObject from 'lodash/isObject';

import { logger } from 'logger';
import { Gender as ContextGender } from 'shared/generated/grpcGateway/pms.pb';
import { displayAge } from 'shared/patient/patientInfoUtils';
import type { RpmCondition } from 'shared/types/clinicalprofile.types';
import { Condition as ContextCondition } from 'shared/types/clinicalprofile.types';
import type { Patient } from 'shared/types/patient.types';
import type { Grams, MmolLs, Pascals } from 'shared/types/units';
import { getUserTimezone } from 'shared/utils/time-helpers';
import {
  convertToLbs,
  convertToMgDl,
  convertToMmHg,
} from 'shared/utils/unit-helpers';

const NOT_AVAILABLE = 'N/A';

export interface TemplateHelpers extends HelperDeclareSpec {
  isAnAgeYearOld: (date: Maybe<string | Date | GoogleDate>) => string | number;
  date: (
    date: Maybe<string | Date | GoogleDate>,
    timezone: Maybe<string>,
  ) => string;
  weight: (grams: Maybe<Grams>) => string;
  bp: (systolic: Maybe<Pascals>, diastolic: Maybe<Pascals>) => string;
  bg: (mmolL: Maybe<MmolLs>) => string;
  hr: (pulse: Maybe<number>) => string;
  ifEqual: (
    strA: Maybe<string>,
    strB: Maybe<string>,
    options: HelperOptions,
  ) => string;
  ifTruthy: (val: Maybe<string>, options: HelperOptions) => string;
  ifContains: (
    arr: Maybe<unknown[]>,
    strB: Maybe<string>,
    options: HelperOptions,
  ) => string;
  fmtConditions: (conditions: Maybe<RpmCondition[]>) => string;
  fmtGender: (gender: Maybe<ContextGender | Patient['gender']>) => string;
  array: (...args: Maybe<unknown>[]) => unknown[];
}

/**
 * These helpers can be used from within handlebars templates to
 * format/transform data
 */
export const TEMPLATE_HELPERS: TemplateHelpers = {
  isAnAgeYearOld(dob) {
    const dobDate = normalizeDate(dob);
    if (dobDate && isValid(dobDate)) {
      const age = displayAge(format(dobDate, 'yyyy-MM-dd'));
      let indefiniteArtical = 'a';
      if ([8, 11, 18, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89].includes(age)) {
        indefiniteArtical = 'an';
      }
      return `is ${indefiniteArtical} ${age} year old`;
    }
    return `is a`;
  },
  date(date, timezone) {
    const parsed = normalizeDate(date);
    if (!isValid(parsed)) {
      return NOT_AVAILABLE;
    }

    // prefer displaying the date in the patient's timezone. if we don't have
    // that for some reason, attempt to fall back to the user's timezone (this
    // is usually good enough since we don't have a lot of discrepancies between
    // clinician and patient timezones). if for some reason that fails, fall back
    // to just letting date-fns do whatever it wants with the formatting. it
    // should be identical to formatInTimeZone but who knows..
    try {
      const userTimezone = getUserTimezone();
      return formatInTimeZone(parsed, timezone || userTimezone, 'MM/dd/yyyy');
    } catch (ex) {
      return format(parsed, 'MM/dd/yyyy');
    }
  },
  weight(grams) {
    if (grams !== undefined && grams !== null) {
      return `${roundDecimalDigit(convertToLbs(grams))} lbs`;
    }
    return NOT_AVAILABLE;
  },
  bp(systolic, diastolic) {
    if (systolic && diastolic) {
      const systolicDisplay = systolic
        ? roundDecimalDigit(convertToMmHg(systolic))
        : '--';
      const diastolicDisplay = diastolic
        ? roundDecimalDigit(convertToMmHg(diastolic))
        : '--';
      return `${systolicDisplay}/${diastolicDisplay}`;
    }
    return NOT_AVAILABLE;
  },
  bg(mmolL) {
    if (mmolL !== undefined && mmolL !== null) {
      return `${roundDecimalDigit(convertToMgDl(mmolL))} mg/dL`;
    }
    return NOT_AVAILABLE;
  },
  hr(pulse) {
    if (pulse !== undefined && pulse !== null) {
      return `${roundDecimalDigit(pulse)} bpm`;
    }
    return NOT_AVAILABLE;
  },
  ifEqual(strA, strB, options) {
    if (strA === strB) {
      return options.fn(this);
    }
    return options.inverse(this);
  },
  ifContains(arr, str, options) {
    if (Array.isArray(arr) && arr?.includes(str)) {
      return options.fn(this);
    }
    return options.inverse(this);
  },
  ifTruthy(val, options) {
    if (val) {
      return options.fn(this);
    }
    return options.inverse(this);
  },
  fmtConditions(conditions) {
    if (!conditions?.length) {
      return NOT_AVAILABLE;
    }
    return conditions
      .map((c) => {
        switch (c) {
          case ContextCondition.COPD:
            return 'COPD';
          case ContextCondition.CHF:
            return 'Heart Failure';
          case ContextCondition.Hypertension:
            return 'Hypertension';
          case ContextCondition.TypeTwoDiabetes:
            return 'Type 2 Diabetes';
          default:
            logger.error(
              `Unknown value '${c}' for condition found while templating for notes`,
            );
            return 'Unknown Condition';
        }
      })
      .join(' + ');
  },
  fmtGender(val) {
    if (!val) {
      return NOT_AVAILABLE;
    }
    switch (val) {
      case ContextGender.FEMALE:
      case 'FEMALE':
        return 'female';
      case ContextGender.MALE:
      case 'MALE':
        return 'male';
      case ContextGender.GENDER_UNSPECIFIED:
        return 'gender unspecified';
      case ContextGender.GENDER_OTHER:
      case 'OTHER':
        return 'other gender';
      case 'X':
        return 'legal sex X';
      default:
        logger.error(
          `Unknown value '${val}' for gender found while templating for notes`,
        );
        return 'unknown gender';
    }
  },
  array(...args) {
    // Create an array from arguments, ignoring the last element which is the
    // options param
    return Array.prototype.slice.call(args, 0, -1);
  },
};

function roundDecimalDigit(value: number) {
  // Rounds to one decimal digit
  return Math.round(value * 10) / 10;
}

function normalizeDate(date: Maybe<string | Date | GoogleDate>) {
  if (date instanceof Date) {
    return date;
  }
  if (isObject(date)) {
    return new Date(date.year, date.month - 1, date.day);
  }
  return parseISO(date || '');
}
