import { useIntl } from 'react-intl';

import { Modal } from 'shared/common/Modal';
import { useToaster } from 'shared/tempo/molecule/Toast';
import { getGrpcErrorMessage } from 'shared/utils/helpers';

import { MedicationForm } from '../forms/MedicationForm';
import type { MedPayload } from '../forms/editMedicationFormConfig';
import { TitrationFormType } from '../forms/formTypeEnum';
import { RxNormRestriction } from '../forms/types';
import { useStartMedication } from '../patientMedications.queries';
import { modalBodyContainer } from '../styles.css';
import { useHasChf } from '../utils/useHasChf';

export function StartMedicationModal({
  patientId,
  onClose,
  existingReferencedMedIds,
}: {
  patientId: string;
  onClose: () => void;
  existingReferencedMedIds: Maybe<string>[];
}) {
  const intl = useIntl();
  const { toaster } = useToaster();

  const hasChf = useHasChf(patientId);
  const startMedMutation = useStartMedication();

  function handleSubmitForm(payload: MedPayload) {
    startMedMutation.mutate(payload, {
      onSuccess: () => {
        toaster.success(
          intl.formatMessage({
            defaultMessage: 'Successfully started new medication',
          }),
        );
        onClose();
      },
      onError: (err) => {
        toaster.error(
          intl.formatMessage(
            {
              defaultMessage: `Failed to start new medication: {message}`,
            },
            { message: getGrpcErrorMessage(err) },
          ),
        );
      },
    });
  }

  return (
    <Modal open onClose={onClose}>
      <Modal.Header
        title={intl.formatMessage({ defaultMessage: 'Start new medication' })}
      />
      <Modal.Body className={modalBodyContainer}>
        <MedicationForm
          titrationFormType={TitrationFormType.Start}
          hasChf={hasChf}
          onSubmit={handleSubmitForm}
          onClose={onClose}
          isProcessing={startMedMutation.isLoading}
          rxNormRestriction={RxNormRestriction.NON_EXISTING_MEDS}
          existingReferencedMedIds={existingReferencedMedIds}
        />
      </Modal.Body>
    </Modal>
  );
}
