import { format, parseISO } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  FieldGroup,
  Script,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { InlineMessage } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/InlineMessage';
import { formatProviderForScript } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/script.utils';
import {
  infoMessage,
  verificationValue,
  verificationsList,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/shared.css';
import { useTimeEstimate } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { LoadingPlaceholder } from 'shared/common/LoadingPlaceholder';
import { Wizard } from 'shared/common/Wizard';
import {
  useCaregivers,
  usePatientContext,
  usePatientDetails,
} from 'shared/hooks/queries';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { getRpmConditionsFromProgramAndStatus } from 'shared/patient/conditions.utils';
import { displayHomeState } from 'shared/patient/patientInfoUtils';
import {
  extractPhoneNumberFromContact,
  getPreferredPhoneContact,
} from 'shared/utils/contact.utils';
import { formatPhoneNumber } from 'shared/utils/phone';

import { useCNFormContext } from '../../../../CNFormContext';
import { INIT_VISIT_SECTIONS } from '../../../metadata';
import { AdminProfileButton } from '../../../shared/AdminProfileButton';
import { initVisitPath } from '../../paths';
import { getEmergencyContactText, getI18nPhoneType } from './i18n.utils';

export function IntroContactInfo() {
  const intl = useIntl();
  const history = useHistory();
  const { patientId } = useCNFormContext();
  const { currentUserFullName } = useCurrentUser();
  const { data: context, isLoading: isLoadingContext } =
    usePatientContext(patientId);
  const { data: patient, isLoading: isLoadingPatient } = usePatientDetails(
    patientId,
    false,
    Boolean(patientId),
  );
  const { data: caregivers, isLoading: isLoadingCaregivers } = useCaregivers(
    patientId || '',
    // The has_caregiver field means we want to use the caregiver contact as the primary contact method
    //  so we need to fetch it
    { enabled: Boolean(patientId) && patient?.has_caregiver },
  );

  const timeEstimate = useTimeEstimate(
    intl.formatMessage({ defaultMessage: '1-3 min' }),
  );

  if (
    !patient ||
    !context ||
    isLoadingPatient ||
    isLoadingContext ||
    isLoadingCaregivers
  ) {
    return <LoadingPlaceholder isLoading />;
  }

  const preferredPhoneContact = getPreferredPhoneContact(
    patient,
    caregivers || [],
  );

  const { phone: preferredPhoneNumber, type: preferredPhoneType } =
    extractPhoneNumberFromContact(preferredPhoneContact);
  const formattedPhone = preferredPhoneNumber
    ? formatPhoneNumber(preferredPhoneNumber)
    : null;

  const emergencyContact = patient?.contacts?.find(({ emergency }) => emergency)
    ?.contact;
  const formattedEmergencyContactPhone = emergencyContact?.phone_number
    ? formatPhoneNumber(emergencyContact.phone_number)
    : null;

  const rpmPrograms = getRpmConditionsFromProgramAndStatus(
    patient?.programs,
    patient?.status,
  );

  const patientName = `${patient?.first_name} ${patient?.last_name}`;
  return (
    <Wizard.Step
      sections={INIT_VISIT_SECTIONS}
      title={intl.formatMessage({ defaultMessage: 'Introduction' })}
      subtitle={timeEstimate}
      onNext={() => history.push(initVisitPath('/symptoms', '/index'))}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage
            defaultMessage="Hi, is this {patientName}? This is {username} calling from Cadence and {provider} on a recorded line."
            values={{
              username: currentUserFullName,
              provider: formatProviderForScript(
                context.primaryPhysicianGivenName,
                context.primaryPhysicianFamilyName,
                context.primaryPhysicianRole,
              ),
              patientName,
            }}
          />
        </Script>
        <Script>
          <FormattedMessage defaultMessage="Before we begin I just need to make sure I have the correct information in front of me." />
        </Script>
        <ol className={verificationsList}>
          <li>
            <FormattedMessage
              defaultMessage="Confirm patient date of birth: <verification>{dob}</verification>"
              values={{
                dob: format(parseISO(patient.dob), 'MMMM d, yyyy'),
                verification,
              }}
            />
          </li>
          <li>
            <FormattedMessage
              defaultMessage="Verify preferred number is: <verification>{preferredNumber}</verification>"
              values={{
                preferredNumber: joinContactInfo(
                  formattedPhone,
                  getI18nPhoneType(
                    intl,
                    preferredPhoneType,
                    patient.has_caregiver,
                  ),
                ),
                verification,
              }}
            />
          </li>
          <li>
            <FormattedMessage
              defaultMessage="Verify emergency contact: <verification>{emergencyContact}</verification>"
              values={{
                emergencyContact: joinContactInfo(
                  getEmergencyContactText(patient, intl),
                  formattedEmergencyContactPhone,
                  getI18nPhoneType(intl, emergencyContact?.phone_type),
                ),
                verification,
              }}
            />
          </li>
          <li>
            <FormattedMessage
              defaultMessage="Verify patient is in their home state: <verification>{homeState}</verification>"
              values={{
                homeState: displayHomeState(patient),
                verification,
              }}
            />
          </li>
        </ol>
      </FieldGroup>
      <InlineMessage className={infoMessage}>
        <FormattedMessage defaultMessage="Use the Patient Admin to update any personal information about the patient and then return here." />
      </InlineMessage>
      <AdminProfileButton />
      <FieldGroup>
        <Script>
          <FormattedMessage defaultMessage="Is now still a good time to talk? We need about 20 minutes together." />
        </Script>
        <Script>
          <FormattedMessage
            defaultMessage="I am excited to talk today. I know you are just getting started with Cadence. My job as your Clinical Navigator is to work with you on your {conditions} control."
            values={{
              conditions: rpmPrograms
                .sort()
                .join(` ${intl.formatMessage({ defaultMessage: 'and' })} `),
            }}
          />
        </Script>
        <Script>
          <FormattedMessage
            defaultMessage="I work as part of your care team to help you with behavioral and lifestyle modifications that will get you closer to the clinical goals set by {provider}."
            values={{
              provider: formatProviderForScript(
                context.primaryPhysicianGivenName,
                context.primaryPhysicianFamilyName,
                context.primaryPhysicianRole,
              ),
            }}
          />
        </Script>
        <Script>
          <FormattedMessage defaultMessage="There are also nurses and nurse practitioners on your Cadence care team who I work with closely if you are not feeling well or need any medication changes. Our goal is to keep you feeling well and out of the hospital! How does all of that sound to you?" />
        </Script>
        <Script>
          <FormattedMessage
            defaultMessage="You will have calls with us a few times a year, in between the visits you have with {provider}. And you do not have to do anything to prepare for your appointments with {provider}, we'll keep them updated on how you are doing."
            values={{
              provider: formatProviderForScript(
                context.primaryPhysicianGivenName,
                context.primaryPhysicianFamilyName,
                context.primaryPhysicianRole,
              ),
            }}
          />
        </Script>
        <Script>
          <FormattedMessage defaultMessage="Do you have any questions about the phone appointments you will have with Cadence?" />
        </Script>
        <InlineMessage>
          <FormattedMessage defaultMessage="If the patient is concerned about the volume of calls, please remind them that they are more than usual at the beginning of the program. The phone appointments space out over time." />
        </InlineMessage>
      </FieldGroup>
    </Wizard.Step>
  );
}

function verification(children: string) {
  return <span className={verificationValue}>{children}</span>;
}

function joinContactInfo(...info: Nullable<string>[]) {
  return (
    info.filter(Boolean).join(', ') || <FormattedMessage defaultMessage="N/A" />
  );
}
