import type { SvgIconProps } from 'deprecated/mui';
import { SvgIcon } from 'deprecated/mui';

const GrayCheckIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props} viewBox="0 0 16 16">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.333 3.66671C14.5934 3.92706 14.5934 4.34917 14.333 4.60952L6.4711 12.4714C6.22728 12.7152 5.83779 12.7329 5.5729 12.5122L1.5729 9.17885C1.29005 8.94314 1.25183 8.52276 1.48754 8.23991C1.72325 7.95706 2.14363 7.91884 2.42648 8.15455L5.95878 11.0981L13.3902 3.66671C13.6505 3.40636 14.0727 3.40636 14.333 3.66671Z"
        fill="#898999"
      />
    </svg>
  </SvgIcon>
);

export default GrayCheckIcon;
