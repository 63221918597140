import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';

import type { ButtonVariant } from 'shared/tempo/atom/Button';
import { Button } from 'shared/tempo/atom/Button';

type RenderProps = {
  closeDialog: () => void;
  isDialogOpen: boolean;
};
interface PatientProfileDialogTriggerButtonProps {
  children: (props: RenderProps) => ReactNode;
  buttonInfo: {
    icon?: JSX.Element;
    label: string;
    className?: string;
    variant?: ButtonVariant;
  };
}

export const PatientProfileDialogTriggerButton = ({
  children,
  buttonInfo: { icon, label, className, variant },
}: PatientProfileDialogTriggerButtonProps) => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  const toggleDialogVisibility = useCallback(
    (shouldOpen) => setDialogOpen(shouldOpen),
    [],
  );

  return (
    <>
      <Button
        id={`open-${label}-dialog`}
        onPress={() => toggleDialogVisibility(true)}
        className={className}
        variant={variant}
      >
        <>
          {icon}
          {label}
        </>
      </Button>
      {children({
        isDialogOpen,
        closeDialog: () => setDialogOpen(false),
      })}
    </>
  );
};
