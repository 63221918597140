import { FormattedMessage, useIntl } from 'react-intl';

import {
  FieldGroup,
  Script,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { InlineMessage } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/InlineMessage';
import { infoMessage } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/shared.css';
import { useTimeEstimate } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from 'shared/common/Wizard';
import { useWizardFormFromConfig } from 'shared/common/Wizard/state';

import { INIT_VISIT_SECTIONS } from '../../metadata';
import { ExerciseForm } from '../../shared/generalAssessment';
import type { ExerciseFormFields } from '../../shared/generalAssessment/formConfig';
import { getExerciseFormConfig } from '../../shared/generalAssessment/formConfig';
import { initVisitPath } from '../paths';

export function Exercise() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<ExerciseFormFields>(
    initVisitPath('/general-assessment', '/exercise'),
    getExerciseFormConfig(intl),
  );

  return (
    <Wizard.Step
      sections={INIT_VISIT_SECTIONS}
      form={form}
      title={intl.formatMessage({ defaultMessage: 'Exercise' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage defaultMessage="Now I have a good sense of how you're eating during the day. Let's spend some time talking about your daily routine and any exercise." />
        </Script>
        <InlineMessage className={infoMessage}>
          <FormattedMessage defaultMessage="This is a conversational moment to learn more about the patient's habits. Engage the patient here to learn more about their routine before drilling down into the questions below." />
        </InlineMessage>
        <ExerciseForm form={form} />
      </FieldGroup>
    </Wizard.Step>
  );
}
