import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from 'shared/common/Form';

export function MaxDoseAchievedField() {
  const intl = useIntl();
  return (
    <Form.RadioGroup
      size={12}
      name="maxToleratedDoseAchieved"
      required
      label={intl.formatMessage({
        defaultMessage: 'Maximum tolerated dose achieved?',
      })}
      orientation="horizontal"
    >
      <Form.Radio value="false">
        <FormattedMessage defaultMessage="No" />
      </Form.Radio>
      <Form.Radio value="true">
        <FormattedMessage defaultMessage="Yes" />
      </Form.Radio>
    </Form.RadioGroup>
  );
}
