import type { ReactNode } from 'react';

import {
  FieldGroup,
  Script,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';

type Props = {
  children: ReactNode;
};

export function ScriptWrapper({ children }: Props) {
  return (
    <FieldGroup>
      {Array.isArray(children) ? (
        children.map((s, i) => <Script key={i}>{s}</Script>)
      ) : (
        <Script>{children}</Script>
      )}
    </FieldGroup>
  );
}
