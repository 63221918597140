import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import CloseIcon from 'shared/assets/svgs/closeNoFill.svg?react';
import PencilIcon from 'shared/assets/svgs/edit.svg?react';
import DotsIcon from 'shared/assets/svgs/horizontal-dots.svg?react';
import MedicationIcon from 'shared/assets/svgs/medication.svg?react';
import { Menu } from 'shared/tempo/@labs/molecule/Menu';
import { IconButton } from 'shared/tempo/atom/IconButton';

import { FormType } from '../forms/formTypeEnum';
import { actionIcon, editIcon, menuItem, removeIcon } from './styles.css';

type Props = {
  allowTitration: boolean;
  onManageRx: () => void;
  onReview: () => void;
  onRemove: () => void;
};

export function NPActions({
  allowTitration,
  onManageRx,
  onReview,
  onRemove,
}: Props) {
  const intl = useIntl();

  const menuItems = [
    <Menu.Item key={FormType.Review}>
      <div className={menuItem}>
        <PencilIcon className={editIcon} />
        <FormattedMessage defaultMessage="Med correction" />
      </div>
    </Menu.Item>,
    <Menu.Item key={FormType.Remove}>
      <div className={menuItem}>
        <CloseIcon className={removeIcon} />
        <FormattedMessage defaultMessage="Mark not active" />
      </div>
    </Menu.Item>,
    ...(allowTitration
      ? [
          <Menu.Item key={FormType.ManageRx}>
            <div className={menuItem}>
              <MedicationIcon className={actionIcon} />
              <FormattedMessage defaultMessage="Titrate or stop" />
            </div>
          </Menu.Item>,
        ]
      : []),
  ];

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setIsTooltipOpen(true);
      }}
      onMouseLeave={() => {
        setIsTooltipOpen(false);
      }}
    >
      <IconButton.Tooltip
        content={intl.formatMessage({
          defaultMessage: 'Med management',
        })}
        // Make tooltip controlled so we can close it when the menu is open
        isOpen={isTooltipOpen}
      >
        <Menu.Trigger
          onOpenChange={(isOpen) => {
            if (isOpen) {
              setIsTooltipOpen(false);
            }
          }}
          onAction={(key) => {
            switch (key) {
              case FormType.Review:
                onReview();
                break;
              case FormType.Remove:
                onRemove();
                break;
              case FormType.ManageRx:
                onManageRx();
                break;
              default:
                break;
            }
          }}
          menu={<Menu>{menuItems}</Menu>}
          placement="bottom end"
        >
          <IconButton variant="secondary" size="small">
            <DotsIcon className={actionIcon} />
          </IconButton>
        </Menu.Trigger>
      </IconButton.Tooltip>
    </div>
  );
}
