import { useState } from 'react';

import { filterObjectDeep } from 'lang.utils';

import type { Schema } from '../Schema.types';
import type { DataObject } from '../SchemaDrivenForm';
import { useInitializeFormData } from './useInitializeFormData.hook';

export function useFormData<T extends DataObject>(
  initialFormData: T | undefined,
  onChange: (values: T) => void,
  schema: Schema,
) {
  const transformedInitialFormData = transformFormData(initialFormData);
  useInitializeFormData(schema, onFormChange, transformedInitialFormData);
  const [formData, setFormData] = useState<T | undefined>(
    transformedInitialFormData,
  );
  return {
    formData: transformFormData(formData),
    onFormChange,
  };

  function onFormChange(newFormData: T) {
    onChange(newFormData);
    setFormData(newFormData);
  }
}

function transformFormData<T extends DataObject>(formData: T | undefined) {
  if (!formData) {
    return formData;
  }
  return filterObjectDeep(
    formData,
    (key, value) => value !== '',
  ) as NonNullable<T>;
}
