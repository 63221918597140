import groupBy from 'lodash/groupBy';
import maxBy from 'lodash/maxBy';

import type { CareProvider } from 'shared/generated/grpcGateway/cadence/models/models.pb';
import type { ExternalAppointment } from 'shared/generated/grpcGateway/pms.pb';

export function getMostFrequentProvider(
  appointments: ExternalAppointment[],
): Nullable<CareProvider> {
  const groupedAppointments = groupBy(
    appointments,
    (appointment) =>
      `${appointment.careProvider?.givenName}-${appointment.careProvider?.familyName}`,
  );
  const mostFrequentGroup = maxBy(
    Object.values(groupedAppointments),
    (appts) => appts.length,
  );
  return mostFrequentGroup?.[0]?.careProvider || null;
}

export function sortAppointments(
  appointments: ExternalAppointment[],
): ExternalAppointment[] {
  return [...appointments].sort((a, b) => {
    const dateA = a.appointmentDatetime
      ? new Date(a.appointmentDatetime)
      : null;
    const dateB = b.appointmentDatetime
      ? new Date(b.appointmentDatetime)
      : null;

    if (dateA && dateB) {
      return dateA.getTime() - dateB.getTime();
    }
    if (dateA) {
      return -1;
    }
    if (dateB) {
      return 1;
    }
    return 0;
  });
}

export function getPastAppointments(
  appointments: ExternalAppointment[],
): ExternalAppointment[] {
  const now = new Date();
  return appointments.filter((appointment) => {
    const date = appointment.appointmentDatetime
      ? new Date(appointment.appointmentDatetime)
      : null;
    return date && date < now;
  });
}

export function getFutureAppointments(
  appointments: ExternalAppointment[],
): ExternalAppointment[] {
  const now = new Date();
  return appointments.filter((appointment) => {
    const date = appointment.appointmentDatetime
      ? new Date(appointment.appointmentDatetime)
      : null;
    return date && date >= now;
  });
}

export function getNextApptProvider(
  appointments: ExternalAppointment[],
): Maybe<CareProvider> {
  return appointments.length > 0 ? appointments[0].careProvider : null;
}

export function formatProviderFullName(
  provider: Maybe<CareProvider>,
  fallback: Nullable<string> = null,
) {
  const fName = provider
    ? [provider.givenName, provider.familyName].filter(Boolean)
    : [];
  return fName.length ? fName.join(' ') : fallback;
}
