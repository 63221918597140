import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Script } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { useTimeEstimate } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from 'shared/common/Wizard';
import { useShowVitalsEducation } from 'shared/common/Wizard/state/sectionUtils';
import { flexContainer } from 'shared/jsStyle/flex.css';
import { gap } from 'shared/jsStyle/gap.css';

import type { CNSectionMap } from '../../metadata';
import type { TActionStep } from '../SetActionSteps';
import { ScriptWrapper } from '../VitalProgress/scripts/ScriptWrapper';
import { useAdditionalScriptContext } from '../vitalEducation/useAdditionalScriptContext';
import { useVitalBasedScript } from '../vitalEducation/useVitalBasedScript';
import { GoalAndStepsBlock } from './GoalAndStepsBlock';
import { AP_COMPLETION_SCRIPTS } from './apCompletionScripts';

type Props = {
  sections: CNSectionMap;
  nextPath: string;
  actionSteps: TActionStep[];
  patientGoalText: Nullable<string>;
};

export function SharedReviewActionSteps({
  sections,
  nextPath,
  actionSteps,
  patientGoalText,
}: Props) {
  const intl = useIntl();
  const history = useHistory();

  const showVitalsEducation = useShowVitalsEducation();

  const [actionPlanCompletionScript, isLoadingScript] = useVitalBasedScript(
    AP_COMPLETION_SCRIPTS,
  );

  const { hasT2dWithScaleOnly, isLoading: isLoadingAddContext } =
    useAdditionalScriptContext();

  return (
    <Wizard.Step
      isLoading={isLoadingScript || isLoadingAddContext}
      sections={sections}
      title={intl.formatMessage({
        defaultMessage: 'Action steps',
      })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => {
        history.push(nextPath);
      }}
    >
      <div className={cx(flexContainer.column, gap.M)}>
        <GoalAndStepsBlock
          actionSteps={actionSteps}
          patientGoalText={patientGoalText}
        />
        {showVitalsEducation ? (
          <>
            <Script>
              <FormattedMessage defaultMessage="We've made a great plan together. Cadence is your accountability partner. We're here to be sure that you achieve your goal! To help you track your progress, I would recommend using something like a notebook or phone app to track each time you do one of our action steps. When we speak next, we'll celebrate your wins and make adjustments as needed." />
            </Script>
            {!hasT2dWithScaleOnly ? (
              <ScriptWrapper>{actionPlanCompletionScript}</ScriptWrapper>
            ) : (
              <ScriptWrapper>
                <FormattedMessage defaultMessage="And, as a reminder, blood glucose control is a lifelong process. Maintaining good blood sugar control can be a key part in creating a happier and healthier life." />
              </ScriptWrapper>
            )}
          </>
        ) : (
          <>
            <Script className={cx(flexContainer.column, gap.M)}>
              <FormattedMessage defaultMessage="Now that we have a goal, Cadence is your accountability partner. I want to help make sure you achieve your goal!" />
            </Script>
            <Script className={cx(flexContainer.column, gap.M)}>
              <FormattedMessage defaultMessage="One way we can be accountable is by tracking our progress. Do you ever use your phone to take notes? Or do you like writing things down in a journal or on a notepad?" />
            </Script>
            <Script className={cx(flexContainer.column, gap.M)}>
              <FormattedMessage defaultMessage="Let's use that to track your efforts between now and your next call. That will be our gauge of how far you've come." />
            </Script>
          </>
        )}
      </div>
    </Wizard.Step>
  );
}
