import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import TrendAlertIcon from 'shared/assets/svgs/trendAlert.svg?react';
import { Tooltip } from 'shared/common/Tooltip';
import { useFlags } from 'shared/hooks';
import { useVitalsEngagement } from 'shared/hooks/useVitalsEngagement';
import { Skeleton } from 'shared/tempo/atom/Skeleton';

import {
  container,
  iconContainer,
  textContainer,
  tooltipMessageContainer,
  trendAlertIcon,
} from './VitalsEngagementStatus.css';

type Props = {
  patientId: string;
  className?: string;
};

export function VitalsEngagementStatus({ patientId, className }: Props) {
  const { vitalHealthIndicator } = useFlags();

  const { data: vitalsEngagement, isLoading: isLoadingVitalsEngagement } =
    useVitalsEngagement(patientId);

  if (!vitalsEngagement || !vitalHealthIndicator) {
    return null;
  }

  const { daysWithVitals, usageRatio, daysEnrolled } = vitalsEngagement;

  const color = getStatusColor(usageRatio);

  return (
    <Tooltip
      tooltipMessage={getTooltipMessage(daysEnrolled)}
      tooltipPlacement="bottom"
      className={cx(container, className)}
    >
      <div className={iconContainer[color]}>
        <TrendAlertIcon className={trendAlertIcon[color]} />
      </div>
      <div className={textContainer}>
        {isLoadingVitalsEngagement ? (
          <Skeleton variant="text" width="100px" />
        ) : (
          <FormattedMessage
            defaultMessage="{vitalsCount} {vitalsCount, plural, =1 {day} other {days}}"
            values={{ vitalsCount: daysWithVitals }}
          />
        )}
      </div>
    </Tooltip>
  );
}

function getStatusColor(usageRatio: number): keyof typeof trendAlertIcon {
  if (usageRatio >= 16 / 30) {
    return 'green';
  }
  if (usageRatio >= 8 / 30) {
    return 'yellow';
  }
  return 'red';
}

function getTooltipMessage(enrollmentDaysCount: number) {
  return (
    <div className={tooltipMessageContainer}>
      {enrollmentDaysCount >= 30 ? (
        <FormattedMessage defaultMessage="Days with vitals submitted in last 30 days" />
      ) : (
        <>
          <FormattedMessage defaultMessage="Days with vitals submitted in " />
          <em>
            <FormattedMessage defaultMessage="first " />
          </em>
          <FormattedMessage
            defaultMessage="{enrollmentDaysCount, plural, =1 {day} other {{enrollmentDaysCount} days}}"
            values={{ enrollmentDaysCount }}
          />
        </>
      )}
    </div>
  );
}
