import cx from 'classnames';
import orderBy from 'lodash/orderBy';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { InputAdornment } from 'deprecated/mui';
import { useFetchAllPages, useFlatPages } from 'reactQuery';
import { Form } from 'shared/common/Form';
import { UserAvatar } from 'shared/common/UserAvatar';
import type { TaskQueue } from 'shared/generated/grpcGateway/task.pb';
import { useFlags } from 'shared/hooks';
import {
  useCareProvidersInfinite,
  usePatientCareProviders,
} from 'shared/hooks/queries';
import { getProviderLabel } from 'shared/provider';
import { Tag } from 'shared/tempo/atom/Tag';
import type { Provider } from 'shared/types/provider.types';
import { ProviderType } from 'shared/types/provider.types';

import {
  formatProviderName,
  taskParticipants,
  toTaskProvider,
} from '../TaskCard/utils';
import type { ConvertedProvider, Task } from '../types';
import {
  optionNameContainer,
  providerListItem,
} from './AssignmentAutocomplete.css';

// Designated view highlights 'designated' careproviders wheras 'participants' highlights task participants
type View = { type: 'designated' } | { type: 'participants'; task: Task };

type TaggedCareProvider = {
  provider: Provider;
  tag: Maybe<ReactNode>;
};

type Props = {
  name: string;
  view: View;
  initialValue?: Provider | ConvertedProvider;
  queue?: TaskQueue;
  isRequired?: boolean;
};

export function AssignmentAutocomplete({
  name,
  view,
  initialValue,
  queue,
  isRequired,
}: Props) {
  const intl = useIntl();
  const { tasksTeamAssignment } = useFlags();
  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  const [assignTo, setAssignTo] = useState<
    Provider | ConvertedProvider | undefined
  >(initialValue);

  const careProvidersQuery = useFetchAllPages(
    useCareProvidersInfinite(
      {
        providerType: ProviderType.Cadence,
        ...(tasksTeamAssignment && queue && { teamIds: queue.teamIds }),
      },
      { enabled: hasBeenOpened },
    ),
  );

  const careProviders = useFlatPages(
    careProvidersQuery,
    'care_providers',
  ).filter((p) => p.first_name && p.last_name);

  const { isLoading: loadingTaggedProviders, taggedCareProviders } =
    useTaggedProviders(view, careProviders);

  const sortedCareProviders = orderBy(
    taggedCareProviders,
    [
      (taggedProvider) => Boolean(taggedProvider.tag),
      (taggedProvider) =>
        formatProviderName(toTaskProvider(taggedProvider.provider)),
    ],
    ['desc', 'asc'],
  );

  const isLoading = careProvidersQuery.isLoading || loadingTaggedProviders;

  return (
    <Form.Autocomplete
      required={isRequired}
      name={name}
      size={12}
      loading={isLoading}
      onOpen={() => setHasBeenOpened(true)}
      onOptionChosen={setAssignTo}
      onOptionCleared={() => setAssignTo(undefined)}
      disableCloseOnSelect={false}
      placeholder={intl.formatMessage({
        defaultMessage: 'Search or select a clinician',
      })}
      label={<FormattedMessage defaultMessage="Assign task" />}
      options={sortedCareProviders.map(({ provider }) => provider)}
      getOptionLabel={getProviderLabel}
      InputProps={{
        startAdornment: assignTo && (
          <InputAdornment position="end">
            <UserAvatar
              size="small"
              firstName={assignTo.first_name}
              lastName={assignTo.last_name}
            />
          </InputAdornment>
        ),
      }}
      renderOption={({ className, ...props }, provider) => (
        <li
          {...props}
          key={provider.id}
          className={cx(className, providerListItem)}
        >
          <span className={optionNameContainer}>
            <UserAvatar
              size="small"
              firstName={provider.first_name}
              lastName={provider.last_name}
            />
            {getProviderLabel(provider)}
          </span>
          {
            sortedCareProviders.find(
              (taggedProvider) => taggedProvider.provider.id === provider.id,
            )?.tag
          }
        </li>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
}

function useTaggedProviders(view: View, careProviders: Provider[]) {
  const { patientId } = useParams<{ patientId?: string }>();
  const { isLoading: loadingPatientProviders, data: patientProviders } =
    usePatientCareProviders(
      patientId || '',
      {},
      { enabled: view.type === 'designated' && Boolean(patientId) },
    );

  let taggedCareProviders: TaggedCareProvider[] = [];
  if (view.type === 'designated' && patientId) {
    taggedCareProviders = careProviders.map((cp) => {
      const designatedProvider = patientProviders?.care_providers?.find(
        (p) => p.id === cp.id,
      );
      return {
        provider: cp,
        tag: !designatedProvider ? null : (
          <Tag>
            <FormattedMessage
              defaultMessage="Designated {role}"
              values={{ role: designatedProvider.role }}
            />
          </Tag>
        ),
      };
    });
  } else if (view.type === 'participants') {
    const { task } = view;
    taggedCareProviders = careProviders.map((cp) => {
      const participant = taskParticipants(
        task.comments,
        task.creator,
        task.assignee ?? undefined,
      ).find((p) => p.uid === cp.id);
      return {
        provider: cp,
        tag: !participant ? null : (
          <Tag>
            <FormattedMessage defaultMessage="Task participant" />
          </Tag>
        ),
      };
    });
  }
  return { isLoading: loadingPatientProviders, taggedCareProviders };
}
