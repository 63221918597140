import type { SyntheticEvent } from 'react';

import type {
  AutocompleteProps,
  InputProps as TInputProps,
} from 'deprecated/mui';
import { Autocomplete as MUIAutocomplete, TextField } from 'deprecated/mui';
import {
  addInputClassname,
  addInputWrapperClassname,
} from 'shared/common/Input';

import type { BaseFieldProps } from '../BaseField';
import { BaseField } from '../BaseField';

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type MUIAutocompleteProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = PartialBy<
  Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'onChange' | 'size'
  >,
  'renderInput'
>;

type Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = {
  placeholder?: string;
  onOptionChosen?: (value: T) => void;
  onOptionCleared?: (e: SyntheticEvent) => void;
  InputProps?: TInputProps;
  classes?: {
    input?: string;
  };
} & MUIAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> &
  BaseFieldProps;

export function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined = undefined,
>({
  label,
  name,
  placeholder,
  size,
  help,
  options,
  persistedHelp,
  isDisabled,
  onOptionChosen,
  onOptionCleared,
  sx,
  InputProps = {},
  onChange,
  classes,
  ...overrides
}: Props<T, Multiple, DisableClearable>) {
  return (
    <BaseField
      label={label}
      name={name}
      size={size}
      help={help}
      required={overrides.required}
      persistedHelp={persistedHelp}
    >
      {({ controller: { field, fieldState } }) => (
        <>
          {/* Everything Before ...overrides can be overridden, everything after should/can not */}
          <MUIAutocomplete
            disableCloseOnSelect
            sx={{
              '.MuiAutocomplete-inputRoot': {
                paddingTop: 0,
                paddingBottom: 0,
                // we want to keep the default left padding if we're allowing
                // multiple selections because we need to give the chips room
                // to breathe.
                ...(!overrides.multiple && {
                  paddingLeft: 0,
                }),
              },
              ...sx,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!fieldState.error}
                hiddenLabel
                variant="outlined"
                placeholder={placeholder}
                className={addInputWrapperClassname(
                  isDisabled,
                  false,
                  !!fieldState.error,
                  classes?.input,
                )}
                InputProps={{ ...params.InputProps, ...InputProps }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={addInputClassname(params.inputProps)}
              />
            )}
            {...field}
            {...overrides}
            id={field.name}
            options={options}
            onChange={(e, value, reason) => {
              if (reason === 'clear') onOptionCleared?.(e);
              if (reason === 'selectOption') onOptionChosen?.(value as T);

              field.onChange(value);
            }}
            disabled={isDisabled}
          />
        </>
      )}
    </BaseField>
  );
}
