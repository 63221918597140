import { useIntl } from 'react-intl';

import { useFormFromConfig } from 'shared/common/Form/FormContainer';
import { validators } from 'shared/common/Form/validations';

export type RemoveMedFormFields = {
  reason: string;
  endDate?: Date;
};

export function useRemoveMedicationForm() {
  const intl = useIntl();
  const { date, maxLengthString } = validators(intl);

  return useFormFromConfig<RemoveMedFormFields>({
    fields: {
      reason: {
        validation: maxLengthString({ maxLength: 255 }),
      },
      endDate: {
        defaultValue: null,
        validation: date(),
      },
    },
  });
}
