import type {
  AsyncTitration,
  AsyncTitrationAsyncTitrationStatus,
  MedicationChange,
  MedicationChangeFrequency,
  NextTitrationStep,
} from 'shared/generated/grpcGateway/medication.pb';
import { useFlags } from 'shared/hooks';

export type TitrationRecommendation = {
  rxcui?: string;
  doseQuantity?: number;
  frequency?: MedicationChangeFrequency;
  vitalsCriteria?: string;
  labsCriteria?: string;
  symptomsCriteria?: string;
  status?: AsyncTitrationAsyncTitrationStatus;
};

export function useTitrationRecommendation(
  medChange: Nullable<MedicationChange>,
): TitrationRecommendation {
  const { asyncTitrations, suggestedTitration } = useFlags();

  if (asyncTitrations && medChange?.asyncTitration) {
    return asyncTitrationToTitrationRecommendation(medChange.asyncTitration);
  }

  if (suggestedTitration && medChange?.nextTitrationStep) {
    return nextTitrationStepToTitrationRecommendation(
      medChange.nextTitrationStep,
    );
  }

  return {};
}

function asyncTitrationToTitrationRecommendation(
  asyncTitration: AsyncTitration,
): TitrationRecommendation {
  return {
    rxcui: asyncTitration.outputRxcui,
    doseQuantity: asyncTitration.outputDoseQuantity,
    frequency: asyncTitration.outputFrequency,
    vitalsCriteria: asyncTitration.outputVitalsCriteria,
    labsCriteria: asyncTitration.outputLabsCriteria,
    symptomsCriteria: asyncTitration.outputSymptomsCriteria,
    status: asyncTitration.status,
  };
}

function nextTitrationStepToTitrationRecommendation(
  nextTitrationStep: NextTitrationStep,
): TitrationRecommendation {
  return {
    rxcui: nextTitrationStep.nextRxcui,
    doseQuantity: nextTitrationStep.nextDoseQuantity,
    frequency: nextTitrationStep.nextFrequency,
    vitalsCriteria: nextTitrationStep.nextVitalsCriteria,
    labsCriteria: nextTitrationStep.nextLabsCriteria,
    symptomsCriteria: nextTitrationStep.nextSymptomsCriteria,
  };
}
