import * as yup from 'yup';

import { PhoneType } from 'shared/types/shared.types';

import { validator } from './factory';

/**
 * Validator for ensuring value is a valid phone number.
 */
export const phoneNumberValidator = validator((intl) => {
  const errorMessage = intl.formatMessage({
    defaultMessage: 'Must be a valid phone number.',
  });
  return (
    yup
      .string()
      // input mask appends +1 automatically, treat this as an empty
      // string if that's all we have
      .transform((value) => (value === '+1' ? '' : value))
      .matches(/^\+1[0-9]{10}$/, {
        excludeEmptyString: true,
        message: errorMessage,
      })
  );
});

/**
 * Validator for ensuring value is a valid phone number.
 */
export const phoneNumberWithoutMaskValidator = validator((intl) => {
  const errorMessage = intl.formatMessage({
    defaultMessage: `Prefix a valid 10-digit US phone number with +1
    e.g. “+18885550123”`,
  });
  return (
    yup
      .string()
      // input mask appends +1 automatically, treat this as an empty
      // string if that's all we have
      .transform((value) => (value === '+1' ? '' : value))
      .matches(/^\+1[0-9]{10}$/, {
        excludeEmptyString: true,
        message: errorMessage,
      })
  );
});

/**
 * Validator for ensuring phone type is a valid selection.
 * @param: allowEmpty, when allowEmpty is true, the empty string becomes an
 * acceptable value.
 */
type PhoneTypeParams = Maybe<{ allowEmpty: boolean }>;
export const phoneTypeValidator = validator((_, params: PhoneTypeParams) => {
  let allowedValues: Array<PhoneType | ''> = Object.values(PhoneType);

  if (params?.allowEmpty) {
    allowedValues = [...allowedValues, ''];
  }

  return yup.string().oneOf(allowedValues);
});
