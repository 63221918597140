import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { settings } from 'config';
import { registerMonitoredUser } from 'monitoring.utils';
import { CANCEL_PENDING_REQUEST } from 'shared/actionCreators';

import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAILURE,
  loginRequested,
} from '../actionCreators';

const loginEpic = (action$: any) =>
  action$.pipe(
    ofType(LOGIN_REQUEST),
    switchMap((action: any) =>
      from(
        axios.post(`${settings.API_URL}/auth/api/v1/login`, {
          email: action.email,
          password: action.password,
        }),
      ).pipe(
        tap(() => registerMonitoredUser(action.email)),
        takeUntil(action$.pipe(ofType(CANCEL_PENDING_REQUEST))),
      ),
    ),
    map((response: AxiosResponse<TLoginPayload>) =>
      loginRequested(response.data),
    ),
    catchError((error, caught) =>
      merge(
        of({
          type: LOGIN_REQUEST_FAILURE,
          payload: error,
          error: true,
        }),
        caught,
      ),
    ),
  );

export default loginEpic;
