import type { ReactNode } from 'react';

import { IconButton as MuiIconButton } from 'deprecated/mui';

type IconButtonProps = {
  className?: string;
  children: ReactNode;
  onClick: () => void;
};

export const IconButton = ({
  className,
  children,
  onClick,
}: IconButtonProps) => (
  <MuiIconButton
    className={className}
    size="small"
    onClick={onClick}
    disableRipple
    sx={{ '&:hover': { background: 'none' } }}
  >
    {children}
  </MuiIconButton>
);
