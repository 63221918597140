import { useIntl } from 'react-intl';

import { useToaster } from 'shared/tempo/molecule/Toast';
import { getGrpcErrorMessage } from 'shared/utils/helpers';

import { usePatientMedicationsContext } from '../../PatientMedicationsContext';
import { useTitrateMedication } from '../../patientMedications.queries';
import { useHasChf } from '../../utils/useHasChf';
import type { MedicationFormProps } from '../MedicationForm';
import { MedicationForm } from '../MedicationForm';
import type { MedPayload } from '../editMedicationFormConfig';
import { TitrationFormType } from '../formTypeEnum';

type Props = MedicationFormProps & {
  onSuccess: () => void;
};

export function TitrateMedicationForm({ onSuccess, ...rest }: Props) {
  const { onClose } = rest;

  const intl = useIntl();
  const { toaster } = useToaster();
  const { patientId } = usePatientMedicationsContext();

  const hasChf = useHasChf(patientId);
  const titrateMutation = useTitrateMedication(onSuccess);

  function handleSubmit(payload: MedPayload) {
    titrateMutation.mutate(payload, {
      onSuccess: onClose,
      onError: (err) => {
        toaster.error(
          intl.formatMessage(
            {
              defaultMessage: 'Failed to titrate medication: {message}',
            },
            { message: getGrpcErrorMessage(err) },
          ),
        );
      },
    });
  }

  return (
    <MedicationForm
      {...rest}
      hasChf={hasChf}
      titrationFormType={TitrationFormType.Titrate}
      isProcessing={titrateMutation.isLoading}
      onSubmit={handleSubmit}
    />
  );
}
