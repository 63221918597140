import jwt_decode from 'jwt-decode';
import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { FormattedMessage, useIntl } from 'react-intl';

import CreateTaskIcon from 'shared/assets/svgs/arrowUpRight.svg?react';
import CalendarIcon from 'shared/assets/svgs/calendar.svg?react';
import DebugIcon from 'shared/assets/svgs/curlyBrackets.svg?react';
import SettingsIcon from 'shared/assets/svgs/settings.svg?react';
import ZendeskIcon from 'shared/assets/svgs/zendesk.svg?react';
import { MODAL_OPEN_ATTR } from 'shared/common/Modal';
import { SchedulePatientModal } from 'shared/common/SchedulePatientModal';
import { useFlags } from 'shared/hooks';
import { usePatientHospitals } from 'shared/hooks/queries';
import { useShouldAutoOpenScheduler } from 'shared/hooks/useShouldAutoOpenScheduler';
import { circular } from 'shared/jsStyle/utils.css';
import { CreateTaskOverlayTrigger } from 'shared/tasking/CreateTaskOverlay';
import { IconButton } from 'shared/tempo/atom/IconButton';
import {
  HotkeyTooltip,
  SpecialChar,
} from 'shared/tempo/molecule/HotkeyTooltip';
import type { CognitoAccessToken } from 'shared/types/cognito.types';
import type { Patient } from 'shared/types/patient.types';
import { isAdmin } from 'shared/utils/userRoles.utils';
import { getPatientZendeskUrl } from 'shared/utils/zendesk.utils';
import { store } from 'store';

import { usePatientDetailsCtx } from './PatientDetailContext';
import { actionsContainer } from './PatientProfileActionButtonGroupContainer.css';
import { GoToChartRpaActionButton } from './RpaWorkflows/GoToChartRpaActionButton';
import { useShouldShowRpaButton } from './RpaWorkflows/useShouldShowRpaButton';
import {
  PatientProfileModal,
  usePatientProfileCtx,
} from './context/PatientProfileCtx';

function PatientProfileSchedulingButton({
  defaultOpen,
  onClose,
}: {
  defaultOpen: boolean;
  onClose: () => void;
}) {
  const { patientDetails } = usePatientDetailsCtx();
  const [isDialogOpen, setIsDialogOpen] = useState(defaultOpen);

  return (
    <>
      <IconButton.Tooltip
        content={<FormattedMessage defaultMessage="Schedule appointment" />}
      >
        <IconButton
          size="small"
          variant="secondary"
          onPress={() => setIsDialogOpen(true)}
        >
          <CalendarIcon />
        </IconButton>
      </IconButton.Tooltip>

      <SchedulePatientModal
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          onClose();
        }}
        // TODO: abolish patientDetailsCtx
        patientDetails={patientDetails as unknown as Patient}
      />
    </>
  );
}

export const PatientProfileActionButtonGroup = () => {
  const intl = useIntl();
  const { patientDetails } = usePatientDetailsCtx();
  const { setCurrentModal } = usePatientProfileCtx();
  const { token } = store.getState().auth;
  const { shouldAutoOpenScheduler, onCloseScheduler } =
    useShouldAutoOpenScheduler();
  const { debugTools, cycle17TaskHubUpdates } = useFlags();

  const currentUserRole = jwt_decode<CognitoAccessToken>(token.access_token)[
    'cognito:groups'
  ];

  const patientHospitalQuery = usePatientHospitals({
    patientId: patientDetails.id,
    page: 1,
    pageSize: 1,
  });
  const { data: paginatedClinics } = patientHospitalQuery;
  const { hospitals } = paginatedClinics || {};
  const patientHospital = hospitals?.[0];

  const showRpaButton = useShouldShowRpaButton(
    patientHospital?.ehr_information?.ehr,
    patientHospital?.health_system_id?.toString(),
  );

  return (
    <div className={actionsContainer}>
      {cycle17TaskHubUpdates ? (
        <CreateTaskOverlayTrigger patientId={patientDetails.id} />
      ) : (
        <LegacyTaskModalTrigger />
      )}
      <PatientProfileSchedulingButton
        defaultOpen={shouldAutoOpenScheduler}
        onClose={onCloseScheduler}
      />
      {isAdmin(currentUserRole) && (
        <IconButton.Tooltip
          content={<FormattedMessage defaultMessage="Patient admin" />}
        >
          <IconButton
            size="small"
            variant="secondary"
            onPress={() =>
              window.open(
                `/admin/patient/${patientDetails.id}/profile`,
                '_blank',
              )
            }
          >
            <SettingsIcon />
          </IconButton>
        </IconButton.Tooltip>
      )}
      <IconButton.Tooltip
        content={<FormattedMessage defaultMessage="Zendesk profile" />}
      >
        <IconButton
          size="small"
          variant="secondary"
          isDisabled={!patientDetails.zendesk_id}
          onPress={() =>
            patientDetails.zendesk_id
              ? window.open(
                  getPatientZendeskUrl(patientDetails.zendesk_id),
                  '_blank',
                )
              : undefined
          }
        >
          <ZendeskIcon />
        </IconButton>
      </IconButton.Tooltip>
      {showRpaButton && patientHospital && (
        <GoToChartRpaActionButton
          patient={patientDetails}
          patientHospital={patientHospital}
        />
      )}
      {debugTools && (
        <HotkeyTooltip
          title={intl.formatMessage({ defaultMessage: 'Debug' })}
          hotkey={[SpecialChar.Meta, SpecialChar.Shift, 'I']}
          classes={{ triggerContainer: circular }}
        >
          <IconButton
            size="small"
            variant="secondary"
            onPress={() => setCurrentModal(PatientProfileModal.Debug)}
          >
            <DebugIcon />
          </IconButton>
        </HotkeyTooltip>
      )}
    </div>
  );
};

// this lives in a separate component so that we can isolate the useHotkeys
// hook. we use the same hotkey for the new task modal and the old task modal,
// but we need to behave differently in each.
function LegacyTaskModalTrigger() {
  const intl = useIntl();
  const { setCurrentModal } = usePatientProfileCtx();

  useHotkeys(
    ['meta+.', 'meta+shift+.'],
    () => setCurrentModal(PatientProfileModal.CreateTask),
    {
      enabled: () => !document.querySelector(`[${MODAL_OPEN_ATTR}="true"]`),
    },
  );

  return (
    <HotkeyTooltip
      title={intl.formatMessage({ defaultMessage: 'Create task' })}
      hotkey={[SpecialChar.Meta, '>']}
      classes={{ triggerContainer: circular }}
    >
      <IconButton
        size="small"
        variant="secondary"
        onPress={() => setCurrentModal(PatientProfileModal.CreateTask)}
      >
        <CreateTaskIcon />
      </IconButton>
    </HotkeyTooltip>
  );
}
