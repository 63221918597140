import { format } from 'date-fns';
import { EditorState, convertToRaw } from 'draft-js';
import { useEffect, useMemo, useState } from 'react';

import { convertToEditorState } from 'shared/common/EditorRTF/utils';
import type { FormConfig } from 'shared/common/Form';
import { getDefaults } from 'shared/common/Form/form.utils';
import { useTimer } from 'shared/notes/Timer';
import type { TNoteBodyRTF } from 'shared/types/note.types';

import { useNoteEditorContext } from '../NoteEditorContext';
import type {
  EncounterModuleInstance,
  EncounterTypeInputs,
  EndEncounterType,
  TimeEntry,
} from '../Notes.types';
import { useAdjustEncounterModuleInstances } from '../utils/encounterModuleInstanceUtils';
import { convertHtmlToDraftJsEditorState } from '../utils/noteConverters';
import { isRtfBodyEmpty } from '../utils/rtfBodyUtil';
import { useEncounterModuleInstances } from './hooks/useEncounterModuleInstances.hook';

export type EndFormValues = {
  endType?: EndEncounterType | null;
  endReason?: string | null;
  endNote?: string | null;
};

export type NoteFormValues = {
  title: string;
  // TODO: Remove when switch over to V2 Rich Text Editor
  // https://cadencerpm.atlassian.net/browse/PLAT-4330
  body: TNoteBodyRTF;
  bodyHtml: Nullable<string>;
  labels: number[];
  actionRequired: boolean;
  urgent: boolean;
  externalProviderId?: string;
  timeEntry: Partial<TimeEntry>;
  encounterModuleInstances: EncounterModuleInstance[];
  zendeskTicket?: Nullable<number>;
  appointmentId?: Nullable<string>;
  noShowAppointmentId?: Nullable<string>;
  endEncounter?: EndFormValues;
  timeElapsed?: Nullable<number>;
};

export type SetNoteFormValue = (
  name: SettableNoteFieldName,
  value: NoteFormValues[SettableNoteFieldName],
) => void;
type SettableNoteFieldName = keyof Omit<
  NoteFormValues,
  'encounterModuleInstances'
>;

export type NoteFormExtras = {
  setEditorState: (value: EditorState) => void;
  editorState: EditorState;
  setEncounterModuleInstance: (value: EncounterModuleInstance) => void;
  encounterTypeInstance?: EncounterModuleInstance<EncounterTypeInputs>;
};

/**
 * Custom note editor form state management.
 * Hopefully we may remove most of this when we move to our useFormFromConfig.
 */
export function useNoteFormState(formConfig: FormConfig) {
  const defaultNoteFormState = getDefaults(formConfig.fields) as NoteFormValues;
  const { value: title, setValue: setTitle } = useNoteField(
    defaultNoteFormState.title,
  );
  const { body, setBody, editorState, setEditorState } = useBody(
    defaultNoteFormState.body,
    defaultNoteFormState.bodyHtml,
  );
  const { bodyHtml, setBodyHtml } = useBodyHtml();

  const timer = useTimer(defaultNoteFormState.timeElapsed ?? 0);

  const { value: zendeskTicket, setValue: setZendeskTicket } = useNoteField(
    defaultNoteFormState.zendeskTicket,
  );
  const { value: appointmentId, setValue: setAppointmentId } = useNoteField(
    defaultNoteFormState.appointmentId,
  );
  const { value: noShowAppointmentId, setValue: setNoShowAppointmentId } =
    useNoteField(defaultNoteFormState.noShowAppointmentId);
  const { value: labels, setValue: setLabels } = useNoteField(
    defaultNoteFormState.labels,
  );
  const { value: actionRequired, setValue: setActionRequired } = useNoteField(
    defaultNoteFormState.actionRequired,
  );
  const { value: urgent, setValue: setUrgent } = useNoteField(
    defaultNoteFormState.urgent,
  );
  const { value: externalProviderId, setValue: setExternalProviderId } =
    useNoteField(defaultNoteFormState.externalProviderId);

  const { value: timeEntry, setValue: setTimeEntry } = useNoteField(
    defaultNoteFormState.timeEntry,
  );

  const { value: endEncounter, setValue: setEndEncounter } = useNoteField(
    defaultNoteFormState.endEncounter,
  );

  const { encounterModuleInstances, encounterTypeInstance, onChangeInstance } =
    useEncounterModuleInstances();

  useAdjustEncounterModuleInstances(body, (newBody: TNoteBodyRTF) => {
    setBody(newBody);
    setEditorState(convertToEditorState(newBody));
  });

  const setNoteFormValue: SetNoteFormValue = (field, value) =>
    (
      ({
        title: setTitle,
        body: setBody,
        bodyHtml: setBodyHtml,
        labels: setLabels,
        actionRequired: setActionRequired,
        urgent: setUrgent,
        externalProviderId: setExternalProviderId,
        timeEntry: setTimeEntry,
        zendeskTicket: setZendeskTicket,
        appointmentId: setAppointmentId,
        noShowAppointmentId: setNoShowAppointmentId,
        endEncounter: setEndEncounter,
        timeElapsed: timer.setTimeElapsed,
      }) as Record<SettableNoteFieldName, Function>
    )[field](value);

  return {
    setNoteFormValue,
    resetNoteFormState: useResetNoteFormState(setNoteFormValue),
    noteFormValues: {
      title,
      body,
      bodyHtml,
      labels,
      actionRequired,
      urgent,
      externalProviderId,
      timeEntry,
      zendeskTicket,
      encounterModuleInstances,
      appointmentId,
      noShowAppointmentId,
      endEncounter,
      timeElapsed: timer.timeElapsed,
    },
    noteFormExtras: {
      setEditorState,
      editorState,
      setEncounterModuleInstance: (instance: EncounterModuleInstance) =>
        onChangeInstance(
          preserveAddedToFormTimestamp(instance, encounterModuleInstances),
        ),
      encounterTypeInstance:
        encounterTypeInstance as EncounterModuleInstance<EncounterTypeInputs>,
      timer,
    },
  };
}

function useBody(initialBody: TNoteBodyRTF, initialBodyHtml: Nullable<string>) {
  const initialEditorState = useMemo(() => {
    // If note was created in V2 editor, convert to V1 format
    if (isRtfBodyEmpty(initialBody) && initialBodyHtml) {
      return convertHtmlToDraftJsEditorState(initialBodyHtml);
    }
    return convertToEditorState(initialBody);
  }, [initialBody, initialBodyHtml]);

  const { value: editorState, setValue: setEditorState } =
    useNoteField(initialEditorState);
  return {
    body: convertToRaw(editorState.getCurrentContent()),
    setBody: (body: TNoteBodyRTF) => setEditorState(convertToEditorState(body)),
    setEditorState,
    editorState,
  };
}

function useBodyHtml() {
  const { value: bodyHtml, setValue: setBodyHtml } = useNoteField(null);
  return { bodyHtml, setBodyHtml };
}

function useNoteField<T>(reinitializeValue: T) {
  const [value, setValue] = useState<T>(reinitializeValue);
  useEffect(() => {
    setValue(reinitializeValue);
  }, [reinitializeValue]);
  return { value, setValue };
}

function preserveAddedToFormTimestamp(
  instance: EncounterModuleInstance,
  encounterModuleInstances: EncounterModuleInstance[],
) {
  return {
    ...instance,
    addedToFormTimestamp: encounterModuleInstances.find(
      (i) => i.encounter_module_id === instance.encounter_module_id,
    )?.addedToFormTimestamp,
  };
}

function useResetNoteFormState(setNoteFormValue: SetNoteFormValue) {
  const { setEncounterModuleInstances } = useNoteEditorContext();
  return () => {
    setNoteFormValue('title', '');
    setNoteFormValue(
      'body',
      convertToRaw(EditorState.createEmpty().getCurrentContent()),
    );
    setNoteFormValue('bodyHtml', null);
    setNoteFormValue('labels', []);
    setNoteFormValue('actionRequired', false);
    setNoteFormValue('urgent', false);
    setNoteFormValue('externalProviderId', undefined);
    setNoteFormValue('timeEntry', {
      start_date: new Date(),
      start_time: format(new Date(), 'HH:mm'),
    });
    setNoteFormValue('zendeskTicket', null);
    setEncounterModuleInstances([]);
    setNoteFormValue('endEncounter', {
      endType: null,
      endReason: null,
      endNote: null,
    });
    setNoteFormValue('timeElapsed', null);
  };
}
