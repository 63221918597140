import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Select } from 'shared/tempo/@labs/molecule/Select';

import { notTitrating } from './SuggestedTitration.css';

// TODO: match up with BE enum once BE is in place
enum NotTitratingReason {
  ProviderPreference,
  PatientPreference,
  MedicalReason,
  FinancialReason,
  NewRxRequired,
}

export function NotTitratingReasonSelect() {
  const [value, setValue] = useState<NotTitratingReason | null>(null);

  return (
    <Select
      label={
        <FormattedMessage defaultMessage="Select a reason why a medication wasn't titrated" />
      }
      className={notTitrating.select}
      selectedKey={value}
      onSelectionChange={(key) => setValue(key as NotTitratingReason)}
    >
      <Select.Option key={NotTitratingReason.ProviderPreference}>
        <FormattedMessage defaultMessage="Provider preference" />
      </Select.Option>
      <Select.Option key={NotTitratingReason.PatientPreference}>
        <FormattedMessage defaultMessage="Patient preference" />
      </Select.Option>
      <Select.Option key={NotTitratingReason.MedicalReason}>
        <FormattedMessage defaultMessage="Medical reason" />
      </Select.Option>
      <Select.Option key={NotTitratingReason.FinancialReason}>
        <FormattedMessage defaultMessage="Financial reason" />
      </Select.Option>
      <Select.Option key={NotTitratingReason.NewRxRequired}>
        <FormattedMessage defaultMessage="New prescription required" />
      </Select.Option>
    </Select>
  );
}
