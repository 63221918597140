import { renderToString } from 'react-dom/server';
import type { FormattedMessage } from 'react-intl';
import { IntlProvider } from 'react-intl';

export function formattedMessageToString(
  formattedMessage: ReturnType<typeof FormattedMessage>,
) {
  return renderToString(
    <IntlProvider locale="en">{formattedMessage}</IntlProvider>,
  );
}
