import cx from 'classnames';
import type { ReactNode } from 'react';

import { DialogTitle } from 'deprecated/mui';
import { IconButton } from 'shared/tempo/atom/IconButton';
import { Skeleton } from 'shared/tempo/atom/Skeleton';

import { useModalContext } from './ModalContext';
import CloseIcon from './close.svg?react';
import {
  closeIcon,
  header,
  headerContents,
  loadingSkeleton,
  rightHeaderContainer,
  scrollShadow,
  title as titleClass,
} from './styles.css';

type Props = {
  title: ReactNode | string | undefined;
  children?: ReactNode;
};

export function Header({ title, children }: Props) {
  const { onClose, disabled, isLoading, isBodyScrollVisible } =
    useModalContext();

  const loadingTitle = (
    <Skeleton
      variant="text"
      height="18px"
      width="220px"
      className={loadingSkeleton}
    />
  );
  const loadingActions = (
    <Skeleton
      variant="text"
      height="18px"
      width="100px"
      className={loadingSkeleton}
    />
  );

  return (
    <DialogTitle
      className={cx(header, { [scrollShadow]: isBodyScrollVisible })}
    >
      <div className={titleClass}>{isLoading ? loadingTitle : title}</div>
      <div className={rightHeaderContainer}>
        {children && (
          <div className={headerContents}>
            {isLoading ? loadingActions : children}
          </div>
        )}
        <div className={closeIcon}>
          <IconButton
            size="small"
            variant="tertiary"
            onPress={onClose}
            isDisabled={disabled}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </DialogTitle>
  );
}
