import { FormattedMessage } from 'react-intl';

import AlertTriangleIcon from 'shared/assets/svgs/alertTriangle.svg?react';
import type { MedicationChange } from 'shared/generated/grpcGateway/medication.pb';

import { medRowAlertIcon, medRowAlertSubtext } from './MedicationRow.css';
import { requiredActions } from './utils/requiredActions';

export function MedicationRequiredActions({
  medChange,
  isDiseaseSpecific,
}: {
  medChange: MedicationChange;
  isDiseaseSpecific: boolean;
}) {
  const { structuring, verification } = requiredActions(
    medChange,
    isDiseaseSpecific,
  );
  let text;
  if (verification && structuring) {
    text = (
      <FormattedMessage defaultMessage="This medication requires verification and must be structured" />
    );
  } else if (structuring) {
    text = (
      <FormattedMessage defaultMessage="This medication must be structured" />
    );
  } else if (verification) {
    text = (
      <FormattedMessage defaultMessage="This medication requires verification" />
    );
  }

  return text ? (
    <div className={medRowAlertSubtext}>
      <AlertTriangleIcon className={medRowAlertIcon} />
      {text}
    </div>
  ) : null;
}
