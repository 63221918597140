import type { ReactNode } from 'react';

import AlertTriangle from 'shared/assets/svgs/alertTriangle.svg?react';
import { color } from 'shared/tempo/theme';

import { BoxContainer } from './BoxContainer';
import { error } from './ErrorDisplay.css';

type Props = {
  children: ReactNode;
};

export function ErrorDisplay({ children }: Props) {
  return (
    <BoxContainer variant="warning">
      <div className={error}>
        <AlertTriangle fill={color.Theme.Light.Danger} />
        {children}
      </div>
    </BoxContainer>
  );
}
