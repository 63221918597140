import { addDays, eachDayOfInterval, max, subDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { getPatientEnrollmentDate } from 'shared/patient/enrollment.utils';
import type { Patient } from 'shared/types/patient.types';
import { formatISODateInUTC } from 'shared/utils/time-helpers';

import { useDeriveVitalsEngagement, usePatientDetails } from './queries';

export type VitalsEngagement = Nullable<{
  daysWithVitals: number;
  daysEnrolled: number;
  daysVitalsFetchedFor: number;
  usageRatio: number;
}>;

function getEnrolledDaysCountInPatientTimezone(
  enrollmentDate: Date,
  patientDetails: Patient,
) {
  const interval = {
    // Negating browser timezone in this timezone dependant calculation
    // as the enrollmentDate (its timestamp) is already in patient timezone
    start: utcToZonedTime(enrollmentDate, 'UTC'),
    // Getting current date time in patient timezone (instead of browser timezone)
    // to imitate that this timezone dependant calculation is done in patient
    // timezone. This may influence the result when the current date with browser
    // timezone is e.g. 2020-01-02T00:00:00-04:00 but patient timezone offset is
    // -7 hours so in patient timezone it would be 2020-01-01T21:00:00-07:00
    // so one less day in the interval as a result.
    end: utcToZonedTime(new Date(), patientDetails.timezone || ''),
  };
  return interval.start < interval.end
    ? eachDayOfInterval(interval).length
    : // In case the enrollment date in patient timezone is in the future we
      // just treat it as the first day of the enrollment.
      // This can happen as browser timezone is used to control what dates are
      // available in RPM / CCM consent date datepickers in Patient Admin, but
      // the selected enrollment date is then treated as in patient timezone,
      // so e.g. when patient timezone is UTC-7 while browser timezone is UTC-5
      // and there is one day in the former and next day already in the latter).
      1;
}

export function useVitalsEngagement(patientId: string) {
  const { data: patient, isLoading: isLoadingPatientDetails } =
    usePatientDetails(patientId, false);
  const enrollmentDate = getPatientEnrollmentDate(patient);
  const { daysWithVitals, isLoading: isLoadingDaysWithVitals } =
    useDaysWithPatientVitalsCount(patient, enrollmentDate);

  const isLoading = isLoadingPatientDetails || isLoadingDaysWithVitals;

  if (!patient || !enrollmentDate) {
    return { data: null as VitalsEngagement, isLoading };
  }

  const enrolledDaysCountInPatientTz = getEnrolledDaysCountInPatientTimezone(
    enrollmentDate,
    patient,
  );

  const daysVitalsFetchedFor = Math.min(enrolledDaysCountInPatientTz, 30);
  const numberDaysWithVitals = daysWithVitals ?? 0;

  return {
    data: {
      daysWithVitals,
      daysEnrolled: enrolledDaysCountInPatientTz,
      daysVitalsFetchedFor,
      usageRatio: numberDaysWithVitals / daysVitalsFetchedFor,
    } as VitalsEngagement,
    isLoading,
  };
}

function useDaysWithPatientVitalsCount(
  patient: Patient | undefined,
  enrollmentDate: Date | null,
) {
  // Two things to deal with here in terms of timezones:
  // 1. enrollmentDate is already in patient timezone,
  // while now Date(), it's timestamp, is in UTC as js dates don't have  any kind of timezone info.
  // 2. browser timezone influencing operations like getting a date string from js Date

  // (1.) enrollmentDate timestamp is already in patient's timezone, so use it as is
  const enrollmentDateInPatientTz = enrollmentDate;
  // (1.) need to adjust current epoch timestamp so getting a date for it results in patient tz date
  const nowInPatientTz = utcToZonedTime(
    utcToZonedTime(new Date(), 'UTC'),
    patient?.timezone || '',
  );
  const date29DaysAgoInPatientTz = subDays(nowInPatientTz, 29);

  // (1.) DerivePatientVitalsEngagement grpc accepts dates in patient timezone
  const { data: patientVitalsSummary, isLoading } = useDeriveVitalsEngagement({
    patientId: patient?.id,
    // (2.) ignore browser timezone (use UTC) when formatting
    dateFrom: formatISODateInUTC(
      enrollmentDateInPatientTz
        ? max([date29DaysAgoInPatientTz, enrollmentDateInPatientTz])
        : date29DaysAgoInPatientTz,
    ),
    // (2.) ignore browser timezone (use UTC) when formatting
    // grab +1 day to include today
    dateTo: formatISODateInUTC(addDays(nowInPatientTz, 1)),
  });

  if (!patientVitalsSummary) {
    return { daysWithVitals: 0, isLoading };
  }
  return {
    daysWithVitals: patientVitalsSummary.datesTakenVitals?.length,
    isLoading,
  };
}
