import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Script } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { useTimeEstimate } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from 'shared/common/Wizard';
import { useShowVitalsEducation } from 'shared/common/Wizard/state/sectionUtils';
import { flexContainer } from 'shared/jsStyle/flex.css';
import { gap } from 'shared/jsStyle/gap.css';

import { INIT_VISIT_SECTIONS } from '../../metadata';
import { ActionPlanIntroScript } from '../../shared/actionPlan/ActionPlanIntroScript';
import { initVisitPath } from '../paths';

export function ActionPlanScript() {
  const intl = useIntl();
  const history = useHistory();

  const showVitalsEducation = useShowVitalsEducation();

  return (
    <Wizard.Step
      sections={INIT_VISIT_SECTIONS}
      title={intl.formatMessage({ defaultMessage: 'Action Plan' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => {
        history.push(initVisitPath('/action-plan', '/set-goal'));
      }}
    >
      <div className={cx(flexContainer.column, gap.M)}>
        {showVitalsEducation ? (
          <ActionPlanIntroScript />
        ) : (
          <Script>
            <FormattedMessage defaultMessage="Thank you for answering all of those background questions. Our next step is to choose a health goal and outline the steps to achieving it." />
          </Script>
        )}
      </div>
    </Wizard.Step>
  );
}
