import { FormattedMessage } from 'react-intl';

import {
  conditionToAbbreviation,
  convertProgramResponseToConditions,
} from 'pages/adminPanel/patient-profile/tabs/ClinicalProfileTab/clinicalprofile.utils';
import { usePatientDetails } from 'shared/hooks/queries';
import { usePatientPrograms } from 'shared/hooks/queries/program-condition.queries';
import { useJitQueryEnabler } from 'shared/hooks/useJitQueryEnabler';
import type { Task } from 'shared/tasking/types';

import { useCanAssignTaskType } from '../utils';
import { title } from './styles.css';
import { useShouldShowPatientDetails } from './useShouldShowPatientDetails';

type Props = {
  task: Task;
};

export function QueueTaskTitle({ task }: Props) {
  const { patientId, taskQueues } = task;
  const { queryEnabled, inViewRef } = useJitQueryEnabler();
  const shouldShowPatientDetails = useShouldShowPatientDetails();
  const { data: patientDetails } = usePatientDetails(
    patientId || '',
    true,
    queryEnabled && shouldShowPatientDetails && Boolean(patientId),
  );
  const { data: programs } = usePatientPrograms(
    patientId ?? '',
    queryEnabled && shouldShowPatientDetails && !!patientId,
  );
  const canAssignTask = useCanAssignTaskType(task);
  const queueNames = taskQueues.map(({ queueName }) => queueName).join(', ');
  const state = patientDetails?.addresses?.find(({ primary }) => primary)
    ?.region;
  const [conditions] = convertProgramResponseToConditions(
    programs,
    patientDetails?.patient?.status,
  );
  const conditionAbbreviations = conditions
    .map(conditionToAbbreviation)
    .join(', ');

  if (!shouldShowPatientDetails) {
    return (
      <div className={title}>
        {canAssignTask ? (
          <FormattedMessage defaultMessage="Unassigned task" />
        ) : (
          <FormattedMessage
            defaultMessage="Task assigned to {queueNames}"
            values={{
              queueNames,
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div className={title} ref={inViewRef}>
      <FormattedMessage
        defaultMessage="Unassigned task for {patient}, {condition}, in {state}"
        values={{
          patient: `${patientDetails?.patient?.givenName} ${patientDetails?.patient?.familyName}`,
          queueNames,
          condition: conditionAbbreviations,
          state,
        }}
      />
    </div>
  );
}
