import { Skeleton } from 'shared/tempo/atom/Skeleton';

import { CardSection } from './CardSection';

export function TaskCardSkeleton() {
  return (
    <CardSection>
      <CardSection.LeftColumn>
        <Skeleton variant="circular" height={40} width={40} />
      </CardSection.LeftColumn>
      <CardSection.RightColumn>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </CardSection.RightColumn>
    </CardSection>
  );
}
