import {
  addDays,
  formatISO,
  isAfter,
  isBefore,
  parseISO,
  subDays,
} from 'date-fns';

import type { VitalsAlert } from 'shared/types/alert.types';

export const getVitalReadingsRange = (
  activeAlerts: VitalsAlert[],
  addBuffer = false,
) => {
  const range = activeAlerts.reduce<{ startDate?: Date; endDate?: Date }>(
    (acc, currentAlert) => {
      const { startDate, endDate } = acc;
      const { patient_day: alertDay } = currentAlert;
      const alertDate = parseISO(alertDay);

      if (!startDate || isBefore(alertDate, startDate)) {
        acc.startDate = alertDate;
      }
      if (!endDate || isAfter(alertDate, endDate)) {
        acc.endDate = alertDate;
      }
      return acc;
    },
    { startDate: undefined, endDate: undefined },
  );

  if (addBuffer && range.startDate && range.endDate) {
    const { startDate, endDate } = range;
    range.startDate = subDays(startDate, 7);
    range.endDate = addDays(endDate, 7);
  }

  return {
    startDate: range.startDate
      ? formatISO(range.startDate, { representation: 'date' })
      : '',
    endDate: range.endDate
      ? formatISO(range.endDate, { representation: 'date' })
      : '',
  };
};

export const getAlertDateRange = (
  activeAlerts: VitalsAlert[],
  addBuffer = false,
) => {
  const range = getVitalReadingsRange(activeAlerts, addBuffer);
  if (!range.startDate || !range.endDate) {
    return { startDate: '', endDate: '' };
  }
  return range;
};
