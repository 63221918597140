import { TableRow } from 'deprecated/mui';
import type { HeaderColumn } from 'shared/common/Table';
import { Table } from 'shared/common/Table';
import { Skeleton } from 'shared/tempo/atom/Skeleton';

type Props<T> = {
  columns: number | HeaderColumn<T>[];
  rows?: number;
  className?: string;
};

export function SkeletonTable<T>({ columns, rows = 4, className }: Props<T>) {
  const columnsArray = isHeaderColumns(columns)
    ? columns
    : (Array(columns).fill({ title: '' }) as HeaderColumn<string>[]);

  return (
    <>
      {Array(rows)
        .fill({})
        .map((_, rowIdx) => (
          <TableRow key={rowIdx}>
            {columnsArray.map((col, colIdx) => (
              <Table.NodeCell
                key={`${rowIdx}-${colIdx}`}
                className={className}
                type={col.type}
              >
                <Skeleton />
              </Table.NodeCell>
            ))}
          </TableRow>
        ))}
    </>
  );
}

function isHeaderColumns<T>(
  columns: number | HeaderColumn<T>[],
): columns is HeaderColumn<T>[] {
  return Array.isArray(columns);
}
