import type { AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { PatientClinicalProfile } from 'shared/generated/grpcGateway/pms.pb';
import { programConditionQuerykey } from 'shared/hooks/queries/program-condition.queries';
import type { NullableValuesPatientClinicalProfile } from 'shared/types/clinicalprofile.types';
import Session from 'shared/utils/session';

const getClinicalProfileQueryKeyBase = (patientId: string) =>
  ['rpm', 'v1', 'patients', patientId, 'clinicalProfile'] as const;

const getProgramWithProfileQueryKeyBase = (patientId: string) => [
  'rpm',
  'v1',
  'patients',
  patientId,
  'programWithProfile',
];

export function usePatientClinicalProfile(patientId: string) {
  return useQuery<PatientClinicalProfile>(
    getClinicalProfileQueryKeyBase(patientId),
    { enabled: Boolean(patientId) },
  );
}

export function useMutateClinicalProfile(patientId: string) {
  const client = useQueryClient();

  return useMutation<
    AxiosResponse<PatientClinicalProfile>,
    Error,
    NullableValuesPatientClinicalProfile
  >(
    (newProfile) =>
      Session.Api.put(
        getProgramWithProfileQueryKeyBase(patientId).join('/'),
        newProfile,
      ),
    {
      onSuccess: () => {
        client.invalidateQueries(getClinicalProfileQueryKeyBase(patientId));
        client.invalidateQueries(programConditionQuerykey.list(patientId));
      },
    },
  );
}
