import type { Patient } from 'shared/types/patient.types';
import type { MedManagementDelegation } from 'shared/types/shared.types';

import { usePatientReferringPhysician } from './providers.queries';

export enum MedManagementDelegationUiMapping {
  NoReferringPhysician = 'NO_REFERRING_PHYSICIAN',
  ProviderMedMgmtMissing = 'PROVIDER_MED_MANAGEMENT_DELEGATION_MISSING',
  ToBeDetermined = 'TO_BE_DETERMINED',
  OptInFullyManaged = 'OPT_IN_FULLY_MANAGED',
  OptInFullyManagedAndNotify = 'OPT_IN_FULLY_MANAGED_AND_NOTIFY',
  OptInCurrentMeds = 'OPT_IN_CURRENT_MEDS',
  OptInCurrentMedsAndNotify = 'OPT_IN_CURRENT_MEDS_AND_NOTIFY',
  OptOutRecommendMeds = 'OPT_OUT_RECOMMENDS_MEDS',
  OptOutProviderManaged = 'OPT_OUT_PROVIDER_MANAGED',
}

export function useMedManagementDelegation(patient: Patient | undefined): {
  isLoading: boolean;
  medDelegation: MedManagementDelegationUiMapping;
  shouldNotifyProvider?: boolean;
} {
  const { referringPhysician, isLoading } = usePatientReferringPhysician(
    patient?.id || '',
    !!patient?.id,
  );

  if (!patient) {
    return {
      isLoading,
      medDelegation: MedManagementDelegationUiMapping.ToBeDetermined,
    };
  }

  if (!referringPhysician) {
    return {
      isLoading,
      medDelegation: MedManagementDelegationUiMapping.NoReferringPhysician,
    };
  }
  const { preferences: providerPreferences } = referringPhysician;
  const shouldNotifyProviderOfMedChanges =
    !!referringPhysician?.preferences?.notify_on_medication_change;

  const providerMedManagementDelegation =
    providerPreferences?.med_management_delegation ||
    MedManagementDelegationUiMapping.ProviderMedMgmtMissing;

  const medMgmtDelegationPreference =
    providerPreferences?.allow_patient_level_med_management_delegation
      ? patient.med_management_delegation
      : providerMedManagementDelegation;

  const { shouldNotifyProvider, medDelegation } =
    getFriendlyMedManagementDelegation(
      shouldNotifyProviderOfMedChanges,
      medMgmtDelegationPreference,
    );
  return {
    isLoading,
    medDelegation,
    shouldNotifyProvider,
  };
}

const getFriendlyMedManagementDelegation = (
  shouldNotifyProvider: boolean,
  medDelegation: MedManagementDelegationUiMapping | MedManagementDelegation,
): {
  shouldNotifyProvider: boolean;
  medDelegation: MedManagementDelegationUiMapping;
} => {
  switch (medDelegation) {
    case MedManagementDelegationUiMapping.OptInFullyManaged:
      return {
        shouldNotifyProvider,
        medDelegation: shouldNotifyProvider
          ? MedManagementDelegationUiMapping.OptInFullyManagedAndNotify
          : MedManagementDelegationUiMapping.OptInFullyManaged,
      };
    case MedManagementDelegationUiMapping.OptInCurrentMeds:
      return {
        shouldNotifyProvider,
        medDelegation: shouldNotifyProvider
          ? MedManagementDelegationUiMapping.OptInCurrentMedsAndNotify
          : MedManagementDelegationUiMapping.OptInCurrentMeds,
      };
    default:
      return {
        shouldNotifyProvider: false,
        medDelegation: medDelegation as MedManagementDelegationUiMapping,
      };
  }
};
