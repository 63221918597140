import last from 'lodash/last';
import sortBy from 'lodash/sortBy';

import {
  type Condition as PmsCondition,
  ProgramProgramStatus,
  ProgramProgramType,
} from 'shared/generated/grpcGateway/pms.pb';
import type {
  Program,
  ProgramCondition,
  ProspectivePatient,
  StatusNote,
} from 'shared/types/patient.types';
import { PatientStatus } from 'shared/types/patient.types';

export function getPrimaryICD10CodesFromProgramAndStatus(
  programs: Maybe<Program[]>,
  status: PatientStatus,
): Nullable<string> {
  if (!programs?.length) {
    return '';
  }
  let programStatus: ProgramProgramStatus;
  if ([PatientStatus.Enrolled].includes(status)) {
    programStatus = ProgramProgramStatus.ENROLLED;
  } else {
    programStatus = ProgramProgramStatus.ENROLLED;
  }

  const foundProgram = programs.find(
    (program) =>
      program.program_type === ProgramProgramType.RPM &&
      program.program_status === programStatus,
  );

  return getPrimaryICD10CodesByProgram(foundProgram);
}

function getICD10Code(condition: ProgramCondition): string {
  return (
    condition.category +
    (condition.etiology ? '.' : '') +
    (condition.etiology || '')
  );
}

export function getPrimaryICD10CodesByProgram(
  program: Maybe<Program>,
  specificCondition: Maybe<PmsCondition> = null,
): Nullable<string> {
  if (!program) {
    return null;
  }

  const conditionToPrimaryICD10Map: Map<PmsCondition, string> = new Map();
  program.conditions.forEach((condition) => {
    if (!conditionToPrimaryICD10Map.has(condition.condition_type)) {
      conditionToPrimaryICD10Map.set(
        condition.condition_type,
        getICD10Code(condition),
      );
    } else {
      const existingICD10 = conditionToPrimaryICD10Map.get(
        condition.condition_type,
      );
      if (!existingICD10) {
        conditionToPrimaryICD10Map.set(
          condition.condition_type,
          getICD10Code(condition),
        );
      } else {
        const existingEtiology = existingICD10.includes('.')
          ? existingICD10.split('.')[1]
          : '';
        if (existingEtiology.length < (condition.etiology || '').length) {
          conditionToPrimaryICD10Map.set(
            condition.condition_type,
            getICD10Code(condition),
          );
        }
      }
    }
  });

  if (specificCondition) {
    return conditionToPrimaryICD10Map.get(specificCondition) || null;
  }

  return [...conditionToPrimaryICD10Map.values()].join(', ');
}

export function getLatestPatientStatusNoteByStatus(
  { status_notes }: ProspectivePatient,
  status: PatientStatus,
) {
  return getLatestPatientStatusNote(
    status_notes,
    (note) => note.status_to === status,
  );
}

export function getLatestPatientStatusNote(
  statusNotes: StatusNote[],
  predicate: (statusNote: StatusNote) => boolean = () => true,
) {
  return last(
    sortBy(statusNotes.filter(predicate), ({ created_at }) =>
      new Date(created_at).getTime(),
    ),
  );
}

export function getLatestPatientRpmOrder(patient: ProspectivePatient) {
  return getLatestRpmOrder(patient.status_notes);
}

export function getLatestRpmOrder(statusNotes: StatusNote[]) {
  return getLatestPatientStatusNote(statusNotes, (note) =>
    Boolean(note.rpm_order),
  )?.rpm_order;
}
