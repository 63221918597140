import { Box } from 'deprecated/mui';
import { flexSection } from 'shared/jsStyle';
import { color } from 'shared/tempo/theme';

export function PatientListEmptyResults({
  icon,
  message,
}: {
  icon: JSX.Element;
  message: string;
}) {
  return (
    <Box
      sx={{
        ...flexSection('column'),
        color: color.Theme.Light['Base Font Subtle'],
        fontSize: '14px',
        marginTop: '1.5rem',
        marginBottom: '2.5rem',
      }}
    >
      <Box sx={{ marginTop: '1rem', marginBottom: '1rem' }}>{icon}</Box>
      {message}
    </Box>
  );
}
