import type { ComponentType } from 'react';

import AthenaLogo from 'shared/assets/svgs/athena.svg?react';
import EpicLogo from 'shared/assets/svgs/epic.svg?react';
import CernerLogo from 'shared/assets/svgs/oracle-cerner.svg?react';
import { EHR } from 'shared/types/ehr.types';

type EhrConfigMap = {
  [key in Exclude<EHR, EHR.NON_INTEGRATED>]: {
    icon: ComponentType;
    name: string;
  };
};

// we don't want to i18n the names as they are proper nouns
export const EHR_CONFIG_MAP: EhrConfigMap = {
  [EHR.Athena]: {
    icon: AthenaLogo,
    name: 'Athena',
  },
  [EHR.Epic]: {
    icon: EpicLogo,
    name: 'Epic',
  },
  [EHR.Cerner]: {
    icon: CernerLogo,
    name: 'Cerner',
  },
};
