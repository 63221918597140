import { useIntl } from 'react-intl';

import PatientsIcon from 'shared/assets/svgs/patients.svg?react';
import UserIcon from 'shared/assets/svgs/user.svg?react';

import { Choice } from '../TaskCard/Choice';

type Props = {
  isIndividual?: boolean;
  isSelected?: boolean;
  onPress?: () => void;
};

export function AssignmentChoice({
  isIndividual = false,
  isSelected = false,
  onPress = () => {},
}: Props) {
  const intl = useIntl();
  const ChoiceIcon = isIndividual ? UserIcon : PatientsIcon;

  return (
    <Choice
      isSelected={isSelected}
      onPress={onPress}
      Icon={ChoiceIcon}
      title={
        isIndividual
          ? intl.formatMessage({ defaultMessage: 'Individual' })
          : intl.formatMessage({ defaultMessage: 'Team' })
      }
    />
  );
}
