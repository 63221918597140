import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import { validators } from 'shared/common/Form/validations';
import { TimePeriod } from 'shared/utils/time-helpers';

export type DeviceUsageGoalsFormFields = {
  declinedToSetGoal: boolean;
  devicesAccessible?: 'true' | 'false';
  deviceLocation?: string;
  hasSchedule?: 'true' | 'false';
  goalScheduleHour?: string;
  goalSchedulePeriod?: 'am' | 'pm';
};

export function getDeviceUsageGoalsFormConfig(
  intl: IntlShape,
  values?: DeviceUsageGoalsFormFields,
) {
  const { boolean, enumType, required } = validators(intl);

  return {
    fields: {
      declinedToSetGoal: {
        defaultValue: values?.declinedToSetGoal ?? false,
        validation: boolean(),
      },
      devicesAccessible: {
        defaultValue: values?.devicesAccessible ?? undefined,
        validation: yup
          .string()
          .oneOf(['true', 'false'])
          .when('declinedToSetGoal', {
            is: false,
            then: required,
          }),
      },
      deviceLocation: {
        defaultValue: values?.deviceLocation ?? undefined,
        validation: yup
          .string()
          .when(['declinedToSetGoal', 'devicesAccessible'], {
            is: (
              declinedToSetGoal: boolean,
              devicesAccessible: 'true' | 'false',
            ) => !declinedToSetGoal && devicesAccessible === 'false',
            then: required,
          }),
      },
      hasSchedule: {
        defaultValue: values?.hasSchedule ?? undefined,
        validation: yup
          .string()
          .oneOf(['true', 'false'])
          .when(['declinedToSetGoal', 'deviceLocation'], {
            is: (declinedToSetGoal: boolean, deviceLocation: string) =>
              !declinedToSetGoal && !!deviceLocation,
            then: required,
          }),
      },
      goalScheduleHour: {
        defaultValue: values?.goalScheduleHour ?? undefined,
        validation: yup.string().when(['declinedToSetGoal', 'hasSchedule'], {
          is: (declinedToSetGoal: boolean, hasSchedule: 'true' | 'false') =>
            !declinedToSetGoal && hasSchedule === 'false',
          then: required,
        }),
      },
      goalSchedulePeriod: {
        defaultValue: values?.goalSchedulePeriod ?? TimePeriod.AnteMeridiem,
        validation: enumType({ source: TimePeriod, pluck: 'values' }).when(
          ['declinedToSetGoal', 'hasSchedule'],
          {
            is: (declinedToSetGoal: boolean, hasSchedule: 'true' | 'false') =>
              !declinedToSetGoal && hasSchedule === 'false',
            then: required,
          },
        ),
      },
    },
  };
}
