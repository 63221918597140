import cx from 'classnames';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { AppBar, Button } from 'deprecated/mui';

import { banner, button, contents, warning } from './styles.css';

const PROD_PREVIEW_HOSTNAME = /^[a-z0-9]{7}\.my\.cadence\.care$/;

export function ProductionPreviewBanner() {
  const { hostname } = window.location;
  const [isExpanded, setIsExpanded] = useState(true);

  if (hostname.match(PROD_PREVIEW_HOSTNAME)) {
    return (
      <AppBar
        className={cx({
          [banner.expanded]: isExpanded,
          [banner.minimized]: !isExpanded,
        })}
      >
        <div className={contents}>
          <span className={warning}>⚠️</span>
          <FormattedMessage
            defaultMessage="WARNING: This is a production preview. Do not modify data or leak
          EPHI!"
          />
        </div>
        <Button
          disableRipple
          color="inherit"
          size="small"
          className={button}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? (
            <FormattedMessage defaultMessage="Minimize" />
          ) : (
            <FormattedMessage defaultMessage="Expand" />
          )}
        </Button>
      </AppBar>
    );
  }

  return null;
}
