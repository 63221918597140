import type { ReactElement } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from 'shared/common/Form';
import { GridItem } from 'shared/common/Form/GridItem';
import type { BaseFieldProps } from 'shared/common/Form/fields/BaseField';
import { RequiredIndicator } from 'shared/tempo/atom/Label';

import {
  type FormFields,
  type PreventativeCareName,
  getPreventativeCareFieldNames,
} from '../formConfig';
import { notApplicableText } from './PreventativeCare.css';
import {
  extraDetails,
  sectionTitle,
  toggleRowHelp,
  toggleRowLabel,
} from './carePlanSections.css';
import { PREVENTATIVE_CARE_OPTIONS } from './preventativeCareOptions';

type Props = {
  form: UseFormReturn<FormFields>;
};

export function PreventativeCare({ form }: Props) {
  const intl = useIntl();

  return (
    <Form.Section
      title={intl.formatMessage({
        defaultMessage: 'Preventative Care (Enter Dates)',
      })}
      classes={{ title: sectionTitle }}
    >
      {PREVENTATIVE_CARE_OPTIONS.map((option) => (
        <PreventativeCareRow
          key={option.name}
          name={option.name}
          label={option.label}
          help={option.help}
          alwaysApplicable={option.alwaysApplicable}
          form={form}
        />
      ))}
      <p className={extraDetails}>
        <FormattedMessage defaultMessage="*A pack-year is smoking an average of one pack of cigarettes per day for one year. For example, a person could have a 20 pack-year history by smoking one pack a day for 20 years or two packs a day for 10 years." />
      </p>
    </Form.Section>
  );
}

function PreventativeCareRow({
  name,
  label,
  help,
  form,
  alwaysApplicable,
}: {
  name: PreventativeCareName;
  label: ReactElement;
  help?: ReactElement;
  alwaysApplicable: boolean;
} & Props) {
  const intl = useIntl();
  const {
    checked: checkedFieldName,
    date: dateFieldName,
    notApplicable: notApplicableFieldName,
  } = getPreventativeCareFieldNames(name);

  const checked = !!form.watch(checkedFieldName);

  return (
    <Form.Row>
      <GridItem size={alwaysApplicable ? 7 : 5.3}>
        <span>
          {label}
          {checked && <RequiredIndicator />}
        </span>
        {help && <span className={toggleRowHelp}>{help}</span>}
      </GridItem>
      {!alwaysApplicable && (
        <Form.Checkbox
          // In general, we don't want to support decimal sizes, but here we need it
          // in order to get the field size to work well.
          // (Need to account for the form when the CCM Care Plan Encounter is Open/Closed)
          size={1.7 as BaseFieldProps['size']}
          labelPlacement="start"
          label={
            <span className={notApplicableText}>
              {intl.formatMessage({ defaultMessage: 'NA' })}
            </span>
          }
          name={notApplicableFieldName}
          onChange={(isChecked) => {
            if (isChecked) {
              form.resetField(checkedFieldName);
              form.resetField(dateFieldName);
            }
          }}
        />
      )}
      <Form.Toggle
        label=""
        disabled={!!form.getValues(notApplicableFieldName)}
        aria-label={checkedFieldName}
        name={checkedFieldName}
        labelPlacement="start"
        labelClassName={toggleRowLabel}
        size={1}
        onChange={(isChecked) => {
          if (!isChecked) {
            form.resetField(dateFieldName);
          }
        }}
      />
      <Form.TextField
        size={3}
        name={dateFieldName}
        placeholder="MM / DD / YYYY"
        isDisabled={
          !form.getValues(checkedFieldName) ||
          !!form.getValues(notApplicableFieldName)
        }
      />
    </Form.Row>
  );
}
