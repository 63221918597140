import cx from 'classnames';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PencilIcon from 'shared/assets/svgs/pencil.svg?react';
import { UserAvatar } from 'shared/common/UserAvatar';
import {
  useUpdateComment,
  useUpdateTask,
} from 'shared/hooks/queries/tasks.queries';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import type { Task, Comment as TaskComment } from 'shared/tasking/types';
import { Button } from 'shared/tempo/atom/Button';
import { Tooltip } from 'shared/tempo/atom/Tooltip';
import {
  HotkeyTooltip,
  SpecialChar,
} from 'shared/tempo/molecule/HotkeyTooltip';
import { useToaster } from 'shared/tempo/molecule/Toast';
import { color } from 'shared/tempo/theme';

import { CardHoverAction } from '../CardHoverAction';
import {
  CommentInput,
  MAX_COMMENT_LENGTH,
} from '../CommentInputControls/CommentInput';
import { Timestamp } from '../Timestamp';
import { useTaskCardContext } from '../taskCardContext';
import { formatProviderName } from '../utils';
import {
  author as authorClass,
  editActions,
  footer,
  note,
  pencilIconButton,
} from './Comment.css';

type Props = {
  taskOrComment: Task | TaskComment;
  canEdit: boolean;
  alternate?: boolean;
};

export function Comment({ canEdit, alternate, taskOrComment }: Props) {
  const body = isTask(taskOrComment)
    ? taskOrComment.description
    : taskOrComment.body;
  const author = isTask(taskOrComment)
    ? taskOrComment.creator ?? { familyName: 'System', givenName: 'Cadence' }
    : taskOrComment.author;
  const createdAt = taskOrComment.createTime;

  const { setIsEditingComment } = useTaskCardContext();
  const [isEditing, setIsEditing] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [editingBody, setEditingBody] = useState('');
  const setIsEditingState = (editing: boolean) => {
    setIsEditing(editing);
    setIsEditingComment(editing);
  };

  const intl = useIntl();
  const { currentUserId } = useCurrentUser();
  const isAuthor = currentUserId === author?.uid;

  const { update, isUpdating } = useUpdateTaskOrComment(taskOrComment, {
    onSuccess: () => {
      setEditingBody('');
      setIsDirty(false);
      setIsEditingState(false);
    },
  });

  const canSave =
    Boolean(editingBody) &&
    editingBody.length <= MAX_COMMENT_LENGTH &&
    !isUpdating &&
    isDirty;

  return (
    <>
      {isEditing && (
        <>
          <CommentInput
            onSubmit={() => update(editingBody)}
            canSubmit={canSave}
            disabled={isUpdating}
            expand="expanded"
            value={editingBody || ''}
            onChange={(val) => {
              setEditingBody(val);
              setIsDirty(true);
            }}
            placeholder={intl.formatMessage({
              defaultMessage: 'Type a comment',
            })}
          />
        </>
      )}
      {!isEditing && (
        <div
          className={cx({
            [note.default]: !alternate,
            [note.alternate]: alternate,
          })}
        >
          {body}
        </div>
      )}
      <div className={footer.container}>
        <div className={footer.left}>
          <UserAvatar
            size="small"
            firstName={author?.givenName ?? ''}
            lastName={author?.familyName ?? ''}
          />
          <span className={authorClass}>
            {formatProviderName(author ?? {})}
          </span>
          <Timestamp.Comment ts={createdAt} />
        </div>
        <div className={footer.right}>
          {isAuthor && canEdit && (
            <CardHoverAction>
              {({ isDisabled }) => (
                <Tooltip
                  isDisabled={isDisabled}
                  placement="bottom"
                  content={<FormattedMessage defaultMessage="Edit Comment" />}
                >
                  <Button
                    isDisabled={isDisabled}
                    variant="tertiary"
                    size="small"
                    className={pencilIconButton}
                    onPress={() => {
                      setIsEditingState(true);
                      setEditingBody(body);
                      setIsDirty(false);
                    }}
                  >
                    <Button.Icon>
                      <PencilIcon
                        fill={color.Theme.Light['Base Font Subtle']}
                      />
                    </Button.Icon>
                  </Button>
                </Tooltip>
              )}
            </CardHoverAction>
          )}
        </div>
      </div>
      {isEditing && (
        <div className={editActions}>
          <Button
            size="small"
            variant="secondary"
            onPress={() => setIsEditingState(false)}
          >
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
          <HotkeyTooltip
            hotkey={[SpecialChar.Meta, SpecialChar.Return]}
            disabled={!canSave}
            title={intl.formatMessage({ defaultMessage: 'Save' })}
          >
            <Button
              size="small"
              isDisabled={!canSave}
              onPress={() => update(editingBody)}
            >
              <FormattedMessage defaultMessage="Save" />
            </Button>
          </HotkeyTooltip>
        </div>
      )}
    </>
  );
}

function isTask(taskOrComment: Task | TaskComment): taskOrComment is Task {
  return Boolean((taskOrComment as Task).comments);
}

type Callbacks = { onSuccess: () => void };

function useUpdateTaskOrComment(
  taskOrComment: Task | TaskComment,
  { onSuccess }: Callbacks,
) {
  const { toaster } = useToaster();
  const intl = useIntl();

  const { mutate: updateComment, isLoading: isSavingCOmment } =
    useUpdateComment(taskOrComment, {
      onSuccess,
      onError: () =>
        toaster.error(
          intl.formatMessage({ defaultMessage: 'Failed to update comment' }),
        ),
    });

  const { isLoading: isSavingTask, mutate: updateTask } = useUpdateTask(
    taskOrComment,
    {
      onError: () =>
        toaster.error(
          intl.formatMessage({
            defaultMessage: 'Failed to update task',
          }),
        ),
      onSuccess,
    },
  );

  return {
    update: (body: string) =>
      isTask(taskOrComment)
        ? updateTask({ task: { description: body } })
        : updateComment({ comment: { body } }),
    isUpdating: isSavingTask || isSavingCOmment,
  };
}
