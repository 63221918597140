import { useEffect } from 'react';
import type { UseFormReturn } from 'react-hook-form';

import type { CyclicalDependencies } from 'shared/common/Form/form.types';
import { usePrevious } from 'shared/hooks';

export const EMERGENCY_CONTACT_FIELDS: string[] = [
  'contact_full_name',
  'emergency_phone',
  'relationship',
  'emergency_phone_type',
];

export function getEmergencyContactDependentFields(forField: string) {
  return EMERGENCY_CONTACT_FIELDS.filter((field) => field !== forField);
}
export const EMERGENCY_CONTACT_CYCLICAL_DEPENDENCIES = [
  ['contact_full_name', 'emergency_phone'],
  ['contact_full_name', 'relationship'],
  ['contact_full_name', 'emergency_phone_type'],
  ['emergency_phone', 'relationship'],
  ['emergency_phone', 'emergency_phone_type'],
  ['relationship', 'emergency_phone_type'],
] as CyclicalDependencies;

export function useTriggerValidationOnEnrollmentTypeChange(
  form: UseFormReturn,
  enable: boolean,
  enrollmentType?: 'VIRTUAL' | 'CLINIC' | 'RE_ENROLLED',
) {
  const prevEnrollmentType = usePrevious(enrollmentType);
  useEffect(() => {
    if (
      enable &&
      enrollmentType &&
      prevEnrollmentType &&
      enrollmentType !== prevEnrollmentType
    ) {
      form.trigger(['contact_full_name']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollmentType, prevEnrollmentType, enable]);
}
