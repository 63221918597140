import { draftJsFieldTemplate } from 'pages/patients/patientDetails/ui/Notes/NoteEditor/templates/template.utils';
import { EncounterModuleId } from 'pages/patients/patientDetails/ui/Notes/Notes.types';

import clinicalAttestationBody from './clinical_attestation_body.hbs';

export const clinicalAttestationModule = {
  [EncounterModuleId.ClinicalAttestation]: {
    clinical_attestation_body: draftJsFieldTemplate(clinicalAttestationBody),
  },
};
