import uniq from 'lodash/uniq';
import without from 'lodash/without';

import ArrowUpRight from 'shared/assets/svgs/arrow-up-right.svg?react';
import { Accordion } from 'shared/common/Accordion';
import { sentenceCase } from 'shared/jsStyle/text.css';
import { Button } from 'shared/tempo/atom/Button';
import { Checkbox } from 'shared/tempo/atom/Checkbox';
import type { RpmCondition } from 'shared/types/clinicalprofile.types';
import { Condition } from 'shared/types/clinicalprofile.types';

import { PatientGoal } from '../PatientGoal';
import { reviewListLink } from './ModuleAccordion.css';
import { educationModule } from './SharedEducationModulesReview.css';
import { EDUCATION_MODULES } from './constants';
import type { EducationModule, EducationModuleSection } from './types';
import { UnderstandDiseaseConditions } from './types';

type Props = {
  header: string;
  module: EducationModule;
  conditions?: RpmCondition[];
  reviewedModules: EducationModuleSection[];
  disabledModules?: EducationModuleSection[];
  patientGoal?: PatientGoal;
  onReviewedChange: (reviewed: EducationModuleSection[]) => void;
};

export function ModuleAccordion({
  header,
  module,
  conditions,
  reviewedModules,
  disabledModules = [],
  patientGoal,
  onReviewedChange,
}: Props) {
  const sections = EDUCATION_MODULES[module].filter(
    (s) => !s.deprecated || reviewedModules.includes(s.section),
  );
  return (
    <Accordion
      clickableHeader
      defaultOpen={getDefaultOpen(module, conditions, patientGoal)}
      shouldUnmountChildren
      header={<span>{header}</span>}
      className={educationModule}
    >
      {sections?.map((link) => (
        <div key={link.href} className={reviewListLink}>
          <Button
            size="small"
            variant="tertiary"
            onPress={() => window.open(link.href, '_blank')}
          >
            <span className={sentenceCase}>{link.title}</span>
            <Button.Icon>
              <ArrowUpRight />
            </Button.Icon>
          </Button>
          <Checkbox
            isDisabled={disabledModules.includes(link.section)}
            isSelected={reviewedModules.includes(link.section)}
            onChange={(isReviewed) => {
              if (isReviewed) {
                // Add to reviewed
                onReviewedChange(uniq([...reviewedModules, link.section]));
              } else {
                // Remove from reviewed
                onReviewedChange(without(reviewedModules, link.section));
              }
            }}
          />
        </div>
      ))}
    </Accordion>
  );
}

function getDefaultOpen(
  module: EducationModule,
  conditions: RpmCondition[] | undefined,
  patientGoal: PatientGoal | undefined,
) {
  if (module === patientGoal) {
    return true;
  }
  // Check for condition specific module goal
  if (patientGoal === PatientGoal.BetterUnderstandMyDisease) {
    if (
      module === UnderstandDiseaseConditions.Htn &&
      conditions?.includes(Condition.Hypertension)
    ) {
      return true;
    }
    if (
      module === UnderstandDiseaseConditions.T2d &&
      conditions?.includes(Condition.TypeTwoDiabetes)
    ) {
      return true;
    }
  }
  return false;
}
