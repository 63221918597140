import { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useIntl } from 'react-intl';

import SendIcon from 'shared/assets/svgs/send.svg?react';
import { TextArea } from 'shared/common/TextArea';
import { useTwilioFlexContext } from 'shared/common/TwilioFlex';
import { useSendSms } from 'shared/hooks/queries/communications.queries';
import { ACTIVE_TASK_STATUSES } from 'shared/hooks/useTwilioFlexMessagesListener';
import { circular } from 'shared/jsStyle/utils.css';
import { Button } from 'shared/tempo/atom/Button';
import {
  HotkeyTooltip,
  SpecialChar,
} from 'shared/tempo/molecule/HotkeyTooltip';
import { useToaster } from 'shared/tempo/molecule/Toast';
import { getErrorMsg } from 'shared/utils/helpers';

import { sendMessageInput, sendMessagesContainer } from './Messages.css';

type Props = {
  patientId: Nullable<string>;
  caregiverId: Nullable<string>;
  patientSmsOptIn?: boolean;
  caregiverSmsOptIn?: boolean;
};

export function SendMessages({
  patientId,
  caregiverId,
  patientSmsOptIn,
  caregiverSmsOptIn,
}: Props) {
  const intl = useIntl();
  const [smsBody, setSmsBody] = useState<string>('');
  const { toaster } = useToaster();

  const sendSmsMutation = useSendSms(patientId, caregiverId);
  const { tasks } = useTwilioFlexContext();

  // Clear smsBody when switching between patient and caregiver(s)
  useEffect(() => {
    setSmsBody('');
  }, [patientId, caregiverId]);

  const hasActivePatientTask = Boolean(
    patientId &&
      Array.from(tasks.values()).some(
        (task) =>
          ACTIVE_TASK_STATUSES.includes(task.status) &&
          task.data.patient_id === patientId &&
          task.data.channelType === 'sms',
      ),
  );
  const hasActiveCaregiverTask = Boolean(
    caregiverId &&
      Array.from(tasks.values()).some(
        (task) =>
          ACTIVE_TASK_STATUSES.includes(task.status) &&
          task.data.caregiver_id === caregiverId &&
          task.data.channelType === 'sms',
      ),
  );

  const recipientSmsOptOut = Boolean(
    (patientId && !patientSmsOptIn) || (caregiverId && !caregiverSmsOptIn),
  );

  const blockSendSms =
    recipientSmsOptOut ||
    hasActivePatientTask ||
    hasActiveCaregiverTask ||
    sendSmsMutation.isLoading ||
    smsBody.length === 0;

  useHotkeys('meta+enter', handleSendSms, [handleSendSms], {
    enabled: !blockSendSms,
    enableOnFormTags: true,
    enableOnContentEditable: true,
    preventDefault: true,
  });

  function handleSendSms() {
    if (smsBody) {
      sendSmsMutation.mutate(smsBody, {
        onSuccess: () => {
          setSmsBody('');
        },
        onError: (err) => {
          toaster.error(getErrorMsg(err));
        },
      });
    }
  }

  let placeHolderText;

  if (hasActivePatientTask || hasActiveCaregiverTask) {
    placeHolderText = intl.formatMessage(
      {
        defaultMessage: 'There is an active Twilio Task for this {recipient}',
      },
      {
        recipient: patientId ? 'patient' : 'caregiver',
      },
    );
  } else if (recipientSmsOptOut) {
    placeHolderText = intl.formatMessage({
      defaultMessage: 'SMS Opt-In disabled',
    });
  } else {
    placeHolderText = intl.formatMessage(
      {
        defaultMessage: 'Type a message to the {recipient}',
      },
      {
        recipient: patientId ? 'patient' : 'caregiver',
      },
    );
  }

  return (
    <div className={sendMessagesContainer}>
      <TextArea
        aria-label={intl.formatMessage(
          {
            defaultMessage: 'Send Message to {recipient}',
          },
          {
            recipient: patientId
              ? intl.formatMessage({ defaultMessage: 'Patient' })
              : intl.formatMessage({ defaultMessage: 'Caregiver' }),
          },
        )}
        disabled={
          recipientSmsOptOut ||
          hasActivePatientTask ||
          hasActiveCaregiverTask ||
          sendSmsMutation.isLoading
        }
        maxRows={5}
        value={smsBody}
        onChange={(event) => setSmsBody(event.target.value)}
        wrapperClassName={sendMessageInput}
        placeholder={placeHolderText}
      />
      <HotkeyTooltip
        title={intl.formatMessage(
          { defaultMessage: 'Send to {recipient}' },
          { recipient: patientId ? 'patient' : 'caregiver' },
        )}
        hotkey={[SpecialChar.Meta, SpecialChar.Return]}
        classes={{ triggerContainer: circular }}
      >
        <Button
          variant="primary"
          isDisabled={blockSendSms}
          onPress={handleSendSms}
          isProcessing={sendSmsMutation.isLoading}
        >
          <Button.Icon>
            <SendIcon />
          </Button.Icon>
        </Button>
      </HotkeyTooltip>
    </div>
  );
}
