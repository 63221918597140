import classnames from 'classnames/bind';
import type { ReactNode } from 'react';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { IconButton } from 'deprecated/mui';
import ArrowLeftIcon from 'shared/assets/svgs/arrow-left.svg?react';

import styles from '../../styles.module.scss';
import { arrowLeftIcon } from './DeprecatedHeader.css';

const cx = classnames.bind(styles);

type Props = {
  subContent?: JSX.Element;
  right?: JSX.Element;
  title: ReactNode;
  backNavigation?: boolean | string;
  className?: {
    containerHeader?: string;
    mainContent?: string;
    headerLeft?: string;
    subContent?: string;
    headerTitle?: string;
    headerRight?: string;
  };
};

export function DeprecatedHeader({
  title,
  right,
  subContent,
  backNavigation,
  className,
}: Props) {
  const history = useHistory();

  const clickHandler = useCallback(() => {
    if (typeof backNavigation === 'string') {
      history.push(backNavigation);
      return;
    }
    history.goBack();
  }, [history, backNavigation]);

  return (
    <div className={cx('container-header', className?.containerHeader)}>
      <div className={cx('main-content', className?.mainContent)}>
        <div className={cx('header-left', className?.headerLeft)}>
          {backNavigation && (
            <div className={cx('button-container')}>
              <IconButton
                type="button"
                className={cx('button')}
                onClick={clickHandler}
              >
                <ArrowLeftIcon className={arrowLeftIcon} />
              </IconButton>
            </div>
          )}
          <div className={cx('header-title', className?.headerTitle)}>
            {title}
          </div>
        </div>
        <div className={cx('header-right', className?.headerRight)}>
          {right}
        </div>
      </div>
      {subContent && (
        <div className={cx('sub-content', className?.subContent)}>
          {subContent}
        </div>
      )}
    </div>
  );
}
