import type { IntlShape } from 'react-intl';

import {
  MedicationChangeChangeType,
  MedicationChangeFrequency,
  MedicationChangeStatus,
  ReferenceMedicationMedicationClass,
  RxNormDeliveryMechanism,
  RxNormMedicationUnit,
  RxNormReleaseType,
} from 'shared/generated/grpcGateway/medication.pb';

export type FrequencyOption = Exclude<
  keyof typeof MedicationChangeFrequency,
  'FREQUENCY_UNSPECIFIED'
>;

export function frequencyLabels(
  intl: IntlShape,
): Record<FrequencyOption, string> {
  return {
    [MedicationChangeFrequency.DAILY]: intl.formatMessage({
      defaultMessage: 'Daily',
    }),
    [MedicationChangeFrequency.DAILY_AM]: intl.formatMessage({
      defaultMessage: 'Daily in the morning',
    }),
    [MedicationChangeFrequency.DAILY_PM]: intl.formatMessage({
      defaultMessage: 'Daily in the evening',
    }),
    [MedicationChangeFrequency.TWICE_A_DAY]: intl.formatMessage({
      defaultMessage: 'Twice a day',
    }),
    [MedicationChangeFrequency.THREE_TIMES_A_DAY]: intl.formatMessage({
      defaultMessage: 'Three times a day',
    }),
    [MedicationChangeFrequency.FOUR_TIMES_A_DAY]: intl.formatMessage({
      defaultMessage: 'Four times a day',
    }),
    [MedicationChangeFrequency.EVERY_OTHER_DAY]: intl.formatMessage({
      defaultMessage: 'Every other day',
    }),
    [MedicationChangeFrequency.ONCE_A_WEEK]: intl.formatMessage({
      defaultMessage: 'Once a week',
    }),
    [MedicationChangeFrequency.TWICE_A_WEEK]: intl.formatMessage({
      defaultMessage: 'Twice a week',
    }),
    [MedicationChangeFrequency.MONTHLY]: intl.formatMessage({
      defaultMessage: 'Monthly',
    }),
    [MedicationChangeFrequency.AS_NEEDED]: intl.formatMessage({
      defaultMessage: 'As needed',
    }),
    [MedicationChangeFrequency.WITH_MEALS]: intl.formatMessage({
      defaultMessage: 'With meals',
    }),
    [MedicationChangeFrequency.HOURLY]: intl.formatMessage({
      defaultMessage: 'Hourly',
    }),
    [MedicationChangeFrequency.EVERY_BEDTIME]: intl.formatMessage({
      defaultMessage: 'Every bedtime',
    }),
  };
}

export type MedClassOptions = Exclude<
  keyof typeof ReferenceMedicationMedicationClass,
  'MEDICATION_CLASS_UNSPECIFIED'
>;

export function medClassLabels(
  intl: IntlShape,
): Record<MedClassOptions, string> {
  return {
    [ReferenceMedicationMedicationClass.ACE_INHIBITOR]: intl.formatMessage({
      defaultMessage: 'Ace Inhibitor',
    }),
    [ReferenceMedicationMedicationClass.ARB]: intl.formatMessage({
      defaultMessage: 'ARB',
    }),
    [ReferenceMedicationMedicationClass.CCB_DHP]: intl.formatMessage({
      defaultMessage: 'CCB DHP',
    }),
    [ReferenceMedicationMedicationClass.ARNI]: intl.formatMessage({
      defaultMessage: 'ARNI',
    }),
    [ReferenceMedicationMedicationClass.CCB_NON_DHP]: intl.formatMessage({
      defaultMessage: 'CCB Non DHP',
    }),
    [ReferenceMedicationMedicationClass.LOOP_DIURETICS]: intl.formatMessage({
      defaultMessage: 'Loop Diuretics',
    }),
    [ReferenceMedicationMedicationClass.ALDOSTERONE_ANTAGONISTS]:
      intl.formatMessage({
        defaultMessage: 'Aldosterone Antagonists',
      }),
    [ReferenceMedicationMedicationClass.BETA_BLOCKERS]: intl.formatMessage({
      defaultMessage: 'Beta Blockers',
    }),
    [ReferenceMedicationMedicationClass.ALPHA_ADRENERGIC_BLOCKERS]:
      intl.formatMessage({
        defaultMessage: 'Alpha Adrenergic Blockers',
      }),
    [ReferenceMedicationMedicationClass.RENIN_INHIBITORS]: intl.formatMessage({
      defaultMessage: 'Renin Inhibitors',
    }),
    [ReferenceMedicationMedicationClass.CENTRAL_ALPHA_2_AGONIST]:
      intl.formatMessage({
        defaultMessage: 'Central Alpha 2 Agonist',
      }),
    [ReferenceMedicationMedicationClass.VASODILATOR]: intl.formatMessage({
      defaultMessage: 'Vasodilator',
    }),
    [ReferenceMedicationMedicationClass.SODIUM_GLUCOSE_CO_TRANSPORTER_2_INHIBITORS]:
      intl.formatMessage({
        defaultMessage: 'SGLT2 Inhibitors',
      }),
    [ReferenceMedicationMedicationClass.GLUCAGON_LIKE_PEPTIDE_1_RECEPTOR_AGONIST]:
      intl.formatMessage({
        defaultMessage: 'GLP-1 Receptor Agonist',
      }),
    [ReferenceMedicationMedicationClass.DIPEPTIDYL_PEPTIDASE_IV_INHIBITORS]:
      intl.formatMessage({
        defaultMessage: 'DPP-4 Inhibitors',
      }),
    [ReferenceMedicationMedicationClass.THIAZOLIDINEDIONES]: intl.formatMessage(
      {
        defaultMessage: 'Thiazolidinediones',
      },
    ),
    [ReferenceMedicationMedicationClass.GLUCOSE_DEPENDENT_INSULINOTROPIC_POLYPEPTIDE]:
      intl.formatMessage({
        defaultMessage: 'GIP',
      }),
    [ReferenceMedicationMedicationClass.SULFONYLUREAS_2ND_GENERATION]:
      intl.formatMessage({
        defaultMessage: 'Sulfonylureas (2nd Gen)',
      }),
    [ReferenceMedicationMedicationClass.BASAL_INSULIN]: intl.formatMessage({
      defaultMessage: 'Basal Insulin',
    }),
    [ReferenceMedicationMedicationClass.PRANDIAL_INSULIN]: intl.formatMessage({
      defaultMessage: 'Prandial Insulin',
    }),
    [ReferenceMedicationMedicationClass.THIAZIDE_DIURETICS]: intl.formatMessage(
      {
        defaultMessage: 'Thiazide Diuretics',
      },
    ),
    [ReferenceMedicationMedicationClass.BIGUANIDES]: intl.formatMessage({
      defaultMessage: 'Biguanides',
    }),
    [ReferenceMedicationMedicationClass.MEGLITINIDES]: intl.formatMessage({
      defaultMessage: 'Meglitinides',
    }),
  };
}

export type RxnormUnitOptions = Exclude<
  keyof typeof RxNormMedicationUnit,
  'MEDICATION_UNIT_UNSPECIFIED'
>;

export function rxNormMedicationUnitLabels(
  intl: IntlShape,
): Record<RxnormUnitOptions, string> {
  return {
    [RxNormMedicationUnit.MG]: intl.formatMessage({
      defaultMessage: 'mg',
    }),
    [RxNormMedicationUnit.MG_ML]: intl.formatMessage({
      defaultMessage: 'mg/ml',
    }),
    [RxNormMedicationUnit.MG_24_HR]: intl.formatMessage({
      defaultMessage: 'mg 24 hr',
    }),
    [RxNormMedicationUnit.G]: intl.formatMessage({
      defaultMessage: 'g',
    }),
    [RxNormMedicationUnit.IU]: intl.formatMessage({
      defaultMessage: 'IU',
    }),
    [RxNormMedicationUnit.MEQ]: intl.formatMessage({
      defaultMessage: 'mEq',
    }),
    [RxNormMedicationUnit.MCG]: intl.formatMessage({
      defaultMessage: 'mcg',
    }),
    [RxNormMedicationUnit.UNIT]: intl.formatMessage({
      defaultMessage: 'unit',
    }),
    [RxNormMedicationUnit.U_ML]: intl.formatMessage({
      defaultMessage: 'U/ml',
    }),
  };
}

export type RxnormDeliveryMechanismOptions = Exclude<
  keyof typeof RxNormDeliveryMechanism,
  'DELIVERY_MECHANISM_UNSPECIFIED'
>;

export function rxNormDeliveryMechanismLabels(
  intl: IntlShape,
): Record<RxnormDeliveryMechanismOptions, string> {
  return {
    [RxNormDeliveryMechanism.TABLET]: intl.formatMessage({
      defaultMessage: 'Tablet(s)',
    }),
    [RxNormDeliveryMechanism.CAPSULE]: intl.formatMessage({
      defaultMessage: 'Capsule(s)',
    }),
    [RxNormDeliveryMechanism.INJECTION]: intl.formatMessage({
      defaultMessage: 'Injection',
    }),
    [RxNormDeliveryMechanism.TOPICAL_OINTMENT]: intl.formatMessage({
      defaultMessage: 'Topical Ointment',
    }),
    [RxNormDeliveryMechanism.TRANSDERMAL_PATCH]: intl.formatMessage({
      defaultMessage: 'Transdermal Patch',
    }),
    [RxNormDeliveryMechanism.PEN_INJECTOR]: intl.formatMessage({
      defaultMessage: 'Pen Injector',
    }),
    [RxNormDeliveryMechanism.SUBCUTANEOUS_PEN_INJECTOR]: intl.formatMessage({
      defaultMessage: 'SQ Pen Injector',
    }),
    [RxNormDeliveryMechanism.SUBCUTANEOUS_SOLUTION]: intl.formatMessage({
      defaultMessage: 'SQ Solution',
    }),
    [RxNormDeliveryMechanism.ORAL_SOLUTION]: intl.formatMessage({
      defaultMessage: 'Oral Solution',
    }),
    [RxNormDeliveryMechanism.INTRAVENOUS_SOLUTION]: intl.formatMessage({
      defaultMessage: 'IV Solution',
    }),
    [RxNormDeliveryMechanism.AUTO_INJECTOR]: intl.formatMessage({
      defaultMessage: 'Auto Injector',
    }),
    [RxNormDeliveryMechanism.CARTRIDGE]: intl.formatMessage({
      defaultMessage: 'Cartridge',
    }),
  };
}

export type RxnormReleaseTypeOptions = Exclude<
  keyof typeof RxNormReleaseType,
  'RELEASE_TYPE_UNSPECIFIED'
>;
export function rxNormReleaseTypeLabels(
  intl: IntlShape,
): Record<RxnormReleaseTypeOptions, string> {
  return {
    [RxNormReleaseType.EXTENDED]: intl.formatMessage({
      defaultMessage: 'ER 24hrs',
    }),
    [RxNormReleaseType.IMMEDIATE]: intl.formatMessage({
      defaultMessage: 'IR',
    }),
    [RxNormReleaseType.EXTENDED_12_HR]: intl.formatMessage({
      defaultMessage: 'ER 12hrs',
    }),
  };
}

export function rxNormReleaseTypeShortLabels(
  intl: IntlShape,
): Record<Exclude<RxnormReleaseTypeOptions, 'IMMEDIATE'>, string> {
  return {
    [RxNormReleaseType.EXTENDED]: intl.formatMessage({
      defaultMessage: '24hr',
    }),
    [RxNormReleaseType.EXTENDED_12_HR]: intl.formatMessage({
      defaultMessage: '12hr',
    }),
  };
}

export type StatusOptions = Exclude<
  keyof typeof MedicationChangeStatus,
  'STATUS_UNSPECIFIED'
>;
export function medicationChangeStatusLabels(
  intl: IntlShape,
): Record<StatusOptions, string> {
  return {
    [MedicationChangeStatus.ACTIVE]: intl.formatMessage({
      defaultMessage: 'Active',
    }),
    [MedicationChangeStatus.INACTIVE]: intl.formatMessage({
      defaultMessage: 'Inactive',
    }),
    [MedicationChangeStatus.NEEDS_REVIEW]: intl.formatMessage({
      defaultMessage: 'Needs Review',
    }),
  };
}

export type ChangeTypeOptions = Exclude<
  keyof typeof MedicationChangeChangeType,
  'CHANGE_TYPE_UNSPECIFIED'
>;
export function medicationChangeTypeLabels(
  intl: IntlShape,
): Record<ChangeTypeOptions, string> {
  return {
    [MedicationChangeChangeType.EMR_CAPTURE]: intl.formatMessage({
      defaultMessage: 'EHR Capture',
    }),
    [MedicationChangeChangeType.REVIEW]: intl.formatMessage({
      defaultMessage: 'Cadence Review',
    }),
    [MedicationChangeChangeType.RECONCILIATION]: intl.formatMessage({
      defaultMessage: 'Reconciliation',
    }),
    [MedicationChangeChangeType.START]: intl.formatMessage({
      defaultMessage: 'Start',
    }),
    [MedicationChangeChangeType.STOP]: intl.formatMessage({
      defaultMessage: 'Stop',
    }),
    [MedicationChangeChangeType.TITRATION]: intl.formatMessage({
      defaultMessage: 'Cadence Titration',
    }),
  };
}

export function doseQuantityFieldLabel(
  intl: IntlShape,
  deliveryMechanism: Maybe<RxNormDeliveryMechanism>,
) {
  switch (deliveryMechanism) {
    case RxNormDeliveryMechanism.INJECTION:
    case RxNormDeliveryMechanism.SUBCUTANEOUS_SOLUTION:
    case RxNormDeliveryMechanism.SUBCUTANEOUS_PEN_INJECTOR:
    case RxNormDeliveryMechanism.INTRAVENOUS_SOLUTION:
    case RxNormDeliveryMechanism.PEN_INJECTOR:
    case RxNormDeliveryMechanism.AUTO_INJECTOR:
    case RxNormDeliveryMechanism.CARTRIDGE:
      return intl.formatMessage({ defaultMessage: 'Inject' });
    case RxNormDeliveryMechanism.TOPICAL_OINTMENT:
    case RxNormDeliveryMechanism.TRANSDERMAL_PATCH:
      return intl.formatMessage({ defaultMessage: 'Apply' });
    case RxNormDeliveryMechanism.TABLET:
    case RxNormDeliveryMechanism.CAPSULE:
    case RxNormDeliveryMechanism.ORAL_SOLUTION:
    default:
      return intl.formatMessage({ defaultMessage: 'Take' });
  }
}

export function doseQuantityPlaceholder(
  intl: IntlShape,
  deliveryMechanism: Maybe<RxNormDeliveryMechanism>,
) {
  switch (deliveryMechanism) {
    case RxNormDeliveryMechanism.ORAL_SOLUTION:
    case RxNormDeliveryMechanism.INJECTION:
    case RxNormDeliveryMechanism.SUBCUTANEOUS_SOLUTION:
    case RxNormDeliveryMechanism.SUBCUTANEOUS_PEN_INJECTOR:
    case RxNormDeliveryMechanism.INTRAVENOUS_SOLUTION:
    case RxNormDeliveryMechanism.PEN_INJECTOR:
    case RxNormDeliveryMechanism.AUTO_INJECTOR:
    case RxNormDeliveryMechanism.CARTRIDGE:
    case RxNormDeliveryMechanism.TOPICAL_OINTMENT:
    case RxNormDeliveryMechanism.TRANSDERMAL_PATCH:
      return intl.formatMessage({ defaultMessage: 'units' });
    case RxNormDeliveryMechanism.TABLET:
      return intl.formatMessage({ defaultMessage: 'tablets' });
    case RxNormDeliveryMechanism.CAPSULE:
      return intl.formatMessage({ defaultMessage: 'capsules' });
    default:
      return intl.formatMessage({ defaultMessage: 'tablets/units' });
  }
}
