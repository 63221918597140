import { parseISO } from 'date-fns';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { useCommunicationTypeMap } from 'pages/patients/patientDetails/ui/shared/i18n/useCommunicationTypeMap';
import {
  getIsActiveEditableTimeTrackingEntry,
  getIsLateEditableTimeTrackingEntry,
  useFormatDistanceMinutes,
} from 'pages/patients/patientDetails/ui/shared/timeTracking.utils';
import { Table } from 'shared/common/Table';
import { useFlags } from 'shared/hooks';
import { Button } from 'shared/tempo/atom/Button';
import type { MonitoringSession } from 'shared/types/monitoringSession.types';

import { useEntryTypeMap } from '../trackingI18n';

type Props = {
  timeEntry: MonitoringSession;
  onEdit: (entryId: string) => void;
  onView: (entryId: string) => void;
};

export const TimeEntryRow = forwardRef(
  (
    { timeEntry, onEdit, onView }: Props,
    ref?: ForwardedRef<HTMLTableRowElement> | null,
  ) => {
    const commTypeMap = useCommunicationTypeMap();
    const entryTypeMap = useEntryTypeMap();

    const formatDistanceMinutes = useFormatDistanceMinutes();
    return (
      <Table.Row hover ref={ref}>
        <Table.DateCell format="MM/dd/yyyy" timezone={timeEntry.timezone}>
          {timeEntry.start_datetime ? parseISO(timeEntry.start_datetime) : null}
        </Table.DateCell>
        <Table.DateCell format="hh:mm aa zzz" timezone={timeEntry.timezone}>
          {timeEntry.start_datetime ? parseISO(timeEntry.start_datetime) : null}
        </Table.DateCell>
        <Table.TextCell>{entryTypeMap[timeEntry.type]}</Table.TextCell>
        <Table.TextCell>
          {formatDistanceMinutes(
            parseISO(timeEntry.start_datetime),
            parseISO(timeEntry.end_datetime),
          )}
        </Table.TextCell>
        <Table.TextCell>
          {commTypeMap[timeEntry.communication_type]}
        </Table.TextCell>
        <Table.TextCell>{timeEntry.care_provider_name}</Table.TextCell>
        <Table.ActionsCell>
          <ActionCell timeEntry={timeEntry} onEdit={onEdit} onView={onView} />
        </Table.ActionsCell>
      </Table.Row>
    );
  },
);

function ActionCell({
  timeEntry,
  onEdit,
  onView,
}: {
  timeEntry: MonitoringSession;
  onEdit: (entryId: string) => void;
  onView: (entryId: string) => void;
}) {
  const { timeTrackingLateEdits } = useFlags();
  if (
    getIsActiveEditableTimeTrackingEntry(timeEntry) ||
    (timeTrackingLateEdits && getIsLateEditableTimeTrackingEntry(timeEntry))
  ) {
    return (
      <Button
        id="update-time-record"
        variant="tertiary"
        onPress={() => onEdit(timeEntry.id)}
      >
        <FormattedMessage defaultMessage="Update" />
      </Button>
    );
  }

  return (
    <Button
      id="view-time-record"
      variant="tertiary"
      onPress={() => onView(timeEntry.id)}
    >
      <FormattedMessage defaultMessage="View" />
    </Button>
  );
}
