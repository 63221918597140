import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal } from 'shared/common/Modal';
import { Button } from 'shared/tempo/atom/Button';

type Props = {
  isLoading?: boolean;
  cancelButtonText?: string;
  confirmButtonText: string | ReactElement;
  denyButtonText?: string | ReactElement;
  dialogTitle: string | ReactElement;
  dialogDescription: string | ReactElement;
  secondaryButtonText?: string | ReactElement;
  onCancel: () => void;
  onConfirm: () => void;
  onDeny?: () => void;
  onSecondaryAction?: () => void;
  isOpen: boolean;
};

export function ConfirmationDialog({
  isLoading,
  cancelButtonText,
  confirmButtonText,
  denyButtonText,
  dialogTitle,
  dialogDescription,
  secondaryButtonText,
  onCancel,
  onConfirm,
  onDeny,
  onSecondaryAction,
  isOpen,
}: Props) {
  const secondaryActionPresent = secondaryButtonText && onSecondaryAction;
  return (
    <Modal open={isOpen} onClose={onCancel} isLoading={isLoading}>
      <Modal.Header title={dialogTitle} />
      <Modal.Body>{dialogDescription}</Modal.Body>
      <Modal.Footer
        tertiary={
          secondaryActionPresent ? (
            <Button
              id="note-form-secondary-action"
              variant="tertiary"
              onPress={onSecondaryAction}
            >
              {secondaryButtonText}
            </Button>
          ) : null
        }
      >
        {denyButtonText && onDeny ? (
          <Button
            id="note-form-confirmation-deny"
            variant="secondary"
            onPress={onDeny}
          >
            {denyButtonText}
          </Button>
        ) : (
          <Button variant="secondary" onPress={onCancel}>
            {cancelButtonText ?? <FormattedMessage defaultMessage="Cancel" />}
          </Button>
        )}
        <Button
          id="note-form-confirmation-close"
          variant="primary"
          onPress={onConfirm}
        >
          {confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
