import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import { useFlags } from 'shared/hooks';

import { showMedReview } from '../NoteEditor/utils/medReviewUtils';
import { useNoteEditorContext } from '../NoteEditorContext';
import type {
  EncounterModuleId,
  EncounterModuleInstance,
  EncounterTypeInputs,
} from '../Notes.types';
import { getEncounterTypeInstance } from '../utils/encounterTypeUtils';
import { convertDraftJsToMarkdown } from '../utils/rtfBodyUtil';
import { InputsPreview } from './InputsPreview';
import { getMarkdownFromNode } from './getMarkdownFromNode';
import { OutputType, getVisitLayoutOutput } from './getVisitLayoutOutput';
import {
  showDiseaseSpecificMeds,
  useGetDiseaseSpecificMedsNoteBody,
} from './hooks/useDSMedsNoteBody';
import { useGetMedReviewNoteBody } from './hooks/useMedReviewNoteBody';

export function useNoteBody(patientId: string) {
  const intl = useIntl();
  const { careModelVersion } = useFlags();
  const { editingNote } = useNoteEditorContext();

  const getMedReviewNoteBody = useGetMedReviewNoteBody(
    patientId,
    editingNote?.note?.id,
  );

  const getDiseaseSpecificMedsNoteBody = useGetDiseaseSpecificMedsNoteBody(
    patientId,
    editingNote?.note?.id,
  );

  return (encounterData: EncounterModuleInstance[]) => {
    const encounterInputs =
      getEncounterTypeInstance(encounterData)?.inputs || {};
    const {
      patient_no_show: patientNoShow,
      has_meds_to_report: hasMedsToReport,
      type_of_encounter: encounterType,
    } = encounterInputs;
    const patientDeclinedMedReview = hasMedsToReport === false;

    const moduleNoteBodies = getVisitLayoutOutput(
      encounterData,
      getInputsNoteBody,
      convertDraftJsToMarkdown,
      intl,
      careModelVersion,
      OutputType.PublishedNoteBody,
      showMedReview(encounterData) && !patientNoShow
        ? getMedReviewOutput(
            intl,
            patientDeclinedMedReview,
            getMedReviewNoteBody(encounterType),
          )
        : null,
      showDiseaseSpecificMeds(encounterData) && !patientNoShow
        ? getDiseaseSpecificMedsNoteBody(encounterType)
        : null,
    );
    return (
      moduleNoteBodies?.filter((value) => value !== null).join('\n\n') ?? ''
    );
  };
}

function getInputsNoteBody<T extends {}>(
  moduleId: EncounterModuleId,
  encounterTypeInstance: Maybe<EncounterModuleInstance<EncounterTypeInputs>>,
  inputs: T,
) {
  return getMarkdownFromNode(
    <InputsPreview
      encounterModuleId={moduleId}
      encounterTypeInstance={encounterTypeInstance}
      inputs={inputs}
    />,
  );
}

function getMedReviewOutput(
  intl: IntlShape,
  patientDeclinedMedReview: boolean,
  medReviewNoteBody: Nullable<string>,
) {
  let medReviewContent = medReviewNoteBody;

  if (patientDeclinedMedReview) {
    medReviewContent = intl.formatMessage({
      defaultMessage: 'Patient declined to review medications',
    });
  } else if (!medReviewNoteBody) {
    medReviewContent = intl.formatMessage({
      defaultMessage: 'No medications to review',
    });
  }

  const title = intl.formatMessage({
    defaultMessage: 'Medication Review',
  });
  const titleMarkdown = `### ${title}\n`;
  return titleMarkdown + medReviewContent;
}
