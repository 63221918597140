import type { IntlShape } from 'react-intl';

import { TimePeriod } from 'shared/utils/time-helpers';

export const getTimePeriodsOptions = (intl: IntlShape) => [
  {
    value: TimePeriod.AnteMeridiem,
    text: intl.formatMessage({
      defaultMessage: 'AM',
      description: 'Abbreviation for Ante Meridiem',
    }),
  },
  {
    value: TimePeriod.PostMeridiem,
    text: intl.formatMessage({
      defaultMessage: 'PM',
      description: 'Abbreviation for Post Meridiem',
    }),
  },
];
