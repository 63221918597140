import maxBy from 'lodash/maxBy';
import omit from 'lodash/omit';

import type { VitalsAlert } from 'shared/types/alert.types';
import type { AllTagType } from 'shared/types/tagsAndThreshold.types';

export enum AlertPriority {
  P0 = 'P0',
  P1 = 'P1',
  P2 = 'P2',
  None = 'None',
}

/**
 * Used to treat priorities from keys as priorities from values
 */
const priorityOverrides = {
  [AlertPriority.P2]: AlertPriority.P1,
} as Record<AlertPriority, AlertPriority | undefined>;

export function getHighestAlertPriority(alert: VitalsAlert): AlertPriority;
export function getHighestAlertPriority(alerts: VitalsAlert[]): AlertPriority;
export function getHighestAlertPriority(
  alertOrAlerts: VitalsAlert | VitalsAlert[],
) {
  const alerts = Array.isArray(alertOrAlerts) ? alertOrAlerts : [alertOrAlerts];

  const alertPrioritiesFromTags = alerts.flatMap((alert) =>
    alert.alert_tags.map((alertTag) => getAlertPriorityFromAlertTag(alertTag)),
  );
  return maxBy(
    [...alertPrioritiesFromTags, AlertPriority.None],
    (alertPriority: AlertPriority) =>
      ({
        [AlertPriority.P0]: 1000,
        [AlertPriority.P1]: 100,
        [AlertPriority.P2]: 10,
        [AlertPriority.None]: 0,
      })[alertPriority],
  );
}

function getAlertPriorityFromAlertTag(alertTag: AllTagType) {
  const alertPriorities = Object.values(
    omit(AlertPriority, AlertPriority.None),
  );
  const alertPriorityFromTag =
    alertPriorities.find((alertPriority) => alertTag.includes(alertPriority)) ??
    AlertPriority.None;
  return priorityOverrides[alertPriorityFromTag] ?? alertPriorityFromTag;
}
