import { datadogRum } from '@datadog/browser-rum';
import type { LDEvaluationDetail } from 'launchdarkly-js-client-sdk';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import type { ComponentType } from 'react';
import ReactDOM from 'react-dom';

import { settings } from 'config';
import { logger } from 'logger';

const DEFAULT_ROOT_NODE_ID = 'root';

export async function renderApp(
  App: ComponentType,
  rootNodeId: string = DEFAULT_ROOT_NODE_ID,
) {
  const root = document.getElementById(rootNodeId);
  if (!root) {
    throw new Error(
      `Failed to mount application. Root node with id "${rootNodeId}" was not found`,
    );
  }

  if (!settings.VITE_LD_CLIENT_ID) {
    logger.error(
      'Environment variable VITE_LD_CLIENT_ID was expected but not found',
    );
  }
  const LDProvider = await asyncWithLDProvider({
    clientSideID: settings.VITE_LD_CLIENT_ID || '',
    options: {
      fetchGoals: false,
      inspectors: [
        {
          type: 'flag-used',
          name: 'dd-inspector',
          method: (key: string, detail: LDEvaluationDetail) => {
            logger.registerMetadata({ ld_flags: { [key]: detail.value } });
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
    },
  });

  ReactDOM.render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('root'),
  );
}
