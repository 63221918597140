import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import { validators } from 'shared/common/Form/validations';

export type DailyLivingFormFields = {
  requiresAssistance: 'true' | 'false';
  requiresAssistanceDescription?: string;
};

export function getDailyLivingFormConfig(
  intl: IntlShape,
  values?: DailyLivingFormFields,
) {
  const { required } = validators(intl);

  return {
    fields: {
      requiresAssistance: {
        defaultValue: values?.requiresAssistance,
        validation: required(yup.string().oneOf(['true', 'false'])),
      },
      requiresAssistanceDescription: {
        defaultValue: values?.requiresAssistanceDescription,
        validation: yup.string().when('requiresAssistance', {
          is: 'true',
          then: required,
        }),
      },
    },
  };
}
