import cx from 'classnames';
import type { ReactElement, ReactNode } from 'react';
import { Children, cloneElement, isValidElement } from 'react';

import { DialogActions } from 'deprecated/mui';
import { Skeleton } from 'shared/tempo/atom/Skeleton';

import { useModalContext } from './ModalContext';
import { footer, loadingSkeleton, primaryFooterItems } from './styles.css';

type Props = {
  children: ReactNode;
  tertiary?: ReactNode;
  className?: string;
};

export function Footer({ children, tertiary, className }: Props) {
  const { disabled, isLoading, isBodyScrollVisible } = useModalContext();

  function disabledProps(
    contextDisabled: boolean,
    child: ReactElement<Record<string, unknown>>,
  ) {
    return {
      disabled: contextDisabled || child.props.disabled,
      isDisabled: contextDisabled || child.props.isDisabled,
    };
  }

  const tertiaryItem = tertiary
    ? Children.map(tertiary, (child) => {
        if (isValidElement<Record<string, unknown>>(child)) {
          return (
            <FooterItemLoadingPlaceholder isLoading={isLoading}>
              {cloneElement(child, disabledProps(disabled, child))}
            </FooterItemLoadingPlaceholder>
          );
        }
        return child;
      })
    : null;

  // Disable actions when modal is disabled
  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement<Record<string, unknown>>(child)) {
      return (
        <FooterItemLoadingPlaceholder isLoading={isLoading}>
          {cloneElement(child, disabledProps(disabled, child))}
        </FooterItemLoadingPlaceholder>
      );
    }
    return child;
  });

  return (
    <DialogActions
      className={cx(className, {
        [footer.default]: !isBodyScrollVisible,
        [footer.scroll]: isBodyScrollVisible,
        [footer.tertiary]: Boolean(tertiaryItem),
      })}
    >
      {tertiaryItem && <div>{tertiaryItem}</div>}
      <div className={primaryFooterItems}>{childrenWithProps}</div>
    </DialogActions>
  );
}

function FooterItemLoadingPlaceholder({
  isLoading,
  children,
}: {
  children: ReactNode;
  isLoading: boolean;
}) {
  return isLoading ? (
    <Skeleton variant="rectangular" height="40px" className={loadingSkeleton}>
      {children}
    </Skeleton>
  ) : (
    <>{children}</>
  );
}
