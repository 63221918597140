import { FormattedMessage, useIntl } from 'react-intl';

import {
  FieldGroup,
  Script,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { Wizard } from 'shared/common/Wizard';
import { usePatientDetails } from 'shared/hooks/queries';
import { useAssignedDevices } from 'shared/hooks/queries/devices.queries';
import { usePatientVitalAvgAndGoals } from 'shared/hooks/queries/vitalsContext.queries';
import { getRpmConditions } from 'shared/patient/programUtils';
import { PatientStatus } from 'shared/types/patient.types';

import { useCNFormContext } from '../../../CNFormContext';
import { VitalAverageLevel } from '../../shared/vitalEducation/types';
import {
  getClinicalGoal,
  getConditionProgram,
  getVitalLevel,
} from '../../shared/vitalEducation/useVitalBasedScript';
import { CYCLE_17_INIT_VISIT_SECTIONS } from '../metadata';
import { ConditionalVitalContent } from './ConditionalVitalContent';

export function VitalProgressIntro() {
  const intl = useIntl();
  const { patientId, program } = useCNFormContext();
  const { level, goal, isLoading } = useVitalLevel();
  const { data: patient } = usePatientDetails(patientId, false);
  const { data: assignedDevices, isLoading: isLoadingDevices } =
    useAssignedDevices(patientId, {});
  const isActivated =
    Boolean(patient?.activated_at) &&
    patient?.status === PatientStatus.Enrolled;

  return (
    <Wizard.Step
      isLoading={isLoading || isLoadingDevices}
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      title={intl.formatMessage({ defaultMessage: 'Vital Progress' })}
    >
      <FieldGroup>
        <ConditionalVitalContent
          level={level}
          program={program}
          goal={goal}
          devices={assignedDevices?.data}
          isActivated={isActivated}
        />
        <Script>
          <FormattedMessage defaultMessage="It will be important to check your readings every day. This should take only a minute or two of your time. Most people like to make this part of their morning routine by taking their readings right after they wake up, before their first cup of coffee or tea. Checking your readings daily allows us to have a clear picture of your health." />
        </Script>
      </FieldGroup>
    </Wizard.Step>
  );
}

function useVitalLevel() {
  const { patientId } = useCNFormContext();
  const { data: patientData, isLoading: isLoadingPatient } = usePatientDetails(
    patientId,
    true,
  );

  const { vitalValuesAndGoals, isLoading: isLoadingVitalData } =
    usePatientVitalAvgAndGoals(patientId);

  const programConditions = getRpmConditions(
    patientData?.programs,
    patientData?.patient?.status,
  );

  const conditionProgram = getConditionProgram(programConditions);

  if (isLoadingPatient || isLoadingVitalData) {
    return { isLoading: true };
  }

  if (!conditionProgram) {
    return { isLoading: false, level: VitalAverageLevel.Unknown };
  }

  const [level] = getVitalLevel(conditionProgram, vitalValuesAndGoals);
  const goal = getClinicalGoal(conditionProgram, vitalValuesAndGoals);

  return { isLoading: false, level, goal };
}
