import { format, isValid, parseISO } from 'date-fns';
import type { HTMLAttributes } from 'react';

import { EhrIcon, getEhrName } from 'shared/common/Ehr';
import { TypeaheadSearch } from 'shared/common/TypeaheadSearch';
import { usePatientStatusMap } from 'shared/patient/patientStatus';
import type { EHR } from 'shared/types/ehr.types';
import type { PatientDetails } from 'shared/types/patient.types';

import {
  ehrLogo,
  resultTableCellEHR,
  resultTableCellTruncate,
} from './patientSearch.css';

type Props = HTMLAttributes<HTMLTableRowElement> & {
  option: PatientDetails;
  onPatientSelected?: (patient: PatientDetails) => void;
};

const PLACEHOLDER = '-';

function EhrCell({ ehr }: { ehr?: EHR }) {
  if (!ehr) {
    return <>{PLACEHOLDER}</>;
  }

  return (
    <>
      <EhrIcon ehr={ehr} className={ehrLogo} />
      {getEhrName(ehr)}
    </>
  );
}

export function PatientResultRow({
  option: patient,
  onPatientSelected,
  ...props
}: Props) {
  const i18nStatuses = usePatientStatusMap(patient.status);
  const dob = parseISO(patient.dob);
  const formattedDob = isValid(dob) ? format(dob, 'MM/dd/yyyy') : null;

  return (
    <TypeaheadSearch.ResultRow
      {...props}
      onSelected={() => onPatientSelected?.(patient)}
    >
      <TypeaheadSearch.ResultCell className={resultTableCellTruncate}>
        {patient.first_name} {patient.last_name}
      </TypeaheadSearch.ResultCell>
      <TypeaheadSearch.ResultCell>
        {formattedDob || PLACEHOLDER}
      </TypeaheadSearch.ResultCell>
      <TypeaheadSearch.ResultCell>
        {patient.mrns?.join(', ') || PLACEHOLDER}
      </TypeaheadSearch.ResultCell>
      <TypeaheadSearch.ResultCell>
        {patient.status ? i18nStatuses[patient.status] : PLACEHOLDER}
      </TypeaheadSearch.ResultCell>
      <>
        <TypeaheadSearch.ResultCell
          className={resultTableCellTruncate}
          title={patient.ehr_information?.hospital_name}
        >
          {patient.ehr_information?.hospital_name || PLACEHOLDER}
        </TypeaheadSearch.ResultCell>
        <TypeaheadSearch.ResultCell className={resultTableCellEHR}>
          <EhrCell ehr={patient.ehr_information?.ehr} />
        </TypeaheadSearch.ResultCell>
      </>
    </TypeaheadSearch.ResultRow>
  );
}
