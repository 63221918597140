import { FormattedMessage, useIntl } from 'react-intl';

import { Link } from 'shared/tempo/atom/Link';
import type { StandaloneCaregiver } from 'shared/types/caregiver.types';
import type { Patient, RelationshipKey } from 'shared/types/patient.types';
import { Relationship } from 'shared/types/patient.types';
import {
  extractPhoneNumberFromContact,
  getPreferredPhoneContact,
  getPrimaryCaregiver,
} from 'shared/utils/contact.utils';
import { formatPhoneNumber } from 'shared/utils/phone';

import {
  preferredContactContainer,
  preferredNumberForCallsLine,
  signature,
} from '../VitalReminders/VitalReminders.css';

export type Props = {
  patient: Patient;
  caregivers: StandaloneCaregiver[];
};

export function PreferredContact({ patient, caregivers }: Props) {
  const intl = useIntl();
  const preferredPhoneContact = getPreferredPhoneContact(patient, caregivers);
  const { phone: preferredNumber } =
    extractPhoneNumberFromContact(preferredPhoneContact) || '';
  const primaryCaregiver = getPrimaryCaregiver(caregivers);

  return (
    <div
      className={preferredContactContainer}
      data-testid="preferred-contact-box"
    >
      <div className={preferredNumberForCallsLine}>
        <FormattedMessage defaultMessage="Preferred number for calls" />
        <div data-testid="contact-phone-number">
          {preferredNumber ? (
            formatPhoneNumber(preferredNumber)
          ) : (
            <Link.Native
              href={`/admin/patient/${patient.id}/profile`}
              target="_blank"
              rel="noreferrer"
            >
              {intl.formatMessage({
                defaultMessage: 'Enter in patient settings',
              })}
            </Link.Native>
          )}
        </div>
      </div>
      {!patient.has_caregiver
        ? Boolean(patient) && (
            <div className={signature} data-testid="patient-signature">
              {patient?.first_name} {patient?.last_name}
            </div>
          )
        : Boolean(primaryCaregiver) && (
            <div
              className={signature}
              data-testid="primary-caregiver-signature"
            >
              {primaryCaregiver?.firstName} {primaryCaregiver?.lastName},{' '}
              {
                Relationship[
                  primaryCaregiver?.patientRelationship as RelationshipKey
                ]
              }
            </div>
          )}
    </div>
  );
}
