import cx from 'classnames';
import noop from 'lodash/noop';
import type { ReactNode } from 'react';
import { useRef, useState } from 'react';
import { Transition } from 'react-transition-group';

import { Collapse as MuiCollapse } from 'deprecated/mui';
import ChevronIcon from 'shared/assets/svgs/chevron.svg?react';
import { flexGrow } from 'shared/jsStyle/flex.css';
import { IconButton } from 'shared/tempo/atom/IconButton';

import {
  chevronButton,
  chevronIcon,
  clickableHeaderRow,
  headerRow,
} from './Accordion.css';

enum ChevronToggleDirection {
  Up,
  Down,
}

export type Props = {
  open?: boolean;
  defaultOpen?: boolean;
  onToggle?: () => void;
  header: ReactNode;
  children?: ReactNode;
  clickableHeader?: boolean;
  className?: string;
  shouldUnmountChildren?: boolean;
  classes?: {
    headerText?: string;
  };
};

export function Accordion({
  open: controlledOpen,
  defaultOpen = false,
  onToggle = noop,
  header,
  children,
  clickableHeader = false,
  className,
  shouldUnmountChildren = true,
  classes = {},
}: Props) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(defaultOpen);
  const open = controlledOpen ?? uncontrolledOpen;

  const onToggleInternal = () => {
    if (controlledOpen === undefined) {
      setUncontrolledOpen((isOpen) => !isOpen);
    }
    onToggle();
  };

  const headerContents = (
    <>
      <div className={cx(flexGrow[1], classes.headerText)}>{header}</div>
      <ChevronToggle
        direction={
          open ? ChevronToggleDirection.Up : ChevronToggleDirection.Down
        }
        onClick={onToggleInternal}
      />
    </>
  );

  return (
    <div className={className}>
      {clickableHeader ? (
        <ClickableHeader onToggle={onToggleInternal}>
          {headerContents}
        </ClickableHeader>
      ) : (
        <div className={headerRow}>{headerContents}</div>
      )}
      <Collapse open={open} shouldUnmountChildren={shouldUnmountChildren}>
        {children}
      </Collapse>
    </div>
  );
}

function ClickableHeader({
  onToggle,
  children,
}: {
  onToggle: () => void;
  children: ReactNode;
}) {
  return (
    <div
      className={cx(clickableHeaderRow, headerRow)}
      onClick={onToggle}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onToggle();
        }
      }}
      tabIndex={-1}
      role="button"
    >
      {children}
    </div>
  );
}

function ChevronToggle({
  direction,
  onClick = noop,
}: {
  direction: ChevronToggleDirection;
  onClick?: () => void;
}) {
  return (
    <IconButton
      variant="tertiary"
      onPress={onClick}
      className={cx({
        [chevronButton.up]: direction === ChevronToggleDirection.Up,
        [chevronButton.down]: direction === ChevronToggleDirection.Down,
      })}
    >
      <ChevronIcon className={chevronIcon} />
    </IconButton>
  );
}

const ANIMATION_DURATION_MS = 200;

function Collapse({
  open,
  children,
  shouldUnmountChildren = true,
}: {
  open: boolean;
  children: ReactNode;
  shouldUnmountChildren?: boolean;
}) {
  const nodeRef = useRef<HTMLDivElement>(null);
  return (
    <MuiCollapse in={open} timeout={ANIMATION_DURATION_MS}>
      <Transition
        mountOnEnter={shouldUnmountChildren}
        unmountOnExit={shouldUnmountChildren}
        in={open}
        timeout={ANIMATION_DURATION_MS}
        nodeRef={nodeRef}
      >
        <div ref={nodeRef}>{children}</div>
      </Transition>
    </MuiCollapse>
  );
}
