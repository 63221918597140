import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import type { RelationshipKey } from 'shared/types/patient.types';

export function useEmergencyContactRelationshipI18n(): Record<
  RelationshipKey,
  string
> {
  const intl = useIntl();
  return getEmergencyContactRelationshipI18n(intl);
}

export function getEmergencyContactRelationshipI18n(
  intl: IntlShape,
): Record<RelationshipKey, string> {
  return {
    ASSISTED_LIVING: intl.formatMessage({
      defaultMessage: 'Assisted Living',
    }),
    AUNT: intl.formatMessage({ defaultMessage: 'Aunt' }),
    BROTHER: intl.formatMessage({ defaultMessage: 'Brother' }),
    BROTHER_IN_LAW: intl.formatMessage({
      defaultMessage: 'Brother In Law',
    }),
    COUSIN: intl.formatMessage({ defaultMessage: 'Cousin' }),
    DAUGHTER: intl.formatMessage({ defaultMessage: 'Daughter' }),
    FATHER: intl.formatMessage({ defaultMessage: 'Father' }),
    FATHER_IN_LAW: intl.formatMessage({
      defaultMessage: 'Father In Law',
    }),
    FORMER_SPOUSE: intl.formatMessage({
      defaultMessage: 'Former Spouse',
    }),
    FRIEND: intl.formatMessage({ defaultMessage: 'Friend' }),
    GRANDFATHER: intl.formatMessage({
      defaultMessage: 'Grandfather',
    }),
    GRANDMOTHER: intl.formatMessage({
      defaultMessage: 'Grandmother',
    }),
    GREAT_GRANDPARENT: intl.formatMessage({
      defaultMessage: 'Great Grandparent',
    }),
    LEGALLY_SEPARATED_SPOUSE: intl.formatMessage({
      defaultMessage: 'Legally Separated Spouse',
    }),
    LEGAL_GUARDIAN: intl.formatMessage({
      defaultMessage: 'Legal Guardian',
    }),
    MENTAL_HEALTH_CONTACT: intl.formatMessage({
      defaultMessage: 'Mental Health Contact',
    }),
    MOTHER: intl.formatMessage({ defaultMessage: 'Mother' }),
    MOTHER_IN_LAW: intl.formatMessage({
      defaultMessage: 'Mother In Law',
    }),
    NEPHEW: intl.formatMessage({ defaultMessage: 'Nephew' }),
    NIECE: intl.formatMessage({ defaultMessage: 'Niece' }),
    OTHER: intl.formatMessage({ defaultMessage: 'Other' }),
    PARTNER: intl.formatMessage({ defaultMessage: 'Partner' }),
    SISTER: intl.formatMessage({ defaultMessage: 'Sister' }),
    SISTER_IN_LAW: intl.formatMessage({
      defaultMessage: 'Sister In Law',
    }),
    SON: intl.formatMessage({ defaultMessage: 'Son' }),
    SPOUSE: intl.formatMessage({ defaultMessage: 'Spouse' }),
    STEPBROTHER: intl.formatMessage({
      defaultMessage: 'Stepbrother',
    }),
    STEPDAUGHTER: intl.formatMessage({
      defaultMessage: 'Stepdaughter',
    }),
    STEPFATHER: intl.formatMessage({
      defaultMessage: 'Stepfather',
    }),
    STEPMOTHER: intl.formatMessage({
      defaultMessage: 'Stepmother',
    }),
    STEPSISTER: intl.formatMessage({
      defaultMessage: 'Stepsister',
    }),
    STEPSON: intl.formatMessage({ defaultMessage: 'Stepson' }),
    UNCLE: intl.formatMessage({ defaultMessage: 'Uncle' }),
  };
}
