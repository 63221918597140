import { SkeletonTable } from 'shared/common/SkeletonTable';
import { Snackbar } from 'shared/common/Snackbar';
import { TypeaheadSearch } from 'shared/common/TypeaheadSearch';
import type { PatientSearchConfig } from 'shared/hooks/queries';
import { useDeprecatedPatientSearch } from 'shared/hooks/queries';
import type { PatientDetails } from 'shared/types/patient.types';

import { PatientResultRow } from './PatientResultRow';
import { SearchResultsContainer } from './SearchResultsContainer';

type SearchResultsWrapperProps = {
  onPatientSelected: (patient: PatientDetails) => void;
  searchTerm: string;
  searchConfig?: PatientSearchConfig;
};

export function SearchResultsWrapper({
  onPatientSelected,
  searchTerm,
  searchConfig = {},
}: SearchResultsWrapperProps) {
  const {
    data: patients,
    isFetching,
    isError,
    error,
  } = useDeprecatedPatientSearch(searchTerm, true, searchConfig);

  return (
    <>
      {isError && <Snackbar message={error} variant="error" />}
      <TypeaheadSearch.ResultTable>
        <SearchResultsContainer>
          {!isFetching && patients ? (
            patients.data.map((patient) => (
              <PatientResultRow
                key={patient.id}
                option={patient}
                onPatientSelected={onPatientSelected}
              />
            ))
          ) : (
            <SkeletonTable columns={6} />
          )}
        </SearchResultsContainer>
      </TypeaheadSearch.ResultTable>
    </>
  );
}
