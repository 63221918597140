import type { SvgIconProps } from 'deprecated/mui';
import { SvgIcon } from 'deprecated/mui';

const BackButtonIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props} viewBox="0 0 24 24">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7803 4.21967C17.0466 4.48594 17.0708 4.9026 16.8529 5.19621L16.7803 5.28033L10.311 11.75L16.7803 18.2197C17.0466 18.4859 17.0708 18.9026 16.8529 19.1962L16.7803 19.2803C16.5141 19.5466 16.0974 19.5708 15.8038 19.3529L15.7197 19.2803L8.71967 12.2803C8.4534 12.0141 8.4292 11.5974 8.64705 11.3038L8.71967 11.2197L15.7197 4.21967C16.0126 3.92678 16.4874 3.92678 16.7803 4.21967Z"
        fill="#3B3A45"
      />
    </svg>
  </SvgIcon>
);

export default BackButtonIcon;
