import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from 'shared/common/Wizard/state';

import { CHF_VISIT_SECTIONS } from '../../metadata';
import type { IntroFormFields } from '../../shared/IntroPatientAttendance';
import { getIntroductionFormConfig } from '../../shared/IntroPatientAttendance';
import { SharedIntroPatientAttendance } from '../../shared/IntroPatientAttendance/SharedIntroPatientAttendance';
import { chfVisitPath } from '../paths';

export function IntroPatientAttendance() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<IntroFormFields>(
    chfVisitPath('/intro', '/patient-attendance'),
    getIntroductionFormConfig(intl),
  );

  return (
    <SharedIntroPatientAttendance
      form={form}
      sections={CHF_VISIT_SECTIONS}
      nextPath={chfVisitPath('/intro', '/patient-identification')}
      timeTrackingPath={chfVisitPath('/time-tracking', '/index')}
    />
  );
}
