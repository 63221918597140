import cx from 'classnames';

import {
  type DatePickerProps,
  DatePicker as MUIDatePicker,
  TextField,
} from 'deprecated/mui';
import CalendarIcon from 'shared/assets/svgs/calendar.svg?react';
import {
  addInputClassname,
  addInputWrapperClassname,
} from 'shared/common/Input';
import { color } from 'shared/tempo/theme';

import { adornment, button, pickerInput } from './DatePicker.css';

type Props = {
  classes?: { input?: string };
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isError?: boolean;
  onBlur?: () => void;
} & Omit<DatePickerProps, 'disabled' | 'renderInput'>;

export function DatePicker({
  isDisabled,
  isReadOnly,
  isError,
  onBlur,
  classes,
  ...props
}: Props) {
  return (
    <MUIDatePicker
      {...props}
      components={{
        OpenPickerIcon: ({ ...iconProps }) => (
          <CalendarIcon
            {...iconProps}
            fill={color.Theme.Light['Base Font']}
            width={18}
            height={18}
          />
        ),
      }}
      InputAdornmentProps={{ className: adornment }}
      OpenPickerButtonProps={{
        disableRipple: true,
        className: button,
      }}
      renderInput={({ inputProps, ...params }) => (
        <TextField
          {...params}
          error={isError}
          onBlur={onBlur}
          label={props.label}
          className={cx(
            addInputWrapperClassname(isDisabled, isReadOnly, isError),
            classes?.input,
          )}
          inputProps={addInputClassname({
            ...inputProps,
            className: cx(inputProps?.className, pickerInput),
          })}
        />
      )}
      disabled={isDisabled}
    />
  );
}
