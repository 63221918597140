import cx from 'classnames';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ErrorImage from 'shared/assets/svgs/errorScene.svg?react';
import { SupportModal } from 'shared/common/Sidebar/SidebarMenu/SupportModal';

import { container, fullSizeContainer, header } from './ErrorScene.css';

interface Props {
  fullscreen?: boolean;
  children?: ReactNode | ((openReport: () => void) => ReactNode);
}

export function ErrorScene({ children, fullscreen }: Props) {
  const [issueModalOpen, setIssueModalOpen] = useState(false);
  return (
    <>
      <div
        className={cx({
          [fullSizeContainer.default]: !fullscreen,
          [fullSizeContainer.fullscreen]: fullscreen,
        })}
      >
        <div className={container}>
          <ErrorImage />
          <h1 className={header}>
            <FormattedMessage defaultMessage="Something went wrong" />
          </h1>
          {typeof children === 'function'
            ? children?.(() => setIssueModalOpen(true))
            : children}
        </div>
      </div>
      {typeof children === 'function' && (
        <SupportModal
          open={issueModalOpen}
          onClose={() => setIssueModalOpen(false)}
        />
      )}
    </>
  );
}
