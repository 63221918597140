import { useIntl } from 'react-intl';
import * as yup from 'yup';

import type { FormConfig } from 'shared/common/Form';
import { validators } from 'shared/common/Form/validations';

export type FormFields = {
  patientId: string;
  description: string;
  page: string;
};

export function useFormConfig(): FormConfig {
  const intl = useIntl();
  const { required } = validators(intl);

  return {
    fields: {
      description: {
        validation: required(yup.string()),
      },
      page: {
        defaultValue: window.location.href,
      },
      patientId: {
        validation: yup
          .string()
          // Mask adds dashes, treat empty dashes as empty string
          .transform((value) => (value === '----' ? '' : value))
          .matches(
            /^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$/,
            {
              excludeEmptyString: true,
              message: intl.formatMessage({
                defaultMessage: 'Invalid Patient ID Format',
              }),
            },
          ),
      },
    },
  };
}
