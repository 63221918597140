import { useObjectRef } from '@react-aria/utils';
import cx from 'classnames';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import type { AriaToggleButtonProps } from 'react-aria';
import { VisuallyHidden, useFocusRing, useSwitch } from 'react-aria';
import { useToggleState } from 'react-stately';

import type { EnforcedA11yLabel } from 'shared/tempo/shared/types';

import {
  container,
  focusRing,
  label,
  svg as svgClass,
  toggleSwitch,
} from './Toggle.css';

type Props = {
  children?: ReactNode;
  isDisabled?: boolean;
} & AriaToggleButtonProps &
  EnforcedA11yLabel;

const SHARED_CONTAINER_ATTRS = {
  x: 5,
  y: 5,
  rx: 16,
};

export const Toggle = forwardRef<HTMLInputElement, Props>(
  ({ children, ...props }, forwardedRef) => {
    const ref = useObjectRef(forwardedRef);
    const state = useToggleState(props);
    const { inputProps } = useSwitch(props, state, ref);
    const { isFocusVisible, focusProps } = useFocusRing();
    const { isSelected } = state;
    const { isDisabled } = props;

    return (
      <label
        className={cx({
          [label.default]: !isDisabled,
          [label.disabled]: isDisabled,
        })}
      >
        <VisuallyHidden>
          <input {...inputProps} {...focusProps} ref={ref} />
        </VisuallyHidden>
        <svg width={80} height={42} aria-hidden="true" className={svgClass}>
          <rect
            id="focus-ring"
            {...SHARED_CONTAINER_ATTRS}
            aria-disabled={isDisabled}
            className={cx({ [focusRing]: isFocusVisible })}
          />
          <rect
            id="container"
            {...SHARED_CONTAINER_ATTRS}
            aria-disabled={isDisabled}
            className={cx(container[isSelected ? 'on' : 'off'], {
              [container.focused]: isFocusVisible,
            })}
          />
          <circle
            id="switch"
            cy={21}
            r={12}
            className={cx(toggleSwitch[isSelected ? 'on' : 'off'])}
          />
        </svg>
        {children}
      </label>
    );
  },
);
