import cx from 'classnames';
import type { ReactElement, ReactText } from 'react';

import type { FormControlLabelProps } from 'deprecated/mui';
import { FormControlLabel } from 'deprecated/mui';
import { RequiredIndicator } from 'shared/tempo/atom/Label';
import { Toggle as TempoToggle } from 'shared/tempo/atom/Toggle';

import type { BaseFieldProps } from '../BaseField';
import { BaseField } from '../BaseField';
import { labelText } from '../BaseField/styles.css';

type Props = {
  disabled?: boolean;
  label: ReactElement | ReactText;
  labelClassName?: string;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  sectionLabel?: ReactElement | ReactText;
} & BaseFieldProps;

export function Toggle({
  disabled,
  label,
  labelClassName,
  labelPlacement,
  sectionLabel,
  required,
  ...baseProps
}: Props) {
  return (
    <BaseField {...baseProps} label={sectionLabel || ''}>
      {({ controller: { field } }) => (
        <FormControlLabel
          label={
            <>
              {label} {required && <RequiredIndicator />}
            </>
          }
          labelPlacement={labelPlacement}
          className={cx(labelClassName, labelText.toggle)}
          classes={{ label: labelText.toggle }}
          disabled={disabled}
          control={
            <TempoToggle
              aria-label={field.name}
              isDisabled={disabled}
              isSelected={field.value}
              onChange={field.onChange}
            />
          }
        />
      )}
    </BaseField>
  );
}
