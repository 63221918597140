import { TableBody as Body } from 'deprecated/mui';

import { Container } from './Container';
import { Header } from './Header';
import { HeaderCell } from './HeaderCell';
import { Row } from './Row';
import { Table as TableComponent } from './Table';
import { ActionsCell } from './cells/ActionsCell';
import { BaseCell as NodeCell } from './cells/BaseCell';
import { BooleanCell } from './cells/BooleanCell';
import { CheckboxCell } from './cells/CheckboxCell';
import { DateCell } from './cells/DateCell';
import { NumberCell } from './cells/NumberCell';
import { TextCell } from './cells/TextCell';

// Export types
export type { HeaderColumn } from './Header';
export type { SortState } from './sort/useTableSort';

// Export hooks
export { useTableSort } from './sort/useTableSort';

// Export Table with subcomponents (i.e. Table.Container, Table.Header, Table.Body, etc...)
export const Table = Object.assign(TableComponent, {
  Container,
  Header,
  Body,
  Row,
  // Cells
  ActionsCell,
  BooleanCell,
  CheckboxCell,
  DateCell,
  TextCell,
  NumberCell,
  // NOTE: Please attempt to use more specific cells before reaching for this one
  NodeCell,
  HeaderCell,
});
