import type {
  AccommodationKey,
  Patient,
  ResidenceKey,
} from 'shared/types/patient.types';
import { PatientStatus } from 'shared/types/patient.types';

import { Mode as AdminPatientProfileMode } from '../../../shared/utils/profileUtils';
import type {
  CreatePatientFormFields,
  EditPatientFormFields,
} from './formConfig';
import {
  parseCreatePatientPayload,
  parseUpdatePatientPayload,
} from './formValueParser';
import type {
  CreatePatientPayload,
  UpdatePatientPayload,
} from './patientForm.queries';

const OTHER = 'OTHER';

export function isOther(value: Maybe<string | string[]>) {
  if (Array.isArray(value)) {
    return value.includes(OTHER);
  }

  return value === OTHER;
}

export function isMobileNumberRequired(hasCaregiver: boolean) {
  return !hasCaregiver;
}

export function isAccommodationsOtherRequired(
  accommodations: AccommodationKey[],
) {
  return isOther(accommodations);
}

export function isResidenceOtherRequired(residence: ResidenceKey) {
  return isOther(residence);
}

export function isContactInformationRequired(patient?: Patient) {
  const statusesWithRequiredContactInformation = [
    PatientStatus.ReadyToEnroll,
    PatientStatus.Enrolled,
    PatientStatus.Disenrolled,
  ];

  return (
    patient?.status &&
    statusesWithRequiredContactInformation.includes(patient.status)
  );
}

type PatientPayload =
  | {
      mode: AdminPatientProfileMode.Create;
      payload: CreatePatientPayload;
    }
  | {
      mode: AdminPatientProfileMode.Update;
      payload: UpdatePatientPayload;
    };

export const getPayloadFromValues = (
  values: CreatePatientFormFields | EditPatientFormFields,
  isEdit: boolean,
  hasNewEnrollmentUiUpdates: boolean,
): PatientPayload =>
  isEdit
    ? {
        mode: AdminPatientProfileMode.Update,
        payload: parseUpdatePatientPayload(
          values as EditPatientFormFields,
          hasNewEnrollmentUiUpdates,
        ),
      }
    : {
        mode: AdminPatientProfileMode.Create,
        payload: parseCreatePatientPayload(values),
      };
