import cx from 'classnames';
import { useIntl } from 'react-intl';

import {
  Labs as LabsTab,
  PatientInfo as PatientInfoTab,
  TimeTracking as TimeTrackingTab,
  Vitals as VitalsTab,
  fullTabPanel,
  tabBody,
  tabPanel,
} from 'pages/patients/patientDetails/ui/tabs';
import { CarePlanTab } from 'pages/patients/patientDetails/ui/tabs/CarePlan';
import { RoutableTabs } from 'shared/common/RoutableTabs';
import { useFlags } from 'shared/hooks';
import { useWasPatientEnrolled } from 'shared/hooks/queries';
import { fullHeight } from 'shared/jsStyle/utils.css';
import { useIsCcmParticipating } from 'shared/patient/ccm.utils';
import { useCurrentRoutedTab } from 'shared/tempo/@labs/atom/Tabs';
import type { Patient } from 'shared/types/patient.types';

import {
  MedPermissions,
  PatientMedicationsList,
} from '../../PatientMedications/PatientMedicationsList';
import { useNoteEditorContext } from '../../patientDetails/ui/Notes/NoteEditorContext';
import { Messages as MessagesTab } from '../../patientDetails/ui/tabs/Messages/Messages';
import { usePatientDetailsCtx } from '../PatientDetailContext';
import '../PatientProfile.scss';
import { smsPanel, staticTabList } from './PatientProfileContent.css';
import { MESSAGES_TAB_CONTENT_CONTAINER_ID } from './constants';
import { Tabs } from './types';

export function PatientProfileContent() {
  const flags = useFlags();
  const intl = useIntl();
  const { editingNote } = useNoteEditorContext();
  const { patientDetails, setPatientDetails } = usePatientDetailsCtx();
  const wasPatientEnrolled = useWasPatientEnrolled(patientDetails.id);
  const { isCcmParticipating } = useIsCcmParticipating(patientDetails.id);

  const currentTab = useCurrentRoutedTab(Object.values(Tabs), Tabs.Vitals);

  return (
    <RoutableTabs
      tabListClassname={cx({ [staticTabList]: currentTab === Tabs.Messages })}
      defaultLink={Tabs.Vitals}
      tabs={[
        {
          link: Tabs.Vitals,
          label: intl.formatMessage({
            defaultMessage: 'Vitals',
          }),
          panel: (
            <div className={tabPanel}>
              <VitalsTab />
            </div>
          ),
        },
        {
          link: Tabs.Meds,
          label: intl.formatMessage({ defaultMessage: 'Meds' }),
          panel: (
            <div className={tabPanel}>
              <PatientMedicationsList
                noteId={editingNote?.note?.id}
                patientId={patientDetails.id}
                medPermissions={MedPermissions.View}
                showRequiredActions={false}
              />
            </div>
          ),
        },
        {
          link: Tabs.Labs,
          label: intl.formatMessage({
            defaultMessage: 'Lab Results',
          }),
          panel: (
            <div className={tabPanel}>
              <div className={tabBody}>
                <LabsTab patientId={patientDetails.id} />
              </div>
            </div>
          ),
        },
        {
          link: Tabs.Messages,
          label: intl.formatMessage({
            defaultMessage: 'SMS + Calls',
          }),
          panel: (
            <div
              className={cx(fullTabPanel, smsPanel)}
              id={MESSAGES_TAB_CONTENT_CONTAINER_ID}
            >
              <MessagesTab patient={patientDetails as Patient} />
            </div>
          ),
        },
        ...(wasPatientEnrolled
          ? [
              {
                link: Tabs.TimeTracking,
                label: intl.formatMessage({
                  defaultMessage: 'Time Tracking',
                }),
                panel: (
                  <div className={cx(tabPanel, fullHeight)}>
                    <div className={tabBody}>
                      <TimeTrackingTab />
                    </div>
                  </div>
                ),
              },
              {
                link: Tabs.PatientInfo,
                label: intl.formatMessage({
                  defaultMessage: 'Patient Info',
                }),
                panel: (
                  <div className={tabPanel}>
                    <PatientInfoTab
                      patient={patientDetails}
                      updatePatient={setPatientDetails}
                    />
                  </div>
                ),
              },
            ]
          : []),
        ...(flags.ccmCarePlanCreation && isCcmParticipating
          ? [
              {
                link: Tabs.CarePlan,
                label: intl.formatMessage({ defaultMessage: 'Care Plan' }),
                panel: (
                  <div className={fullTabPanel}>
                    <CarePlanTab />
                  </div>
                ),
              },
            ]
          : []),
      ]}
    />
  );
}
