import omit from 'lodash/omit';
import pick from 'lodash/pick';
import type { QueryFunctionContext } from 'react-query/types/core/types';

import { getQueryParamsFromQueryKey } from 'reactQuery';

import type { QueryParamsFilterLookup } from './grpc-filter-builder';
import { GrpcFilterBuilder } from './grpc-filter-builder';
import type { QueryParamsOrderByLookup } from './grpc-orderby-builder';
import { GrpcOrderbyBuilder } from './grpc-orderby-builder';

type GrpcGetRequest = {
  name?: string;
};

type GrpcListRequest = {
  pageSize?: number;
  pageToken?: string;
};

type GrpcFilterableRequest = {
  filter?: string;
  parent?: string;
};

type GrpcOrderableRequest = {
  orderBy?: string;
};

type GrpcFilterableAndOrderableRequest = GrpcFilterableRequest &
  GrpcOrderableRequest;

export type GrpcGenericRequest = GrpcGetRequest &
  GrpcListRequest &
  GrpcFilterableAndOrderableRequest;

export const buildPatientsRPCRequest = (
  ctx: QueryFunctionContext,
  qpFilterLookup: QueryParamsFilterLookup,
  qpOrderByLookup: QueryParamsOrderByLookup,
): GrpcGenericRequest => {
  const queryParams = getQueryParamsFromQueryKey(ctx.queryKey);

  let filter = '';
  let orderBy = '';

  if (queryParams) {
    filter = new GrpcFilterBuilder().fromQueryParams(
      omit(queryParams, ['sort_by', 'order_by']),
      qpFilterLookup,
    ).filter;

    orderBy = new GrpcOrderbyBuilder().fromQueryParams(
      pick(queryParams, ['sort_by', 'order_by']),
      qpOrderByLookup,
    ).orderBy;
  }

  return {
    pageToken: ctx.pageParam,
    filter,
    orderBy,
  };
};
