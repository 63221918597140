import { formatISO, parseISO } from 'date-fns';
import difference from 'lodash/difference';
import sortBy from 'lodash/sortBy';

import type {
  DiagnosisCode,
  Patient,
  PatientConditionsPerProgram,
  Program as PbProgram,
  ProgramCondition as PbProgramCondition,
  ProgramConditionHistory as PbProgramConditionHistory,
} from 'shared/generated/grpcGateway/pms.pb';
import {
  ConditionConfidence,
  Condition as PbCondition,
  StatusNoteReason,
} from 'shared/generated/grpcGateway/pms.pb';
import { Condition } from 'shared/types/clinicalprofile.types';
import type {
  Program,
  ProgramCondition,
  ProgramConditionHistory,
} from 'shared/types/patient.types';
import { AcuityTierThreshold } from 'shared/types/patient.types';
import { ALL_PRIMARY_CODES } from 'shared/utils/diagnosis-codes.constants';
import { parseGrpcDate } from 'shared/utils/grpc';

export enum PatientProgram {
  HEART_FAILURE = 'HEART_FAILURE',
  HYPERTENSION = 'HYPERTENSION',
  TYPE_2_DIABETES = 'TYPE_2_DIABETES',
}

export const pbConditionTypeToConditionTypeMap: Record<string, string> = {
  [PbCondition.AFIB_AND_AFL]: Condition.AfibAndFlutter,
  [PbCondition.ASTHMA]: Condition.Asthma,
  [PbCondition.CHRONIC_KIDNEY_DISEASE]: Condition.ChronicKidneyDisease,
  [PbCondition.CONDITION_UNSPECIFIED]: Condition.Unspecified,
  [PbCondition.COPD]: Condition.COPD,
  [PbCondition.HEART_FAILURE]: Condition.CHF,
  [PbCondition.GENERIC]: Condition.Generic,
  [PbCondition.HYPERTENSION]: Condition.Hypertension,
  [PbCondition.HYPERLIPIDEMIA]: Condition.Hyperlipidemia,
  [PbCondition.HYPOTHYROIDISM]: Condition.Hypothyroidism,
  [PbCondition.ISCHEMIC_HEART_DISEASE]: Condition.IschemicHeartDisease,
  [PbCondition.MORBID_OBESITY]: Condition.MorbidObesity,
  [PbCondition.OBSTRUCTIVE_SLEEP_APNEA]: Condition.ObstructiveSleepApnea,
  [PbCondition.OSTEOARTHRITIS]: Condition.Osteoarthritis,
  [PbCondition.PERIPHERAL_ARTERY_DISEASE]: Condition.PeripheralArteryDisease,
  [PbCondition.TYPE_2_DIABETES]: Condition.TypeTwoDiabetes,
};

export const programMap: Record<PatientProgram, string> = {
  [PatientProgram.HEART_FAILURE]: 'CHF',
  [PatientProgram.HYPERTENSION]: 'Hypertension',
  [PatientProgram.TYPE_2_DIABETES]: 'Type 2 Diabetes',
};

type PatientProgramWithCombo =
  | PatientProgram
  | 'TYPE_2_DIABETES_WITH_HYPERTENSION';

export function conditionsToProgramsFilter(
  conditions: PatientProgramWithCombo[],
): string {
  const ALL_PROGRAMS = ['CHF', 'Hypertension', 'Type 2 Diabetes'];

  const patientPrograms = conditions.reduce(
    (programs: string[], program: PatientProgramWithCombo) => {
      if (program === 'TYPE_2_DIABETES_WITH_HYPERTENSION') {
        return programs;
      }
      if (program in programMap) {
        programs.push(programMap[program]);
      }
      return programs;
    },
    [],
  );

  const combo = conditions.includes('TYPE_2_DIABETES_WITH_HYPERTENSION');

  const excludedPrograms = difference(ALL_PROGRAMS, patientPrograms);
  const excludedProgramsString = excludedPrograms
    .map((program) => `NOT programs: "${program}"`)
    .join(' AND ');

  const orProgramsString = patientPrograms
    .map((program) => `programs: "${program}"`)
    .join(' OR ');

  const comboProgramString = `programs: "Hypertension" AND programs: "Type 2 Diabetes"`;

  const isPrograms = patientPrograms.length > 0;
  const isExclusions = excludedProgramsString.length > 0;

  // All RPM programs selected: CHF, HT2, T2D and Combo(HT2+T2D)
  if (combo && !isExclusions) {
    return '';
  }
  // Only RPM programs selected, without Combo(HT2+T2D)
  if (!combo && isPrograms) {
    return `(${orProgramsString}) ${
      isExclusions ? `AND ${excludedProgramsString}` : ''
    } AND NOT (${comboProgramString})`;
  }
  // Only Combo(HT2+T2D) selected
  if (combo && !isPrograms) {
    return `(${comboProgramString})`;
  }
  // Combo(HT2+T2D) and other RPM programs selected, but not all RPM programs
  if (combo && isPrograms) {
    return `(${orProgramsString} OR (${comboProgramString})) ${
      excludedProgramsString.includes('CHF') ? `AND NOT programs: "CHF"` : ''
    }`;
  }

  return '';
}

export function acuityTierToPatientTierFilter(
  tierThreshold: AcuityTierThreshold,
): string {
  const tiers = [
    AcuityTierThreshold.Tier1,
    AcuityTierThreshold.Tier2,
    AcuityTierThreshold.Tier3,
  ];

  if (tiers.includes(tierThreshold)) {
    // Include all tiers up to and including the selected tier
    const filteredTiers = tiers.slice(0, tiers.indexOf(tierThreshold) + 1);
    const formattedQuery = `(patientTier = "${filteredTiers.join(
      '" OR patientTier = "',
    )}")`;
    return formattedQuery;
  }
  return '';
}

export function conditionConfidenceToHighConfidenceFilter(
  conditionConfidence: string,
): string {
  let filter = '';
  if (conditionConfidence === 'HIGH_ONLY') {
    filter = `hasHighConfidenceProgram`;
  }
  return filter;
}

type PbPatient = Patient;

export function convertPbProgramConditionToProgramCondition(
  programCondition: Maybe<PbProgramCondition>,
): Nullable<ProgramCondition> {
  if (
    !programCondition ||
    !programCondition.name ||
    !programCondition.category ||
    !programCondition.conditionType ||
    !programCondition.description
  ) {
    return null;
  }
  return {
    category: programCondition.category,
    etiology: programCondition.etiology || null,
    description: programCondition.description,
    condition_type: programCondition.conditionType,
    id: parseInt(programCondition.name, 10),
    version_hash: null,
    condition_confidence:
      !programCondition.conditionConfidence ||
      programCondition.conditionConfidence ===
        ConditionConfidence.CONDITION_CONFIDENCE_UNSPECIFIED
        ? null
        : programCondition.conditionConfidence,
  };
}

function convertPbProgramConditionHistoryToProgramConditionHistory(
  programConditionHistory: Maybe<PbProgramConditionHistory>,
): Nullable<ProgramConditionHistory> {
  if (
    !programConditionHistory ||
    !programConditionHistory.name ||
    !programConditionHistory.category ||
    !programConditionHistory.conditionType ||
    !programConditionHistory.description ||
    !programConditionHistory.operation ||
    !programConditionHistory.operationTime
  ) {
    return null;
  }

  return {
    category: programConditionHistory.category,
    etiology: programConditionHistory.etiology || null,
    description: programConditionHistory.description,
    condition_type: programConditionHistory.conditionType,
    id: parseInt(programConditionHistory.name, 10),
    version_hash: null,
    condition_confidence:
      !programConditionHistory.conditionConfidence ||
      programConditionHistory.conditionConfidence ===
        ConditionConfidence.CONDITION_CONFIDENCE_UNSPECIFIED
        ? null
        : programConditionHistory.conditionConfidence,
    operation: programConditionHistory.operation,
    operation_time: parseISO(programConditionHistory.operationTime),
  };
}

function convertPbProgramToProgram(
  program: Maybe<PbProgram>,
): Nullable<Program> {
  if (
    !program ||
    !program.name ||
    !program.programStatus ||
    !program.programType ||
    !program.conditions
  ) {
    return null;
  }

  const conditions: ProgramCondition[] = [];
  program.conditions.forEach((cond) => {
    const condition = convertPbProgramConditionToProgramCondition(cond);
    if (condition) {
      conditions.push(condition);
    }
  });

  const conditionHistory: ProgramConditionHistory[] = [];
  if (program.conditionHistory) {
    program.conditionHistory.forEach((pbCondHistory) => {
      const condHistory =
        convertPbProgramConditionHistoryToProgramConditionHistory(
          pbCondHistory,
        );
      if (condHistory) {
        conditionHistory.push(condHistory);
      }
    });
  }

  return {
    id: parseInt(program.name, 10),
    program_status: program.programStatus,
    program_type: program.programType,
    version_hash: null,
    conditions,
    condition_history: conditionHistory,
  };
}

export function convertPtConditionPerProgramToPrograms(
  ptConditionPerProgram: Maybe<PatientConditionsPerProgram>,
): Program[] {
  const programs: Program[] = [];
  if (!ptConditionPerProgram || !ptConditionPerProgram.programs) {
    return [];
  }
  ptConditionPerProgram?.programs.forEach((pbProgram: PbProgram) => {
    const program: Nullable<Program> = convertPbProgramToProgram(pbProgram);
    if (program) {
      programs.push(program);
    }
  });
  return programs;
}

export const convertPbPatientToPatient = (patient: PbPatient) => ({
  ...patient,
  id: patient.name,
  mrns: [patient.mrn],
  program: convertPbProgramToProgram(patient.program),
  status_notes: [
    ...(patient.statusNotes ?? []).map((statusNote) => ({
      rpm_order: null,
      reason_description: statusNote.description,
      created_at: statusNote.createTime,
      status_to: statusNote.statusTo,
      reasons:
        statusNote?.reasons?.map((reason) => {
          if (reason === StatusNoteReason.DOCTOR_RECOMMENDED_DISENROLLMENT) {
            return 'doctor recommended disenrollment (and reason)';
          }
          if (reason === StatusNoteReason.LACK_OF_PARTICIPATION) {
            return 'lack of participation (and reason)';
          }
          if (reason === StatusNoteReason.MOVED_TO_HOSPICE_PALLIATIVE_CARE) {
            return 'moved to hospice/palliative care';
          }
          if (reason === StatusNoteReason.RE_ENROLLED) {
            return 're-enrolled';
          }
          if (reason === StatusNoteReason.CLINIC_NO_SHOW) {
            return 'clinic no-show';
          }
          if (reason === StatusNoteReason.CADENCE_APPOINTMENT_NO_SHOW) {
            return 'cadence appointment no-show';
          }
          if (reason === StatusNoteReason.PREFER_SELF_MANAGE) {
            return 'prefer self-manage';
          }
          return reason.toLowerCase().replaceAll('_', ' ');
        }) ?? [],
      status_change_date: statusNote?.statusChangeDate
        ? formatISO(
            new Date(
              statusNote?.statusChangeDate?.year,
              statusNote?.statusChangeDate?.month - 1,
              statusNote?.statusChangeDate?.day,
            ),
          )
        : null,
    })),
    ...(patient.latestRpmOrderStatusNote
      ? [
          {
            rpm_order: {
              provider_last_name:
                patient.latestRpmOrderStatusNote?.providerLastName,
              provider_first_name:
                patient.latestRpmOrderStatusNote?.providerFirstName,
              // TODO: This is very weird, but it's what the client expects. We apparently don't care about TZ. Consulted with Tomek.
              ehr_created_at:
                patient.latestRpmOrderStatusNote?.ehrCreateTime?.slice(0, -1),
              icd10_codes: {
                codes:
                  patient.latestRpmOrderStatusNote?.icd10Codes?.codes ?? [],
                detailed_codes:
                  patient.latestRpmOrderStatusNote?.icd10Codes?.detailedCodes?.map(
                    (detailedCode) => ({
                      code: detailedCode.code,
                      description: detailedCode.description,
                      type: detailedCode.type,
                    }),
                  ) ?? [],
              },
              ignorable_error_list:
                patient.latestRpmOrderStatusNote?.ignorableErrorList?.map(
                  (error) => ({
                    error: error.error,
                    order_conditions: error.orderConditions,
                    patient_conditions: error.patientConditions,
                  }),
                ),
            },
            created_at: patient.latestRpmOrderStatusNote?.createTime,
            reason_description: patient.latestRpmOrderStatusNote?.description,
            reasons:
              patient.latestRpmOrderStatusNote?.reasons?.map((reason) =>
                reason.toLowerCase().replace('_', ' '),
              ) ?? [],
            status_change_date: patient.latestRpmOrderStatusNote
              ?.statusChangeDate
              ? formatISO(
                  new Date(
                    patient.latestRpmOrderStatusNote?.statusChangeDate?.year,
                    patient.latestRpmOrderStatusNote?.statusChangeDate?.month -
                      1,
                    patient.latestRpmOrderStatusNote?.statusChangeDate?.day,
                  ),
                )
              : null,
            status_to: patient.latestRpmOrderStatusNote?.statusTo,
          },
        ]
      : []),
  ],
  first_name: patient.givenName,
  last_name: patient.familyName,
  is_prepped: patient.isPrepped,
  visited_emergency_department: patient.visitedEmergencyDepartment,
  scheduling_hospital_name: patient.hospital,
  duration: patient.upcomingAppointmentDuration,
  scheduling_care_provider_name: patient.schedulingCareProvider,
  patient_profile: {
    chf_profile: {
      chf_stage: patient.observations?.chfStage,
      echo_s3_url: patient.observations?.echoUri,
      ejection_fraction: {
        lower: patient.observations?.efLower,
        upper: patient.observations?.efUpper,
      },
    },
    t2d_profile: {
      lab_s3_url: patient.observations?.a1cUri,
      t2d_data: {
        a1c: patient.observations?.a1c,
      },
    },
  },
  last_blood_pressure_average: {
    systolic: patient.observations?.lastBloodPressureAverageSystolic,
    diastolic: patient.observations?.lastBloodPressureAverageDiastolic,
  },
  last_blood_pressure_max: {
    systolic: patient.observations?.lastBloodPressureMaxSystolic,
    diastolic: patient.observations?.lastBloodPressureMaxDiastolic,
  },
  last_blood_pressure: {
    systolic: patient.observations?.lastBloodPressureSystolic,
    diastolic: patient.observations?.lastBloodPressureDiastolic,
  },
  primary_insurance_name: patient.primaryInsurance,
  secondary_insurance_name: patient.secondaryInsurance,
  // TODO: This is very weird, but it's what the client expects. We apparently don't care about TZ. Consulted with Tomek and David.
  upcoming_appointment_date: patient.upcomingAppointmentTime?.slice(0, -1),
  identification_type: patient.identificationType,
  last_clinic_appt_department_name: patient.lastClinicApptDepartment,
  last_clinic_appt_date: patient.lastClinicApptTime,
  last_clinic_appt_provider_name: patient.lastClinicApptProvider,
  npi: {
    care_provider: patient.referringProvider
      ? {
          first_name: patient.referringProvider?.split(' ')[0],
          last_name: patient.referringProvider?.split(' ').slice(1).join(' '),
        }
      : null,
  },
  zendesk_id: patient.zendeskId,
  diagnosis_codes: buildDiagnosisCodes(patient.diagnosisCodes),
  medication_name_list:
    sortBy(
      patient.medications?.map(({ name }) => name).filter(Boolean),
      (name: string) => name.toLowerCase(),
    ).join(', ') ?? '',
  dob: patient.dob ? formatISO(parseGrpcDate(patient.dob)) : undefined,
  usual_provider_name: patient.usualProvider,
  has_potential_duplicate_patients: patient.isDuplicate,
  nab_lists: patient?.eplListIds,
  latest_nab_list_generated_at: patient?.latestEplListGenerationTime,
  identified_provider_npi: {
    care_provider: patient.identifiedProvider
      ? {
          first_name: patient.identifiedProvider?.split(' ')[0],
          last_name: patient.identifiedProvider?.split(' ').slice(1).join(' '),
        }
      : null,
  },
  selected_care_provider_confidence: {
    score: patient.selectedCareProviderConfidence,
  },
  epls_pend_statuses: (patient.eligiblePatientListsPendStatuses ?? [])?.map(
    (eplsPendStatuses) => ({
      epl_id: eplsPendStatuses.eplId,
      order_id: eplsPendStatuses.orderId,
      error_description: eplsPendStatuses.errorDescription,
    }),
  ),
  enrollment_score: patient.enrollmentScore,
});

const buildDiagnosisCodes = (diagnosisCodes?: {
  [key: string]: DiagnosisCode;
}) => {
  const getCodeNames = (codes: { [key: string]: DiagnosisCode }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mappedDiagnosisCodes: any = {};
    Object.entries(codes).forEach(([key, value]) => {
      if (ALL_PRIMARY_CODES.includes(key)) {
        mappedDiagnosisCodes[key] = {
          code: value.code,
          code_description: value.codeDescription,
          category: value.category,
          etiology: value.etiology,
        };
      }
    });
    return mappedDiagnosisCodes;
  };

  const fullDiagnosisCodes = {
    code_names: diagnosisCodes ? getCodeNames(diagnosisCodes) : {},
  };

  return fullDiagnosisCodes;
};
