import { FormattedMessage } from 'react-intl';

import { Script } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { Form } from 'shared/common/Form';
import type { ConfiguredForm } from 'shared/common/Form/FormContainer';

import {
  subfieldWrapper,
  symptomCheckboxLabel,
  symptomCheckboxRoot,
} from './SharedSecondarySymptoms.css';
import type { SecondarySymptomsFormFields } from './formConfig';
import type { Symptom } from './symptomList';

type Props = {
  symptom: Symptom;
  label: string;
  form: ConfiguredForm<SecondarySymptomsFormFields>;
};

export function SymptomFields({ symptom, label, form }: Props) {
  const sectionVisibility = useSectionVisibility(symptom, form);

  return (
    <div>
      <Form.Checkbox
        size={12}
        name={`${symptom}-checked`}
        label={label}
        onChange={() => form.trigger('newSecondarySymptoms')}
        classes={{ label: symptomCheckboxLabel, root: symptomCheckboxRoot }}
      />
      {sectionVisibility.subFields && (
        <div className={subfieldWrapper}>
          <Form.RadioGroup
            required
            size={12}
            name={`${symptom}-newSymptom`}
            label={<FormattedMessage defaultMessage="Is this a new symptom?" />}
            orientation="horizontal"
          >
            <Form.Radio value="true">
              <FormattedMessage defaultMessage="Yes" />
            </Form.Radio>
            <Form.Radio value="false">
              <FormattedMessage defaultMessage="No" />
            </Form.Radio>
          </Form.RadioGroup>
          {sectionVisibility.severity && (
            <Form.RadioGroup
              required
              size={12}
              name={`${symptom}-newSeverity`}
              label={
                <FormattedMessage defaultMessage="Is this symptom more intense, frequent, or feeling different than usual?" />
              }
              orientation="horizontal"
            >
              <Form.Radio value="true">
                <FormattedMessage defaultMessage="Yes" />
              </Form.Radio>
              <Form.Radio value="false">
                <FormattedMessage defaultMessage="No" />
              </Form.Radio>
            </Form.RadioGroup>
          )}
        </div>
      )}
      {sectionVisibility.nurseReviewMessage && (
        <Script>
          <FormattedMessage defaultMessage="After we finish up today, I will be reviewing your symptoms with one of the nurses. You may receive another call today to talk more about this symptom. If you don't hear back, you can assume that we will continue to monitor your symptoms for now." />
        </Script>
      )}
      {sectionVisibility.chronicIssueMessage && (
        <Script>
          <FormattedMessage defaultMessage="It sounds like this is a chronic issue you're dealing with. If these symptoms do change at all, or if you're experiencing any new symptoms, you can always call your Cadence clinical team for assistance." />
        </Script>
      )}
    </div>
  );
}

function useSectionVisibility(
  symptom: Symptom,
  form: ConfiguredForm<SecondarySymptomsFormFields>,
) {
  const [checked, newSymptom, newSeverity] = form.watch([
    `${symptom}-checked`,
    `${symptom}-newSymptom`,
    `${symptom}-newSeverity`,
  ]);

  return {
    subFields: checked,
    severity: newSymptom === 'false',
    nurseReviewMessage: newSymptom === 'true' || newSeverity === 'true',
    chronicIssueMessage: newSymptom === 'false' && newSeverity === 'false',
  };
}
