import cx from 'classnames';
import { useRef } from 'react';
import type { AriaListBoxProps } from 'react-aria';
import { useListBox } from 'react-aria';
import type { ListState } from 'react-stately';

import { listReset } from 'shared/tempo/shared/styles/listStyles.css';

import { listBox } from './ListBox.css';
import { Option } from './Option';
import { Section } from './Section';

type Props<T> = {
  className?: string;
  state: ListState<T>;
} & Omit<AriaListBoxProps<T>, 'children' | 'items'>;

export function ControlledListBox<T extends { section?: string }>({
  className,
  state,
  selectionMode,
  ...props
}: Props<T>) {
  const ref = useRef(null);
  const { listBoxProps } = useListBox(props, state, ref);

  return (
    <ul
      {...listBoxProps}
      ref={ref}
      className={cx(listBox, listReset, className)}
    >
      {[...state.collection].map((item) =>
        item.type === 'section' ? (
          <Section
            key={item.key}
            section={item}
            state={state}
            selectionMode={selectionMode}
          />
        ) : (
          <Option
            key={item.key}
            item={item}
            state={state}
            selectionMode={selectionMode}
          />
        ),
      )}
    </ul>
  );
}
