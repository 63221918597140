import cx from 'classnames';
import type { ElementType, ReactNode } from 'react';

import { TableContainer } from 'deprecated/mui';

import { container } from './Container.css';

type Props = {
  children: ReactNode;
  className?: string;
  component?: ElementType;
  stickyHeader?: boolean;
};

export function Container({
  children,
  className,
  component = DefaultContainer,
  ...rest
}: Props) {
  return (
    <TableContainer component={component} className={cx(className)} {...rest}>
      {children}
    </TableContainer>
  );
}

function DefaultContainer({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return <div className={cx(container, className)}>{children}</div>;
}
