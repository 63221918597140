import { format, fromUnixTime } from 'date-fns';
import { useCallback, useMemo } from 'react';
import type { TooltipProps } from 'recharts';
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import type {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import { color } from 'shared/tempo/theme';
import {
  getGraphDaysSpan,
  getXAxisTickValues,
  getYAxisDomain,
} from 'shared/utils/vitals-graph-helpers';

import { HintBody } from './HintBody';
import { graphContainer } from './VitalsScatterplotChart.css';

type Props = {
  startDate: Date;
  endDate: Date;
  graphs: { [graphName: string]: GraphInfo };
};

// used for dynamic min width
const X_LABEL_SIZE = 45;
const Y_COLUMN_WIDTH = 40;

// This was from the original code
const DEFAULT_GRAPH_HEIGHT = 568;
const PLOT_STYLE_MARGIN = {
  top: 10,
  right: 20,
  bottom: 0,
  left: -20,
};

const STROKE_COLOR = color.Palette['Grey Neutral'][300];

export const VitalsScatterplotChart = ({
  startDate,
  endDate,
  graphs,
}: Props) => {
  const graphDaysSpan = useMemo(
    () => getGraphDaysSpan(startDate, endDate),
    [endDate, startDate],
  );

  const xTicks = useMemo(
    () => getXAxisTickValues(graphDaysSpan),
    [graphDaysSpan],
  );
  const formatXTick = useCallback(
    (timestamp) => format(fromUnixTime(timestamp), 'MM/dd'),
    [],
  );

  const yDomain = useMemo(() => getYAxisDomain(graphs), [graphs]);
  const yTicksCount = useMemo(() => {
    const yMax = yDomain[1];
    const count = yMax > 300 ? yMax / 50 : yMax / 20;
    return Math.ceil(count);
  }, [yDomain]);

  return (
    <div className={graphContainer}>
      <ResponsiveContainer
        width="100%"
        // make an attempt at setting a reasonable min width so that the
        // labels have room to breathe but we also don't force scrolling
        // when it's not needed.
        minWidth={xTicks.length * X_LABEL_SIZE + Y_COLUMN_WIDTH}
        height={DEFAULT_GRAPH_HEIGHT}
      >
        <ScatterChart margin={PLOT_STYLE_MARGIN}>
          <CartesianGrid stroke={STROKE_COLOR} />
          <XAxis
            tick={{ fontSize: 11 }}
            tickLine={false}
            domain={[graphDaysSpan[0], graphDaysSpan[graphDaysSpan.length - 1]]}
            dataKey="x"
            orientation="top"
            scale="linear"
            type="number"
            interval={0}
            tickFormatter={(timestamp) => formatXTick(timestamp)}
            ticks={xTicks}
            axisLine={{ stroke: STROKE_COLOR }}
          />
          <YAxis
            tick={{ fontSize: 11 }}
            tickLine={false}
            scale="linear"
            domain={yDomain}
            dataKey="y"
            tickCount={yTicksCount}
            type="number"
            axisLine={{ stroke: STROKE_COLOR }}
          />
          <Tooltip
            trigger="hover"
            content={({ active, payload }: TooltipProps<ValueType, NameType>) =>
              // Want to rename to <HoverToolTip/> instead
              // once we remove the current VitalsGraph
              active && <HintBody payload={payload} />
            }
          />
          {Object.entries(graphs).map(([subType, { data, markStyle }]) => (
            <Scatter
              isAnimationActive={false}
              key={subType}
              data={data}
              style={markStyle}
            />
          ))}
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};
