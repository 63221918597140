import { format, isToday, isYesterday, parseISO } from 'date-fns';

import type { Patient } from 'shared/types/patient.types';
import type {
  PatientSummaryBloodGlucoseReading,
  PatientSummaryBloodPressureReading,
  PatientSummaryHeartRateReading,
  PatientSummaryWeightReading,
} from 'shared/types/patientSummary.types';
import { ReadingDataType } from 'shared/types/patientSummary.types';

type PatientSummary = Patient['patient_summary'];
type PatientSummaryReading = Unpacked<PatientSummary>;

export const getWeightReadings = (patientSummary: PatientSummary) =>
  patientSummary?.find(isWeightReading);

export const getHeartRateReadings = (patientSummary: PatientSummary) =>
  patientSummary?.find(isHrReading);

export const getBpReadings = (patientSummary: PatientSummary) =>
  patientSummary?.find(isBpReading);

export const getBgReadings = (patientSummary: PatientSummary) =>
  patientSummary?.find(isBgReading);

export const getDate = (readingTimestamp: string) => {
  const date = parseISO(readingTimestamp);

  if (isToday(date)) {
    return 'Today';
  }
  if (isYesterday(date)) {
    return 'Yesterday';
  }

  return format(date, 'MM/dd/yyyy');
};

// Type guards

const isBpReading = (
  reading: PatientSummaryReading,
): reading is PatientSummaryBloodPressureReading =>
  reading?.data_type === ReadingDataType.BloodPressure;

const isBgReading = (
  reading: PatientSummaryReading,
): reading is PatientSummaryBloodGlucoseReading =>
  reading?.data_type === ReadingDataType.BloodGlucose;

const isHrReading = (
  reading: PatientSummaryReading,
): reading is PatientSummaryHeartRateReading =>
  reading?.data_type === ReadingDataType.HeartRate;

const isWeightReading = (
  reading: PatientSummaryReading,
): reading is PatientSummaryWeightReading =>
  reading?.data_type === ReadingDataType.Weight;
