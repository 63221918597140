import { NotesFilterValue, TypeOfEncounter } from '../Notes.types';

const CLINICAL_ENCOUNTERS = [
  TypeOfEncounter.INITIAL_NP_VISIT,
  TypeOfEncounter.INITIAL_RN_VISIT_DEPRECATED,
  TypeOfEncounter.INITIAL_CN_VISIT,
  TypeOfEncounter.CN_VISIT,
  TypeOfEncounter.NP_VISIT,
  TypeOfEncounter.INITIAL_CN_CHF_VISIT,
  TypeOfEncounter.CN_CHF_VISIT,
  TypeOfEncounter.REGULAR_RN_VISIT_DEPRECATED,
  TypeOfEncounter.CCM_CARE_PLAN,
  TypeOfEncounter.CCM_VISIT,
  TypeOfEncounter.NP_VISIT_DEPRECATED,
  TypeOfEncounter.RN_VISIT_DEPRECATED,
];

const FilterEncounterTypes = {
  [NotesFilterValue.ClinicalEncounters]: CLINICAL_ENCOUNTERS,
  [NotesFilterValue.ClinicalNotes]: [
    ...CLINICAL_ENCOUNTERS,
    // Follow-ups are clinical notes, but aren't considered clinical encounters
    TypeOfEncounter.RESULTS_FOLLOW_UP,
    TypeOfEncounter.FOURTY_EIGHT_HOUR_FOLLOW_UP_DEPRECATED,
  ],
  [NotesFilterValue.AlertNotes]: [TypeOfEncounter.ALERT_DOCUMENTATION],
  [NotesFilterValue.NonClinicalNotes]: [
    TypeOfEncounter.PATIENT_ENROLLMENT,
    TypeOfEncounter.VIRTUAL_ENROLLMENT,
    TypeOfEncounter.PATIENT_INBOUND,
    TypeOfEncounter.ENROLLER_VISIT,
    TypeOfEncounter.PS_VISIT,
    TypeOfEncounter.WELCOME_CALL,
    TypeOfEncounter.DISENROLLMENT,
  ],
  [NotesFilterValue.InitialVisits]: [
    TypeOfEncounter.INITIAL_CN_VISIT,
    TypeOfEncounter.INITIAL_NP_VISIT,
    TypeOfEncounter.INITIAL_RN_VISIT_DEPRECATED,
    TypeOfEncounter.INITIAL_CN_CHF_VISIT,
  ],
  [NotesFilterValue.CNVisits]: [
    TypeOfEncounter.INITIAL_CN_VISIT,
    TypeOfEncounter.CN_VISIT,
    TypeOfEncounter.INITIAL_CN_CHF_VISIT,
    TypeOfEncounter.CN_CHF_VISIT,
  ],
  [NotesFilterValue.NPVisits]: [
    TypeOfEncounter.INITIAL_NP_VISIT,
    TypeOfEncounter.NP_VISIT,
    TypeOfEncounter.NP_VISIT_DEPRECATED,
  ],
  [NotesFilterValue.RNVisits]: [
    TypeOfEncounter.INITIAL_RN_VISIT_DEPRECATED,
    TypeOfEncounter.REGULAR_RN_VISIT_DEPRECATED,
    TypeOfEncounter.RN_VISIT_DEPRECATED,
  ],
};

export function getNotesFilterQuerystr(
  filterValue: Exclude<NotesFilterValue, NotesFilterValue.AllNotes>,
) {
  if (filterValue === NotesFilterValue.PatientNoShows) {
    return '&is_no_show=true';
  }
  const encounterTypes = FilterEncounterTypes[filterValue];
  return encounterTypes
    .map((encounterType) => `&encounter_type=${encounterType}`)
    .join('');
}
