import type { AriaMenuProps } from '@react-aria/menu';
import { useMenu } from '@react-aria/menu';
import { mergeProps, useObjectRef, useSyncRef } from '@react-aria/utils';
import { useTreeState } from '@react-stately/tree';
import cx from 'classnames';
import type { ForwardedRef } from 'react';
import { forwardRef, useContext } from 'react';

import { listReset } from 'shared/tempo/shared/styles/listStyles.css';

import { MenuItem } from './MenuItem';
import { MenuSection } from './MenuSection';
import { MenuContext, MenuStateContext } from './context';

type Props<T> = { className?: string } & AriaMenuProps<T>;

function Menu<T extends object>(
  { className, ...props }: Props<T>,
  forwardedRef: ForwardedRef<HTMLUListElement>,
) {
  const ref = useObjectRef(forwardedRef);
  const contextProps = useContext(MenuContext);
  const completeProps = mergeProps(contextProps, props);

  const state = useTreeState(completeProps);
  const { menuProps } = useMenu(completeProps, state, ref);

  useSyncRef(contextProps, ref);

  return (
    <MenuStateContext.Provider value={{ state }}>
      <ul {...menuProps} ref={ref} className={cx(listReset, className)}>
        {[...state.collection].map((item) => {
          if (item.type === 'section') {
            return <MenuSection key={item.key} section={item} state={state} />;
          }

          const menuItem = (
            <MenuItem
              key={item.key}
              item={item}
              state={state}
              closeOnSelect={completeProps.closeOnSelect}
              onAction={completeProps.onAction}
            />
          );

          return item.wrapper ? item.wrapper(menuItem) : menuItem;
        })}
      </ul>
    </MenuStateContext.Provider>
  );
}

const ForwardedMenu = forwardRef(Menu);
export { ForwardedMenu as Menu };
