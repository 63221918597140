import { useIntl } from 'react-intl';

import type { CareProvider } from 'shared/tasking/types';

import { formatProviderName } from '../utils';

export function useFormatProviderNameOrMyself() {
  const intl = useIntl();

  return function formatProviderNameOrMyself(
    provider: CareProvider,
    currentUserId: string,
  ) {
    // TODO: standardize on uid or name. this is here temporarily for backwards compat
    // while we figure it out
    if (provider.uid === currentUserId || provider.name === currentUserId) {
      return intl.formatMessage({ defaultMessage: 'Myself' });
    }
    return formatProviderName(provider);
  };
}
