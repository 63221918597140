import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Box } from 'deprecated/mui';
import { LabelledCheckbox } from 'shared/common/LabelledCheckbox';
import { usePatientDetails } from 'shared/hooks/queries';
import { flexSection } from 'shared/jsStyle';
import { color } from 'shared/tempo/theme';
import { EHR } from 'shared/types/ehr.types';
import type { RouteParam } from 'shared/types/route.types';

import { NoteCareProviderSearchBar } from '../NoteCareProviderSearchBar';
import { ErrorMessage } from './ErrorMessage';
import { errorMessage as errorMessageStyle } from './NoteEditor.css';

export const NoteEditorFooter = ({
  actionRequired,
  urgent,
  externalProviderId,
  clearNoteExternalProviderId,
  onActionRequiredChange,
  onUrgentChange,
  setNoteExternalProviderId,
  hasError,
}: {
  actionRequired: boolean;
  urgent: boolean;
  externalProviderId?: string;
  clearNoteExternalProviderId: () => void;
  onActionRequiredChange: (value: boolean) => void;
  onUrgentChange: (value: boolean) => void;
  setNoteExternalProviderId: (id: string) => void;
  hasError: boolean;
}) => {
  const { patientId }: RouteParam = useParams();
  const { data: patientDetails } = usePatientDetails(patientId, false);
  const isEpicPatient = patientDetails?.ehr_information?.ehr === EHR.Epic;
  const providerSearchDisabled = isEpicPatient || !actionRequired;
  const renderProviderSearchMessage = () => {
    const messageColor = externalProviderId ? 'green' : 'red';
    const defaultMessage = externalProviderId ? (
      <FormattedMessage defaultMessage="Provider selection saved" />
    ) : (
      <ErrorMessage hasError={hasError} />
    );
    return (
      <Box sx={{ fontSize: '13px', color: messageColor }}>{defaultMessage}</Box>
    );
  };

  return (
    <Box
      sx={{
        ...flexSection('column', 'center', 'center'),
        width: '100%',
      }}
    >
      <Box
        sx={{
          ...flexSection('row', 'flex-end', 'center'),
          width: '100%',
          ...(hasError && {
            fieldset: { borderColor: color.Theme.Light.Danger },
          }),
        }}
      >
        <Box
          sx={{
            minWidth: '100%',
            opacity: providerSearchDisabled ? '1' : '0.5',
          }}
        >
          <NoteCareProviderSearchBar
            patientId={patientId}
            disabled={providerSearchDisabled}
            onClose={clearNoteExternalProviderId}
            onSelect={setNoteExternalProviderId}
            selectedValue={externalProviderId}
          />
          {isEpicPatient && (
            <span className={errorMessageStyle}>
              <FormattedMessage defaultMessage="Provider selection disabled for Epic patients." />
            </span>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          ...flexSection('column', 'flex-end', 'flex-end', 'inline-flex'),
          width: '100%',
        }}
      >
        <Box
          sx={{
            ...flexSection('row', 'space-between', 'AlignVerticalTop'),
            width: '100%',
            marginTop: '3px',
          }}
        >
          {(actionRequired && renderProviderSearchMessage()) || <div />}
          <div>
            <LabelledCheckbox
              checked={actionRequired}
              label={<FormattedMessage defaultMessage="Action required" />}
              id="add-note-form-checkbox"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onActionRequiredChange(e.target.checked)
              }
              fontSize="12px"
            />
            {actionRequired && (
              <LabelledCheckbox
                checked={Boolean(urgent)}
                label={<FormattedMessage defaultMessage="Urgent" />}
                id="add-note-form-urgent-checkbox"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onUrgentChange(e.target.checked)
                }
                fontSize="12px"
              />
            )}
          </div>
        </Box>
      </Box>
    </Box>
  );
};
