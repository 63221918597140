import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { settings } from 'config';
import { CANCEL_PENDING_REQUEST } from 'shared/actionCreators';

import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  forgotPasswordInitiated,
} from '../actionCreators';

const forgotPasswordEpic = (action$: any) =>
  action$.pipe(
    ofType(FORGOT_PASSWORD),
    switchMap((action: any) =>
      from(
        axios.post(`${settings.API_URL}/auth/api/v1/forgot-password`, {
          email: action.email,
        }),
      ).pipe(takeUntil(action$.pipe(ofType(CANCEL_PENDING_REQUEST)))),
    ),
    map((response: AxiosResponse) => forgotPasswordInitiated(response.data)),
    catchError((error, caught) =>
      merge(
        of({
          type: FORGOT_PASSWORD_FAILURE,
          payload: error,
          error: true,
        }),
        caught,
      ),
    ),
  );

export default forgotPasswordEpic;
