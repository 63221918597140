import type {
  DraftInlineStyleType,
  RawDraftContentBlock,
  RawDraftContentState,
} from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';

import { CustomRtfEditorStyles } from 'shared/common/EditorRTF/types';
import type { TNoteBodyRTF } from 'shared/types/note.types';

export function isRtfBodyEmpty(rtfBody: Maybe<TNoteBodyRTF>) {
  return !rtfBody?.blocks || rtfBody.blocks.every((block) => block.text === '');
}

export const EMPTY_RTF_BODY: RawDraftContentState = {
  blocks: [],
  entityMap: {},
};

// TODO: Remove when switch over to V2 Rich Text Editor
// https://cadencerpm.atlassian.net/browse/PLAT-4330
export function convertDraftJsToMarkdown(rtfBody: Maybe<TNoteBodyRTF>) {
  if (!rtfBody?.blocks || isRtfBodyEmpty(rtfBody)) {
    return null;
  }

  const newRtfBody = {
    ...rtfBody,
    blocks: [...rtfBody.blocks].map((block) => {
      // Replace empty list items with new lines because they show as headings in markdown
      if (block.type === 'unordered-list-item') {
        if (block.text.replace(/\s+/g, '') === '') {
          return {
            ...block,
            type: 'unstyled',
          };
        }
      }

      // Escape # at beginning of dash list item (e.g. "- # of servings per week") because they show as headings
      if (block.text.includes('- #')) {
        return {
          ...block,
          text: block.text.replace('- #', '- \\#'),
        };
      }

      if (isTemplateHeader(block)) {
        return {
          ...block,
          type: 'header-three',
        };
      }

      return block;
    }),
  };

  return draftToMarkdown(convertRtfBodyToRawDraftContentState(newRtfBody), {
    preserveNewlines: true,
  });
}

function isTemplateHeader(block: RawDraftContentBlock) {
  return block.inlineStyleRanges.some(
    (r) =>
      r.style ===
      (CustomRtfEditorStyles.TEMPLATE_HEADER as unknown as DraftInlineStyleType),
  );
}

export function convertRtfBodyToRawDraftContentState(
  rtfBody: Maybe<TNoteBodyRTF>,
) {
  if (!rtfBody || !isRawDraftContentState(rtfBody)) {
    return EMPTY_RTF_BODY;
  }
  return rtfBody;
}

export function isRawDraftContentState(
  rtfBody: TNoteBodyRTF,
): rtfBody is RawDraftContentState {
  return Boolean(rtfBody?.blocks && rtfBody?.entityMap);
}

export function titleRtfBlock(title: string): RawDraftContentBlock {
  return {
    key: title,
    text: title,
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [{ offset: 0, length: title.length, style: 'BOLD' }],
    entityRanges: [],
    data: {},
  };
}
