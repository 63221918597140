import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import bubbles from 'shared/assets/bubbles2.png';
import { SimpleEmptyState as EmptyState } from 'shared/common/EmptyState';
import { LoadingPlaceholder } from 'shared/common/LoadingPlaceholder';
import type {
  CallDirection,
  CallState,
} from 'shared/generated/grpcGateway/call.pb';
import { useCaregiverDetails } from 'shared/hooks/queries';
import { useListCalls } from 'shared/hooks/queries/call.queries';
import { useSmsHistory } from 'shared/hooks/queries/communications.queries';
import type { Patient } from 'shared/types/patient.types';
import { PatientStatus } from 'shared/types/patient.types';

import { ConversationHistory } from './ConversationHistory';
import { ConversationsSelect } from './ConversationsSelect';
import { Footer } from './Footer';
import { emptyStateText, loadingContainer, messageTab } from './Messages.css';
import { SendMessages } from './SendMessage';
import { processCallData } from './utils';

export type SMSMessage = {
  body: string;
  date_sent: string;
  date_created: string;
  direction: string;
  error_message?: string;
  from_: string;
  sid: string;
  status: string;
  to: string;
};

export type Conference = {
  uniqueId: string;
  direction: CallDirection;
  state: CallState;
  startTime: string;
  legs: ConferenceLeg[];
  patientDuration: number;
  recordingSid: Maybe<string>;
  noteId: Maybe<number>;
};

export type ConferenceLeg = {
  careProviderFirstName: Maybe<string>;
  careProviderLastName: Maybe<string>;
  careProviderRole?: Maybe<string>;
  duration: number;
  isTransfer: boolean;
};

type Props = {
  patient: Patient;
};

export type Recipient = {
  firstName: string | undefined;
  lastName: string | undefined;
  isCaregiver: boolean;
};

export function Messages({ patient }: Props) {
  const { data: conversationsData, isLoading: isLoadingMessages } =
    useSmsHistory(patient.id);
  const intl = useIntl();

  const conversations = (conversationsData?.message_histories ?? []).map(
    (msgHistory) => ({
      key: msgHistory.caregiver_id ?? msgHistory.patient_id ?? '',
      patientId: msgHistory.patient_id,
      caregiverId: msgHistory.caregiver_id,
      caregiverName: msgHistory.caregiver_name,
      messages: msgHistory.messages,
      phoneNumber: msgHistory.phone_number,
    }),
  );

  const [messageKey, setMessageKey] = useState<string | undefined>();

  // Can be true if contact is not in cerebro (which is a separate problem to solve)
  const patientHasNoConversation = !conversations.find((c) => c.patientId);

  const selectedConversation =
    patientHasNoConversation && messageKey === patient.id
      ? {
          key: patient.id,
          patientId: patient.id,
          caregiverId: null,
          caregiverName: null,
          phoneNumber: null,
          messages: [],
        }
      : conversations.find((msg) => msg.key === messageKey);

  const { data: callsData } = useListCalls({
    user: {
      patientId: patient.id,
      caregiverId: selectedConversation?.caregiverId,
    },
  });

  const caregiverQuery = useCaregiverDetails(
    patient.id,
    selectedConversation?.caregiverId ?? '',
    Boolean(selectedConversation?.caregiverId),
  );

  const caregiverSmsOptIn =
    caregiverQuery.data?.caregiver?.notification_settings?.sms_consent;
  const patientSmsOptIn = patient.notification_settings?.sms_consent;

  // Set default messageKey value once messageOptions is populated
  useEffect(() => {
    // Early return if no need to set messageKey
    if (conversations.length === 0 || messageKey) return;

    const patientConversationKey = conversations.find(({ patientId }) =>
      Boolean(patientId),
    )?.key;
    const caregiverConversationKey = conversations.find(({ caregiverId }) =>
      Boolean(caregiverId),
    )?.key;
    setMessageKey(patientConversationKey ?? caregiverConversationKey);
  }, [conversations, messageKey]);

  const selectedConversationMessages =
    selectedConversation?.messages ?? conversations[0]?.messages;

  const uniqueCalls = processCallData(callsData?.calls || []);

  const messagesAndCalls = [
    ...((selectedConversationMessages as SMSMessage[]) ?? []),
    ...uniqueCalls,
  ];

  messagesAndCalls.sort((a, b) => {
    const dateA =
      'date_created' in a
        ? new Date(a.date_created)
        : new Date(a.startTime as string);
    const dateB =
      'date_created' in b
        ? new Date(b.date_created)
        : new Date(b.startTime as string);

    return dateA.getTime() - dateB.getTime();
  });

  const recipient = selectedConversation?.patientId
    ? {
        firstName: patient.first_name,
        lastName: patient.last_name,
        isCaregiver: false,
      }
    : {
        firstName: selectedConversation?.caregiverName?.split(' ')[0],
        lastName: selectedConversation?.caregiverName?.split(' ')[1],
        isCaregiver: true,
      };

  return (
    <LoadingPlaceholder
      className={loadingContainer}
      isLoading={isLoadingMessages}
    >
      <div className={messageTab}>
        {!selectedConversation && !conversations[0] && (
          <EmptyState
            image={
              <img
                src={bubbles}
                alt={intl.formatMessage({ defaultMessage: 'message bubbles' })}
              />
            }
          >
            <div className={emptyStateText}>
              {patient.status === PatientStatus.Disenrolled ? (
                <FormattedMessage defaultMessage="This patient has been disenrolled and cannot receive SMSs" />
              ) : (
                <FormattedMessage defaultMessage="No SMSs" />
              )}
            </div>
          </EmptyState>
        )}
        {messagesAndCalls && messagesAndCalls.length > 0 && (
          <ConversationHistory
            conversation={messagesAndCalls}
            recipient={recipient}
          />
        )}
        {messagesAndCalls?.length === 0 && (
          <EmptyState
            image={
              <img
                src={bubbles}
                alt={intl.formatMessage({ defaultMessage: 'message bubbles' })}
              />
            }
          >
            <div className={emptyStateText}>
              <FormattedMessage defaultMessage="No SMS or Calls have been exchanged yet" />
            </div>
          </EmptyState>
        )}
      </div>
      {(selectedConversation || conversations[0]) && (
        <Footer>
          <ConversationsSelect
            options={conversations}
            selected={selectedConversation || conversations[0]}
            onChange={(opt) => setMessageKey(opt.key)}
            patientWithNoConversation={
              patientHasNoConversation ? patient : null
            }
          />
          <SendMessages
            patientSmsOptIn={patientSmsOptIn}
            caregiverSmsOptIn={caregiverSmsOptIn}
            patientId={selectedConversation?.patientId ?? null}
            caregiverId={selectedConversation?.caregiverId ?? null}
          />
        </Footer>
      )}
    </LoadingPlaceholder>
  );
}
