import { INIT_VISIT_SECTIONS } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/metadata';
import type { DeviceUsageGoalsFormFields } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/LowDeviceUsage';
import type { ProductFeedbackFormFields } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/ProductSupport/feedbackFormConfig';
import type { SectionStepsState } from 'shared/common/Wizard/state';
import { getWizardStepValues } from 'shared/common/Wizard/state';
import type { VitalsEngagement } from 'shared/hooks/useVitalsEngagement';
import type { FeatureFlagSet } from 'shared/types/featureFlags.types';

import { cnProductSupport } from '../../shared/cnProductSupport';

export function initialVisitProductSupport(
  data: SectionStepsState,
  vitalsEngagement: VitalsEngagement,
  flags: FeatureFlagSet,
) {
  const feedbackData = getWizardStepValues(
    data,
    INIT_VISIT_SECTIONS,
    '/product-support',
    '/feedback',
  )<ProductFeedbackFormFields>();
  const deviceUsageData = getWizardStepValues(
    data,
    INIT_VISIT_SECTIONS,
    '/product-support',
    '/low-device-usage',
  )<DeviceUsageGoalsFormFields>();

  // this section does not exist for the new initial visit
  if (flags.cycle17InitialCnVisit) {
    return '';
  }

  return cnProductSupport({
    feedbackData,
    deviceUsageData,
    vitalsEngagement,
  });
}
