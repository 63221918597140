import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { TypeOfEncounter } from 'pages/patients/patientDetails/ui/Notes/Notes.types';
import { LoadingPlaceholder } from 'shared/common/LoadingPlaceholder';
import { Wizard } from 'shared/common/Wizard';
import type { SectionMeta } from 'shared/common/Wizard/path.utils';
import { getFullStepPath } from 'shared/common/Wizard/path.utils';
import type { WizardFormData } from 'shared/common/Wizard/state';
import type { AutosavedNoteSchema } from 'shared/generated/api/pms';
import { useFlags } from 'shared/hooks';
import { ConditionProgram } from 'shared/types/condition.types';

import { CNNotesHeading } from '../CNNotesHeading';
import { CNNotesPanelHeader } from '../CNNotesPanelHeader';
import {
  useAutosaveCnWizardState,
  useHasClinicalEncounter,
} from '../shared/query.hooks';
import { cnNoteKeys } from '../shared/querykeys';
import { panelBody } from './CNWizardForm.css';
import { ConfiguredCNFormContextProvider } from './ConfiguredCNFormContextProvider';
import { DeleteAutosaveModal } from './modals';
import {
  CHF_VISIT_SECTIONS,
  CYCLE_17_INIT_VISIT_SECTIONS,
  INIT_VISIT_SECTIONS,
  PATIENT_FOLLOW_UP_SECTIONS,
  REGULAR_VISIT_SECTIONS,
} from './sections/metadata';
import type { CNVisitType } from './types';

type Props = {
  onStopEditing: () => void;
  onReset: () => void;
  autosavedNote: Maybe<AutosavedNoteSchema>;
  initialState: WizardFormData | undefined;
  program: Nullable<ConditionProgram>;
};

const SECTIONS_MAP: Record<CNVisitType, Readonly<SectionMeta[]>> = {
  [TypeOfEncounter.INITIAL_CN_VISIT]: INIT_VISIT_SECTIONS,
  [TypeOfEncounter.CN_VISIT]: REGULAR_VISIT_SECTIONS,
  [TypeOfEncounter.CN_CHF_VISIT]: CHF_VISIT_SECTIONS,
  [TypeOfEncounter.PATIENT_FOLLOW_UP]: PATIENT_FOLLOW_UP_SECTIONS,
  [TypeOfEncounter.INITIAL_CN_CHF_VISIT]: CHF_VISIT_SECTIONS,
  [TypeOfEncounter.CYCLE_17_INITIAL_CN_VISIT]: CYCLE_17_INIT_VISIT_SECTIONS,
};

export function CNWizardForm({
  onStopEditing,
  onReset,
  initialState,
  autosavedNote,
  program,
}: Props) {
  const isChf = program === ConditionProgram.CHF;
  const { patientId } = useParams<{ patientId: string }>();
  const [isDeleting, setIsDeleting] = useState(false);
  const { cycle17InitialCnVisit } = useFlags();
  const autosavedVisitType =
    autosavedNote?.clinical_navigator_note?.[0]?.visitType;
  const [cnVisitType, setCNVisitType] = useState<CNVisitType>(() => {
    if (autosavedVisitType) {
      return autosavedVisitType;
    }

    if (cycle17InitialCnVisit) {
      return TypeOfEncounter.CYCLE_17_INITIAL_CN_VISIT;
    }

    return isChf
      ? TypeOfEncounter.INITIAL_CN_CHF_VISIT
      : TypeOfEncounter.INITIAL_CN_VISIT;
  });
  const sections = SECTIONS_MAP[cnVisitType];

  const firstSectionPath = getFullStepPath(
    sections,
    sections[0]?.basePath,
    sections[0]?.steps[0].path,
  );

  const queryClient = useQueryClient();
  const { hasClinicalEncounter, isLoading: isLoadingClinicalEncounters } =
    useHasClinicalEncounter(patientId);

  // If patient has had an initial visit and no loaded autosaved note
  // then set the visit type as Regular CN Visit
  useEffect(() => {
    if (!isLoadingClinicalEncounters) {
      if (!autosavedNote && hasClinicalEncounter) {
        setCNVisitType(
          isChf ? TypeOfEncounter.CN_CHF_VISIT : TypeOfEncounter.CN_VISIT,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingClinicalEncounters]);

  const noteId = autosavedNote?.id;
  const zendeskTicketId = autosavedNote?.zendesk_ticket_id ?? null;
  // if we don't have a note id, we want to invalidate our autosaved query in order
  // to grab it. we don't want to unconditionally invalidate the query because it results
  // in a lot of unnecessary network requests.
  const shouldInvalidate = !noteId;

  // Autosaves on form field blur, on step navigation, whenever we submit a step, and when
  // the timer is updated (Currently every 10 seconds)
  const { mutate: autosaveWizardState } = useAutosaveCnWizardState(
    patientId,
    cnVisitType,
    zendeskTicketId,
    shouldInvalidate,
    autosavedNote?.appointment_id,
    autosavedNote?.no_show_appointment_id,
  );

  const clearEditor = useCallback(() => {
    queryClient.invalidateQueries(cnNoteKeys.autosaved(patientId));
    queryClient.resetQueries(cnNoteKeys.autosaved(patientId));
    const publishedNotesKey = cnNoteKeys.publishedNotesInfinite(patientId, {
      is_clinical_navigator_note: true,
    });
    queryClient.invalidateQueries(publishedNotesKey);
    queryClient.resetQueries(publishedNotesKey);
    onStopEditing();
  }, [onStopEditing, patientId, queryClient]);

  return (
    <LoadingPlaceholder
      // keepMounted is critical - we invalidate note data on publish, which triggers
      // a loading state and a re-render, putting the form into a weird state.
      keepMounted
      isLoading={isLoadingClinicalEncounters}
    >
      <ConfiguredCNFormContextProvider
        noteId={noteId}
        cnVisitType={cnVisitType}
        zendeskTicketId={zendeskTicketId}
        clearEditor={clearEditor}
        sections={sections}
        appointmentId={autosavedNote?.appointment_id || null}
        noShowAppointmentId={autosavedNote?.no_show_appointment_id || null}
      >
        {/* Re-mount when visit type changes */}
        <Wizard.Provider
          key={cnVisitType}
          initialState={initialState}
          initPath={firstSectionPath}
          onStateChange={autosaveWizardState}
        >
          {({
            timerRunning,
            setTimerRunning,
            isPauseable,
            resetWizard,
            wizardFormData: { data: stepData },
          }) => (
            <>
              <CNNotesPanelHeader
                cnVisitType={cnVisitType}
                onClose={clearEditor}
                onDelete={async () => setIsDeleting(true)}
                stepData={stepData}
                timer={{
                  timerRunning,
                  isPauseable,
                  onToggle: () => setTimerRunning(!timerRunning),
                }}
              />
              <div className={panelBody}>
                <CNNotesHeading
                  isChf={isChf}
                  cnVisitType={cnVisitType}
                  onVisitTypeChange={(visit) => {
                    // Need to reset the wizard before changing the visit type
                    resetWizard();
                    onReset();

                    if (
                      cycle17InitialCnVisit &&
                      visit === TypeOfEncounter.INITIAL_CN_VISIT
                    ) {
                      setCNVisitType(TypeOfEncounter.CYCLE_17_INITIAL_CN_VISIT);
                    } else {
                      setCNVisitType(visit);
                    }
                  }}
                />

                <Wizard visitType={cnVisitType} />
              </div>
            </>
          )}
        </Wizard.Provider>
        <DeleteAutosaveModal
          patientId={patientId}
          isOpen={isDeleting}
          close={() => setIsDeleting(false)}
          onDeleted={() => clearEditor()}
        />
      </ConfiguredCNFormContextProvider>
    </LoadingPlaceholder>
  );
}
