import { draftJsFieldTemplate } from 'pages/patients/patientDetails/ui/Notes/NoteEditor/templates/template.utils';
import { EncounterModuleId } from 'pages/patients/patientDetails/ui/Notes/Notes.types';

import assessmentBody from '../../__shared__/t2dAndHtn_assessment_body.hbs';

export const generalAssessmentAndPlanModule = {
  [EncounterModuleId.GeneralAssessmentAndPlan]: {
    assessment_body: draftJsFieldTemplate(assessmentBody),
  },
};
