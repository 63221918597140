import type { ReactNode } from 'react';

import AlertIcon from 'shared/assets/svgs/alertTriangle.svg?react';
import { color } from 'shared/tempo/theme';

import {
  body,
  container,
  title as titleStyles,
} from './CarePlanAlertBanner.css';

type Props = {
  title: ReactNode;
  children?: ReactNode;
};

export function CarePlanAlertBanner({ title, children }: Props) {
  return (
    <div className={container}>
      <div className={titleStyles.container}>
        <AlertIcon fill={color.Theme.Light.Danger} />
        <div className={titleStyles.text}>{title}</div>
      </div>
      {children && <div className={body}>{children}</div>}
    </div>
  );
}
