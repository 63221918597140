import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import PlusIcon from 'shared/assets/icons/PlusIcon';
import StopWatchIcon from 'shared/assets/svgs/stopWatch.svg?react';
import { useFlags } from 'shared/hooks';
import { Button } from 'shared/tempo/atom/Button';
import { color } from 'shared/tempo/theme';

import { TimeEntryTable } from './TimeEntryTable';
import {
  addTimeButton,
  container,
  header,
  plusIcon,
  stopWatchIcon,
  title,
} from './TimeTracking.css';
import { TimeTrackingModals } from './modals/TimeTrackingModals';
import type { ModalState } from './modals/modalState.types';
import { ModalType } from './modals/modalState.types';

export function TimeTracking() {
  const { patientId } = useParams<{ patientId: string }>();
  const [modalState, setModalState] = useState<Nullable<ModalState>>(null);
  const { removeManualTimeTracking } = useFlags();

  return (
    <>
      <div className={container}>
        <div className={header}>
          <div className={title}>
            <StopWatchIcon
              fill={color.Theme.Light['Base Font']}
              className={stopWatchIcon}
            />
            <FormattedMessage defaultMessage="Time Records" />
          </div>
          {!removeManualTimeTracking && (
            <Button
              id="add-time-record"
              variant="secondary"
              onPress={() =>
                setModalState({ type: ModalType.CreateConfirmation })
              }
              className={addTimeButton}
              size="small"
            >
              <Button.Icon>
                <PlusIcon className={plusIcon} />
              </Button.Icon>
              <FormattedMessage defaultMessage="Add time record" />
            </Button>
          )}
        </div>
        <TimeEntryTable
          patientId={patientId}
          onEdit={(entryId: string) =>
            setModalState({ type: ModalType.UpdateTimeEntry, entryId })
          }
          onView={(entryId: string) =>
            setModalState({ type: ModalType.ViewTimeEntry, entryId })
          }
        />
      </div>
      <TimeTrackingModals
        modalState={modalState}
        setModalState={setModalState}
      />
    </>
  );
}
