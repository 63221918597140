import cx from 'classnames';
import type { ForwardedRef } from 'react';
import { type ComponentProps, type FunctionComponent, forwardRef } from 'react';

import { NotificationBadge } from 'shared/tempo/atom/NotificationBadge';

import {
  cardPadding,
  selectedCard,
  selectedIcon,
  unselectedCard,
  unselectedIcon,
} from './Choice.css';

type Props = {
  isSelected?: boolean;
  onPress?: () => void;
  title: string;
  Icon?: FunctionComponent<ComponentProps<'svg'>>;
  size?: 'small' | 'large';
  count?: number;
};

export const Choice = forwardRef(
  (
    {
      isSelected = false,
      onPress = () => {},
      title,
      Icon,
      size = 'large',
      count,
    }: Props,
    ref?: ForwardedRef<HTMLDivElement> | null,
  ) => {
    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        onPress();
      }
    };

    return (
      <div
        className={cx({
          [cardPadding.small]: size === 'small',
          [cardPadding.large]: size === 'large',
          [selectedCard]: isSelected,
          [unselectedCard]: !isSelected,
        })}
        onClick={onPress}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => handleKeyPress(e)}
        ref={ref}
      >
        {Icon && (
          <Icon
            className={cx({
              [selectedIcon]: isSelected,
              [unselectedIcon]: !isSelected,
            })}
          />
        )}
        {title}
        {count !== undefined && (
          <NotificationBadge count={count} variant="muted" />
        )}
      </div>
    );
  },
);
