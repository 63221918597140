import type { ReactElement } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from 'shared/common/Form';

import {
  type AssessmentName,
  type FormFields,
  getAssessmentFieldNames,
} from '../formConfig';
import {
  instructions,
  sectionTitle,
  toggleRowLabel,
} from './carePlanSections.css';
import { PSYCHOSOCIAL_ASSESSMENT_OPTIONS } from './psychoSocialAssessmentOptions';

type Props = {
  form: UseFormReturn<FormFields>;
};

export function PsychosocialAssessment({ form }: Props) {
  const intl = useIntl();

  return (
    <Form.Section
      title={intl.formatMessage({
        defaultMessage: 'Psychosocial Assessment/Social Determinants of Need',
      })}
      classes={{ title: sectionTitle }}
    >
      <p className={instructions.body}>
        <FormattedMessage
          defaultMessage="Complete the <link>assessment</link> with the patient and then document the results below."
          values={{
            link: (text) => (
              <a
                className={instructions.link}
                href="https://forms.gle/zGUyvZppW53nULwv7"
                target="_blank"
                rel="noreferrer"
              >
                {text}
              </a>
            ),
          }}
        />
      </p>
      {PSYCHOSOCIAL_ASSESSMENT_OPTIONS.map(({ name, label }) => (
        <AssessmentRow key={name} name={name} label={label} form={form} />
      ))}
    </Form.Section>
  );
}

function AssessmentRow({
  name,
  label,
  form,
}: {
  name: AssessmentName;
  label: ReactElement;
} & Props) {
  const intl = useIntl();
  const { checked: checkedFieldName, description: descriptionFieldName } =
    getAssessmentFieldNames(name);
  const checked = form.getValues(checkedFieldName);

  return (
    <Form.Row>
      <Form.Toggle
        required={!!checked}
        name={checkedFieldName}
        label={label}
        labelPlacement="start"
        labelClassName={toggleRowLabel}
        size={6}
        onChange={(isChecked) => {
          if (!isChecked) {
            form.resetField(descriptionFieldName);
          }
        }}
      />
      <Form.TextArea
        size={5}
        name={descriptionFieldName}
        placeholder={
          checked
            ? intl.formatMessage({
                defaultMessage: 'Resource provided',
              })
            : undefined
        }
        isDisabled={!checked}
      />
    </Form.Row>
  );
}
