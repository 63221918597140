import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from 'shared/common/Wizard/state';

import { CHF_VISIT_SECTIONS } from '../../metadata';
import { SharedEndCall } from '../../shared/EndCall';
import type { EndCallFormFields } from '../../shared/EndCall/formConfig';
import { getEndCallFormConfig } from '../../shared/EndCall/formConfig';
import { chfVisitPath } from '../paths';

export function EndCall() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<EndCallFormFields>(
    chfVisitPath('/end-call', '/index'),
    getEndCallFormConfig(intl),
  );

  return (
    <SharedEndCall
      form={form}
      sections={CHF_VISIT_SECTIONS}
      nextPath={chfVisitPath('/time-tracking', '/index')}
    />
  );
}
