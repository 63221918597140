import type {
  Medication,
  MedicationChange,
  PatientMedications,
} from 'shared/generated/grpcGateway/medication.pb';
import { MedicationChangeStatus } from 'shared/generated/grpcGateway/medication.pb';

import type { OriginalMedChanges } from '../PatientMedicationsContext';
import {
  compareDate,
  isStructured,
  lastChange,
  sortName,
} from './medChangeUtils';

type MedOrMedChange = {
  med: Nullable<Medication>;
  medChange: MedicationChange;
};

const priority = {
  [MedicationChangeStatus.NEEDS_REVIEW]: 1,
  [MedicationChangeStatus.ACTIVE]: 2,
  [MedicationChangeStatus.INACTIVE]: 3,
};

export function getMedGroups(
  data?: PatientMedications,
  originalUnrefdMedChanges?: OriginalMedChanges,
  originalRefdMedChanges?: OriginalMedChanges,
) {
  const refMeds = data?.referencedMedications || [];
  const unreferencedMeds = data?.unreferencedMedications || [];
  const diseaseSpecificMeds = getDiseaseSpecificMeds(
    refMeds,
    originalRefdMedChanges,
  );
  const otherMeds = getOtherMeds(
    refMeds,
    unreferencedMeds,
    originalRefdMedChanges,
    originalUnrefdMedChanges,
  );
  return {
    diseaseSpecificMeds,
    otherMeds,
  };
}

/**
 *  getDiseaseSpecificMeds returns disease-specific medications sorted in the following order:
 *  1. Status = 'NEEDS REVIEW'
 *     a. Unstructured -- encourage verify/remove and structure actions
 *     b. Structured -- encourage verify/remove and review actions
 *  2. Status = 'ACTIVE'
 *    a. Unstructured -- encourage structure action. (should show warning)
 *    b. Structured
 *  3. Status = 'INACTIVE'
 *
 * And within each category, sort by last updated date in descending order
 */
function getDiseaseSpecificMeds(
  refMeds: Medication[],
  originalRefdMedChanges?: OriginalMedChanges,
) {
  return refMeds
    .filter((med) => med.isDiseaseSpecific)
    .sort((m1: Medication, m2: Medication) => {
      const mc1 = lastChange(m1.medChanges);
      const mc2 = lastChange(m2.medChanges);
      if (!mc1) {
        return 1;
      }
      if (!mc2) {
        return -1;
      }
      return sortMedChanges(
        { med: m1, medChange: mc1 },
        { med: m2, medChange: mc2 },
        true,
        originalRefdMedChanges,
      );
    });
}

/**
 * getOtherMeds returns non-disease-specific meds, which includes a mix of
 *  non-disease-specific referenced meds and all unreferenced med changes,
 *  sorted using the same logic as disease-specific meds except without sorting
 *  structured vs. unstructured
 */
function getOtherMeds(
  refMeds: Medication[],
  unreferencedMedChanges: MedicationChange[],
  originalRefdMedChanges?: OriginalMedChanges,
  originalUnrefdMedChanges?: OriginalMedChanges,
) {
  const nonDiseaseSpecificReferencedMedChanges: MedOrMedChange[] = refMeds
    .filter((med) => !med.isDiseaseSpecific)
    .map((m) => ({
      med: m,
      medChange: lastChange(m.medChanges),
    })) as MedOrMedChange[];
  const otherMedChanges: MedOrMedChange[] =
    nonDiseaseSpecificReferencedMedChanges.concat(
      unreferencedMedChanges.map((mc) => ({ med: null, medChange: mc })),
    );
  return otherMedChanges.sort((a, b) =>
    sortMedChanges(
      a,
      b,
      false,
      originalRefdMedChanges,
      originalUnrefdMedChanges,
    ),
  );
}

function sortMedChanges(
  mc1: MedOrMedChange,
  mc2: MedOrMedChange,
  isDiseaseSpecific = false,
  originalRefdMedChanges?: OriginalMedChanges,
  originalUnrefdMedChanges?: OriginalMedChanges,
) {
  const firstMedChange = getOriginalMedChange(
    mc1,
    originalRefdMedChanges,
    originalUnrefdMedChanges,
  );
  const secondMedChange = getOriginalMedChange(
    mc2,
    originalRefdMedChanges,
    originalUnrefdMedChanges,
  );

  // Sort by status
  const status1 = firstMedChange?.status;
  const status2 = secondMedChange?.status;
  if (!status1 || status1 === MedicationChangeStatus.STATUS_UNSPECIFIED) {
    return 1;
  }
  if (!status2 || status2 === MedicationChangeStatus.STATUS_UNSPECIFIED) {
    return -1;
  }
  if (status1 !== status2) {
    return priority[status1] - priority[status2];
  }

  // Then sort by unstructured vs. structured
  if (isDiseaseSpecific) {
    if (!isStructured(firstMedChange) && isStructured(secondMedChange)) {
      return -1;
    }
    if (!isStructured(secondMedChange) && isStructured(firstMedChange)) {
      return 1;
    }
  }

  // Then sort by updated date
  const dateComparision = compareDate(
    firstMedChange.updatedDate,
    secondMedChange.updatedDate,
  );

  if (dateComparision !== 0) {
    return dateComparision;
  }

  // Then sort by med name
  const sortNameA = sortName(firstMedChange) || 'a';
  const sortNameB = sortName(secondMedChange) || 'b';
  return sortNameA.localeCompare(sortNameB);
}

/**
 *  getOriginalMedChange returns the med change that was originally
 *  first from the server on page load, which we use for the sort logic
 *  so that the med can stay in place after an action is taken
 * */
function getOriginalMedChange(
  { med, medChange }: MedOrMedChange,
  originalRefdMedChanges?: OriginalMedChanges,
  originalUnrefdMedChanges?: OriginalMedChanges,
) {
  const refId = med?.referenceMedicationId;
  const medChangeId = medChange?.name;
  const prevMedChange =
    (refId && originalRefdMedChanges?.[refId]) ||
    (medChangeId && originalUnrefdMedChanges?.[medChangeId]);
  return prevMedChange || medChange;
}
