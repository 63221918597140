import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { settings } from 'config';
import { CANCEL_PENDING_REQUEST } from 'shared/actionCreators';

import {
  FORCE_CHANGE_PASSWORD,
  FORCE_CHANGE_PASSWORD_FAILURE,
  passwordChanged,
} from '../actionCreators';

const forceChangePasswordEpic = (action$: any) =>
  action$.pipe(
    ofType(FORCE_CHANGE_PASSWORD),
    switchMap((action: any) =>
      from(
        axios.post(`${settings.API_URL}/auth/api/v1/confirm-forgot-password`, {
          email: action.email,
          confirmation_code: action.confirmationCode,
          proposed_password: action.proposedPassword,
        }),
      ).pipe(takeUntil(action$.pipe(ofType(CANCEL_PENDING_REQUEST)))),
    ),
    map((response: AxiosResponse) => passwordChanged(response.data)),
    catchError((error, caught) =>
      merge(
        of({
          type: FORCE_CHANGE_PASSWORD_FAILURE,
          payload: error,
          error: true,
        }),
        caught,
      ),
    ),
  );

export default forceChangePasswordEpic;
