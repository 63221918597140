import first from 'lodash/first';
import { Redirect, useRouteMatch } from 'react-router-dom';

import { LoadingPlaceholder } from 'shared/common/LoadingPlaceholder';
import { usePatientClinicalProfile } from 'shared/hooks/queries';
import { useListCarePlans } from 'shared/hooks/queries/carePlan.queries';
import { grpcNameToId } from 'shared/utils/grpc';
import { joinPaths } from 'shared/utils/route-helpers';

import { CarePlanEmptyState } from './CarePlanEmptyState';

export function CarePlanRedirect() {
  const { url, params } = useRouteMatch<{ patientId: string }>();
  const { data, isFetching } = useListCarePlans({
    filter: `patientId="${params.patientId}"`,
    orderBy: 'createTime desc',
  });
  const { isLoading: loadingClinicalProfile, data: clinicalProfile } =
    usePatientClinicalProfile(params.patientId);

  if (isFetching || loadingClinicalProfile) {
    return <LoadingPlaceholder isLoading />;
  }

  if (data?.ccmCarePlans?.length) {
    const plan = first(data.ccmCarePlans);

    if (plan?.name) {
      return (
        <Redirect to={`/${joinPaths(url, grpcNameToId(plan.name) || '')}`} />
      );
    }
  }

  return (
    <CarePlanEmptyState
      hasLegacyApprovedCarePlan={!!clinicalProfile?.ccmCarePlanApprovalDate}
    />
  );
}
