import type { SectionStepsState } from 'shared/common/Wizard/state';
import { getWizardStepValues } from 'shared/common/Wizard/state';

import { PATIENT_FOLLOW_UP_SECTIONS } from '../../../CNWizardForm/sections/patientFollowUp';
import type { NotesFormFields } from '../../../CNWizardForm/sections/patientFollowUp/notesFormConfig';

export function patientFollowUpNotes(data: SectionStepsState) {
  const notesData = getWizardStepValues(
    data,
    PATIENT_FOLLOW_UP_SECTIONS,
    '/notes',
    '/index',
  )<NotesFormFields>();
  return notesData?.notes;
}
