import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  useWizardFormFromConfig,
  useWizardStateContext,
} from 'shared/common/Wizard/state';
import { ConditionProgram } from 'shared/types/condition.types';

import { useCNFormContext } from '../../../../CNFormContext';
import { CYCLE_17_INIT_VISIT_SECTIONS } from '../../../metadata';
import type { EducationModulesReviewFormFields } from '../../../shared/EducationModulesReview';
import {
  SharedEducationModulesReview,
  getEducationModulesReviewFormConfig,
} from '../../../shared/EducationModulesReview';
import { cycle17InitVisitPath } from '../../paths';
import { usePatientGoal } from '../usePatientGoal';

export function EducationModulesReview() {
  const intl = useIntl();
  const history = useHistory();
  const form = useWizardFormFromConfig<EducationModulesReviewFormFields>(
    cycle17InitVisitPath('/action-plan', '/education-modules-review'),
    getEducationModulesReviewFormConfig(intl),
  );
  const patientGoal = usePatientGoal();
  const { program } = useCNFormContext();
  const { skipSectionStep } = useWizardStateContext();

  return (
    <SharedEducationModulesReview
      form={form}
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      patientGoal={patientGoal}
      onNext={() => {
        if (program === ConditionProgram.CHF) {
          skipSectionStep(
            cycle17InitVisitPath('/action-plan', '/set-action-steps'),
          );
          skipSectionStep(
            cycle17InitVisitPath('/action-plan', '/review-action-steps'),
          );
          history.push(
            cycle17InitVisitPath('/general-assessment', '/tobacco-use'),
          );
        } else {
          history.push(
            cycle17InitVisitPath('/action-plan', '/set-action-steps'),
          );
        }
      }}
    />
  );
}
