import { useMostRecentPublishedNote } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/query.hooks';
import { TypeOfEncounter } from 'pages/patients/patientDetails/ui/Notes/Notes.types';
import { getWizardStepValues } from 'shared/common/Wizard/state';

import { INIT_VISIT_SECTIONS, REGULAR_VISIT_SECTIONS } from '../../metadata';
import type { PatientGoalFormFields } from '../../shared/setPatientGoal';

export function useFindPreviousNoteWithPatientGoal(patientId: string) {
  return useMostRecentPublishedNote(patientId, true, (note) => {
    const cnNote = note.clinical_navigator_note?.[0];
    const stepData = cnNote?.form?.data;
    if (!stepData) {
      return false;
    }

    if (cnNote.visitType === TypeOfEncounter.INITIAL_CN_VISIT) {
      return !!getWizardStepValues(
        stepData,
        INIT_VISIT_SECTIONS,
        '/action-plan',
        '/set-goal',
      )<PatientGoalFormFields>()?.patientGoal;
    }
    if (cnNote.visitType === TypeOfEncounter.CN_VISIT) {
      return !!getWizardStepValues(
        stepData,
        REGULAR_VISIT_SECTIONS,
        '/action-plan',
        '/set-goal',
      )<PatientGoalFormFields>()?.patientGoal;
    }
    return false;
  });
}
