import cx from 'classnames';

import { EllipseIcon } from 'shared/assets/icons/Ellipse';
import { ActionIconTag } from 'shared/common/ActionIconTag';
import { variantIcon } from 'shared/common/ActionIconTag/ActionIconTag.css';
import { getTagType, useStatusText } from 'shared/patient/patientStatus';
import { Tooltip } from 'shared/tempo/atom/Tooltip';
import type { Patient } from 'shared/types/patient.types';
import { PatientStatus } from 'shared/types/patient.types';

import { statusTag } from '../PatientProfileHeader.css';

type Props = {
  patient: Patient;
  className?: string;
};

export function PatientStatusTag({ patient, className }: Props) {
  const isActivated =
    Boolean(patient.activated_at) && patient.status === PatientStatus.Enrolled;
  const { statusLabel } = useStatusText(patient.status, isActivated);
  const tagType = getTagType(patient.status, isActivated);

  return (
    <Tooltip content={statusLabel}>
      <ActionIconTag
        className={cx(statusTag, className)}
        icon={<EllipseIcon className={variantIcon[tagType]} />}
        tagLabel={statusLabel}
        tagType={tagType}
      />
    </Tooltip>
  );
}
