import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  FieldGroup,
  Script,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { Block } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Block/Block';
import { InlineMessage } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/InlineMessage';
import { useRecentNoteFormData } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useRecentNoteFormData';
import { useTimeEstimate } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Form } from 'shared/common/Form';
import { Wizard } from 'shared/common/Wizard';
import { useWizardFormFromConfig } from 'shared/common/Wizard/state';
import { useShowVitalsEducation } from 'shared/common/Wizard/state/sectionUtils';

import { REGULAR_VISIT_SECTIONS } from '../../metadata';
import { regVisitPath } from '../../paths';
import { discussionGuideList, prevNotes } from './PrevGoalDiscuss.css';
import type { GoalDiscussFormFields } from './formConfig';
import { getGoalDiscussFormConfig } from './formConfig';

export function PrevGoalDiscuss() {
  const intl = useIntl();
  const history = useHistory();

  const showVitalsEducation = useShowVitalsEducation();

  const path = regVisitPath('/action-plan', '/prev-goal-progress/discuss');
  const { data } = useRecentNoteFormData<GoalDiscussFormFields>(path);
  const form = useWizardFormFromConfig<GoalDiscussFormFields>(path, {
    ...getGoalDiscussFormConfig(),
  });

  return (
    <Wizard.Step
      sections={REGULAR_VISIT_SECTIONS}
      form={form}
      title={intl.formatMessage({ defaultMessage: 'Action plan' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => history.push(regVisitPath('/action-plan', '/set-goal'))}
    >
      <FieldGroup>
        {showVitalsEducation && (
          <Script>
            <FormattedMessage defaultMessage="How would you describe your experience with following your action plan since our last review? Are there any aspects that have been particularly challenging or rewarding for you?" />
          </Script>
        )}
        <InlineMessage>
          {showVitalsEducation ? (
            <FormattedMessage defaultMessage="Use this time to engage in a discussion about what went well and opportunities for improvement with the patient in regards to their health goal. If they struggled, stay positive. Help them to create a smaller goal that feels more achievable." />
          ) : (
            <FormattedMessage defaultMessage="Use this time to engage in a discussion about what went well and opportunities for improvement with the patient in regards to their health goal." />
          )}
        </InlineMessage>
        {!showVitalsEducation && (
          <ul className={discussionGuideList}>
            <li>
              <FormattedMessage defaultMessage="Use positive reinforcement: e.g. great work on achieving your goal! I'm proud of your achievement and it is a major step towards improving your health." />
            </li>

            <li>
              <FormattedMessage defaultMessage="Remain positive if the patient has not progressed" />
            </li>
            <li>
              <FormattedMessage defaultMessage="Review barriers" />
            </li>
            <li>
              <FormattedMessage defaultMessage="Consider a smaller goal" />
            </li>

            <li>
              <FormattedMessage defaultMessage="Avoid telling the patient what to do, instead try to understand their motivations and listen with empathy. Try to empower them with achievable goals" />
            </li>
          </ul>
        )}
        {showVitalsEducation && (
          <>
            {data?.actionPlanNotes && (
              <Block className={prevNotes.body}>
                <FormattedMessage defaultMessage="Previous notes:" />
                <br />
                {data.actionPlanNotes}
              </Block>
            )}
            <Form.TextArea
              size={12}
              rows={3}
              label={
                <FormattedMessage defaultMessage="New patient action plan notes" />
              }
              name="actionPlanNotes"
            />
          </>
        )}
      </FieldGroup>
    </Wizard.Step>
  );
}
