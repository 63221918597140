import { formatISO } from 'date-fns';

import { usePatientDetailsCtx } from 'pages/patients/PatientProfile/PatientDetailContext';
import { usePatientProfileCtx } from 'pages/patients/PatientProfile/context/PatientProfileCtx';

import { VitalsView } from '../patientDetails/ui/tabs/Vitals';

export const PatientListSidebarVitalsTable = () => {
  const { vitalsStartDate, vitalsEndDate } = usePatientProfileCtx();

  const { patientDetails } = usePatientDetailsCtx();
  const requestParams = {
    patientId: patientDetails.id,
    dateFrom: encodeURI(formatISO(vitalsStartDate, { representation: 'date' })),
    dateTo: encodeURI(formatISO(vitalsEndDate, { representation: 'date' })),
  };

  return (
    <VitalsView requestParams={requestParams} showLoadingSpinner={false} />
  );
};
