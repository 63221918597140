// eslint-disable-next-line no-restricted-imports
import type { GridSpacing } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import type { ResponsiveStyleValue } from '@mui/system';
import classnames from 'classnames/bind';
import type { ReactNode } from 'react';

import { Grid } from 'deprecated/mui';

import styles from './styles.module.scss';

const cx = classnames.bind(styles);

interface RowProps {
  children: ReactNode;
  className?: string;
  rowSpacing?: number;
  columnSpacing?: ResponsiveStyleValue<GridSpacing>;
}

export function Row({
  children,
  className,
  rowSpacing = 1.5,
  columnSpacing,
}: RowProps) {
  return (
    <Grid
      container
      className={cx('form-row', className)}
      rowSpacing={rowSpacing}
      columnSpacing={columnSpacing || { xs: 2, md: 4, lg: 6 }}
    >
      {children}
    </Grid>
  );
}
