import type { IntlShape } from 'react-intl';

import { validators } from 'shared/common/Form/validations';

export type MedReviewFormFields = {
  patientDeclineMedReview: boolean;
};

export function getMedReviewFormConfig(intl: IntlShape) {
  const { boolean } = validators(intl);

  return {
    fields: {
      patientDeclineMedReview: {
        defaultValue: false,
        validation: boolean(),
      },
    },
  };
}
