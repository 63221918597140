import cx from 'classnames';
import inRange from 'lodash/inRange';
import noop from 'lodash/noop';
import { useCallback, useRef } from 'react';

import type { TextFieldProps } from 'deprecated/mui';
import { TextField } from 'deprecated/mui';
import DownArrow from 'shared/assets/svgs/downArrow.svg?react';
import UpArrow from 'shared/assets/svgs/upArrow.svg?react';
import {
  addInputClassname,
  addInputWrapperClassname,
} from 'shared/common/Input';

import {
  arrow,
  button,
  buttonContainer,
  field,
  suffixText,
  textInput,
} from './style.css';

const MAX_DISPLAYABLE_DIGITS = 3;

export type NumberPickerProps = {
  onChange: (value?: number) => void;
  suffix?: {
    text: string;
    class?: string;
  };
  value?: number;
  min?: number;
  max?: number;
  error?: boolean;
  className?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
} & Omit<TextFieldProps, 'onChange' | 'disabled' | 'readonly'>;

export function NumberPicker({
  onChange,
  value,
  suffix,
  min = Number.NEGATIVE_INFINITY,
  max = Number.POSITIVE_INFINITY,
  error = false,
  isDisabled,
  className,
  ...rest
}: NumberPickerProps) {
  const inputRef = useRef<HTMLInputElement>();
  const inputProps = addInputClassname({
    'aria-label': rest.name,
    ...rest.inputProps,
  });
  const handleChange = useCallback(
    (val?: string) => {
      // Normalize value to number
      const parsedNumStr = val?.match(/-?\d+/g)?.join('');
      if (parsedNumStr === undefined || parsedNumStr === '') {
        onChange(undefined);
        return;
      }

      const numVal = Number(parsedNumStr);
      // Don't allow negative numbers
      if (numVal < 0) {
        return;
      }

      // Don't allow new value if it's not a number or beyond displayable digits
      if (Number.isNaN(numVal) || getDigits(numVal) > MAX_DISPLAYABLE_DIGITS) {
        return;
      }
      // Don't allow new value if it is outside the provided range
      if (!inRange(numVal || 0, min, max + 1)) {
        return;
      }
      onChange(numVal);
    },
    [max, min, onChange],
  );

  const initArrowVal = Math.max(0, min);
  const increment = () => {
    inputRef.current?.focus();
    handleChange(
      value !== undefined ? String(value + 1) : String(initArrowVal),
    );
  };
  const decrement = () => {
    inputRef.current?.focus();
    handleChange(
      value !== undefined ? String(value - 1) : String(initArrowVal),
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      <TextField
        {...rest}
        disabled={isDisabled}
        onClick={() => inputRef.current?.focus()}
        inputRef={inputRef}
        className={cx(
          addInputWrapperClassname(isDisabled, rest.isReadOnly, !!error),
          className,
          field,
        )}
        error={error}
        value={value ?? ''}
        InputProps={{
          ...rest.InputProps,
          classes: { input: textInput },
          endAdornment: (
            <>
              {suffix?.text && (
                <Suffix
                  text={suffix.text}
                  className={cx(suffix.class)}
                  onClick={() => inputRef.current?.focus()}
                />
              )}
              <Buttons
                increment={increment}
                decrement={decrement}
                isDisabled={isDisabled}
              />
            </>
          ),
          inputProps,
        }}
        onChange={(event) => handleChange(event.target.value)}
        sx={{
          '.MuiOutlinedInput-input': {
            height: '22px',
          },
        }}
      />
    </div>
  );
}

function Suffix({
  text,
  className,
  onClick,
}: {
  text: string;
  className: string;
  onClick: () => void;
}) {
  return (
    <span className={cx(className, suffixText)} onClick={onClick} role="none">
      {text}
    </span>
  );
}

function Buttons({
  increment,
  decrement,
  isDisabled,
}: {
  increment: () => void;
  decrement: () => void;
  isDisabled?: boolean;
}) {
  const onIncrement = isDisabled ? noop : increment;
  const onDecrement = isDisabled ? noop : decrement;
  return (
    <span className={buttonContainer}>
      <div
        role="button"
        tabIndex={0}
        className={cx(button.up, { [button.disabled]: isDisabled })}
        onClick={onIncrement}
        onKeyPress={onIncrement}
        // Prevent buttons from taking focus away from input on mouse down
        onMouseDown={(e) => e.preventDefault()}
      >
        <UpArrow className={arrow.up} />
      </div>
      <div
        role="button"
        tabIndex={0}
        className={cx(button.down, { [button.disabled]: isDisabled })}
        onClick={onDecrement}
        onKeyPress={onDecrement}
        // Prevent buttons from taking focus away from input on mouse down
        onMouseDown={(e) => e.preventDefault()}
      >
        <DownArrow className={arrow.down} />
      </div>
    </span>
  );
}

function getDigits(num?: number) {
  return typeof num === 'number' ? String(num).length : 0;
}
