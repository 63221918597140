import { type ReactNode, useState } from 'react';

import { useFlatPages } from 'reactQuery';
import type { TaskState, TaskType } from 'shared/generated/grpcGateway/task.pb';
import {
  DEFAULT_ORDER_BY,
  useTaskTypesInfinite,
} from 'shared/hooks/queries/tasks.queries';

import { TabKey } from '../TabKey';
import type { TeamTabAssignee } from './taskFilterContext';
import { TaskFilterContextProvider } from './taskFilterContext';

type Props = {
  children: ReactNode;
};

export function ConfiguredTaskFilterContext({ children }: Props) {
  const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY);
  const [taskTypes, setTaskTypes] = useState<TaskType[]>([]);
  const [teamTabAssignee, setTeamTabAssignee] =
    useState<TeamTabAssignee>('unassigned');
  const allTaskTypes = useFlatPages<TaskType, 'data'>(useTaskTypesInfinite({}));

  const getFilter = (tab: TabKey, state: TaskState) => {
    const filter = { state, types: taskTypes };

    if (tab === TabKey.Team) {
      return {
        ...filter,
        hasAssignee: teamTabAssignee !== 'unassigned',
        // if we have "All" selected on the team tab, we want to
        // limit to relevant task types for the user
        ...(filter.types.length === 0 && {
          types: allTaskTypes.filter((type) => type.isVisible),
        }),
      };
    }

    return filter;
  };

  return (
    <TaskFilterContextProvider
      value={{
        orderBy,
        onOrderByChange: setOrderBy,
        taskTypes,
        onTaskTypesChange: setTaskTypes,
        teamTabAssignee,
        onTeamTabAssigneeChange: setTeamTabAssignee,
        getFilter,
      }}
    >
      {children}
    </TaskFilterContextProvider>
  );
}
