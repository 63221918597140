import type { StandaloneGeneralContact } from 'shared/types/generalContacts.types';

import {
  FETCH_PATIENT_CONTACTS,
  FETCH_PATIENT_CONTACTS_SUCCESS,
} from '../actionCreators';

interface PatientContactsState {
  status: {
    isProcessing: boolean;
    isSuccess: boolean;
  };
  data: StandaloneGeneralContact;
  error: any;
}

const initialState: PatientContactsState = {
  status: {
    isProcessing: true,
    isSuccess: false,
  },
  data: {
    email: '',
    phones: [],
  },
  error: null,
};

function patientContactsReducer(
  state = initialState,
  action: any,
): PatientContactsState {
  switch (action.type) {
    case FETCH_PATIENT_CONTACTS:
      return {
        ...state,
        status: {
          isProcessing: true,
          isSuccess: false,
        },
        data: {
          email: '',
          phones: [],
        },
        error: null,
      };
    case FETCH_PATIENT_CONTACTS_SUCCESS:
      return {
        ...state,
        status: {
          isProcessing: false,
          isSuccess: true,
        },
        data: { ...action.payload },
        error: null,
      };
    default:
      return state;
  }
}

export default patientContactsReducer;
