import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from 'shared/common/Wizard/state';

import { CHF_VISIT_SECTIONS } from '../../metadata';
import { SharedMedReview } from '../../shared/MedReview';
import type { MedReviewFormFields } from '../../shared/MedReview/formConfig';
import { getMedReviewFormConfig } from '../../shared/MedReview/formConfig';
import { chfVisitPath } from '../paths';

export function MedReview() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<MedReviewFormFields>(
    chfVisitPath('/med-review', '/index'),
    getMedReviewFormConfig(intl),
  );

  return (
    <SharedMedReview
      form={form}
      sections={CHF_VISIT_SECTIONS}
      nextPath={chfVisitPath('/general-assessment', '/tobacco-use')}
    />
  );
}
