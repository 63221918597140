import { useState } from 'react';

import { PatientDetailsProvider } from 'pages/patients/PatientProfile/PatientDetailContext';
import type { PatientDetails } from 'shared/types/patient.types';

import { PatientAlertsDashboardPage } from './PatientAlertsDashboardPage';

export function PatientListDashboard() {
  const [patientDetails, setPatientDetails] = useState<PatientDetails>(
    {} as PatientDetails,
  );

  return (
    <PatientDetailsProvider value={{ patientDetails, setPatientDetails }}>
      <PatientAlertsDashboardPage />
    </PatientDetailsProvider>
  );
}
