import { CYCLE_17_INIT_VISIT_SECTIONS } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/cycle17InitialVisit';
import type { DeviceTipsFormFields } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/cycle17InitialVisit/deviceTips/formConfig';
import type { SectionStepsState } from 'shared/common/Wizard/state';
import { getWizardStepValues } from 'shared/common/Wizard/state';
import type { FeatureFlagSet } from 'shared/types/featureFlags.types';

import { MarkdownBuilder } from '../../../engine/MarkdownBuilder';
import { sectionHasData } from '../../util';

export function initialVisitDeviceTips(
  data: SectionStepsState,
  flags: FeatureFlagSet,
) {
  const deviceTipsData = getWizardStepValues(
    data,
    CYCLE_17_INIT_VISIT_SECTIONS,
    '/device-tips',
    '/index',
  )<DeviceTipsFormFields>();

  // this section does not exist for the old initial visit
  if (!flags.cycle17InitialCnVisit || !sectionHasData(deviceTipsData)) {
    return '';
  }

  const md = new MarkdownBuilder();

  md.h3('Device Tips');
  md.field(
    'Patient is receiving SMS vital receipts',
    deviceTipsData?.smsReceived,
  );

  return md.toString();
}
