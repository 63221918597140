import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TextField } from 'deprecated/mui';
import { Modal } from 'shared/common/Modal';
import { Button } from 'shared/tempo/atom/Button';

type MedicalInfoDialogProps = {
  isSaving: boolean;
  onSave: (text: string) => void;
  onClose: () => void;
};

export function MedicalInfoDialog({
  isSaving,
  onSave,
  onClose,
}: MedicalInfoDialogProps) {
  const [text, setText] = useState<string>('');

  return (
    <Modal open onClose={onClose} disabled={isSaving}>
      <Modal.Header
        title={<FormattedMessage defaultMessage="New medical info entry" />}
      />
      <Modal.Body>
        <label htmlFor="medical-info-entry">
          <FormattedMessage defaultMessage="Description of event" />
        </label>
        <TextField
          autoFocus
          fullWidth
          multiline
          id="medical-info-entry"
          margin="dense"
          value={text}
          onChange={(event) => setText(event.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="cancel-medical-entry"
          type="button"
          variant="secondary"
          onPress={onClose}
        >
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button
          id="save-medical-entry"
          isDisabled={!text.trim().length}
          isProcessing={isSaving}
          type="button"
          variant="primary"
          onPress={() => onSave(text.trim())}
        >
          <FormattedMessage defaultMessage="Save" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
