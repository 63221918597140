import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { AdditionalActions } from 'shared/common/AdditionalActions';
import type { Task } from 'shared/tasking/types';
import { Menu } from 'shared/tempo/@labs/molecule/Menu';

import { DismissTaskModal } from './DismissTaskModal';
import { EditTaskModal } from './EditTaskModal';

enum ActionTypes {
  DismissTask = 'dismiss_task',
  EditTask = 'edit_task',
}

type Props = {
  task: Task;
  isDisabled?: boolean;
};

enum ModalType {
  Dismiss = 0,
  Edit = 1,
}

export function OpenActions({ task, isDisabled }: Props) {
  const [modal, setModal] = useState<Nullable<ModalType>>(null);
  return (
    <>
      <AdditionalActions
        isDisabled={isDisabled}
        onAction={(key) => {
          if (key === ActionTypes.DismissTask) {
            setModal(ModalType.Dismiss);
          } else if (key === ActionTypes.EditTask) {
            setModal(ModalType.Edit);
          }
        }}
      >
        <Menu>
          <Menu.Item key={ActionTypes.DismissTask}>
            <FormattedMessage defaultMessage="Dismiss task" />
          </Menu.Item>
          <Menu.Item key={ActionTypes.EditTask}>
            <FormattedMessage defaultMessage="Edit" />
          </Menu.Item>
        </Menu>
      </AdditionalActions>
      <DismissTaskModal
        task={task}
        open={modal === ModalType.Dismiss}
        onClose={() => setModal(null)}
      />
      <EditTaskModal
        task={task}
        open={modal === ModalType.Edit}
        onClose={() => setModal(null)}
      />
    </>
  );
}
