import { REGULAR_VISIT_SECTIONS } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/regularVisit';
import type { MedReviewFormFields } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/MedReview/formConfig';
import type { SectionStepsState } from 'shared/common/Wizard/state';
import { getWizardStepValues } from 'shared/common/Wizard/state';
import type { PatientMedications } from 'shared/generated/grpcGateway/medication.pb';
import type { FeatureFlagSet } from 'shared/types/featureFlags.types';

import { cnMedicationReview } from '../../shared/cnMedicationReview';

export function regularVisitMedicationReview(
  data: SectionStepsState,
  noteId: number,
  meds: PatientMedications,
  flags: FeatureFlagSet,
) {
  const medReviewData = getWizardStepValues(
    data,
    REGULAR_VISIT_SECTIONS,
    '/med-review',
    '/index',
  )<MedReviewFormFields>();

  return cnMedicationReview({ noteId, meds, medReviewData, flags });
}
