import { routeSpinner } from 'App.css';
import { Suspense, lazy } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { ProductionPreviewBanner } from 'ProductionPreviewBanner';
import { settings } from 'config';
import { useRegisterMonitoredUser } from 'monitoring.utils';
import { ForgotPasswordPage } from 'pages/auth/ui/ForgotPasswordPage';
import ForgotPasswordPrompt from 'pages/auth/ui/ForgotPasswordPrompt';
import { LoginPage } from 'pages/auth/ui/LoginPage';
import { LoginPasswordPage } from 'pages/auth/ui/LoginPasswordPage';
import OAuthLandingPage from 'pages/auth/ui/OAuthLandingPage';
import { ResetPasswordPage } from 'pages/auth/ui/ResetPasswordPage';
import ResetPasswordPrompt from 'pages/auth/ui/ResetPasswordPrompt';
import { UserConfirmationPage } from 'pages/auth/ui/UserConfirmationPage';
import { Routes } from 'pages/routes';
import { PrivateRoute } from 'pages/routes/PrivateRoute';
import {
  useForceClientUpdate,
  useIdleForceLogout,
  useLDClientIdentify,
} from 'shared/hooks';
import { Spinner } from 'shared/tempo/atom/Spinner';

// Code-split Routes Meant Only To Be Loaded via Iframe
const ZendeskAppRoutes = lazy(
  () => import('apps/zendesk/src/routes/ZendeskAppRoutes'),
);
const TwilioFlexRoutes = lazy(
  () => import('apps/twilio-flex/src/routes/TwilioFlexRoutes'),
);

export const App = () => {
  useLDClientIdentify();
  useIdleForceLogout();
  useForceClientUpdate();
  useRegisterMonitoredUser();

  return (
    <Router>
      <ProductionPreviewBanner />
      {/* Suspense for lazy loaded routes */}
      <Suspense fallback={<Spinner className={routeSpinner} />}>
        <Switch>
          <Route path="/login" component={LoginPage} exact />
          <Route path="/login/password" component={LoginPasswordPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route
            path="/forgot-password-success"
            component={ForgotPasswordPrompt}
          />
          <Route path="/reset-password" component={ResetPasswordPage} />
          <Route
            path="/reset-password-success"
            component={ResetPasswordPrompt}
          />
          <Route path="/confirm-sign-up" component={UserConfirmationPage} />
          <Route path="/oauth2/callback" component={OAuthLandingPage} />
          <Route path="/zendesk-app" component={ZendeskAppRoutes} />
          <Route path="/twilio-flex" component={TwilioFlexRoutes} />

          <PrivateRoute
            ComponentRoutes={Routes}
            environment={settings.NODE_ENV}
          />
        </Switch>
      </Suspense>
    </Router>
  );
};
