import { logger } from 'logger';
import type { RpmCondition } from 'shared/types/clinicalprofile.types';
import { Condition } from 'shared/types/clinicalprofile.types';

export function getConditionKeys(conditions?: Condition[]) {
  const conditionNames = conditions ?? Object.keys(CONDITION_KEYS);
  return Object.entries(CONDITION_KEYS)
    .filter(([conditionName]) => conditionNames.includes(conditionName))
    .map(([, conditionKey]) => conditionKey);
}

export function getConditionInputs(conditions: RpmCondition[]) {
  return conditions.reduce(
    (result, condition) => {
      const conditionKey = CONDITION_KEYS[condition];
      if (CONDITION_KEYS[condition]) {
        return {
          ...result,
          [conditionKey]: true,
        };
      }

      logger.error(`No corresponding condition key for ${condition}`);
      return result;
    },
    {} as { [key: string]: boolean },
  );
}

export function getHiddenConditionPropertiesUiSchema() {
  return Object.fromEntries(
    getConditionKeys().map((conditionProperty) => [
      conditionProperty,
      {
        'ui:widget': 'hidden',
      },
    ]),
  );
}

const CONDITION_KEYS = {
  [Condition.CHF]: 'has_chf',
  [Condition.Hypertension]: 'has_hypertension',
  [Condition.TypeTwoDiabetes]: 'has_type_2_diabetes',
  [Condition.COPD]: 'has_copd',
  [Condition.Generic]: 'has_generic',
};
