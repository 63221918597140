import { format, parseISO } from 'date-fns';
import sortBy from 'lodash/sortBy';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import type { Appointment } from 'shared/generated/grpcGateway/scheduling.pb';
import { Select } from 'shared/tempo/@labs/molecule/Select';

import { appts } from './PublishConfirmationDialog.css';

type Props = {
  selectedId?: Maybe<string>;
  appointments: Appointment[];
  onChange: (appointmentId: string | undefined) => void;
};

export const NO_ASSOCIATED_APPT = '__no_associated_appointment__';

export function AppointmentsSelect({
  selectedId,
  onChange,
  appointments,
}: Props) {
  const intl = useIntl();
  const sortedAppts = sortBy(appointments, ({ startTime }) =>
    startTime ? parseISO(startTime) : null,
  );
  return (
    <Select
      className={appts.select}
      selectedKey={!selectedId ? NO_ASSOCIATED_APPT : selectedId}
      onSelectionChange={(key) => onChange(`${key}`)}
      items={[
        {
          key: NO_ASSOCIATED_APPT,
          label: intl.formatMessage({
            defaultMessage: 'No Associated Appointment',
          }),
        },
        ...sortedAppts.map((appt) => ({
          key: appt.name,
          label: formatLabel(appt, intl),
        })),
      ]}
      aria-label={intl.formatMessage({ defaultMessage: 'Appointments' })}
    >
      {(item) => <Select.Option key={item.key}>{item.label}</Select.Option>}
    </Select>
  );
}

function formatLabel(appt: Appointment, intl: IntlShape) {
  const startDate = appt.startTime ? parseISO(appt.startTime) : '';
  const formattedDate = startDate
    ? format(startDate, 'MM/dd/yyyy hh:mm a')
    : 'N/A';

  return intl.formatMessage(
    {
      defaultMessage: `{apptDate} - {apptType} {careProviderName, select, NOT_FOUND {{careProviderEmail, select, NOT_FOUND {} other {with {careProviderEmail}}}} other {with {careProviderName}}}`,
    },
    {
      formattedDate,
      apptType: appt.appointmentType,
      apptDate: formattedDate,
      careProviderName: appt.careProvider || 'NOT_FOUND',
      careProviderEmail: appt.careProviderEmail || 'NOT_FOUND',
    },
  );
}
