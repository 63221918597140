import type { FieldValues } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { logger } from 'logger';
import { ChevronRight } from 'shared/assets/svgs';
import { Button } from 'shared/tempo/atom/Button';

import type { StepFormValues } from '../state';
import { getIndex, useDisplayedSections } from '../state/sectionUtils';
import type { DiscriminatedFormProps, SubmitBtnRenderFn } from './Step';

type SubmitButtonProps<TFieldValues extends FieldValues> = {
  submitButton?: SubmitBtnRenderFn<TFieldValues> | false;
  submitWithTiming: (
    isComplete: boolean,
    overrideTimerRunning?: boolean,
  ) => TFieldValues | StepFormValues;
  isDisabled?: boolean;
} & DiscriminatedFormProps<TFieldValues>;

export function SubmitButton<TFieldValues extends FieldValues>({
  submitButton,
  submitWithTiming,
  isDisabled,
  ...formProps
}: SubmitButtonProps<TFieldValues>) {
  const { pathname: fullStepPath } = useLocation();
  const history = useHistory();
  const displayedSections = useDisplayedSections();

  const { form, onNext } = formProps;
  function defaultOnNext() {
    const currentPath = fullStepPath.split('/');

    const currentBasePath = `/${currentPath[1]}`;
    const currentSectionIndex = getIndex(currentBasePath, displayedSections);
    if (currentSectionIndex < 0) {
      logger.error(`Section ${currentBasePath} not found`);
      return;
    }

    const currentStepPath = `/${currentPath[2]}`;
    const { steps } = displayedSections[currentSectionIndex];
    const currentStepIndex = steps.findIndex(
      (step) => step.path === currentStepPath,
    );

    if (currentStepIndex < 0) {
      logger.error(`Step ${currentStepPath} not found`);
      return;
    }

    if (currentStepIndex === steps.length - 1) {
      // If last step, navigate to next section
      const nextSection = displayedSections[currentSectionIndex + 1];
      history.push(`${nextSection.basePath}${nextSection.steps[0].path}`);
    } else {
      // Otherwise, navigate to next step
      const nextStep = steps[currentStepIndex + 1];
      history.push(`${currentBasePath}${nextStep.path}`);
    }
  }

  function onNextOrDefault(data?: TFieldValues) {
    if (onNext && form && data) {
      onNext(data);
      return;
    }

    if (onNext && !form) {
      onNext();
      return;
    }

    defaultOnNext();
  }

  if (submitButton) {
    return (
      <>
        {submitButton({
          submitWithTiming,
          ...formProps,
          onNext: onNextOrDefault,
        })}
      </>
    );
  }

  return (
    <Button
      size="small"
      type="submit"
      onPress={() => {
        const storeValues = submitWithTiming(true);
        onNextOrDefault(storeValues as TFieldValues);
      }}
      isDisabled={(!!form && !form.formState.isValid) || isDisabled}
    >
      <FormattedMessage defaultMessage="Next" />
      <Button.Icon>
        <ChevronRight />
      </Button.Icon>
    </Button>
  );
}
