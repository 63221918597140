import type { VitalsAlert } from 'shared/types/alert.types';
import type { AllTagType } from 'shared/types/tagsAndThreshold.types';

export function containsVerifiedAlert(alerts: VitalsAlert[]) {
  return alerts.some((alert) => isAlertVerified(alert));
}

export function isAlertVerified(alert: VitalsAlert) {
  return Boolean(alert.alert_verified_tags?.length);
}

export function isAlertTagVerified(alert: VitalsAlert, tag: AllTagType) {
  return alert.alert_verified_tags?.includes(tag);
}
