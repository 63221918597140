import type { UseInfiniteQueryOptions } from 'react-query';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';

import type { PatientNotificationAuditSchema } from 'shared/generated/api/notification';
import {
  NotificationAuditService,
  WeeklySummaryEmailService,
} from 'shared/generated/api/notification';
import type {
  PaginatedData,
  PaginationMetadata,
} from 'shared/types/pagination.types';

const NOTIFICATIONS_AUDIT_KEY_BASE = [
  'notification',
  'api',
  'v1',
  'notification-audit',
] as const;

const notificationsAuditKeys = {
  infinite: (patientId: string, params: NotificationsAuditQueryParams = {}) =>
    [...NOTIFICATIONS_AUDIT_KEY_BASE, patientId, params, 'infinite'] as const,
};

export type NotificationAudit = ElementType<
  PatientNotificationAuditSchema['notifications']
>;

export type NotificationsAuditQueryParams = {
  notificationType?: 'SMS' | 'EMAIL';
  notificationStatus?: string;
  page?: number;
  pageSize?: number;
  sortBy?: SortableNotificationsAuditOrderFields;
  orderBy?: 'asc' | 'desc';
};

export type SortableNotificationsAuditOrderFields =
  | 'timestamp'
  | 'notification_type'
  | 'notification_status';

export function useInfinitePatientNotificationsAudit(
  patientId: string,
  params: NotificationsAuditQueryParams = {},
  config: UseInfiniteQueryOptions<PaginatedData<NotificationAudit>> = {},
) {
  return useInfiniteQuery<PaginatedData<NotificationAudit>>(
    notificationsAuditKeys.infinite(patientId, params),
    async (ctx) => {
      const resp =
        await NotificationAuditService.getNotificationApiV1NotificationAudit(
          patientId,
          params.notificationType,
          params.notificationStatus,
          ctx.pageParam ?? params.page,
          params.pageSize,
          params.sortBy,
          params.orderBy,
        );
      return {
        metadata: { ...(resp.pagination as PaginationMetadata) },
        data: resp.notifications || [],
      };
    },
    config,
  );
}

export function useResendWeeklyEmailSummary({
  onSuccess,
}: {
  onSuccess?: () => void;
}) {
  const client = useQueryClient();
  return useMutation(
    (patientId: string) =>
      WeeklySummaryEmailService.postNotificationApiV1CommunicationsWeeklySummaryEmail(
        { patient_id: patientId },
      ),
    {
      onSuccess: () => {
        onSuccess?.();
        setTimeout(() => {
          client.invalidateQueries(NOTIFICATIONS_AUDIT_KEY_BASE);
        }, 5000);
      },
    },
  );
}
