import { useState } from 'react';

import type { EHRCredentialsSchema } from 'shared/generated/api/pms';
import { useGetEhrHealthSystemProvider } from 'shared/hooks/queries';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import type { Hospital } from 'shared/types/hospital.types';
import {
  goToPatientChart,
  isRpaChromeExtensionEnabled,
} from 'shared/utils/rpa-helpers';

export function useGoToPatientChart(mrn: string | undefined) {
  const { currentUserId: providerId } = useCurrentUser();
  const [hospital, setHospital] = useState<Hospital>();
  const healthSystemId = hospital?.health_system_id.toString();
  const [shouldFetchProvider, setShouldFetchProvider] = useState(false);
  const [isCredentialsModalOpen, setIsCredentialsModalOpen] = useState(false);
  const enabled = Boolean(
    providerId && healthSystemId && shouldFetchProvider && hospital,
  );

  const onSuccess = ({ username, password }: EHRCredentialsSchema) => {
    setShouldFetchProvider(false);

    if (!hospital) {
      return;
    }

    goToPatientChart(mrn, hospital, { username, password });
  };

  const onError = () => {
    setShouldFetchProvider(false);
    setIsCredentialsModalOpen(true);
  };

  useGetEhrHealthSystemProvider(providerId, healthSystemId ?? '', {
    onSuccess,
    onError,
    enabled,
    retry: false,
  });

  return {
    isEnabled: isRpaChromeExtensionEnabled(),
    isCredentialsModalOpen,
    setIsCredentialsModalOpen,
    goToChart: (patientHospital: Hospital) => {
      setHospital(patientHospital);
      setShouldFetchProvider(true);
    },
  };
}
