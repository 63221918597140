import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import first from 'lodash/first';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFlatPages } from 'reactQuery';
import CheckCircleIcon from 'shared/assets/svgs/circle-success.svg?react';
import { LoadingPlaceholder } from 'shared/common/LoadingPlaceholder';
import {
  useInfinitePatientNotificationsAudit,
  usePatientDetails,
  useResendWeeklyEmailSummary,
} from 'shared/hooks/queries';
import { Button } from 'shared/tempo/atom/Button';
import { useToaster } from 'shared/tempo/molecule/Toast';

import { NotificationAuditStatusTag } from './NotificationAuditStatusTag';
import {
  bodyText,
  resendButton,
  statusContainer,
  successContainer,
} from './WeeklySummary.css';

type Props = {
  patientId: string;
};

export function WeeklySummary({ patientId }: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const infiniteQuery = useInfinitePatientNotificationsAudit(patientId, {
    sortBy: 'timestamp',
    orderBy: 'desc',
    notificationType: 'EMAIL',
  });
  const [didResend, setDidResend] = useState(false);

  const { isLoading: isLoadingComms } = infiniteQuery;
  const notifications = useFlatPages(infiniteQuery);
  const { isLoading: isLoadingPatient, data: patient } = usePatientDetails(
    patientId || '',
    true,
    Boolean(patientId),
  );

  const weeklySummaryEmails = notifications.filter(
    (n) => n.subject?.includes('your weekly health summary is here'),
  );
  const patientTimezone = patient?.patient?.timeZone;
  const isLoading = isLoadingComms || isLoadingPatient;

  const mostRecentSummary = first(weeklySummaryEmails);
  const mostRecentSummaryTimestamp = mostRecentSummary?.timestamp;
  const mostRecentSummarySentAt =
    mostRecentSummaryTimestamp && patientTimezone
      ? formatInTimeZone(
          parseISO(`${mostRecentSummaryTimestamp}Z`),
          patientTimezone,
          "MM/dd/yyyy 'at' h:mma z",
        )
      : intl.formatMessage({ defaultMessage: 'N/A' });

  const { mutate: resendSummary, isLoading: isSendingSummary } =
    useResendWeeklyEmailSummary({
      onSuccess: () => {
        toaster.success(
          intl.formatMessage({
            defaultMessage: 'Patient "Weekly Summary Email" has been resent',
          }),
        );
        setDidResend(true);
      },
    });

  if (didResend) {
    return (
      <div className={successContainer}>
        <CheckCircleIcon />
        <FormattedMessage defaultMessage="Attempted a Weekly email summary resend, to track the status, please view the Communications > Outbound tab in the Support Modal." />
      </div>
    );
  }

  return (
    <LoadingPlaceholder isLoading={isLoading}>
      <div className={bodyText}>
        {mostRecentSummaryTimestamp ? (
          <>
            <div>
              <FormattedMessage
                defaultMessage="Last weekly summary email was sent on <strong>{summarySentAt}</strong>."
                values={{
                  summarySentAt: mostRecentSummarySentAt,
                  strong: (t) => <strong>{t}</strong>,
                }}
              />
            </div>
            <div>
              <FormattedMessage
                defaultMessage="Summary Email status is: {status}"
                values={{
                  status: (
                    <span className={statusContainer}>
                      <NotificationAuditStatusTag
                        status={mostRecentSummary?.notification_status}
                      />
                    </span>
                  ),
                }}
              />
            </div>
          </>
        ) : (
          <FormattedMessage defaultMessage="Could not find most recent weekly summary sent date." />
        )}
      </div>
      <div className={resendButton}>
        <Button
          variant="secondary"
          onPress={() => resendSummary(patientId)}
          isProcessing={isSendingSummary}
        >
          <FormattedMessage defaultMessage="Resend Weekly Summary" />
        </Button>
      </div>
    </LoadingPlaceholder>
  );
}
