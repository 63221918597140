import type { ValidationError, ValidatorResult } from 'jsonschema';

import { MEDICATION_CLASSES } from 'pages/patients/patientDetails/ui/Notes/NoteEditor/MedicationsForm/formattedMessages';

// TODO: fix different rjsf labels when medication plan is titration
function getRjsfLabelName(rjsfLabel: string) {
  if (rjsfLabel === 'start_date') {
    return 'titration_date';
  }
  if (rjsfLabel === 'new_dose') {
    return 'med_dose';
  }
  return rjsfLabel;
}

function hasValidationErrorString(
  rjsfLabel: string,
  validationErrorTitle: string,
  path: (string | number)[],
  medicationClass: string | undefined,
) {
  const adjustedRjsfLabel = getRjsfLabelName(rjsfLabel);
  return (validationErrorTitle === rjsfLabel ||
    validationErrorTitle === adjustedRjsfLabel ||
    (path.length === 1 && path[0] === rjsfLabel)) &&
    (!medicationClass || path.includes(medicationClass))
    ? rjsfLabel
    : null;
}

export function hasValidationErrorFormContext(
  formContext: ValidatorResult,
  rjsfLabel: string,
  id?: string,
) {
  const { errors: validationErrors } = formContext;
  // TODO: See if this could be made more generic?
  const medicationClass = id && getMedicationClass(id);

  const error = validationErrors
    ? (validationErrors as ValidationError[]).find((validationError) => {
        const { argument: validationErrorTitle, path } = validationError;

        return hasValidationErrorString(
          rjsfLabel,
          validationErrorTitle,
          path,
          medicationClass,
        );
      })
    : null;
  return !!error;
}

function getMedicationClass(id: string) {
  return Object.keys(MEDICATION_CLASSES).find(
    (medicationClass) => id?.includes(medicationClass),
  );
}
