import { Stack } from 'deprecated/mui';
import { Skeleton } from 'shared/tempo/atom/Skeleton';

export function SkeletonInfoSummary() {
  return (
    <Stack direction="column" spacing="14px" width="100%">
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="80%" />
    </Stack>
  );
}
