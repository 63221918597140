import cx from 'classnames';
import { useIntl } from 'react-intl';
import type { WrappedFieldProps } from 'redux-form';

import { FormControl, FormHelperText } from 'deprecated/mui';
import { logger } from 'logger';
import type { SelectChangeEvent } from 'shared/common/Select';
import { Option, Select } from 'shared/common/Select';
import { Tag } from 'shared/common/Tag';

import { NoteLabelTag } from './NoteLabelTag';
import styles from './NoteLabelsDropdown.module.scss';
import { useFetchNoteLabels } from './hooks/useFetchNoteLabels.hook';

type NoteLabelsDropdownProps = {
  selectedLabels: number[];
  handleChangeMultiple: (e: SelectChangeEvent<number[]>) => void;
  hasError: boolean;
} & Partial<WrappedFieldProps>;

export const NoteLabelsDropdown = ({
  selectedLabels,
  handleChangeMultiple,
  meta,
  hasError,
}: NoteLabelsDropdownProps) => {
  const intl = useIntl();

  const error = meta?.error;
  const touched = meta?.touched;

  const { noteLabels, isFetchingNoteLabels } = useFetchNoteLabels();

  return (
    <FormControl fullWidth style={{ marginTop: '5px' }}>
      <Select
        hasError={hasError}
        classes={{
          select: cx({
            [styles['note-labels-select']]: selectedLabels.length > 0,
            [styles['error-style']]: hasError,
          }),
        }}
        fullWidth
        multiple
        onChange={handleChangeMultiple}
        placeholder={intl.formatMessage({
          defaultMessage: 'Add labels',
        })}
        value={selectedLabels}
        renderValue={(values) =>
          values.map((value) => {
            const noteLabel = noteLabels.find((label) => label.id === value);
            if (!noteLabel && !isFetchingNoteLabels) {
              logger.error(`Note label with id ${value} does not exist`);
            }
            return noteLabel ? (
              <NoteLabelTag key={noteLabel.id} noteLabel={noteLabel} />
            ) : null;
          })
        }
      >
        {noteLabels.map((label) => (
          <Option key={label.id} value={label.id}>
            <Tag backgroundColor={label.rgb_hex}>{label.name}</Tag>
          </Option>
        ))}
      </Select>

      {touched && error && (
        <FormHelperText error>{error.message}</FormHelperText>
      )}
    </FormControl>
  );
};
