import isEmpty from 'lodash/isEmpty';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

import type { TNoteBodyRTF } from 'shared/types/note.types';
import { sanitizeHTML } from 'shared/utils/sanitizeHtml';

import type { EncounterModuleInstance } from '../Notes.types';
import { EncounterModuleId } from '../Notes.types';
import { getEncounterTypeInstance } from '../utils/encounterTypeUtils';
import { matchSignature } from '../utils/matchSignature';
import { InputsPreview } from './InputsPreview';
import { RtfBodyPreview } from './RtfBodyPreview';
import { VisitLayoutNoteBodyPreview } from './VisitLayoutNoteBodyPreview';
import { formatEncounterTypeInputs } from './formatEncounterTypeInputs.util';

export type NoteBodyPreviewProps = {
  encounterModuleInstances?: EncounterModuleInstance[];
  // TODO: Remove when switch over to V2 Rich Text Editor
  // https://cadencerpm.atlassian.net/browse/PLAT-4330
  rtfBody: Maybe<TNoteBodyRTF>;
  bodyHtml: Nullable<string>;
  body?: string;
  hasInvalidMarkdown?: boolean;
};

export const NoteBodyPreview = ({
  encounterModuleInstances,
  rtfBody,
  bodyHtml,
  body,
  hasInvalidMarkdown = false,
}: NoteBodyPreviewProps) => {
  const encounterTypeInstance =
    encounterModuleInstances &&
    getEncounterTypeInstance(encounterModuleInstances);
  const createdWithVisitLayout = encounterTypeInstance?.inputs.visit_layout;
  if (createdWithVisitLayout) {
    if (!encounterTypeInstance?.inputs.type_of_encounter) {
      return <FormattedMessage defaultMessage="Preview note" />;
    }

    if (!hasInvalidMarkdown) {
      return (
        <Markdown remarkPlugins={[remarkBreaks, remarkGfm]}>{body}</Markdown>
      );
    }

    // Show electronic signature automatically appended by backend. For visit layout notes, we need to grab the signature from `body` field because we are no longer using the `rtf_body` field
    const signature = matchSignature(body);
    return (
      <VisitLayoutNoteBodyPreview
        encounterModuleInstances={encounterModuleInstances}
        signature={signature}
      />
    );
  }

  const rtfBodyPreview = getRtfBodyPreview(rtfBody, bodyHtml);
  const instancePreviews = getInstancePreviews(encounterModuleInstances);
  const isEmptyNoteBody = !rtfBodyPreview && isEmpty(instancePreviews);
  return isEmptyNoteBody ? (
    <FormattedMessage defaultMessage="Preview note" />
  ) : (
    <>
      {instancePreviews}
      {rtfBodyPreview}
    </>
  );
};

function getInstancePreviews(
  encounterModuleInstances?: EncounterModuleInstance[],
) {
  const encounterTypeModule = getEncounterTypeInstance(
    encounterModuleInstances || [],
  );
  const encounterTypeInputs = encounterTypeModule?.inputs;

  const encounterTypePreview =
    encounterTypeInputs && !isEmpty(encounterTypeInputs)
      ? formatEncounterTypeInputs(encounterTypeModule?.inputs)
      : null;

  const otherInstancePreviews = encounterModuleInstances
    ?.filter(
      ({ encounter_module_id }) =>
        encounter_module_id !== EncounterModuleId.EncounterType,
    )
    .map(({ encounter_module_id, inputs }) => (
      <Fragment key={encounter_module_id}>
        <InputsPreview
          encounterTypeInstance={encounterTypeModule}
          encounterModuleId={encounter_module_id}
          inputs={inputs}
        />
      </Fragment>
    ))
    .filter(Boolean);

  const result = otherInstancePreviews || [];
  if (encounterTypePreview) {
    result.unshift(encounterTypePreview);
  }
  return result;
}

function getRtfBodyPreview(
  rtfBody: TNoteBodyRTF | null | undefined,
  bodyHtml: Nullable<string>,
) {
  if (bodyHtml) {
    return (
      <div
        // Using DOMPurify to sanitize HTML and prevent XSS attacks
        // "If you are certain the content passed to dangerouslySetInnerHTML is sanitized HTML you can disable this rule." https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/no-danger.md
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: sanitizeHTML(bodyHtml),
        }}
      />
    );
  }

  return <RtfBodyPreview rtfBody={rtfBody} />;
}
