import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from 'shared/common/Wizard/state';
import { useShowVitalsEducation } from 'shared/common/Wizard/state/sectionUtils';

import { useCNFormContext } from '../../../../CNFormContext';
import { INIT_VISIT_SECTIONS } from '../../../metadata';
import type { SecondarySymptomsFormFields } from '../../../shared/SecondarySymptoms';
import {
  SharedSecondarySymptoms,
  getSecondarySymptomsFormConfig,
} from '../../../shared/SecondarySymptoms';
import { initVisitPath } from '../../paths';

export function SecondarySymptoms() {
  const intl = useIntl();
  const { program } = useCNFormContext();
  const form = useWizardFormFromConfig<SecondarySymptomsFormFields>(
    initVisitPath('/symptoms', '/secondary'),
    getSecondarySymptomsFormConfig(intl, program),
  );

  const showVitalsEducation = useShowVitalsEducation();

  return (
    <SharedSecondarySymptoms
      form={form}
      sections={INIT_VISIT_SECTIONS}
      nextPath={
        showVitalsEducation
          ? initVisitPath('/product-support', '/feedback')
          : initVisitPath('/product-support', '/intro')
      }
    />
  );
}
