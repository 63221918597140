import cx from 'classnames';
import type { HTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';

import { color as colors } from 'shared/tempo/theme';

import { avatar } from './Avatar.css';
import { AvatarContextProvider } from './avatarContext';

type Props = {
  color?: string;
  size?: 'default' | 'small';
  className?: string;
  children?: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const Avatar = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      className,
      size = 'default',
      color = colors.Brand.Primary['Bright Blue'],
      ...props
    },
    ref,
  ) => (
    <AvatarContextProvider value={{ size }}>
      <div
        {...props}
        ref={ref}
        style={{ backgroundColor: color }}
        className={cx(avatar[size], className)}
      >
        {children}
      </div>
    </AvatarContextProvider>
  ),
);
