import cx from 'classnames';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import type { AriaButtonProps } from 'react-aria';

import { Button } from 'shared/tempo/atom/Button';

import {
  iconButton,
  iconButtonActive,
  iconButtonFocused,
  iconButtonSize,
} from './IconButton.css';
import './icon.css';

type Props = {
  className?: string;
  children: ReactNode;
  size?: 'large' | 'small';
  variant?: 'primary' | 'secondary' | 'tertiary';
  isProcessing?: boolean;
} & AriaButtonProps<'button'>;

export const IconButton = forwardRef<HTMLButtonElement, Props>(
  (
    { className, children, size = 'large', variant = 'primary', ...props },
    forwardedRef,
  ) => (
    <Button
      {...props}
      ref={forwardedRef}
      variant={variant}
      className={cx(iconButtonSize[size], className)}
      classes={{
        default: iconButton,
        active: iconButtonActive,
        focused: iconButtonFocused,
      }}
    >
      <Button.Icon>{children}</Button.Icon>
    </Button>
  ),
);
