import type { AxiosError, AxiosResponse } from 'axios';
import noop from 'lodash/noop';
import { useIntl } from 'react-intl';
import type { UseInfiniteQueryOptions, UseQueryOptions } from 'react-query';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import type {
  MessageTemplateListSchema,
  MessageTemplateSchema,
} from 'shared/generated/api/notification';
import { MessageTemplateService } from 'shared/generated/api/notification';
import { useToaster } from 'shared/tempo/molecule/Toast';
import type { Hospital } from 'shared/types/hospital.types';
import type { PaginatedData } from 'shared/types/pagination.types';
import type {
  CompositeSortDirection,
  CompositeSortKey,
  SortDirection,
} from 'shared/types/sorting.types';
import Session from 'shared/utils/session';

import type { CernerProvider, EpicProvider } from './ehr-clinic.queries';
import {
  useInvalidateCareProvider,
  useInvalidateProviderHospitals,
} from './providers.queries';

type PatientHospitalsParams = Partial<{
  page: number;
  pageSize: number;
  name: string;
  sortBy: 'created_at' | 'updated_at';
  orderBy: SortDirection;
  hospitalId: string;
}>;

const getPatientHospitalsParams = (params: PatientHospitalsParams) => ({
  ...(params.page && { page: params.page }),
  ...(params.pageSize && { page_size: params.pageSize }),
  ...(params.sortBy && { sort_by: params.sortBy }),
  ...(params.orderBy && { order_by: params.orderBy }),
  ...(params.hospitalId && { hospital_id: params.hospitalId }),
  ...(params.name && { name: params.name }),
});

const getPatientHospitalsQueryKeyBase = (patientId: string) => [
  'pms',
  'api',
  'v1',
  'patients',
  patientId,
  'hospitals',
];

export function usePatientHospitals(
  { patientId, ...params }: PatientHospitalsParams & { patientId: string },
  options: UseQueryOptions<PaginatedData<Hospital, 'hospitals'>> = {},
) {
  return useQuery<PaginatedData<Hospital, 'hospitals'>>(
    [
      ...getPatientHospitalsQueryKeyBase(patientId),
      getPatientHospitalsParams(params),
    ],
    { ...options, enabled: (options.enabled ?? true) && Boolean(patientId) },
  );
}

type NoContentResponse = '';
export type HospitalAssociationPayload = Partial<{
  mrn: string;
  is_primary: boolean;
}>;

export function useUpdatePatientHospitalAssociation(
  patientId: string,
  onUpdate: () => void = noop,
) {
  const client = useQueryClient();

  return useMutation(
    ({
      payload,
      hospitalId,
    }: {
      payload: HospitalAssociationPayload;
      hospitalId: string;
    }) =>
      Session.Api.put<NoContentResponse>(
        `/pms/api/v1/hospitals/${hospitalId}/patients/${patientId}`,
        payload,
      ),
    {
      onSuccess: () => {
        client.invalidateQueries(getPatientHospitalsQueryKeyBase(patientId));
        onUpdate();
      },
    },
  );
}

type CreateHospitalAssociationVars = {
  payload: HospitalAssociationPayload;
  hospitalId: string;
};

export function useCreatePatientHospitalAssociation(
  patientId: string,
  onSuccess: () => void = noop,
) {
  const client = useQueryClient();

  return useMutation<
    AxiosResponse<Hospital>,
    AxiosError<{ name?: string }>,
    CreateHospitalAssociationVars
  >(
    ({ payload, hospitalId }: CreateHospitalAssociationVars) =>
      Session.Api.post<Hospital>(
        `/pms/api/v1/hospitals/${hospitalId}/patients/${patientId}`,
        payload,
      ),
    {
      onSuccess: () => {
        client.invalidateQueries(getPatientHospitalsQueryKeyBase(patientId));
        onSuccess();
      },
    },
  );
}

export function useRemovePatientFromHospital(
  patientId: string,
  onSuccess: () => void = noop,
) {
  const client = useQueryClient();

  return useMutation(
    (hospitalId: string) =>
      Session.Api.delete(
        `/pms/api/v1/hospitals/${hospitalId}/patients/${patientId}`,
      ),
    {
      onSuccess: () => {
        client.invalidateQueries(getPatientHospitalsQueryKeyBase(patientId));
        onSuccess();
      },
    },
  );
}

const HOSPITALS_QUERY_KEY_BASE = ['pms', 'api', 'v1', 'hospitals'];

type HospitalsParams = Partial<{
  page: number;
  pageSize: number;
  name: string;
  sortBy: SortableHospitalFields;
  orderBy: SortDirection;
  id: string;
  marketId?: string;
}>;

type HospitalInfiniteParams = {
  sort_by?: CompositeSortKey<SortableHospitalFields>;
  order_by?: CompositeSortDirection;
  market_id?: string;
};

const getHospitalsParams = (params: HospitalsParams) => ({
  ...(params.page && { page: params.page }),
  ...(params.pageSize && { page_size: params.pageSize }),
  ...(params.sortBy && { sort_by: params.sortBy }),
  ...(params.orderBy && { order_by: params.orderBy }),
  ...(params.name && { name: params.name }),
  ...(params.id && { id: params.id }),
  ...(params.marketId && { market_id: params.marketId }),
});

type Config = {
  enabled?: boolean;
  keepPreviousData?: boolean;
};

export function useHospitals(params: HospitalsParams, config?: Config) {
  return useQuery<PaginatedData<Hospital, 'hospitals'>>(
    [...HOSPITALS_QUERY_KEY_BASE, getHospitalsParams(params)],
    config,
  );
}

export type SortableHospitalFields =
  | 'name'
  | 'created_at'
  | 'updated_at'
  | 'patient_outreach_enabled';

export const hospitalKeys = {
  infinite: (params?: HospitalInfiniteParams) =>
    [...HOSPITALS_QUERY_KEY_BASE, params, 'infinite'] as const,
};

export function useHospitalsInfinite(
  params: HospitalInfiniteParams,
  options?: UseInfiniteQueryOptions<PaginatedData<Hospital, 'hospitals'>>,
) {
  return useInfiniteQuery<PaginatedData<Hospital, 'hospitals'>>(
    hospitalKeys.infinite(params),
    options,
  );
}

const SMS_QUERY_KEY_BASE = ['notification', 'api', 'v1', 'message-template'];

function hospitalMessageTemplateQueryKeyBase(hospitalId: string) {
  return [...SMS_QUERY_KEY_BASE, 'hospitals', hospitalId];
}

export type UpdateHospitalMessageTemplateParams = {
  params: MessageTemplateSchema;
  messageTemplateId: number;
};

export function useGetHospitalMessageTemplates(
  hospitalId: string,
  config?: Config,
) {
  return useQuery<MessageTemplateListSchema>(
    hospitalMessageTemplateQueryKeyBase(hospitalId),
    () =>
      MessageTemplateService.getNotificationApiV1MessageTemplateHospitals(
        hospitalId,
      ),
    config,
  );
}

export function useCreateHospitalMessageTemplate(hospitalId: string) {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { toaster } = useToaster();

  return useMutation(
    (params: MessageTemplateSchema) =>
      MessageTemplateService.postNotificationApiV1MessageTemplate(params),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          hospitalMessageTemplateQueryKeyBase(hospitalId),
        );
        toaster.success(
          intl.formatMessage({
            defaultMessage: 'SMS templates saved',
          }),
        );
      },
    },
  );
}

export function useUpdateHospitalMessageTemplate(hospitalId: string) {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { toaster } = useToaster();

  return useMutation(
    ({ params, messageTemplateId }: UpdateHospitalMessageTemplateParams) =>
      MessageTemplateService.putNotificationApiV1MessageTemplate(
        messageTemplateId,
        params,
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          hospitalMessageTemplateQueryKeyBase(hospitalId),
        );
        toaster.success(
          intl.formatMessage({
            defaultMessage: 'SMS templates updated',
          }),
        );
      },
    },
  );
}

export type AssignClinicToProviderPayload = EpicProvider & CernerProvider;

export function useAssignClinicToProvider(
  providerId: string,
  clinicId: string,
) {
  const queryClient = useQueryClient();
  const invalidateProviderHospitals = useInvalidateProviderHospitals();
  const invalidateCareProvider = useInvalidateCareProvider();
  return useMutation(
    (payload?: AssignClinicToProviderPayload) =>
      Session.Api.post<Hospital>(
        `/pms/api/v1/hospitals/${clinicId}/care_providers/${providerId}`,
        payload,
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(HOSPITALS_QUERY_KEY_BASE);
        await invalidateProviderHospitals(providerId);
        await invalidateCareProvider(providerId);
      },
    },
  );
}

export function useUnassignClinicFromProvider(
  providerId: string,
  clinicId: string,
) {
  const queryClient = useQueryClient();
  const invalidateProviderHospitals = useInvalidateProviderHospitals();

  return useMutation(
    () =>
      Session.Api.delete<void>(
        `/pms/api/v1/hospitals/${clinicId}/care_providers/${providerId}`,
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(HOSPITALS_QUERY_KEY_BASE);
        await invalidateProviderHospitals(providerId);
      },
    },
  );
}
