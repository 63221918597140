import { endOfDay, formatISO, startOfDay } from 'date-fns';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { appointmentKeys } from 'pages/patients/PatientProfile/PatientScheduling/appointments.queries';

import { useTwilioFlexContext } from '../common/TwilioFlex';
import { useCurrentUser } from './useCurrentUser';

const TWILIO_FLEX_ORIGIN = 'https://flex.twilio.com';

export enum TaskStatus {
  PENDING = 'pending',
  ASSIGNED = 'assigned',
  WRAPPING = 'wrapping',
  ACCEPTED = 'accepted',
  RESERVED = 'reserved',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
}

export enum TaskDirection {
  INBOUND = 'inbound',
  OUTBOUND = 'outbound',
}

enum EventTypes {
  AGENT_LOGGED_OUT = 'AGENT_LOGGED_OUT',
  AGENT_ACCEPTED_TASK = 'AGENT_ACCEPTED_TASK',
}

export const ACTIVE_TASK_STATUSES = [
  TaskStatus.ASSIGNED,
  TaskStatus.WRAPPING,
  TaskStatus.ACCEPTED,
];

export const AWAITING_INBOUND_TASK_STATUSES = [
  TaskStatus.PENDING,
  TaskStatus.RESERVED,
];

export const ENDED_TASK_STATUSES = [TaskStatus.CANCELED, TaskStatus.COMPLETED];

type TwilioTaskAttributes = {
  channelType: string;
  contact_type: string;
  conversationSid: string;
  conversations: unknown;
  customerAddress: string;
  customerName: string;
  customers: unknown;
  direction: TaskDirection;
  flexChannelInviteSid: string;
  flexInteractionChannelSid: string;
  flexInteractionSid: string;
  from: string;
  initiatedBy: string;
  patient_id: string;
  caregiver_id: string;
  patient_name: string;
  zd_ticket_id: string;
  conference: unknown;
};

export type TwilioTask = {
  data: TwilioTaskAttributes;
  status: TaskStatus;
};

type LogOutData = {
  type: EventTypes.AGENT_LOGGED_OUT;
};

type AcceptTaskData = {
  type: EventTypes.AGENT_ACCEPTED_TASK;
  data: TwilioTaskAttributes;
};

type TwilioMessage = TwilioTask | LogOutData | AcceptTaskData;

/**
 * Custom hook to listen to window 'message' events coming specifically from Twilio Flex,
 * ensuring that the message includes 'data' and 'status' properties.
 * @param {function} handleMessage - The callback function to be executed when a valid message event from Twilio Flex is received.
 */
export const useTwilioFlexMessagesListener = (
  handleMessage?: (task: TwilioTask) => void,
) => {
  const { tasks, setTasks, setIsFlexOpen } = useTwilioFlexContext();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { currentUserId: providerId } = useCurrentUser();

  useEffect(() => {
    const messageListener = (event: MessageEvent<TwilioMessage>) => {
      // Check if the message is from Twilio Flex
      if (event.origin !== TWILIO_FLEX_ORIGIN) return;

      const messageData = event.data;

      if ('type' in messageData) {
        switch (messageData.type) {
          case 'AGENT_ACCEPTED_TASK':
            // eslint-disable-next-line no-case-declarations
            const patientId = messageData.data.patient_id;
            if (patientId) {
              if (messageData.data.direction === TaskDirection.OUTBOUND) {
                return;
              }
              history.push(`/patients/${patientId}/messages`);
              setIsFlexOpen(true);
            }
            // Invalidating after call is picked up so that the appointment tray is aware of whether an appt has calls
            queryClient.invalidateQueries(
              appointmentKeys.list({
                apptStartTimeFrom: formatISO(startOfDay(new Date())),
                apptStartTimeTo: formatISO(endOfDay(new Date())),
                careProviderId: providerId,
              }),
            );
            queryClient.invalidateQueries(
              appointmentKeys.nextScheduled(patientId ?? ''),
            );
            break;
          case 'AGENT_LOGGED_OUT':
            setTasks(new Map());
            break;
          default:
            break;
        }
        // Check for valid task message structure
      } else {
        if (!('data' in messageData && 'status' in messageData)) {
          return;
        }

        // Update tasks based on status
        const newTasks = new Map(tasks);
        if (ENDED_TASK_STATUSES.includes(messageData.status)) {
          newTasks.delete(
            `${messageData.data.direction}_${messageData.data.from}`,
          );
        } else {
          newTasks.set(
            `${messageData.data.direction}_${messageData.data.from}`,
            messageData,
          );
        }
        setTasks(newTasks);

        // Call the provided callback with the message data
        if (handleMessage) {
          handleMessage(messageData);
        }
      }
    };

    // Add the event listener for 'message' events
    window.addEventListener('message', messageListener);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [
    handleMessage,
    history,
    providerId,
    queryClient,
    setIsFlexOpen,
    setTasks,
    tasks,
  ]);
};
