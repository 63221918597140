import type { IntlShape } from 'react-intl';

import type { AllHeartRateTagType } from 'shared/types/tagsAndThreshold.types';
import { HeartRateTagType } from 'shared/types/tagsAndThreshold.types';

import type { HeartRateFormatterProps } from '../../AlertDescription.types';
import { AlertFormatter } from './AlertFormatter';

export class HeartRateAlertFormatter extends AlertFormatter<
  AllHeartRateTagType,
  HeartRateFormatterProps
> {
  constructor(private intl: IntlShape) {
    super();
  }

  protected map: Map<AllHeartRateTagType, string> = new Map<
    AllHeartRateTagType,
    string
  >([
    [
      HeartRateTagType.PulseHighP0,
      this.intl.formatMessage({ defaultMessage: 'High Heart Rate' }),
    ],
    [
      HeartRateTagType.PulseLowP0,
      this.intl.formatMessage({ defaultMessage: 'Low Heart Rate' }),
    ],
  ]);

  // eslint-disable-next-line class-methods-use-this
  protected unitConverter = (value: number) => value;

  protected unit = 'bpm';

  public getDescription(
    _: AllHeartRateTagType,
    props: HeartRateFormatterProps,
  ): string {
    const { operator, values, unit } = super.convertReadings(props);
    return `${values.currentReading} ${operator} ${values.threshold} ${unit}`;
  }
}
