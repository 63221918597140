import AlertIcon from 'shared/assets/svgs/alertTriangle.svg?react';
import { usePatientReferringPhysician } from 'shared/hooks/queries';
import { color as themeColor } from 'shared/tempo/theme';

import {
  medDelegationNoteContainerV2,
  medDelegationNoteIconV2,
  medDelegationNoteText,
} from './PreferencesInfoFields.css';

export function MedDelegationNoteInfoFieldV2({
  patientId,
}: {
  patientId: string;
}) {
  const { referringPhysician } = usePatientReferringPhysician(patientId);
  const medManagementDelegationNote =
    referringPhysician?.preferences?.med_management_delegation_note;

  return (
    <>
      {medManagementDelegationNote && (
        <div className={medDelegationNoteContainerV2}>
          <div className={medDelegationNoteIconV2}>
            <AlertIcon fill={themeColor.Palette.Danger[500]} />
          </div>
          <div className={medDelegationNoteText}>
            {medManagementDelegationNote}
          </div>
        </div>
      )}
    </>
  );
}
