import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  FieldGroup,
  Script,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { useTimeEstimate } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Form } from 'shared/common/Form';
import { Wizard } from 'shared/common/Wizard';
import { useWizardFormFromConfig } from 'shared/common/Wizard/state';

import type { ProductFeedbackFormFields } from './feedbackFormConfig';
import { getProductFeedbackFormConfig } from './feedbackFormConfig';
import { helperList } from './productSupport.css';

type Props = {
  currentPath: string;
  nextPath?: string;
};

export function SharedFeedback({ currentPath, nextPath }: Props) {
  const intl = useIntl();
  const history = useHistory();
  const form = useWizardFormFromConfig<ProductFeedbackFormFields>(
    currentPath,
    getProductFeedbackFormConfig(),
  );

  return (
    <Wizard.Step
      sections={[]}
      title={intl.formatMessage({ defaultMessage: 'Product Support' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      form={form}
      onNext={nextPath ? () => history.push(nextPath) : undefined}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage defaultMessage="Let's pause here. What questions can I answer for you about our program, the devices, or anything else that is on your mind?" />
        </Script>
        <Form.TextArea
          size={12}
          rows={3}
          name="productFeedback"
          label={
            <FormattedMessage defaultMessage="Patient product support feedback" />
          }
        />
        <ul className={helperList}>
          <li>
            <FormattedMessage defaultMessage="If a patient is having issues with devices, discuss potential errors." />
          </li>
          <li>
            <FormattedMessage defaultMessage="If you cannot triage these issues yourself, inform the patient that if they can stay on the line, you will transfer them to our patient success team after the call to help troubleshoot." />
          </li>
          <li>
            <FormattedMessage defaultMessage="If they cannot stay on, create a ticket for Patient Success in Zendesk and inform the patient that someone will call them back." />
          </li>
        </ul>
      </FieldGroup>
    </Wizard.Step>
  );
}
