import type { ReactElement, ReactText } from 'react';
import { FormattedMessage } from 'react-intl';

import { Form } from 'shared/common/Form';
import { tiledRadioLabel } from 'shared/jsStyle/ui.css';

type Props = {
  name: string;
  label: ReactElement | ReactText;
  yesLabel?: ReactElement | ReactText;
  noLabel?: ReactElement | ReactText;
  orientation?: 'horizontal' | 'vertical';
  required?: boolean;
  classes?: {
    label?: string;
  };
};

export function YesNoRadioGroup({
  name,
  label,
  classes,
  yesLabel,
  noLabel,
  orientation = 'horizontal',
  required = true,
}: Props) {
  return (
    <Form.RadioGroup
      size={12}
      name={name}
      required={required}
      label={label}
      orientation={orientation}
      classes={classes}
    >
      <Form.Radio
        value="true"
        classes={{ label: tiledRadioLabel[orientation] }}
      >
        {yesLabel ?? <FormattedMessage defaultMessage="Yes" />}
      </Form.Radio>
      <Form.Radio
        value="false"
        classes={{ label: tiledRadioLabel[orientation] }}
      >
        {noLabel ?? <FormattedMessage defaultMessage="No" />}
      </Form.Radio>
    </Form.RadioGroup>
  );
}
