export enum PhoneType {
  Cellular = 'CELLULAR',
  Landline = 'LANDLINE',
}

export enum CountryTypes {
  UnitedStates = 'United States',
}

export interface Address {
  full_name: string;
  street_address: string;
  premise: string;
  locality: string;
  postal_code: string;
  region: string;
  country: string;
  id?: number;
  created_at?: string;
  updated_at?: string;
  created_by?: string;
  updated_by?: string;
}

export type CamelizedAddress = SnakeToCamelCaseNested<Address>;

type BaseNotificationSettings = {
  vitals_confirmation: boolean;
  vitals_value: boolean;
  vitals_reminder: boolean;
  weekly_summary: boolean;
};

type CaregiverNotificationSettings = {
  notification_consent: boolean;
} & BaseNotificationSettings;

export type PatientNotificationSettings = {
  appointments_reminder?: boolean;
  email_consent?: boolean;
  notification_consent?: boolean;
  sms_consent?: boolean;
  vitals_confirmation?: boolean;
  vitals_reminder?: boolean;
  vitals_value?: boolean;
  weekly_summary?: boolean;
};

export type CamelizedCaregiverNotificationSettings =
  SnakeToCamelCaseNested<CaregiverNotificationSettings>;

export interface Contact {
  id?: number;
  name: string;
  phone_type: PhoneType;
  phone_number: string;
  created_at?: string;
  updated_at?: string;
  createdBy?: string;
  updated_by?: string;
}

export type CamelizedContact = SnakeToCamelCaseNested<Contact>;

export type Metadata = {
  links?: {
    first: string;
    last: string;
    next: Nullable<string>;
    previous: Nullable<string>;
    self: string;
  };
  page: number;
  pages: number;
  per_page: number;
  total: number;
};

export enum MedManagementDelegation {
  OptInFullyManaged = 'OPT_IN_FULLY_MANAGED',
  OptInCurrentMeds = 'OPT_IN_CURRENT_MEDS',
  OptOutRecommendsMeds = 'OPT_OUT_RECOMMENDS_MEDS',
  OptOutProviderManaged = 'OPT_OUT_PROVIDER_MANAGED',
  ToBeDetermined = 'TO_BE_DETERMINED',
}
