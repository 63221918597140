import cx from 'classnames';
import type { ReactNode } from 'react';

import { Box } from 'deprecated/mui';

import { sectionDescription, sectionTitle } from './section.css';

export interface SectionProps {
  title?: string;
  description?: string;
  children: ReactNode;
  classes?: {
    container?: string;
    title?: string;
    description?: string;
  };
}

export function Section({
  title,
  description,
  children,
  classes,
}: SectionProps) {
  return (
    <Box padding="1rem 0" className={classes?.container}>
      {title && (
        <span className={cx(sectionTitle, classes?.title)}>{title}</span>
      )}
      {description && (
        <p className={cx(sectionDescription, classes?.description)}>
          {description}
        </p>
      )}
      {children}
    </Box>
  );
}
