import type { ReactNode } from 'react';

import { Box, Paper } from 'deprecated/mui';

interface ContainerProps {
  padding?: string;
  children: ReactNode;
}

export function Container({ padding = '24px', children }: ContainerProps) {
  return (
    <Paper>
      <Box padding={padding}>{children}</Box>
    </Paper>
  );
}
