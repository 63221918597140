import type { FeatureFlagSet } from 'shared/types/featureFlags.types';

import { oneDotOhTemplates } from './files/1.0';
import { twoDotOhTemplates } from './files/2.0';
import { getThreeDotOhTemplates } from './files/3.0';
import type { SmartTemplateMap } from './types';

export function getSmartTemplatesMap(flags: FeatureFlagSet): SmartTemplateMap {
  return {
    ...oneDotOhTemplates,
    ...twoDotOhTemplates,
    ...getThreeDotOhTemplates(flags),
  };
}
