import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useInjectTemplateValue } from 'pages/patients/patientDetails/ui/Notes/NoteEditor/hooks/useInjectTemplates.hooks';
import { useTemplateContext } from 'pages/patients/patientDetails/ui/Notes/NoteEditor/templates/hooks';
import {
  EncounterModuleId,
  type EncounterModuleInstance,
  NotesFilterValue,
} from 'pages/patients/patientDetails/ui/Notes/Notes.types';
import { usePatientNotes } from 'pages/patients/patientDetails/ui/Notes/note.queries';
import { NoteStatus } from 'shared/generated/grpcGateway/note.pb';
import type { AppointmentDetails } from 'shared/generated/grpcGateway/scheduling.pb';
import { useIsCnExperience } from 'shared/hooks';
import type { Note } from 'shared/types/note.types';
import { NoteType } from 'shared/types/note.types';
import { idToGrpcName } from 'shared/utils/grpc';

import { getNoteMetadataFromApptName } from './utils';

export const useCreateNoteFromAppointment = (
  appointment: Maybe<AppointmentDetails>,
  createEncounter: Function,
) => {
  const patientId = appointment?.patientId ?? '';
  const { context: patientContext, isLoading } = useTemplateContext(patientId);
  const { injectTemplates } = useInjectTemplateValue();
  const intl = useIntl();
  const { isCnExperience } = useIsCnExperience();
  const { items: notes } = usePatientNotes(patientId, {
    noteTypes: [NoteType.Standard],
    notesFilter: NotesFilterValue.AllNotes,
    isNoShow: true,
  });

  let originalNoShowNote: Maybe<Note> = null;
  if (appointment?.patientNoShow) {
    originalNoShowNote = notes.find((note) => note.id === appointment.noteId);
  }

  const callback = useCallback(() => {
    const { encounterType, visitType, title } = getNoteMetadataFromApptName(
      intl,
      appointment?.appointmentCanonicalName,
      patientContext.rpmConditions ?? [],
    );
    let templatedModules: EncounterModuleInstance[] = [];
    if (originalNoShowNote && originalNoShowNote.encounter_instances) {
      templatedModules = originalNoShowNote.encounter_instances.map(
        (instance) => {
          if (
            instance.encounter_module_id === EncounterModuleId.EncounterType
          ) {
            return {
              ...instance,
              inputs: {
                ...instance.inputs,
                // Override no-show to False when copy-forwarding from no-show note
                patient_no_show: false,
              },
            };
          }
          return instance;
        },
      );
    } else if (encounterType) {
      templatedModules = injectTemplates(encounterType, patientContext);
    }

    const req = {
      note: {
        appointmentId: !appointment?.patientNoShow
          ? appointment?.name
          : undefined,
        noShowAppointmentId: appointment?.patientNoShow
          ? appointment.name
          : undefined,
        title,
        status: NoteStatus.AUTOSAVED,
        visitType,
        encounterModuleInstances: isCnExperience
          ? []
          : templatedModules.map((instance) => ({
              encounterModuleId: instance.encounter_module_id,
              inputs: JSON.stringify(instance.inputs),
            })),
      },
      parent: idToGrpcName('patient', patientContext.name ?? ''),
    };
    createEncounter(req);
  }, [
    appointment?.appointmentCanonicalName,
    appointment?.name,
    appointment?.patientNoShow,
    createEncounter,
    injectTemplates,
    intl,
    isCnExperience,
    originalNoShowNote,
    patientContext,
  ]);

  return { isLoading, createNoteFromAppointment: callback };
};
