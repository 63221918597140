import type { TDraftNotePayload } from 'shared/types/note.types';

import { InProgressNoteBox } from './InProgressNoteBox';
import { useNoteEditorContext } from './NoteEditorContext';
import { EditableNoteType } from './Notes.types';
import { useEncounterModules } from './note.queries';
import { useEnqueueOpenEditorError } from './utils/useEnqueueOpenEditorError';
import { useSetNoteEditorContentFromNote } from './utils/useSetNoteEditorContent.hook';

type Props = {
  draftNote: TDraftNotePayload;
};

export const CurrentDraftBox = ({ draftNote }: Props) => {
  const enqueueOpenEditorError = useEnqueueOpenEditorError();
  const { isLoading: isLoadingEncounterModules } = useEncounterModules();
  const { openEditor, setEditingNote, isEditorOpen } = useNoteEditorContext();

  const setNoteEditorContentFromNote = useSetNoteEditorContentFromNote();

  const openDraftNote = () => {
    if (isEditorOpen) {
      enqueueOpenEditorError();
      return;
    }

    setEditingNote({ type: EditableNoteType.Draft, note: draftNote });
    openEditor();

    setNoteEditorContentFromNote(draftNote);
  };

  return (
    <InProgressNoteBox
      variant="draft"
      isLoading={isLoadingEncounterModules}
      onOpen={openDraftNote}
    />
  );
};
