import { useMutation, useQuery, useQueryClient } from 'react-query';

import type {
  AddReferenceMedicationRequest,
  AddRxNormRequest,
  UpdateReferenceMedicationRequest,
  UpdateRxNormRequest,
} from 'shared/generated/grpcGateway/medication.pb';
import { MedicationService } from 'shared/generated/grpcGateway/medication.pb';
import { idToGrpcName } from 'shared/utils/grpc';

const referenceMedicationsKey = ['rpm', 'v1', 'medications', 'references'];

const medicationsKeys = {
  referenceMedications: () => [...referenceMedicationsKey] as const,
  referenceMedication: (referenceMedicationId: string) =>
    [...referenceMedicationsKey, referenceMedicationId] as const,
};

export function useReferenceMedications() {
  return useQuery(medicationsKeys.referenceMedications(), () =>
    MedicationService.ListReferenceMedications({}),
  );
}

export function useReferenceMedication(
  referenceMedicationId: string,
  enabled: boolean,
) {
  return useQuery(
    medicationsKeys.referenceMedication(referenceMedicationId),
    () =>
      MedicationService.GetReferenceMedication({
        referenceMedication: idToGrpcName(
          'medications/references',
          referenceMedicationId,
        ),
      }),
    {
      enabled,
    },
  );
}

export function useAddReferenceMedication() {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: AddReferenceMedicationRequest) =>
      MedicationService.AddReferenceMedication({
        ...payload,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(referenceMedicationsKey);
      },
    },
  );
}

export function useUpdateReferenceMedication(refMedId: string) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: UpdateReferenceMedicationRequest) =>
      MedicationService.UpdateReferenceMedication({
        referenceMedication: idToGrpcName('medications/references', refMedId),
        ...payload,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(referenceMedicationsKey);
      },
    },
  );
}

export function useAddRxnorm() {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: AddRxNormRequest) =>
      MedicationService.AddRxNorm({
        ...payload,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(referenceMedicationsKey);
      },
    },
  );
}

export function useUpdateRxnorm(rxnormId: string) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: UpdateRxNormRequest) =>
      MedicationService.UpdateRxNorm({
        rxnorm: idToGrpcName('medications/references/rxnorm', rxnormId),
        ...payload,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(referenceMedicationsKey);
      },
    },
  );
}
