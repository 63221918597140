import type { SvgIconProps } from 'deprecated/mui';
import { SvgIcon } from 'deprecated/mui';

const HighlightColorIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props} viewBox="0 0 24 24">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.12216 18L9.43892 14.2926H14.7315L16.0483 18H17.7102L12.9034 4.90909H11.267L6.46023 18H8.12216ZM9.9375 12.8864L12.0341 6.98011H12.1364L14.233 12.8864H9.9375Z"
        fill="#3B3A45"
      />
      <rect x="4" y="19" width="16" height="3" fill="#F0C323" />
    </svg>
  </SvgIcon>
);

export default HighlightColorIcon;
