import { FormattedMessage } from 'react-intl';

import { Link } from 'shared/tempo/atom/Link';

import { SubHeader } from '../../SubHeader';

export function OrdersResources() {
  return (
    <>
      <SubHeader>
        <FormattedMessage defaultMessage="Resources" />
      </SubHeader>
      <ul>
        <li>
          <FormattedMessage
            defaultMessage="<link>Patient Eligibility Job Aid</link>"
            values={{
              link: (children: string) => (
                <Link.Native href="https://www.notion.so/cadencesolutions/Patient-Eligibility-Job-Aid-4fff698af7db4e529d14bfbe2ac350fe?pvs=4">
                  {children}
                </Link.Native>
              ),
            }}
          />
        </li>
        <li>
          <FormattedMessage
            defaultMessage="<link>Revising Diagnosis on Order Job Aid</link>"
            values={{
              link: (children: string) => (
                <Link.Native href="https://www.notion.so/cadencesolutions/Revising-Diagnosis-on-Order-Job-Aid-37e440169b7d4b6ca966496ca224a370?pvs=4">
                  {children}
                </Link.Native>
              ),
            }}
          />
        </li>
      </ul>
    </>
  );
}
