import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { IconButton } from 'deprecated/mui';
import InfoIcon from 'shared/assets/svgs/info-reminder.svg?react';
import { Popover, TriggerBy } from 'shared/common/Popover';
import { Snackbar } from 'shared/common/Snackbar';
import { usePatchPatient, usePatientDetails } from 'shared/hooks/queries';
import { useToaster } from 'shared/tempo/molecule/Toast';
import { color } from 'shared/tempo/theme';
import type { PatientReminderNoteData } from 'shared/types/patient.types';
import type { RouteParam } from 'shared/types/route.types';

import { ReminderNoteContainer } from './ReminderNoteContainer';
import { useStyles } from './useStyles.hook';

export const PatientReminderNote = () => {
  const intl = useIntl();
  const { patientId }: RouteParam = useParams();

  const [note, setNote] = useState<Nullable<PatientReminderNoteData>>(null);
  const { isError, error } = usePatientDetails(
    patientId,
    false,
    true,
    (details) => {
      setNote(details.reminder_note ?? null);
    },
  );

  const { toaster } = useToaster();
  const [isOpen, setIsOpen] = useState(Boolean(note));
  const classes = useStyles({ note, active: isOpen });

  const { mutate: updatePatient, isLoading: isUpdatingPatient } =
    usePatchPatient(patientId, (updated) => {
      setNote(updated.reminder_note);
      toaster.info(
        intl.formatMessage({
          defaultMessage: 'Reminder note updated successfully',
        }),
      );
    });

  const handleOnReminderNoteAdded = (newNoteText: string) => {
    updatePatient({ reminder_note: { text: newNoteText } });
  };

  const handleOnReminderNoteDelete = () => {
    updatePatient({ reminder_note: null });
  };

  return (
    <>
      {isError && <Snackbar variant="error" message={error} />}
      <Popover
        trigger={{
          type: TriggerBy.MANUAL,
          open: isOpen,
          onClose: () => setIsOpen(false),
        }}
        target={
          <IconButton disableRipple onClick={() => setIsOpen(!isOpen)}>
            <InfoIcon className={classes.infoIcon} width="16px" />
          </IconButton>
        }
        boxStyles={{ border: `2px solid ${color.Theme.Light.Warning}` }}
      >
        <ReminderNoteContainer
          note={note}
          isUpdatingReminderNote={isUpdatingPatient}
          onCancelNoteEdit={() => setIsOpen(false)}
          onReminderNoteAdded={handleOnReminderNoteAdded}
          onReminderNoteDelete={handleOnReminderNoteDelete}
        />
      </Popover>
    </>
  );
};
