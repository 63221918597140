import { useCallback, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { CurrentDraftBox } from 'pages/patients/patientDetails/ui/Notes/CurrentDraftBox';
import { InProgressNoteBox } from 'pages/patients/patientDetails/ui/Notes/InProgressNoteBox';
import { NoteEditor } from 'pages/patients/patientDetails/ui/Notes/NoteEditor';
import { ConfirmationDialog } from 'pages/patients/patientDetails/ui/Notes/NoteEditor/dialogs';
import {
  useClearNoteEditor,
  useOpenNewNoteForAlert,
} from 'pages/patients/patientDetails/ui/Notes/NoteEditor/hooks/noteEditorVisibility.hooks';
import { NoteEditorLayout } from 'pages/patients/patientDetails/ui/Notes/NoteEditor/noteEditor.types';
import { useNoteEditorContext } from 'pages/patients/patientDetails/ui/Notes/NoteEditorContext';
import { EditableNoteType } from 'pages/patients/patientDetails/ui/Notes/Notes.types';
import {
  useEncounterModules,
  usePatientDraftNote,
} from 'pages/patients/patientDetails/ui/Notes/note.queries';
import { useSetNoteEditorContentFromNote } from 'pages/patients/patientDetails/ui/Notes/utils/useSetNoteEditorContent.hook';
import { useFlags, useQueryParams } from 'shared/hooks';
import {
  useDeleteAutosavedNoteByPatientId,
  usePatientAutosavedNote,
} from 'shared/hooks/queries/autosave-notes.queries';
import type { RequiredNoteType } from 'shared/types/note.types';
import type { RouteParam } from 'shared/types/route.types';

import { DrawerState, NoteDrawer } from '../NoteDrawer';
import { usePatientVitalsAlerts } from '../shared';
import { useNotesLoadingStateContext } from './NotesLoadingStateContext';

function useDrawerState() {
  const { isEditorOpen, isPreviewing } = useNoteEditorContext();

  if (isEditorOpen) {
    return isPreviewing ? DrawerState.Preview : DrawerState.Editing;
  }

  return DrawerState.Collapsed;
}

export function PatientNotesSidebarPanel() {
  const intl = useIntl();
  const { patientId }: RouteParam = useParams();
  const drawerState = useDrawerState();
  const deleteAutoSavedNote = useDeleteAutosavedNoteByPatientId(patientId);

  const {
    isEditorOpen,
    openEditor,
    isPreviewing,
    setIsPreviewing,
    editingNote,
    setEditingNote,
  } = useNoteEditorContext();
  const params = useQueryParams();
  const noteId = params.get('noteId');
  const { data: draftNote } = usePatientDraftNote(patientId, {
    onSuccess: (loadedDraft) => {
      // Update editing note state with the note id after loading
      if (
        loadedDraft &&
        editingNote &&
        editingNote.type === EditableNoteType.Draft
      ) {
        setEditingNote({ ...editingNote, note: loadedDraft });
      }
    },
  });
  const { data: autosavedNote } = usePatientAutosavedNote(patientId, {
    onSuccess: (loadedAutosave) => {
      // Update editing note state with the note id after loading
      if (
        loadedAutosave &&
        editingNote &&
        [EditableNoteType.Autosaved, EditableNoteType.Alert].includes(
          editingNote.type,
        )
      ) {
        setEditingNote({ ...editingNote, note: loadedAutosave });
      }
    },
  });

  const openNewNoteForAlert = useOpenNewNoteForAlert(patientId);

  useHotkeys(
    'meta+p',
    () => setIsPreviewing(!isPreviewing),
    [isPreviewing, setIsPreviewing],
    {
      enabled: isEditorOpen,
      enableOnFormTags: true,
      enableOnContentEditable: true,
      preventDefault: true,
    },
  );

  useHotkeys(
    'esc',
    (event) => {
      // there's a bug with this library's handling of "esc"
      // where it keeps triggering on any keypress after the
      // initial trigger. this only seems to happen when we
      // try to capture a keypress while an input is focused
      // (enableOnFormTags and enableOnContentEditable). does
      // not seem to be the case with any other keys ¯\_(ツ)_/¯
      if (event.key !== 'Escape') {
        return false;
      }

      setIsPreviewing(false);
      return true;
    },
    [setIsPreviewing],
    {
      enabled: isPreviewing,
      enableOnFormTags: true,
      enableOnContentEditable: true,
    },
  );

  const { isLoading } = useNotesLoadingStateContext();
  const { isLoading: isLoadingEncounterModules } = useEncounterModules();

  const [isNewNoteConfirmationOpen, setIsNewNoteConfirmationOpen] =
    useState(false);
  function closeNewNoteConfirmationDialog() {
    setIsNewNoteConfirmationOpen(false);
  }
  function onClickNewNote() {
    if (autosavedNote) {
      setIsNewNoteConfirmationOpen(true);
    } else {
      openNewNote();
    }
  }

  const clearNoteEditor = useClearNoteEditor();
  function openNewNote() {
    clearNoteEditor();
    openEditor();
  }

  const { isLoading: isLoadingAlerts, data: vitalsAlerts } =
    usePatientVitalsAlerts(patientId, true);
  const autosavedAlertId = autosavedNote?.ui_state?.vitals_alert_id;
  const { persistAlertResolutionOnAutosave } = useFlags();

  const setAutosavedNote = useCallback(
    (note: Nullable<RequiredNoteType>) => {
      const autosavedAlert = vitalsAlerts?.data?.find(
        ({ id }) => id === autosavedAlertId,
      );
      if (autosavedAlert && persistAlertResolutionOnAutosave) {
        setEditingNote({
          type: EditableNoteType.Alert,
          alert: autosavedAlert,
          note,
        });
      } else {
        setEditingNote({
          type: EditableNoteType.Autosaved,
          note,
        });
      }
    },
    [
      autosavedAlertId,
      persistAlertResolutionOnAutosave,
      setEditingNote,
      vitalsAlerts?.data,
    ],
  );
  const history = useHistory();
  const location = useLocation();
  const setNoteEditorContentFromNote = useSetNoteEditorContentFromNote();

  useEffect(() => {
    if (autosavedNote && noteId && autosavedNote.id.toString() === noteId) {
      params.delete('noteId');
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
      openEditor();
      setAutosavedNote(autosavedNote);
    }
    if (draftNote && noteId && draftNote.id.toString() === noteId) {
      params.delete('noteId');
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
      setEditingNote({ type: EditableNoteType.Draft, note: draftNote });
      openEditor();

      setNoteEditorContentFromNote(draftNote);
    }
  }, [
    autosavedNote,
    draftNote,
    history,
    location.pathname,
    noteId,
    openEditor,
    params,
    setAutosavedNote,
    setEditingNote,
    setNoteEditorContentFromNote,
  ]);

  return (
    <>
      <NoteDrawer
        state={drawerState}
        isLoading={isLoading}
        onClickNewNote={onClickNewNote}
        onClickBackdrop={() => setIsPreviewing(false)}
        onOpenAlertNote={openNewNoteForAlert}
      >
        {autosavedNote && !isEditorOpen && (
          <InProgressNoteBox
            variant="autosaved"
            isLoading={
              isLoadingEncounterModules ||
              (persistAlertResolutionOnAutosave &&
                Boolean(autosavedAlertId) &&
                isLoadingAlerts)
            }
            onOpen={() => {
              openEditor();
              setAutosavedNote(autosavedNote);
            }}
          />
        )}
        {draftNote &&
          !isEditorOpen &&
          editingNote?.type !== EditableNoteType.Draft && (
            <CurrentDraftBox draftNote={draftNote} />
          )}
        {isEditorOpen && (
          <NoteEditor
            layout={
              isPreviewing
                ? NoteEditorLayout.TwoColumn
                : NoteEditorLayout.OneColumn
            }
            draftNote={!!draftNote}
          />
        )}
      </NoteDrawer>
      <ConfirmationDialog
        isOpen={isNewNoteConfirmationOpen}
        onCancel={closeNewNoteConfirmationDialog}
        onConfirm={() =>
          deleteAutoSavedNote.mutate(undefined, {
            onSuccess: () => {
              openNewNote();
              closeNewNoteConfirmationDialog();
            },
          })
        }
        onSecondaryAction={() => {
          openEditor();
          closeNewNoteConfirmationDialog();
          // Setting noteId as null because it is set once we perform the first autosave
          setAutosavedNote(null);
        }}
        confirmButtonText={intl.formatMessage({
          defaultMessage: 'Create new note',
        })}
        dialogTitle={intl.formatMessage({
          defaultMessage: 'Create a new note',
        })}
        dialogDescription={intl.formatMessage({
          defaultMessage:
            'Are you sure you want to create a new note? This will discard your current autosaved note.',
        })}
        secondaryButtonText={intl.formatMessage({
          defaultMessage: 'View autosaved note',
        })}
      />
    </>
  );
}
