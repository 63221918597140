import type {
  PatientContact,
  PatientFormContact,
} from 'shared/types/patient.types';
import type { Address } from 'shared/types/shared.types';
import { CountryTypes, PhoneType } from 'shared/types/shared.types';
import { map12HTimeFormatTo24H } from 'shared/utils/time-helpers';

import type { CaregiverFormFields } from '../CaregiversTab/CaregiverForm/formConfig';
import type {
  CommunicationPreferencesFormFields,
  EditPatientFormFields,
  EditPatientFormFieldsWithEmergencyContact,
  PatientPreferedContact,
} from './formConfig';

export const hasAddressRelatedFields = (
  values: Partial<EditPatientFormFields>,
) =>
  values.locality ||
  values.postal_code ||
  values.premise ||
  values.region ||
  values.street_address;

export const hasEmergencyContactRelatedFields = (
  values: EditPatientFormFields,
): values is EditPatientFormFieldsWithEmergencyContact =>
  !!values.contact_full_name &&
  !!values.emergency_phone &&
  !!values.emergency_phone_type &&
  !!values.relationship;

export const parseEmergencyContact = (
  values: EditPatientFormFieldsWithEmergencyContact,
): PatientContact => ({
  contact: {
    name: values.contact_full_name,
    phone_number: values.emergency_phone,
    phone_type: values.emergency_phone_type,
  },
  emergency: true,
  primary: false,
  relationship: values.relationship,
});

export const parseAddress = (
  values: EditPatientFormFields | CaregiverFormFields,
) => {
  if (!values.street_address) {
    return undefined;
  }

  const address: Address = {
    country: CountryTypes.UnitedStates,
    full_name: `${values.first_name} ${values.last_name}`,
    locality: values.locality,
    postal_code: values.postal_code,
    premise: values.premise,
    region: values.region,
    street_address: values.street_address,
  };

  return {
    address,
  };
};
export const parseCaregiverNotificationSetting = (
  values: CaregiverFormFields,
) => {
  const notificationSettings: Record<string, unknown> = {
    notification_consent: Boolean(values.notification_consent),
    vitals_confirmation: Boolean(values.vitals_confirmation),
    vitals_value: Boolean(values.vitals_value),
  };
  return notificationSettings;
};

export function parseCommunicationPreferencesFields(
  values: CommunicationPreferencesFormFields,
) {
  const notificationSettings = parsePatientNotificationSetting(values);
  return {
    ...(values.vitals_reminder_time && {
      vitals_reminders_time_local: map12HTimeFormatTo24H(
        values.vitals_reminder_time,
        values.vitals_reminder_time_am_pm,
      ),
    }),
    notification_settings: { ...notificationSettings },
  };
}

export function parsePatientNotificationSetting(
  values: CommunicationPreferencesFormFields,
) {
  const notificationSettings: Record<string, unknown> = {
    email_consent: Boolean(values.email_consent),
    sms_consent: Boolean(values.sms_consent),
    vitals_confirmation: Boolean(values.vitals_confirmation),
    vitals_value: Boolean(values.vitals_value),
    streaks: Boolean(values.streaks),
    bingo: Boolean(values.bingo),
  };
  if ('vitals_reminder' in values) {
    notificationSettings.vitals_reminder = Boolean(values.vitals_reminder);
  }
  if ('appointments_reminder' in values) {
    notificationSettings.appointments_reminder = Boolean(
      values.appointments_reminder,
    );
  }
  if ('weekly_summary' in values) {
    notificationSettings.weekly_summary = Boolean(values.weekly_summary);
  }
  delete notificationSettings.notification_consent;
  return notificationSettings;
}

type ParseableContact = {
  mobile_number: string;
  phone_number: string;
  preferred_phone: PatientPreferedContact | '';
  first_name: string;
  last_name: string;
};

export const parseContacts = (
  values: ParseableContact,
): PatientFormContact[] => {
  const {
    mobile_number: mobile,
    phone_number: home,
    preferred_phone: preferredType,
    first_name: firstName,
    last_name: lastName,
  } = values;

  const numbers = [
    ...(values.phone_number
      ? [{ number: home, type: PhoneType.Landline }]
      : []),
    ...(values.mobile_number
      ? [{ number: mobile, type: PhoneType.Cellular }]
      : []),
  ];

  return numbers.map(({ number, type }) => ({
    is_preferred: preferredType === type,
    contact: {
      name: `${firstName} ${lastName}`,
      phone_type: type,
      phone_number: number,
    },
  }));
};

export const mapToPatientContact = (
  contacts: PatientFormContact[],
): PatientContact[] =>
  contacts.map((contact: PatientFormContact) => {
    const { is_preferred: isPreferred, ...rest } = contact;
    return { ...rest, emergency: false, primary: isPreferred };
  });
