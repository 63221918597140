import CadenceLogo from 'shared/assets/svgs/cadence-logo-no-fill.svg?react';
import { Avatar } from 'shared/tempo/atom/Avatar';
import { color } from 'shared/tempo/theme';

import { cadenceLogo } from './UserAvatar.css';
import { getTheme, userInitials } from './utils';

type Props = {
  firstName: string;
  lastName: string;
  size?: 'default' | 'small';
};

export function UserAvatar({ firstName, lastName, size = 'default' }: Props) {
  const initials = userInitials(firstName.trim(), lastName.trim());
  const theme = getTheme(firstName, lastName);
  const isCadenceSystem = firstName === 'Cadence' && lastName === 'System';
  return (
    <>
      {isCadenceSystem ? (
        <Avatar color={color.Brand.Primary.Navy} size={size}>
          <CadenceLogo className={cadenceLogo} />
        </Avatar>
      ) : (
        <Avatar color={theme.background} size={size}>
          <Avatar.Label color={theme.text}>{initials}</Avatar.Label>
        </Avatar>
      )}
    </>
  );
}
