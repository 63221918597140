import type { ReactNode } from 'react';

import type { TooltipProps } from 'shared/tempo/atom/Tooltip';

export type Props = {
  value: string;
  children: ReactNode;
  className?: string;
  tooltip?: Omit<TooltipProps, 'children'>;
};

/**
 * Used to map value/children to SegmentedButtons
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Segment(props: Props) {
  return null;
}
