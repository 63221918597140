import { Tag } from 'shared/common/Tag';
import { color } from 'shared/tempo/theme';
import type { NoteLabel } from 'shared/types/noteLabels.types';

import { tagContainer } from './NoteLabelTag.css';

export const NoteLabelTag = ({ noteLabel }: { noteLabel: NoteLabel }) => (
  <div className={tagContainer}>
    <Tag
      backgroundColor={noteLabel.rgb_hex}
      borderColor={color.Theme.Light['Base Divider']}
    >
      {noteLabel.name}
    </Tag>
  </div>
);
