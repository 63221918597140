import type { Action, Location } from 'history';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useOnMount } from 'shared/hooks/useOnMount';

type Props = {
  onNavigation: (location: Location, action: Action) => void;
};

export function NavigationSubscriber({ onNavigation }: Props) {
  const history = useHistory();

  useOnMount(() => {
    // Add initial route to history stack
    if (history.length === 0) {
      onNavigation(history.location, 'PUSH');
    }
  });

  useEffect(() => {
    const unlisten = history.listen(onNavigation);
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return null;
}
