import isEmpty from 'lodash/isEmpty';

import { Box } from 'deprecated/mui';
import DownArrow from 'shared/assets/svgs/downArrow.svg?react';
import SmallAlertCircle from 'shared/assets/svgs/smallAlertCircle.svg?react';
import UpArrow from 'shared/assets/svgs/upArrow.svg?react';
import { color } from 'shared/tempo/theme';
import type { Tags } from 'shared/types/tagsAndThreshold.types';
import {
  convertToLbs,
  convertToMgDl,
  convertToMmHg,
} from 'shared/utils/unit-helpers';

import { vitalAlert } from './VitalReading.css';
import { getTagsInfo } from './utils/tagUtils';

type ReadingProps = {
  tags: Tags | {} | null | undefined;
  hasAlert: boolean;
  isReadingSuppressed: boolean;
};

type BPReadingProps = {
  systolic: number;
  diastolic: number;
} & ReadingProps;
export const BPReading = ({
  systolic,
  diastolic,
  tags,
  hasAlert,
  isReadingSuppressed,
}: BPReadingProps) => (
  <VitalReading
    vitalString={`${convertToMmHg(systolic)}/${convertToMmHg(diastolic)}`}
    tags={tags}
    hasAlert={hasAlert}
    isReadingSuppressed={isReadingSuppressed}
  />
);

type HRReadingProps = {
  pulse: number;
} & ReadingProps;
export const HRReading = ({
  pulse,
  tags,
  hasAlert,
  isReadingSuppressed,
}: HRReadingProps) => (
  <VitalReading
    vitalString={`${pulse} bpm`}
    tags={tags}
    hasAlert={hasAlert}
    isReadingSuppressed={isReadingSuppressed}
  />
);

type WeightReadingProps = {
  weight: number;
} & ReadingProps;
export const WeightReading = ({
  weight,
  tags,
  hasAlert,
  isReadingSuppressed,
}: WeightReadingProps) => (
  <VitalReading
    vitalString={`${convertToLbs(weight)} lbs`}
    tags={tags}
    hasAlert={hasAlert}
    isReadingSuppressed={isReadingSuppressed}
  />
);

type BGReadingProps = {
  glucoseLevel: number;
} & ReadingProps;
export const BGReading = ({
  glucoseLevel,
  tags,
  hasAlert,
  isReadingSuppressed,
}: BGReadingProps) => (
  <VitalReading
    vitalString={`${convertToMgDl(glucoseLevel)} mg/dL`}
    tags={tags}
    hasAlert={hasAlert}
    isReadingSuppressed={isReadingSuppressed}
  />
);

type VitalReadingProps = {
  vitalString: string;
  tags: Tags | {} | null | undefined;
  hasAlert: boolean;
  isReadingSuppressed?: boolean;
};
function VitalReading({
  vitalString,
  hasAlert,
  tags,
  isReadingSuppressed = false,
}: VitalReadingProps) {
  if (isEmpty(tags)) {
    return <>{vitalString}</>;
  }

  return (
    <Box
      sx={[
        containerStyle,
        hasAlert && {
          color: color.Theme.Light.Danger,
          fontWeight: 'bold', // TODO: Change to fontWeight: 500 when Inter 500 is added
        },
      ]}
    >
      {vitalString}
      <Box sx={iconContainerStyle}>
        <VitalIcon
          hasAlert={hasAlert}
          isReadingSuppressed={isReadingSuppressed}
          {...getTagsInfo(tags as Tags)} // Type cast is needed because lodash's isEmpty does not type guard
        />
      </Box>
    </Box>
  );
}

function VitalIcon({
  hasAlert,
  hasHighTags,
  hasLowTags,
  isReadingSuppressed,
}: {
  hasAlert: boolean;
  hasHighTags: boolean;
  hasLowTags: boolean;
  isReadingSuppressed: boolean;
}) {
  if (isReadingSuppressed) {
    return <></>;
  }

  const fill = hasAlert
    ? color.Theme.Light.Danger
    : color.Theme.Light['Base Font'];

  if (hasHighTags && !hasLowTags) {
    return <UpArrow fill={fill} data-testid="vital-up-arrow" />;
  }
  if (hasLowTags && !hasHighTags) {
    return <DownArrow fill={fill} data-testid="vital-down-arrow" />;
  }
  if (hasHighTags && hasLowTags) {
    return (
      <span className={vitalAlert.wrapper}>
        <SmallAlertCircle stroke={fill} className={vitalAlert.icon} />
      </span>
    );
  }

  // No tags
  return null;
}

const containerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const iconContainerStyle = { marginLeft: '6px', marginBottom: '2px' };
