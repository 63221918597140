import * as yup from 'yup';

import type { CyclicalDependencies } from 'shared/common/Form/form.types';
import {
  anyFieldIsPopulated,
  validators,
} from 'shared/common/Form/validations';
import { validator } from 'shared/common/Form/validations/factory';

import type { AddressValues } from './formUtils';
import { getAddressDependentFields } from './formUtils';

export const CYCLICAL_DEPENDENCIES = [
  ['street_address', 'premise'],
  ['street_address', 'postal_code'],
  ['street_address', 'locality'],
  ['street_address', 'region'],
  ['premise', 'postal_code'],
  ['premise', 'locality'],
  ['premise', 'region'],
  ['postal_code', 'locality'],
  ['postal_code', 'region'],
  ['locality', 'region'],
] as CyclicalDependencies;

export const addressValidation = validator(
  (intl, field: keyof AddressValues) => {
    const { required } = validators(intl);
    return yup.string().when(getAddressDependentFields(field), {
      is: anyFieldIsPopulated,
      then: (schema) => required(schema),
    });
  },
);
