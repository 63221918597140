import type { Placement } from '@react-types/overlays';
import cx from 'classnames';
import type { ComponentProps, FunctionComponent, ReactElement } from 'react';

import DotsIcon from 'shared/assets/svgs/horizontal-dots.svg?react';
import { Menu } from 'shared/tempo/@labs/molecule/Menu';
import { IconButton } from 'shared/tempo/atom/IconButton';

import { additionalActionsIcon, pressed } from './AdditionalActions.css';

type Props = {
  onAction: (key: React.Key) => void;
  children: ReactElement;
  isDisabled?: boolean;
  placement?: Placement;
  Icon?: FunctionComponent<ComponentProps<'svg'>>;
};

export function AdditionalActions({
  children,
  onAction,
  isDisabled,
  placement = 'bottom end',
  Icon = DotsIcon,
}: Props) {
  return (
    <Menu.Trigger
      placement={placement}
      onAction={onAction}
      menu={children}
      isDisabled={isDisabled}
    >
      {({ isOpen }) => (
        <IconButton variant="tertiary" isDisabled={isDisabled} size="small">
          <Icon
            className={cx({
              [additionalActionsIcon.default]: !isDisabled,
              [additionalActionsIcon.disabled]: isDisabled,
              [pressed]: isOpen,
            })}
          />
        </IconButton>
      )}
    </Menu.Trigger>
  );
}
