import cx from 'classnames';
import IMask from 'imask';
import isNaN from 'lodash/isNaN';
import trim from 'lodash/trim';
import trimStart from 'lodash/trimStart';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import type { TextFieldProps } from 'deprecated/mui';
import { TextField } from 'deprecated/mui';
import type { InputMaskEvent } from 'shared/common/Form/fields/shared/InputMask';
import { InputMask } from 'shared/common/Form/fields/shared/InputMask';
import {
  addInputClassname,
  addInputWrapperClassname,
} from 'shared/common/Input';

import { button, buttonContainer, inputRoot, textInput } from './style.css';
import {
  joinTime,
  twelveToTwentyFourHourTime,
  twentyFourHourToTwelveHourTime,
} from './timePicker.utils';
import type { MeridianValue, TwentyFourHourTime } from './types';
import { Meridian } from './types';

type Props = {
  onChange: (value: string) => void;
  value?: string;
  error?: boolean;
  className?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
} & Omit<TextFieldProps, 'onChange'>;

export function TimePicker({
  onChange,
  value,
  error = false,
  className,
  ...rest
}: Props) {
  const inputProps = addInputClassname({
    'aria-label': rest.name,
    ...rest.inputProps,
  });
  const inputRef = useRef<HTMLInputElement>();
  const [time, setTime] = useState<string>('');
  const [focused, setFocused] = useState(isFocused(inputRef.current));
  const [meridian, setMeridian] = useState<MeridianValue | undefined>(
    undefined,
  );

  function invokeChange(
    timeVal: string,
    meridianVal?: MeridianValue,
    force?: boolean,
  ) {
    const inputTimeVal =
      inputRef.current?.value?.split(':').map(trim).join(':') || '';
    const valid24HrTime: Nullable<TwentyFourHourTime> =
      twelveToTwentyFourHourTime(
        force ? inputTimeVal || '' : timeVal,
        meridianVal,
      );
    if (valid24HrTime !== null || force) {
      onChange(valid24HrTime || '');
    }
  }

  useEffect(() => {
    const twelveHourTime = twentyFourHourToTwelveHourTime(value || '');
    if (twelveHourTime) {
      const [timeVal, meridianVal] = twelveHourTime;
      setTime(timeVal);
      setMeridian(meridianVal);
      invokeChange(timeVal, meridianVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const isEmptyTime = !time;
  return (
    <div style={{ position: 'relative' }}>
      <TextField
        {...rest}
        placeholder="hh : mm"
        // Preserves focus when switching between masked component and the
        // placeholder (unmasked)
        autoFocus={focused}
        inputRef={inputRef}
        className={cx(
          addInputWrapperClassname(rest.isDisabled, rest.isReadOnly, !!error),
          inputRoot,
          className,
        )}
        error={error}
        value={time ?? ''}
        InputProps={{
          ...rest.InputProps,
          classes: { input: textInput },
          endAdornment: (
            <Buttons
              val={meridian}
              placeholder={!time && !meridian}
              onChange={(val) => {
                setMeridian(val);
                invokeChange(time, val, true);
              }}
            />
          ),
          // In order to properly show placeholder, don't use input mask if
          // time input is empty
          ...(!isEmptyTime && { inputComponent: InputMask }),
          inputProps: {
            ...inputProps,
            ...(!isEmptyTime && {
              overwrite: true,
              autofix: true,
              mask: 'h{space:space}mm',
              // https://github.com/uNmAnNeR/imaskjs/issues/53#issuecomment-633483187
              blocks: {
                h: {
                  mask(val: string) {
                    if (!val.match(/^[0-9]*$/)) {
                      return false;
                    }
                    const intValue = Number.parseInt(val, 10);
                    if (isNaN(intValue)) {
                      return false;
                    }
                    return val.length < 3 && intValue >= 0 && intValue <= 12;
                  },
                  lazy: true,
                  maxLength: 2,
                },
                mm: {
                  mask: IMask.MaskedRange,
                  placeholderChar: '0',
                  from: 0,
                  to: 59,
                  maxLength: 2,
                },
                space: {
                  mask: ' ',
                },
              },
            }),
            name: rest.name,
          },
        }}
        onFocus={() => setFocused(true)}
        onBlur={() => {
          invokeChange(time, meridian, true);
          setFocused(false);
        }}
        onKeyPress={(e) => {
          const input = inputRef.current;
          const lowerKey = e.key.toLowerCase();
          if (lowerKey === 'a') {
            setMeridian(Meridian.AM);
          } else if (lowerKey === 'p') {
            setMeridian(Meridian.PM);
          }

          if (input?.selectionStart === 0 && lowerKey === '0') {
            // Don't allow zero as first entry
            e.preventDefault();
          }

          if (!/^[0-9]$/i.test(e.key)) {
            // Ensure key input only allows numbers
            e.preventDefault();
          }
        }}
        onChange={(event) => {
          if (event.target instanceof HTMLInputElement) {
            // This means we are not using the input mask, event is of HTMLInputElement type
            const newVal = event.target.value;
            setTime(newVal);
          } else {
            // Need to force the cast since we pass in the InputMask component
            const { target } = event as unknown as InputMaskEvent;
            const [hours, mins] = target.value.split(':');
            const newTime = joinTime(trimStart(hours, '0'), mins);
            setTime(newTime);
            invokeChange(newTime, meridian);
          }
        }}
      />
    </div>
  );
}

function Buttons({
  val,
  onChange,
  placeholder = false,
}: {
  val?: MeridianValue;
  onChange: (val: MeridianValue) => void;
  placeholder: boolean;
}) {
  return (
    <span className={buttonContainer}>
      <div
        role="button"
        tabIndex={0}
        className={cx(button.base, {
          [button.selected]: val === Meridian.AM,
          [button.placeholder]: placeholder,
        })}
        // Prevent losing input focus on button click
        onMouseDown={(e) => e.preventDefault()}
        onClick={() => onChange(Meridian.AM)}
        onKeyPress={() => onChange(Meridian.AM)}
      >
        <FormattedMessage
          defaultMessage="am"
          description="Acronymn for antemeridian"
        />
      </div>
      <div
        role="button"
        tabIndex={0}
        className={cx(button.base, {
          [button.selected]: val === Meridian.PM,
          [button.placeholder]: placeholder,
        })}
        // Prevent losing input focus on button click
        onMouseDown={(e) => e.preventDefault()}
        onClick={() => onChange(Meridian.PM)}
        onKeyPress={() => onChange(Meridian.PM)}
      >
        <FormattedMessage
          defaultMessage="pm"
          description="Acronymn for postmeridian"
        />
      </div>
    </span>
  );
}

function isFocused(el?: Element) {
  return document.activeElement === el;
}
