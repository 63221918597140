import { QueryClient } from 'react-query';
import type { QueryFunctionContext } from 'react-query/types/core/types';

import { isOfType } from 'lang.utils';
import type { InitReq } from 'shared/generated/grpcGateway/fetch.pb';
import type {
  AipPaginatedData,
  PaginatedData,
} from 'shared/types/pagination.types';
import Session from 'shared/utils/session';

import type { GrpcGenericRequest } from '../shared/hooks/queries/patients-grpc';

const PAGE_SIZE = 50;

class ReactQueryError extends Error {
  name: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(...args: any[]) {
    super(...args);
    this.name = 'ReactQueryError';
  }
}

export const grpcQueryFunction = async <T = unknown>(
  ctx: QueryFunctionContext,
  rpc: (req: GrpcGenericRequest, initReq?: InitReq | undefined) => Promise<T>,
  grpcReq: GrpcGenericRequest,
): Promise<T> => {
  if (typeof ctx.queryKey[0] !== 'string') {
    throw new ReactQueryError(
      `Invalid query key 'queryKey[0]' must be an endpoint url or` +
        ` part of it when using default query function`,
    );
  }

  return rpc(grpcReq);
};

export const grpcGetNextPageParam = (lastPage: unknown) =>
  (lastPage as AipPaginatedData<unknown, string>).nextPageToken || undefined;

export const defaultQueryFunction = async ({
  queryKey,
  pageParam = 1,
}: QueryFunctionContext) => {
  if (typeof queryKey[0] !== 'string') {
    throw new ReactQueryError(
      `Invalid query key 'queryKey[0]' must be an endpoint url or` +
        ` part of it when using default query function`,
    );
  }
  const { type, url } = getQueryTypeAndUrlFromQueryKey(queryKey);
  if (type === 'infinite') {
    const [urlWithoutParams, queryString] = url.split('?');
    const queryParams = new URLSearchParams(queryString);

    if (!queryParams.has('page')) {
      queryParams.append('page', String(pageParam));
    }
    if (!queryParams.has('page_size')) {
      queryParams.append('page_size', String(PAGE_SIZE));
    }

    return (
      await Session.Api.get(`${urlWithoutParams}?${queryParams.toString()}`)
    ).data;
  }

  // For paginated calls to the Go RPM api, we do not set the query
  // function from the query key like we do above since we use the
  // generated grpc-gateway client.
  // The default page size is set on the backend, and the pageToken
  // should be passed from context to the query function. For an example
  // see `usePatientAcuityAssessments`

  return (await Session.Api.get(url)).data;
};

export const defaultGetNextPageParam = (lastPage: unknown) => {
  if (isOfType<PaginatedData<unknown>>(lastPage, 'metadata')) {
    return lastPage.metadata.page < lastPage.metadata.pages
      ? lastPage.metadata.page + 1
      : undefined;
  }

  // This type is for paginated calls to the Go RPM api, which follows the
  // Google AIP standard (https://google.aip.dev/158)
  if (isOfType<AipPaginatedData<unknown, string>>(lastPage, 'nextPageToken')) {
    return lastPage.nextPageToken || undefined;
  }
  throw new ReactQueryError(
    'lastPage which is not a PaginatedData received for an infinite query',
  );
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFunction,
      getNextPageParam: defaultGetNextPageParam,
      refetchOnWindowFocus: false,
    },
  },
});

/**
 * Supports query keys for finite and infinite queries,
 * with whole endpoint as first element like
 * [`/pms/api/v1/patients?sort_by=${sortBy}&order_by=${orderBy}`, 'infinite'],
 * or with endpoint in parts with query params object after them like:
 * [
 *     'pms',
 *     'api',
 *     'v1',
 *     'patients',
 *     {
 *       sort_by: sortBy,
 *       order_by: orderBy,
 *     },
 *     'infinite',
 *   ]
 */
function getQueryTypeAndUrlFromQueryKey(queryKey: Readonly<Array<unknown>>) {
  const type =
    queryKey[queryKey.length - 1] === 'infinite' ? 'infinite' : 'finite';
  const queryKeyWithoutType =
    type === 'infinite' ? queryKey.slice(0, -1) : queryKey;
  const queryParams =
    typeof queryKeyWithoutType[queryKeyWithoutType.length - 1] === 'object'
      ? queryKeyWithoutType[queryKeyWithoutType.length - 1]
      : undefined;
  const queryKeyWithoutTypeAndQueryParams = queryParams
    ? queryKeyWithoutType.slice(0, -1)
    : queryKeyWithoutType;
  const queryParamString = queryParams
    ? `?${parseQueryParams(queryParams as {}).toString()}`
    : '';
  const url = `${queryKeyWithoutTypeAndQueryParams.join(
    '/',
  )}${queryParamString}`;

  return { type, url };
}

export type QueryParams = Record<
  string,
  Array<string> | Array<number> | string | number | boolean
>;

export function getQueryParamsFromQueryKey(queryKey: Readonly<Array<unknown>>) {
  const type =
    queryKey[queryKey.length - 1] === 'infinite' ? 'infinite' : 'finite';
  const queryKeyWithoutType =
    type === 'infinite' ? queryKey.slice(0, -1) : queryKey;
  const queryParams =
    typeof queryKeyWithoutType[queryKeyWithoutType.length - 1] === 'object'
      ? (queryKeyWithoutType[queryKeyWithoutType.length - 1] as QueryParams)
      : undefined;

  return queryParams;
}

function parseQueryParams(params: {}) {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (!Array.isArray(value)) {
      queryParams.append(key, String(value));
      return;
    }

    value.forEach((v) => queryParams.append(key, v));
  });

  return queryParams;
}
