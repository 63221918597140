import { useIntl } from 'react-intl';

import { CN_SECTIONS_MAP } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/metadata';
import type { SecondarySymptomsFormFields } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/SecondarySymptoms';
import type { SymptomsFormFields } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/Symptoms';
import type { CNVisitType } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/types';
import { isCnNote } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/note.utils';
import type { SymptomsInputs } from 'pages/patients/patientDetails/ui/Notes/NotePreview/formatSymptomsInputs.util';
import { EncounterModuleId } from 'pages/patients/patientDetails/ui/Notes/Notes.types';
import { getInstance } from 'pages/patients/patientDetails/ui/Notes/utils/encounterModuleInstanceUtils';
import type { WizardFormData } from 'shared/common/Wizard/state';
import { getWizardStepValues } from 'shared/common/Wizard/state';
import type { Note } from 'shared/types/note.types';

export enum NoteSymptom {
  Bloating,
  ChangeInVision,
  ChestPain,
  Confusion,
  DecreasedAppetite,
  Dizziness,
  ExcessiveHunger,
  Fainting,
  FocalWeakness,
  FootOrAnkleSwelling,
  FrequentUrination,
  Headache,
  IncreasedThirst,
  Nausea,
  Other,
  ShortnessOfBreath,
  SlurredSpeech,
  Sweating,
  Syncope,
  Weakness,
}

export function getSymptomsFromNote(note: Note) {
  const symptoms = isCnNote(note)
    ? getSymptomsFromCnNote(note)
    : getSymptomsFromEncounterNote(note);
  return [...new Set(symptoms)];
}

export function useNoteSymptomI18nMap(): Record<NoteSymptom, string> {
  const intl = useIntl();

  return {
    [NoteSymptom.Bloating]: intl.formatMessage({ defaultMessage: 'Bloating' }),
    [NoteSymptom.ChangeInVision]: intl.formatMessage({
      defaultMessage: 'Changes in vision',
    }),
    [NoteSymptom.ChestPain]: intl.formatMessage({
      defaultMessage: 'Chest pain',
    }),
    [NoteSymptom.Confusion]: intl.formatMessage({
      defaultMessage: 'Confusion',
    }),
    [NoteSymptom.DecreasedAppetite]: intl.formatMessage({
      defaultMessage: 'Decreased appetite',
    }),
    [NoteSymptom.Dizziness]: intl.formatMessage({
      defaultMessage: 'Dizziness',
    }),
    [NoteSymptom.ExcessiveHunger]: intl.formatMessage({
      defaultMessage: 'Excessive hunger',
    }),
    [NoteSymptom.Fainting]: intl.formatMessage({ defaultMessage: 'Fainting' }),
    [NoteSymptom.FocalWeakness]: intl.formatMessage({
      defaultMessage: 'Focal weakness',
    }),
    [NoteSymptom.FootOrAnkleSwelling]: intl.formatMessage({
      defaultMessage: 'Foot or ankle swelling',
    }),
    [NoteSymptom.FrequentUrination]: intl.formatMessage({
      defaultMessage: 'Frequent urination',
    }),
    [NoteSymptom.Headache]: intl.formatMessage({ defaultMessage: 'Headache' }),
    [NoteSymptom.IncreasedThirst]: intl.formatMessage({
      defaultMessage: 'Increased thirst',
    }),
    [NoteSymptom.Nausea]: intl.formatMessage({ defaultMessage: 'Nausea' }),
    [NoteSymptom.Other]: intl.formatMessage({ defaultMessage: 'Other' }),
    [NoteSymptom.ShortnessOfBreath]: intl.formatMessage({
      defaultMessage: 'Shortness of breath',
    }),
    [NoteSymptom.SlurredSpeech]: intl.formatMessage({
      defaultMessage: 'Slurred speech',
    }),
    [NoteSymptom.Sweating]: intl.formatMessage({ defaultMessage: 'Sweating' }),
    [NoteSymptom.Syncope]: intl.formatMessage({ defaultMessage: 'Syncope' }),
    [NoteSymptom.Weakness]: intl.formatMessage({ defaultMessage: 'Weakness' }),
  };
}

const CN_FORM_KEY_TO_SYMPTOM_MAP: Record<string, NoteSymptom> = {
  newWeakness: NoteSymptom.Weakness,
  fainting: NoteSymptom.Fainting,
  slurredSpeech: NoteSymptom.SlurredSpeech,
  confusion: NoteSymptom.Confusion,
  chestPain: NoteSymptom.ChestPain,
  dizziness: NoteSymptom.Dizziness,
  'abdominalFullnessOrBloating-checked': NoteSymptom.Bloating,
  'changesInVision-checked': NoteSymptom.ChangeInVision,
  'chestPain-checked': NoteSymptom.ChestPain,
  'decreasedAppetite-checked': NoteSymptom.DecreasedAppetite,
  'dizziness-checked': NoteSymptom.Dizziness,
  'excessiveHunger-checked': NoteSymptom.ExcessiveHunger,
  'footOrAnkleSwelling-checked': NoteSymptom.FootOrAnkleSwelling,
  'frequentUrination-checked': NoteSymptom.FrequentUrination,
  'headache-checked': NoteSymptom.Headache,
  'increasedThirst-checked': NoteSymptom.IncreasedThirst,
  'nausea-checked': NoteSymptom.Nausea,
  'shortnessOfBreath-checked': NoteSymptom.ShortnessOfBreath,
  'sweating-checked': NoteSymptom.Sweating,
};

function getSymptomsFromCnNote(note: Note) {
  const cnNote = note.clinical_navigator_note?.[0];
  const sections = CN_SECTIONS_MAP[cnNote?.visitType as CNVisitType];
  const data = (cnNote?.form as WizardFormData)?.data;

  if (!cnNote || !sections || !data) {
    return [];
  }

  const symptomsData =
    getWizardStepValues(
      data,
      sections,
      '/symptoms',
      '/index',
    )<SymptomsFormFields>() ?? ({} as SymptomsFormFields);
  const secondarySymptomsData =
    getWizardStepValues(
      data,
      sections,
      '/symptoms',
      '/secondary',
    )<SecondarySymptomsFormFields>() ?? ({} as SecondarySymptomsFormFields);

  return [
    ...getSymptomListFromCnForm(symptomsData),
    ...getSymptomListFromCnForm(secondarySymptomsData),
  ].filter(Boolean) as NoteSymptom[];
}

function getSymptomListFromCnForm<
  T extends object = SymptomsFormFields | SecondarySymptomsFormFields,
>(symptomsData: T) {
  return Object.keys(symptomsData).map((key) => {
    const value = symptomsData[key as keyof T];

    if (value === true || value === 'true') {
      return CN_FORM_KEY_TO_SYMPTOM_MAP[key];
    }

    return null;
  });
}

const ENCOUNTER_INPUT_KEY_TO_SYMPTOM_MAP: Record<string, NoteSymptom> = {
  changes_in_vision: NoteSymptom.ChangeInVision,
  chest_pain: NoteSymptom.ChestPain,
  dizziness: NoteSymptom.Dizziness,
  focal_weakness: NoteSymptom.FocalWeakness,
  headache: NoteSymptom.Headache,
  shortness_of_breath: NoteSymptom.ShortnessOfBreath,
  slurred_speech: NoteSymptom.SlurredSpeech,
  syncope: NoteSymptom.Syncope,
};

function getSymptomsFromEncounterNote(note: Note) {
  const symptomsModule = getInstance<SymptomsInputs>(
    note.encounter_instances ?? [],
    EncounterModuleId.Symptoms,
  );
  const inputs = symptomsModule?.inputs;

  if (!inputs) {
    return [];
  }

  return Object.keys(inputs)
    .map((key) => {
      const input = inputs[key];
      const symptom = ENCOUNTER_INPUT_KEY_TO_SYMPTOM_MAP[key];

      if (typeof input === 'boolean') {
        return input ? symptom : null;
      }

      if (input.has_symptom) {
        return symptom;
      }

      return null;
    })
    .filter(Boolean) as NoteSymptom[];
}
