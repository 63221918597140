import React from 'react';
import { useIntl } from 'react-intl';

import FileMedical from 'shared/assets/svgs/fileMedical.svg?react';
import Message from 'shared/assets/svgs/message.svg?react';
import Question from 'shared/assets/svgs/question.svg?react';
import User from 'shared/assets/svgs/userThick.svg?react';
import VitalsIcon from 'shared/assets/svgs/vitals.svg?react';

import { Communications } from './Pages/Communications';
import { Orders } from './Pages/Orders';
import { PatientInfo } from './Pages/PatientInfo';
import { ReportIssue } from './Pages/ReportIssue';
import { Vitals } from './Pages/Vitals';
import { categoryList } from './Pages/page.css';

export const BASE_ROUTE = '/support-modal';

type Category = {
  component: React.FC;
  description: string;
  label: string;
  to: string;
  icon: React.ReactNode;
};

export function useCategories(): Category[] {
  const intl = useIntl();

  return [
    {
      component: Vitals,
      description: intl.formatMessage({
        defaultMessage: 'Delayed vitals, vitals not received, etc.',
      }),
      label: intl.formatMessage({ defaultMessage: 'Vitals' }),
      to: `${BASE_ROUTE}/vitals`,
      icon: <VitalsIcon className={categoryList.iconStroke} />,
    },
    {
      component: Communications,
      description: intl.formatMessage({
        defaultMessage: 'SMS and Email Communications',
      }),
      label: intl.formatMessage({ defaultMessage: 'Communications' }),
      to: `${BASE_ROUTE}/communications`,
      icon: <Message className={categoryList.iconFill} />,
    },
    {
      component: Orders,
      description: intl.formatMessage({
        defaultMessage: 'Patient orders, diagnosis, etc.',
      }),
      label: intl.formatMessage({ defaultMessage: 'Orders' }),
      to: `${BASE_ROUTE}/orders`,
      icon: <FileMedical className={categoryList.iconFill} />,
    },
    {
      component: PatientInfo,
      description: intl.formatMessage({
        defaultMessage: 'Patient data in Cadence',
      }),
      label: intl.formatMessage({ defaultMessage: 'Patient Information' }),
      to: `${BASE_ROUTE}/patient-info`,
      icon: <User className={categoryList.iconFill} />,
    },
    {
      component: ReportIssue,
      description: intl.formatMessage({
        defaultMessage: 'Click here for all other issues',
      }),
      label: intl.formatMessage({ defaultMessage: 'Something Else' }),
      to: `${BASE_ROUTE}/other`,
      icon: <Question className={categoryList.iconFill} />,
    },
  ];
}
