import { useLayoutEffect, useState } from 'react';

import type { TabListProps } from 'deprecated/mui';
import { TabList as MuiTabList } from 'deprecated/mui';

import { ACTION_STRIP_ID } from '../Page/Header';

const PrimaryBlue = '#3975CD';

/** @deprecated Use Tempo Tabs instead */
export function TabList(props: TabListProps) {
  const [stickyTop, setStickyTop] = useState(0);

  // Ensure that we "stick" tabs underneath the action strip as we scroll
  // via appropriate 'top' value
  useLayoutEffect(() => {
    const actionStrip = document.getElementById(ACTION_STRIP_ID);
    if (actionStrip) {
      setStickyTop(actionStrip.getBoundingClientRect().height);
    }
  }, []);

  return (
    <MuiTabList
      sx={{
        borderBottom: '1px solid #e8e8e8',
        backgroundColor: 'white',
        position: 'sticky',
        top: stickyTop,
        zIndex: 1, // $zMainSectionHeader
        '& .MuiTabs-indicator': {
          backgroundColor: PrimaryBlue,
        },
        '& .MuiTab-wrapper': {
          fontWeight: 500,
          fontSize: '14px',
          textTransform: 'none',
        },
        '& .Mui-selected': {
          color: PrimaryBlue,
          fontWeight: 800,
        },
      }}
      {...props}
    />
  );
}
