import type { IntlShape } from 'react-intl';

import type { FeatureFlagSet } from 'shared/types/featureFlags.types';
import { ReadingDataType } from 'shared/types/patientSummary.types';
import type { AllBloodGlucoseTagType } from 'shared/types/tagsAndThreshold.types';
import {
  BloodGlucoseMonthlyAverageTagType,
  BloodGlucoseTagType,
  BloodGlucoseWeeklyPercentageOutOfRangeTagType,
  WeeklyPercentageOutOfRangeThresholdKey,
} from 'shared/types/tagsAndThreshold.types';
import type { BloodGlucoseVital } from 'shared/types/vitals.types';
import { VitalType } from 'shared/types/vitals.types';

import type {
  AlertLoggerInfo,
  BgFormatterProps,
} from '../../AlertDescription.types';
import { BloodGlucoseAlertFormatter } from '../alertDescriptionFormatters';
import type {
  AlertDescriptionStrategy,
  TagRelatedValuesRequest,
} from '../baseAlertDescriptionStrategy';
import { BaseAlertDescriptionStrategy } from '../baseAlertDescriptionStrategy';
import { getComparisonOperator } from '../sharedAlertsUtils';

export class BloodGlucoseAlertStrategy
  extends BaseAlertDescriptionStrategy<
    AllBloodGlucoseTagType,
    BloodGlucoseVital
  >
  implements AlertDescriptionStrategy
{
  constructor(intl: IntlShape, flags: FeatureFlagSet) {
    super(
      VitalType.BloodGlucose,
      [
        ...Object.values(BloodGlucoseTagType),
        ...Object.values(BloodGlucoseMonthlyAverageTagType),
        ...Object.values(BloodGlucoseWeeklyPercentageOutOfRangeTagType),
      ],
      new BloodGlucoseAlertFormatter(intl, flags),
      ReadingDataType.BloodGlucose,
    );
  }

  protected getTagRelatedValues(
    request: TagRelatedValuesRequest<AllBloodGlucoseTagType, BloodGlucoseVital>,
    _: BloodGlucoseVital[],
    alertLoggerInfo: AlertLoggerInfo,
  ): BgFormatterProps {
    const currentReading = request.relatedReading.glucose_level;
    const threshold = this.getThresholdValue(
      request.tag,
      request.relatedReading,
      alertLoggerInfo,
    );
    const operator = BloodGlucoseAlertStrategy.isBgAvgRelatedTags(request.tag)
      ? BloodGlucoseAlertStrategy.getAvgTrendOperator(request.tag)
      : getComparisonOperator(currentReading, threshold, true);

    return {
      operator,
      values: {
        currentReading,
        threshold,
      },
    };
  }

  protected tagThresholdLookUp(
    tag: AllBloodGlucoseTagType,
    patientId: string,
  ): string {
    if (tag.includes('WEEKLY_PERCENTAGE_HIGH_P0')) {
      return WeeklyPercentageOutOfRangeThresholdKey.HighP0;
    }
    if (tag.includes('WEEKLY_PERCENTAGE_HIGH_P1')) {
      return WeeklyPercentageOutOfRangeThresholdKey.HighP1;
    }
    if (tag.includes('WEEKLY_PERCENTAGE_HIGH_P2')) {
      return WeeklyPercentageOutOfRangeThresholdKey.HighP2;
    }

    return super.tagThresholdLookUp(tag, patientId);
  }

  private static trendTags = [
    BloodGlucoseWeeklyPercentageOutOfRangeTagType.BloodGlucoseWeeklyPercentageOutOfRangeHighP0,
    BloodGlucoseWeeklyPercentageOutOfRangeTagType.BloodGlucoseWeeklyPercentageOutOfRangeHighP1,
    BloodGlucoseWeeklyPercentageOutOfRangeTagType.BloodGlucoseWeeklyPercentageOutOfRangeHighP2,
    BloodGlucoseMonthlyAverageTagType.BloodGlucoseMonthlyAverageHighP0,
    BloodGlucoseMonthlyAverageTagType.BloodGlucoseMonthlyAverageHighP1,
    BloodGlucoseMonthlyAverageTagType.BloodGlucoseMonthlyAverageHighP2,
  ];

  private static isBgAvgRelatedTags(tag: AllBloodGlucoseTagType) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return BloodGlucoseAlertStrategy.trendTags.includes(tag as any);
  }

  private static getAvgTrendOperator(tag: AllBloodGlucoseTagType): string {
    return tag.includes('HIGH') ? '>' : '<';
  }
}
