import { store } from 'store/store';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAILURE = 'LOGIN_REQUEST_FAILURE';
export const LOGIN_REQUEST_SSO = 'LOGIN_REQUEST_SSO';
export const LOGIN_REQUEST_SSO_SUCCESS = 'LOGIN_REQUEST_SSO_SUCCESS';
export const LOGIN_REQUEST_SSO_FAILURE = 'LOGIN_REQUEST_SSO_FAILURE';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD';
export const FORCE_CHANGE_PASSWORD_SUCCESS = 'FORCE_CHANGE_PASSWORD_SUCCESS';
export const FORCE_CHANGE_PASSWORD_FAILURE = 'FORCE_CHANGE_PASSWORD_FAILURE';
export const CONFIRM_USER = 'CONFIRM_USER';
export const CONFIRM_USER_SUCCESS = 'CONFIRM_USER_SUCCESS';
export const CONFIRM_USER_FAILURE = 'CONFIRM_USER_FAILURE';
export const SIGN_UP_REQUEST_SSO = 'SIGN_UP_REQUEST_SSO';
export const SIGN_UP_REQUEST_SSO_SUCCESS = 'SIGN_UP_REQUEST_SSO_SUCCESS';
export const SIGN_UP_REQUEST_SSO_FAILURE = 'SIGN_UP_REQUEST_SSO_FAILURE';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const RAISE_ERROR = 'RAISE_ERROR';

export const requestLogin = (
  email: string,
  password: string,
): TActionCreatorStringParam => ({
  email,
  password,
  type: LOGIN_REQUEST,
});

export const loginRequested = (payload: any): TLoginResponse => ({
  payload,
  type: LOGIN_REQUEST_SUCCESS,
});

export const loginFailed = (
  payload: TResponseError,
): TActionCreatorResponseError => ({
  payload,
  type: LOGIN_REQUEST_FAILURE,
});

export const requestLogout = (): TActionCreator => ({
  type: LOGOUT,
});

export const logout = (): TActionCreator => ({
  type: LOGOUT_SUCCESS,
});

export const logoutFailure = (
  payload: TResponseError,
): TActionCreatorResponseError => ({
  payload,
  type: LOGOUT_FAILURE,
});

export const refreshToken = (
  payload: TRefreshTokenPayload,
): TRefreshTokenResponse => ({
  payload,
  type: REFRESH_TOKEN_SUCCESS,
});

export const refreshTokenFailure = (
  payload: TResponseError,
): TActionCreatorResponseError => {
  store.dispatch(logout());

  return {
    payload,
    type: REFRESH_TOKEN_FAILURE,
  };
};

export const forgotPassword = (email: string): TActionCreatorMultipleParam => ({
  email,
  type: FORGOT_PASSWORD,
});

export const forgotPasswordInitiated = (payload: any): any => ({
  payload,
  type: FORGOT_PASSWORD_SUCCESS,
});

export const forgotPasswordFailure = (
  payload: TResponseError,
): TActionCreatorResponseError => ({
  payload,
  type: FORGOT_PASSWORD_FAILURE,
});

export const forceChangePassword = (
  email: string,
  confirmationCode: string,
  proposedPassword: string,
): TActionCreatorMultipleParam => ({
  email,
  confirmationCode,
  proposedPassword,
  type: FORCE_CHANGE_PASSWORD,
});

export const passwordChanged = (payload: any): any => ({
  payload,
  type: FORCE_CHANGE_PASSWORD_SUCCESS,
});

export const forceChangePasswordFailure = (
  payload: TResponseError,
): TActionCreatorResponseError => ({
  payload,
  type: FORCE_CHANGE_PASSWORD_FAILURE,
});

export const confirmUser = (
  email: string,
  previousPassword: string,
  proposedPassword: string,
): TActionCreatorMultipleParam => ({
  email,
  previousPassword,
  proposedPassword,
  type: CONFIRM_USER,
});

export const userConfirmed = (payload: TLoginPayload): TLoginResponse => ({
  payload,
  type: CONFIRM_USER_SUCCESS,
});

export const confirmUserFailure = (
  payload: TResponseError,
): TActionCreatorResponseError => ({
  payload,
  type: CONFIRM_USER_FAILURE,
});

export const requestLoginSSO = (
  querystring: string,
): TActionCreatorStringParam => ({
  querystring,
  type: LOGIN_REQUEST_SSO,
});

export const loginSSORequested = (payload: any): any => ({
  payload,
  type: LOGIN_REQUEST_SSO_SUCCESS,
});

export const loginSSOFailed = (
  payload: TResponseError,
): TActionCreatorResponseError => ({
  payload,
  type: LOGIN_REQUEST_SSO_FAILURE,
});

export const requestSignUpSSO = (email: string): TActionCreatorStringParam => ({
  email,
  type: SIGN_UP_REQUEST_SSO,
});

export const SignUpSSORequested = (payload: any, headers: any): any => ({
  payload,
  headers,
  type: SIGN_UP_REQUEST_SSO_SUCCESS,
});

export const SignUpSSOFailed = (
  payload: TResponseError,
): TActionCreatorResponseError => ({
  payload,
  type: SIGN_UP_REQUEST_SSO_FAILURE,
});

export const updateEmail = (email: string): TActionCreatorStringParam => ({
  email,
  type: UPDATE_EMAIL,
});

export const raiseError = (): TActionCreator => ({
  type: RAISE_ERROR,
});
