import { FormattedMessage } from 'react-intl';

import type { SYMPTOMS_FORMATTED_MESSAGES } from '../NoteEditor/SymptomsForm';
import { SYMPTOMS_CAPITALIZED_FORMATTED_MESSAGES } from '../NoteEditor/SymptomsForm';
import type { TypeOfEncounter } from '../Notes.types';
import { isAdHocClinicalEncounterType } from '../utils/encounterTypeUtils';

type SymptomValue = {
  has_symptom?: boolean;
  description?: string;
};

export type SymptomsInputs = {
  [key: string]: SymptomValue | boolean;
};

const OTHER_SYMPTOM_KEY = 'other';

export const formatSymptomsInputs = (
  inputs: SymptomsInputs,
  title: string,
  encounterType: TypeOfEncounter | undefined,
  isPatientNoShow: boolean,
  symptomsToReport: boolean | undefined,
) => {
  if (symptomsToReport === undefined) return null;

  const header = <b>{title}:</b>;

  if (symptomsToReport === false) {
    if (isPatientNoShow) {
      return null;
    }

    return (
      <>
        {header}
        <div>{noSymptomsToReportMessage(encounterType)}</div>
      </>
    );
  }

  const sortedSymptomInputs = getSortedSymptomInputs(inputs);
  const symptomsExperienced = sortedSymptomInputs.filter(
    ([, value]) => value.has_symptom,
  );

  return (
    <>
      {header}
      {symptomsExperienced.map(([symptomKey, symptomValue]) => (
        <div key={symptomKey} data-testid={`symptom-text-${symptomKey}`}>
          {getEnumCapitalizedFormattedMessage(symptomKey)}
          {symptomValue.description && `: ${symptomValue.description}`}
        </div>
      ))}
    </>
  );
};

function noSymptomsToReportMessage(encounterType?: TypeOfEncounter) {
  if (isAdHocClinicalEncounterType(encounterType)) {
    return (
      <FormattedMessage defaultMessage="No disease-specific symptoms were reported." />
    );
  }
  return (
    <FormattedMessage defaultMessage="A full disease-specific review of symptoms was completed and was negative." />
  );
}

function getSortedSymptomInputs(inputs: SymptomsInputs) {
  const symptomInputs = Object.entries(inputs).filter(
    ([, value]) => typeof value !== 'boolean' && value,
  ) as Array<[string, SymptomValue]>;
  const otherSymptomInput = symptomInputs.filter(
    ([symptomKey]) => symptomKey === OTHER_SYMPTOM_KEY,
  ) as [[string, SymptomValue]];
  return [
    ...symptomInputs
      .filter(([symptomKey]) => symptomKey !== OTHER_SYMPTOM_KEY)
      .sort(),
    ...otherSymptomInput,
  ];
}

const getEnumCapitalizedFormattedMessage = (enumText: string) =>
  SYMPTOMS_CAPITALIZED_FORMATTED_MESSAGES[
    enumText as keyof typeof SYMPTOMS_FORMATTED_MESSAGES
  ] || enumText;
