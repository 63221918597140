import { useParams } from 'react-router-dom';

import { LoadingPlaceholder } from 'shared/common/LoadingPlaceholder';
import { useFlags } from 'shared/hooks';
import { useCaregivers, usePatientDetails } from 'shared/hooks/queries';
import type { RouteParam } from 'shared/types/route.types';

import { AppointmentsReminders } from './AppointmentReminders';
import { preferenceFieldStyles } from './CommunicationPreferences.css';
import { PreferredContact } from './PreferredContact';
import { VitalReminders } from './VitalReminders';
import { WeeklyEmailSummary } from './WeeklyEmailSummary';

export function CommunicationPreferences() {
  const { smsAppointmentsReminders } = useFlags();
  const { patientId }: RouteParam = useParams();
  const { data: patientDetails, isLoading: isLoadingPatientDetails } =
    usePatientDetails(patientId, false);
  const { data: caregivers, isLoading: isLoadingCaregivers } =
    useCaregivers(patientId);

  const patientNotifications = patientDetails?.notification_settings;

  return (
    <LoadingPlaceholder
      isLoading={isLoadingPatientDetails || isLoadingCaregivers}
    >
      {patientDetails && caregivers && (
        <PreferredContact patient={patientDetails} caregivers={caregivers} />
      )}
      {patientDetails && patientNotifications && smsAppointmentsReminders && (
        <div data-testid="appointment-reminders-section">
          <AppointmentsReminders
            patient={patientDetails}
            patientNotifications={patientNotifications}
            customClass={preferenceFieldStyles}
          />
        </div>
      )}
      {patientDetails && patientNotifications && (
        <div>
          <VitalReminders
            patient={patientDetails}
            patientNotifications={patientNotifications}
            customClass={preferenceFieldStyles}
          />
        </div>
      )}
      {patientDetails && patientNotifications && (
        <div data-testid="weekly-email-summary-section">
          <WeeklyEmailSummary
            patient={patientDetails}
            patientNotifications={patientNotifications}
            customClass={preferenceFieldStyles}
          />
        </div>
      )}
    </LoadingPlaceholder>
  );
}
