import type { ReactNode } from 'react';

import type { TooltipProps } from 'deprecated/mui';
import { Tooltip as MUIToolTip } from 'deprecated/mui';

import { customArrow, customTooltip } from './Tooltip.css';

type Props = {
  children: ReactNode;
  tooltipMessage?: ReactNode;
  tooltipPlacement?: TooltipProps['placement'];
  disabled?: boolean;
  arrow?: boolean;
  className?: string;
  tooltipClassName?: string;
  arrowClassName?: string;
};
/**
 * @deprecated This component has been deprecated in favor of
 * the shared/tempo/atom/Tooltip component
 */
export const Tooltip = ({
  children,
  tooltipMessage,
  tooltipPlacement,
  disabled,
  arrow = true,
  className,
  tooltipClassName,
  arrowClassName,
}: Props) => {
  if (!tooltipMessage || disabled) {
    return <>{children}</>;
  }
  return (
    <MUIToolTip
      classes={{
        tooltip: tooltipClassName ?? customTooltip,
        arrow: arrowClassName ?? customArrow,
      }}
      title={tooltipMessage}
      placement={tooltipPlacement}
      arrow={arrow}
    >
      <span className={className}>{children}</span>
    </MUIToolTip>
  );
};
