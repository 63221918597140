import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from 'shared/common/Wizard/state';

import { CYCLE_17_INIT_VISIT_SECTIONS } from '../../metadata';
import { SharedMedReview } from '../../shared/MedReview';
import type { MedReviewFormFields } from '../../shared/MedReview/formConfig';
import { getMedReviewFormConfig } from '../../shared/MedReview/formConfig';
import { cycle17InitVisitPath } from '../paths';

export function MedReview() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<MedReviewFormFields>(
    cycle17InitVisitPath('/med-review', '/index'),
    getMedReviewFormConfig(intl),
  );

  return (
    <SharedMedReview
      form={form}
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      nextPath={cycle17InitVisitPath('/scheduling', '/index')}
    />
  );
}
