import { FormattedMessage } from 'react-intl';

import { getEhrName } from 'shared/common/Ehr';
import type { EHR } from 'shared/types/ehr.types';

import { ConfirmationDialog } from './NoteEditor/dialogs';
import { useRepublishNote } from './note.queries';

export function NoteRepublishConfirmationDialog({
  closeNoteRepublishDialog,
  ehr,
  isNoteRepublishOpen,
  noteId,
  patientId,
}: {
  closeNoteRepublishDialog: () => void;
  ehr?: EHR;
  isNoteRepublishOpen: boolean;
  noteId: string;
  patientId: string;
}) {
  const republishNote = useRepublishNote();
  const handleRepublishNote = () => {
    closeNoteRepublishDialog();
    republishNote.mutate({ noteId, patientId });
  };

  return (
    <ConfirmationDialog
      isOpen={isNoteRepublishOpen}
      onCancel={closeNoteRepublishDialog}
      onConfirm={handleRepublishNote}
      confirmButtonText={
        <FormattedMessage
          defaultMessage="Republish to {ehr}"
          values={{ ehr: getEhrName(ehr) }}
        />
      }
      dialogTitle={<FormattedMessage defaultMessage="Republish note" />}
      dialogDescription={
        <FormattedMessage defaultMessage="The note's sync status will update in the notes list when complete. Would you like to proceed?" />
      }
    />
  );
}
