import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { AdditionalActions } from 'shared/common/AdditionalActions';
import { Menu } from 'shared/tempo/@labs/molecule/Menu';

import { ReopenTaskModal } from './ReopenTaskModal';

enum ActionTypes {
  ReopenTask = 'reopen_task',
}

type Props = {
  onReopen: () => void;
  isDisabled?: boolean;
};

export function ResolvedActions({ onReopen, isDisabled }: Props) {
  const [showReopenTaskModal, setShowReopenTaskModal] = useState(false);
  return (
    <>
      <AdditionalActions
        isDisabled={isDisabled}
        onAction={(key) => {
          if (key === ActionTypes.ReopenTask) {
            setShowReopenTaskModal(true);
          }
        }}
      >
        <Menu>
          <Menu.Item key={ActionTypes.ReopenTask}>
            <FormattedMessage defaultMessage="Reopen task" />
          </Menu.Item>
        </Menu>
      </AdditionalActions>
      <ReopenTaskModal
        open={showReopenTaskModal}
        onClose={() => setShowReopenTaskModal(false)}
        onReopen={onReopen}
      />
    </>
  );
}
