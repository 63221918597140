import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import { validators } from 'shared/common/Form/validations';

export type TobaccoUseFormFields = {
  currentTobaccoUse: 'true' | 'false';
  currentTobaccoUseDescription?: string;
  pastTobaccoUse: 'true' | 'false';
  pastTobaccoUseDescription?: string;
};

export function getTobaccoUseFormConfig(
  intl: IntlShape,
  values?: TobaccoUseFormFields,
) {
  const { required, maxLengthString } = validators(intl);

  return {
    fields: {
      currentTobaccoUse: {
        defaultValue: values?.currentTobaccoUse,
        validation: required(yup.string().oneOf(['true', 'false'])),
      },
      currentTobaccoUseDescription: {
        defaultValue: values?.currentTobaccoUseDescription,
        validation: maxLengthString({ maxLength: 255 }).when(
          'currentTobaccoUse',
          {
            is: 'true',
            then: required,
          },
        ),
      },
      pastTobaccoUse: {
        defaultValue: values?.pastTobaccoUse,
        validation: yup
          .string()
          .oneOf(['true', 'false'])
          .when('currentTobaccoUse', {
            is: 'false',
            then: required,
          }),
      },
      pastTobaccoUseDescription: {
        defaultValue: values?.pastTobaccoUseDescription,
        validation: maxLengthString({ maxLength: 255 }).when('pastTobaccoUse', {
          is: 'true',
          then: required,
        }),
      },
    },
  };
}
