import cx from 'classnames';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Backdrop } from 'deprecated/mui';
import PlusIcon from 'shared/assets/svgs/plus.svg?react';
import { LoadingPlaceholder } from 'shared/common/LoadingPlaceholder';
import { fullHeight } from 'shared/jsStyle/utils.css';
import { Button } from 'shared/tempo/atom/Button';
import type { VitalsAlert } from 'shared/types/alert.types';

import { SegmentedTabs } from '../PatientNotesSidebarPanel/SegmentedTabs';
import {
  backdrop,
  drawerBorder,
  editingDrawer,
  header,
  newNoteButton,
  previewDrawer,
  scrollContainer,
  smallDrawer,
} from './NoteDrawer.css';
import { DrawerState } from './types';

type DrawerConfig = {
  backdrop: boolean;
  className: string;
};

const drawerConfigs: { [key: string]: DrawerConfig } = {
  [DrawerState.Collapsed]: { backdrop: false, className: smallDrawer },
  [DrawerState.Editing]: { backdrop: false, className: editingDrawer },
  [DrawerState.Preview]: { backdrop: true, className: previewDrawer },
};

type Props = {
  children: ReactNode;
  isLoading: boolean;
  onClickBackdrop?: () => void;
  onClickNewNote: () => void;
  onOpenAlertNote?: (alert: VitalsAlert) => void;
  state: DrawerState;
};

export function NoteDrawer({
  children,
  isLoading,
  onClickBackdrop,
  onClickNewNote,
  onOpenAlertNote,
  state,
}: Props) {
  const config = drawerConfigs[state];

  return (
    <>
      {config.backdrop && (
        <Backdrop open className={backdrop} onClick={onClickBackdrop} />
      )}
      <div className={cx(config.className, drawerBorder)}>
        <LoadingPlaceholder isLoading={isLoading} keepMounted>
          {state === DrawerState.Collapsed && (
            <div className={header}>
              <Button
                id="new-note-btn"
                className={newNoteButton}
                onPress={onClickNewNote}
                variant="tertiary"
              >
                <Button.Icon>
                  <PlusIcon />
                </Button.Icon>
                <FormattedMessage defaultMessage="New note" />
              </Button>
            </div>
          )}
          <div
            className={cx(fullHeight, {
              [scrollContainer]: state === DrawerState.Collapsed,
            })}
          >
            {children}
            <SegmentedTabs
              isHidden={state !== DrawerState.Collapsed}
              onClickNewNote={onClickNewNote}
              onOpenNewAlertNote={onOpenAlertNote}
            />
          </div>
        </LoadingPlaceholder>
      </div>
    </>
  );
}
