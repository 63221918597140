import type { IntlShape } from 'react-intl';

import { logger } from 'logger';
import type { AllBloodPressureTagType } from 'shared/types/tagsAndThreshold.types';
import { BloodPressureTagType } from 'shared/types/tagsAndThreshold.types';
import { convertToMmHg } from 'shared/utils/unit-helpers';

import type { BpFormatterProps } from '../../AlertDescription.types';
import {
  isBpMonthlyAvgTag,
  isBpMonthlyPercentageRelatedTag,
  isBpRelatedTags,
} from '../alertTagsUtil';
import { AlertFormatter } from './AlertFormatter';
import { isFullBpReading } from './alertDescriptionTypePredicates';

export class BloodPressureAlertFormatter extends AlertFormatter<
  AllBloodPressureTagType,
  BpFormatterProps
> {
  constructor(private intl: IntlShape) {
    super();
  }

  protected map: Map<AllBloodPressureTagType, string> = new Map<
    AllBloodPressureTagType,
    string
  >([
    [
      BloodPressureTagType.SystolicHighP1,
      this.intl.formatMessage({ defaultMessage: 'High SBP' }),
    ],
    [
      BloodPressureTagType.SystolicHighP0,
      this.intl.formatMessage({ defaultMessage: 'Very High SBP' }),
    ],
    [
      BloodPressureTagType.SystolicLowP0,
      this.intl.formatMessage({ defaultMessage: 'Very Low SBP' }),
    ],
    [
      BloodPressureTagType.SystolicLowP1,
      this.intl.formatMessage({ defaultMessage: 'Low SBP' }),
    ],
    [
      BloodPressureTagType.DiastolicHighP1,
      this.intl.formatMessage({ defaultMessage: 'High DBP' }),
    ],
    [
      BloodPressureTagType.DiastolicHighP0,
      this.intl.formatMessage({ defaultMessage: 'Very High DBP' }),
    ],
    [
      BloodPressureTagType.DiastolicLowP0,
      this.intl.formatMessage({ defaultMessage: 'Very Low DBP' }),
    ],
    [
      BloodPressureTagType.DiastolicLowP1,
      this.intl.formatMessage({ defaultMessage: 'Low DBP' }),
    ],
    [
      BloodPressureTagType.MeanArterialPressureLowP0,
      this.intl.formatMessage({ defaultMessage: 'Very Low MAP' }),
    ],
    [
      BloodPressureTagType.MonthlyPercentageHighP1,
      this.intl.formatMessage({ defaultMessage: 'High BP' }),
    ],
    [
      BloodPressureTagType.MonthlyAverageHighP0,
      this.intl.formatMessage({
        defaultMessage: 'Very High 30D SBP, Very High 30D DBP',
      }),
    ],
  ]);

  protected unitConverter = convertToMmHg;

  protected unit = 'mmHg';

  public getDescription(
    tag: AllBloodPressureTagType,
    props: BpFormatterProps,
  ): string {
    if (isBpRelatedTags(tag)) {
      const { operator, values, unit } = this.convertReadings(props);
      return `${values.currentReading} ${operator} ${values.threshold} ${unit}`;
    }

    if (isBpMonthlyPercentageRelatedTag(tag)) {
      const { values, unit } = this.convertReadings(props);

      if (tag === BloodPressureTagType.MonthlyPercentageHighP1) {
        let thresholdStr = '';
        // Hardcoding operator because this tag has different logic for whether
        // or not a reading is "higher"
        const operator = '>';

        if (isFullBpReading(values.threshold)) {
          thresholdStr = `${values.threshold.systolic}/${values.threshold.diastolic}`;
        } else {
          logger.error(
            'Monthly percentage tag has unexpected threshold structure',
          );
        }

        return this.intl.formatMessage(
          {
            defaultMessage: '50%+ Month BP {operator} {thresholdStr} {unit}',
          },
          {
            operator,
            thresholdStr,
            unit,
          },
        );
      }
    }

    if (isBpMonthlyAvgTag(tag)) {
      const { values, unit } = this.convertReadings(props);

      if (tag === BloodPressureTagType.MonthlyAverageHighP0) {
        let thresholdStr = '';
        const operator = '>';

        if (isFullBpReading(values.threshold)) {
          thresholdStr = `${values.threshold.systolic}/${values.threshold.diastolic}`;
        } else {
          logger.error(
            'Monthly average tag has unexpected threshold structure',
          );
        }

        return this.intl.formatMessage(
          {
            defaultMessage: 'Avg Month BP {operator} {thresholdStr} {unit}',
          },
          { operator, thresholdStr, unit },
        );
      }
    }

    return this.intl.formatMessage({ defaultMessage: 'Invalid Alert Type' });
  }
}
