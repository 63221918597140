import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import type { PhoneType } from 'shared/types/shared.types';
import { formatPhoneNumber, getPhoneType } from 'shared/utils/phone';

import { InfoRow } from '../InfoRow';

type ContactProps = {
  isPreferred: boolean;
  phoneNumber: string;
  phoneType: PhoneType;
};

export function Contact({ isPreferred, phoneNumber, phoneType }: ContactProps) {
  const intl = useIntl();
  const preferred = useMemo(
    () => intl.formatMessage({ defaultMessage: '(preferred)' }),
    [intl],
  );
  let leftText = getPhoneType(phoneType as PhoneType);

  if (isPreferred) {
    leftText = `${leftText} ${preferred}`;
  }

  return (
    <InfoRow
      leftSubdued
      left={leftText}
      right={formatPhoneNumber(phoneNumber)}
    />
  );
}
