import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from 'shared/common/Form';

import { EndEncounterReason } from './formConfig';

export function EndEarlyReasons() {
  const intl = useIntl();
  return (
    <div>
      <Form.RadioGroup
        required
        size={12}
        name="endEarlyReason"
        label={
          <FormattedMessage defaultMessage="Select the reason this visit was not completed" />
        }
        orientation="vertical"
      >
        <Form.Radio value={EndEncounterReason.PatientUnableToSpeak}>
          <FormattedMessage defaultMessage="Patient unable to speak at this time" />
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.SchedulingErrorDisenrollment}>
          <FormattedMessage defaultMessage="Scheduling error / disenrollment" />
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.PatientInNonCadenceState}>
          <FormattedMessage defaultMessage="Patient in non-Cadence state" />
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.PatientHospitalized}>
          <FormattedMessage defaultMessage="Patient currently hospitalized" />
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.PatientRefusedAppointment}>
          <FormattedMessage defaultMessage="Patient refused appointment" />
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.PatientDissatisfied}>
          <FormattedMessage defaultMessage="Patient dissatisfied" />
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.TimeConstraints}>
          <FormattedMessage defaultMessage="Time constraints" />
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.DeviceDelayTroubleshooting}>
          <FormattedMessage defaultMessage="Device delay / troubleshooting" />
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.Other}>
          <FormattedMessage defaultMessage="Other" />
        </Form.Radio>
      </Form.RadioGroup>
      <Form.TextArea
        size={12}
        rows={4}
        label={intl.formatMessage({ defaultMessage: 'Notes' })}
        name="endCallNotes"
        placeholder="Enter optional notes"
      />
    </div>
  );
}
