/*
 * Weight Tag Types
 */
import type { FullBpReading } from 'pages/patients/patientDetails/ui/AlertDescription/AlertDescription.types';

export enum DryWeightTagType {
  DryWeightGainP0 = 'DRY_WEIGHT_CHANGE_GAIN_P0',
  DryWeightLossP1 = 'DRY_WEIGHT_CHANGE_LOSS_P1',
}
export type AllDryWeightTagType = DryWeightTagType;

export enum WeightGainTagType {
  RapidWeightGainP0 = 'RAPID_WEIGHT_GAIN_P0',
  WeeklyWeightGainP0 = 'WEEKLY_WEIGHT_GAIN_P0',
}

export type AllWeightGainTagType = WeightGainTagType;

/*
 * Blood Pressure Tag Types
 */

export enum BloodPressureTagType {
  SystolicHighP1 = 'SYSTOLIC_HIGH_P1',
  SystolicHighP0 = 'SYSTOLIC_HIGH_P0',
  SystolicLowP0 = 'SYSTOLIC_LOW_P0',
  SystolicLowP1 = 'SYSTOLIC_LOW_P1',
  DiastolicHighP0 = 'DIASTOLIC_HIGH_P0',
  DiastolicHighP1 = 'DIASTOLIC_HIGH_P1',
  DiastolicLowP0 = 'DIASTOLIC_LOW_P0',
  DiastolicLowP1 = 'DIASTOLIC_LOW_P1',
  MeanArterialPressureLowP0 = 'MEAN_ARTERIAL_PRESSURE_LOW_P0',
  MonthlyPercentageHighP1 = 'BLOOD_PRESSURE_MONTHLY_PERCENTAGE_HIGH_P1',
  MonthlyAverageHighP0 = 'BLOOD_PRESSURE_MONTHLY_AVG_HIGH_P0',
}

export type AllBloodPressureTagType = BloodPressureTagType;

/*
 * Blood Glucose Tag Types
 */

export enum BloodGlucoseTagType {
  BloodGlucoseHighP1 = 'BLOOD_GLUCOSE_HIGH_P1',
  BloodGlucoseHighP0 = 'BLOOD_GLUCOSE_HIGH_P0',
  BloodGlucoseLowP0 = 'BLOOD_GLUCOSE_LOW_P0',
  BloodGlucoseLowP1 = 'BLOOD_GLUCOSE_LOW_P1',
}

export enum BloodGlucoseMonthlyAverageTagType {
  BloodGlucoseMonthlyAverageHighP0 = 'BLOOD_GLUCOSE_MONTHLY_AVG_HIGH_P0',
  BloodGlucoseMonthlyAverageHighP1 = 'BLOOD_GLUCOSE_MONTHLY_AVG_HIGH_P1',
  BloodGlucoseMonthlyAverageHighP2 = 'BLOOD_GLUCOSE_MONTHLY_AVG_HIGH_P2',
}

export enum BloodGlucoseWeeklyPercentageOutOfRangeTagType {
  BloodGlucoseWeeklyPercentageOutOfRangeHighP0 = 'BLOOD_GLUCOSE_WEEKLY_PERCENTAGE_HIGH_P0',
  BloodGlucoseWeeklyPercentageOutOfRangeHighP1 = 'BLOOD_GLUCOSE_WEEKLY_PERCENTAGE_HIGH_P1',
  BloodGlucoseWeeklyPercentageOutOfRangeHighP2 = 'BLOOD_GLUCOSE_WEEKLY_PERCENTAGE_HIGH_P2',
}

/* Not in use currently */
export type AllBloodGlucoseTagType =
  | BloodGlucoseTagType
  | BloodGlucoseMonthlyAverageTagType
  | BloodGlucoseWeeklyPercentageOutOfRangeTagType;

/*
 * Heart Rate Tag Types
 */

export enum HeartRateTagType {
  PulseHighP0 = 'PULSE_HIGH_P0',
  PulseLowP0 = 'PULSE_LOW_P0',
}

export type AllHeartRateTagType = HeartRateTagType;

/*
 * All Tag Types
 */

export type AllTagType =
  | AllBloodPressureTagType
  | AllHeartRateTagType
  | AllWeightGainTagType
  | AllBloodGlucoseTagType
  | AllDryWeightTagType;

export type TagType =
  | WeightGainTagType
  | DryWeightTagType
  | BloodPressureTagType
  | BloodGlucoseTagType
  | BloodGlucoseMonthlyAverageTagType
  | BloodGlucoseWeeklyPercentageOutOfRangeTagType
  | HeartRateTagType;

export enum GenericTagThresholdKey {
  HighP0 = 'high_p0',
  HighP1 = 'high_p1',
  HighP2 = 'high_p2',
  LowP0 = 'low_p0',
  LowP1 = 'low_p1',
  LowP2 = 'low_p2',
}

type MainArterialPressureLowThreshold = {
  [GenericTagThresholdKey.LowP0]: number;
};

type BloodPressureTagThreshold = {
  [GenericTagThresholdKey.HighP1]: number;
  [GenericTagThresholdKey.HighP0]: number;
  [GenericTagThresholdKey.LowP0]?: number;
  [GenericTagThresholdKey.LowP1]: number;
};

type BloodPressurePercentageOutOfRangeTagThreshold = {
  [GenericTagThresholdKey.HighP1]: FullBpReading;
};

type BloodGlucoseTagThreshold = {
  [GenericTagThresholdKey.HighP0]: number;
  [GenericTagThresholdKey.HighP1]: number;
  [GenericTagThresholdKey.LowP0]: number;
  [GenericTagThresholdKey.LowP1]: number;
};

type BloodGlucoseMonthlyAverageTagThreshold = {
  [GenericTagThresholdKey.HighP0]: number;
  [GenericTagThresholdKey.HighP1]: number;
  [GenericTagThresholdKey.HighP2]: number;
};

export enum WeeklyPercentageOutOfRangeThresholdKey {
  HighP0 = 'high_weekly_p0',
  HighP1 = 'high_weekly_p1',
  HighP2 = 'high_weekly_p2',
}

type BloodGlucosePercentageOutOfRangeTagThreshold = {
  [WeeklyPercentageOutOfRangeThresholdKey.HighP0]: number;
  [WeeklyPercentageOutOfRangeThresholdKey.HighP1]: number;
  [WeeklyPercentageOutOfRangeThresholdKey.HighP2]: number;
};

type HeartRateTagThreshold = {
  [GenericTagThresholdKey.HighP0]: number;
  [GenericTagThresholdKey.LowP0]: number;
};

export enum WeightTagThresholdKey {
  GainP0 = 'gain_p0',
  LossP1 = 'loss_p1',
}
type WeeklyWeightGainThreshold = {
  [WeightTagThresholdKey.GainP0]: number;
  [WeightTagThresholdKey.LossP1]?: number;
};

export type RapidWeightGainSubThreshold = {
  one_day_change: number;
  two_day_change: number;
};

type RapidWeightGainThreshold = {
  [WeightTagThresholdKey.GainP0]: RapidWeightGainSubThreshold;
};

type DryWeightChangeThreshold = {
  [WeightTagThresholdKey.GainP0]: number;
  [WeightTagThresholdKey.LossP1]: number;
};

type WeeklyWeightGainTags = {
  [WeightGainTagType.WeeklyWeightGainP0]: WeeklyWeightGainThreshold;
};

type RapidWeightGainTags = {
  [WeightGainTagType.RapidWeightGainP0]: RapidWeightGainThreshold;
};

type DryWeightTags = {
  [k in DryWeightTagType]: DryWeightChangeThreshold;
};

export type BloodPressureTags = {
  [k in BloodPressureTagType]:
    | BloodPressureTagThreshold
    | MainArterialPressureLowThreshold;
};

export type HeartRateTags = {
  [k in HeartRateTagType]: HeartRateTagThreshold;
};

export type BloodGlucoseTags = {
  [k in BloodGlucoseTagType]: BloodGlucoseTagThreshold;
};

type WeightGainTags = WeeklyWeightGainTags | RapidWeightGainTags;

export type WeightTags = WeightGainTags & DryWeightTags;

export type Tags =
  | HeartRateTags
  | BloodGlucoseTags
  | (BloodPressureTags & WeightTags);

export enum TagThresholdType {
  BloodPressure = 'blood_pressure',
  HeartRate = 'heart_rate',
  Weight = 'weight',
  BloodGlucose = 'blood_glucose',
}

enum BpThresholdSubType {
  Diastolic = 'diastolic',
  Systolic = 'systolic',
  MeanArterialPressure = 'mean_arterial_pressure',
  MonthlyPercentageOutOfRange = 'monthly_percentage_out_of_range',
  MonthlyAverage = 'monthly_average',
}
enum WeightThresholdSubType {
  DryWeight = 'dry_weight',
  WeeklyWeightGain = 'weekly_weight_gain',
  RapidWeightGain = 'rapid_weight_gain',
}

enum HeartRateThresholdSubType {
  Puls = 'pulse',
}

enum BloodGlucoseThresholdSubType {
  Reading = 'reading',
  MonthlyAverage = 'monthly_average',
  WeeklyPercentageOutOfRange = 'weekly_percentage_out_of_range',
}

type BloodPressureVitalThreshold = {
  [BpThresholdSubType.Diastolic]: BloodPressureTagThreshold;
  [BpThresholdSubType.MeanArterialPressure]: MainArterialPressureLowThreshold;
  [BpThresholdSubType.Systolic]: BloodPressureTagThreshold;
  [BpThresholdSubType.MonthlyPercentageOutOfRange]: BloodPressurePercentageOutOfRangeTagThreshold;
  [BpThresholdSubType.MonthlyAverage]: BloodPressureTagThreshold;
};

type HeartRateVitalThreshold = {
  [HeartRateThresholdSubType.Puls]: HeartRateTagThreshold;
};

type WeightVitalThreshold = {
  [WeightThresholdSubType.DryWeight]: DryWeightChangeThreshold;
  [WeightThresholdSubType.WeeklyWeightGain]: WeeklyWeightGainThreshold;
  [WeightThresholdSubType.RapidWeightGain]: RapidWeightGainThreshold;
};

type BloodGlucoseVitalThreshold = {
  [BloodGlucoseThresholdSubType.Reading]: BloodGlucoseTagThreshold;
  [BloodGlucoseThresholdSubType.MonthlyAverage]: BloodGlucoseMonthlyAverageTagThreshold;
  [BloodGlucoseThresholdSubType.WeeklyPercentageOutOfRange]: BloodGlucosePercentageOutOfRangeTagThreshold;
};

export type VitalThreshold =
  | BloodPressureVitalThreshold
  | HeartRateVitalThreshold
  | WeightVitalThreshold
  | BloodGlucoseVitalThreshold;

export type ThresholdSubTypeLike = {
  [k: string]: {
    [k: string]: number | RapidWeightGainSubThreshold | FullBpReading;
  };
};
