import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import { validators } from 'shared/common/Form/validations';

import type { TActionStep } from './types';

export type SetActionStepsFields = {
  actionSteps: TActionStep[];
};

const EMPTY_ACTION_STEPS: TActionStep[] = [
  { step: '', frequency: '', dependencies: '' },
];

export function getSetActionStepsFormConfig(
  intl: IntlShape,
  values?: SetActionStepsFields,
) {
  const { required, array } = validators(intl);

  return {
    fields: {
      actionSteps: {
        defaultValue: values?.actionSteps || EMPTY_ACTION_STEPS,
        validation: array({
          minLength: 1,
          maxLength: 3,
          ofType: yup.object({
            step: required(yup.string()),
            frequency: required(yup.string()),
            dependencies: yup.string(),
          }),
        }),
      },
    },
  };
}
