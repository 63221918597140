import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';

import type { Schema } from 'shared/common/@deprecated/SchemaDrivenForm/';

/**
 * Recursively merge all properties of two schemas, similar to _.merge except
 * arrays are concatenated
 * @param firstSchema
 * @param secondSchema
 * @returns
 */
export function mergeSchemas(firstSchema: Schema, secondSchema: Schema) {
  return mergeWith({}, firstSchema, secondSchema, (objValue, srcValue) => {
    if (isArray(objValue)) {
      return objValue.concat(srcValue);
    }

    // If customizer returns undefined, merging is handled by the method instead
    return undefined;
  });
}
