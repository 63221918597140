import type { VitalsEngagement } from 'shared/hooks/useVitalsEngagement';

import type { DeviceUsageGoalsFormFields } from '../../../CNWizardForm/sections/shared/LowDeviceUsage';
import { isHighDeviceUsage } from '../../../CNWizardForm/sections/shared/ProductSupport';
import type { ProductFeedbackFormFields } from '../../../CNWizardForm/sections/shared/ProductSupport/feedbackFormConfig';
import { MarkdownBuilder } from '../../engine/MarkdownBuilder';

type Params = {
  feedbackData: Maybe<ProductFeedbackFormFields>;
  deviceUsageData: Maybe<DeviceUsageGoalsFormFields>;
  vitalsEngagement: VitalsEngagement;
};

export function cnProductSupport({
  vitalsEngagement,
  feedbackData,
  deviceUsageData,
}: Params) {
  const md = new MarkdownBuilder();
  const isLowDeviceUsage = !isHighDeviceUsage(vitalsEngagement);

  md.h3('Product Support');
  md.field(
    'Patient device utilization',
    vitalsEngagement?.daysWithVitals !== undefined
      ? `${vitalsEngagement?.daysWithVitals} days with vitals in past 30 days`
      : null,
  );

  if (feedbackData?.productFeedback) {
    md.field('Product support feedback', feedbackData?.productFeedback);
  }

  if (isLowDeviceUsage) {
    if (deviceUsageData?.declinedToSetGoal) {
      md.p('Patient declined to set a goal for device location.');
    } else {
      if (deviceUsageData?.deviceLocation) {
        md.field('Device location goal', deviceUsageData?.deviceLocation);
      }
      const hasDailyVitalTimeGoal =
        deviceUsageData?.goalScheduleHour &&
        deviceUsageData?.goalSchedulePeriod;
      if (hasDailyVitalTimeGoal) {
        md.field(
          'Daily vital time goal',
          hasDailyVitalTimeGoal
            ? `${deviceUsageData?.goalScheduleHour} ${deviceUsageData?.goalSchedulePeriod}`
            : null,
        );
      }
    }
  }

  return md.toString();
}
