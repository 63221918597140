import { useIntl } from 'react-intl';

import { CareProviderRole } from 'shared/generated/grpcGateway/cadence/models/models.pb';
import { ProviderTitle } from 'shared/types/provider.types';

export function useProviderRoleI18nMapping() {
  const intl = useIntl();
  return {
    [ProviderTitle.Other]: intl.formatMessage({ defaultMessage: 'Other' }),
    [ProviderTitle.MD]: intl.formatMessage({
      defaultMessage: 'MD',
      description: 'Medical Doctor',
    }),
    [ProviderTitle.DO]: intl.formatMessage({
      defaultMessage: 'DO',
      description: 'Doctor of Osteopathy',
    }),
    [ProviderTitle.NP]: intl.formatMessage({
      defaultMessage: 'NP',
      description: 'Nurse Practitioner',
    }),
    [ProviderTitle.RN]: intl.formatMessage({
      defaultMessage: 'RN',
      description: 'Registered Nurse',
    }),
    [ProviderTitle.MA]: intl.formatMessage({
      defaultMessage: 'MA',
      description: 'Medical Assistant',
    }),
    [ProviderTitle.PA]: intl.formatMessage({
      defaultMessage: 'PA',
      description: 'Physician Assistant',
    }),
    [ProviderTitle.LPN]: intl.formatMessage({
      defaultMessage: 'LPN',
      description: 'Licensed Practical Nurse',
    }),
  };
}

export function useCareProviderRoleI18nMapping(excludeIndistinctRoles = false) {
  const intl = useIntl();
  return {
    [CareProviderRole.MD]: intl.formatMessage({
      defaultMessage: 'MD',
      description: 'Medical Doctor',
    }),
    [CareProviderRole.DO]: intl.formatMessage({
      defaultMessage: 'DO',
      description: 'Doctor of Osteopathy',
    }),
    [CareProviderRole.NP]: intl.formatMessage({
      defaultMessage: 'NP',
      description: 'Nurse Practitioner',
    }),
    [CareProviderRole.RN]: intl.formatMessage({
      defaultMessage: 'RN',
      description: 'Registered Nurse',
    }),
    [CareProviderRole.MA]: intl.formatMessage({
      defaultMessage: 'MA',
      description: 'Medical Assistant',
    }),
    [CareProviderRole.PA]: intl.formatMessage({
      defaultMessage: 'PA',
      description: 'Physician Assistant',
    }),
    [CareProviderRole.LPN]: intl.formatMessage({
      defaultMessage: 'LPN',
      description: 'Licensed Practical Nurse',
    }),
    ...(excludeIndistinctRoles
      ? {}
      : {
          [CareProviderRole.ROLE_UNSPECIFIED]: intl.formatMessage({
            defaultMessage: 'Unspecified',
          }),
          [CareProviderRole.ROLE_OTHER]: intl.formatMessage({
            defaultMessage: 'Other',
          }),
        }),
  };
}
