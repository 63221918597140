import type {
  DailyLivingFormFields,
  DietFormFields,
  ExerciseFormFields,
  TobaccoUseFormFields,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/generalAssessment/formConfig';

import { MarkdownBuilder } from '../../engine/MarkdownBuilder';
import { sectionHasData } from '../util';

type Params = {
  tobaccoData: Maybe<TobaccoUseFormFields>;
  dietData: Maybe<DietFormFields>;
  exerciseData: Maybe<ExerciseFormFields>;
  dailyLivingData: Maybe<DailyLivingFormFields>;
};

export function cnGeneralAssessment({
  tobaccoData,
  dietData,
  exerciseData,
  dailyLivingData,
}: Params) {
  const md = new MarkdownBuilder();

  if (
    [tobaccoData, dietData, exerciseData, dailyLivingData].some(sectionHasData)
  ) {
    md.h3('General Assessment');
  }

  /**
   * Social History
   */
  // Tobacco Use
  if (sectionHasData(tobaccoData)) {
    // HACK: Whenever we have two headers directly nested (Regardless of size or amount of newlines)
    // the EMR Generated PDF will squash them. Using bold text instead of h4 to get around this
    md.newline();
    md.concat('**Social History:**');
    md.newline();

    if (tobaccoData?.currentTobaccoUse === 'true') {
      md.fieldWithDescription('Tobacco use', tobaccoData?.currentTobaccoUse, {
        description: tobaccoData.currentTobaccoUseDescription,
        whenVal: 'true',
      });
    } else if (tobaccoData?.currentTobaccoUse === 'false') {
      if (tobaccoData?.pastTobaccoUse === 'true') {
        md.field('Tobacco use', `No, ${tobaccoData.pastTobaccoUseDescription}`);
      } else {
        md.field('Tobacco use', 'No current or past tobacco use');
      }
    }
  }

  /**
   * Diet
   */
  if (sectionHasData(dietData)) {
    md.h4('Diet:');

    if (dietData?.processedFoods) {
      md.field('Current diet', dietData?.currentDiet);
      md.field('Current processed food habits', dietData?.processedFoods);
    } else {
      md.field(
        'Current fast food/processed food habits',
        dietData?.currentDiet,
      );
    }

    md.fieldWithDescription(
      'Regular sodas, fruit juices, or sweet tea',
      dietData?.sugaryDrinks,
      { description: dietData?.sugaryDrinksDescription, whenVal: 'true' },
    );

    md.field('Daily water consumption', dietData?.waterPerDay);
    md.field('Add salt to meals regularly', dietData?.addSalt);
  }

  /**
   * Exercise
   */
  if (sectionHasData(exerciseData)) {
    md.h4('Exercise:');
    md.fieldWithDescription('Regular exercise', exerciseData?.currentExercise, {
      description: exerciseData?.currentExerciseDescription,
      whenVal: 'true',
    });
  }

  /**
   * Activities of Daily Living
   */
  if (sectionHasData(dailyLivingData)) {
    md.h4('Activities of Daily Living:');
    md.fieldWithDescription(
      'Requires any assistance with walking, eating, using the bathroom, or getting dressed',
      dailyLivingData?.requiresAssistance,
      {
        description: dailyLivingData?.requiresAssistanceDescription,
        whenVal: 'true',
      },
    );
  }

  return md.toString();
}
