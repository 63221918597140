import { useEffect, useRef } from 'react';

import { MESSAGES_TAB_CONTENT_CONTAINER_ID } from 'pages/patients/PatientProfile/PatientProfileContent/constants';

import { ConversationExchange } from './ConversationExchange';
import type { Conference, Recipient, SMSMessage } from './Messages';
import { messageHistoryComponent } from './Messages.css';

type Props = {
  conversation: (SMSMessage | Conference)[];
  recipient: Recipient;
};

export function ConversationHistory({ conversation, recipient }: Props) {
  const msgHistoryRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.getElementById(MESSAGES_TAB_CONTENT_CONTAINER_ID)?.scroll({
      behavior: 'smooth',
      top: document.getElementById(MESSAGES_TAB_CONTENT_CONTAINER_ID)
        ?.scrollHeight,
    });
  }, [conversation.length]);

  return (
    <div ref={msgHistoryRef} className={messageHistoryComponent}>
      {(conversation ?? []).map((exchange) => (
        <ConversationExchange
          key={
            'recordingSid' in exchange
              ? (exchange as Conference).uniqueId
              : (exchange as SMSMessage).sid
          }
          message={'recordingSid' in exchange ? null : (exchange as SMSMessage)}
          conference={
            'recordingSid' in exchange ? (exchange as Conference) : null
          }
          recipient={recipient}
        />
      ))}
    </div>
  );
}
