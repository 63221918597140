import type { ReactNode } from 'react';
import { IntlProvider as ReduxIntlProvider } from 'react-intl-redux';
import { Provider as ReduxStoreProvider } from 'react-redux';
import type { Store } from 'redux';
import { PersistGate as ReduxPersistGate } from 'redux-persist/integration/react';

import {
  AdapterDateFns,
  LocalizationProvider,
  StyledEngineProvider,
} from 'deprecated/mui';
import { QueryClientProvider } from 'reactQuery';
import { Toast } from 'shared/tempo/molecule/Toast';
import { persistor, store } from 'store';

type Props = {
  children: ReactNode;
  reduxStore?: Maybe<Store>;
};

export function Providers({ children, reduxStore }: Props) {
  return (
    // TODO: Deprecate and remove redux providers + replace ReduxIntlProvider
    // with standard intl provider
    <ReduxStoreProvider store={reduxStore || store}>
      <ReduxPersistGate loading={null} persistor={persistor}>
        <ReduxIntlProvider locale="en">
          <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <QueryClientProvider>
                <Toast.Provider>{children}</Toast.Provider>
              </QueryClientProvider>
            </LocalizationProvider>
          </StyledEngineProvider>
        </ReduxIntlProvider>
      </ReduxPersistGate>
    </ReduxStoreProvider>
  );
}
