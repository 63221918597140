import { type ReactNode, useRef } from 'react';
import { Overlay, useOverlay, useOverlayTrigger } from 'react-aria';
import type { OverlayTriggerState } from 'react-stately';

import { MODAL_OPEN_ATTR } from 'shared/common/Modal';
import { useEscHotkey } from 'shared/hooks';

import { container, modal } from './SidebarTray.css';

type RenderFn = (props: { state: OverlayTriggerState }) => ReactNode;

export type Props = {
  state: OverlayTriggerState;
  children: ReactNode | RenderFn;
  position?: 'left' | 'right';
};

export function TrayOverlay({ children, state, position = 'left' }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const { overlayProps } = useOverlayTrigger({ type: 'dialog' }, state);

  const { overlayProps: modalProps } = useOverlay(
    {
      isDismissable: true,
      isOpen: state.isOpen,
      onClose: state.close,
      shouldCloseOnInteractOutside() {
        // Don't allow close on click outside if any modals are open
        return !document.querySelector(`[${MODAL_OPEN_ATTR}="true"]`);
      },
      // built-in keyboard dismissal is a little finicky so we'll do it
      // as its own keybind instead.
      isKeyboardDismissDisabled: true,
    },
    ref,
  );

  useEscHotkey(
    () => {
      state.close();
      return true;
    },
    [],
    { enableOnFormTags: false, enableOnContentEditable: false },
  );

  return (
    <Overlay>
      <div {...modalProps} ref={ref} className={modal[position]}>
        <div {...overlayProps} className={container}>
          {typeof children === 'function' ? children({ state }) : children}
        </div>
      </div>
    </Overlay>
  );
}
