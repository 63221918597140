import cx from 'classnames';
import type { ReactElement, ReactNode } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import { removePadding } from 'shared/jsStyle/utils.css';
import { joinPaths } from 'shared/utils/route-helpers';

import { Tab, TabContext, TabList, TabPanel } from '../Tab';
import { tabButton, tabPanel } from './RoutableTabs.css';

type RoutableTab = {
  link: string;
  label: ReactNode;
  disabled?: boolean;
  exact?: boolean;
  hidden?: boolean;
  panel: ReactElement;
  className?: string;
};

type Props = {
  tabs: RoutableTab[];
  defaultLink: string;
  tabVariant?: 'fullWidth' | 'scrollable' | 'standard';
  tabListClassname?: string;
};

export function RoutableTabs({
  tabs,
  defaultLink,
  tabVariant = 'fullWidth',
  tabListClassname,
}: Props) {
  const history = useHistory();
  const { path: matchedPath, url: matchedUrl } = useRouteMatch();

  const location = useLocation();
  const currentTabValue =
    tabs
      .map((tab) => tab.link)
      .find((link) => location.pathname.includes(link)) || defaultLink;
  const visibleTabs = tabs.filter((tab) => !tab.hidden);
  const { search } = useLocation();

  return (
    <TabContext value={currentTabValue}>
      <TabList
        variant={tabVariant}
        textColor="primary"
        indicatorColor="primary"
        className={tabListClassname}
      >
        {visibleTabs.map((tab, idx) => (
          <Tab
            onClick={() => {
              const newLocation = `/${joinPaths(matchedUrl, tab.link)}`;
              if (history.location.pathname !== newLocation) {
                history.replace(newLocation);
              }
            }}
            className={cx(tabButton, tab.className)}
            tabIndex={idx}
            disabled={tab.disabled}
            label={tab.label}
            value={tab.link}
            key={`tab-${tab.link}`}
          />
        ))}
      </TabList>
      <Switch>
        {visibleTabs.map(
          (tab) =>
            !tab.disabled && (
              <Route
                key={`route-${tab.link}`}
                path={`/${joinPaths(matchedPath, tab.link)}`}
                exact={tab.exact}
                render={() => (
                  <TabPanel
                    className={cx([removePadding, tabPanel])}
                    value={tab.link}
                  >
                    {tab.panel}
                  </TabPanel>
                )}
              />
            ),
        )}
        <Redirect
          from="*"
          to={{ pathname: `/${joinPaths(matchedUrl, defaultLink)}`, search }}
        />
      </Switch>
    </TabContext>
  );
}
