import CloseIcon from 'shared/assets/svgs/close.svg?react';
import { IconButton } from 'shared/tempo/atom/IconButton';

import { modalHeader, modalTitle } from './SidebarTray.css';

type Props = {
  onClose: () => void;
  children: React.ReactElement;
};

export function Header({ children, onClose }: Props) {
  return (
    <div className={modalHeader}>
      <span className={modalTitle}>{children}</span>
      <IconButton size="small" variant="tertiary" onPress={onClose}>
        <CloseIcon width="16px" height="16px" />
      </IconButton>
    </div>
  );
}
