import { logger } from 'logger';
import type { Schema } from 'shared/common/@deprecated/SchemaDrivenForm/';

import { mapSchema } from './mapSchema';
import { mergeSchemas } from './mergeSchemas';

/**
 * Replaces the "$ref"s of the schema with it's corresponding subschemas
 * defined in the "definitions" object
 *
 * Note: This does not yet support nested definitions e.g. $refs like
 * #/definitions/ClassName/SubClassName
 *
 * @param schema - Original schema
 * @returns new schema
 */
export function inlineDefinitions(schema: Schema) {
  if (!schema.definitions) {
    return schema;
  }
  const { definitions, ...newSchema } = mapSchema(
    schema,
    getInlineDefinitionsMapperFn(schema.definitions),
  );
  return newSchema;
}

function getInlineDefinitionsMapperFn(definitions: { [name: string]: Schema }) {
  return function inlineDefinitionsMapperFn(schema: Schema) {
    if (!schema.$ref) {
      return schema;
    }

    const definitionKey = schema.$ref?.split('/')[2];
    if (!definitionKey) {
      logger.error(
        `Definition key ${definitionKey} not in the expected format`,
      );
      return schema;
    }

    const definition = definitions[definitionKey];
    if (!definition) {
      logger.error(`Could not find definition under key ${definitionKey}`);
      return schema;
    }

    const { $ref, ...schemaWithoutRef } = schema;

    return mergeSchemas(schemaWithoutRef, definition);
  };
}
