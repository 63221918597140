import cx from 'classnames';
import { useIntl } from 'react-intl';

import { BaseField } from 'shared/common/Form/fields/BaseField';
import type { RpmCondition } from 'shared/types/clinicalprofile.types';

import { PatientGoal } from '../PatientGoal';
import { ModuleAccordion } from './ModuleAccordion';
import type { EducationModuleSection } from './types';
import { UnderstandDiseaseConditions } from './types';

type Props = {
  name: string;
  className?: string;
  reviewedModules: EducationModuleSection[];
  previouslyReviewedModules?: EducationModuleSection[];
  patientGoal?: PatientGoal;
  conditions: RpmCondition[];
};

export function ReviewedModules({
  name,
  className,
  reviewedModules,
  previouslyReviewedModules = [],
  patientGoal,
  conditions,
}: Props) {
  const intl = useIntl();

  return (
    <BaseField
      size={12}
      label=""
      name={name}
      className={cx(className)}
      aria-label={intl.formatMessage({ defaultMessage: 'Reviewed Modules' })}
    >
      {({ controller: { field } }) => (
        <>
          <ModuleAccordion
            header={intl.formatMessage({
              defaultMessage: 'Become more physically active',
            })}
            reviewedModules={reviewedModules}
            disabledModules={previouslyReviewedModules}
            onReviewedChange={field.onChange}
            module={PatientGoal.BecomePhysicallyActive}
            patientGoal={patientGoal}
          />
          <ModuleAccordion
            header={intl.formatMessage({
              defaultMessage: 'Take my medications more consistently',
            })}
            reviewedModules={reviewedModules}
            disabledModules={previouslyReviewedModules}
            onReviewedChange={field.onChange}
            module={PatientGoal.TakeMedicationsConsistently}
            patientGoal={patientGoal}
          />
          <ModuleAccordion
            header={intl.formatMessage({
              defaultMessage: 'Lower my weight',
            })}
            reviewedModules={reviewedModules}
            disabledModules={previouslyReviewedModules}
            onReviewedChange={field.onChange}
            module={PatientGoal.LowerMyWeight}
            patientGoal={patientGoal}
          />
          <ModuleAccordion
            header={intl.formatMessage({
              defaultMessage: 'Improve my food choices',
            })}
            reviewedModules={reviewedModules}
            disabledModules={previouslyReviewedModules}
            onReviewedChange={field.onChange}
            module={PatientGoal.ImproveMyFoodChoices}
            patientGoal={patientGoal}
          />
          <ModuleAccordion
            header={intl.formatMessage({
              defaultMessage: 'Reduce my stress',
            })}
            reviewedModules={reviewedModules}
            disabledModules={previouslyReviewedModules}
            onReviewedChange={field.onChange}
            module={PatientGoal.ReduceMyStress}
            patientGoal={patientGoal}
          />
          <ModuleAccordion
            conditions={conditions}
            header={intl.formatMessage({
              defaultMessage: 'Better Understand My Disease - Diabetes',
            })}
            reviewedModules={reviewedModules}
            disabledModules={previouslyReviewedModules}
            onReviewedChange={field.onChange}
            patientGoal={patientGoal}
            module={UnderstandDiseaseConditions.T2d}
          />
          <ModuleAccordion
            conditions={conditions}
            header={intl.formatMessage({
              defaultMessage: 'Better Understand My Disease - Hypertension',
            })}
            reviewedModules={reviewedModules}
            disabledModules={previouslyReviewedModules}
            patientGoal={patientGoal}
            onReviewedChange={field.onChange}
            module={UnderstandDiseaseConditions.Htn}
          />
          <ModuleAccordion
            conditions={conditions}
            header={intl.formatMessage({
              defaultMessage: 'Better Understand My Disease - CHF',
            })}
            reviewedModules={reviewedModules}
            disabledModules={previouslyReviewedModules}
            patientGoal={patientGoal}
            onReviewedChange={field.onChange}
            module={UnderstandDiseaseConditions.Chf}
          />
          <ModuleAccordion
            header={intl.formatMessage({
              defaultMessage: 'Get better sleep',
            })}
            reviewedModules={reviewedModules}
            disabledModules={previouslyReviewedModules}
            patientGoal={patientGoal}
            onReviewedChange={field.onChange}
            module={PatientGoal.GetBetterSleep}
          />
          <ModuleAccordion
            header={intl.formatMessage({
              defaultMessage: 'Quit smoking',
            })}
            reviewedModules={reviewedModules}
            disabledModules={previouslyReviewedModules}
            patientGoal={patientGoal}
            onReviewedChange={field.onChange}
            module={PatientGoal.QuitSmoking}
          />
        </>
      )}
    </BaseField>
  );
}
