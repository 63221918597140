import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { bodyDefault, headingSmall } from 'shared/jsStyle/typography.css';
import type { RouteParam } from 'shared/types/route.types';

import type { NoteFormValues } from '../NoteEditor/noteFormState';
import { NoteBodyPreview } from './NoteBodyPreview';
import { NoteLabelsPreview } from './NoteLabelsPreview';
import { infoText, previewSection } from './NotePreview.css';
import { useNoteBody } from './getVisitLayoutNoteBody';

type NotePreviewProps = {
  noteFormValues: NoteFormValues;
  className?: string;
};

export const NotePreview = ({
  className,
  noteFormValues,
}: NotePreviewProps) => {
  const { patientId }: RouteParam = useParams();
  const getNoteBody = useNoteBody(patientId);
  const noteBody = getNoteBody(noteFormValues.encounterModuleInstances);

  return (
    <div className={className}>
      <h2 className={headingSmall}>
        <FormattedMessage defaultMessage="Smart note preview" />
      </h2>
      <p className={cx(bodyDefault, infoText)}>
        <FormattedMessage defaultMessage="In this area you can see how the note will look like after publishing it. These inputs are not editable, all the changes you make on the left side of the screen will be reflected here." />
      </p>

      <div className={cx(bodyDefault, previewSection)}>
        {noteFormValues.title || (
          <FormattedMessage defaultMessage="Preview title" />
        )}
      </div>

      <div className={cx(bodyDefault, previewSection)}>
        <NoteBodyPreview
          bodyHtml={noteFormValues.bodyHtml}
          rtfBody={noteFormValues.body}
          body={noteBody}
          encounterModuleInstances={noteFormValues.encounterModuleInstances}
        />
      </div>

      <div className={cx(bodyDefault, previewSection)}>
        <NoteLabelsPreview labelIds={noteFormValues.labels} />
      </div>
    </div>
  );
};
