import { upsertItem } from 'lang.utils';
import { CareModelVersion } from 'shared/types/featureFlags.types';

import type {
  EncounterModuleInstance,
  EncounterTypeInputs,
} from '../Notes.types';
import { EncounterModuleId as ModuleId } from '../Notes.types';
import {
  InitialEncounterTypeInstance,
  getEncounterTypeInstance,
  isClinicalNavigatorEncounterType,
  isClinicianVisit,
  isPreV3ClinicalNavigatorEncounterType,
} from './encounterTypeUtils';

export function adjustEncounterTypeInstance(
  encounterModuleInstances: EncounterModuleInstance[],
  careModelVersion: CareModelVersion,
) {
  let newEncounterTypeInstance =
    getEncounterTypeInstance(encounterModuleInstances) ||
    InitialEncounterTypeInstance;

  const { inputs } = newEncounterTypeInstance;

  newEncounterTypeInstance = {
    ...newEncounterTypeInstance,
    inputs: {
      ...inputs,
      ...getAdjustedReportingFields(inputs, careModelVersion),
      // Initialize visit_layout field
      // (Resetting the visit_layout field upon changing of the feature flag is handled separately in getInstancesMigratedToVisitLayout, so that we know the original value before migrating)
      visit_layout: inputs.visit_layout ?? true,
    },
  };

  // Ensure Encounter Type instance is present for all notes
  return upsertItem(
    encounterModuleInstances,
    newEncounterTypeInstance,
    (instance) => instance.encounter_module_id === ModuleId.EncounterType,
  );
}

function getAdjustedReportingFields(
  inputs: EncounterTypeInputs,
  careModelVersion: CareModelVersion,
) {
  const { type_of_encounter: encounterType } = inputs;
  const isCareModelV3 = careModelVersion === CareModelVersion.V3;
  const isCnVisit = isClinicalNavigatorEncounterType(encounterType);

  // In the new meds module, we are using the has_meds_to_report
  // field to persist whether med actions need to be taken in this
  // encounter (see showRequiredActionsInNote). The default is
  // true so that "Patient declined to answer" toggle must be explicitly
  // turned on to not show the required actions
  const hasMedsToReport = inputs.has_meds_to_report ?? true;

  if (isCareModelV3 && isCnVisit) {
    return {
      has_meds_to_report: hasMedsToReport,
      has_emergency_visits_to_report: false,
    };
  }

  // Set clinician encounter module-related reporting fields to undefined if those modules are being removed in removeClinicianEncounterModules()
  if (
    !isClinicianVisit(encounterType) &&
    !isPreV3ClinicalNavigatorEncounterType(careModelVersion, encounterType)
  ) {
    return {
      // Note: We cannot omit these fields completely because rjsf
      // will reset them to undefined since they are in the schema,
      // causing the !isEqual check in useAdjustEncounterModuleInstances()
      // to always return true
      has_meds_to_report: hasMedsToReport,
      has_symptoms_to_report: undefined,
      has_emergency_visits_to_report: undefined,
    };
  }

  // Default reporting fields to false for patient no-shows
  if (inputs.patient_no_show) {
    return {
      has_meds_to_report: inputs.has_meds_to_report ?? false,
      has_symptoms_to_report: inputs.has_symptoms_to_report ?? false,
      has_emergency_visits_to_report:
        inputs.has_emergency_visits_to_report ?? false,
    };
  }

  return { has_meds_to_report: hasMedsToReport };
}
