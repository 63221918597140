import { CareModelVersion } from 'shared/types/featureFlags.types';

import { initialRnVisit as twoDotOhInitialRnVisit } from '../2.0/initialRnVisit';
import { regularRnVisit as twoDotOhRegularRnVisit } from '../2.0/regularRnVisit';
import { disenrollment } from '../__shared__/disenrollment';
import { initialCnVisit } from '../__shared__/initialCnVisit';
import { patientEnrollment } from '../__shared__/patientEnrollment';
import { regularCnVisit } from '../__shared__/regularCnVisit';
import { virtualEnrollment } from '../__shared__/virtualEnrollment';
import { welcomeCall } from '../__shared__/welcomeCall';
import { initialNpVisit } from './initialNpVisit';
import { regularNpVisit } from './regularNpVisit';

export const oneDotOhTemplates = {
  [CareModelVersion.V1]: {
    ...initialNpVisit,
    ...regularNpVisit,
    ...initialCnVisit,
    ...regularCnVisit,
    ...patientEnrollment,
    ...virtualEnrollment,
    ...welcomeCall,
    ...disenrollment,

    // Some 1.0 NPs are temporarily filling in as 2.0 RNs and need access to
    // RN 2.0 templates. See: https://cadencerpm.atlassian.net/browse/PLAT-4781
    // TODO: Remove these from 1.0 templates when no longer needed
    ...twoDotOhInitialRnVisit,
    ...twoDotOhRegularRnVisit,
  },
};
