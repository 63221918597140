import { useRef } from 'react';
import type { AriaTextFieldOptions } from 'react-aria';
import { useTextField } from 'react-aria';

import LockIcon from 'shared/assets/icons/LockIcon';
import type { InputProps } from 'shared/tempo/atom/Input';
import { Input } from 'shared/tempo/atom/Input';
import { Label } from 'shared/tempo/atom/Label';
import { ValidationError } from 'shared/tempo/atom/ValidationError';
import type { EnforcedA11yLabel } from 'shared/tempo/shared/types';
import { InputAdorner } from 'shared/tempo/util/InputAdorner/InputAdorner';

import { label as labelCss, validationError } from './TextField.css';

type Props = {
  className?: string;
  leftAdornment?: React.ReactNode;
  rightAdornment?: React.ReactNode;
} & AriaTextFieldOptions<'input'> &
  EnforcedA11yLabel &
  Pick<InputProps, 'mask'>;

export function TextField({
  leftAdornment,
  rightAdornment,
  className,
  mask,
  ...props
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useTextField(props, inputRef);
  const { description, errorMessage, isReadOnly, isRequired, label } = props;
  const hasError = !!errorMessage;

  return (
    <div className={className}>
      <Label
        className={labelCss}
        description={description}
        descriptionProps={descriptionProps}
        hasError={hasError}
        isRequired={isRequired}
        label={label}
        labelProps={labelProps}
      />
      <InputAdorner
        leftAdornment={leftAdornment}
        rightAdornment={isReadOnly ? <LockIcon /> : rightAdornment}
      >
        <Input {...inputProps} ref={inputRef} hasError={hasError} mask={mask} />
      </InputAdorner>
      {errorMessage && (
        <ValidationError {...errorMessageProps} className={validationError}>
          {errorMessage}
        </ValidationError>
      )}
    </div>
  );
}
