import { ConditionProgram } from 'shared/types/condition.types';

import { generalAssessmentAndPlanModule } from './assessmentAndPlanModule';
import { patientNotesModule } from './patientNotesModule';

export const htn = {
  [ConditionProgram.Hypertension]: {
    ...patientNotesModule,
    ...generalAssessmentAndPlanModule,
  },
};
