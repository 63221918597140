import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Modal } from 'shared/common/Modal';
import { useDeleteMonitoringSession } from 'shared/hooks/queries';
import { Button } from 'shared/tempo/atom/Button';
import { useToaster } from 'shared/tempo/molecule/Toast';
import type { RouteParam } from 'shared/types/route.types';

export type Props = {
  entryId: string;
  onClose: () => void;
};

export function DeleteConfirmationModal({ onClose, entryId }: Props) {
  const intl = useIntl();
  const { patientId }: RouteParam = useParams();
  const { toaster } = useToaster();
  const { mutate: deleteEntry, isLoading: isDeleting } =
    useDeleteMonitoringSession(patientId);

  return (
    <Modal open onClose={onClose}>
      <Modal.Header
        title={intl.formatMessage({
          defaultMessage: 'Are you sure you want to delete',
        })}
      />
      <Modal.Body>
        <FormattedMessage defaultMessage="Are you sure you want to delete this time tracking record?" />
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="close"
          variant="secondary"
          isDisabled={isDeleting}
          onPress={onClose}
        >
          <FormattedMessage defaultMessage="Close" />
        </Button>
        <Button
          id="delete-time-record"
          type="submit"
          variant="primary"
          isDisabled={isDeleting}
          onPress={() =>
            deleteEntry(entryId, {
              onSuccess: () => {
                toaster.success(
                  intl.formatMessage({
                    defaultMessage: 'Time record successfully deleted',
                  }),
                );
                onClose();
              },
              onError: () => {
                toaster.error(
                  intl.formatMessage({
                    defaultMessage: 'Failed to delete time record',
                  }),
                );
              },
            })
          }
        >
          <FormattedMessage defaultMessage="Delete" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
