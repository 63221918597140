import type { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import type { SectionMeta } from 'shared/common/Wizard/path.utils';
import { useCaregivers, usePatientDetails } from 'shared/hooks/queries';

import { usePatientProgram } from '../shared/usePatientProgram';
import type { CNFormContext } from './CNFormContext';
import { CNFormContextProvider } from './CNFormContext';
import type { CNVisitType } from './types';

type Props = {
  noteId: Maybe<number>;
  cnVisitType: CNVisitType;
  zendeskTicketId: Nullable<number>;
  clearEditor: () => void;
  children: ReactNode | ((props: CNFormContext) => ReactNode);
  sections: readonly SectionMeta[];
  appointmentId: Nullable<string>;
  noShowAppointmentId: Nullable<string>;
};

export function ConfiguredCNFormContextProvider({
  children,
  ...values
}: Props) {
  const { patientId } = useParams<{ patientId: string }>();
  const { program } = usePatientProgram(patientId);
  const { data: patientDetails } = usePatientDetails(patientId, false, true);
  const { data: caregivers } = useCaregivers(patientId);
  const value = {
    ...values,
    patientId,
    program,
    contacts: patientDetails?.contacts ?? [],
    caregivers: caregivers ?? [],
  };

  return (
    <CNFormContextProvider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </CNFormContextProvider>
  );
}
