import type { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

import { RadioGroup as SharedRadioGroup } from 'shared/tempo/molecule/RadioGroup';

import type { BaseFieldProps } from '../BaseField';
import { BaseField } from '../BaseField';

type Props = {
  children: ReactNode;
  orientation?: 'horizontal' | 'vertical';
  parseValue?: (val: string) => unknown;
  serializeValue?: (val: unknown) => string;
  description?: ReactNode;
  classes?: {
    label?: string;
  };
} & BaseFieldProps;

export function RadioGroup({
  children,
  label,
  parseValue,
  serializeValue,
  orientation,
  description,
  classes,
  ...baseProps
}: Props) {
  const form = useFormContext();
  const errorMessage = form.formState.errors[baseProps.name]?.message;
  return (
    <BaseField {...baseProps} label="">
      {({ controller: { field }, isRequired, renderError }) => {
        renderError(); // Removing validation error this way from BaseField to use from SharedRadioGroup instead
        return (
          <SharedRadioGroup
            label={label}
            orientation={orientation}
            value={serializeValue ? serializeValue(field.value) : field.value}
            onChange={(value) => {
              field.onChange(parseValue ? parseValue(value) : value);
            }}
            isRequired={isRequired}
            description={description}
            classes={classes}
            errorMessage={errorMessage}
          >
            {children}
          </SharedRadioGroup>
        );
      }}
    </BaseField>
  );
}
