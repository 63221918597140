import truncate from 'lodash/truncate';

import { Box, Chip } from 'deprecated/mui';
import DeleteIcon from 'shared/assets/svgs/deleteIcon.svg?react';

import { chip, chipLabel, filledChip } from './MultiselectDropdown.css';

export const SelectedItems = ({
  items,
  selected,
  onDelete,
  maxLabelLength = 28,
  isDisabled = false,
}: {
  items: Array<{ value: string; label: string }>;
  selected: string[];
  onDelete: (value: string) => void;
  maxLabelLength?: number;
  isDisabled?: boolean;
}) => {
  const selectedLabels = selected
    .map((value) => items.find((item) => item.value === value)?.label || '')
    .map((label) => truncate(label, { length: maxLabelLength }));

  let currentLength = 0;
  let truncatedChipCount = 0;

  return (
    <Box>
      {selected.map((item, index) => {
        currentLength += selectedLabels[index].length;
        if (currentLength <= getMaxLengthOfChip(index + 1, maxLabelLength)) {
          return (
            <Chip
              disabled={isDisabled}
              key={item}
              label={selectedLabels[index]}
              classes={{
                root: chip,
                filled: filledChip,
                label: chipLabel,
              }}
              variant="filled"
              deleteIcon={<DeleteIcon />}
              onDelete={() => onDelete(item)}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
            />
          );
        }
        truncatedChipCount += 1;
        return null;
      })}
      {truncatedChipCount > 0 ? `+ ${truncatedChipCount}` : null}
    </Box>
  );
};

// Width a chip adds in addition to its text, converted approximately to number
// of characters
const CHIP_EXTRA_BOX_LENGTH = 10;

function getMaxLengthOfChip(position: number, maxLabelLength: number) {
  return (
    (maxLabelLength + CHIP_EXTRA_BOX_LENGTH) * 2 -
    position * CHIP_EXTRA_BOX_LENGTH
  );
}
