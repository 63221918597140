import { FormattedMessage, FormattedNumber } from 'react-intl';

import type { PatientClinicalProfile } from 'shared/generated/grpcGateway/pms.pb';
import {
  usePatientClinicalProfile,
  usePatientDetails,
} from 'shared/hooks/queries';
import { getRpmConditionsFromProgramAndStatus } from 'shared/patient/conditions.utils';
import type { RpmCondition } from 'shared/types/clinicalprofile.types';
import { Condition } from 'shared/types/clinicalprofile.types';
import { displayA1c, displayEjectionFraction } from 'shared/utils/helpers';
import { convertToLbs } from 'shared/utils/unit-helpers';

import {
  CommonInfoField,
  PLACEHOLDER,
} from '../../../shared/common/CommonInfoField/CommonInfoField';

type ClinicalPatientInfoFieldsProps = {
  clinicalProfile: PatientClinicalProfile;
  conditions: RpmCondition[];
};

export const ClinicalPatientInfoFieldsWrapper = ({
  patientId,
}: {
  patientId: string;
}) => {
  // TODO: replace with GRPC version of usePatientDetails EMR-1444
  const { data: patient } = usePatientDetails(patientId, false, !!patientId);
  const { data: clinicalProfile } = usePatientClinicalProfile(patientId);

  return (
    <>
      {clinicalProfile && patient && (
        <ClinicalPatientInfoFields
          useSubtleLabelStyle
          clinicalProfile={clinicalProfile}
          conditions={getRpmConditionsFromProgramAndStatus(
            patient?.programs,
            patient?.status,
          )}
        />
      )}{' '}
    </>
  );
};

export const ClinicalPatientInfoFields = ({
  clinicalProfile,
  conditions,
  useSubtleLabelStyle,
}: ClinicalPatientInfoFieldsProps & { useSubtleLabelStyle?: boolean }) => (
  <>
    {conditions.includes(Condition.CHF) && (
      <ChfInfo
        useSubtleLabelStyle={useSubtleLabelStyle}
        chfProfile={clinicalProfile}
      />
    )}
    {conditions.includes(Condition.TypeTwoDiabetes) && (
      <T2dInfo
        useSubtleLabelStyle={useSubtleLabelStyle}
        t2dProfile={clinicalProfile}
      />
    )}
    {conditions.includes(Condition.Hypertension) && (
      <HtnInfo
        useSubtleLabelStyle={useSubtleLabelStyle}
        htnProfile={clinicalProfile}
      />
    )}
  </>
);

const ChfInfo = ({
  chfProfile,
  useSubtleLabelStyle,
}: {
  chfProfile: PatientClinicalProfile;
  useSubtleLabelStyle?: boolean;
}) => (
  <>
    <CommonInfoField
      useSubtleLabelStyle={useSubtleLabelStyle}
      label={<FormattedMessage defaultMessage="Dry weight" />}
      text={
        chfProfile.dryWeight ? (
          <FormattedNumber
            value={convertToLbs(chfProfile.dryWeight)}
            /* the style prop for this component isn't CSS like this rule assumes */
            /* eslint-disable-next-line react/style-prop-object */
            style="unit"
            unit="pound"
          />
        ) : (
          PLACEHOLDER
        )
      }
    />
    <CommonInfoField
      useSubtleLabelStyle={useSubtleLabelStyle}
      label={<FormattedMessage defaultMessage="ACC/AHA stage" />}
      text={chfProfile?.chfStage || PLACEHOLDER}
    />
    <CommonInfoField
      useSubtleLabelStyle={useSubtleLabelStyle}
      label={<FormattedMessage defaultMessage="NYHA Class" />}
      text={chfProfile?.chfClass || PLACEHOLDER}
    />
    <CommonInfoField
      useSubtleLabelStyle={useSubtleLabelStyle}
      label={<FormattedMessage defaultMessage="EJ Fract" />}
      text={displayEjectionFraction({
        lower: chfProfile?.efLower,
        upper: chfProfile?.efUpper,
      })}
    />
  </>
);

const T2dInfo = ({
  t2dProfile,
  useSubtleLabelStyle,
}: {
  t2dProfile: PatientClinicalProfile;
  useSubtleLabelStyle?: boolean;
}) => (
  <CommonInfoField
    useSubtleLabelStyle={useSubtleLabelStyle}
    label={<FormattedMessage defaultMessage="Starting A1C" />}
    text={displayA1c(t2dProfile.a1c)}
  />
);

const HtnInfo = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  htnProfile,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useSubtleLabelStyle,
}: {
  htnProfile?: PatientClinicalProfile;
  useSubtleLabelStyle?: boolean;
}) => <>{/* No HTN-Specific Fields */}</>;
