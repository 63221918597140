import type { IntlShape } from 'react-intl';

import { logger } from 'logger';
import type { RxNorm } from 'shared/generated/grpcGateway/medication.pb';
import {
  MedicationChangeFrequency,
  RxNormDeliveryMechanism,
  RxNormMedicationUnit,
  RxNormReleaseType,
} from 'shared/generated/grpcGateway/medication.pb';

import {
  frequencyLabels,
  rxNormDeliveryMechanismLabels,
  rxNormMedicationUnitLabels,
  rxNormReleaseTypeLabels,
} from '../forms/formFieldLabels';
import { DoseType, getDoseType } from './medChangeUtils';

export function getRxNormStr(rxnorm: RxNorm, intl: IntlShape) {
  const doseType = getDoseType(rxnorm);

  const brand = rxnorm?.brand ? `[${rxnorm?.brand}] `.toUpperCase() : '';
  let rxNormComponents =
    rxnorm?.components
      ?.map((component) => {
        const ingredient = component.ingredient?.toUpperCase();
        const { strength } = component;
        const unit = unitLabel(intl, rxnorm?.unit);
        return `${ingredient} ${strength} ${unit}`;
      })
      .join(' - ') || '';

  if (
    doseType === DoseType.PILL &&
    rxnorm?.releaseType &&
    ![
      RxNormReleaseType.RELEASE_TYPE_UNSPECIFIED,
      RxNormReleaseType.IMMEDIATE,
    ].includes(rxnorm?.releaseType)
  ) {
    rxNormComponents = [
      rxNormComponents,
      ` ${releaseTypeLabel(intl, rxnorm?.releaseType) || ''}`,
    ].join('');
  }
  return `${brand}${rxNormComponents}`;
}

function unitLabel(intl: IntlShape, unit?: RxNormMedicationUnit) {
  if (!unit || unit === RxNormMedicationUnit.MEDICATION_UNIT_UNSPECIFIED) {
    logger.error('Unit not found');
    return null;
  }
  const labels = rxNormMedicationUnitLabels(intl);
  return labels[unit];
}

function releaseTypeLabel(intl: IntlShape, releaseType?: RxNormReleaseType) {
  if (
    !releaseType ||
    releaseType === RxNormReleaseType.RELEASE_TYPE_UNSPECIFIED
  ) {
    return null;
  }
  const labels = rxNormReleaseTypeLabels(intl);
  return labels[releaseType];
}

export function getDoseStr(
  intl: IntlShape,
  doseQuantities: Maybe<number[]>,
  frequencies: Maybe<MedicationChangeFrequency[]>,
  rxnorm: Maybe<RxNorm>,
) {
  const doseType = getDoseType(rxnorm);

  const doseComponents = doseQuantities?.map((quantity, i) => {
    const frequency = frequencyLabel(intl, frequencies?.[i]);
    const deliveryType = deliveryMechanismLabel(
      intl,
      rxnorm?.deliveryMechanism,
    );
    if (doseType === DoseType.PILL) {
      return `take ${quantity} ${deliveryType || ''} ${frequency}`;
    }
    return `${quantity} unit(s) ${deliveryType || ''} ${frequency}`;
  });
  return doseComponents?.join('; ');
}

export function frequencyLabel(
  intl: IntlShape,
  frequency?: MedicationChangeFrequency,
) {
  if (
    !frequency ||
    frequency === MedicationChangeFrequency.FREQUENCY_UNSPECIFIED
  ) {
    logger.error(
      'Frequency corresponding with dose quantity expected but not found',
    );
    return null;
  }
  const labels = frequencyLabels(intl);
  return labels[frequency];
}

export function deliveryMechanismLabel(
  intl: IntlShape,
  mechanism?: RxNormDeliveryMechanism,
) {
  if (
    !mechanism ||
    mechanism === RxNormDeliveryMechanism.DELIVERY_MECHANISM_UNSPECIFIED
  ) {
    return null;
  }
  const labels = rxNormDeliveryMechanismLabels(intl);
  return labels[mechanism];
}
