import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { logger } from 'logger';
import type {
  ReadingId,
  RelationTagsUnsuppressed,
} from 'shared/types/alert.types';
import type { ReadingDataType } from 'shared/types/patientSummary.types';
import type {
  AllTagType,
  TagType,
  ThresholdSubTypeLike,
} from 'shared/types/tagsAndThreshold.types';
import { GenericTagThresholdKey } from 'shared/types/tagsAndThreshold.types';
import type {
  PatientVitals,
  Vital,
  VitalType,
} from 'shared/types/vitals.types';

import type {
  AlertLoggerInfo,
  AlertMessageFormatterProps,
} from '../AlertDescription.types';
import type { AlertDescriptionMeta } from '../hooks';
import { MissingTagThresholdError } from './alert.errors';
import type { AlertFormatter } from './alertDescriptionFormatters';

export interface AlertDescriptionStrategy {
  getAlertDescription(
    readingIds: string[],
    vitals: PatientVitals,
    alertTags: AllTagType[],
    alertLoggerInfo: AlertLoggerInfo,
    relationTags?: RelationTagsUnsuppressed,
  ): AlertDescriptionMeta[];
}

export type TagRelatedValuesRequest<T extends AllTagType, U extends Vital> = {
  tag: T;
  relatedReading: U;
};

export abstract class BaseAlertDescriptionStrategy<
  T extends AllTagType,
  U extends Vital,
> implements AlertDescriptionStrategy
{
  protected vitalType: VitalType;

  protected formatter: AlertFormatter<T, AlertMessageFormatterProps>;

  protected supportedTagTypes: TagType[];

  protected readingDataType: ReadingDataType;

  protected abstract getTagRelatedValues(
    request: TagRelatedValuesRequest<T, U>,
    alertRelatedVitals: U[],
    alertLoggerInfo: AlertLoggerInfo,
  ): AlertMessageFormatterProps;

  constructor(
    vitalType: VitalType,
    supportedTagTypes: TagType[],
    formatter: AlertFormatter<T, AlertMessageFormatterProps>,
    readingDataType: ReadingDataType,
  ) {
    this.vitalType = vitalType;
    this.supportedTagTypes = supportedTagTypes;
    this.formatter = formatter;
    this.readingDataType = readingDataType;
  }

  getAlertDescription(
    readingIds: string[],
    vitals: PatientVitals,
    alertTags: AllTagType[],
    alertLoggerInfo: AlertLoggerInfo,
    relationTags?: {
      [key: ReadingId]: AllTagType[];
    },
  ): AlertDescriptionMeta[] {
    const alertRelatedVitals = vitals[this.vitalType] as U[];

    const alertTagAssociatedReadings = this.getAlertRelatedReadings(
      alertTags,
      readingIds,
      alertRelatedVitals,
      alertLoggerInfo,
      relationTags,
    );

    return alertTagAssociatedReadings.map((alertTagAssociatedReading) => {
      const { tag, relatedReading } = alertTagAssociatedReading;
      const relatedValues = this.getTagRelatedValues(
        alertTagAssociatedReading,
        alertRelatedVitals,
        alertLoggerInfo,
      );

      return {
        tag,
        readings: relatedValues,
        timestamp: relatedReading.timestamp,
        formatter: this.formatter,
      };
    });
  }

  private getRelatedTags(tags: AllTagType[]): string[] {
    return tags.filter((tag) =>
      [...this.supportedTagTypes].includes(tag),
    ) as T[];
  }

  protected static getTagThresholdValue(
    tag: AllTagType,
    readingsTag: ThresholdSubTypeLike,
    lookUpKey: string,
  ) {
    // We can knowingly cast to number here because most alerts use the value field,
    // Otherwise expect object types for additional threshold fields (ex: systolic).
    const value = get(
      readingsTag,
      `${tag}.${lookUpKey}.value`,
    ) as unknown as number;
    const useValue = value && value !== 0;
    const currentTagValue = useValue
      ? value
      : // This is incorrectly cast.
        // TODO: Recast to ListPatientVitalsResponseThreshold and rewrite description strategies.
        (get(readingsTag, `${tag}.${lookUpKey}`) as unknown as number);

    return currentTagValue;
  }

  protected getThresholdValue(
    tag: T,
    relatedReading: U,
    alertLoggerInfo: AlertLoggerInfo,
  ): number {
    if (isEmpty(relatedReading?.tags)) {
      logger.error(
        BaseAlertDescriptionStrategy.getAlertTagLoggerErrorMsg(
          relatedReading.id,
          alertLoggerInfo,
        ),
      );
    }

    const lookUpKey = this.tagThresholdLookUp(tag, alertLoggerInfo.patientId);
    return BaseAlertDescriptionStrategy.getTagThresholdValue(
      tag,
      relatedReading.tags,
      lookUpKey,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  protected tagThresholdLookUp(tag: T, patientId: string): string {
    if (tag.includes('HIGH_P0')) {
      return GenericTagThresholdKey.HighP0;
    }
    if (tag.includes('HIGH_P1')) {
      return GenericTagThresholdKey.HighP1;
    }
    if (tag.includes('HIGH_P2')) {
      return GenericTagThresholdKey.HighP2;
    }
    if (tag.includes('LOW_P0')) {
      return GenericTagThresholdKey.LowP0;
    }
    if (tag.includes('LOW_P1')) {
      return GenericTagThresholdKey.LowP1;
    }
    if (tag.includes('LOW_P2')) {
      return GenericTagThresholdKey.LowP2;
    }

    throw new MissingTagThresholdError(
      `No ${tag} related threshold exist for patient: ${patientId}`,
    );
  }

  protected static getAlertLoggerErrorMsg(
    readingId: string,
    alertLoggerInfo: AlertLoggerInfo,
  ): string {
    const { patientId, alertId } = alertLoggerInfo;
    return `Can not find reading: ${readingId} for patient: ${patientId} alert: ${alertId}`;
  }

  protected static getAlertTagLoggerErrorMsg(
    readingId: string,
    alertLoggerInfo: AlertLoggerInfo,
  ): string {
    const { patientId, alertId } = alertLoggerInfo;
    return `No related tag for reading: '${readingId}', alert: '${alertId}', patient: '${patientId}'`;
  }

  protected getAlertRelatedReadings(
    alertTags: AllTagType[],
    readingIds: string[],
    vitals: U[],
    alertLoggerInfo: AlertLoggerInfo,
    relationTags?: RelationTagsUnsuppressed,
  ) {
    // find alert related readings
    const populatedReadings = readingIds
      .map((readingId) => {
        const reading = vitals.find(({ id }) => id === readingId);
        if (!reading) {
          logger.error(
            BaseAlertDescriptionStrategy.getAlertLoggerErrorMsg(
              readingId,
              alertLoggerInfo,
            ),
          );
        }
        return reading;
      })
      .filter((reading) => !isEmpty(reading));

    if (populatedReadings.length < 1) {
      logger.warn(
        `Can not find any reading related to patient: '${alertLoggerInfo.patientId}', alert: '${alertLoggerInfo.alertId}'`,
      );
      return [];
    }

    // sort relations here maybe is unnecessary since backend append new readings
    // but guard here just for extra safety
    // sort readings from the earliest date to present date
    const sortedReadings = populatedReadings.sort(
      (a, b) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        new Date(a!.timestamp).valueOf() -
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        new Date(b!.timestamp).valueOf(),
    );

    // Return all unique tag/reading pairs that match the `alertTags` param
    const alertRelatedReadings = sortedReadings.reduce(
      (acc, reading) => {
        if (!reading) return acc;

        const uniqueTagsForReading = [
          ...new Set(Object.keys(reading.tags) as T[]),
        ];

        // Fallback on alertTags because old alerts won't have the readingTags
        // object populated. (The result of this is that old alerts will show
        // alert description for a reading's tag as long as that tag is in that
        // alert, even if it's on another reading)
        const readingTags =
          relationTags?.[this.readingDataType]?.[reading.id] || alertTags;

        const relatedReadingTags = this.getRelatedTags(readingTags);

        uniqueTagsForReading.forEach((tag) => {
          if (relatedReadingTags.includes(tag)) {
            acc.push({
              tag,
              relatedReading: reading,
            });
          }
        });

        return acc;
      },
      [] as {
        tag: T;
        relatedReading: U;
      }[],
    );
    return alertRelatedReadings;
  }
}
