import classnames from 'classnames/bind';
import type { ReactNode } from 'react';
import { createContext, useRef } from 'react';

import { Container } from 'deprecated/mui';

import styles from './styles.module.scss';

const cx = classnames.bind(styles);

type Props = {
  children: ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
  className?: string;
};

export const PageBodyElementContext = createContext<HTMLDivElement | null>(
  null,
);

export function Body({ children, className, maxWidth = 'full' }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const content =
    maxWidth !== 'full' ? (
      <Container maxWidth={maxWidth}>{children}</Container>
    ) : (
      children
    );

  return (
    <PageBodyElementContext.Provider value={ref.current}>
      <div className={cx('container-content', className)} ref={ref}>
        {content}
      </div>
    </PageBodyElementContext.Provider>
  );
}
