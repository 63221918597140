import { useIntl } from 'react-intl';

import { getCcmConditionI18n } from 'pages/adminPanel/patient-profile/tabs/ClinicalProfileTab/clinicalprofile.utils';
import type { CcmCondition } from 'shared/types/clinicalprofile.types';
import { Condition } from 'shared/types/clinicalprofile.types';

export const CCM_CONDITION_RESOURCES: Record<CcmCondition, string> = {
  [Condition.Asthma]:
    'https://www.notion.so/cadencesolutions/Asthma-Education-Guide-ebb7f5fcadd044099d62d7cea69010d5?pvs=25',
  [Condition.AfibAndFlutter]:
    'https://www.notion.so/cadencesolutions/Atrial-Fibrillation-and-Flutter-Education-Guides-5090d62bd2054bfdaf40db1fe1ef06ee?pvs=25',
  [Condition.ChronicKidneyDisease]:
    'https://www.notion.so/cadencesolutions/Chronic-Kidney-Disease-Education-Guides-NOT-ESRD-Dialysis-624d84e1217f4f888bbe2d849eae10d3?pvs=25',
  [Condition.COPD]:
    'https://www.notion.so/cadencesolutions/Chronic-Obstructive-Pulmonary-Disease-Education-Guide-a40a421c076d4f6480cf10a90656edaa?pvs=25',
  [Condition.Dementia]:
    'https://www.notion.so/cadencesolutions/Dementia-Education-Guide-06012ccd138e479d8984b7e57c00c835?pvs=25',
  [Condition.TypeTwoDiabetes]:
    'https://www.notion.so/cadencesolutions/Diabetes-Education-Guide-1bb07b0ee8174ecfa424c8eebde6d7c5?pvs=25',
  [Condition.CHF]:
    'https://www.notion.so/cadencesolutions/Heart-Failure-Education-Guide-9a4f67a171e64197b479f9ab04f9e1bd?pvs=25',
  [Condition.Hyperlipidemia]:
    'https://www.notion.so/cadencesolutions/Hyperlipidemia-High-Cholesterol-Education-Guide-b7c5e7dddd2b4af68a32cb2d7c91d39c?pvs=25',
  [Condition.Hypertension]:
    'https://www.notion.so/cadencesolutions/Hypertension-Education-Guide-17e3f8b9f3534375937effb34454c205?pvs=25',
  [Condition.Hypothyroidism]:
    'https://www.notion.so/cadencesolutions/Hypothyroidism-Education-Guide-ad924b5810ed4d6a904cadd650fda720?pvs=25',
  [Condition.IschemicHeartDisease]:
    'https://www.notion.so/cadencesolutions/Ischemic-Heart-Disease-Education-Guide-0c00df05615c4928804851caa4838846?pvs=25',
  [Condition.MorbidObesity]:
    'https://www.notion.so/cadencesolutions/Morbid-Obesity-Education-Guide-787c576fd7e4464b8d204fb78a3578a0?pvs=25',
  [Condition.ObstructiveSleepApnea]:
    'https://www.notion.so/cadencesolutions/Obstructive-Sleep-Apnea-db422639b19245db8a827c8536bf9faf?pvs=25',
  [Condition.Osteoarthritis]:
    'https://www.notion.so/cadencesolutions/Osteoarthritis-c4630b0654a642378f8a14d76d224724?pvs=25',
  [Condition.PeripheralArteryDisease]:
    'https://www.notion.so/cadencesolutions/Peripheral-Arterial-Disease-141b6dddc80847cfae3bdbb648b9376a?pvs=25',
};

export function useCcmConditionI18n(): Record<CcmCondition, string> {
  const intl = useIntl();
  return getCcmConditionI18n(intl);
}
