import type { TypeaheadSearchProps } from 'shared/common/TypeaheadSearch';
import { TypeaheadSearch } from 'shared/common/TypeaheadSearch';

import type { BaseFieldProps } from '../BaseField';
import { BaseField } from '../BaseField';

type RelevantSearchProps<T> = Omit<
  TypeaheadSearchProps<T>,
  | 'freeSolo'
  | 'fullWidth'
  | 'hasError'
  | 'onClose'
  | 'onSelect'
  | 'PopperComponent'
>;

type Props<T> = BaseFieldProps & RelevantSearchProps<T> & {};

/**
 * this is a newer version of Form.Autocomplete that takes a more opinionated approach
 * to styling and is compatible with TypeaheadSearch's exported components.
 */
export function Search<T>({
  label,
  name,
  size,
  help,
  persistedHelp,
  ...rest
}: Props<T>) {
  return (
    <BaseField
      label={label}
      name={name}
      size={size}
      help={help}
      persistedHelp={persistedHelp}
    >
      {({ controller: { field, fieldState } }) => (
        <TypeaheadSearch
          {...rest}
          freeSolo={false}
          fullWidth
          hasError={!!fieldState.error}
          onClose={() => field.onChange(null)}
          onSelect={(selected) => field.onChange(selected)}
          PopperComponent={TypeaheadSearch.ResponsivePopper}
        />
      )}
    </BaseField>
  );
}
