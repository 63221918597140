import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  FieldGroup,
  Script,
  YesNoRadioGroup,
} from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { scriptLabel } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';
import { useTimeEstimate } from 'pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Form } from 'shared/common/Form';
import type { ConfiguredForm } from 'shared/common/Form/FormContainer';
import { Wizard } from 'shared/common/Wizard';

import type { CNSectionMap } from '../../metadata';
import { formRow } from './EmergencyVisits.css';
import type { EmergencyVisitsFormFields } from './formConfig';
import { useReasonForVisitI18nMap, useTypeOfVisitI18nMap } from './i18n';
import { ReasonForVisit, TypeOfVisit } from './types';

type Props = {
  form: ConfiguredForm<EmergencyVisitsFormFields>;
  sections: CNSectionMap;
  nextPath?: string;
};

export function SharedEmergencyVisits({ form, nextPath, sections }: Props) {
  const history = useHistory();
  const intl = useIntl();
  const { patientReportedVisits } = useSectionVisibility(form);
  const typeOfVisitI18nMap = useTypeOfVisitI18nMap();
  const reasonForVisitI18nMap = useReasonForVisitI18nMap();

  return (
    <Wizard.Step
      sections={sections}
      form={form}
      title={intl.formatMessage({ defaultMessage: 'Emergency Visits' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={nextPath ? () => history.push(nextPath) : undefined}
    >
      <FieldGroup>
        <YesNoRadioGroup
          name="newVisits"
          classes={{ label: scriptLabel }}
          yesLabel={<FormattedMessage defaultMessage="Add visit" />}
          noLabel={<FormattedMessage defaultMessage="Nothing to report" />}
          label={
            <Script>
              <FormattedMessage defaultMessage="Have you had any emergency visits in the last 3 months? This includes urgent care, the emergency room, or being admitted to the hospital." />
            </Script>
          }
        />
        {patientReportedVisits && (
          <>
            <Form.CheckboxGroup
              required
              compact
              size={12}
              name="typeOfVisit"
              label={
                <FormattedMessage defaultMessage="What type of emergency visit?" />
              }
              help={<FormattedMessage defaultMessage="Select all that apply" />}
              options={[
                {
                  label: typeOfVisitI18nMap[TypeOfVisit.UrgentCare],
                  value: TypeOfVisit.UrgentCare,
                },
                {
                  label: typeOfVisitI18nMap[TypeOfVisit.EmergencyDepartment],
                  value: TypeOfVisit.EmergencyDepartment,
                },
                {
                  label: typeOfVisitI18nMap[TypeOfVisit.HospitalAdmission],
                  value: TypeOfVisit.HospitalAdmission,
                },
              ]}
            />
            <Form.Row className={formRow}>
              <Form.DatePicker
                required
                name="startDate"
                size={6}
                label={<FormattedMessage defaultMessage="Start date" />}
              />
              <Form.DatePicker
                name="endDate"
                size={6}
                label={<FormattedMessage defaultMessage="End date" />}
              />
            </Form.Row>
            <Form.TextField
              name="location"
              size={12}
              label={<FormattedMessage defaultMessage="Location" />}
            />
            <Form.CheckboxGroup
              required
              compact
              size={12}
              name="reasonForVisit"
              label={
                <FormattedMessage defaultMessage="Was this related to your condition of congestive heart failure?" />
              }
              help={
                <FormattedMessage defaultMessage="Select what the patient was admitted for" />
              }
              options={[
                {
                  label: reasonForVisitI18nMap[ReasonForVisit.CHF],
                  value: ReasonForVisit.CHF,
                },
                {
                  label: reasonForVisitI18nMap[ReasonForVisit.HTN],
                  value: ReasonForVisit.HTN,
                },
                {
                  label: reasonForVisitI18nMap[ReasonForVisit.T2D],
                  value: ReasonForVisit.T2D,
                },
                {
                  label: reasonForVisitI18nMap[ReasonForVisit.Other],
                  value: ReasonForVisit.Other,
                },
              ]}
            />
            <Form.TextField
              name="additionalInformation"
              size={12}
              label={
                <FormattedMessage defaultMessage="Additional information" />
              }
            />
          </>
        )}
      </FieldGroup>
    </Wizard.Step>
  );
}

function useSectionVisibility(form: ConfiguredForm<EmergencyVisitsFormFields>) {
  const newVisits = form.watch('newVisits');

  return {
    patientReportedVisits: newVisits === 'true',
  };
}
