import type { IntlShape } from 'react-intl';

import { validators } from 'shared/common/Form/validations';

export type EndCallFormFields = {
  endCallReason: EndCallReason;
  endCallNotes: string;
};

export enum EndCallReason {
  PatientUnableToSpeak = 'PATIENT_UNABLE_TO_SPEAK',
  SchedulingErrorDisenrollment = 'SCHEDULING_ERROR_OR_DISENROLLMENT',
  PatientInNonCadenceState = 'PATIENT_IN_NON_CADENCE_STATE',
  PatientHospitalized = 'PATIENT_HOSPITALIZED',
  PatientRefusedAppointment = 'PATIENT_REFUSED_APPOINTMENT',
  PatientDissatisfied = 'PATIENT_DISSATISFIED',
  TimeConstraints = 'TIME_CONSTRAINTS',
  DeviceDelayTroubleshooting = 'DEVICE_DELAY_OR_TROUBLESHOOTING',
  Other = 'OTHER',
}

export function getEndCallFormConfig(intl: IntlShape) {
  const { required, enumType, maxLengthString } = validators(intl);

  return {
    fields: {
      endCallReason: {
        defaultValue: undefined,
        validation: required(
          enumType({ source: EndCallReason, pluck: 'values' }),
        ),
      },
      endCallNotes: {
        default: undefined,
        validation: maxLengthString({ maxLength: 255 }),
      },
    },
  };
}
