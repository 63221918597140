import isEqualWith from 'lodash/isEqualWith';
import omit from 'lodash/omit';
import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';

import type {
  EditableNoteState,
  EncounterModuleInstance,
  NoteEditorContent,
} from 'pages/patients/patientDetails/ui/Notes/Notes.types';
import { EncounterModuleId } from 'pages/patients/patientDetails/ui/Notes/Notes.types';
import { useEncounterTypePropsToOmit } from 'pages/patients/patientDetails/ui/Notes/utils/encounterTypeUtils';
import { useCaregivers, usePatientDetails } from 'shared/hooks/queries';

import { NoteEditorContextProvider } from './NoteEditorContext';

export function ConfiguredNoteEditorContextProvider({
  patientId,
  children,
}: {
  patientId: string;
  children: ReactNode;
}) {
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [noteEditorContent, setNoteEditorContent] =
    useState<Maybe<NoteEditorContent>>();
  const [encounterModuleInstances, setEncounterModuleInstances] = useState<
    EncounterModuleInstance[]
  >([]);
  const [isPreviewing, setIsPreviewing] = useState<boolean>(false);
  const [editingNote, setEditingNoteState] =
    useState<Nullable<EditableNoteState>>(null);

  // If the encounterModule schema does not have sms or phone_number anymore, omit
  // them from the UI schema and from the initialValues
  const fieldsToOmit = useEncounterTypePropsToOmit();

  const setEditingNote = useCallback(
    (newState: Nullable<EditableNoteState>) => {
      if (!areNoteStatesShallowEqual(editingNote, newState)) {
        setEditingNoteState(newState);
      }
    },
    [editingNote],
  );

  const openEditor = useCallback(() => {
    setIsEditorOpen(true);
  }, []);

  const closeEditor = useCallback(() => {
    setIsEditorOpen(false);
    setIsPreviewing(false);
  }, []);

  const formatAndSetEncounterModuleInstances = useCallback(
    (instances: EncounterModuleInstance[]) => {
      // TODO: remove this formatting once
      // https://cadencerpm.atlassian.net/browse/PLAT-4152 is complete
      const formattedInstances = instances.map((instance) => ({
        ...instance,
        inputs: omit(
          instance.inputs,
          instance.encounter_module_id === EncounterModuleId.EncounterType
            ? fieldsToOmit
            : [],
        ),
      }));
      setEncounterModuleInstances(formattedInstances);
    },
    [fieldsToOmit],
  );

  const { data: patientDetails } = usePatientDetails(patientId, false, true);
  const { data: caregivers } = useCaregivers(patientId);

  return (
    <NoteEditorContextProvider
      value={{
        isEditorOpen,
        openEditor,
        closeEditor,
        noteEditorContent,
        setNoteEditorContent,
        encounterModuleInstances,
        setEncounterModuleInstances: formatAndSetEncounterModuleInstances,
        isPreviewing,
        setIsPreviewing,
        editingNote,
        setEditingNote,
        contacts: patientDetails?.contacts ?? [],
        caregivers: caregivers ?? [],
        isSubmitting,
        setIsSubmitting,
      }}
    >
      {children}
    </NoteEditorContextProvider>
  );
}

function areNoteStatesShallowEqual(
  a: Nullable<EditableNoteState>,
  b: Nullable<EditableNoteState>,
) {
  return isEqualWith(a, b, (valueA, valueB, key) => {
    // Only consider notes to be different based on their id
    if (key === 'note') {
      return (
        (valueA as EditableNoteState['note'])?.id ===
        (valueB as EditableNoteState['note'])?.id
      );
    }
    // Compare other values as normal using isEqual
    return undefined;
  });
}
