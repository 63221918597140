/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessageTemplateListSchema } from '../models/MessageTemplateListSchema';
import type { MessageTemplateSchema } from '../models/MessageTemplateSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MessageTemplateService {
    /**
     * Create new MessageTemplate
     * @param requestBody A JSON object containing MessageTemplate information
     * @returns MessageTemplateSchema Successful operation
     * @throws ApiError
     */
    public static postNotificationApiV1MessageTemplate(
        requestBody: MessageTemplateSchema,
    ): CancelablePromise<MessageTemplateSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/notification/api/v1/message-template',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get a list of message templates by Hospital ID
     * @param hospitalId Hospital ID used to query message_templates
     * @returns MessageTemplateListSchema Successful operation
     * @throws ApiError
     */
    public static getNotificationApiV1MessageTemplateHospitals(
        hospitalId: string,
    ): CancelablePromise<MessageTemplateListSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/notification/api/v1/message-template/hospitals/{hospital_id}',
            path: {
                'hospital_id': hospitalId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                404: `Not found`,
            },
        });
    }
    /**
     * Delete MessageTemplate by ID
     * @param messageTemplateId ID of the MessageTemplate
     * @returns void
     * @throws ApiError
     */
    public static deleteNotificationApiV1MessageTemplate(
        messageTemplateId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/notification/api/v1/message-template/{message_template_id}',
            path: {
                'message_template_id': messageTemplateId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get message template by MessageTemplate ID
     * @param messageTemplateId MessageTemplate ID to query for
     * @returns MessageTemplateSchema Successful operation
     * @throws ApiError
     */
    public static getNotificationApiV1MessageTemplate(
        messageTemplateId: string,
    ): CancelablePromise<MessageTemplateSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/notification/api/v1/message-template/{message_template_id}',
            path: {
                'message_template_id': messageTemplateId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                404: `Not found`,
            },
        });
    }
    /**
     * Update MessageTemplate by ID
     * @param messageTemplateId ID of the MessageTemplate
     * @param requestBody A JSON object containing MessageTemplate information
     * @returns MessageTemplateSchema Successful operation
     * @throws ApiError
     */
    public static putNotificationApiV1MessageTemplate(
        messageTemplateId: number,
        requestBody: MessageTemplateSchema,
    ): CancelablePromise<MessageTemplateSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/notification/api/v1/message-template/{message_template_id}',
            path: {
                'message_template_id': messageTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
