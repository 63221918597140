import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage, useIntl } from 'react-intl';

import { LoadingPlaceholder } from 'shared/common/LoadingPlaceholder';
import { ProgramTag } from 'shared/common/ProgramTag';
import { Tooltip } from 'shared/common/Tooltip';
import { usePatientDetails } from 'shared/hooks/queries';
import {
  getCcmConditionsFromProgramAndStatus,
  getConditionAbbreviationI18nMap,
  getRpmConditionsFromProgramAndStatus,
} from 'shared/patient/conditions.utils';
import { Tag } from 'shared/tempo/atom/Tag';
import type { Patient } from 'shared/types/patient.types';

import { loadingContainer } from '../PatientInfoSummary.css';
import { PatientMedsInfoTag } from '../PatientMedsInfoTag';
import { SkeletonInfoSummary } from '../SkeletonInfoSummary';
import { errorTag, patientInfoTagsContainer } from './PatientInfoTags.css';
import { TagType } from './types';

type Props = {
  patient: Patient;
  className?: string;
  show?: TagType[];
};

export const PatientInfoTagsWrapper = ({
  patientId,
  className,
  show,
}: {
  patientId: string;
  className?: string;
  show?: TagType[];
}) => {
  // TODO: replace with GRPC version of usePatientDetails EMR-1444
  const { data: patient, isLoading } = usePatientDetails(patientId, false);
  return (
    <LoadingPlaceholder
      className={loadingContainer}
      isLoading={isLoading}
      placeholder={<SkeletonInfoSummary />}
    >
      {patient && (
        <PatientInfoTags patient={patient} className={className} show={show} />
      )}
    </LoadingPlaceholder>
  );
};

export const PatientInfoTags = ({
  patient,
  className,
  show = [TagType.Programs, TagType.MedInfo],
}: Props) => {
  const intl = useIntl();
  const conditions = getRpmConditionsFromProgramAndStatus(
    patient.programs,
    patient.status,
  );

  const ccmConditions = getCcmConditionsFromProgramAndStatus(
    patient.programs,
    patient.status,
  );
  const isParticipatingCCM = !isEmpty(ccmConditions);
  const conditionAbbreviations = getConditionAbbreviationI18nMap(intl);
  const showProgramTags = show.includes(TagType.Programs);
  const showMedInfoTags = show.includes(TagType.MedInfo);
  return (
    <div className={cx(patientInfoTagsContainer, className)}>
      {showProgramTags && (
        <>
          {conditions.length > 0 && (
            <ProgramTag
              program={intl.formatMessage({
                defaultMessage: 'RPM',
                description: 'Abbreviation for "Remote Patient Monitoring"',
              })}
              badges={conditions.map(
                (condition) => conditionAbbreviations[condition],
              )}
            />
          )}
          {isParticipatingCCM && (
            <Tooltip tooltipMessage={ccmConditions.join(', ')}>
              <ProgramTag
                program={intl.formatMessage({
                  defaultMessage: 'CCM',
                  description: 'Abbreviation for "Chronic Care Management"',
                })}
                badges={[(ccmConditions.length || 0).toString()]}
              />
            </Tooltip>
          )}
          {!conditions.length && !isParticipatingCCM && (
            <Tag variant="error" className={errorTag}>
              <FormattedMessage defaultMessage="No Program Assigned" />
            </Tag>
          )}
        </>
      )}
      {showMedInfoTags && <PatientMedsInfoTag patient={patient} />}
    </div>
  );
};
