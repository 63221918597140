import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from 'shared/common/Form';
import { useFormFromConfig } from 'shared/common/Form/FormContainer';
import { Modal } from 'shared/common/Modal';
import { Button } from 'shared/tempo/atom/Button';
import type { Provider } from 'shared/types/provider.types';

import { AssignmentAutocomplete } from '../AssignmentAutocomplete';
import type { Task } from '../types';
import type { FormFields } from './form.config';
import { getFormConfig } from './form.config';

type Props = {
  open: boolean;
  task: Task;
  onClose: () => void;
  onSubmit: (assignee: Provider) => void;
};

export function ReassignmentModal({ open, task, onClose, onSubmit }: Props) {
  const intl = useIntl();
  const form = useFormFromConfig<FormFields>(getFormConfig(intl));

  return (
    <Modal open={open} onClose={onClose}>
      <Form form={form}>
        {({ canSubmit }) => (
          <>
            <Modal.Header
              title={intl.formatMessage({
                defaultMessage: 'Choose another user',
              })}
            />
            <Modal.Body>
              <Form.Row>
                <AssignmentAutocomplete
                  name="assignee"
                  view={{ type: 'participants', task }}
                />
              </Form.Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onPress={onClose}>
                <FormattedMessage defaultMessage="Cancel" />
              </Button>
              <Button
                variant="primary"
                isDisabled={!canSubmit}
                onPress={() => {
                  onSubmit(form.getValues('assignee'));
                  onClose();
                }}
              >
                <FormattedMessage defaultMessage="Select user" />
              </Button>
            </Modal.Footer>
          </>
        )}
      </Form>
    </Modal>
  );
}
