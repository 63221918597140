import { subDays } from 'date-fns';
import noop from 'lodash/noop';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from 'deprecated/mui';
import { bigButton } from 'pages/auth/ui/shared/auth.css';
import { BasicInfoPatientInfoSummary } from 'pages/patients/PatientInfoSummary';
import { PatientProfileProvider } from 'pages/patients/PatientProfile/context/PatientProfileCtx';
import { ProfileAlertDetail } from 'pages/patients/patientDetails/ui/ProfileAlertDetail';
import { SidePanel } from 'shared/common/Page';
import { usePatientDetails } from 'shared/hooks/queries';
import { flexSection } from 'shared/jsStyle';
import { Button } from 'shared/tempo/atom/Button';
import { color } from 'shared/tempo/theme';

import { usePatientDetailsCtx } from '../PatientProfile/PatientDetailContext';
import { PatientListSidebarVitalsTable } from './PatientListSidebarVitalsTable';

export const PatientListSidebarPanel = () => {
  const { patientDetails } = usePatientDetailsCtx();
  const { data: patientData } = usePatientDetails(
    patientDetails?.id,
    false,
    !!patientDetails?.id,
  );

  const handleClick = () => {
    window.open(`/patients/${patientDetails?.id}`, '_blank');
  };

  const [vitalsStartDate, setVitalsStartDate] = useState(
    subDays(new Date(), 5),
  );
  const [vitalsEndDate, setVitalsEndDate] = useState(new Date());

  return (
    <SidePanel autoDismiss>
      <SidePanel.Header
        title={`${patientDetails?.first_name} ${patientDetails?.last_name}`}
      />
      {/* TODO: PLAT-2521 */}
      <SidePanel.Body>
        <PatientProfileProvider
          value={{
            vitalsStartDate,
            vitalsEndDate,
            setVitalsStartDate,
            setVitalsEndDate,
            // Unused on this page
            currentModal: null,
            setCurrentModal: noop,
          }}
        >
          <Box sx={{ padding: '16px 24px 8px' }}>
            <BasicInfoPatientInfoSummary patientDetails={patientData} />
          </Box>
          {patientDetails && (
            <ProfileAlertDetail patientId={patientDetails.id} />
          )}
          <Box sx={{ padding: '8px 24px 8px' }}>
            <PatientListSidebarVitalsTable />
          </Box>
        </PatientProfileProvider>
      </SidePanel.Body>
      <SidePanel.Footer>
        <Box
          sx={{
            ...flexSection('column', 'center', 'center'),
            width: '100%',
            height: '112px',
            borderTop: `1px solid ${color.Theme.Light['Base Form Disabled']}`,
            padding: '32px',
          }}
        >
          <Button className={bigButton} onPress={handleClick}>
            <FormattedMessage defaultMessage="Go to patient profile" />
          </Button>
        </Box>
      </SidePanel.Footer>
    </SidePanel>
  );
};
