import type { Schema } from 'shared/common/@deprecated/SchemaDrivenForm/';

import { mapSchema } from './mapSchema';

/**
 * Replaces or removes keywords not supported by rjsf
 *
 * @param schema - Original schema
 * @returns new schema with keywords either replaced or removed
 */
export function replaceKeywords(schema: Schema) {
  return removeUnevaluatedProperties(schema);
}

function removeUnevaluatedProperties(schema: Schema) {
  return mapSchema(schema, (s: Schema) => {
    // Simply delete the "unevaluatedProperties" keyword because we are not
    // using it on the frontend. "unevaluatedProperties" is used to ensure
    // the frontend doesn't accidentally try to save data with incorrect schema
    const { unevaluatedProperties, ...rest } = s;
    return rest;
  });
}
