import type { SectionMeta } from 'shared/common/Wizard/path.utils';
import type { PatientSchema } from 'shared/generated/api/pms/models/PatientSchema';
import { createCtx } from 'shared/hooks/createCtx';
import type { StandaloneCaregiver } from 'shared/types/caregiver.types';
import type { ConditionProgram } from 'shared/types/condition.types';

import type { CNVisitType } from './types';

export type CNFormContext = {
  noteId: Maybe<number>;
  patientId: string;
  cnVisitType: CNVisitType;
  program: Nullable<ConditionProgram>;
  zendeskTicketId: Nullable<number>;
  contacts: Exclude<Required<PatientSchema>['contacts'], undefined>;
  caregivers: StandaloneCaregiver[];
  clearEditor: () => void;
  sections: readonly SectionMeta[];
  appointmentId: Nullable<string>;
  noShowAppointmentId: Nullable<string>;
};

export const [useCNFormContext, CNFormContextProvider] =
  createCtx<CNFormContext>('CNFormContext');
