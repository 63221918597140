import type { Note } from 'shared/types/note.types';

export const sortNoteLabelsAlphabetically = (data: Note[] | []) => {
  data.forEach((note: Note) =>
    note.labels.sort((firstEl, secondEl) =>
      firstEl.name.localeCompare(secondEl.name),
    ),
  );

  return data;
};
