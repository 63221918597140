import cx from 'classnames';
import type { ReactChild, ReactElement } from 'react';

import { Tooltip } from 'deprecated/mui';
import { customArrow, customTooltip } from 'shared/common/Tooltip/Tooltip.css';

import {
  commonInfoField,
  commonInfoLabel,
  commonInfoText,
} from './CommonInfoField.css';

type CommonInfoProps = {
  label?: ReactElement;
  text: ReactChild;
  toolTipMsg?: ReactChild;
  className?: string;
};

type CommonInfoWrapperProps = CommonInfoProps & {
  useSubtleLabelStyle?: boolean;
};

export const PLACEHOLDER = '-';

const CommonInfoFieldSubtle = ({
  label,
  text,
  toolTipMsg,
  className,
}: CommonInfoProps) => {
  const content = (
    <span>
      {label ? (
        <>
          <span className={commonInfoLabel}>{label}</span>:&nbsp;
          <span className={cx(commonInfoText, className)}>{text}</span>
        </>
      ) : (
        <span className={cx(commonInfoText, className)}>{text}</span>
      )}
    </span>
  );

  return toolTipMsg ? (
    <Tooltip
      classes={{
        tooltip: customTooltip,
        arrow: customArrow,
      }}
      arrow
      title={toolTipMsg}
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
};

const CommonInfoFieldRegular = ({
  label,
  text,
  toolTipMsg,
  className,
}: CommonInfoProps) => {
  const content = (
    <span className={cx(commonInfoField, className)}>
      {label ? (
        <>
          {label}:&nbsp;{text}
        </>
      ) : (
        text
      )}
    </span>
  );

  return toolTipMsg ? <Tooltip title={toolTipMsg}>{content}</Tooltip> : content;
};

export const CommonInfoField = ({
  useSubtleLabelStyle = false,
  ...rest
}: CommonInfoWrapperProps) =>
  useSubtleLabelStyle ? (
    <CommonInfoFieldSubtle {...rest} />
  ) : (
    <CommonInfoFieldRegular {...rest} />
  );
