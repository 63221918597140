import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import type { PatientEhrMedications } from 'shared/generated/grpcGateway/medication.pb';
import { MedicationService } from 'shared/generated/grpcGateway/medication.pb';
import { idToGrpcName } from 'shared/utils/grpc';

const PATIENT_EHR_MEDICATION_QUERY_KEY_BASE = [
  'rpm',
  'v1',
  'patients',
  'ehrMedications',
] as const;

const patientMedicationKeys = {
  patientEhrMedications: (patientId: string) =>
    [...PATIENT_EHR_MEDICATION_QUERY_KEY_BASE, patientId] as const,
};

export function usePatientEhrMedications(
  patientId: string,
  options?: UseQueryOptions<PatientEhrMedications>,
) {
  return useQuery({
    queryKey: patientMedicationKeys.patientEhrMedications(patientId),
    queryFn: () =>
      MedicationService.GetPatientEhrMedications({
        name: idToGrpcName('patients', patientId, 'ehrMedications'),
      }),
    ...options,
    enabled: Boolean(patientId) && (options?.enabled ?? true),
  });
}
