import cx from 'classnames';

import type { ExpandState } from 'shared/common/TextArea';
import { TextArea as TextAreaInput } from 'shared/common/TextArea';
import { fullWidth } from 'shared/jsStyle/utils.css';

import type { BaseFieldProps } from '../BaseField';
import { BaseField } from '../BaseField';

type TextAreaProps = BaseFieldProps & {
  expand?: ExpandState;
  expandHeight?: number;
  maxLength?: number;
  maxRows?: number;
  rows?: number;
  placeholder?: string;
  value?: string;
  classes?: {
    root?: string;
    input?: string;
  };
};

export function TextArea({
  maxRows,
  rows,
  placeholder,
  expand,
  expandHeight,
  maxLength,
  classes,
  ...baseProps
}: TextAreaProps) {
  return (
    <BaseField {...baseProps} className={cx(classes?.root)}>
      {({ controller }) => (
        <TextAreaInput
          rows={rows}
          maxRows={maxRows}
          maxLength={maxLength}
          expand={expand}
          expandHeight={expandHeight}
          placeholder={placeholder}
          disabled={baseProps.isDisabled}
          error={!!controller.fieldState.error}
          {...controller.field}
          id={baseProps.name}
          className={cx(classes?.input, fullWidth)}
        />
      )}
    </BaseField>
  );
}
