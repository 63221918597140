import { useIntl } from 'react-intl';

import { useToaster } from 'shared/tempo/molecule/Toast';

export function useEnqueueOpenEditorError() {
  const { toaster } = useToaster();
  const intl = useIntl();

  return () => {
    toaster.error(
      intl.formatMessage({
        defaultMessage:
          'You already have a note in progress. Please publish or discard the open note first',
      }),
    );
  };
}
