import type { ReactNode } from 'react';
import { Fragment } from 'react';

import type { TooltipPlacement } from 'shared/tempo/atom/Tooltip';
import { Tooltip } from 'shared/tempo/atom/Tooltip';

import {
  content,
  hotkey as hotkeyClass,
  hotkeyContainer,
  title as titleClass,
} from './HotkeyTooltip.css';
import type { HotkeyChar } from './types';

type Props = {
  title: string;
  hotkey: HotkeyChar[];
  disabled?: boolean;
  children: ReactNode;
  placement?: TooltipPlacement;
  classes?: {
    tooltip?: string;
    triggerContainer?: string;
  };
};

export function HotkeyTooltip({
  title,
  hotkey,
  disabled,
  children,
  placement = 'top',
  classes = {},
}: Props) {
  const hotkeyDisplay = hotkey.flatMap((char, idx) => {
    const hkey = <Hotkey>{char}</Hotkey>;

    if (idx < hotkey.length - 1) {
      return [hkey, <Plus />];
    }

    return hkey;
  });

  return (
    <Tooltip
      size="large"
      isDisabled={disabled}
      placement={placement}
      classes={classes}
      content={
        <div className={content}>
          <div className={titleClass}>{title}</div>
          <div className={hotkeyContainer}>
            {hotkeyDisplay.map((keyComponent, idx) => (
              <Fragment key={idx}>{keyComponent}</Fragment>
            ))}
          </div>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
}

function Hotkey({ children }: { children: string }) {
  return <div className={hotkeyClass}>{children}</div>;
}

function Plus() {
  return <div>+</div>;
}
