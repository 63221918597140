/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as GoogleProtobufTimestamp from "../../google/protobuf/timestamp.pb"

type Absent<T, K extends keyof T> = { [k in Exclude<keyof T, K>]?: undefined };
type OneOf<T> =
  | { [k in keyof T]?: undefined }
  | (
    keyof T extends infer K ?
      (K extends string & keyof T ? { [k in K]: T[K] } & Absent<T, K>
        : never)
    : never);

export enum EHR {
  EHR_UNSPECIFIED = "EHR_UNSPECIFIED",
  ATHENA = "ATHENA",
  EPIC = "EPIC",
  CERNER = "CERNER",
  CUSTOM = "CUSTOM",
}

export enum MedManagementDelegation {
  MED_MANAGEMENT_DELEGATION_UNSPECIFIED = "MED_MANAGEMENT_DELEGATION_UNSPECIFIED",
  MED_MANAGEMENT_DELEGATION_OTHER = "MED_MANAGEMENT_DELEGATION_OTHER",
  OPT_OUT_PROVIDER_MANAGED = "OPT_OUT_PROVIDER_MANAGED",
  OPT_OUT_RECOMMENDS_MEDS = "OPT_OUT_RECOMMENDS_MEDS",
  OPT_IN_FULLY_MANAGED = "OPT_IN_FULLY_MANAGED",
  OPT_IN_CURRENT_MEDS = "OPT_IN_CURRENT_MEDS",
}

export enum PatientStatus {
  PATIENT_STATUS_UNSPECIFIED = "PATIENT_STATUS_UNSPECIFIED",
  IDENTIFIED = "IDENTIFIED",
  ELIGIBLE = "ELIGIBLE",
  NOT_ELIGIBLE = "NOT_ELIGIBLE",
  PENDING = "PENDING",
  PENDING_ORDER = "PENDING_ORDER",
  PENDING_ENROLLMENT = "PENDING_ENROLLMENT",
  NEEDS_REVIEW = "NEEDS_REVIEW",
  ENROLLED = "ENROLLED",
  NOT_ENROLLED = "NOT_ENROLLED",
  DISENROLLED = "DISENROLLED",
  PROVIDER_DECLINED = "PROVIDER_DECLINED",
  PATIENT_DECLINED = "PATIENT_DECLINED",
  WAITING_FOR_PROVIDER = "WAITING_FOR_PROVIDER",
  READY_TO_ENROLL = "READY_TO_ENROLL",
  APPROVED = "APPROVED",
  ORDER_PENDED = "ORDER_PENDED",
  DUPLICATE = "DUPLICATE",
  DELETED = "DELETED",
}

export enum ProgramCondition {
  PROGRAM_CONDITION_UNSPECIFIED = "PROGRAM_CONDITION_UNSPECIFIED",
  HEART_FAILURE = "HEART_FAILURE",
  HYPERTENSION = "HYPERTENSION",
  COPD = "COPD",
  TYPE_2_DIABETES = "TYPE_2_DIABETES",
}

export enum ProgramStatus {
  PROGRAM_STATUS_UNSPECIFIED = "PROGRAM_STATUS_UNSPECIFIED",
  PROGRAM_STATUS_ELIGIBLE = "PROGRAM_STATUS_ELIGIBLE",
  PROGRAM_STATUS_ORDERED = "PROGRAM_STATUS_ORDERED",
  PROGRAM_STATUS_ENROLLED = "PROGRAM_STATUS_ENROLLED",
  PROGRAM_STATUS_DISENROLLED = "PROGRAM_STATUS_DISENROLLED",
  PROGRAM_STATUS_SELECTED = "PROGRAM_STATUS_SELECTED",
  PROGRAM_STATUS_SUGGESTED = "PROGRAM_STATUS_SUGGESTED",
}

export enum ProgramType {
  PROGRAM_TYPE_UNSPECIFIED = "PROGRAM_TYPE_UNSPECIFIED",
  RPM = "RPM",
  CCM = "CCM",
}

export enum Gender {
  GENDER_UNSPECIFIED = "GENDER_UNSPECIFIED",
  FEMALE = "FEMALE",
  MALE = "MALE",
  GENDER_OTHER = "GENDER_OTHER",
  GENDER_X = "GENDER_X",
}

export enum EncounterType {
  ENCOUNTER_TYPE_UNSPECIFIED = "ENCOUNTER_TYPE_UNSPECIFIED",
  ENROLLMENT = "ENROLLMENT",
  ENROLLMENT_FOLLOW_UP = "ENROLLMENT_FOLLOW_UP",
  RN_VISIT = "RN_VISIT",
  NP_VISIT = "NP_VISIT",
  ALERT_DOCUMENTATION = "ALERT_DOCUMENTATION",
  RESULTS_FOLLOW_UP = "RESULTS_FOLLOW_UP",
  REGULAR_RN_VISIT = "REGULAR_RN_VISIT",
  REGULAR_NP_VISIT = "REGULAR_NP_VISIT",
  DISENROLLMENT = "DISENROLLMENT",
  VIRTUAL_ENROLLMENT = "VIRTUAL_ENROLLMENT",
  INITIAL_RN_VISIT = "INITIAL_RN_VISIT",
  INITIAL_NP_VISIT = "INITIAL_NP_VISIT",
  INITIAL_CN_VISIT = "INITIAL_CN_VISIT",
  REGULAR_CN_VISIT = "REGULAR_CN_VISIT",
  ENROLLMENT_CALL = "ENROLLMENT_CALL",
  CCM_CARE_PLAN = "CCM_CARE_PLAN",
  REGULAR_CCM_VISIT = "REGULAR_CCM_VISIT",
}

export enum OrderingRoute {
  ORDERING_ROUTE_UNSPECIFIED = "ORDERING_ROUTE_UNSPECIFIED",
  APPOINTMENT_BASED = "APPOINTMENT_BASED",
  ELIGIBLE_PATIENT_LIST = "ELIGIBLE_PATIENT_LIST",
  DIRECT_TO_PEND_ASSESSMENT_AND_PLAN = "DIRECT_TO_PEND_ASSESSMENT_AND_PLAN",
}

export enum CareProviderRole {
  ROLE_UNSPECIFIED = "ROLE_UNSPECIFIED",
  MD = "MD",
  DO = "DO",
  NP = "NP",
  RN = "RN",
  MA = "MA",
  PA = "PA",
  ROLE_OTHER = "ROLE_OTHER",
  LPN = "LPN",
}

export enum CareProviderTeamRole {
  TEAM_ROLE_UNSPECIFIED = "TEAM_ROLE_UNSPECIFIED",
  NURSE_PRACTITIONER = "NURSE_PRACTITIONER",
  CLINICAL_NAVIGATOR = "CLINICAL_NAVIGATOR",
  REGISTERED_NURSE = "REGISTERED_NURSE",
  PATIENT_SUCCESS_ADVOCATE = "PATIENT_SUCCESS_ADVOCATE",
  ONBOARDER = "ONBOARDER",
  ENROLLER = "ENROLLER",
  ADMINISTRATIVE_STAFF = "ADMINISTRATIVE_STAFF",
  TEAM_ROLE_OTHER = "TEAM_ROLE_OTHER",
  GROWTH_OP = "GROWTH_OP",
}

export enum CareProviderStatus {
  STATUS_UNSPECIFIED = "STATUS_UNSPECIFIED",
  PROSPECT = "PROSPECT",
  ONBOARDED = "ONBOARDED",
  DEACTIVATED = "DEACTIVATED",
  UNKNOWN = "UNKNOWN",
}

export enum CareProviderProviderSpecialty {
  PROVIDER_SPECIALTY_UNSPECIFIED = "PROVIDER_SPECIALTY_UNSPECIFIED",
  PRIMARY_CARE = "PRIMARY_CARE",
  CARDIOLOGY = "CARDIOLOGY",
  OTHER = "OTHER",
}

export enum RpmOrderDetailsCreationType {
  CREATION_TYPE_UNSPECIFIED = "CREATION_TYPE_UNSPECIFIED",
  MANUAL = "MANUAL",
  AUTOMATED = "AUTOMATED",
}

export enum RpmOrderDetailsSource {
  UNKNOWN = "UNKNOWN",
  APPOINTMENT = "APPOINTMENT",
  INBOX = "INBOX",
  API = "API",
}


type BaseHospitalEhrInfo = {
  ehr?: EHR
}

export type HospitalEhrInfo = BaseHospitalEhrInfo
  & OneOf<{ epicHospitalInfo: EpicHospitalInfo; athenaHospitalInfo: AthenaHospitalInfo; cernerHospitalInfo: CernerHospitalInfo }>

export type EpicHospitalInfo = {
  instanceId?: string
  epicDepartmentId?: string
  epicDepartmentAbbr?: string
}

export type AthenaHospitalInfo = {
  athenaDepartmentId?: string
  athenaDepartment?: string
  athenaPracticeId?: string
}

export type CernerHospitalInfo = {
  instanceId?: string
  cernerDepartmentId?: string
}

export type PatientEhrInfo = {
  ehr?: EHR
  mrn?: string
  hospital?: Hospital
  fhirId?: string
  primaryProfile?: boolean
}

export type Hospital = {
  name?: string
  displayName?: string
  email?: string
  timeZone?: string
  healthSystem?: HealthSystem
  ehrInfo?: HospitalEhrInfo
}

export type HealthSystem = {
  name?: string
  displayName?: string
}

export type CareProviderPreferences = {
  medManagementDelegation?: MedManagementDelegation
  medManagementDelegationNote?: string
  allowPatientLevelMedManagementDelegation?: boolean
}

export type CareProvider = {
  id?: string
  givenName?: string
  familyName?: string
  role?: CareProviderRole
  status?: CareProviderStatus
  preferences?: CareProviderPreferences
  providerSpecialty?: CareProviderProviderSpecialty
}

export type Address = {
  addressLines?: string[]
  city?: string
  state?: string
  postalCode?: string
}

export type RpmOrderDetails = {
  athenaOrderId?: string
  athenaPracticeId?: string
  athenaProviderId?: string
  athenaDepartmentId?: string
  cernerOrderId?: string
  cernerProviderId?: string
  cernerDepartmentId?: string
  epicOrderId?: string
  epicProviderId?: string
  epicDepartmentId?: string
  npi?: string
  mrn?: string
  status?: string
  icd10Codes?: string[]
  orderedTime?: string
  ehrCreatedTime?: GoogleProtobufTimestamp.Timestamp
  note?: string
  creationType?: RpmOrderDetailsCreationType
  source?: RpmOrderDetailsSource
}